import { ActionTree } from 'vuex'
import { TemplateState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, TemplateLoadingEvent, TemplateLoadedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { TemplateAPI } from '@/services/DigitalTwinEditor/infrastructure/api/TemplateAPI'

export const actions: ActionTree<TemplateState, any> = {
  [ActionTypes.GET_TEMPLATES]: async ({ commit, dispatch }, data) => {
    try {
      commit(new TemplateLoadingEvent())
      let res = await APIClient.shared.request(new TemplateAPI.GetTemplates(data.payload))
      commit(new TemplateLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.COUNT_TEMPLATES]: async ({ commit, dispatch }, data) => {
    try {
      commit(new TemplateLoadingEvent())
      let res = await APIClient.shared.request(new TemplateAPI.CountTemplates(data.payload))
      commit(new TemplateLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  }
}
