import Model from './Model'

export default class User extends Model {
  resource () {
    return 'users'
  }

  fullName () {
    return `${this.surname} ${this.name} ${this.midname}`
  }
}
