import { ActionTree } from 'vuex'
import { SchemaState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, SchemaLoadedEvent, SchemaLoadingEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { SchemaAPI } from '@/services/ApiService/infrastructure/api/SchemaAPI'

export const actions: ActionTree<SchemaState, any> = {
  [ActionTypes.GET_SCHEMAS]: async ({ commit, dispatch }, data) => {
    try {
      commit(new SchemaLoadingEvent())
      let res = await APIClient.shared.request(new SchemaAPI.GetSchemas(data.payload))
      commit(new SchemaLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.CREATE_SCHEMA]: async ({ commit, dispatch }, data) => {
    try {
      commit(new SchemaLoadingEvent())
      let res = await APIClient.shared.request(new SchemaAPI.CreateSchema(data.payload))
      commit(new SchemaLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.DELETE_SCHEMA]: async ({ commit, dispatch }, data) => {
    try {
      commit(new SchemaLoadingEvent())
      let res = await APIClient.shared.request(new SchemaAPI.DeleteSchema(data.guid))
      commit(new SchemaLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.UPDATE_SCHEMA]: async ({ commit, dispatch }, data) => {
    try {
      commit(new SchemaLoadingEvent())
      let res = await APIClient.shared.request(new SchemaAPI.UpdateSchema(data.payload))
      commit(new SchemaLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  }
}
