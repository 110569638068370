import CommandInterface from './CommandInterface'
import { Nullable } from '@/core/domain/type/types'

export default abstract class AbstractEntityDeleteCommand implements CommandInterface
{
    private guid: string;

    private payload: string[][] | object | null;

    constructor(guid: string, payload?: string[][] | object | null)
    {
        this.guid = guid;
        this.payload = payload;
    }

    getGuid(): string
    {
        return this.guid;
    }

    getPayload(): string[][] | object | null
    {
        return this.payload;
    }

    abstract getClassName(): string;
}