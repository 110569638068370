export default class FloatEditor {
  private value: any
  private input: HTMLInputElement
  init (params) {
    this.value = params.value

    this.input = document.createElement('input')
    this.input.id = 'input'
    this.input.type = 'number'
    this.input.inputMode = 'decimal'
    this.input.value = this.value

    this.input.addEventListener('input', (event:any) => {
      this.value = event.target.value
    })
  }

  /* Component Editor Lifecycle methods */
  // gets called once when grid ready to insert the element
  getGui () {
    return this.input
  }

  // the final value to send to the grid, on completion of editing
  getValue () {
    // this simple editor doubles any value entered into the input
    if (this.value === '') return null
    return this.value
  }

  // after this component has been created and inserted into the grid
  afterGuiAttached () {
    this.input.focus()
  }
}
