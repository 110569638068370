import { MutationTree } from 'vuex'
import { ModelInstanceState } from './state'
import { MutationTypes, ModelInstanceCreatedEvent, ModelInstanceDeletedEvent, ModelInstanceUpdatedEvent } from './mutation-types'

export const mutations: MutationTree<ModelInstanceState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false;
    state.error = error;    
  },
  [MutationTypes.MODEL_INSTANCE_LOADING_EVENT]: (state) => {
    state.isLoading = true;
  },
  [MutationTypes.MODEL_INSTANCE_CREATED_EVENT]: (state, action: ModelInstanceCreatedEvent) => {
    state.location = action.location;
    state.isLoading = false;
  },
  [MutationTypes.MODEL_INSTANCE_DELETED_EVENT]: (state, action: ModelInstanceDeletedEvent) => {
    state.isLoading = false;
    state.deletedElementGuid = action.guid;
  },
  [MutationTypes.MODEL_INSTANCE_UPDATED_EVENT]: (state, action: ModelInstanceUpdatedEvent) => {
    state.isLoading = false;
  }
}