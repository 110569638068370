import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
  ERROR = 'error',
  MIGRATION_LOADING_EVENT = 'migration_loading_event',
  MIGRATION_LOADED_EVENT = 'migration_loaded_event'
}

export class MigrationLoadingEvent implements FluxStandardAction {
  type = MutationTypes.MIGRATION_LOADING_EVENT
}

export class MigrationLoadedEvent implements FluxStandardAction {
  type = MutationTypes.MIGRATION_LOADED_EVENT
}
