import { ActionTree } from 'vuex'
import { RowFormulaState, initialState } from '@/services/LogicEditor/infrastructure/store/modules/RowFormula/state'
import { ActionTypes } from './action-types'
import {
  MutationTypes,
  RowFormulaUpdatedEvent,
  RowFormulaCreatedEvent,
  RowFormulaLoadingEvent,
  RowFormulaDeletedEvent,
  RowFormulaLoadedEvent
} from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { RowFormulaAPI } from '@/services/LogicEditor/infrastructure/api/RowFormulaAPI'

export const actions: ActionTree<RowFormulaState, any> = {
  [ActionTypes.GET_ROW_FORMULA_BY_GUID]: async ({ commit, dispatch }, data) => {
    commit(new RowFormulaLoadingEvent())

    return APIClient.shared.request(new RowFormulaAPI.GetRowFormulaByGuid(data.guid))
      .then((response) => {
        commit(new RowFormulaLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.CREATE_ROW_FORMULA]: async ({ commit, dispatch }, data) => {
    commit(new RowFormulaLoadingEvent())

    return APIClient.shared.request(new RowFormulaAPI.CreateRowFormula(data.payload))
      .then((location) => {
        commit(new RowFormulaCreatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.UPDATE_ROW_FORMULA]: async ({ commit, dispatch }, data) => {
    commit(new RowFormulaLoadingEvent())

    return APIClient.shared.request(new RowFormulaAPI.UpdateRowFormula(data.payload))
      .then((location) => {
        commit(new RowFormulaUpdatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.DELETE_ROW_FORMULA]: async ({ commit, dispatch }, data) => {
    commit(new RowFormulaLoadingEvent())

    return APIClient.shared.request(new RowFormulaAPI.DeleteRowFormula(data.guid))
      .then(() => {
        commit(new RowFormulaDeletedEvent(data.guid))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  }
}
