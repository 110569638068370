import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import RegionDTO from '@/services/AddressService/domain/model/Region'

export namespace RegionAPI {

  export class GetRegions implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor () {
      this.path = '/fiasaddressservice/regions'
    }
  }

  export class UpdateRegion implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.PUT;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: RegionDTO) {
      this.path = `/fiasaddressservice/regions/${params.code}`
    }
  }

  export class UpdateFromFIAS implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor () {
      this.path = '/fiasaddressservice/fias/update'
    }
  }
}
