<template>
  <div class="toolbar" v-show="isShowToolbar && hiddenToolbar">
    <el-button v-show="!showButtonAgGrid.update" type="text" icon="el-icon-refresh-right" class="size" @click="emit('load')"></el-button>
    <el-button v-show="!readonly && !showButtonAgGrid.add" type="text" icon="el-icon-plus" class="size" @click="$emit('add-record')"></el-button>
    <el-button v-show="!readonly && isOuterXref &&!showButtonAgGrid.add_existing" type="text" icon="el-icon-finished" class="size" @click="$emit('add-existing')"></el-button>
    <el-button
      v-show="!showButtonAgGrid.edit"
      type="text"
      :icon="'el-icon-edit'"
      class="size"
      :loading="editLoading"
      @click="$emit('edit-record', selectedRows[0])"
      :disabled="selectedRows.length >=2 || !selectedRows.length"
    ></el-button>
    <el-button
      v-show="!readonly && !showButtonAgGrid.delete"
      type="text"
      icon="el-icon-delete"
      @click="emit('delete')"
      :class="{ active: !!selectedRows.length }"
      class="size color"
      :disabled="!selectedRows.length"
    ></el-button>
    <el-tooltip content="Сбросить фильтры" placement="top">
      <el-button v-show="!showClearFiltersBtn" type="text" icon="el-icon-s-operation" class="size" @click="$emit('clear-filters')"></el-button>
    </el-tooltip>
    <el-dropdown v-show="!showButtonAgGrid.export" :hide-on-click="false">
      <el-button
        type="text"
        icon="el-icon-download"
        class="size"
        :loading="exportLoading"
      ></el-button>
      <el-dropdown-menu class="custom-scrollbar" slot="dropdown">
        <el-dropdown-item>
          <span class="el-dropdown-link" @click="$emit('export')">
            {{ $t('main.button.export') }}
          </span>
        </el-dropdown-item>
        <template v-if="exportTemplates.length > 0">
          <el-dropdown-item
            v-for="(item, index) in exportTemplates"
            :key="item.task_id"
            :index="index" :divided="!index"
          >
            <span class="el-dropdown-link" @click="$emit('export', 'task', item)">
              {{item.task_name}}
            </span>
          </el-dropdown-item>
        </template>
        <template v-else>
          <el-dropdown-item disabled divided>
            <span class="el-dropdown-link">
              {{ $t('registry.toolbar.template_empty') }}
            </span>
          </el-dropdown-item>
        </template>
      </el-dropdown-menu>
    </el-dropdown>
    <el-input
      v-show="!showButtonAgGrid.search"
      size="mini"
      clearable
      @keyup.enter.native="loadData"
      @clear="loadData"
      v-model="searchModel"
      :style="cssQuickSearch"
      :placeholder="placeholderQuickSearch"
    >
      <i slot="prefix" class="el-input__icon el-icon-search"></i>
    </el-input>
  </div>
</template>

<script>
export default {
  name: 'toolbar',

  inject: ['getEventBus'],

  props: {
    readonly: {
      type: Boolean
    },
    isShowToolbar: {
      type: Boolean,
      default: true
    },
    isOuterXref: {
      type: Boolean
    },

    editLoading: {
      type: Boolean,
      default: false
    },

    exportLoading: {
      type: Boolean,
      default: false
    },

    cssQuickSearch: {
      type: String
    },

    placeholderQuickSearch: {
      type: String
    },

    showButtonAgGrid: {
      type: Object,
      default: function () {
        return {}
      }
    },

    exportTemplates: {
      type: Array,
      default: function () {
        return []
      }
    }
  },

  data () {
    return {
      selectedRows: [],
      searchModel: null
    }
  },

  computed: {
    hiddenToolbar () {
      if (Object.values(this.showButtonAgGrid).length) {
        return Object.values(this.showButtonAgGrid).includes(false)
      }
      return true
    },
    showClearFiltersBtn () {
      if ('clearFilters' in this.showButtonAgGrid) {
        return this.showButtonAgGrid.clearFilters
      }
      return true
    }
  },

  methods: {
    loadData () {
      this.$emit('query-search', this.searchModel)
      this.getEventBus().$emit('deselectAll')
      this.selectedRows = []
    },

    emit (type) {
      this.getEventBus().$emit(type, this.selectedRows)
    },

    changeSelected (rows) {
      this.selectedRows = rows
    },

    registerEvents () {
      this.getEventBus().$on('selectedRows', this.changeSelected)
    }
  },

  mounted () {
    this.registerEvents()
  },

  beforeDestroy () {
    this.getEventBus().$off('selectedRows', this.changeSelected)
  }
}
</script>

<style scoped src="./Toolbar.css">

</style>
