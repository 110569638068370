import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { TemplateDTO } from '@/services/DigitalTwinEditor/domain/model/Project/Template'

export namespace TemplateAPI {

    export class CountTemplates implements APIRequest<TemplateDTO> {
        response: TemplateDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor (payload: string[][]) {
          this.path = '/digitaltwineditor/templates?*[func]=count&' + (new URLSearchParams(payload).toString())
        }
    }

    export class GetTemplates implements APIRequest<TemplateDTO> {
        response: TemplateDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor (payload: string[][]) {
          this.path = '/digitaltwineditor/templates?' + (new URLSearchParams(payload).toString())
        }
    }
}