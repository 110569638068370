<template>
  <iframe :src="url" width="100%" height="99%" style="border: none" title=""></iframe>
</template>

<script>
export default {
  name: 'iframe',
  props: ['url']
}
</script>

<style scoped>

</style>
