import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import StrokeStyleQueryRepositoryInterface from '@/core/domain/repository/modules/StrokeStyleQueryRepositoryInterface'
import store from '@/store'
import { GetStrokeStyles, GetStrokeStyleByGuid } from '@/core/infrastructure/store/modules/StrokeStyle/action-types'

export default class StrokeStyleQueryRepository extends AbstractQueryRepository implements StrokeStyleQueryRepositoryInterface
{
    get(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new GetStrokeStyles(payload));
    }

    getByGuid(guid: string): Promise<any>
    {
        return store.dispatch(new GetStrokeStyleByGuid(guid));
    }
}