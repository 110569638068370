import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { LayerGroupDTO } from '@/services/MapEditor/domain/model/Layer/LayerGroup'

export enum ActionTypes {
    ADD_LAYER_GROUP = 'add_layer_group',
    UPDATE_LAYER_GROUP = 'update_layer_group',
    GET_LAYER_GROUP_BY_GUID = 'get_layer_group_by_guid',
    GET_LAYER_GROUPS = 'get_layer_groups',
}

export class AddLayerGroup implements FluxStandardAction {
    type = 'LayerGroup/' + ActionTypes.ADD_LAYER_GROUP
    constructor(public payload: LayerGroupDTO){}
}

export class UpdateLayerGroup implements FluxStandardAction {
    type = 'LayerGroup/' + ActionTypes.UPDATE_LAYER_GROUP
    constructor(public payload: LayerGroupDTO){}
}