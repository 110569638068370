<template>
  <div>

    <div>
      <span class="property_label" style="display: inline-block;">{{ $t(`interface_editor.init_value_range`) }}</span>
      <el-input
        v-model.number.trim="localValue.initialValue"
        :disabled="localValue.range"
        type="number"
        size="mini"
      ></el-input>
    </div>

    <div>
      <span class="property_label" style="display: inline-block;">{{ $t(`interface_editor.range_max`) }}</span>
      <el-input
        v-model.number.trim="localValue.max"
        type="number"
        size="mini"
      ></el-input>
    </div>

    <div>
      <span class="property_label" style="display: inline-block;">{{ $t(`interface_editor.range_min`) }}</span>
      <el-input
        v-model.number.trim="localValue.min"
        type="number"
        size="mini"
      ></el-input>
    </div>

    <div>
      <span class="property_label" style="display: inline-block;">{{ $t(`interface_editor.range_step`) }}</span>
      <el-input
        v-model.number.trim="localValue.step"
        type="number"
        size="mini"
      ></el-input>
    </div>
  <!-- range -->
    <div>
      <el-checkbox v-model="localValue.range"></el-checkbox>
      <span class="property_label" style="display: inline-block;">{{ $t(`interface_editor.range_range`) }}</span>
      <el-tooltip  placement="top-start" :open-delay="300" effect="dark" :content="$t(`interface_editor.range_range_tooltip`)">
        <i class="el-icon-question"></i>
      </el-tooltip>
    </div>
    <div v-show="localValue.range">
      <span class="property_label" style="display: inline-block;">{{ $t(`interface_editor.range_range_init_value`) }}</span>
      <el-tooltip  placement="top-start" :open-delay="300" effect="dark" :content="$t(`interface_editor.range_range_tooltip_des`)">
        <i class="el-icon-question"></i>
      </el-tooltip>
      <el-input
        v-model.trim="localValue.multiValue"
        placeholder="35,65"
        size="mini"
      ></el-input>
    </div>
  <!-- range -->

    <div>
      <el-checkbox v-model="localValue.showStops"></el-checkbox>
      <span class="property_label" style="display: inline-block;">{{ $t(`interface_editor.range_show_stops`) }}</span>
    </div>

    <div>
      <el-checkbox v-model="localValue.initFilter"></el-checkbox>
      <span class="property_label" style="display: inline-block;">{{ $t(`interface_editor.init_filter`) }}</span>
    </div>

    <div>
      <el-checkbox v-model="localValue.showValue"></el-checkbox>
      <span class="property_label" style="display: inline-block;">{{ $t(`interface_editor.range_show_value`) }}</span>
    </div>

    <!-- tooltip -->
    <div>
      <el-checkbox v-model="localValue.tooltip"></el-checkbox>
      <span class="property_label" style="display: inline-block;">{{ $t(`interface_editor.range_tooltip`) }}</span>
      <el-tooltip v-show="localValue.tooltip"  placement="top-start" :open-delay="300" effect="dark" :content="$t(`interface_editor.range_tooltip_des`)">
        <i class="el-icon-question"></i>
      </el-tooltip>
    </div>

    <div class="format_tooltip" v-show="localValue.tooltip">
      {{ $t(`interface_editor.range_tooltip_max`) }} {{localValue.max / localValue.formatTooltip.formatValue}} {{localValue.formatTooltip.symbol}}
      <el-select v-model="localValue.formatTooltip.formatValue">
        <el-option
          v-for="(item, index) in formatValues"
          :key="index"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      <span class="property_label" style="display: inline-block;">{{ $t(`interface_editor.range_tooltip_symbol`) }}: °C, %</span>
      <el-input
        v-model="localValue.formatTooltip.symbol"
        placeholder="°C, %"
        size="mini"
      ></el-input>
    </div>
    <!-- tooltip -->

    <div>
      <el-checkbox v-model="localValue.vertical"></el-checkbox>
      <span class="property_label" style="display: inline-block;">{{ $t(`interface_editor.range_vertical`) }}</span>
    </div>

    <div v-show="localValue.vertical">
      <span class="property_label" style="display: inline-block;">{{ $t(`interface_editor.range_height`) }}</span>
      <el-tooltip  placement="top-start" :open-delay="300" effect="dark" :content="$t(`interface_editor.range_height_tooltip`)">
        <i class="el-icon-question"></i>
      </el-tooltip>
      <el-input
        v-model.trim="localValue.height"
        size="mini"
      ></el-input>
    </div>

  </div>
</template>
<script>

export default {
  name: 'editor-range',
  props: {
    value: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
    otherProperties: {
      type: Object
    }
  },
  data () {
    return {
      localValue: {
        initialValue: 0,
        max: 100,
        min: 0,
        step: 10,
        height: '200px',
        showStops: false,
        tooltip: false,
        vertical: false,
        showValue: false,
        initFilter: true,
        range: false,
        multiValue: '5,10',
        formatTooltip: {
          formatValue: 1,
          symbol: ''
        }
      }
    }
  },
  computed: {
    formatValues () {
      return [
        { id: 1, name: this.$t(`interface_editor.range_integer`) },
        { id: 10, name: this.$t(`interface_editor.range_decimal`) },
        { id: 100, name: this.$t(`interface_editor.range_hundredth`) }
      ]
    }
  },
  watch: {
    localValue: {
      handler (value) {
        console.log(value)
        this.$emit('change', value)
      },
      deep: true
    }
  },
  mounted () {
    if (Object.keys(this.value).length > 0) {
      this.localValue = this.value
    }
    console.log('mounted localValue', this.localValue)
    console.log('mounted value', this.value)
  }
}
</script>

<style scoped>
.format_tooltip {
    margin-bottom: 5px;
    padding: 5px;
    background-color: rgb(245 245 245);
    border-left: 2px solid rgb(210 212 94);
}
</style>
