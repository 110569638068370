import { MutationTree } from 'vuex'
import { EntityState, initialState } from './state'
import { MutationTypes } from './mutation-types'

export const mutations: MutationTree<EntityState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false;  
    state.error = error;    
  },
  [MutationTypes.ENTITIES_LOADING_EVENT]: (state) => {
    state.isLoading = true;
  },
  [MutationTypes.ENTITIES_LOADED_EVENT]: (state) => {
    state.isLoading = false;
  }
}