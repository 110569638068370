import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { TopicDTO } from '@/services/Databus/domain/model/Topic/Topic'

export enum ActionTypes {
    ADD_TOPIC = 'add_topic',
    UPDATE_TOPIC = 'update_topic',
    DELETE_TOPIC = 'delete_topic',
    GET_TOPICS = 'get_topics',
    COUNT_TOPICS = 'count_topics',
    GET_TOPIC_BY_GUID = 'get_topic_by_guid'
}

export class AddTopic implements FluxStandardAction {
    type = 'Topic/' + ActionTypes.ADD_TOPIC
    constructor(public payload: TopicDTO){}
}

export class UpdateTopic implements FluxStandardAction {
    type = 'Topic/' + ActionTypes.UPDATE_TOPIC
    constructor(public payload: TopicDTO){}
}

export class DeleteTopic implements FluxStandardAction {
    type = 'Topic/' + ActionTypes.DELETE_TOPIC
    constructor(public guid: string){}
}

export class GetTopics implements FluxStandardAction {
    type = 'Topic/' + ActionTypes.GET_TOPICS
    constructor(public payload: object){}
}

export class CountTopics implements FluxStandardAction {
    type = 'Topic/' + ActionTypes.COUNT_TOPICS
    constructor(public payload: object){}
}

export class GetTopicByGuid implements FluxStandardAction {
    type = 'Topic/' + ActionTypes.GET_TOPIC_BY_GUID
    constructor(public guid: string){}
}