<template>
  <div class="document_editor">
    <iframe :src="url" width="100%" height="99%" style="border: none" title=""></iframe>
  </div>
</template>

<script>
export default {
  name: 'DocumentViewer',

  props: {
    reportId: Number,
    filename: String,
    isRegistryFile: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    url () {
      let filePath = `file:///data/reports/documents/${this.filename}`

      if (this.isRegistryFile) {
        filePath = `file:///data/${this.filename}`
      }

      return `${this.$config.api}/documenteditor?file_path=${filePath}`
    }
  }
}
</script>

<style type="text/css">
.document_editor {
  height: 99%;
  width: 100%;
}
</style>
