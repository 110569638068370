import Vue from 'vue'

export default Vue.extend({
  mixins: [],
  name: 'percentageSparklineField',
  data () {
    return {
      percentageSettings: [],
      cellData: [],
      totalCount: 0
    }
  },
  template: `
    <div style="width: 100%; height: 80%; display: flex; flex-direction: row">
    <el-tooltip
        v-show="!totalCount"
        :open-delay="500"
        class="item"
        effect="light"
        placement="top">
      <div v-html="tooltipContent" slot="content"></div>
      <div
          v-show="!totalCount"
          :style="{
            backgroundColor: '#CCC',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden'
          }">
          <span
              :style="{
              fontFamily: 'Roboto',
              fontSize: '0.8em'
            }"
          >
            0%
          </span>
      </div>
    </el-tooltip>
    <el-tooltip
        v-show="totalCount > 0"
        v-for="(item, index) in parts"
        :key="index"
        :open-delay="500"
        class="item"
        effect="light"
        placement="top">
      <div v-html="tooltipContent" slot="content"></div>
      <div
          :style="{
            backgroundColor: item.color,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            width: item.part + '%',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden'
          }">
          <span
              :style="{
              fontFamily: 'Roboto',
              fontSize: '0.8em'
            }"
          >
            {{ item.part }}%
          </span>
      </div>
    </el-tooltip>
    </div>
  `,
  mounted () {
    this.percentageSettings = this.params.colDef.cellRendererParams.percentageOptions
    this.cellData = this.params.data
  },
  computed: {
    parts () {
      this.percentageSettings.forEach((el) => {
        if (!this.cellData[el.attribute]) this.cellData[el.attribute] = 0
        this.totalCount += parseInt(this.cellData[el.attribute])
      })
      let result = this.percentageSettings.map((el) => {
        const value = parseInt(this.cellData[el.attribute])
        const part = this.totalCount === 0 ? 0 : Math.floor(value / this.totalCount * 100)
        return {
          color: el.color,
          value,
          part,
          label: el.name
        }
      })

      let totalPercent = result.reduce((prev, cur) => prev + cur.part, 0)
      if (totalPercent < 100 && result.length > 0) {
        result[result.length - 1].part += (100 - totalPercent)
      }

      return result
    },
    tooltipContent () {
      let fullString = ''
      this.percentageSettings.map((el) => {
        const value = parseInt(this.cellData[el.attribute])
        fullString += `<div style="display: inline-block; background: ${el.color}; width: 10px; height: 10px"></div> ${el.name}: ${value}<br>`
      })
      return fullString
    }
  }
})
