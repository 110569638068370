import {
  is,
  getBusinessObject
} from 'bpmn-js/lib/util/ModelUtil'

export function isConditionalFlow (element) {
  const businessObject = getBusinessObject(element)
  const sourceBusinessObject = getBusinessObject(element.source)
  console.log(is(sourceBusinessObject, 'bpmn:Activity'), sourceBusinessObject)
  if (!is(element, 'bpmn:SequenceFlow') || !sourceBusinessObject) {
    return false
  }

  return is(sourceBusinessObject, 'bpmn:Activity')
}

export function getLoopCharacteristics (element) {
  const businessObject = getBusinessObject(element)
  return businessObject.get('loopCharacteristics')
}

export function getExtensionProperty (element, property: string) {
  if (Object.keys(element.businessObject?.extensionElements || {}).length) {
    const properties = element.businessObject?.extensionElements.get('values')[0].get('properties')
    const _extension = properties?.find((_) => _.name === property)
    if (_extension) {
      return _extension.value
    }
  }

  return null
}
