export default class BooleanEditor {
  private value: any
  private input: HTMLSelectElement

  init (params) {
    this.value = params.value

    this.input = document.createElement('select')
    this.input.id = 'input'
    const array = [
      { id: 'no', name: 'Нет', selected: !params.value },
      { id: 'yes', name: 'Да', selected: params.value }
    ]
    // Create and append the options
    array.forEach((item) => {
      let option = document.createElement('option')
      option.value = item.id
      option.text = item.name
      option.selected = item.selected
      this.input.appendChild(option)
    })

    this.input.addEventListener('change', (event: any) => {
      this.value = event.target.value === 'yes'
    })
  }

  /* Component Editor Lifecycle methods */

  // gets called once when grid ready to insert the element
  getGui () {
    return this.input
  }

  // the final value to send to the grid, on completion of editing
  getValue () {
    // this simple editor doubles any value entered into the input
    return this.value
  }

  // after this component has been created and inserted into the grid
  afterGuiAttached () {
    this.input.focus()
  }
}
