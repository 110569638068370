import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ModelInstanceCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/ModelInstanceCommandRepositoryInterface'
import ModelInstanceUpdateCommand from '@/services/DigitalTwinEditor/application/command/ModelInstanceUpdateCommand'
import { ModelInstanceDTO } from '@/services/DigitalTwinEditor/domain/model/Project/ModelInstance'

export default class ModelInstanceUpdateHandler implements CommandHandlerInterface
{
    private repo: ModelInstanceCommandRepositoryInterface;

    constructor(repo: ModelInstanceCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: ModelInstanceUpdateCommand): Promise<void>
    {
        const item: ModelInstanceDTO = {
            guid: command.getGuid(),
            position: command.getPosition(),
            rotation: command.getRotation()
        };
        return this.repo.update(item);
    }
}