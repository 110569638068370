export interface AddressState {
  isLoading: boolean;
  error: object;
}

export const initialState = (): AddressState => {
  return {
    isLoading: false,
    error: null
  }
}
