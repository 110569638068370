import { MutationTree } from 'vuex'
import { SourceState, initialState } from './state'
import { MutationTypes, SourceCreatedEvent, SourceUpdatedEvent, SourceDeletedEvent } from './mutation-types'

export const mutations: MutationTree<SourceState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false;
    state.error = error;    
  },
  [MutationTypes.SOURCE_LOADING_EVENT]: (state) => {
    state.isLoading = true;
  },
  [MutationTypes.SOURCE_LOADED_EVENT]: (state) => {
    state.isLoading = false;
  },
  [MutationTypes.SOURCE_CREATED_EVENT]: (state, action: SourceCreatedEvent) => {
    state.location = action.location;
    state.isLoading = false;
  },
  [MutationTypes.SOURCE_UPDATED_EVENT]: (state, action: SourceUpdatedEvent) => {
    state.isLoading = false;
  },
  [MutationTypes.SOURCE_DELETED_EVENT]: (state, action: SourceDeletedEvent) => {
    state.isLoading = false;
  }
}