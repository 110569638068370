<template>
  <div>
    <el-form-item :label="$locale.interface_editor.component_editor.table.source_type">
      <editor-select :value="value.type" @change="$set(value ,'type', $event)"
                     :options="{multiple: false, options: $locale.interface_editor.component_editor.editor_tasks_source.data_types}">
      </editor-select>
    </el-form-item>
    <el-form-item v-if="value.type === 'registry'" :label="$locale.interface_editor.component_editor.editor_tasks_source.source.registry">
      <registry-select-tree
        style="width: 100%;"
        type="registry"
        value-as="number"
        v-model="value.entityId"
        :placeholder="$locale.interface_editor.component_editor.editor_tasks_source.group_list_registry"
      />
    </el-form-item>
    <el-form-item v-else-if="value.type === 'query'"
                  :label="$locale.interface_editor.component_editor.editor_tasks_source.source.query">
      <editor-select
        :value="value.entityId"
        @change="$set(value ,'entityId', $event)"
        :options="{multiple: false, options: requests}"
      ></editor-select>
    </el-form-item>
    <el-form-item v-else-if="value.type === 'extended_object'"
                  :label="$locale.interface_editor.component_editor.editor_tasks_source.source.extended_object">
      <editor-select
        :value="value.entityId"
        @change="$set(value ,'entityId', $event)"
        :options="{multiple: false, options: extendedObjects}"
      ></editor-select>
    </el-form-item>
  </div>
</template>

<script>
    import EditorSelect from '../editor-select'
    import RegistrySelectTree from '@/components/Common/RegistrySelectTree.vue'
    export default {
      name: "source-data",
      components: {
        EditorSelect,
        RegistrySelectTree
      },
      data () {
        return {
          extendedObjects: [],
          requests: [],
          groupListFields: []
        }
      },
      props: {
        value: {
          type: Object,
          default () {
            return {
              type: null,
              entityId: null
            }
          }
        }
      },
      async mounted () {
        this.requests = await this.getRequests()
        this.extendedObjects = await this.getExtendObjects()
      },
      watch: {
        'value.type': {
          handler () {
            this.value.entityId = null
            this.value.field = null
            this.value.sorting = null
          }
        },
        'value.entityId': {
          handler () {
            this.value.field = null
            this.value.sorting = null
          }
        }
      },
      methods: {
        async getRequests () {
          let response = await this.$http.get(`${this.$config.api}/bieditor/queries`)
          return response.data
        },
        async getExtendObjects () {
          let extendObjects = await this.$http.get(`${this.$config.api}/bieditor/extended_objects`)
          return extendObjects.data
        },
      }
    }
</script>

<style scoped>

</style>
