import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import RouteQueryRepositoryInterface from '@/services/Databus/domain/repository/RouteQueryRepositoryInterface'
import store from '@/store'
import { GetRoutes, CountRoutes, GetRouteByGuid } from '@/services/Databus/infrastructure/store/modules/Route/action-types'

export default class RouteQueryRepository extends AbstractQueryRepository implements RouteQueryRepositoryInterface
{
    get(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new GetRoutes(payload));
    }

    getCount(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new CountRoutes(payload));
    }

    getByGuid(guid: string): Promise<any>
    {
        return store.dispatch(new GetRouteByGuid(guid));
    }
}