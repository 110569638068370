import Data from './Data'
import DataModel from './DataModel'
export default class Registry extends DataModel {
  resource () {
    return 'registry'
  }
  data () {
    return this.hasMany(Data)
  }
}
