import { FluxStandardAction } from '@/core/domain/type/types'
import { AffineTransformationDTO } from '@/services/MapEditor/domain/model/Geometry/AffineTransformation'

export enum MutationTypes {
    ERROR = 'error',
    AFFINE_TRANSFORMATION_LOADING_EVENT = 'affine_transformation_loading_event',
    AFFINE_TRANSFORMATION_CREATED_EVENT = 'affine_transformation_created_event',
    AFFINE_TRANSFORMATION_UPDATED_EVENT = 'affine_transformation_updated_event',
    AFFINE_TRANSFORMATION_DELETED_EVENT = 'affine_transformation_deleted_event'     
}

export class AffineTransformationLoadingEvent implements FluxStandardAction {
    type = MutationTypes.AFFINE_TRANSFORMATION_LOADING_EVENT
}

export class AffineTransformationCreatedEvent implements FluxStandardAction {
    type = MutationTypes.AFFINE_TRANSFORMATION_CREATED_EVENT
    constructor(public location: string){}
}

export class AffineTransformationUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.AFFINE_TRANSFORMATION_UPDATED_EVENT
}

export class AffineTransformationDeletedEvent implements FluxStandardAction {
    type = MutationTypes.AFFINE_TRANSFORMATION_DELETED_EVENT
    constructor(public guid: string){}
}