import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    EVENT_LOADING_EVENT = 'event_loading_event',
    EVENT_LOADED_EVENT = 'event_loaded_event'
}

export class EventLoadingEvent implements FluxStandardAction {
    type = MutationTypes.EVENT_LOADING_EVENT
}

export class EventLoadedEvent implements FluxStandardAction {
    type = MutationTypes.EVENT_LOADED_EVENT
}