import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import StyleQueryRepositoryInterface from '../../../domain/repository/StyleQueryRepositoryInterface'
import StyleTypePropertiesQuery from '../../query/StyleTypePropertiesQuery'

export default class StyleTypePropertiesHandler implements QueryHandlerInterface
{
    private repo: StyleQueryRepositoryInterface;

    constructor(repo: StyleQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: StyleTypePropertiesQuery): Promise<any>
    {
        return this.repo.getTypeProperties(query.getType());
    }
}