import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { UserDTO, UserUploadDTO } from '@/services/AccessEditor/domain/model/User/User'

export namespace UserAPI {

    export class AddUser implements APIRequest<string> {
      response: string;
      path = '/v2/accesseditor/users';
      method = HTTPMethod.POST;
      hideNotification = true;
      hasFiles = true;
      parse = (data: AxiosResponse) => data.headers.location
      constructor (public params: UserDTO) {}
    }

    export class UpdateUser implements APIRequest<string> {
      response: string;
      path: string;
      method = HTTPMethod.POST;
      hideNotification = true;
      hasFiles = true;
      parse = (data: AxiosResponse) => data.data
      constructor (public params: UserDTO) {
        this.path = `/v2/accesseditor/users/${params.guid}`
      }
    }

    export class DeleteUser implements APIRequest<string> {
      response: string;
      path: string;
      method = HTTPMethod.DELETE;
      parse = (data: AxiosResponse) => data.data
      constructor (guid: string) {
        this.path = `/v2/accesseditor/users/${guid}`
      }
    }

    export class CountUsers implements APIRequest<UserDTO> {
      response: UserDTO;
      path: string;
      method = HTTPMethod.GET;
      parse = (data: AxiosResponse) => data.data
      constructor (payload: string[][]) {
        this.path = '/v2/accesseditor/users?*[func]=count&is_deleted=false&' + (new URLSearchParams(payload).toString())
      }
    }

    export class GetUsers implements APIRequest<UserDTO> {
      response: UserDTO;
      path: string;
      method = HTTPMethod.GET;
      parse = (data: AxiosResponse) => data.data
      constructor (payload: string[][]) {
        this.path = '/v2/accesseditor/users?is_deleted=false&' + (new URLSearchParams(payload).toString())
      }
    }

    export class GetUserByGuid implements APIRequest<UserDTO> {
      response: UserDTO;
      path: string;
      method = HTTPMethod.GET;
      parse = (data: AxiosResponse) => data.data
      constructor (guid: string) {
        this.path = `/v2/accesseditor/users/${guid}`
      }
    }
    export class GetUserById implements APIRequest<UserDTO> {
      response: UserDTO;
      path: string;
      method = HTTPMethod.GET;
      parse = (data: AxiosResponse) => data.data
      constructor (id: string) {
        this.path = `/v2/accesseditor/users?id=${id}`
      }
    }

    export class GetPassword implements APIRequest<string> {
      response: string;
      path: string;
      method = HTTPMethod.GET;
      parse = (data: AxiosResponse) => data.data
      constructor () {
        this.path = '/v2/accesseditor/password'
      }
    }

    export class GetPasswordRules implements APIRequest<string> {
      response: string;
      path: string;
      method = HTTPMethod.GET;
      hideNotification = true;
      parse = (data: AxiosResponse) => data.data
      constructor () {
        this.path = '/v2/accesseditor/password/rules'
      }
    }

    export class PasswordCheck implements APIRequest<string> {
      response: string;
      path = '/v2/accesseditor/password/check';
      method = HTTPMethod.POST;
      hideNotification = true;
      params = null;
      parse = (data: AxiosResponse) => data.data;
      constructor (public password: string) {
        this.params = {
          password: this.password
        }
      }
    }

    export class GetApiKey implements APIRequest<string> {
      response: string;
      path: string;
      method = HTTPMethod.GET;
      parse = (data: AxiosResponse) => data.data
      constructor (id: number, roleId: number, systemIps: Array<string>) {
        this.path = `/v2/accesseditor/api_key?user_id=${id}&role_id=${roleId}&system_ips[]=${systemIps}`
      }
    }

    export class GetAvatar implements APIRequest<string> {
      response: string;
      path: string;
      method = HTTPMethod.GET;
      parse = (data: AxiosResponse) => data.data
      constructor (avatarId: number) {
        this.path = `/registryservice/files/${avatarId}`
      }
    }

    export class UploadUsers implements APIRequest<string> {
      response: string;
      path: string;
      method = HTTPMethod.POST;
      hasFiles = true;
      blob = true;
      fileName: string;
      parse = (data: AxiosResponse) => data.data
      constructor (public params: UserUploadDTO) {
        this.fileName = 'users.xlsx'
        this.path = `/v2/accesseditor/users/upload`
      }
    }

    export class GetUserCard implements APIRequest<string> {
      response: string;
      path: string;
      method = HTTPMethod.GET;
      hideNotification = true;
      parse = (data: AxiosResponse) => data.data
      constructor () {
        this.path = '/v2/accesseditor/user/card'
      }
    }
}
