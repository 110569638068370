export default {
  props: {
    value: String,
    attributeId: Number
  },
  mounted () {
    this.render()
  },
  watch: {
    value () {
      this.render()
    }
  }
}
