export default class DownloadLastBuildCommand
{
    private guid: string;
    private year: number;
    private month: number;
    private extension: string;

    constructor(guid: string, year: number, month: number, extension: string)
    {
        this.guid = guid;
        this.year = year;
        this.month = month;
        this.extension = extension;
    }

    getClassName(): string
    {
        return 'DownloadLastBuildCommand';
    }

    getGuid(): string
    {
        return this.guid;
    }

    getYear(): number
    {
        return this.year;
    }

    getMonth(): number
    {
        return this.month;
    }

    getExtension(): string
    {
        return this.extension;
    }
}