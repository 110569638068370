<template>
  <div class="task_form">
    <dialog-form v-if="editor.show" :form="editor" :save="dialogSave" :cancel="dialogCancel"></dialog-form>

    <el-form ref="form" :model="value" size="mini" :rules="formRules" label-position="top">
      <el-form-item :label="$t('etl_editor.form.fields.name')" prop="name">
        <el-input v-model="localValue.name" :placeholder="$t('etl_editor.form.fields.name')" size="mini"></el-input>
      </el-form-item>

      <el-form-item :label="$t('etl_editor.form.fields.extractor')" prop="extractor_id">
        <div class="input-group">
          <el-select v-model="localValue.extractor_id" :placeholder="$t('etl_editor.form.fields.extractor')" filterable size="mini">
            <el-option v-for="item in list.extractor" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <div class="input-group__append">
            <el-button type="success" icon="el-icon-plus" size="mini" @click="added('extractor')"></el-button>
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="edit('extractor', value.extractor_id)" :disabled="value.extractor_id === null"></el-button>
          </div>
        </div>
      </el-form-item>

      <el-form-item :label="$t('etl_editor.form.fields.loader')" prop="loader_id">
        <div class="input-group">
          <el-select v-model="localValue.loader_id" :placeholder="$t('etl_editor.form.fields.loader')" filterable size="mini">
            <el-option v-for="item in list.loader" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <div class="input-group__append">
            <el-button type="success" icon="el-icon-plus" size="mini" @click="added('loader')"></el-button>
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="edit('loader', value.loader_id)" :disabled="value.loader_id === null"></el-button>
          </div>
        </div>
      </el-form-item>

      <el-form-item :label="$t('etl_editor.form.fields.code')" prop="code">
        <el-input v-model="localValue.code" :placeholder="$t('etl_editor.form.fields.code')"></el-input>
      </el-form-item>

      <slot></slot>
    </el-form>
  </div>
</template>

<script>
import DialogForm from '../../render/DialogForm'
import Loader from '../../Models/Loader'
import Extractor from '../../Models/Extractor'

export default {
  name: 'TaskCreate',

  props: {
    value: {
      type: Object,
      default: null
    },
    title: String
  },

  components: {
    DialogForm
  },

  watch: {
    value () {
      this.localValue = this.value
    },

    localValue () {
      this.$emit('input', this.localValue)
    }
  },

  data () {
    return {
      localValue: this.value,
      formRules: {
        name: [
          { required: true, message: 'Please input Name', trigger: 'blur' }
        ],
        loader_id: [
          { required: true, message: 'Please select loader', trigger: 'change' }
        ],
        extractor_id: [
          { required: true, message: 'Please select extractor', trigger: 'change' }
        ]
      },
      list: {
        loader: [],
        extractor: []
      },
      editor: {
        title: null,
        isNew: true,
        model: null,
        component: null,
        show: false
      },
      defaultModel: {
        loader: {
          name: null,
          loader_type_id: null
        },
        extractor: {
          name: null,
          extractor_type_id: null
        }
      },
      models: {
        loader: Loader,
        extractor: Extractor
      },
      forms: {
        create: {
          loader: 'LoaderCreate',
          extractor: 'ExtractorCreate'
        },
        update: {
          loader: 'LoaderUpdate',
          extractor: 'ExtractorUpdate'
        }
      }
    }
  },

  mounted () {
    this.$http
      .get(`${this.$config.api}/etleditor/extractors?fields=id,name&order=name:asc`)
      .then((response) => {
        this.list.extractor = response.data
      })

    this.$http
      .get(`${this.$config.api}/etleditor/loaders?fields=id,name&order=name:asc`)
      .then((response) => {
        this.list.loader = response.data
      })
  },

  methods: {
    submit (save) {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          await save(true)
        }
      })
    },

    added (type) {
      this.editor.title = this.$t('etl_editor.form.title.' + `create_${type}`)
      this.editor.model = new this.models[type](this.defaultModel[type])
      this.editor.component = this.forms.create[type]

      this.editor.isNew = true
      this.editor.show = true
      this.editor.type = type
    },

    async edit (type, id) {
      this.editor.title = this.$t('etl_editor.form.title.' + `edit_${type}`)
      this.editor.model = await this.models[type].find(id)
      this.editor.component = this.forms.update[type]

      this.editor.isNew = false
      this.editor.show = true
      this.editor.type = type
    },

    dialogCancel () {
      this.editor = {
        title: null,
        isNew: true,
        model: null,
        component: null,
        show: false,
        type: null
      }
    },

    async dialogSave (isNew) {
      let response = await this.editor.model.save()

      if (isNew) {
        this.editor.isNew = false
        this.editor.model = await this.models[this.editor.type].find(response.id)
        this.editor.title = this.$t('etl_editor.form.title.' + `edit_${this.editor.type}`)
        this.editor.component = this.forms.update[this.editor.type]
      }

      let listIndex = this.list[this.editor.type].findIndex(item => item.id === response.id)

      if (listIndex !== -1) {
        this.list[this.editor.type][listIndex].name = response.name
      } else {
        this.list[this.editor.type].push({
          id: response.id,
          name: response.name
        })
      }

      let localValue = this.value
      let props = {
        loader: 'loader_id',
        extractor: 'extractor_id'
      }

      localValue[props[this.editor.type]] = response.id

      this.$emit('input', localValue)
    }
  }
}
</script>

<style type="text/css">

</style>
