import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import StyleQueryRepositoryInterface from '../../../domain/repository/StyleQueryRepositoryInterface'
import StyleTypesQuery from '../../query/StyleTypesQuery'

export default class StyleTypesHandler implements QueryHandlerInterface
{
    private repo: StyleQueryRepositoryInterface;

    constructor(repo: StyleQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: StyleTypesQuery): Promise<any>
    {
        return this.repo.getTypes();
    }
}