import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ProjectTreeElementCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/ProjectTreeElementCommandRepositoryInterface'
import ProjectTreeElementOpenWorldCreateCommand from '@/services/DigitalTwinEditor/application/command/ProjectTreeElementOpenWorldCreateCommand'
import { OpenWorldDTO } from '@/services/DigitalTwinEditor/domain/model/OpenWorld/OpenWorld'

export default class ProjectTreeElementOpenWorldCreateHandler implements CommandHandlerInterface
{
    private repo: ProjectTreeElementCommandRepositoryInterface;

    constructor(repo: ProjectTreeElementCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: ProjectTreeElementOpenWorldCreateCommand): Promise<void>
    {
        const item: OpenWorldDTO = {
            name: command.getName(),
            alias: command.getAlias(),
            heightmap: command.getHeightmap(),
            description: command.getDescription(),
            coordinate_system_id: command.getCoordinateSystem(),
            start_point: command.getStartPoint(),
            is_auto_borders: command.getAutoBorders(),
            borders_bbox: command.getBordersBbox(),
            radius_of_buffer: command.getRadiusOfBuffer(),
            parent_id: command.getParentId()
        };
        return this.repo.insertOpenWorld(item);
    }
}