import axios from 'axios'
import Vue from 'vue'
import EventFactory from '@/core/infrastructure/service/EventFactory'
import _localStorage from '@/helpers'

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem('user-token') || '',
    status: '',
    changePassword: false,
    userId: '',
    roleId: '',
    userAttributeData: ''
  },
  actions: {
    OAuthLogin: ({ commit, dispatch }, data) => {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        axios({ url: `${config.api}/oauthservice/login/${data.providerId}`,
          data: {
            code: data.code
          },
          method: 'POST',
          hideNotification: true })
          .then(responseAuth => {
            if (responseAuth.data && responseAuth.data.error) {
              commit('error', responseAuth.data.error)
              reject(responseAuth.data.error)
              return
            }
            localStorage.setItem('authorization_type', 'OAUTH')
            dispatch('login', responseAuth.data['uuid']).then(() => {
              resolve()
            })
          })
          .catch(err => {
            commit('error', err)
            _localStorage.clearExcept(['redirect_url'])
            reject(err)
          })
      })
    },
    EsiaLogin: ({ commit, dispatch }, data) => {
      return new Promise((resolve, reject) => {
        axios({ url: `${config.api}/esiaauthentication/auth/geturl${data && data.organisationId ? `/${data.organisationId}` : ''}`, method: 'GET' })
          .then(response => {
            if (response.data.url && response.data.state) {
              const ESIA_URL = new URL(response.data.url)
              localStorage.setItem('ESIA_BASE_URL', ESIA_URL.origin)
              localStorage.setItem('ESIA_CLIENT_ID', ESIA_URL.searchParams.get('client_id'))
              localStorage.setItem('authorization_type', 'ESIA')
              window.location = response.data.url
              resolve()
              // const esiaState = response.data.state
              // localStorage.setItem('esiaState', esiaState)
            }
          })
          .catch(err => {
            commit('error', err)
            _localStorage.clearExcept(['redirect_url'])
            reject(err)
          })
      })
    },
    LDAPLogin: ({ commit, dispatch }, user) => {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        axios({ url: `${config.api}/ldapauthentication/auth/login`, data: user, method: 'POST', hideNotification: true })
          .then(responseAuth => {
            if (responseAuth.data && responseAuth.data.error) {
              commit('error', responseAuth.data.error)
              reject(responseAuth.data.error)
              return
            }
            localStorage.setItem('authorization_type', 'LDAP')
            dispatch('login', responseAuth.data['uuid']).then(() => {
              resolve()
            })
          })
          .catch(err => {
            commit('error', err)
            _localStorage.clearExcept(['redirect_url'])
            reject(err)
          })
      })
    },
    defaultLogin: ({ commit, dispatch }, user) => {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        axios({ url: `${config.api}/defaultauthentication/auth/login`, data: user, method: 'POST', hideNotification: true })
          .then(responseAuth => {
            if (responseAuth.data && responseAuth.data.error) {
              commit('error', responseAuth.data.error)
              reject(responseAuth.data.error)
              return
            }
            if (responseAuth.data.two_factor_auth_type) {
              resolve(responseAuth.data)
              return
            }
            localStorage.setItem('authorization_type', 'default')
            dispatch('login', responseAuth.data['uuid']).then(() => {
              resolve()
            })
          })
          .catch(err => {
            commit('error', err)
            _localStorage.clearExcept(['redirect_url'])
            reject(err)
          })
      })
    },
    login: ({ commit, dispatch }, uuid) => {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        axios({ url: `${config.api}/v2/accesseditor/login/authorize`, data: { uuid: uuid }, method: 'POST', hideNotification: true })
          .then(response => {
            const token = response.data['token']
            const nameUser = response.data['name']
            const avatar = response.data['avatar_id']
            const changePassword = response.data['need_change_password']
            localStorage.setItem('user-token', token) // store the token in localstorage
            localStorage.setItem('nameUser', nameUser) // store the token in localstorage
            localStorage.setItem('avatar', avatar) // store the token in localstorage
            localStorage.setItem('changePassword', changePassword) // store the token in localstorage
            commit('success', token)

            //let protocol = (window.location.protocol === 'https:') ? 'wss' : 'ws'
            //Vue.prototype.$eventSocket = new WebSocket(`${protocol}://${config.api.substring(7)}/eventservice/socket`, localStorage.getItem('user-token'))

            EventFactory.send(
              Vue.prototype,
              {
                eventType: 'login',
                authorizationType: localStorage.getItem('authorization_type')
              }
            )
            // you have your token, now log in your user :)
            resolve(response)
          })
          .catch(err => {
            commit('error', err)
            _localStorage.clearExcept(['redirect_url'])
            reject(err)
          })
      })
    },
    logout: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        commit('logout')
        if (localStorage.getItem('ESIA_LOGGED')) {
          const BASE_URL = localStorage.getItem('ESIA_BASE_URL')
          const CLIENT_ID = localStorage.getItem('ESIA_CLIENT_ID')
          if (BASE_URL && CLIENT_ID) {
            window.location = `${BASE_URL}/idp/ext/Logout?client_id=${CLIENT_ID}&redirect_url=${window.location.origin}`
          }
        }
        _localStorage.clearExcept(['redirect_url'])
        resolve()
      })
    }
  },
  mutations: {
    success: (state, token) => {
      state.status = 'success'
      state.token = token
      state.changePassword = true
      try {
        state.userId = JSON.parse(atob(localStorage.getItem('user-token').split('.')[1])).user.id
        state.roleId = JSON.parse(atob(localStorage.getItem('user-token').split('.')[1])).user.role_id
      } catch (e) {
        state.userId = null
        state.roleId = null
      }
    },
    error: (state) => {
      state.status = 'error'
    },
    logout: (state) => {
      state.token = ''
      state.userId = ''
      state.roleId = ''
    }
  },
  getters: {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status,
    changePassword: state => state.changePassword,
    userAttributeData: (state) => async (attr) => {
      let response = await axios.get(`${config.api}/v2/accesseditor/user/extended?attr=${attr}`)
      // let response = await axios.get(`${config.api}/accesseditor/users/user?attr=${attr}`)
      return response.data.data
    },
    userId: state => {
      if (state.userId) {
        return state.userId
      } else {
        try {
          return JSON.parse(atob(localStorage.getItem('user-token').split('.')[1])).user.id
        } catch (e) {
          return null
        }
      }
    },
    roleId: state => {
      if (state.roleId) {
        return state.roleId
      } else {
        try {
          return JSON.parse(atob(localStorage.getItem('user-token').split('.')[1])).user.role_id
        } catch (e) {
          return null
        }
      }
    }
  }
}
