import QueryInterface from '@/core/application/query/QueryInterface'

export default class RoleProfileQuery implements QueryInterface {
    private roleId: number;

    constructor (roleId: number) {
      this.roleId = roleId
    }

    getClassName (): string {
      return 'RoleProfileQuery'
    }

    getRoleId (): number {
      return this.roleId
    }
}
