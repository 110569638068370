<template>
  <el-select
    v-model="localModel"
    filterable
    clearable
    @change="onChange">
    <el-option
      v-for="(item, index) in options"
      :key="index"
      :label="item.name"
      :value="item.id">
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: 'editor-dashboards',
  props: ['value'],
  data () {
    return {
      localModel: this.value,
      options: []
    }
  },
  async mounted () {
    let response = await this.$http.get(`${this.$config.api}/interfaceeditor/dashboards`)
    this.options = response.data
  },
  methods: {
    onChange (value) {
      this.$emit('change', value)
    }
  }
}
</script>

<style scoped>

</style>
