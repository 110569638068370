<template>
  <el-form label-width="120px" label-position="top" size="mini" class="mini-form">
    <el-select
      v-model="localModel.cardId"
      filterable
      clearable
      @clear="localModel.cardId = null, $emit('change', localModel)"
      @change="$emit('change', localModel)"
    >
      <el-option
        v-for="(item, index) in cards"
        :key="index"
        :label="item.name"
        :value="item.id">
      </el-option>
    </el-select>
    <el-form-item v-show="localModel.cardId" style="display:flex;;" :label="$locale.interface_editor.component_editor.button_action.open_in_window">
      <el-checkbox style="margin-left: 10px" v-model="localModel.isWindow"></el-checkbox>
    </el-form-item>
    <el-form-item
      :label="this.$locale.interface_editor.component_editor.button_action.window_title"
      v-if="localModel.isWindow"
    >
      <el-input v-model="localModel.windowTitle" />
    </el-form-item>
      <el-form-item
        :label="this.$locale.interface_editor.component_editor.button_action.window_width"
        v-if="localModel.isWindow">
        <editor-select
          :value="localModel.windowWidth"
          @change="$set(localModel ,'windowWidth', $event)"
          :options="{
                multiple: false,
                options: [
                  { id: '25', name:'25%' },
                  { id: '50', name:'50%' },
                  { id: '75', name:'75%' },
                  { id: '100', name:'100%' },
                ]
              }"
        ></editor-select>
      </el-form-item>
    <el-form-item v-if="localModel.isWindow">
      <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.window_height }} (ex: 400px)</span>
      <el-input :value="localModel.windowHeight" @input="$set(localModel ,'windowHeight', $event)"></el-input>
    </el-form-item>
    <el-form-item>
      <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.editor_open_card.container }}</span>
      <el-tooltip placement="top-start" :open-delay="400">
        <div slot="content" v-html="$locale.interface_editor.component_editor.editor_open_card.tooltip.container"></div>
        <i class="el-icon-question"></i>
      </el-tooltip>
      <el-select v-model="localModel.containerAlias" clearable>
        <el-option
          v-for="(item, index) in containers"
          :key="index"
          :label="item.alias"
          :value="item.alias"
        ></el-option>
      </el-select>
    </el-form-item>
    </el-form>
</template>

<script>
import Card from '@/components/ObjectEditor/Models/Card'
import EditorSelect from '@/components/InterfaceEditor/components/editor/editor-select.vue'

export default {
  name: 'editor-cards',
  components: {
    EditorSelect
  },
  props: ['value', 'options', 'other-properties'],
  inject: {
    getContainersStore: {
      default: () => () => {}
    }
  },
  data () {
    return {
      localModel: this.value,
      cards: [],
      containersStore: this.getContainersStore()
    }
  },
  watch: {
    'otherProperties.settings': {
      async handler (val, oldVal) {
        if (val && val.registryId) {
          await this.loadCards(val.registryId)
        }
      },
      deep: true
    },
    'otherProperties.registryId': {
      async handler (val, oldVal) {
        if (val) {
          await this.loadCards(val)
        }
      }
    }
  },
  computed: {
    containers () {
      if (!this.containersStore) {
        return
      }

      return this.containersStore.getFlat().filter(container => container.alias != null)
    }
  },
  created () {
    if (this.value && typeof this.value === 'number') {
      this.localModel = {
        cardId: this.value,
        isWindow: false,
        windowTitle: '',
        windowWidth: '25'
      }
    }
  },
  async mounted () {
    // внешняя ссылка
    if (this.options && this.options.registryId) {
      await this.loadCards(this.options.registryId)
    } else if (
      this.otherProperties &&
      this.otherProperties.registry_properties &&
      this.otherProperties.registry_properties.find((item) => item.id === 'xref') &&
      this.otherProperties.registry_properties.find((item) => item.id === 'xref').value
    ) {
      let outerXrefId = this.otherProperties.registry_properties.find((item) => item.id === 'xref').value
      let response = await this.$http.get(`${this.$config.api}/objecteditor/entities/${outerXrefId}`)
      if (response.data.data.object_id) {
        await this.loadCards(response.data.data.object_id)
      }
    }
    // a-table
    if (this.otherProperties?.settings?.registryId) {
      await this.loadCards(this.otherProperties.settings.registryId)
    }
    // ag-grid базовые таблицы
    if (this.otherProperties.registryId) {
      await this.loadCards(this.otherProperties.registryId)
    }
  },
  methods: {
    async loadCards (entityId) {
      this.cards = await new Card().params({ entity_id: entityId, fields: ['id', 'name'] }).$get()
    }
  }
}
</script>

<style scoped>
.mini-form /deep/ .el-form-item {
  margin-bottom: 0px;
}
</style>
