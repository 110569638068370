<template>
  <div>
    <Treeselect
      ref="registryFieldSelect"
      placeholder="Выбрать"
      class="custom_scrollbar"
      :value="localValue"
      :options="registryFields"
      :normalizer="registryFieldsSettings.normalizer"
      :disable-branch-nodes="true"
      :clear-on-select="true"
      :clearValueText="registryFieldsSettings.clearValueText"
      :noChildrenText="registryFieldsSettings.noChildrenText"
      :noResultsText="registryFieldsSettings.noResultsText"
      :limitText="registryFieldsSettings.limitText"
      :matchKeys="registryFieldsSettings.matchKeys"
      :value-consists-of="registryFieldsSettings.valueConsistsOf"
      :appendToBody="true"
      @select="selectField($event)"
      @input="input"
    >
      <div slot="value-label" slot-scope="{ node }"><b>{{ node.id }} </b>({{ node.label }})</div>
      <!-- <div slot="value-label" slot-scope="{ node }">{{ node.raw.customLabel }}</div> -->
      <label
        slot="option-label"
        slot-scope="{ node, shouldShowCount, count, labelClassName }"
        :class="labelClassName"
      >
        <b>{{ node.label }}</b>
        <br>
        <span style="font-size: 10px">{{ node.id }}</span>
        <span style="float: right; font-size: 10px">{{
          $locale.object_editor.entity_types[node.raw.entity_type_id] ||
          node.raw.entity_type_id
        }}</span>
      </label>
    </Treeselect>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  name: 'editor-tree-attr',
  components: {
    Treeselect
  },
  props: {
    value: {
      type: String
    },
    registryFields: {
      type: Array
    }
  },
  data () {
    return {
      localValue: this.value,
      registryFieldsSettings: {
        matchKeys: ['name', 'id'],
        valueConsistsOf: 'LEAF_PRIORITY',
        clearValueText: 'Очистить',
        noChildrenText: 'Нет данных',
        noResultsText: 'Не найдено',
        limitText: (count) => `и еще ${count}`,
        normalizer (node) {
          return {
            id: `attr_${node.id}_`,
            label: node.name,
            children: node.children,
            isLeaf: node.leaf
          }
        }
      }
    }
  },
  methods: {
    selectField (e) {
      this.localValue = `attr_${e.id}_`
      this.$emit('change', this.localValue)
    },
    input (e) {
      if (!this.$refs.registryFieldSelect.getValue()) {
        this.localValue = ''
        this.$emit('change', '')
      }
    }
  },
  mounted () {
    // console.log(this.$refs.registryFieldSelect)
  }
}
</script>
