<template>
  <div>
    <el-form-item class="editor-wrapper">
      <el-collapse value="editor-input-label">
        <el-collapse-item :title="$locale.interface_editor.component_editor.editor_input_label.label" name="editor-input-label">
          <template slot="title">
            <span class="editor-property-label">{{ $locale.interface_editor.component_editor.editor_input_label.label }}</span>
            <!-- <el-tooltip placement="top-start" :open-delay="400">
              <div slot="content" v-html="''"></div>
              <i class="el-icon-question"></i>
            </el-tooltip> -->
          </template>

          <!-- Position -->
          <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.editor_input_label.position }}</span>
          <el-tooltip placement="top-start" :open-delay="400" effect="dark">
            <div slot="content" v-html="$locale.interface_editor.component_editor.editor_input_label.tooltips.position"></div>
            <i class="el-icon-question"></i>
          </el-tooltip>
          <el-select v-model="localModel.position" clearable @change="$emit('change', localModel)">
            <el-option
              v-for="positionOption in positionOptions"
              :key="positionOption.label"
              :label="positionOption.label"
              :value="positionOption.value"
            ></el-option>
          </el-select>

          <!-- Width -->
          <div v-if="localModel.position">
            <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.editor_input_label.width }}</span>
            <el-tooltip placement="top-start" :open-delay="400" effect="dark">
              <div slot="content" v-html="$locale.interface_editor.component_editor.editor_input_label.tooltips.width"></div>
              <i class="el-icon-question"></i>
            </el-tooltip>
            <el-input v-model="localModel.width" type="number" @input="$emit('change', localModel)">
              <!-- WidthType -->
              <el-tooltip slot="append" :content="$locale.interface_editor.component_editor.editor_input_label.width_type" placement="top-start" :open-delay="700" effect="dark">
                <el-select v-model="localModel.widthType" @change="$emit('change', localModel)">
                  <el-option
                    v-for="widthTypeOption in widthTypeOptions"
                    :key="widthTypeOption.label"
                    :label="widthTypeOption.label"
                    :value="widthTypeOption.value"
                  ></el-option>
                </el-select>
              </el-tooltip>
            </el-input>
          </div>

          <!-- CSSClasses -->
          <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.editor_input_label.css_classes }}</span>
          <el-tooltip placement="top-start" :open-delay="400" effect="dark">
            <div slot="content" v-html="$locale.interface_editor.component_editor.editor_input_label.tooltips.css_classes"></div>
            <i class="el-icon-question"></i>
          </el-tooltip>
          <el-input v-model="localModel.cssClasses" @input="$emit('change', localModel)"></el-input>

          <!-- CSS -->
          <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.editor_input_label.css }}</span>
          <el-tooltip placement="top-start" :open-delay="400" effect="dark">
            <div slot="content" v-html="$locale.interface_editor.component_editor.editor_input_label.tooltips.css"></div>
            <i class="el-icon-question"></i>
          </el-tooltip>
          <el-input v-model="localModel.css" @input="$emit('change', localModel)"></el-input>
        </el-collapse-item>
      </el-collapse>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'editor-input-label',
  props: {
    value: {
      type: Object,
      default: () => ({
        position: null, // Не применять без явно заданной позиции
        width: 120,
        widthType: 'px'
      })
    }
  },
  data () {
    return {
      localModel: this.value,
      positionOptions: [
        {
          label: 'Top',
          value: 'top'
        },
        {
          label: 'Left',
          value: 'left'
        }
      ],
      widthTypeOptions: [
        {
          label: 'px',
          value: 'px'
        },
        {
          label: '%',
          value: '%'
        }
      ]
    }
  }
}
</script>

<style scoped>
/* Исправить позицию стрелки select в append в выпадающем списке в el-input */
* /deep/ .el-input-group__append .el-select .el-input {
  width: 64px;
}
</style>
