import { Module } from 'vuex'
import { actions } from './actions'
import { mutations } from './mutations'
import { getters } from './getters'
import { RuleTreeElementState, initialState } from './state'

const store: Module<RuleTreeElementState, any> = {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters
}

export default store
