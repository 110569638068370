<template>
    <el-table
            height="500"
            :data="data"
            class="registry custom_scrollbar edit_column_btn"
            :row-class-name="tableRowClassName"
            style="width: 100%">
        <el-table-column
                v-for="(column, index) in columns"
                :key="index"
                :prop="column.value"
                :label="column.text"
                :fixed="column.fixed"
                :width="column.width"
                header-align="center"
        >
            <template slot-scope="scope">
                <i
                        v-if="index === 0"
                        :style="`float: left;margin-left: 10px;color:${(scope.row.status === 'success' ? 'green': (scope.row.status === 'error' ? 'red' : ''))}`"
                        :class="{'el-icon-edit' : scope.row.status === 'edited',
                         'el-icon-loading' : scope.row.status === 'loading',
                         'el-icon-circle-check': scope.row.status === 'success',
                         'el-icon-circle-close': scope.row.status === 'error',
                         }"
                ></i>
                <component
                        v-if="!column.editable"
                        :is="column.type"
                        :value="scope.row[column.value]"
                        :record-id="scope.row.id"
                        :extended="column.extended"
                ></component>
                <component
                        v-else
                        :is="'input' + UcFirst(column.type)"
                        :value="scope.row[column.value]"
                        :record-id="scope.row.id"
                        :attr-name="column.value"
                        :fixed-number="column.fixedNumber"
                        @input="$set(scope.row, column.value, $event); $set(scope.row, 'status', 'edited')"
                ></component>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
import stringField from '../Columns/string_field'
import booleanField from '../Columns/boolean_field'
import dateField from '../Columns/date_field'
import datetimeField from '../Columns/datetime_field'
import floatField from '../Columns/float_field'
import integerField from '../Columns/integer_field'
import textField from '../Columns/text_field'
import xrefField from '../Columns/xref_field'
import xrefMultiField from '../Columns/xref_multi_field'
import xrefOuterField from '../Columns/xref_outer_field'
import fileField from '../Columns/file_field'
import timeField from '../Columns/time_field'
import indicatorField from '../Columns/indicator_field'
import progressField from '../Columns/progress_field'
import monthField from '../Columns/month_field'

import inputStringField from '../Inputs/input_string_field'
import inputTextField from '../Inputs/input_text_field'
import inputIntegerField from '../Inputs/input_integer_field'
import inputFloatField from '../Inputs/input_float_field'
import inputBooleanField from '../Inputs/input_boolean_field'
import inputTimeField from '../Inputs/input_time_field'
import inputDateField from '../Inputs/input_date_field'
import inputDatetimeField from '../Inputs/input_datetime_field'
import inputXrefField from '../Inputs/input_xref_field'
import inputXrefMultiField from '../Inputs/input_xref_multi_field'
import inputMonthField from '../Inputs/input_month_field'
export default {
  name: 'fast-editor',
  components: {
    inputStringField,
    inputTextField,
    inputIntegerField,
    inputFloatField,
    inputBooleanField,
    inputTimeField,
    inputDateField,
    inputDatetimeField,
    inputXrefField,
    inputXrefMultiField,
    inputMonthField,

    stringField,
    booleanField,
    dateField,
    datetimeField,
    floatField,
    integerField,
    textField,
    xrefField,
    xrefMultiField,
    xrefOuterField,
    fileField,
    timeField,
    indicatorField,
    progressField,
    monthField
  },
  props: {
    columns: {
      type: Array,
      default () {
        return []
      }
    },
    data: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
    }
  },
  methods: {
    tableRowClassName ({ row, rowIndex }) {
      if (row.status === 'success') {
        return 'success-row'
      } else if (row.status === 'error') {
        return 'warning-row'
      } else if (row.status === 'loading') {
        return 'loading-row'
      }
    },
    UcFirst (s) {
      return s.replace(/(^[a-z])/ig, ($1) => {
        return $1.toUpperCase()
      })
    }
  }
}
</script>
<style>
    .el-table .warning-row {
        background: #f9ebeb;
    }

    .el-table .success-row {
        background: #f0f9eb;
    }
    .el-table .loading-row {
        background: #FFF;
    }
</style>
<style src="../Registry.css">

</style>
