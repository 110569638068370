import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { EventDTO } from '@/services/Databus/domain/model/Event/Event'

export enum ActionTypes {
    GET_EVENTS = 'get_events',
    COUNT_EVENTS = 'count_events',
    GET_EVENT_BY_GUID = 'get_event_by_guid',
    GET_EVENT_TREE = 'get_event_tree'
}

export class GetEvents implements FluxStandardAction {
    type = 'DatabusEvent/' + ActionTypes.GET_EVENTS
    constructor(public payload: object){}
}

export class CountEvents implements FluxStandardAction {
    type = 'DatabusEvent/' + ActionTypes.COUNT_EVENTS
    constructor(public payload: object){}
}

export class GetEventByGuid implements FluxStandardAction {
    type = 'DatabusEvent/' + ActionTypes.GET_EVENT_BY_GUID
    constructor(public guid: string){}
}

export class GetEventTree implements FluxStandardAction {
    type = 'DatabusEvent/' + ActionTypes.GET_EVENT_TREE
    constructor(public guid: string){}
}