import { MutationTree } from 'vuex'
import { RuleGroupState, initialState } from './state'
import { MutationTypes, RuleGroupCreatedEvent, RuleGroupUpdatedEvent, RuleGroupDeletedEvent } from './mutation-types'

export const mutations: MutationTree<RuleGroupState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false
    state.error = error
  },
  [MutationTypes.RULE_GROUP_LOADING_EVENT]: (state) => {
    state.isLoading = true
  },
  [MutationTypes.RULE_GROUP_LOADED_EVENT]: (state) => {
    state.isLoading = false
  },
  [MutationTypes.RULE_GROUP_CREATED_EVENT]: (state, action: RuleGroupCreatedEvent) => {
    state.isLoading = false
    state.location = action.location
  },
  [MutationTypes.RULE_GROUP_UPDATED_EVENT]: (state, action: RuleGroupUpdatedEvent) => {
    state.isLoading = false
  },
  [MutationTypes.RULE_GROUP_DELETED_EVENT]: (state, action: RuleGroupDeletedEvent) => {
    state.isLoading = false
    state.deletedRuleGroupGuid = action.guid
  }
}
