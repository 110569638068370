import { ActionTree } from 'vuex'
import { ProjectGroupState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, ProjectGroupUpdatedEvent, ProjectGroupLoadingEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { ProjectGroupAPI } from '@/services/MapEditor/infrastructure/api/ProjectGroupAPI'

export const actions: ActionTree<ProjectGroupState, any> = {
    [ActionTypes.UPDATE_PROJECT_GROUP]: async ({ commit, dispatch }, data) => {
        try {
            commit(new ProjectGroupLoadingEvent());
            await APIClient.shared.request(new ProjectGroupAPI.UpdateProjectGroup(data.payload));
            commit(new ProjectGroupUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    }
}
