import OpenWorldCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/OpenWorldCommandRepositoryInterface'
import { OpenWorldDTO } from '@/services/DigitalTwinEditor/domain/model/OpenWorld/OpenWorld'
import store from '@/store'
import { UpdateOpenWorld, SetDefaultTextures, SetTerrain, SetLandscape, StopTiling, UpdateStyle } from '@/services/DigitalTwinEditor/infrastructure/store/modules/OpenWorld/action-types'

export default class OpenWorldCommandRepository implements OpenWorldCommandRepositoryInterface
{
    update(item: OpenWorldDTO): Promise<void>
    {
        return store.dispatch(new UpdateOpenWorld(item));
    }

    setDefaultTextures(item: OpenWorldDTO): Promise<void>
    {
        return store.dispatch(new SetDefaultTextures(item));
    }

    setTerrain(item: OpenWorldDTO): Promise<void>
    {
        return store.dispatch(new SetTerrain(item));
    }

    setLandscape(item: OpenWorldDTO): Promise<void>
    {
        return store.dispatch(new SetLandscape(item));
    }

    stopTiling(item: OpenWorldDTO): Promise<void>
    {
        return store.dispatch(new StopTiling(item));
    }

    updateStyle(item: OpenWorldDTO): Promise<void>
    {
        return store.dispatch(new UpdateStyle(item));
    }
}
