import axios from 'axios'
import { NetworkClient } from './NetworkClient'
import { APIResponse } from './APIResponse'
import { APIRequest } from './APIRequest'
import { APIError } from './APIError'

export enum HTTPMethod {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT'
}

// APIClient is client class for xhr request
export class APIClient implements NetworkClient {
  static shared = new APIClient()

  baseURL: string = `${config.api}`
  // Timeout duration
  timeout: number = 15 * 1000

  constructor (baseURL?: string) {
    if (baseURL) {
      this.baseURL = baseURL
    }
  }

  request<U extends APIResponse> (request: APIRequest<U>): Promise<U> {
    const isRead = request.method === HTTPMethod.GET
    let payload = {
      url: request.path,
      method: request.method,
      params: isRead && request.params,
      data: !isRead && this.prepareData(request.params, request.hasFiles),
      // timeout: this.timeout,
      baseURL: request.baseURL || this.baseURL,
      headers: this.createHeaders(request.hasFiles),
      hideNotification: request.hideNotification
    }
    if (request.blob) {
      payload['responseType'] = 'blob'
    }
    return new Promise<U>((resolve, reject) => {
      axios
        .request(payload)
        .then(data => {
          if (request.blob) {
            this.forceFileDownload<U>(request, data, resolve)
          } else {
            const response = request.parse
              ? request.parse(data)
              : this.parse<U>(data)
            resolve(response)
          }
        })
        .catch(err => {
          console.log(err, 'Catch error')
          const apiError = this.normalizeError(err)
          reject(apiError)
        })
    })
  }

  private prepareData (data: any, hasFiles: boolean): any {
    if (hasFiles) {
      let formData = new FormData()
      for (let key in data) {
        if (data.hasOwnProperty(key)) {
          if (typeof data[key] !== 'undefined' && data[key] !== null) {
            formData.append(key, data[key])
          }
        }
      }
      return formData
    }
    return data
  }

  private forceFileDownload<U extends APIResponse> (request: APIRequest<U>, data: any, resolve: Function) {
    const url = window.URL.createObjectURL(new Blob([data.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', request.fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    resolve(request.fileName)
  }

  // Default parser
  private parse<U extends APIResponse> (data: any): U {
    return data
  }

  // Convert axios error into APIError
  private normalizeError (error: any): APIError {
    let data = error.response && error.response.data
    return {
      status: (data && data.status) || (error.response && error.response.status),
      message: (data && data.message) || error.message,
      code: data && data.code,
      params: data && data.params,
      raw: error
    }
  }

  // Create headers
  private createHeaders (hasFiles: boolean): any {
    let headers = {}
    if (hasFiles) {
      headers['Content-Type'] = 'multipart/form-data'
    }
    return headers
  }
}
