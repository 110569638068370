import { MutationTree } from 'vuex'
import { LoaderState, initialState } from './state'
import { MutationTypes, LoaderCreatedEvent, LoaderUpdatedEvent, LoaderDeletedEvent } from './mutation-types'

export const mutations: MutationTree<LoaderState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false
    state.error = error
  },
  [MutationTypes.LOADER_LOADING_EVENT]: (state) => {
    state.isLoading = true
  },
  [MutationTypes.LOADER_LOADED_EVENT]: (state) => {
    state.isLoading = false
  },
  [MutationTypes.LOADER_CREATED_EVENT]: (state, action: LoaderCreatedEvent) => {
    state.isLoading = false
    state.location = action.location
  },
  [MutationTypes.LOADER_UPDATED_EVENT]: (state, action: LoaderUpdatedEvent) => {
    state.isLoading = false
  },
  [MutationTypes.LOADER_DELETED_EVENT]: (state, action: LoaderDeletedEvent) => {
    state.isLoading = false
    state.deletedLoaderGuid = action.guid
  }
}
