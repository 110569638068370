import ValueFormatters from '@/core/infrastructure/components/Grid/infrastructure/service/ValueFormatters'

const types = {
  dateField: {
    valueFormatter: ValueFormatters.dateField
  },
  xrefField: {
    valueFormatter: ValueFormatters.xrefField,
    enablePivot: true
  },
  xrefMultiField: {
    valueFormatter: ValueFormatters.xrefField
  },
  timeField: {},
  textField: {
    enablePivot: true
  },
  stringField: {
    enablePivot: true
  },
  integerField: {},
  floatField: {
    valueGetter: ValueFormatters.floatField,
    valueFormatter: ValueFormatters.floatField
  },
  fileField: {
    cellRenderer: ValueFormatters.fileField
  },
  datetimeField: {
    valueFormatter: ValueFormatters.dateTimeField
  },
  booleanField: {},
  addressField: {},
  garAddressField: {},
  garAddressMultiField: {},
  htmlField: {},
  agSparklineCellRenderer: {
    valueGetter: ValueFormatters.sparkline
  },
  stringWithSetFilter: {
    enablePivot: true,
    filter: 'stringSetFilter'
  }
}

export default types
