import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    SETTING_LOADING_EVENT = 'setting_loading_event',
    SETTING_LOADED_EVENT = 'setting_loaded_event',
    SETTING_CREATED_EVENT = 'setting_created_event',
    SETTING_UPDATED_EVENT = 'setting_updated_event',
    SETTING_DELETED_EVENT = 'setting_deleted_event'
}

export class SettingLoadingEvent implements FluxStandardAction {
    type = MutationTypes.SETTING_LOADING_EVENT
}

export class SettingLoadedEvent implements FluxStandardAction {
    type = MutationTypes.SETTING_LOADED_EVENT
}

export class SettingCreatedEvent implements FluxStandardAction {
    type = MutationTypes.SETTING_CREATED_EVENT
    constructor(public location: string){}
}

export class SettingUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.SETTING_UPDATED_EVENT
}

export class SettingDeletedEvent implements FluxStandardAction {
    type = MutationTypes.SETTING_DELETED_EVENT
    constructor(public id: number){}
}