import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { ModelFileDTO, TransformFileDTO } from '@/services/DigitalTwinEditor/domain/model/File/ModelFile'

export enum ActionTypes {
    ATTACH_FILE_TO_MODEL = 'attach_file_to_model',
    DELETE_MODEL_FILE = 'delete_model_file',
    GET_FILE_BY_GUID = 'get_file_by_guid',
    TRANSFORM_FILE = 'transform_file',
    GET_MODEL_FILES = 'get_model_files'
}

export class AttachFileToModel implements FluxStandardAction {
    type = 'ModelFile/' + ActionTypes.ATTACH_FILE_TO_MODEL
    constructor(public payload: ModelFileDTO){}
}

export class DeleteModelFile implements FluxStandardAction {
    type = 'ModelFile/' + ActionTypes.DELETE_MODEL_FILE
    constructor(public guid: string, public payload: string[][]){}
}

export class GetFileByGuid implements FluxStandardAction {
    type = 'ModelFile/' + ActionTypes.GET_FILE_BY_GUID
    constructor(public guid: string){}
}

export class TransformFile implements FluxStandardAction {
    type = 'ModelFile/' + ActionTypes.TRANSFORM_FILE
    constructor(public payload: TransformFileDTO){}
}

export class GetModelFiles implements FluxStandardAction {
    type = 'ModelFile/' + ActionTypes.GET_MODEL_FILES
    constructor(public modelGuid: string, public payload: string[][]){}
}
