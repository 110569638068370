import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class RelatedObjectDTO {
  id?: Nullable<number>
  guid?: Nullable<string>
  name: Nullable<string>
  description: Nullable<string>
  object_id: Nullable<number>
  alias_field_id: Nullable<number>
  sql_statements: Nullable<object>
  filters: Nullable<Array<object>>
  alias: Nullable<string>
  logic_id?: Nullable<number>
  parent_tree_element_id?: Nullable<number>

  constructor ({ id, guid, name, description, object_id, alias_field_id, sql_statements, filters, alias, logic_id, parent_tree_element_id }) {
    this.id = id
    this.guid = guid
    this.name = name
    this.description = description
    this.object_id = object_id
    this.alias_field_id = alias_field_id
    this.sql_statements = sql_statements
    this.filters = filters
    this.alias = alias
    this.logic_id = logic_id
    this.parent_tree_element_id = parent_tree_element_id
  }

  static create (): RelatedObjectDTO {
    return new RelatedObjectDTO({
      id: null,
      guid: null,
      name: null,
      description: null,
      object_id: null,
      sql_statements: {
        select: null,
        join: null,
        group_by: null
      },
      alias_field_id: null,
      filters: [],
      alias: null,
      logic_id: null,
      parent_tree_element_id: null
    })
  }
}

export class FilterDTO {
  id?: Nullable<number>
  guid?: Nullable<string>
  name?: Nullable<string>
  filters?: Nullable<Array<object>>

  constructor ({ id, guid, name, filters }: { id?: Nullable<number>, guid?: Nullable<string>, name?: Nullable<string>, filters?: Nullable<Array<object>> }) {
    this.id = id
    this.guid = guid
    this.name = name
    this.filters = filters
  }

  static create (name: Nullable<string> = null): FilterDTO {
    return new FilterDTO({
      name: name,
      filters: []
    })
  }
}

export class FilterFieldDTO {
  related_object_field_id?: Nullable<number>
  current_object_field_id?: Nullable<number>

  constructor ({ related_object_field_id, current_object_field_id }) {
    this.related_object_field_id = related_object_field_id
    this.current_object_field_id = current_object_field_id
  }

  static create (): FilterFieldDTO {
    return new FilterFieldDTO({
      related_object_field_id: null,
      current_object_field_id: null
    })
  }
}

export default class RelatedObject extends AbstractBaseEntity {
  private id?: Nullable<number>
  private guid?: Nullable<string>
  private name: string
  private description: Nullable<string>
  private objectId: number
  private aliasFieldId: number
  private sqlStatements: object
  private filters: Array<object>
  private logicId: number
  private alias: Nullable<string>
  private parentTreeElementId?: Nullable<number>

  constructor (
    name: string,
    description: Nullable<string>,
    objectId: number,
    aliasFieldId: number,
    sqlStatements: object,
    filters: Array<object>,
    logicId: number,
    alias: Nullable<string>,
    id?: Nullable<number>,
    guid?: Nullable<string>,
    parentTreeElementId?: Nullable<number>
  ) {
    super()
    this.id = id
    this.guid = guid
    this.name = name
    this.description = description
    this.objectId = objectId
    this.aliasFieldId = aliasFieldId
    this.sqlStatements = sqlStatements
    this.filters = filters
    this.logicId = logicId
    this.alias = alias
    this.parentTreeElementId = parentTreeElementId
  }

  static create (dto: RelatedObjectDTO): RelatedObject {
    return new RelatedObject(
      dto.name,
      dto.description,
      dto.object_id,
      dto.alias_field_id,
      dto.sql_statements,
      dto.filters,
      dto.logic_id,
      dto.alias,
      dto.id,
      dto.guid,
      dto.parent_tree_element_id
    )
  }

  setId (id: number): void {
    this.id = id
  }

  getId (): number {
    return this.id
  }

  setGuid (guid: string): void {
    this.guid = guid
  }

  getGuid (): string {
    return this.guid
  }

  setName (name: string): void {
    this.name = name
  }

  getName (): string {
    return this.name
  }

  getDescription (): string {
    return this.description
  }

  setDescription (description: string): void {
    this.description = description
  }

  setObjectId (objectId: number): void {
    this.objectId = objectId
  }

  getObjectId (): number {
    return this.objectId
  }

  setAliasFieldId (aliasFieldId: number): void {
    this.aliasFieldId = aliasFieldId
  }

  getAliasFieldId (): number {
    return this.aliasFieldId
  }

  setSqlStatements (sqlStatements: object): void {
    this.sqlStatements = sqlStatements
  }

  getSqlStatements (): object {
    return this.sqlStatements
  }

  setFilters (filters: Array<object>): void {
    this.filters = filters
  }

  getFilters (): Array<object> {
    return this.filters
  }

  setLogicId (logicId: number): void {
    this.logicId = logicId
  }

  getLogicId (): number {
    return this.logicId
  }

  getParentTreeElementId (): Nullable<number> {
    return this.parentTreeElementId
  }

  setParentTreeElementId (parentTreeElementId?: Nullable<number>): void {
    this.parentTreeElementId = parentTreeElementId
  }

  getAlias (): Nullable<string> {
    return this.alias
  }

  setAlias (alias: Nullable<string>): void {
    this.alias = alias
  }
}
