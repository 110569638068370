<template>
  <el-form-item :label="label">
    <el-select v-model="localModel"
               clearable
               @clear="$emit('input', null)"
               @change="$emit('input', localModel)">
      <el-option
        v-for="(item, index) in options"
        :key="index"
        :label="$locale.address_service.address_levels[item.name]"
        :value="item.id"
      >
      </el-option>
    </el-select>
  </el-form-item>
</template>

<script>

export default {
  name: 'address_level',
  props: ['value', 'entity-id', 'entity-type-id', 'properties', 'label'],
  data () {
    return {
      localModel: this.value,
      options: []
    }
  },
  async mounted () {
    let data = await this.$http.get(`${this.$config.api}/fiasaddressservice/address_levels`)
    this.options = data.data
  }
}
</script>

<style scoped>

</style>
