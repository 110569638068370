export default class PaletteProvider {
  eventBus
  constructor (palette, eventBus) {
    this.eventBus = eventBus
    palette.registerProvider(this)
  }

  getPaletteEntries (element) {
    const elements = [
      {
        title: 'Добавить начало процесса',
        alias: 'add-approval',
        icon: 'bpmn-icon-start-event-none',
        data: {
          type: 'StartEvent'
        }
      },
      {
        title: 'Добавить конец процесса',
        alias: 'add-approval-end',
        icon: 'bpmn-icon-end-event-none',
        data: {
          type: 'EndEvent'
        }
      },
      {
        title: 'Добавить ServiceTask',
        alias: 'add-service-task',
        icon: 'bpmn-icon-service-task',
        data: {
          type: 'ServiceTask'
        }
      },
      {
        title: 'Добавить UserTask',
        alias: 'add-user-task',
        icon: 'bpmn-icon-manual',
        data: {
          type: 'UserTask',
          name: 'Задача'
        }
      },
      {
        title: 'Добавить параллельный шлюз',
        alias: 'add-parallel-gateway',
        icon: 'bpmn-icon-gateway-parallel',
        data: {
          type: 'ParallelGateway'
        }
      },
      {
        title: 'Добавить эксклюзивный шлюз',
        alias: 'add-exclusive-gateway',
        icon: 'bpmn-icon-gateway-xor',
        data: {
          type: 'ExclusiveGateway'
        }
      },
      {
        title: 'Добавить инклюзивный шлюз',
        alias: 'add-inclusive-gateway',
        icon: 'bpmn-icon-gateway-or',
        data: {
          type: 'InclusiveGateway'
        }
      },
      {
        title: 'Добавить подпроцесс',
        alias: 'add-subprocess',
        icon: 'bpmn-icon-subprocess-collapsed',
        data: {
          name: 'Процесс',
          type: 'SubProcess'
        }
      }
    ]
    const eventBus = this.eventBus
    return function (entries) {
      // console.log(entries)
      let result = {
        'hand-tool': Object.assign(entries['hand-tool'], { title: 'Активировать перетаскивание' }),
        'lasso-tool': Object.assign(entries['lasso-tool'], { title: 'Активировать лассо' }),
        'global-connect-tool': Object.assign(entries['global-connect-tool'], { title: 'Соединить элементы' }),
        'create.participant-expanded': Object.assign(entries['create.participant-expanded'], { title: 'Добавить дорожку', group: 'tools' }),
        'tool-separator': {
          group: 'tools',
          separator: true
        }
      }

      elements.forEach((item, index) => {
        if (item.separator) {
          result[`separator_${index}`] = {
            group: 'accent',
            separator: true
          }
          return
        }
        result[item.alias] = {
          group: 'accent',
          className: item.icon,
          title: item.title,
          action: {
            dragstart: (event) => {
              eventBus.fire('add', Object.assign(item.data, { event: event }))
            },
            click: (event) => {
              eventBus.fire('add', Object.assign(item.data, { event: event }))
            }
          }
        }
      })

      return result
    }
  }
}

PaletteProvider.$inject = [
  'palette',
  'eventBus'
]
