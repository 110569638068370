<template>
  <div v-if="value.properties.source.type !== 'TileWMS'" style="margin:10px;">
    <el-form-item label="При клике открывать">
      <el-select v-model="value.properties.interactive.type">
        <el-option
          v-for="type in interactiveTypes"
          :key="type.id"
          :label="type.name"
          :value="type.id"
          :disabled="!type.sources.includes(value.properties.source.type)"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-radio-group v-model="activeCardType" size="small" style="margin:10px;">
      <el-radio-button label="standard" key="standard">{{ this.$locale.interface_editor.component_editor.map_card_interactive.standard_card }}</el-radio-button>
      <el-radio-button label="registry" key="registry" v-if="value.properties.source.type === 'Registry'">{{ this.$locale.interface_editor.component_editor.map_card_interactive.registry_card }}</el-radio-button>
      <el-radio-button label="tooltip" key="tooltip">Подсказки</el-radio-button>
    </el-radio-group>
    <interactive
      v-if="activeCardType==='registry' && value.properties.source.type"
      v-model="value.properties.interactive"
      :source-type="value.properties.source.type"
      :entity-id="value.properties.source.entityId"
    ></interactive>
    <standard-card
      v-if="activeCardType==='standard' && value.properties.source.type"
      v-model="value.properties.interactive"
      :entity-id="value.properties.source.entityId"
      :source-type="value.properties.source.type"
    ></standard-card>
    <popup-panel
      v-if="activeCardType==='tooltip' && value.properties.interactive.popup"
      v-model="value.properties.interactive.popup"
    ></popup-panel>
  </div>
</template>
<script>

import StandardCard from '@/core/infrastructure/components/InteractivePanel/StandardCard'
import Interactive from '@/core/infrastructure/components/InteractivePanel/Interactive'
import PopupPanel from '@/core/infrastructure/components/PopupPanel/index.vue'

const INTERACTIVE_TYPES = [
  { id: 'open_standard_card', name: 'Стандартная карточка', sources: ['Registry', 'WFS', 'WMS', 'TileWMS'] },
  { id: 'open_registry_card', name: 'Карточка реестра', sources: ['Registry'] },
  { id: 'nothing', name: 'Ничего', sources: ['Registry', 'WFS', 'WMS', 'TileWMS', 'Field'] }
]

export default {
  name: 'InteractivePanel',
  components: {
    StandardCard, Interactive, PopupPanel
  },
  props: {
    value: Object
  },
  data () {
    return {
      activeCardType: 'standard',
      interactiveTypes: INTERACTIVE_TYPES
    }
  }
}
</script>

<style scoped>

</style>
