import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import TemplateQueryRepositoryInterface from '../../../domain/repository/TemplateQueryRepositoryInterface'
import TemplatesCountQuery from '../../query/TemplatesCountQuery'

export default class TemplatesCountHandler implements QueryHandlerInterface {
    private repo: TemplateQueryRepositoryInterface;

    constructor (repo: TemplateQueryRepositoryInterface) {
      this.repo = repo
    }

    execute (query: TemplatesCountQuery): Promise<any> {
      return this.repo.getCount(query.getPayload())
    }
}
