import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { MigrationDTO } from '@/services/SyncService/domain/model/Snapshot/Migration'

export namespace MigrationAPI {

  export class GetMigrations implements APIRequest<MigrationDTO> {
    response: MigrationDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data;
    constructor (payload: string[][]) {
      this.path = '/syncservice/migrations_log?' + (new URLSearchParams(payload).toString())
    }
  }

  export class CountMigrations implements APIRequest<MigrationDTO> {
    response: MigrationDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (payload: string[][]) {
      this.path = '/syncservice/migrations_log?*[func]=count'
    }
  }
}
