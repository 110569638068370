import { FluxStandardAction, Nullable } from '@/core/domain/type/types'

export enum ActionTypes {
  GET_RESOURCES = 'get_resources',
  GET_LOGS_BY_RESOURCE = 'get_logs_by_resource',
  GET_COUNT_BY_RESOURCE = 'get_count_by_resource',
  GET_LOGS_BY_RESOURCE_AND_DATE = 'get_logs_by_resource_and_date'
}

export class GetResources implements FluxStandardAction {
  type = 'Logs/' + ActionTypes.GET_RESOURCES
  constructor (public payload: object) {}
}

export class GetLogsByResource implements FluxStandardAction {
  type = 'Logs/' + ActionTypes.GET_LOGS_BY_RESOURCE
  constructor (public payload: string[][] | object, public resource: string) {}
}

export class GetCountByResource implements FluxStandardAction {
  type = 'Logs/' + ActionTypes.GET_COUNT_BY_RESOURCE
  constructor (public payload: string[][] | object, public resource: string) {}
}

export class GetLogsByResourceAndDate implements FluxStandardAction {
  type = 'Logs/' + ActionTypes.GET_LOGS_BY_RESOURCE_AND_DATE
  constructor (public payload: string[][] | object, public resource: string) {}
}
