import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class LogicGroupDTO {
  id?: Nullable<number>
  guid?: Nullable<string>
  name: Nullable<string>
  description: Nullable<string>
  alias: Nullable<string>
  logic_id?: Nullable<number>
  parent_tree_element_id?: Nullable<number>

  constructor ({ id, guid, name, description, alias, logic_id, parent_tree_element_id }) {
    this.id = id
    this.guid = guid
    this.name = name
    this.description = description
    this.alias = alias
    this.logic_id = logic_id
    this.parent_tree_element_id = parent_tree_element_id
  }

  static create (): LogicGroupDTO {
    return new LogicGroupDTO({
      id: null,
      guid: null,
      name: null,
      description: null,
      alias: null,
      logic_id: null,
      parent_tree_element_id: null
    })
  }
}

export default class LogicGroup extends AbstractBaseEntity {
  private id?: Nullable<number>
  private guid?: Nullable<string>
  private name: string
  private description: Nullable<string>
  private logicId: number
  private alias: Nullable<string>
  private parentTreeElementId?: Nullable<number>

  constructor (
    name: string,
    description: Nullable<string>,
    logicId: number,
    alias: Nullable<string>,
    id?: Nullable<number>,
    guid?: Nullable<string>,
    parentTreeElementId?: Nullable<number>
  ) {
    super()
    this.id = id
    this.description = description
    this.guid = guid
    this.name = name
    this.logicId = logicId
    this.alias = alias
    this.parentTreeElementId = parentTreeElementId
  }

  static create (dto: LogicGroupDTO): LogicGroup {
    return new LogicGroup(
      dto.name,
      dto.description,
      dto.logic_id,
      dto.alias,
      dto.id,
      dto.guid,
      dto.parent_tree_element_id
    )
  }

  setId (id: number): void {
    this.id = id
  }

  getId (): number {
    return this.id
  }

  setGuid (guid: string): void {
    this.guid = guid
  }

  getGuid (): string {
    return this.guid
  }

  setName (name: string): void {
    this.name = name
  }

  getName (): string {
    return this.name
  }

  getDescription (): string {
    return this.description
  }

  setDescription (description: string): void {
    this.description = description
  }

  setLogicId (logicId: number): void {
    this.logicId = logicId
  }

  getLogicId (): number {
    return this.logicId
  }

  getParentTreeElementId (): Nullable<number> {
    return this.parentTreeElementId
  }

  setParentTreeElementId (parentTreeElementId?: Nullable<number>): void {
    this.parentTreeElementId = parentTreeElementId
  }

  getAlias (): Nullable<string> {
    return this.alias
  }

  setAlias (alias: Nullable<string>): void {
    this.alias = alias
  }
}
