export enum ConditionTypes {
  state = 'state',
  procedure = 'procedure'
}

export interface IConstraint {
  id?: number|null;
  guid?: string|null;
  name: string;
  description: string|null;
  constraint_type_id: string;
  is_auto: boolean;
  is_allow: boolean;
  is_init_allow: boolean;
  is_notify: boolean;
  message: string|null;
  view_fields: number[];
  hide_fields: number[];
  disable_fields: number[];
  constraint_cards: number[];
  constraint_roles: number[];
  constraint_states: number[];
  constraint_aliases: string[];
  state_match_type: string;
  constraint_init_states: number[];
  init_state_match_type: string;
  alias: string|null;
  init_condition_type: ConditionTypes;
  condition_type: ConditionTypes;
  init_condition_procedure_id: number|null;
  condition_procedure_id: number|null;
  logic_id?: number;
  parent_tree_element_id?: number|null;
}

export class ConstraintDTO {
  id?: number|null;
  guid?: string|null;
  name: string;
  description: string|null;
  constraint_type_id: string;
  is_auto: boolean;
  is_allow: boolean;
  is_init_allow: boolean;
  is_notify: boolean;
  message: string|null;
  view_fields: number[];
  hide_fields: number[];
  disable_fields: number[];
  constraint_cards: number[];
  constraint_roles: number[];
  constraint_states: number[];
  constraint_aliases: string[];
  state_match_type: string;
  constraint_init_states: number[];
  init_state_match_type: string;
  alias: string|null;
  init_condition_type: ConditionTypes;
  condition_type: ConditionTypes;
  init_condition_procedure_id: number|null;
  condition_procedure_id: number|null;
  logic_id?: number;
  parent_tree_element_id?: number|null;

  constructor (data: IConstraint) {
    this.id = data.id
    this.guid = data.guid
    this.name = data.name
    this.description = data.description
    this.constraint_type_id = data.constraint_type_id
    this.is_auto = data.is_auto
    this.is_allow = data.is_allow
    this.is_init_allow = data.is_init_allow
    this.is_notify = data.is_notify
    this.message = data.message
    this.view_fields = data.view_fields
    this.hide_fields = data.hide_fields
    this.disable_fields = data.disable_fields
    this.constraint_cards = data.constraint_cards
    this.constraint_roles = data.constraint_roles
    this.constraint_states = data.constraint_states
    this.constraint_aliases = data.constraint_aliases
    this.state_match_type = data.state_match_type
    this.constraint_init_states = data.constraint_init_states
    this.init_state_match_type = data.init_state_match_type
    this.alias = data.alias
    this.init_condition_type = data.init_condition_type
    this.condition_type = data.condition_type
    this.init_condition_procedure_id = data.init_condition_procedure_id
    this.condition_procedure_id = data.condition_procedure_id
    this.logic_id = data.logic_id
    this.parent_tree_element_id = data.parent_tree_element_id
  }

  static create (): ConstraintDTO {
    return new ConstraintDTO({
      id: null,
      guid: null,
      name: null,
      description: null,
      constraint_type_id: null,
      is_auto: true,
      is_allow: false,
      is_init_allow: false,
      is_notify: false,
      message: null,
      view_fields: [],
      hide_fields: [],
      disable_fields: [],
      constraint_cards: [],
      constraint_roles: [],
      constraint_states: [],
      constraint_aliases: [],
      state_match_type: 'all',
      constraint_init_states: [],
      init_state_match_type: 'all',
      alias: null,
      logic_id: null,
      parent_tree_element_id: null,
      init_condition_type: ConditionTypes.state,
      condition_type: ConditionTypes.state,
      init_condition_procedure_id: null,
      condition_procedure_id: null
    })
  }
}
