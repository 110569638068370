<template>
<div style="display: inline-block">
  <span :class="{ 'property_label': label }" style="display: inline-block;">{{ label ? $t(`interface_editor.${label}`) : '' }}</span>
  <el-tooltip
    v-if="options && options.tooltip && options.tooltip.show"
    :content="$t(`interface_editor.${options.tooltip.content}`)"
    :open-delay="300"
    placement="top-start"
    effect="dark"
  >
    <div slot="content" v-html="$t(`interface_editor.${options.tooltip.content}`)"></div>
    <i class="el-icon-question"></i>
  </el-tooltip>

  <el-select
    v-model="localModel"
    :multiple="options.multiple"
    :disabled="options.disabled"
    filterable
    :clearable="options.clearable"
    @clear="localModel = options.multiple ? [] : null; $emit('change', localModel)"
    @change="$emit('change', localModel)"
  >
    <el-option
      v-for="(item, index) in options.options"
      :key="index"
      :label="item.name"
      :value="item.id"
      v-show="!item.hidden">
    </el-option>
  </el-select>
  </div>
</template>

<script>
export default {
  name: 'editor-select',
  props: ['value', 'options', 'label'],
  data () {
    return {
      localModel: this.value
    }
  },
  methods: {
    clearValue () {
      this.localModel = undefined
    }
  }
}
</script>

<style scoped>

</style>
