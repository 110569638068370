import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'

export namespace StructureAPI {

  export class GetStructure implements APIRequest<object> {
    response: object;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (registryId: number) {
      this.path = `/registryservice/registry/${registryId}/structure`
    }
  }
}
