import { ActionTree } from 'vuex'
import { StyleState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, StyleLoadingEvent, StyleLoadedEvent, StyleCreatedEvent, StyleUpdatedEvent, StyleDeletedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { StyleAPI } from '@/services/MapEditor/infrastructure/api/StyleAPI'

export const actions: ActionTree<StyleState, any> = {
    [ActionTypes.ADD_STYLE]: async ({ commit, dispatch }, data) => {
        try {
            commit(new StyleLoadingEvent());
            let location = await APIClient.shared.request(new StyleAPI.AddStyle(data.payload));
            commit(new StyleCreatedEvent(location));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.UPDATE_STYLE]: async ({ commit, dispatch }, data) => {
        try {
            commit(new StyleLoadingEvent());
            let location = await APIClient.shared.request(new StyleAPI.UpdateStyle(data.payload));
            commit(new StyleUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.DELETE_STYLE]: async ({ commit, dispatch }, data) => {
        try {
            await APIClient.shared.request(new StyleAPI.DeleteStyle(data.guid));
            commit(new StyleDeletedEvent(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }         
    },
    [ActionTypes.GET_STYLES]: async ({ commit, dispatch }, data) => {
        try {
            commit(new StyleLoadingEvent());
            let res = await APIClient.shared.request(new StyleAPI.GetStyles(data.payload));
            commit(new StyleLoadedEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.COUNT_STYLES]: async ({ commit, dispatch }, data) => {
        try {
            commit(new StyleLoadingEvent());
            let res = await APIClient.shared.request(new StyleAPI.CountStyles(data.payload));
            commit(new StyleLoadedEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.GET_STYLE_BY_GUID]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new StyleAPI.GetStyleByGuid(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    },
    [ActionTypes.GET_STYLE_PROJECTS]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new StyleAPI.GetStyleProjects(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    },
    [ActionTypes.GET_TYPES]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new StyleAPI.GetTypes());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.GET_TYPE_PROPERTIES]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new StyleAPI.GetTypeProperties(data.val));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    }
}
