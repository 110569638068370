import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
  ERROR = 'error',
  CONFIGURATION_ELEMENT_LOADING_EVENT = 'configuration_element_loading_event',
  CONFIGURATION_ELEMENT_LOADED_EVENT = 'configuration_element_loaded_event'
}

export class ConfigurationElementLoadingEvent implements FluxStandardAction {
  type = MutationTypes.CONFIGURATION_ELEMENT_LOADING_EVENT
}

export class ConfigurationElementLoadedEvent implements FluxStandardAction {
  type = MutationTypes.CONFIGURATION_ELEMENT_LOADED_EVENT
}
