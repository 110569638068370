import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { ApprovalDTO } from '@/services/LogicEditor/domain/model/Approval'
import { ApprovalStageDTO } from '@/services/LogicEditor/domain/model/ApprovalStage'

export namespace ApprovalAPI {
  export class GetApprovalStagesByApprovalId implements APIRequest<ApprovalStageDTO[]> {
    response: ApprovalStageDTO[];
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data.map(item => new ApprovalStageDTO(item))
    constructor (approvalId: number, payload: string[][]) {
      this.path = `/v2/logiceditor/approvals/${approvalId}/stages?${new URLSearchParams(payload).toString()}`
    }
  }

  export class GetApprovalStageTypes implements APIRequest<object[]> {
    response: object[];
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor () {
      this.path = `/v2/logiceditor/approval_stage_types`
    }
  }

  export class GetApprovalByGuid implements APIRequest<ApprovalDTO> {
    response: ApprovalDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => new ApprovalDTO(data.data)
    constructor (guid: string) {
      this.path = `/v2/logiceditor/approvals/${guid}`
    }
  }

  export class CreateApproval implements APIRequest<string> {
    response: string;
    path = '/v2/logiceditor/approvals';
    method = HTTPMethod.POST;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.headers.location
    constructor (public params: ApprovalDTO) {}
  }

  export class UpdateApproval implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.PUT;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: ApprovalDTO) {
      this.path = `/v2/logiceditor/approvals/${params.guid}`
    }
  }

  export class DeleteApproval implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.DELETE;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/logiceditor/approvals/${guid}`
    }
  }
}
