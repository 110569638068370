import { MutationTree } from 'vuex'
import { ProjectGroupState } from './state'
import { MutationTypes, ProjectGroupUpdatedEvent } from './mutation-types'

export const mutations: MutationTree<ProjectGroupState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false;
    state.error = error;    
  },
  [MutationTypes.PROJECT_GROUP_LOADING_EVENT]: (state) => {
    state.isLoading = true;
  },
  [MutationTypes.PROJECT_GROUP_LOADED_EVENT]: (state) => {
    state.isLoading = false;
  },
  [MutationTypes.PROJECT_GROUP_UPDATED_EVENT]: (state, action: ProjectGroupUpdatedEvent) => {
    state.isLoading = false;
  }
}