import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'

export namespace ObjectEditorAPI {
  export class GetEntities implements APIRequest<string> {
    response: string;
    path = 'objecteditor/entities';
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data;
    hideNotification = true;

    constructor (public params: Object) {
    }
  }
}
