import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ProjectCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/ProjectCommandRepositoryInterface'
import DownloadLastBuildCommand from '@/services/DigitalTwinEditor/application/command/DownloadLastBuildCommand'
import { ProjectBuildDTO } from '@/services/DigitalTwinEditor/domain/model/Project/Project'

export default class DownloadLastBuildHandler implements CommandHandlerInterface
{
    private repo: ProjectCommandRepositoryInterface;

    constructor(repo: ProjectCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: DownloadLastBuildCommand): Promise<void>
    {
        let item: ProjectBuildDTO = {
            guid: command.getGuid(),
            year: command.getYear(),
            month: command.getMonth(),
            extension: command.getExtension()
        };
        return this.repo.downloadLastBuild(item);
    }
}