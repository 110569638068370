<template>
  <el-table-column
    align="center" fixed="left"
    type="selection"
    width="35"
    header-align="center"
  >
  </el-table-column>
</template>

<script>
export default {
  props: ['isGrouped'],
  name: 'SelectableColumn'
}
</script>

<style scoped>

</style>
