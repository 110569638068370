import { ActionTree } from 'vuex'
import { ApprovalState, initialState } from './state'
import { ActionTypes } from './action-types'
import {
  MutationTypes,
  ApprovalUpdatedEvent,
  ApprovalCreatedEvent,
  ApprovalLoadingEvent,
  ApprovalDeletedEvent,
  ApprovalLoadedEvent
} from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { ApprovalAPI } from '@/services/LogicEditor/infrastructure/api/ApprovalAPI'

export const actions: ActionTree<ApprovalState, any> = {
  [ActionTypes.GET_APPROVAL_STAGE_TYPES]: async ({ commit, dispatch }, data) => {
    commit(new ApprovalLoadingEvent())

    return APIClient.shared.request(new ApprovalAPI.GetApprovalStageTypes())
      .then((response) => {
        commit(new ApprovalLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.GET_APPROVAL_STAGES]: async ({ commit, dispatch }, data) => {
    commit(new ApprovalLoadingEvent())

    return APIClient.shared.request(new ApprovalAPI.GetApprovalStagesByApprovalId(data.approvalId, data.payload))
      .then((response) => {
        commit(new ApprovalLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.GET_APPROVAL_BY_GUID]: async ({ commit, dispatch }, data) => {
    commit(new ApprovalLoadingEvent())

    return APIClient.shared.request(new ApprovalAPI.GetApprovalByGuid(data.guid))
      .then((response) => {
        commit(new ApprovalLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.CREATE_APPROVAL]: async ({ commit, dispatch }, data) => {
    commit(new ApprovalLoadingEvent())

    return APIClient.shared.request(new ApprovalAPI.CreateApproval(data.payload))
      .then((location) => {
        commit(new ApprovalCreatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.UPDATE_APPROVAL]: async ({ commit, dispatch }, data) => {
    commit(new ApprovalLoadingEvent())

    return APIClient.shared.request(new ApprovalAPI.UpdateApproval(data.payload))
      .then((location) => {
        commit(new ApprovalUpdatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.DELETE_APPROVAL]: async ({ commit, dispatch }, data) => {
    commit(new ApprovalLoadingEvent())

    return APIClient.shared.request(new ApprovalAPI.DeleteApproval(data.guid))
      .then(() => {
        commit(new ApprovalDeletedEvent(data.guid))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  }
}
