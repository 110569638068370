import { FluxStandardAction } from '@/core/domain/type/types'
import { ExtractorDTO } from '@/services/EtlEditor/domain/model/Extractor'

export enum ActionTypes {
  GET_EXTRACTOR = 'get_extractor',
  GET_EXTRACTOR_BY_GUID = 'get_extractor_by_guid',
  GET_EXTRACTOR_TYPES = 'get_extractor_types',
  GET_EXTRACTOR_TYPE_PROPERTIES = 'get_extractor_type_properties',
  CREATE_EXTRACTOR = 'create_extractor',
  UPDATE_EXTRACTOR = 'update_extractor',
  DELETE_EXTRACTOR = 'delete_extractor'
}

export class GetExtractors implements FluxStandardAction {
  type = 'Extractor/' + ActionTypes.GET_EXTRACTOR
  constructor (public payload: object) {}
}

export class GetExtractorByGuid implements FluxStandardAction {
  type = 'Extractor/' + ActionTypes.GET_EXTRACTOR_BY_GUID
  constructor (public guid: string) {}
}

export class GetExtractorTypes implements FluxStandardAction {
  type = 'Extractor/' + ActionTypes.GET_EXTRACTOR_TYPES
  constructor () {}
}

export class GetExtractorTypeProperties implements FluxStandardAction {
  type = 'Extractor/' + ActionTypes.GET_EXTRACTOR_TYPE_PROPERTIES
  constructor (public extractorTypeId: string) {}
}

export class CreateExtractor implements FluxStandardAction {
  type = 'Extractor/' + ActionTypes.CREATE_EXTRACTOR
  constructor (public payload: ExtractorDTO) {}
}

export class UpdateExtractor implements FluxStandardAction {
  type = 'Extractor/' + ActionTypes.UPDATE_EXTRACTOR
  constructor (public payload: ExtractorDTO) {}
}

export class DeleteExtractor implements FluxStandardAction {
  type = 'Extractor/' + ActionTypes.DELETE_EXTRACTOR
  constructor (public guid: string) {}
}
