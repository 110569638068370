import { ActionTree } from 'vuex'
import { LoaderState, initialState } from '@/services/EtlEditor/infrastructure/store/modules/Loader/state'
import { ActionTypes } from './action-types'
import { MutationTypes, LoaderUpdatedEvent, LoaderCreatedEvent, LoaderLoadingEvent, LoaderDeletedEvent, LoaderLoadedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { LoaderAPI } from '@/services/EtlEditor/infrastructure/api/LoaderAPI'

export const actions: ActionTree<LoaderState, any> = {
  [ActionTypes.GET_LOADER]: async ({ commit, dispatch }, data) => {
    try {
      commit(new LoaderLoadingEvent())
      let response = await APIClient.shared.request(new LoaderAPI.GetLoader(data.payload))
      commit(new LoaderLoadedEvent())
      return response
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_LOADER_BY_GUID]: async ({ commit, dispatch }, data) => {
    try {
      return await APIClient.shared.request(new LoaderAPI.GetLoaderByGuid(data.guid))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_LOADER_TYPES]: async ({ commit, dispatch }, data) => {
    try {
      return await APIClient.shared.request(new LoaderAPI.GetLoaderTypes())
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_LOADER_TYPE_PROPERTIES]: async ({ commit, dispatch }, data) => {
    try {
      return await APIClient.shared.request(new LoaderAPI.GetLoaderTypeProperties(data.loaderTypeId))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.CREATE_LOADER]: async ({ commit, dispatch }, data) => {
    try {
      commit(new LoaderLoadingEvent())
      let location = await APIClient.shared.request(new LoaderAPI.CreateLoader(data.payload))
      commit(new LoaderCreatedEvent(location))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.UPDATE_LOADER]: async ({ commit, dispatch }, data) => {
    try {
      commit(new LoaderLoadingEvent())
      let location = await APIClient.shared.request(new LoaderAPI.UpdateLoader(data.payload))
      commit(new LoaderUpdatedEvent(location))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.DELETE_LOADER]: async ({ commit, dispatch }, data) => {
    try {
      await APIClient.shared.request(new LoaderAPI.DeleteLoader(data.guid))
      commit(new LoaderDeletedEvent(data.guid))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  }
}
