export interface ITelegramChat {
  id: number;
  name: string;
  type: string;
}

export default class TelegramChatDTO implements ITelegramChat {
  public id: number;
  public name: string;
  public type: string;

  constructor (data: ITelegramChat) {
    this.id = data.id
    this.name = data.name
    this.type = data.type
  }
}
