import { Nullable } from '@/core/domain/type/types'

export default class StyleUpdateCommand
{
    private guid: string;
    private name: string;
    private description: Nullable<string>;
    private type: string;
    private properties: object;

    constructor(guid: string, name: string, description: Nullable<string>, type: string, properties: object)
    {
        this.guid = guid;
        this.name = name;
        this.description = description;
        this.type = type;
        this.properties = properties;
    }

    getGuid(): string
    {
        return this.guid;
    }

    getName(): string
    {
        return this.name;
    }

    getDescription(): string
    {
        return this.description;
    }

    getType(): string
    {
        return this.type;
    }

    getProperties(): object
    {
        return this.properties;
    }

    getClassName(): string
    {
        return 'StyleUpdateCommand';
    }    
}