import Vue from 'vue'
import openCardMixin from '@/components/InterfaceEditor/components/openCard_mixin.js'
import RegistryCard from '@/components/RegistryCard'
import clickMixin from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/clickMixin'
export default Vue.extend({
  mixins: [openCardMixin, clickMixin],
  components: { RegistryCard },
  name: 'addressField',
  template: `
    <div :class="{'hover_effect': clickTable}" :style="this.params.colDef.cssStyle">
      <span @click="clickTable ? btnClickedHandler() : ''">
        {{normalizedValue}}
      </span>
    </div>
  `,
  computed: {
    normalizedValue () {
      if (this.params.value) {
        let value = this.params.value
        try {
          value = JSON.parse(this.params.value)
        } catch (error) {}

        if (value && Array.isArray(value)) {
          return value.map((item) => item.address).join(', ')
        }
        return value
      }

      return null
    }
  }
})
