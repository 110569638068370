import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import UserQueryRepositoryInterface from '../../../domain/repository/UserQueryRepositoryInterface'
import UserPasswordQuery from '../../query/UserPasswordQuery'

export default class UserPasswordHandler implements QueryHandlerInterface {
    private repo: UserQueryRepositoryInterface;

    constructor (repo: UserQueryRepositoryInterface) {
      this.repo = repo
    }

    execute (query: UserPasswordQuery): Promise<any> {
      return this.repo.getPassword()
    }
}
