import { FluxStandardAction } from '@/core/domain/type/types'
import Rule from '@/services/TaskEditor/domain/model/Rule'

export enum ActionTypes {
  GET_RULE_BY_GUID = 'get_rule_by_guid',
  GET_RULE_FIELD_TYPES = 'get_rule_field_types',
  CREATE_RULE = 'create_rule',
  UPDATE_RULE = 'update_rule',
  DELETE_RULE = 'delete_rule'
}

export class GetRuleByGuid implements FluxStandardAction {
  type = 'Rule/' + ActionTypes.GET_RULE_BY_GUID
  constructor (public guid: string) {}
}

export class GetRuleFieldTypes implements FluxStandardAction {
  type = 'Rule/' + ActionTypes.GET_RULE_FIELD_TYPES
  constructor () {}
}

export class CreateRule implements FluxStandardAction {
  type = 'Rule/' + ActionTypes.CREATE_RULE
  constructor (public payload: Rule) {}
}

export class UpdateRule implements FluxStandardAction {
  type = 'Rule/' + ActionTypes.UPDATE_RULE
  constructor (public payload: Rule) {}
}

export class DeleteRule implements FluxStandardAction {
  type = 'Rule/' + ActionTypes.DELETE_RULE
  constructor (public guid: string) {}
}
