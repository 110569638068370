
import { VueContext } from 'vue-context'
import { Loading } from 'element-ui'
import EditorTree from '@/core/infrastructure/components/EditorTree.vue'
import ModelTreeElementDeleteCommand from '@/services/DigitalTwinEditor/application/command/ModelTreeElementDeleteCommand'
import ModelTreeElementGroupCreateCommand from '@/services/DigitalTwinEditor/application/command/ModelTreeElementGroupCreateCommand'
import ModelTreeElementModelCreateCommand from '@/services/DigitalTwinEditor/application/command/ModelTreeElementModelCreateCommand'
import ModelByGuidQuery from '@/services/DigitalTwinEditor/application/query/ModelByGuidQuery'
import ModelGroupByGuidQuery from '@/services/DigitalTwinEditor/application/query/ModelGroupByGuidQuery'
import ModelTreeElementByGuidQuery from '@/services/DigitalTwinEditor/application/query/ModelTreeElementByGuidQuery'
import ModelTreeElementsQuery from '@/services/DigitalTwinEditor/application/query/ModelTreeElementsQuery'
import TreeElementType from '@/services/DigitalTwinEditor/domain/model/TreeElementType'
import ModelTreeElement, { ModelTreeElementDTO } from '@/services/DigitalTwinEditor/domain/model/Model/ModelTreeElement'
import { ModelDTO } from '@/services/DigitalTwinEditor/domain/model/Model/Model'
import { ModelGroupDTO } from '@/services/DigitalTwinEditor/domain/model/Model/ModelGroup'
import GroupForm from './GroupForm.vue'
import ModelForm from './ModelForm.vue'

export default {
  name: 'MainTree',
  components: {
    EditorTree,
    VueContext,
    GroupForm,
    ModelForm
  },
  props: [],
  data () {
    return {
      modelTreeNodeProps: {
        isLeaf: 'is_leaf',
        label: 'name',
        children: 'child'
      },
      elementBuilder: ModelTreeElement,
      selectedModelTreeElement: null,
      modelTreeElementDto: {},
      loadedModels: {},
      allModels: [],
      loadResolve: null,
      showCard: false,
      command: null
    }
  },  
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus', 'getTreeIcon', 'expandTreeNode'],
  provide() {
    return {
    }
  },
  watch: {   
    isModelGroupLoading: (state) => state,
    modelGroupCreated: function (location) {
      this.getQueryBus().execute(
        new ModelTreeElementByGuidQuery(
          location.replace('/model_tree_elements/', '')
        )
      ).then((elementDto) => {
        this.$refs.modelTree.addEditorElement(elementDto);
      });
    },
    modelTreeElementCreatedEvent: function (location) {
      this.getQueryBus().execute(
        new ModelTreeElementByGuidQuery(
          location.replace('/model_tree_elements/', '')
        )
      ).then((elementDto) => {
        this.$refs.modelTree.addEditorElement(elementDto);
        this.showEditPanel(elementDto);
      });
    }
  },
  computed: {
    console: () => console,
    isModelGroupLoading() {
      return this.$store.getters['ModelGroup/isLoading'];     
    },
    modelGroupCreated() {
      return this.$store.getters['ModelGroup/getLocation'];
    },
    modelTreeElementCreatedEvent() {
      return this.$store.getters['ModelTreeElement/getElementLocation'];
    }
  },
  methods: {
    debug (message) {
    },    
    addModelTreeElement(command) {
      if (command == 'add_model') {
        this.modelTreeElementDto = new ModelDTO({});
      } else if (command == 'add_group') {
        this.modelTreeElementDto = new ModelGroupDTO({});
      }
      this.showCard = true;
      this.command = command;
    },  
    editModelTreeElement() {
      if (this.selectedModelTreeElement == null) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      } else {
        this.showEditPanel(this.selectedModelTreeElement);
      } 
    },
    removeModelTreeElement() {
      if (this.selectedModelTreeElement == null) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      } else {
        this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
          confirmButtonText: this.$locale.main.button.delete,
          cancelButtonText: this.$locale.main.button.cancel,
          type: 'warning'
        }).then(async () => {
          this.getCommandBus().execute(
            new ModelTreeElementDeleteCommand(
              this.selectedModelTreeElement.guid
            )
          ).then((response) => {
            this.getEventBus().$emit('removeTreeElementByGuid', this.selectedModelTreeElement.element_guid);
            this.$refs.modelTree.remove(this.selectedModelTreeElement);               
            this.selectedModelTreeElement = null;     
          });
        }).catch((error) => { console.log(error); })
      }        
    },
    async loadModelTree(node, resolve) {
      this.loadResolve = resolve;
      this.getQueryBus().execute(
        new ModelTreeElementsQuery(
          ( node == null || node.level === 0 ) ? null : node.data.id
        )
      ).then((elements) => {
        resolve(elements);
      });
    },
    selectmodelTreeNode(elementDto) {
      this.selectedModelTreeElement = elementDto;
      this.showEditPanel(elementDto);      
    },
    showEditPanel(elementDto) {
      let query;
      if (elementDto.element_type == 'model_group') {
        query = new ModelGroupByGuidQuery(
          elementDto.element_guid
        )
      } else if (elementDto.element_type == 'model') {
        query = new ModelByGuidQuery(
          elementDto.element_guid
        )
      }
      this.getQueryBus().execute(
        query 
      ).then((resultDto) => {
        resultDto.parent_id = elementDto.parent_id;
        resultDto.element_type = elementDto.element_type;
        this.getEventBus().$emit('openEditForm', resultDto, elementDto);
      });
    },
    allowDrop(draggingNode, dropNode, type) {
      if (type == "inner") return !dropNode.data.is_leaf;
      return true;
    },
    allowDrag() {
      return this.allowNodeDrag;
    },
    nodeDrop (dragNode, dropNode, type) {

    },
    saveTreeElement () {
      let form;
      form = this.$refs.formParent.$refs.formChild;
      if (typeof form !== "undefined") {
        form.validate((valid, invalidFields) => {
          if (valid) {
            let command;
            let parentId = this.selectedModelTreeElement !== null ? this.selectedModelTreeElement.id : null;
            if (this.command == 'add_group') {
              command = new ModelTreeElementGroupCreateCommand(                
                this.modelTreeElementDto.name,
                this.modelTreeElementDto.alias,
                parentId
              )
            } else if (this.command == 'add_model') {
              command = new ModelTreeElementModelCreateCommand(                
                this.modelTreeElementDto.name,
                this.modelTreeElementDto.description,
                this.modelTreeElementDto.alias,
                this.modelTreeElementDto.coordinate_system_id,
                this.modelTreeElementDto.position,
                this.modelTreeElementDto.rotation,
                this.modelTreeElementDto.files,
                parentId
              )
            }
            this.getCommandBus().execute(command).then((response) => {
              this.showCard = false;
              this.modelTreeElementDto = {};
            });
          }
        });
      }
    }
  },
  mounted () {
  }
}
