import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { MappingDTO, MappingElementTypeDTO } from '@/services/EtlEditor/domain/model/Mapping'

export namespace MappingAPI {
  export class GetMappings implements APIRequest<MappingDTO | { count: number }> {
    response: MappingDTO | { count: number };
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (payload: string[][]) {
      this.path = `/v2/etleditor/mappings?${new URLSearchParams(payload).toString()}`
    }
  }

  export class GetMappingByGuid implements APIRequest<MappingDTO> {
    response: MappingDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/etleditor/mappings/${guid}`
    }
  }

  export class GetMappingElementTypes implements APIRequest<MappingElementTypeDTO> {
    response: MappingElementTypeDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor () {
      this.path = `/v2/etleditor/mapping_element_types`
    }
  }

  export class CreateMapping implements APIRequest<string> {
    response: string;
    path = '/v2/etleditor/mappings';
    method = HTTPMethod.POST;
    parse = (data: AxiosResponse) => data.headers.location
    constructor (public params: MappingDTO) {}
  }

  export class UpdateMapping implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.PUT;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: MappingDTO) {
      this.path = `/v2/etleditor/mappings/${params.guid}`
    }
  }

  export class DeleteMapping implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.DELETE;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/etleditor/mappings/${guid}`
    }
  }
}
