<template>
  <div>
    <span class="property_label" style="display: inline-block">Поле реестра</span>
    <select-field
      v-model="localValue.fieldId"
      class="extraLine_select"
      style="margin-top: 5px"
      :clearable="true"
      :standartTree="false"
      :dataTable="registryFields"
    />
  </div>
</template>
<script>
// Выбор поля реестра
import SelectField from '@/core/infrastructure/components/TreeSelect.vue'
export default {
  name: 'editor-bimmodel-viewer',
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          source: 'string',
          url: `${this.$config.api}/bimsurfer/`,
          fieldId: null
        }
      }
    },
    otherProperties: {
      type: Object
    },
    registryFields: {
      type: Array,
      default: () => []
    }
  },
  components: {
    SelectField
  },
  data () {
    return {
      localValue: this.value
    }
  },
  watch: {
    localValue: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    }
  },
  mounted () {
    if (this.otherProperties.url && this.localValue.url === null) {
      this.localValue.source = 'string'
      this.localValue.url = this.otherProperties.url
    }
  }
}
</script>

<style scoped></style>
