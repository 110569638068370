<template>
    <el-row>
        <el-col :span="8">
            <el-select size="small" v-model="data.type" placeholder="Select" @change="onChange">
                <el-option
                        v-for="item in items"
                        :key="item.type"
                        :label="item.name"
                        :value="item.type">
                </el-option>
            </el-select>
        </el-col>
        <el-col :span="16">
            <el-time-picker v-show="['eq','lt','gt'].includes(data.type)"
                            size="small"
                            value-format="HH:mm:ss"
                            format="HH:mm:ss"
                            v-model="data.value"
                            type="date"
                            @change="onChange"
                            placeholder="Pick a day">
            </el-time-picker>
            <el-time-picker
                    v-show="['bt'].includes(data.type)"
                    size="small"
                    value-format="HH:mm:ss"
                    format="HH:mm:ss"
                    @change="onChange"
                    v-model="data.value_range"
                    is-range
                    range-separator="-"
            >
            </el-time-picker>
        </el-col>
    </el-row>
</template>

<script>
import mixin from './mixin'
export default {
  mixins: [mixin],
  data () {
    return {
      data: {
        type: null,
        value: null,
        value_range: [
          '00:00:00', '23:59:59'
        ]
      },
      items: this.$locale.registry.filter.fields.time_field.types
    }
  },
  methods: {
    render () {
      if (this.value) {
        let properties = this.value.split(',')
        this.data.type = properties.shift()
        this.data.value = properties.join(',')
      }
    },
    onChange () {
      let value = null
      if (this.data.type && (this.data.value || this.data.value_range)) {
        switch (this.data.type) {
          case 'eq':case 'gt':case 'lt':
            value = `${this.data.type},${this.data.value}`
            break
          case 'bt':
            value = `${this.data.type},${this.data.value_range.join(',')}`
            break
        }
      }
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped>
    .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input__inner {
        width: 100%;
    }
</style>
