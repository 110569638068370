<template>
  <div>
    <el-button type="text" @click="dialogSettingsVisible = true">{{ $locale.main.button.edit }}</el-button>

    <el-dialog title="Настройки контекстного меню слоя" :modal="false"
               :visible.sync="dialogSettingsVisible"
               class="window">
      <el-row>
        <el-col :span="12">
          <div
            v-for="item of values"
            @click="activeItem = item"
            >
            <el-row
              :class="(activeItem && activeItem.name === item.name) ? 'context_menu_settings_item_selected' : 'context_menu_settings_item'"
            >
              <el-col :span="24">
                {{$locale.interface_editor.component_editor.map_context_layer_menu[item.name]}}
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="12">
          <div v-if="activeItem" style="padding: 10px;">
            <el-checkbox v-model="activeItem.hidden" label="Скрыть"></el-checkbox>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'MapLayerContextMenuSettings',
    props: ['other-properties', 'value', 'options'],
    data: function () {
      return {
        dialogSettingsVisible: false,
        values: this.value,
        activeItem: null
      }
    },
    watch: {
      values: {
        handler (value) {
          this.$emit('change', value)
        },
        deep: true
      }
    }
  }
</script>

<style scoped>
  .context_menu_settings_item {
    padding: 0 10px;
    cursor: pointer;
  }
  .context_menu_settings_item:hover {
    background-color: #e7e7e7;
    padding: 0 10px;
    cursor: pointer;
  }
  .context_menu_settings_item_selected {
    background-color: #cbcbcb;
    padding: 0 10px;
    cursor: pointer;
  }
</style>
