import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'

export namespace DataAPI {

  export class GetData implements APIRequest<[]> {
    response: [];
    path: string;
    method = HTTPMethod.POST;
    hideNotification = true;
    parse = (data: AxiosResponse) => data.data
    constructor (registryId: number, public params?: string[][]) {
      this.path = `/registryservice/registry/${registryId}`
    }
  }
}
