
// Основные настройки выпадающего списка
import Vue from 'vue'
import RegistrySelect from '@/components/Common/RegistrySelectTreeWithSearch.vue'
import SelectField from '@/core/infrastructure/components/TreeSelect.vue'
export default Vue.extend({
  name: 'editor-select-new',
  props: {
    value: {
      type: Object
    }
  },
  components: {
    RegistrySelect,
    SelectField
  },
  data () {
    return {
      localValue: this.value,
      listField: []
    }
  },
  methods: {
    async getFieldsRegistry (registryId: number): Promise<[]> {
      let data = await this.$http.get(`${this.$config.api}/objecteditor/entities?parent_id=${registryId}&show_children=true`)
      return data.data.data
    }
  },
  watch: {
    'value.registryId': {
      async handler (id) {
        if (id) {
          this.listField = await this.getFieldsRegistry(id)
        } else {
          this.listField = []
          this.localValue.groupBy = null
          this.localValue.fieldId = null
        }
      },
      immediate: true
    },
    localValue: {
      handler () {
        this.$emit('change', this.localValue)
      },
      deep: true
    }
  },
  mounted () {
    console.log(this.value)
  }
})
