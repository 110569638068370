<template>
  <div v-if="value.properties">
    <el-form>
      <el-form-item label="Мэпинг полей">
        <el-button @click="addItem" class="add-button" type="text">Добавить</el-button>
        <div v-for="(item, index) in value.properties.mapping">
          <el-select v-model="item.field">
            <el-option
              v-for="(item, index) in components.filter((cmp) => cmp.properties.name && cmp.initialType === 'registry/file_field')"
              :key="index"
              :label="`${item.name} (${item.properties.name})`"
              :value="item.properties.name"
            ></el-option>
          </el-select>
          <el-input type="text" v-model="item.property"></el-input>
          <el-button @click="removeItem(item, index)" type="text" style="margin: 0 13px;">Удалить</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  const DEFAULT_PROPERTY = {
    field: null,
    property: null
  }

  export default {
    name: 'ImportFeaturesEditor',
    inject: ['getComponents'],
    props: {
      value: {
        type: Object
      }
    },
    data () {
      return {
        components: []
      }
    },
    mounted () {
      if (!this.value.properties) {
        this.$set(this.value, 'properties', {})
      }
      if (!this.value.properties.mapping || !Array.isArray(this.value.propertiesMapping)) {
        this.$set(this.value.properties, 'mapping', [])
      }
      this.components = this.getComponents()
    },
    methods: {
      addItem () {
        this.value.properties.mapping.splice(this.value.properties.mapping, 0, JSON.parse(JSON.stringify(DEFAULT_PROPERTY)))
      },
      removeItem (item, index) {
        this.value.properties.mapping.splice(index, 1)
      }
    }
  }
</script>

<style scoped>

</style>
