<template>
  <div>
    <el-tree
      node-key="guid"
      ref="tree"
      lazy
      :load="loadNode"
      :props="properties"
      :expand-on-click-node="false"
      :show-checkbox="true"
      :render-after-expand="false"
      :default-expand-all="expandLayersByDefault"
      :default-checked-keys="defaultCheckedKeys"
      :filter-node-method="filterTreeHandler"
      v-loading="isLoading"
      @node-click="layerClick"
      @check-change="onCheckChange"
    >
      <span slot-scope="{ node, data }" style="flex: 1; min-width: 0; display: contents;" @contextmenu.prevent="showContextMenu($event, data, node)">
        <LayersIcon v-if="data.properties.iconStyleTypeId != null" :type="data.properties.iconStyleTypeId" :properties="data.properties.style[`${data.properties.iconStyleTypeId}`]" style="width: 15px; height: 15px; min-width: 15px;"/>
        <span :style="data.type === 'layer' ? (node.checked ? 'width: calc(100% - 70px)' : 'width: calc(100% - 15px)') : ''" :class="{'label' : true, 'active': data.guid === activeLayerGuid}" style="margin-left: 2px; display: inline-block;">
          {{ node.label }}
        </span>
        <i class="el-icon-loading" v-if="data.isLoading"></i>
        <span v-if="data.totalFeatures && node.checked" style="margin-right: 5px;">[{{data.totalFeatures}}]</span>
        <el-slider
          v-if="showLayersOpacity && data.type === 'layer' && node.checked && data.isLoading === false"
          v-model="data.properties.opacity"
          :min="0"
          :max="100"
          class="layer-opacity-slider"
          @change="changeTreeItemOpacity(data, data.properties.opacity)"
        ></el-slider>
      </span>
    </el-tree>
    <vue-context
      :use-scroll-height="true"
      :useScrollWidth="true"
      class="layer-context"
      ref="context-menu"
      v-slot="{ data }">
      <div
        v-for="item in contextMenuSettings.filter(x => !x.hidden)"
        class="el-dropdown-menu__item"
        @click="contextMenuAction(item.name, data)">
        <i class="el-icon-star-on"></i>{{$locale.interface_editor.component_editor.map_context_layer_menu[item.name]}}
      </div>

    </vue-context>
  </div>
</template>

<script>
import LayersIcon from '@/core/infrastructure/components/LayerIcon'
import { VueContext } from 'vue-context'
import 'vue-context/src/sass/vue-context.scss'

export default {
  name: 'LayersTree',
  components: { LayersIcon, VueContext },
  inject: {
    isEditor: {
      default: () => false
    },
  },
  props: {
    layers: {
      type: Array,
      default: () => []
    },
    expandLayersByDefault: {
      type: Boolean,
      default: false
    },
    showLayersOpacity: {
      type: Boolean,
      default: false
    },
    searchText: {
      type: String,
      default: null
    },
    isFullscreen: {
      type: Boolean,
      default: false
    },
    hideLayers: {
      type: Array,
      default: () => {
        return []
      }
    },
    activeLayerGuid: {
      type: String | null,
      default: null
    },
    contextMenuSettings: {
      type: Array
    }
  },
  data () {
    return {
      colors: [
        '#F59300',
        '#06A6E0',
        '#009945'
      ],
      properties: {
        label: 'name',
        isLeaf: 'leaf',
        children: 'children1'
      },
      contextMenuItem: null,
      hideAndUnchecked: [],
      defaultCheckedKeys: [],
      isLoading: false
    }
  },
  watch: {
    searchText () {
      this.filterTree()
    },
    hideLayers: {
      handler: function () {
        this.filterTree()
      },
      deep: true
    }
  },
  mounted () {
    //this.enableDefaultLayers()
  },
  methods: {
    setLoading (value) {
      this.$set(this, 'isLoading', value)
    },
    loadNode (node, resolve) {
      //console.log('t', this)
      //this.setLoading(true)
      node.loading = true
      if (node.level === 0) {
        resolve(this.sseparateLayers(this.layers))
      } else {
        if (node.data.type === 'group') {
          const a = this.sseparateLayers(node.data.children)
          resolve(a)
        }
      }
      node.loading = false
      //this.setLoading(false)
    },
    actualizeInteractiveStructure (layer) {
      if (layer.properties.interactive.card) {
        if (!layer.properties.interactive.card.card) {
          layer.properties.interactive.card.card = {}
          if (Object.keys(layer.properties.interactive.card.card).length === 0) {
            let tmp = JSON.parse(JSON.stringify(layer.properties.interactive.card))
            delete tmp.card
            layer.properties.interactive.card.card = tmp
            layer.properties.interactive.card.type = 'open_card'
          }
        }
      }
      return layer
    },
    sseparateLayers (items) {
      for (let i = 0; i < items.length; i++) {
        const newTreeItem = this.separateLayers(items[i])
        items.splice(i, 1, newTreeItem)
        this.$emit(
          'layers-loaded',
          [{
              guid: newTreeItem.guid,
              name: newTreeItem.name,
              type: newTreeItem.type,
              properties: newTreeItem.properties
          }]
        )
      }
      return items
    },
    separateLayers (item) {
      //console.log('i', item)
      if (item.type === 'group') {
        //item.children = item.children.map(child => this.separateLayers(child))
      } else if (item.type === 'layer') {
        item = this.actualizeInteractiveStructure(item)
        if (
          !this.isEditor() &&
          item.properties.separation.type === 'rules' &&
          Array.isArray(item.properties.separation.rules) &&
          item.properties.separation.rules.length > 0 &&
          item.properties.separation.enable !== false) {
          item = this.separateLayer(item)
        }
      }
      //item.disabled = false
      //console.log(item.name, 'loaded')
      return item
    },
    separateLayer (layer) {
      // не настроены правила деления
      if (layer.properties.separation.type !== 'rules' || layer.properties.separation.rules.length === 0) {
        return layer
      }
      // уже был разделен
      if (typeof layer.properties.separation.rule === 'object'
        && Object.keys(layer.properties.separation.rule).length > 0) {
        return layer
      }
      let group = {
        guid: layer.guid,
        isVisible: true,
        leaf: false,
        name: layer.name,
        properties: {},
        type: 'group',
        children: []
      }
      let localSeparatedLayers = []

      layer.properties.separation.rules.forEach((rule) => {
        const guid = this.generateGuid()
        localSeparatedLayers.push(guid)
        let newLayer = JSON.parse(JSON.stringify(layer))
        newLayer.properties.separation.rule = rule
        newLayer.guid = guid
        newLayer.name = rule.name
        newLayer.properties.style = rule.style
        newLayer.leaf = true
        newLayer.type = 'layer'
        newLayer.isLoading = false
        group.children.push(newLayer)
      })

      this.$emit('layer-separated', layer.guid, localSeparatedLayers)
      //this.separatedLayers[layer.guid] = localSeparatedLayers
      return group
    },
    filterTree() {
      this.$nextTick(() => this.$refs['tree'].filter(this.searchText))
    },
    getInterfaceEditorVersion() {
      let version = 1
      let parent = this
      while (parent.$parent) {
        if (parent.$options['_componentTag'] === 'InterfaceViewerV2') {
          version = 2
        }
        parent = parent.$parent
      }
      return version
    },
    focusOnLayer (layer) {
      this.$emit('focus-on-layer', layer)
    },
    calculateFeatures (layer) {
      this.$emit('calculate-features', layer)
    },
    exportLayer (layer) {
      this.$emit('export-layer', layer)
    },
    showContextMenu (event, layer) {
      if (this.contextMenuSettings.filter(x => !x.hidden).length === 0) {
        return
      }
      let customEvent = {}
      if (this.getInterfaceEditorVersion() === 1) {
        customEvent.clientX = event.layerX
        customEvent.clientY = event.layerY
        customEvent.target = event.target
      } else {
        customEvent = event
      }
      this.$refs['context-menu'].open(this.isFullscreen ? event : customEvent, layer)
    },
    contextMenuAction(itemName, data) {
      const methodName = this.toCamelCase(itemName)
      this[methodName](data)
    },
    filterTreeHandler (value, data) {
      // если нужно скрывать - скрываем
      if (this.hideLayers.includes(data.guid)) {
        return false
      }
      return value !== null ? data.name.indexOf(value) !== -1 : true
    },
    changeTreeItemOpacity (treeItem, newOpacity) {
      if (treeItem.type === 'layer') {
        this.$emit('change-layer-opacity', treeItem, newOpacity)
      } else {
        treeItem.children.forEach((child) => {
          this.changeTreeItemOpacity(child, newOpacity)
        })
      }
    },
    async setCheckOnLayer (layerGuid, isCheck) {
      const path = this.findLayerPathOnLayerTreeByGuid(this.layers, layerGuid)
      if (Array.isArray(path)) {
        for (let i = 0; i < path.length; i++) {
          const node = this.$refs['tree'].getNode(path[i])
          await node.loadData()
        }
      }

      this.$refs.tree.setChecked(layerGuid, isCheck)
    },
    findLayerPathOnLayerTreeByGuid (layers, guid, path) {
      //console.log('all', layers)
      if (path === undefined) {
        path = []
      }
      for (let i = 0; i < layers.length; i++) {
        const layer = layers[i]
        //console.log(layer)
        if (layer.guid === guid) {
          //console.log('найден', guid, path)
          return path
        } else {
          if (layer.type === 'group'
            && layer.children
            && Array.isArray(layer.children)
            && layer.children.length > 0
          ) {
            path.push(layer.guid)
            const result = this.findLayerPathOnLayerTreeByGuid(layer.children, guid, path)
            if (result === false) {
              path.pop()
            } else {
              return result
            }
          }
        }
      }
      return false
    },
    /*
    getDefaultLayers (array = []) {
      let result = []
      array.forEach((item) => {
        if (item.properties.showByDefault) {
          result.push(item.guid)
          if (item.properties.isActiveByDefault) {
            this.activeLayerGuid = item.guid
          }
        }
        if ((item.children || []).length > 0) {
          result.push(...this.getDefaultLayers(item.children))
        }
      })

      return result
    },*/
    layerClick (layer, node) {
      if (node.checked && layer.properties.isEditable) {
        this.$emit('click-on-layer', layer.guid)
      }
    },
    onCheckChange (data, checked, indeterminate) {
      //console.log(data, checked, indeterminate)
      if (indeterminate) {
        return
      }
      //this.setLoading(true)
      //console.log('t', this)
      //console.log(this.$refs['tree'])
      const node = this.$refs['tree'].getNode(data)
      //console.log('n', node)

      //this.$refs['tree'].getNode(data).store.load(this.$refs['tree'].getNode(data))
      node.loadData()

      this.$nextTick(() => {
        //node.loading = false
        if (checked) {
          this.$refs['tree'].setChecked(data.guid, true, true)
        }
      })
      if (data.type === 'layer') {
        if (!checked && data.guid === this.activeLayerGuid) {
          this.$emit('click-on-layer', this.activeLayerGuid)
          //this.activeLayerGuid = null
        }
        if (!checked) {
          delete(data.totalFeatures)
        }
        this.$emit('layer-select', data, checked)
      }
      //node.loading = false
      //this.setLoading(false)
      //this.isLoading = false
    },
    setCheckedLayerGuids (checkedGuids) {
      this.$nextTick(() => {
        this.$refs['tree'].setCheckedKeys(checkedGuids)
      })
    },
    getCheckedKeys() {
      return this.$refs['tree'].getCheckedKeys(false)
    }
  }
}
</script>

<style scoped>
/deep/ .el-tree-node__children{
  border-left: 2px solid #4FA9F3;
  margin-left: 5px;
  /* padding-left: 10px; */
  /* box-shadow: 4px 1px 0px -3px rgba(27, 141, 229, 1) inset; */
}
/deep/ .el-tree-node.is-current{
  background-color: #EEF0F1;
  background-color: rgb(245 247 250);
}
/deep/ .el-tree-node.is-focusable.is-expanded{
  background-color: transparent;
}
/deep/ .el-tree-node {
  overflow: hidden;
}
.layer-context {
  padding: 0;
}
.layer-opacity-slider {
  width: 30px;
  position: relative;
  margin-right: 7px;
  display: inline-block;
}
.layer-opacity-slider /deep/ .el-slider__runway {
  height: 3px;
}
.layer-opacity-slider /deep/ .el-slider__bar {
  height: 3px;
}
.layer-opacity-slider /deep/ .el-slider__button {
  width: 8px;
  height: 8px;
}
.layer-opacity-slider /deep/ .el-slider__button-wrapper {
  top: -16px;
}
.label {
  color: #2D3239;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.active {
  border-bottom: 1px dashed black;
}
.dot {
  vertical-align: text-top;
  width: 10px;
  display: inline-block;
  height: 10px;
  border-radius: 10px;
  margin-right: 5px;
}
.loading-icon {

}
</style>
