export default class SetTerrainCommand
{
    private guid: string;

    constructor(guid: string)
    {
        this.guid = guid;
    }

    getGuid(): string
    {
        return this.guid;
    }

    getClassName(): string
    {
        return 'SetTerrainCommand';
    }
}