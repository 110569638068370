import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { ReleaseDTO } from '@/services/ReleaseEditor/domain/model/Release/Release'

export enum ActionTypes {
    ADD_RELEASE = 'add_release',
    UPDATE_RELEASE = 'update_release',
    DELETE_RELEASE = 'delete_release',
    GET_RELEASES = 'get_releases',
    COUNT_RELEASES = 'count_releases',
    GET_RELEASE_BY_GUID = 'get_release_by_guid'
}

export class AddRelease implements FluxStandardAction {
    type = 'Release/' + ActionTypes.ADD_RELEASE
    constructor(public payload: ReleaseDTO){}
}

export class UpdateRelease implements FluxStandardAction {
    type = 'Release/' + ActionTypes.UPDATE_RELEASE
    constructor(public payload: ReleaseDTO){}
}

export class DeleteRelease implements FluxStandardAction {
    type = 'Release/' + ActionTypes.DELETE_RELEASE
    constructor(public guid: string){}
}

export class GetReleases implements FluxStandardAction {
    type = 'Release/' + ActionTypes.GET_RELEASES
    constructor(public payload: object){}
}

export class CountReleases implements FluxStandardAction {
    type = 'Release/' + ActionTypes.COUNT_RELEASES
    constructor(public payload: object){}
}

export class GetReleaseByGuid implements FluxStandardAction {
    type = 'Release/' + ActionTypes.GET_RELEASE_BY_GUID
    constructor(public guid: string){}
}