import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    ROLE_LOADING_EVENT = 'role_loading_event',
    ROLE_LOADED_EVENT = 'role_loaded_event',
    ROLE_CREATED_EVENT = 'role_created_event',
    ROLE_UPDATED_EVENT = 'role_updated_event',
    ROLE_DELETED_EVENT = 'role_deleted_event'
}

export class RoleLoadingEvent implements FluxStandardAction {
    type = MutationTypes.ROLE_LOADING_EVENT
}

export class RoleLoadedEvent implements FluxStandardAction {
    type = MutationTypes.ROLE_LOADED_EVENT
}

export class RoleCreatedEvent implements FluxStandardAction {
    type = MutationTypes.ROLE_CREATED_EVENT
    constructor (public location: string) {}
}

export class RoleUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.ROLE_UPDATED_EVENT
}

export class RoleDeletedEvent implements FluxStandardAction {
    type = MutationTypes.ROLE_DELETED_EVENT
    constructor (public guid: string) {}
}
