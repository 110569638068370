<template>
    <span v-if="error">{{ error }}</span>
</template>

<script>
export default {
  props: {
    providerId: {
      type: String,
      default: null
    }
  },
  name: 'oauth',
  data () {
    return {
      code: this.$route.query.code
    }
  },
  computed: {
    error () {
      let errors = []
      if (!this.providerId) {
        errors.push(this.$locale.login.errors.oauth_provider_id_required)
      }
      if (!this.code) {
        errors.push(this.$locale.login.errors.oauth_code_required)
      }

      return errors.join('\r\n')
    }
  },
  mounted () {
    if (!this.error) {
      this.login()
    }
  },
  methods: {
    login () {
      this.$store.dispatch(`Authorization/OAuthLogin`, { providerId: this.providerId, code: this.code })
        .then((res) => {
          this.$router.push('/')
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style scoped>

</style>
