import { MutationTree } from 'vuex'
import { StateState, initialState } from './state'
import {
  MutationTypes,
  StateCreatedEvent,
  StateUpdatedEvent,
  StateDeletedEvent
} from './mutation-types'

export const mutations: MutationTree<StateState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false
    state.error = error
  },
  [MutationTypes.STATE_LOADING_EVENT]: (state) => {
    state.isLoading = true
  },
  [MutationTypes.STATE_LOADED_EVENT]: (state) => {
    state.isLoading = false
  },
  [MutationTypes.STATE_CREATED_EVENT]: (state, action: StateCreatedEvent) => {
    state.isLoading = false
    state.location = action.location
  },
  [MutationTypes.STATE_UPDATED_EVENT]: (state, action: StateUpdatedEvent) => {
    state.isLoading = false
  },
  [MutationTypes.STATE_DELETED_EVENT]: (state, action: StateDeletedEvent) => {
    state.isLoading = false
    state.deletedStateGuid = action.guid
  }
}
