import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import OpenWorldQueryRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/OpenWorldQueryRepositoryInterface'
import store from '@/store'
import { GetOpenWorldByGuid, GetOpenWorldGeojson, GetOpenWorldStyle } from '@/services/DigitalTwinEditor/infrastructure/store/modules/OpenWorld/action-types'

export default class OpenWorldQueryRepository extends AbstractQueryRepository implements OpenWorldQueryRepositoryInterface
{
    getByGuid(guid: string): Promise<any>
    {
        return store.dispatch(new GetOpenWorldByGuid(guid));
    }

    getOpenWorldGeojson(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new GetOpenWorldGeojson(payload));
    }

    getStyle(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new GetOpenWorldStyle(payload));
    }
}