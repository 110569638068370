import { MutationTree } from 'vuex'
import { LayerGroupState, initialState } from './state'
import { MutationTypes, LayerGroupCreatedEvent, LayerGroupUpdatedEvent } from './mutation-types'

export const mutations: MutationTree<LayerGroupState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false;
    state.error = error;    
  },
  [MutationTypes.LAYER_GROUP_LOADING_EVENT]: (state) => {
    state.isLoading = true;
  },
  [MutationTypes.LAYER_GROUP_LOADED_EVENT]: (state) => {
    state.isLoading = false;
  },
  [MutationTypes.LAYER_GROUP_CREATED_EVENT]: (state, action: LayerGroupCreatedEvent) => {
    state.location = action.location;
    state.isLoading = false;
  },
  [MutationTypes.LAYER_GROUP_UPDATED_EVENT]: (state, action: LayerGroupUpdatedEvent) => {
    state.isLoading = false;
  }
}