import StrokeStyleCommandRepositoryInterface from '@/core/domain/repository/modules/StrokeStyleCommandRepositoryInterface'
import { StrokeStyleDTO } from '@/core/domain/model/modules/StrokeStyle'
import store from '@/store'
import { AddStrokeStyle, UpdateStrokeStyle, DeleteStrokeStyle } from '@/core/infrastructure/store/modules/StrokeStyle/action-types'

export default class StrokeStyleCommandRepository implements StrokeStyleCommandRepositoryInterface
{
    insert(item: StrokeStyleDTO): Promise<void>
    {
        return store.dispatch(new AddStrokeStyle(item));
    }

    update(item: StrokeStyleDTO): Promise<void>
    {
        return store.dispatch(new UpdateStrokeStyle(item));
    }

    delete(guid: string): Promise<void>
    {
        return store.dispatch(new DeleteStrokeStyle(guid));
    }
}
