import { FluxStandardAction, Nullable } from '@/core/domain/type/types'

export enum ActionTypes {
  GET_MIGRATIONS = 'get_migrations',
  COUNT_MIGRATIONS = 'count_migrations'
}

export class GetMigrations implements FluxStandardAction {
  type = 'Migration/' + ActionTypes.GET_MIGRATIONS;
  constructor (public payload: object) {
  }
}

export class CountMigrations implements FluxStandardAction {
  type = 'Migration/' + ActionTypes.COUNT_MIGRATIONS
  constructor (public payload: object) {
  }
}
