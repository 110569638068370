import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import RuleGroup from '@/services/TaskEditor/domain/model/RuleGroup'

export namespace RuleGroupAPI {
  export class GetRuleGroupByGuid implements APIRequest<RuleGroup> {
    response: RuleGroup;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => new RuleGroup(data.data)
    constructor (guid: string) {
      this.path = `/taskeditor/rule_groups/${guid}`
    }
  }

  export class CreateRuleGroup implements APIRequest<string> {
    response: string;
    path = '/taskeditor/rule_groups';
    method = HTTPMethod.POST;
    parse = (data: AxiosResponse) => data.headers.location
    constructor (public params: RuleGroup) {}
  }

  export class UpdateRuleGroup implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.PUT;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: RuleGroup) {
      this.path = `/taskeditor/rule_groups/${params.guid}`
    }
  }

  export class DeleteRuleGroup implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.DELETE;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/taskeditor/rule_groups/${guid}`
    }
  }
}
