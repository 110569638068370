import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { AffineTransformationDTO } from '@/services/MapEditor/domain/model/Geometry/AffineTransformation'

export namespace AffineTransformationAPI {

    export class AddAffineTransformation implements APIRequest<string> {
        response: string;
        path = '/mapeditor/affine_transformations';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.headers.location
        constructor(public params: AffineTransformationDTO) {}
    }

    export class UpdateAffineTransformation implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: AffineTransformationDTO) {
            this.path = `/mapeditor/affine_transformations/${params.guid}`;
        }
    }

    export class DeleteAffineTransformation implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.DELETE;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/mapeditor/affine_transformations/${guid}`;
        }
    }

    export class CountAffineTransformations implements APIRequest<AffineTransformationDTO> {
        response: AffineTransformationDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/mapeditor/affine_transformations?*[func]=count'
        }
    }

    export class GetAffineTransformations implements APIRequest<AffineTransformationDTO> {
        response: AffineTransformationDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/mapeditor/affine_transformations?' +  (new URLSearchParams(payload).toString())
        }
    }

    export class GetAffineTransformationByGuid implements APIRequest<AffineTransformationDTO> {
        response: AffineTransformationDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/mapeditor/affine_transformations/${guid}`;
        }
    }
}