<template>
  <el-row>
    <!-- <span class="property_label">{{$locale.interface_editor.component_editor.plugins.plugin}}</span> -->
    <el-select
      v-model="localModel"
      filterable
      clearable
      @change="onChange">
      <el-option
        v-for="(item, index) in options"
        :key="index"
        :label="item.name"
        :value="item.id">
      </el-option>
    </el-select>
    <!-- <div v-show="!withoutPostfix">
      <span class="property_label">Постфикс</span>
      <el-input v-model="postfix" @change="onChange"></el-input>
    </div> -->
  </el-row>
</template>

<script>
// API
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { PluginAPI } from '@/services/LogicEditor/infrastructure/api/PluginAPI'

export default {
  name: 'editor-plugins',
  props: {
    value: {
      type: String,
      default: null
    },
    withoutPostfix: {
      type: Boolean
    }
  },
  data () {
    return {
      localModel: (this.value || '').split('/')[0],
      options: [],
      postfix: (this.value || '').split('/')[1]
    }
  },
  async mounted () {
    try {
      let response = await APIClient.shared.request(new PluginAPI.GetPlugins())
      this.options = response
    } catch (error) {
      console.log('editor-plugins', error)
    }
  },
  methods: {
    onChange (value) {
      this.$emit('change', this.localModel + (this.postfix ? '/' + this.postfix : ''))
    }
  }
}
</script>

<style scoped>

</style>
