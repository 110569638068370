import QueryInterface from './QueryInterface'
import { Nullable } from '@/core/domain/type/types'

export default abstract class AbstractEntitiesTreeQuery implements QueryInterface
{
    private parentId: Nullable<number>;

    constructor(parentId: Nullable<number>)
    {
        this.parentId = parentId;
    }

    getParentId(): Nullable<number>
    {
        return this.parentId;
    }

    abstract getClassName(): string;
}