import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import DagRepositoryInterface from '@/services/Databus/domain/repository/DagRepositoryInterface'
import store from '@/store'
import { GetDags, RunDag } from '@/services/Databus/infrastructure/store/modules/Dag/action-types'
import { DagDTO } from '@/services/Databus/domain/model/Dag/Dag'

export default class DagQueryRepository extends AbstractQueryRepository implements DagRepositoryInterface
{
    get(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new GetDags(payload));
    }

    run(item: DagDTO): Promise<void>
    {
        return store.dispatch(new RunDag(item));
    }
}