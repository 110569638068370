import CommandHandlerInterface from '../CommandHandlerInterface'
import CommandInterface from '../CommandInterface'
import CommandHandlerMap from '../CommandHandlerMap'
import { Nullable } from '@/core/domain/type/types'

export default class CommandBusHandlerLocator
{
    private handlers: object;

    constructor(handlers: object) {
        this.handlers = handlers;
    }

    getHandler(command: CommandInterface): Nullable<CommandHandlerInterface>
    {
        return this.handlers[command.getClassName()];
    }
}