import { FluxStandardAction } from '@/core/domain/type/types'
import { CssClassDTO } from '@/services/CssEditor/domain/model/CssClass'

export enum ActionTypes {
  GET_CSS_CLASS = 'get_css_class',
  GET_CSS_CLASS_TREE = 'get_css_class_tree',
  GET_CSS_CLASS_SEARCH = 'get_css_class_search',
  GET_CSS_CLASS_BY_GUID = 'get_css_class_by_guid',
  GET_CSS_CLASS_TREE_BY_GUID = 'get_css_class_tree_by_guid',
  CREATE_CSS_CLASS = 'create_css_class',
  UPDATE_CSS_CLASS = 'update_css_class',
  DELETE_CSS_CLASS = 'delete_css_class'
}

export class GetCssClassesTree implements FluxStandardAction {
  type = 'CssClass/' + ActionTypes.GET_CSS_CLASS_TREE
  constructor (public payload: object) {}
}

export class GetCssClassesSearch implements FluxStandardAction {
  type = 'CssClass/' + ActionTypes.GET_CSS_CLASS_SEARCH
  constructor (public payload: object) {}
}

export class GetCssClassTreeByGuid implements FluxStandardAction {
  type = 'CssClass/' + ActionTypes.GET_CSS_CLASS_TREE_BY_GUID
  constructor (public guid: string) {}
}

export class GetCssClasses implements FluxStandardAction {
  type = 'CssClass/' + ActionTypes.GET_CSS_CLASS
  constructor (public payload: object) {}
}

export class GetCssClassByGuid implements FluxStandardAction {
  type = 'CssClass/' + ActionTypes.GET_CSS_CLASS_BY_GUID
  constructor (public guid: string) {}
}

export class CreateCssClass implements FluxStandardAction {
  type = 'CssClass/' + ActionTypes.CREATE_CSS_CLASS
  constructor (public payload: CssClassDTO) {}
}

export class UpdateCssClass implements FluxStandardAction {
  type = 'CssClass/' + ActionTypes.UPDATE_CSS_CLASS
  constructor (public payload: CssClassDTO) {}
}

export class DeleteCssClass implements FluxStandardAction {
  type = 'CssClass/' + ActionTypes.DELETE_CSS_CLASS
  constructor (public guid: string) {}
}
