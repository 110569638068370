import { ActionTree } from 'vuex'
import { ListenerState, initialState } from '@/services/LogicEditor/infrastructure/store/modules/Listener/state'
import { ActionTypes } from './action-types'
import {
  MutationTypes,
  ListenerUpdatedEvent,
  ListenerCreatedEvent,
  ListenerLoadingEvent,
  ListenerDeletedEvent,
  ListenerLoadedEvent
} from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { ListenerAPI } from '@/services/LogicEditor/infrastructure/api/ListenerAPI'

export const actions: ActionTree<ListenerState, any> = {
  [ActionTypes.GET_LISTENER_BY_GUID]: async ({ commit, dispatch }, data) => {
    commit(new ListenerLoadingEvent())

    return APIClient.shared.request(new ListenerAPI.GetListenerByGuid(data.guid))
      .then((response) => {
        commit(new ListenerLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.CREATE_LISTENER]: async ({ commit, dispatch }, data) => {
    commit(new ListenerLoadingEvent())

    return APIClient.shared.request(new ListenerAPI.CreateListener(data.payload))
      .then((location) => {
        commit(new ListenerCreatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.UPDATE_LISTENER]: async ({ commit, dispatch }, data) => {
    commit(new ListenerLoadingEvent())

    return APIClient.shared.request(new ListenerAPI.UpdateListener(data.payload))
      .then((location) => {
        commit(new ListenerUpdatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.DELETE_LISTENER]: async ({ commit, dispatch }, data) => {
    commit(new ListenerLoadingEvent())

    return APIClient.shared.request(new ListenerAPI.DeleteListener(data.guid))
      .then(() => {
        commit(new ListenerDeletedEvent(data.guid))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  }
}
