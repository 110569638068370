export interface IThemeDTO {
  id?: number;
  guid?: string;
  name: string;
  description: string|null;
  alias: string|null;
  properties: {
    [variable: string]: any;
  };
}

export const defaultProperties = {
  '$--color-primary': '#409EFF',
  '$--color-white': '#FFFFFF',
  '$--color-black': '#000000',
  '$--color-success': '#67C23A',
  '$--color-warning': '#E6A23C',
  '$--color-danger': '#F56C6C',
  '$--color-info': '#909399',
  '$--color-text-primary': '#303133',
  '$--color-text-regular': '#606266',
  '$--color-text-secondary': '#909399',
  '$--color-text-placeholder': '#C0C4CC',
  '$--border-color-base': '#DCDFE6',
  '$--border-color-light': '#E4E7ED',
  '$--border-color-lighter': '#EBEEF5',
  '$--border-color-extra-light': '#F2F6FC',
  '$--background-color-base': '#F5F7FA',
  '$--border-radius-base': '4px',
  '$--border-radius-small': '2px',
  '$--border-radius-circle': '100%',
  '$--border-radius-zero': '0',
  '$--box-shadow-base': '0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04)',
  '$--box-shadow-light': '0 2px 12px 0 rgba(0, 0, 0, 0.1)',
  '$--font-size-extra-large': '20px',
  '$--font-size-large': '18px',
  '$--font-size-medium': '16px',
  '$--font-size-base': '14px',
  '$--font-size-small': '13px',
  '$--font-size-extra-small': '12px',
  '$--font-weight-primary': '500',
  '$--font-weight-secondary': '100',
  '$--font-line-height-primary': '24px',
  '$--font-line-height-secondary': '16px',
  '$--checkbox-font-size': '14px',
  '$--checkbox-font-weight': '$--font-weight-primary',
  '$--checkbox-font-color': '$--color-text-regular',
  '$--checkbox-border-radius': '$--border-radius-small',
  '$--checkbox-background-color': '$--color-white',
  '$--checkbox-disabled-border-color': '$--border-color-base',
  '$--checkbox-checked-font-color': '$--color-primary',
  '$--checkbox-checked-background-color': '$--color-primary',
  '$--checkbox-bordered-height': '40px',
  '$--checkbox-bordered-padding': '9px 20px 9px 10px',
  '$--checkbox-bordered-medium-padding': '7px 20px 7px 10px',
  '$--checkbox-bordered-small-padding': '5px 15px 5px 10px',
  '$--checkbox-bordered-mini-padding': '3px 15px 3px 10px',
  '$--checkbox-bordered-medium-height': '36px',
  '$--checkbox-bordered-small-height': '32px',
  '$--checkbox-bordered-mini-height': '28px',
  '$--checkbox-button-checked-background-color': '$--color-primary',
  '$--checkbox-button-checked-font-color': '$--color-white',
  '$--checkbox-button-checked-border-color': '$--color-primary',
  '$--radio-font-size': '$--font-size-base',
  '$--radio-font-weight': '$--font-weight-primary',
  '$--radio-font-color': '$--color-text-regular',
  '$--radio-input-border-radius': '$--border-radius-circle',
  '$--radio-input-background-color': '$--color-white',
  '$--radio-input-border-color': '$--border-color-base',
  '$--radio-icon-color': '$--color-white',
  '$--radio-checked-font-color': '$--color-primary',
  '$--radio-checked-input-border-color': '$--color-primary',
  '$--radio-checked-input-background-color': '$--color-white',
  '$--radio-checked-icon-color': '$--color-primary',
  '$--radio-button-font-size': '$--font-size-base',
  '$--radio-button-checked-background-color': '$--color-primary',
  '$--radio-button-checked-font-color': '$--color-white',
  '$--radio-button-checked-border-color': '$--color-primary',
  '$--select-font-size': '$--font-size-base',
  '$--select-input-focus-border-color': '$--color-primary',
  '$--select-input-font-size': '14px',
  '$--select-option-height': '34px',
  '$--select-option-selected-font-color': '$--color-primary',
  '$--select-dropdown-max-height': '274px',
  '$--alert-border-radius': '$--border-radius-base',
  '$--alert-title-font-size': '13px',
  '$--alert-description-font-size': '12px',
  '$--alert-close-font-size': '12px',
  '$--alert-close-customed-font-size': '13px',
  '$--alert-icon-size': '16px',
  '$--alert-icon-large-size': '28px',
  '$--messagebox-title-color': '$--color-text-primary',
  '$--messagebox-font-size': '$--font-size-large',
  '$--messagebox-content-font-size': '$--font-size-base',
  '$--messagebox-content-color': '$--color-text-regular',
  '$--messagebox-error-font-size': '12px',
  '$--messagebox-success-color': '$--color-success',
  '$--messagebox-info-color': '$--color-info',
  '$--messagebox-warning-color': '$--color-warning',
  '$--messagebox-danger-color': '$--color-danger',
  '$--message-close-icon-color': '$--color-text-placeholder',
  '$--message-close-size': '16px',
  '$--message-close-hover-color': '$--color-text-secondary',
  '$--message-success-font-color': '$--color-success',
  '$--message-info-font-color': '$--color-info',
  '$--message-warning-font-color': '$--color-warning',
  '$--message-danger-font-color': '$--color-danger',
  '$--notification-padding': '14px 26px 14px 13px',
  '$--notification-border-color': '$--border-color-lighter',
  '$--notification-content-font-size': '$--font-size-base',
  '$--notification-content-color': '$--color-text-regular',
  '$--notification-title-font-size': '16px',
  '$--notification-title-color': '$--color-text-primary',
  '$--notification-close-color': '$--color-text-secondary',
  '$--notification-close-hover-color': '$--color-text-regular',
  '$--notification-success-icon-color': '$--color-success',
  '$--notification-info-icon-color': '$--color-info',
  '$--notification-warning-icon-color': '$--color-warning',
  '$--notification-danger-icon-color': '$--color-danger',
  '$--input-font-color': '$--color-text-regular',
  '$--input-height': '40px',
  '$--input-border-radius': '$--border-radius-base',
  '$--input-background-color': '$--color-white',
  '$--input-icon-color': '$--color-text-placeholder',
  '$--input-placeholder-color': '$--color-text-placeholder',
  '$--input-medium-font-size': '14px',
  '$--input-medium-height': '36px',
  '$--input-small-font-size': '13px',
  '$--input-small-height': '32px',
  '$--input-mini-font-size': '12px',
  '$--input-mini-height': '28px',
  '$--cascader-menu-font-color': '$--color-text-regular',
  '$--cascader-menu-selected-font-color': '$--color-primary',
  '$--button-font-size': '$--font-size-base',
  '$--button-font-weight': '$--font-weight-primary',
  '$--button-border-radius': '$--border-radius-base',
  '$--button-padding-vertical': '12px',
  '$--button-padding-horizontal': '20px',
  '$--button-medium-font-size': '$--font-size-base',
  '$--button-medium-border-radius': '$--border-radius-base',
  '$--button-medium-padding-vertical': '10px',
  '$--button-medium-padding-horizontal': '20px',
  '$--button-small-font-size': '12px',
  '$--button-small-padding-vertical': '9px',
  '$--button-small-padding-horizontal': '15px',
  '$--button-mini-font-size': '12px',
  '$--button-mini-padding-vertical': '7px',
  '$--button-mini-padding-horizontal': '15px',
  '$--button-default-font-color': '$--color-text-regular',
  '$--button-default-background-color': '$--color-white',
  '$--button-default-border-color': '$--border-color-base',
  '$--button-disabled-font-color': '$--color-text-placeholder',
  '$--button-disabled-background-color': '$--color-white',
  '$--button-disabled-border-color': '$--border-color-lighter',
  '$--button-primary-border-color': '$--color-primary',
  '$--button-primary-font-color': '$--color-white',
  '$--button-primary-background-color': '$--color-primary',
  '$--button-success-border-color': '$--color-success',
  '$--button-success-font-color': '$--color-white',
  '$--button-success-background-color': '$--color-success',
  '$--button-warning-border-color': '$--color-warning',
  '$--button-warning-font-color': '$--color-white',
  '$--button-warning-background-color': '$--color-warning',
  '$--button-danger-border-color': '$--color-danger',
  '$--button-danger-font-color': '$--color-white',
  '$--button-danger-background-color': '$--color-danger',
  '$--button-info-border-color': '$--color-info',
  '$--button-info-font-color': '$--color-white',
  '$--button-info-background-color': '$--color-info',
  '$--switch-on-color': '$--color-primary',
  '$--switch-off-color': '$--border-color-base',
  '$--switch-font-size': '$--font-size-base',
  '$--dialog-title-font-size': '$--font-size-large',
  '$--dialog-content-font-size': '14px',
  '$--dialog-font-line-height': '$--font-line-height-primary',
  '$--dialog-padding-primary': '20px',
  '$--table-border-color': '$--border-color-lighter',
  '$--table-font-color': '$--color-text-regular',
  '$--table-header-font-color': '$--color-text-secondary',
  '$--table-row-hover-background-color': '$--background-color-base',
  '$--table-header-background-color': '$--color-white',
  '$--pagination-font-size': '13px',
  '$--pagination-background-color': '$--color-white',
  '$--pagination-font-color': '$--color-text-primary',
  '$--pagination-button-color': '$--color-text-primary',
  '$--pagination-button-width': '35.5px',
  '$--pagination-button-height': '28px',
  '$--pagination-button-disabled-color': '$--color-text-placeholder',
  '$--pagination-button-disabled-background-color': '$--color-white',
  '$--pagination-hover-color': '$--color-primary',
  '$--popup-modal-background-color': '$--color-black',
  '$--popup-modal-opacity': '0.5',
  '$--popover-background-color': '$--color-white',
  '$--popover-font-size': '$--font-size-base',
  '$--popover-border-color': '$--border-color-lighter',
  '$--popover-padding': '12px',
  '$--popover-title-font-size': '16px',
  '$--popover-title-font-color': '$--color-text-primary',
  '$--tooltip-fill': '$--color-text-primary',
  '$--tooltip-color': '$--color-white',
  '$--tooltip-font-size': '12px',
  '$--tooltip-border-color': '$--color-text-primary',
  '$--tooltip-padding': '10px',
  '$--tag-info-color': '$--color-info',
  '$--tag-primary-color': '$--color-primary',
  '$--tag-success-color': '$--color-success',
  '$--tag-warning-color': '$--color-warning',
  '$--tag-danger-color': '$--color-danger',
  '$--tag-font-size': '12px',
  '$--tree-node-hover-background-color': '$--background-color-base',
  '$--tree-font-color': '$--color-text-regular',
  '$--tree-expand-icon-color': '$--color-text-placeholder',
  '$--badge-background-color': '$--color-danger',
  '$--badge-font-size': '12px',
  '$--badge-padding': '6px',
  '$--badge-size': '18px',
  '$--card-border-color': '$--border-color-lighter',
  '$--card-padding': '20px',
  '$--slider-main-background-color': '$--color-primary',
  '$--slider-runway-background-color': '$--border-color-light',
  '$--slider-height': '6px',
  '$--slider-button-size': '16px',
  '$--menu-item-font-size': '$--font-size-base',
  '$--menu-item-font-color': '$--color-text-primary',
  '$--menu-background-color': '$--color-white',
  '$--rate-font-size': '$--font-size-base',
  '$--rate-icon-size': '18px',
  '$--rate-icon-margin': '6px',
  '$--datepicker-off-font-color': '$--color-text-placeholder',
  '$--datepicker-header-font-color': '$--color-text-regular',
  '$--datepicker-inrange-background-color': '$--border-color-extra-light',
  '$--datepicker-inrange-hover-background-color': '$--border-color-extra-light',
  '$--datepicker-active-color': '$--color-primary',
  '$--datepicker-hover-font-color': '$--color-primary',
  '$--loading-spinner-size': '42px',
  '$--loading-fullscreen-spinner-size': '50px',
  '$--carousel-arrow-font-size': '12px',
  '$--carousel-indicator-width': '30px',
  '$--carousel-indicator-height': '2px',
  '$--collapse-border-color': '$--border-color-lighter',
  '$--collapse-header-height': '48px',
  '$--collapse-header-background-color': '$--color-white',
  '$--collapse-header-font-color': '$--color-text-primary',
  '$--collapse-header-font-size': '13px',
  '$--collapse-content-background-color': '$--color-white',
  '$--collapse-content-font-size': '13px',
  '$--collapse-content-font-color': '$--color-text-primary',
  '$--transfer-panel-width': '200px',
  '$--transfer-panel-header-height': '40px',
  '$--transfer-panel-header-background-color': '$--background-color-base',
  '$--transfer-panel-footer-height': '40px',
  '$--transfer-panel-body-height': '246px',
  '$--transfer-item-height': '30px',
  '$--transfer-filter-height': '32px',
  '$--backtop-background-color': '$--color-white',
  '$--backtop-font-color': '$--color-primary',
  '$--backtop-hover-background-color': '$--border-color-extra-light',
  '$--link-font-size': '$--font-size-base',
  '$--link-font-weight': '$--font-weight-primary',
  '$--link-default-font-color': '$--color-text-regular',
  '$--link-default-active-color': '$--color-primary',
  '$--link-disabled-font-color': '$--color-text-placeholder',
  '$--link-primary-font-color': '$--color-primary',
  '$--link-success-font-color': '$--color-success',
  '$--link-warning-font-color': '$--color-warning',
  '$--link-danger-font-color': '$--color-danger',
  '$--link-info-font-color': '$--color-info',
  '$--calendar-border': '$--table-border',
  '$--calendar-selected-background-color': '#F2F8FE',
  '$--form-label-font-size': '$--font-size-base',
  '$--avatar-font-color': '#fff',
  '$--avatar-background-color': '#C0C4CC',
  '$--avatar-text-font-size': '14px',
  '$--avatar-icon-font-size': '18px',
  '$--avatar-border-radius': '$--border-radius-base',
  '$--avatar-large-size': '40px',
  '$--avatar-medium-size': '36px',
  '$--avatar-small-size': '28px'
}

export class ThemeDTO {
  id?: number;
  guid?: string;
  name: string;
  description: string|null;
  alias: string|null;
  properties: {
    [variable: string]: any;
  };

  _properties: {
    [variable: string]: any;
  };

  /**
   * Заполнение данными
   *
   * @param data
   */
  constructor (data: IThemeDTO) {
    this.id = data.id
    this.guid = data.guid
    this.name = data.name
    this.description = data.description
    this.alias = data.alias
    this.properties = Object.assign({}, defaultProperties, data.properties)
    this._properties = Object.assign({}, defaultProperties, data.properties)
  }

  /**
   * Создать пустую модель
   */
  static create (name: string) {
    return new ThemeDTO({
      name: name,
      description: '',
      alias: null,
      properties: defaultProperties
    })
  }

  isEditProperties (): boolean {
    return JSON.stringify(this._properties) !== JSON.stringify(this.properties)
  }

  duplicate () {
    return new ThemeDTO({
      name: this.name + ' Copy',
      description: this.description,
      alias: this.alias,
      properties: this.properties
    })
  }

  asNew (name?: string) {
    return new ThemeDTO({
      name: name || this.name,
      description: this.description,
      alias: this.alias,
      properties: this.properties
    })
  }

  resetVariables (): void {
    this.properties = Object.assign({}, defaultProperties)
  }

  isDefault (): boolean {
    return typeof this.id !== 'undefined' && this.id === 0
  }
}
