import { MutationTree } from 'vuex'
import { StrokeStyleState, initialState } from './state'
import { MutationTypes, StrokeStyleCreatedEvent, StrokeStyleUpdatedEvent, StrokeStyleDeletedEvent } from './mutation-types'

export const mutations: MutationTree<StrokeStyleState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false;
    state.error = error;    
  },
  [MutationTypes.STROKE_STYLE_LOADING_EVENT]: (state) => {
    state.isLoading = true;
  },
  [MutationTypes.STROKE_STYLE_LOADED_EVENT]: (state) => {
    state.isLoading = false;
  },
  [MutationTypes.STROKE_STYLE_CREATED_EVENT]: (state, action: StrokeStyleCreatedEvent) => {
    state.location = action.location;
    state.isLoading = false;
  },
  [MutationTypes.STROKE_STYLE_UPDATED_EVENT]: (state, action: StrokeStyleUpdatedEvent) => {
    state.isLoading = false;
  },
  [MutationTypes.STROKE_STYLE_DELETED_EVENT]: (state, action: StrokeStyleDeletedEvent) => {
    state.isLoading = false;
  }
}