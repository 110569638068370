import { ActionTree } from 'vuex'
import { LayerState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, LayerCreatedEvent, LayerUpdatedEvent, LayerDeletedEvent, LayerLoadingEvent, LayerLoadedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { LayerAPI } from '@/services/MapEditor/infrastructure/api/LayerAPI'

export const actions: ActionTree<LayerState, any> = {
    [ActionTypes.ADD_LAYER]: async ({ commit, dispatch }, data) => {
        try {
            commit(new LayerLoadingEvent());
            let location = await APIClient.shared.request(new LayerAPI.AddLayer(data.payload));
            commit(new LayerCreatedEvent(location));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.UPDATE_LAYER]: async ({ commit, dispatch }, data) => {
        try {
            commit(new LayerLoadingEvent());
            let location = await APIClient.shared.request(new LayerAPI.UpdateLayer(data.payload));
            commit(new LayerUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.GET_LAYER_BY_GUID]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new LayerAPI.GetLayerByGuid(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    },
    [ActionTypes.GET_LAYERS]: async ({ commit, dispatch }, data) => {
        try {
            commit(new LayerLoadingEvent());
            let res = await APIClient.shared.request(new LayerAPI.GetLayers(data.payload));
            commit(new LayerLoadedEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.DELETE_PROJECT_LAYER]: async ({ commit, dispatch }, data) => {
        try {
            await APIClient.shared.request(new LayerAPI.DeleteProjectLayer(data.id, data.projectGuid));
            commit(new LayerDeletedEvent(data.id));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }         
    }
}
