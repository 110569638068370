import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import ProjectTreeElementQueryRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/ProjectTreeElementQueryRepositoryInterface'
import store from '@/store'
import { GetProjectTreeElementByGuid, GetProjectTreeElements } from '@/services/DigitalTwinEditor/infrastructure/store/modules/ProjectTreeElement/action-types'

export default class ProjectTreeElementQueryRepository extends AbstractQueryRepository implements ProjectTreeElementQueryRepositoryInterface
{
    getByGuid(guid: string): Promise<any>
    {
        return store.dispatch(new GetProjectTreeElementByGuid(guid));
    }

    getChildNodes(parentId: Nullable<number>): Promise<any>
    {
        return store.dispatch(new GetProjectTreeElements(parentId));
    }
}