export interface ThemeState {
  isLoading: boolean
  location: string
  deletedThemeGuid: string
  error: object
}

export const initialState = (): ThemeState => {
  return {
    isLoading: false,
    location: null,
    deletedThemeGuid: null,
    error: null
  }
}
