import { FluxStandardAction } from '@/core/domain/type/types'
import { StrokeStyleDTO } from '@/core/domain/model/modules/StrokeStyle'

export enum ActionTypes {
    ADD_STROKE_STYLE = 'add_stroke_style',
    UPDATE_STROKE_STYLE = 'update_stroke_style',
    DELETE_STROKE_STYLE = 'delete_stroke_style',
    GET_STROKE_STYLES = 'get_stroke_styles',
    GET_STROKE_STYLE_BY_GUID = 'get_stroke_style_by_guid'
}

export class AddStrokeStyle implements FluxStandardAction {
    type = 'StrokeStyle/' + ActionTypes.ADD_STROKE_STYLE
    constructor(public payload: StrokeStyleDTO){}
}

export class UpdateStrokeStyle implements FluxStandardAction {
    type = 'StrokeStyle/' + ActionTypes.UPDATE_STROKE_STYLE
    constructor(public payload: StrokeStyleDTO){}
}

export class DeleteStrokeStyle implements FluxStandardAction {
    type = 'StrokeStyle/' + ActionTypes.DELETE_STROKE_STYLE
    constructor(public guid: string){}
}

export class GetStrokeStyles implements FluxStandardAction {
    type = 'StrokeStyle/' + ActionTypes.GET_STROKE_STYLES
    constructor(public payload: object){}
}

export class GetStrokeStyleByGuid implements FluxStandardAction {
    type = 'StrokeStyle/' + ActionTypes.GET_STROKE_STYLE_BY_GUID
    constructor(public guid: string){}
}