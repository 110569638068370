<script type="ts">
import ElImage from 'element-ui/packages/image/src/main.vue'
import ElButton from 'element-ui/packages/button/src/button.vue'
import StrokeStyleCreateCommand from '@/core/application/command/modules/StrokeStyle/StrokeStyleCreateCommand'
import StrokeStyleCreateHandler from '@/core/application/command/modules/StrokeStyle/StrokeStyleCreateHandler'
import StrokeStyleUpdateCommand from '@/core/application/command/modules/StrokeStyle/StrokeStyleUpdateCommand'
import StrokeStyleUpdateHandler from '@/core/application/command/modules/StrokeStyle/StrokeStyleUpdateHandler'
import StrokeStyleDeleteCommand from '@/core/application/command/modules/StrokeStyle/StrokeStyleDeleteCommand'
import StrokeStyleDeleteHandler from '@/core/application/command/modules/StrokeStyle/StrokeStyleDeleteHandler'
import StrokeStylesQuery from '@/core/application/query/modules/StrokeStyle/StrokeStylesQuery'
import StrokeStylesHandler from '@/core/application/query/modules/StrokeStyle/StrokeStylesHandler'
import StrokeStyleByGuidQuery from '@/core/application/query/modules/StrokeStyle/StrokeStyleByGuidQuery'
import StrokeStyleByGuidHandler from '@/core/application/query/modules/StrokeStyle/StrokeStyleByGuidHandler'
import StrokeStyleCommandRepository from '@/core/infrastructure/domain/repository/modules/StrokeStyleCommandRepository'
import StrokeStyleQueryRepository from '@/core/infrastructure/domain/repository/modules/StrokeStyleQueryRepository'
import StrokeStyle, { StrokeStyleDTO } from '@/core/domain/model/modules/StrokeStyle'

export default {
    name: 'LinestringPatternSet',
    props: {        
        showControls: {
            type: Boolean,
            default: true
        },
        value: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        
    },
    data() {
        return {
            dto: new StrokeStyle({}),
            items: [],
            itemsLoaded: false,
            iconValue: null,
            defaultPatternExists: false,
            strokeStyleCommandRepository: new StrokeStyleCommandRepository(),
            strokeStyleQueryRepository: new StrokeStyleQueryRepository(),
            strokeStyleCreateHandler: new StrokeStyleCreateHandler(this.getStrokeStyleCommandRepository()),
            strokeStyleUpdateHandler: new StrokeStyleUpdateHandler(this.getStrokeStyleCommandRepository()),
            strokeStyleDeleteHandler: new StrokeStyleDeleteHandler(this.getStrokeStyleCommandRepository()),
            strokeStyleByGuidHandler: new StrokeStyleByGuidHandler(this.getStrokeStyleQueryRepository()),
            strokeStylesHandler: new StrokeStylesHandler(this.getStrokeStyleQueryRepository())
        };
    },
    watch: {
        iconValue(val) {
            this.$emit('change', val);
        }
    },
    methods: {
        getStrokeStyleCommandRepository() {
            return this.strokeStyleCommandRepository || (new StrokeStyleCommandRepository());
        },
        getStrokeStyleQueryRepository() {
            return this.strokeStyleQueryRepository || (new StrokeStyleQueryRepository());
        },
        renderLinestringStack() {
            if (this.items.length > 0) {
                return this.$createElement("div", {
                    ref: "linestringSet",
                    class: {
                        "linestring-stack": true
                    }
                },[
                    ...this.renderLinestringItems()
                ]);
            }
            return null;
        },
        renderLinestringItems() {
            let components = [];
            for (let i = 0; i < this.items.length; i += 1) {
                let item = this.items[i];
                components.push(
                    this.$createElement("div",
                        {
                            class: {
                                "linestring-row": true,
                                "selected": this.iconValue ? item.pattern == this.iconValue.pattern : item.pattern.length == 0
                            },
                            on: {
                                click: (el) => {
                                    this.iconValue = {"guid": item.guid, "pattern": item.pattern};
                                }
                            },
                            style: {
                                "background-image": `url(${this.$config.api}/files/mapeditor/images/${item.guid}.svg)`,
                                "background-repeat": "repeat-x"
                            }
                        },[
                        
                    ])
                );
            }
            return components;            
        },
        renderControls() {
            return this.$createElement("span", {
                style: {
                    "width": "40px",
                    "padding-left": "4px",
                    "display": (this.showControls ? "block" : "none")
                }
            }, [
                this.$createElement("div", {},[
                    this.$createElement(ElButton, {
                        props: {
                            type: "info",
                            circle: true,
                            icon: "el-icon-plus",
                            size: "mini",
                            plain: true
                        },
                        on: {
                            click: () => {
                                this.$emit('addLinestringPattern', (item) => {
                                    this.items.push({
                                        guid: item.getGuid(),
                                        pattern: item.getPattern()
                                    });
                                });
                            }
                        }
                    }, [])
                ]),
                this.$createElement("div", {},[
                    this.$createElement(ElButton, {
                        props: {
                            type: "info",
                            circle: true,
                            icon: "el-icon-delete",
                            size: "mini",
                            plain: true
                        },
                        on: {
                            click: () => {
                                if (this.iconValue == null) {
                                    this.$message({
                                        message: this.$locale.main.message.select_image,
                                        type: 'warning'
                                    });
                                } else {
                                    this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
                                        confirmButtonText: this.$locale.main.button.delete,
                                        cancelButtonText: this.$locale.main.button.cancel,
                                        type: 'warning'
                                    }).then(async () => {
                                        let guid = this.iconValue['guid'];
                                        this.strokeStyleDeleteHandler.execute(
                                            new StrokeStyleDeleteCommand(
                                                this.iconValue['guid']
                                            )
                                        ).then((response) => {
                                            this.iconValue = {"pattern": []};
                                            for (let i = 0; i < this.items.length; i += 1) {
                                                let item = this.items[i];
                                                if (item.guid == guid) {
                                                    this.items.splice(i, 1);
                                                    break;
                                                }
                                            }
                                        });

                                    }).catch((error) => { console.log(error); })
                                }
                            }
                        }
                    }, [])
                ])                 
            ]);
        }
    },
    render (createElement) {
        if (Object.keys(this.value).length > 0) {
            this.iconValue = this.value;
        }
        if (!this.itemsLoaded) {
            this.itemsLoaded = true;
            this.strokeStylesHandler.execute(new StrokeStylesQuery({id: 0})).then((data) => {
                let defaultLine = data[0];
                this.strokeStyleUpdateHandler.execute(
                    new StrokeStyleUpdateCommand(
                        defaultLine.guid,
                        "",
                        "",
                        []
                    )
                ).then((data) => {
                    this.strokeStylesHandler.execute(new StrokeStylesQuery({})).then((data) => {
                        this.items = data;
                    });
                })                
            });            
        }
        return createElement("div", {
            style: {
                "display": "flex",
                "overflow": "hidden"
            }
        },[
            this.$createElement("div", {
                class: {
                    "linestring-pattern-set": true,
                    "custom_scrollbar": true
                },
                style: {
                    "flex": "100%"
                }
            },[
                this.renderLinestringStack()
            ]),
            this.renderControls()
        ]);
    }
};
</script>
