import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer'

import {
  append as svgAppend,
  classes as svgClasses,
  attr as svgAttr,
  create as svgCreate
} from 'tiny-svg'

import { getBusinessObject, is } from 'bpmn-js/lib/util/ModelUtil'

const HIGH_PRIORITY = 1500

export default class AccentRenderer extends BaseRenderer {
  constructor (eventBus, bpmnRenderer) {
    super(eventBus, HIGH_PRIORITY)

    this.bpmnRenderer = bpmnRenderer
  }

  canRender (element) {
    // isAny(element, ['bpmn:ServiceTask', 'bpmn:StartEvent', 'bpmn:Task']) &&
    return !element.labelTarget
  }

  getFill (type) {
    switch (type) {
      case 'EndEvent':
        return '#f0a8ab'
      case 'StartEvent':
        return '#e5fd95'
      case 'ServiceTask':
      case 'Task':
      case 'ManualTask':
      case 'UserTask':
      case 'SubProcess':
      case 'BoundaryEvent':
        return '#ecefff'
      case 'ParallelGateway':
      case 'ExclusiveGateway':
        return '#fffec9'
      case 'IntermediateThrowEvent':
      case 'IntermediateCatchEvent':
        return '#fffbe1'
      case 'DataObject':
      case 'DataStoreReference':
        return '#f0f0f0'
      default:
        return null
    }
  }

  getStroke (type) {
    switch (type) {
      case 'EndEvent':
        return '#a73e3f'
      case 'StartEvent':
        return '#68951d'
      case 'ServiceTask':
      case 'Task':
      case 'BoundaryEvent':
      case 'SubProcess':
      case 'ManualTask':
      case 'UserTask':
        return '#1c5372'
      case 'ParallelGateway':
      case 'ExclusiveGateway':
        return '#acaa5c'
      case 'IntermediateThrowEvent':
      case 'IntermediateCatchEvent':
        return '#999771'
      case 'DataObject':
      case 'DataStoreReference':
        return '#9d9d9d'
      default:
        return null
    }
  }

  drawShape (parentNode, element) {
    const shape = this.bpmnRenderer.drawShape(parentNode, element)

    const fill = this.getFill(element.type.replace('bpmn:', ''))
    if (fill) {
      shape.style.fill = fill
    }
    const stroke = this.getStroke(element.type.replace('bpmn:', ''))
    if (stroke) {
      shape.style.stroke = stroke
    }

    return shape
  }
}

AccentRenderer.$inject = ['eventBus', 'bpmnRenderer']
