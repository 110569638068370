import { FluxStandardAction } from '@/core/domain/type/types'
import { ListenerDTO } from '@/services/LogicEditor/domain/model/Listener'

export enum ActionTypes {
  GET_LISTENER = 'get_listener',
  GET_LISTENER_BY_GUID = 'get_listener_by_guid',
  CREATE_LISTENER = 'create_listener',
  UPDATE_LISTENER = 'update_listener',
  DELETE_LISTENER = 'delete_listener'
}

export class GetListenerByGuid implements FluxStandardAction {
  type = 'Listener/' + ActionTypes.GET_LISTENER_BY_GUID
  constructor (public guid: string) {}
}

export class CreateListener implements FluxStandardAction {
  type = 'Listener/' + ActionTypes.CREATE_LISTENER
  constructor (public payload: ListenerDTO) {}
}

export class UpdateListener implements FluxStandardAction {
  type = 'Listener/' + ActionTypes.UPDATE_LISTENER
  constructor (public payload: ListenerDTO) {}
}

export class DeleteListener implements FluxStandardAction {
  type = 'Listener/' + ActionTypes.DELETE_LISTENER
  constructor (public guid: string) {}
}
