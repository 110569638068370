<template>
  <selectTree
    class="extraLine_select"
    :disabled="isEdit"
    :clearable="false"
    :standartTree="true"
    ref="entity_tree"
    :selectAlias="selectAlias"
    :dataTable="dataTable"
    :value="localValue"
    @input="localValue = $event"/>
</template>

<script>
import selectTree from '@/core/infrastructure/components/TreeSelect'

export default {
  name: 'UsersTable',

  components: {
    selectTree
  },

  props: {
    value: Number,

    isEdit: {
      type: Boolean
    }
  },

  data () {
    return {
      field: null,
      localValue: this.value,
      dataTable: []
    }
  },

  methods: {
    selectAlias (data) {
      this.$emit('input', data.id)
    }
  },

  created () {
    this.$http
      .get(`${this.$config.api}/objecteditor/entities?properties[is_users_table]=true&show_children=true`)
      .then(response => {
        this.dataTable = response.data.data
      })
      .catch(error => console.log(error))
  }
}
</script>

<style type="text/css"></style>
