<template>
  <div>
    <el-button type="text" @click="dialogSettingsVisible = true">{{ $locale.main.button.edit }}</el-button>

    <el-dialog title="Видимость слоев" :modal="false"
               :visible.sync="dialogSettingsVisible"
               class="window">
      <layer-filters
        :layers="otherProperties.layers"
        :value="value"
      ></layer-filters>
    </el-dialog>
  </div>
</template>

<script>
  import LayerFilters from './LayerFilters'

  export default {
    name: 'MapLayerFilters',
    components: { LayerFilters },
    inject: {
      getLayers: {
        default: () => { return [] }
      }
    },
    props: {
      otherProperties: {
        type: Object,
        default: () => {
          return {}
        }
      },
      value: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    watch: {
      value: {
        handler: function (newValue, oldValue) {
          this.$emit('change', newValue)
        },
        deep: true
      }
    },
    data: () => {
      return {
        dialogSettingsVisible: false
      }
    }
  }
</script>

<style scoped>

</style>
