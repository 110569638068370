
import Vue from 'vue'
import ElCollapsibleTabs from '@/core/infrastructure/components/CollapsibleTabs.vue'
import CommandBus from '@/core/application/command/service/CommandBus'
import CommandBusHandlerLocator from '@/core/application/command/service/CommandBusHandlerLocator'
import QueryBus from '@/core/application/query/service/QueryBus'
import QueryBusHandlerLocator from '@/core/application/query/service/QueryBusHandlerLocator'
import AttachFileToModelHandler from '@/services/DigitalTwinEditor/application/handler/command/AttachFileToModelHandler'
import AttachTemplateToProjectHandler from '@/services/DigitalTwinEditor/application/handler/command/AttachTemplateToProjectHandler'
import DetachTemplateFromProjectHandler from '@/services/DigitalTwinEditor/application/handler/command/DetachTemplateFromProjectHandler'
import DownloadLastBuildHandler from '@/services/DigitalTwinEditor/application/handler/command/DownloadLastBuildHandler'
import DownloadPrjHandler from '@/services/DigitalTwinEditor/application/handler/command/DownloadPrjHandler'
import FileDeleteHandler from '@/services/DigitalTwinEditor/application/handler/command/FileDeleteHandler'
import FileTransformHandler from '@/services/DigitalTwinEditor/application/handler/command/FileTransformHandler'
import LayerUpdateHandler from '@/services/DigitalTwinEditor/application/handler/command/LayerUpdateHandler'
import ModelGroupUpdateHandler from '@/services/DigitalTwinEditor/application/handler/command/ModelGroupUpdateHandler'
import ModelInstanceCreateHandler from '@/services/DigitalTwinEditor/application/handler/command/ModelInstanceCreateHandler'
import ModelInstanceUpdateHandler from '@/services/DigitalTwinEditor/application/handler/command/ModelInstanceUpdateHandler'
import ModelInstanceDeleteHandler from '@/services/DigitalTwinEditor/application/handler/command/ModelInstanceDeleteHandler'
import ModelTreeElementDeleteHandler from '@/services/DigitalTwinEditor/application/handler/command/ModelTreeElementDeleteHandler'
import ModelTreeElementGroupCreateHandler from '@/services/DigitalTwinEditor/application/handler/command/ModelTreeElementGroupCreateHandler'
import ModelTreeElementModelCreateHandler from '@/services/DigitalTwinEditor/application/handler/command/ModelTreeElementModelCreateHandler'
import ModelUpdateHandler from '@/services/DigitalTwinEditor/application/handler/command/ModelUpdateHandler'
import OpenWorldUpdateHandler from '@/services/DigitalTwinEditor/application/handler/command/OpenWorldUpdateHandler'
import StyleUpdateHandler from '@/services/DigitalTwinEditor/application/handler/command/StyleUpdateHandler'
import SetDefaultTexturesHandler from '@/services/DigitalTwinEditor/application/handler/command/SetDefaultTexturesHandler'
import SetTerrainHandler from '@/services/DigitalTwinEditor/application/handler/command/SetTerrainHandler'
import SetLandscapeHandler from '@/services/DigitalTwinEditor/application/handler/command/SetLandscapeHandler'
import StopTilingHandler from '@/services/DigitalTwinEditor/application/handler/command/StopTilingHandler'
import ProjectGroupUpdateHandler from '@/services/DigitalTwinEditor/application/handler/command/ProjectGroupUpdateHandler'
import ProjectTreeElementDeleteHandler from '@/services/DigitalTwinEditor/application/handler/command/ProjectTreeElementDeleteHandler'
import ProjectTreeElementGroupCreateHandler from '@/services/DigitalTwinEditor/application/handler/command/ProjectTreeElementGroupCreateHandler'
import ProjectTreeElementLayerCreateHandler from '@/services/DigitalTwinEditor/application/handler/command/ProjectTreeElementLayerCreateHandler'
import ProjectTreeElementModelCreateHandler from '@/services/DigitalTwinEditor/application/handler/command/ProjectTreeElementModelCreateHandler'
import ProjectTreeElementOpenWorldCreateHandler from '@/services/DigitalTwinEditor/application/handler/command/ProjectTreeElementOpenWorldCreateHandler'
import ProjectTreeElementProjectCreateHandler from '@/services/DigitalTwinEditor/application/handler/command/ProjectTreeElementProjectCreateHandler'
import ProjectUpdateHandler from '@/services/DigitalTwinEditor/application/handler/command/ProjectUpdateHandler'
import ProjectBuildHandler from '@/services/DigitalTwinEditor/application/handler/command/ProjectBuildHandler'
import FileByGuidHandler from '@/services/DigitalTwinEditor/application/handler/query/FileByGuidHandler'
import ModelFilesHandler from '@/services/DigitalTwinEditor/application/handler/query/ModelFilesHandler'
import LandscapeTexturesHandler from '@/services/DigitalTwinEditor/application/handler/query/LandscapeTexturesHandler'
import LandscapeTexturesCountHandler from '@/services/DigitalTwinEditor/application/handler/query/LandscapeTexturesCountHandler'
import LayerByGuidHandler from '@/services/DigitalTwinEditor/application/handler/query/LayerByGuidHandler'
import ModelByGuidHandler from '@/services/DigitalTwinEditor/application/handler/query/ModelByGuidHandler'
import ModelGroupByGuidHandler from '@/services/DigitalTwinEditor/application/handler/query/ModelGroupByGuidHandler'
import ModelTreeElementByGuidHandler from '@/services/DigitalTwinEditor/application/handler/query/ModelTreeElementByGuidHandler'
import ModelTreeElementsHandler from '@/services/DigitalTwinEditor/application/handler/query/ModelTreeElementsHandler'
import ModelInstanceByGuidHandler from '@/services/DigitalTwinEditor/application/handler/query/ModelInstanceByGuidHandler'
import OpenWorldByGuidHandler from '@/services/DigitalTwinEditor/application/handler/query/OpenWorldByGuidHandler'
import OpenWorldGeojsonHandler from '@/services/DigitalTwinEditor/application/handler/query/OpenWorldGeojsonHandler'
import StyleHandler from '@/services/DigitalTwinEditor/application/handler/query/StyleHandler'
import ProjectByGuidHandler from '@/services/DigitalTwinEditor/application/handler/query/ProjectByGuidHandler'
import ProjectGroupByGuidHandler from '@/services/DigitalTwinEditor/application/handler/query/ProjectGroupByGuidHandler'
import ProjectTreeElementByGuidHandler from '@/services/DigitalTwinEditor/application/handler/query/ProjectTreeElementByGuidHandler'
import ProjectTreeElementsHandler from '@/services/DigitalTwinEditor/application/handler/query/ProjectTreeElementsHandler'
import ProjectBuildsHandler from '@/services/DigitalTwinEditor/application/handler/query/ProjectBuildsHandler'
import ProjectBuildsCountHandler from '@/services/DigitalTwinEditor/application/handler/query/ProjectBuildsCountHandler'
import TasksCountHandler from '@/services/DigitalTwinEditor/application/handler/query/TasksCountHandler'
import TasksHandler from '@/services/DigitalTwinEditor/application/handler/query/TasksHandler'
import TexturesCountHandler from '@/services/DigitalTwinEditor/application/handler/query/TexturesCountHandler'
import TexturesHandler from '@/services/DigitalTwinEditor/application/handler/query/TexturesHandler'
import TemplatesCountHandler from '@/services/DigitalTwinEditor/application/handler/query/TemplatesCountHandler'
import TemplatesHandler from '@/services/DigitalTwinEditor/application/handler/query/TemplatesHandler'
import FileCommandRepository from '@/services/DigitalTwinEditor/infrastructure/domain/repository/FileCommandRepository'
import LayerCommandRepository from '@/services/DigitalTwinEditor/infrastructure/domain/repository/LayerCommandRepository'
import FileQueryRepository from '@/services/DigitalTwinEditor/infrastructure/domain/repository/FileQueryRepository'
import LayerQueryRepository from '@/services/DigitalTwinEditor/infrastructure/domain/repository/LayerQueryRepository'
import ModelCommandRepository from '@/services/DigitalTwinEditor/infrastructure/domain/repository/ModelCommandRepository'
import ModelGroupCommandRepository from '@/services/DigitalTwinEditor/infrastructure/domain/repository/ModelGroupCommandRepository'
import ModelGroupQueryRepository from '@/services/DigitalTwinEditor/infrastructure/domain/repository/ModelGroupQueryRepository'
import ModelInstanceCommandRepository from '@/services/DigitalTwinEditor/infrastructure/domain/repository/ModelInstanceCommandRepository'
import ModelInstanceQueryRepository from '@/services/DigitalTwinEditor/infrastructure/domain/repository/ModelInstanceQueryRepository'
import ModelQueryRepository from '@/services/DigitalTwinEditor/infrastructure/domain/repository/ModelQueryRepository'
import ModelTreeElementCommandRepository from '@/services/DigitalTwinEditor/infrastructure/domain/repository/ModelTreeElementCommandRepository'
import ModelTreeElementQueryRepository from '@/services/DigitalTwinEditor/infrastructure/domain/repository/ModelTreeElementQueryRepository'
import LandscapeTexturesQueryRepository from '@/services/DigitalTwinEditor/infrastructure/domain/repository/LandscapeTexturesQueryRepository'
import OpenWorldCommandRepository from '@/services/DigitalTwinEditor/infrastructure/domain/repository/OpenWorldCommandRepository'
import OpenWorldQueryRepository from '@/services/DigitalTwinEditor/infrastructure/domain/repository/OpenWorldQueryRepository'
import ProjectCommandRepository from '@/services/DigitalTwinEditor/infrastructure/domain/repository/ProjectCommandRepository'
import ProjectGroupCommandRepository from '@/services/DigitalTwinEditor/infrastructure/domain/repository/ProjectGroupCommandRepository'
import ProjectGroupQueryRepository from '@/services/DigitalTwinEditor/infrastructure/domain/repository/ProjectGroupQueryRepository'
import ProjectQueryRepository from '@/services/DigitalTwinEditor/infrastructure/domain/repository/ProjectQueryRepository'
import ProjectBuildsQueryRepository from '@/services/DigitalTwinEditor/infrastructure/domain/repository/ProjectBuildsQueryRepository'
import ProjectTreeElementCommandRepository from '@/services/DigitalTwinEditor/infrastructure/domain/repository/ProjectTreeElementCommandRepository'
import ProjectTreeElementQueryRepository from '@/services/DigitalTwinEditor/infrastructure/domain/repository/ProjectTreeElementQueryRepository'
import TaskQueryRepository from '@/services/DigitalTwinEditor/infrastructure/domain/repository/TaskQueryRepository'
import TextureQueryRepository from '@/services/DigitalTwinEditor/infrastructure/domain/repository/TextureQueryRepository'
import TemplateQueryRepository from '@/services/DigitalTwinEditor/infrastructure/domain/repository/TemplateQueryRepository'
import ProjectTree from '@/services/DigitalTwinEditor/infrastructure/components/Project/MainTree.vue'
import ModelTree from '@/services/DigitalTwinEditor/infrastructure/components/Model/MainTree.vue'
import TaskList from '@/services/DigitalTwinEditor/infrastructure/components/Task/TaskList.vue'
import TextureList from '@/services/DigitalTwinEditor/infrastructure/components/Texture/TextureList.vue'
import EditTreeElement from './EditPanel.vue'

export default {
  name: 'DigitalTwinEditor',
  components: {
    ElCollapsibleTabs,
    ProjectTree,
    ModelTree,
    EditTreeElement,
    TaskList,
    TextureList
  },
  computed: {
  },
  watch: { 
    activeTabName: function (value) {
      if (value == "textures" || value == "tasks" ) {
        this.leftSize = 100;
        this.rightSize = 0;
      } else {
        this.leftSize = 50;
        this.rightSize = 50;
      }
    }
  },
  data() {
    return {
      eventBus: new Vue(),
      commandBus: new CommandBus(
        new CommandBusHandlerLocator({
          'AttachFileToModelCommand': new AttachFileToModelHandler(
            new FileCommandRepository()
          ),
          'AttachTemplateToProjectCommand': new AttachTemplateToProjectHandler(
            new ProjectCommandRepository()
          ),
          'DetachTemplateFromProjectCommand': new DetachTemplateFromProjectHandler(
            new ProjectCommandRepository()
          ),
          'FileDeleteCommand': new FileDeleteHandler(
            new FileCommandRepository()
          ),
          'FileTransformCommand': new FileTransformHandler(
            new FileCommandRepository()
          ),
          'DigitalTwinLayerUpdateCommand': new LayerUpdateHandler(
            new LayerCommandRepository()
          ),
          'ModelGroupUpdateCommand': new ModelGroupUpdateHandler(
            new ModelGroupCommandRepository()
          ),
          'ModelInstanceCreateCommand': new ModelInstanceCreateHandler(
            new ModelInstanceCommandRepository()
          ),
          'ModelInstanceUpdateCommand': new ModelInstanceUpdateHandler(
            new ModelInstanceCommandRepository()
          ),
          'ModelInstanceDeleteCommand': new ModelInstanceDeleteHandler(
            new ModelInstanceCommandRepository()
          ),
          'ModelTreeElementDeleteCommand': new ModelTreeElementDeleteHandler(
            new ModelTreeElementCommandRepository()
          ),
          'ModelTreeElementGroupCreateCommand': new ModelTreeElementGroupCreateHandler(
            new ModelTreeElementCommandRepository()
          ),
          'ModelTreeElementModelCreateCommand': new ModelTreeElementModelCreateHandler(
            new ModelTreeElementCommandRepository()
          ),
          'ModelUpdateCommand': new ModelUpdateHandler(
            new ModelCommandRepository()
          ),
          'OpenWorldUpdateCommand': new OpenWorldUpdateHandler(
            new OpenWorldCommandRepository()
          ),
          'StyleUpdateCommand': new StyleUpdateHandler(
            new OpenWorldCommandRepository()
          ),
          'SetDefaultTexturesCommand': new SetDefaultTexturesHandler(
            new OpenWorldCommandRepository()
          ),
          'SetTerrainCommand': new SetTerrainHandler(
            new OpenWorldCommandRepository()
          ),
          'SetLandscapeCommand': new SetLandscapeHandler(
            new OpenWorldCommandRepository()
          ),
          'StopTilingCommand': new StopTilingHandler(
            new OpenWorldCommandRepository()
          ),
          'DigitalTwinProjectGroupUpdateCommand': new ProjectGroupUpdateHandler(
            new ProjectGroupCommandRepository()
          ),
          'DigitalTwinProjectTreeElementDeleteCommand': new ProjectTreeElementDeleteHandler(
            new ProjectTreeElementCommandRepository()
          ),
          'DigitalTwinProjectTreeElementGroupCreateCommand': new ProjectTreeElementGroupCreateHandler(
            new ProjectTreeElementCommandRepository()
          ),
          'DigitalTwinProjectTreeElementLayerCreateCommand': new ProjectTreeElementLayerCreateHandler(
            new ProjectTreeElementCommandRepository()
          ),
          'DigitalTwinProjectTreeElementModelCreateCommand': new ProjectTreeElementModelCreateHandler(
            new ProjectTreeElementCommandRepository()
          ),
          'DigitalTwinProjectTreeElementOpenWorldCreateCommand': new ProjectTreeElementOpenWorldCreateHandler(
            new ProjectTreeElementCommandRepository()
          ),
          'DigitalTwinProjectTreeElementProjectCreateCommand': new ProjectTreeElementProjectCreateHandler(
            new ProjectTreeElementCommandRepository()
          ),
          'DigitalTwinProjectUpdateCommand': new ProjectUpdateHandler(
            new ProjectCommandRepository()
          ),
          'DigitalTwinProjectBuildCommand': new ProjectBuildHandler(
            new ProjectCommandRepository()
          ),
          'DownloadLastBuildCommand': new DownloadLastBuildHandler(
            new ProjectCommandRepository()
          ),
          'DigitalTwinDownloadPrjCommand': new DownloadPrjHandler(
            new ProjectCommandRepository()
          )
        })
      ),
      queryBus: new QueryBus(
        new QueryBusHandlerLocator({
          'DigitalTwinFileByGuidQuery': new FileByGuidHandler(
            new FileQueryRepository()
          ),
          'ModelFilesQuery': new ModelFilesHandler(
            new FileQueryRepository()
          ),
          'DigitalTwinLayerByGuidQuery': new LayerByGuidHandler(
            new LayerQueryRepository()
          ),
          'ModelByGuidQuery': new ModelByGuidHandler(
            new ModelQueryRepository()
          ),
          'ModelGroupByGuidQuery': new ModelGroupByGuidHandler(
            new ModelGroupQueryRepository()
          ),
          'ModelTreeElementByGuidQuery': new ModelTreeElementByGuidHandler(
            new ModelTreeElementQueryRepository()
          ),
          'ModelTreeElementsQuery': new ModelTreeElementsHandler(
            new ModelTreeElementQueryRepository()
          ),
          'ModelInstanceByGuidQuery': new ModelInstanceByGuidHandler(
            new ModelInstanceQueryRepository()
          ),
          'OpenWorldByGuidQuery': new OpenWorldByGuidHandler(
            new OpenWorldQueryRepository()
          ),
          'OpenWorldGeojsonQuery': new OpenWorldGeojsonHandler(
            new OpenWorldQueryRepository()
          ),
          'OpenWorldStyleQuery': new StyleHandler(
            new OpenWorldQueryRepository()
          ),
          'DigitalTwinProjectByGuidQuery': new ProjectByGuidHandler(
            new ProjectQueryRepository()
          ),
          'DigitalTwinProjectGroupByGuidQuery': new ProjectGroupByGuidHandler(
            new ProjectGroupQueryRepository()
          ),
          'DigitalTwinProjectTreeElementByGuidQuery': new ProjectTreeElementByGuidHandler(
            new ProjectTreeElementQueryRepository()
          ),
          'DigitalTwinProjectTreeElementsQuery': new ProjectTreeElementsHandler(
            new ProjectTreeElementQueryRepository()
          ),
          'ProjectBuildsQuery': new ProjectBuildsHandler(
            new ProjectBuildsQueryRepository()
          ),
          'ProjectBuildsCountQuery': new ProjectBuildsCountHandler(
            new ProjectBuildsQueryRepository()
          ),
          'LandscapeTexturesQuery': new LandscapeTexturesHandler(
            new LandscapeTexturesQueryRepository()
          ),
          'LandscapeTexturesCountQuery': new LandscapeTexturesCountHandler(
            new LandscapeTexturesQueryRepository()
          ),
          'DigitalTwinTasksCountQuery': new TasksCountHandler(
            new TaskQueryRepository()
          ),
          'DigitalTwinTasksQuery': new TasksHandler(
            new TaskQueryRepository()
          ),
          'TexturesCountQuery': new TexturesCountHandler(
            new TextureQueryRepository()
          ),
          'TexturesQuery': new TexturesHandler(
            new TextureQueryRepository()
          ),
          'DigitalTwinTemplatesCountQuery': new TemplatesCountHandler(
            new TemplateQueryRepository()
          ),
          'DigitalTwinTemplatesQuery': new TemplatesHandler(
            new TemplateQueryRepository()
          )
        })
      ),
      activeTabName: "projects",
      leftSize: 50,
      rightSize: 50
    }    
  },
  provide() {
    return {
      getEventBus: this.getEventBus,
      getCommandBus: this.getCommandBus,
      getQueryBus: this.getQueryBus,
      getTreeIcon: this.getTreeIcon,
      expandTreeNode: this.expandTreeNode
    }
  },
  methods: {
    expandTreeNode (node) {
      node.expanded = true;
    },
    getTreeIcon(node) {
      if (node.element_type === 'open_world') {
        return 'el-icon-picture-outline-round';
      }
      if (node.element_type === 'project') {
        return 'el-icon-document';
      }  
      if (node.element_type === 'model') {
        return 'el-icon-s-grid';
      } 
      if (node.element_type === 'layer') {
        return 'el-icon-files';
      }
      if (!node.expanded) {
        return 'el-icon-folder'
      }
      return 'el-icon-folder-opened';
    },
    getEventBus() {
      return this.eventBus;
    },
    getCommandBus() {
      return this.commandBus;
    },
    getQueryBus() {
      return this.queryBus;
    }
  },
  mounted() {
  }
}
