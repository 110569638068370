import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ModelCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/ModelCommandRepositoryInterface'
import ModelUpdateCommand from '@/services/DigitalTwinEditor/application/command/ModelUpdateCommand'
import { ModelDTO } from '@/services/DigitalTwinEditor/domain/model/Model/Model'

export default class ModelUpdateHandler implements CommandHandlerInterface
{
    private repo: ModelCommandRepositoryInterface;

    constructor(repo: ModelCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: ModelUpdateCommand): Promise<void>
    {
        let item: ModelDTO = {
            guid: command.getGuid(),
            name: command.getName(),
            description: command.getDescription(),
            alias: command.getAlias(),
            coordinate_system_id: command.getCoordinateSystem(),
            position: command.getPosition(),
            rotation: command.getRotation(),
            files: command.getFiles(),
            parent_id: command.getParentId()
        };
        return this.repo.update(item);
    }
}