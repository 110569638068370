import { MutationTree } from 'vuex'
import { CommandState, initialState } from './state'
import { MutationTypes, CommandCreatedEvent, CommandUpdatedEvent, CommandDeletedEvent } from './mutation-types'

export const mutations: MutationTree<CommandState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false
    state.error = error
  },

  [MutationTypes.COMMAND_LOADING_EVENT]: (state) => {
    state.isLoading = true
  },

  [MutationTypes.COMMAND_LOADED_EVENT]: (state) => {
    state.isLoading = false
  },

  [MutationTypes.COMMAND_CREATED_EVENT]: (state, action: CommandCreatedEvent) => {
    state.isLoading = false
    state.location = action.location
  },

  [MutationTypes.COMMAND_UPDATED_EVENT]: (state, action: CommandUpdatedEvent) => {
    state.isLoading = false
  },

  [MutationTypes.COMMAND_DELETED_EVENT]: (state, action: CommandDeletedEvent) => {
    state.isLoading = false
    state.deletedCommandGuid = action.guid
  }
}
