import PackageCommandRepositoryInterface from '@/services/Databus/domain/repository/PackageCommandRepositoryInterface'
import store from '@/store'
import { DownloadPackage } from '@/services/Databus/infrastructure/store/modules/Package/action-types'

export default class PackageCommandRepository implements PackageCommandRepositoryInterface
{
    download(topic: string, guid: string, fileName: string): Promise<void>
    {
        return store.dispatch(new DownloadPackage(topic, guid, fileName));
    }
}
