import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { ThemeDTO } from '@/services/CssEditor/domain/model/Theme'

export namespace ThemeAPI {
  export class GetTheme implements APIRequest<ThemeDTO[]> {
    response: ThemeDTO[];
    path: string;
    method = HTTPMethod.GET;
    parse = (res: AxiosResponse) => res.data.map(item => new ThemeDTO(item))
    constructor (payload?: string[][]) {
      this.path = '/csseditor/themes'

      if (payload) {
        this.path += `?${new URLSearchParams(payload).toString()}`
      }
    }
  }

  export class GetThemeByGuid implements APIRequest<ThemeDTO> {
    response: ThemeDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => new ThemeDTO(data.data)
    constructor (guid: string) {
      this.path = `/csseditor/themes/${guid}`
    }
  }

  export class CreateTheme implements APIRequest<string> {
    response: string;
    path = '/csseditor/themes';
    method = HTTPMethod.POST;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.headers.location
    constructor (public params: ThemeDTO) {}
  }

  export class UpdateTheme implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.PUT;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: ThemeDTO) {
      this.path = `/csseditor/themes/${params.guid}`
    }
  }

  export class DeleteTheme implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.DELETE;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/csseditor/themes/${guid}`
    }
  }
}
