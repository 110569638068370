import { MutationTree } from 'vuex'
import { MappingState, initialState } from './state'
import { MutationTypes, MappingCreatedEvent, MappingUpdatedEvent, MappingDeletedEvent } from './mutation-types'

export const mutations: MutationTree<MappingState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false
    state.error = error
  },
  [MutationTypes.MAPPING_LOADING_EVENT]: (state) => {
    state.isLoading = true
  },
  [MutationTypes.MAPPING_LOADED_EVENT]: (state) => {
    state.isLoading = false
  },
  [MutationTypes.MAPPING_CREATED_EVENT]: (state, action: MappingCreatedEvent) => {
    state.isLoading = false
    state.location = action.location
  },
  [MutationTypes.MAPPING_UPDATED_EVENT]: (state, action: MappingUpdatedEvent) => {
    state.isLoading = false
  },
  [MutationTypes.MAPPING_DELETED_EVENT]: (state, action: MappingDeletedEvent) => {
    state.isLoading = false
    state.deletedMappingGuid = action.guid
  }
}
