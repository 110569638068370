export interface IProcedure {
  id?: number|null;
  guid?: string|null;
  name: string
  description: string|null;
  name_db: string;
  type: string;
  language: string;
  return_type: string;
  code: string;
  alias: string|null;
  logic_id?: number
  parent_tree_element_id?: number|null;
}

export class ProcedureDTO {
  id?: number|null;
  guid?: string|null;
  name: string
  description: string|null;
  name_db: string;
  type: string;
  language: string;
  return_type: string;
  code: string;
  alias: string|null;
  logic_id?: number
  parent_tree_element_id?: number|null;

  constructor (data: IProcedure) {
    this.id = data.id
    this.guid = data.guid
    this.name = data.name
    this.description = data.description
    this.name_db = data.name_db
    this.type = data.type
    this.language = data.language
    this.return_type = data.return_type
    this.code = data.code
    this.alias = data.alias
    this.logic_id = data.logic_id
    this.parent_tree_element_id = data.parent_tree_element_id
  }

  static create (): ProcedureDTO {
    return new ProcedureDTO({
      id: null,
      guid: null,
      name: null,
      description: null,
      name_db: 'my_procedure_or_function_1',
      type: 'function',
      code: 'BEGIN',
      return_type: 'string',
      language: 'sql',
      logic_id: null,
      alias: null,
      parent_tree_element_id: null
    })
  }
}
