import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { CoordinateSystemDTO } from '@/services/MapEditor/domain/model/Geometry/CoordinateSystem'

export enum ActionTypes {
    ADD_COORDINATE_SYSTEM = 'add_coordinate_system',
    UPDATE_COORDINATE_SYSTEM = 'update_coordinate_system',
    DELETE_COORDINATE_SYSTEM = 'delete_coordinate_system',
    DELETE_PROJECT_COORDINATE_SYSTEM = 'delete_project_coordinate_system',
    GET_COORDINATE_SYSTEMS = 'get_coordinate_systems',
    COUNT_COORDINATE_SYSTEMS = 'count_coordinate_systems',
    GET_COORDINATE_SYSTEM_BY_SRID = 'get_coordinate_system_by_srid',
    GET_COORDINATE_SYSTEM_BY_USER_ID = 'get_coordinate_system_by_user_id'
}

export class AddCoordinateSystem implements FluxStandardAction {
    type = 'CoordinateSystem/' + ActionTypes.ADD_COORDINATE_SYSTEM
    constructor(public payload: CoordinateSystemDTO){}
}

export class UpdateCoordinateSystem implements FluxStandardAction {
    type = 'CoordinateSystem/' + ActionTypes.UPDATE_COORDINATE_SYSTEM
    constructor(public payload: CoordinateSystemDTO){}
}

export class DeleteCoordinateSystem implements FluxStandardAction {
    type = 'CoordinateSystem/' + ActionTypes.DELETE_COORDINATE_SYSTEM
    constructor(public srid: number){}
}

export class DeleteProjectCoordinateSystem implements FluxStandardAction {
    type = 'CoordinateSystem/' + ActionTypes.DELETE_PROJECT_COORDINATE_SYSTEM
    constructor(public srid: number, public projectGuid: string){}
}

export class GetCoordinateSystems implements FluxStandardAction {
    type = 'CoordinateSystem/' + ActionTypes.GET_COORDINATE_SYSTEMS
    constructor(public payload: string[][]){}
}

export class CountCoordinateSystems implements FluxStandardAction {
    type = 'CoordinateSystem/' + ActionTypes.COUNT_COORDINATE_SYSTEMS
    constructor(public payload: string[][]){}
}

export class GetCoordinateSystemBySrid implements FluxStandardAction {
    type = 'CoordinateSystem/' + ActionTypes.GET_COORDINATE_SYSTEM_BY_SRID
    constructor(public srid: number){}
}

export class GetCoordinateSystemsByUserId implements FluxStandardAction {
    type = 'CoordinateSystem/' + ActionTypes.GET_COORDINATE_SYSTEM_BY_USER_ID
    constructor(public userId: number){}
}