import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ProjectTreeElementCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/ProjectTreeElementCommandRepositoryInterface'
import ProjectTreeElementLayerCreateCommand from '@/services/DigitalTwinEditor/application/command/ProjectTreeElementLayerCreateCommand'
import { LayerDTO } from '@/services/DigitalTwinEditor/domain/model/Layer/Layer'

export default class ProjectTreeElementLayerCreateHandler implements CommandHandlerInterface
{
    private repo: ProjectTreeElementCommandRepositoryInterface;

    constructor(repo: ProjectTreeElementCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: ProjectTreeElementLayerCreateCommand): Promise<void>
    {
        const item: LayerDTO = {
            name: command.getName(),
            alias: command.getAlias(),
            description: command.getDescription(),
            parent_id: command.getParentId()
        };
        return this.repo.insertLayer(item);
    }
}