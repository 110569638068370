import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    STROKE_STYLE_LOADING_EVENT = 'stroke_style_loading_event',
    STROKE_STYLE_LOADED_EVENT = 'stroke_style_loaded_event',
    STROKE_STYLE_CREATED_EVENT = 'stroke_style_created_event',
    STROKE_STYLE_UPDATED_EVENT = 'stroke_style_updated_event',
    STROKE_STYLE_DELETED_EVENT = 'stroke_style_deleted_event' 
}

export class StrokeStyleLoadingEvent implements FluxStandardAction {
    type = MutationTypes.STROKE_STYLE_LOADING_EVENT
}

export class StrokeStyleLoadedEvent implements FluxStandardAction {
    type = MutationTypes.STROKE_STYLE_LOADED_EVENT
}

export class StrokeStyleCreatedEvent implements FluxStandardAction {
    type = MutationTypes.STROKE_STYLE_CREATED_EVENT
    constructor(public location: string){}
}

export class StrokeStyleUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.STROKE_STYLE_UPDATED_EVENT
}

export class StrokeStyleDeletedEvent implements FluxStandardAction {
    type = MutationTypes.STROKE_STYLE_DELETED_EVENT
    constructor(public guid: string){}
}