import StyleCommandRepositoryInterface from '@/services/MapEditor/domain/repository/StyleCommandRepositoryInterface'
import { StyleDTO } from '@/services/MapEditor/domain/model/Style/Style'
import store from '@/store'
import { AddStyle, UpdateStyle, DeleteStyle } from '@/services/MapEditor/infrastructure/store/modules/Style/action-types'

export default class StyleCommandRepository implements StyleCommandRepositoryInterface
{
    insert(item: StyleDTO): Promise<void>
    {
        return store.dispatch(new AddStyle(item));
    }

    update(item: StyleDTO): Promise<void>
    {
        return store.dispatch(new UpdateStyle(item));
    }

    delete(guid: string): Promise<void>
    {
        return store.dispatch(new DeleteStyle(guid));
    }
}
