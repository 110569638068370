import { ActionTree } from 'vuex'
import { AffineTransformationState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, AffineTransformationCreatedEvent, AffineTransformationLoadingEvent, AffineTransformationDeletedEvent, AffineTransformationUpdatedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { AffineTransformationAPI } from '@/services/MapEditor/infrastructure/api/AffineTransformationAPI'

export const actions: ActionTree<AffineTransformationState, any> = {
    [ActionTypes.ADD_AFFINE_TRANSFORMATION]: async ({ commit, dispatch }, data) => {
        try {
            commit(new AffineTransformationLoadingEvent());
            let location = await APIClient.shared.request(new AffineTransformationAPI.AddAffineTransformation(data.payload));
            commit(new AffineTransformationCreatedEvent(location));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.UPDATE_AFFINE_TRANSFORMATION]: async ({ commit, dispatch }, data) => {
        try {
            commit(new AffineTransformationLoadingEvent());
            await APIClient.shared.request(new AffineTransformationAPI.UpdateAffineTransformation(data.payload));
            commit(new AffineTransformationUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.DELETE_AFFINE_TRANSFORMATION]: async ({ commit, dispatch }, data) => {
        try {
            await APIClient.shared.request(new AffineTransformationAPI.DeleteAffineTransformation(data.guid));
            commit(new AffineTransformationDeletedEvent(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }         
    },
    [ActionTypes.GET_AFFINE_TRANSFORMATION_BY_GUID]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new AffineTransformationAPI.GetAffineTransformationByGuid(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    }
}
