import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { RouteDTO } from '@/services/Databus/domain/model/Route/Route'

export namespace RouteAPI {

    export class AddRoute implements APIRequest<string> {
        response: string;
        path = '/databusservice/routes';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.headers.location
        constructor(public params: RouteDTO) {}
    }

    export class UpdateRoute implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: RouteDTO) {
            this.path = `/databusservice/routes/${params.guid}`;
        }
    }

    export class DeleteRoute implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.DELETE;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/databusservice/routes/${guid}`;
        }
    }

    export class CountRoutes implements APIRequest<RouteDTO> {
        response: RouteDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/databusservice/routes?*[func]=count&' + (new URLSearchParams(payload).toString())
        }
    }

    export class GetRoutes implements APIRequest<RouteDTO> {
        response: RouteDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/databusservice/routes?' + (new URLSearchParams(payload).toString())
        }
    }

    export class GetRouteByGuid implements APIRequest<RouteDTO> {
        response: RouteDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/databusservice/routes/${guid}`;
        }
    }
}