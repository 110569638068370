<template>
  <el-select
    v-model="localModel"
    filterable
    clearable
    @clear="localModel = null;$emit('change', localModel)"
    @change="$emit('change', localModel)"
  >
    <el-option
      v-for="(item, index) in components"
      :key="index"
      :label="`${item.name}`"
      :value="item.guid">
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: 'editor-frames',
  inject: ['getComponents'],
  props: ['value'],
  data () {
    return {
      localModel: this.value,
      components: this.getComponents().filter((item) => item.name === 'basic/a-frame')
    }
  }
}
</script>

<style scoped>

</style>
