import { ActionTree } from 'vuex'
import { EntityState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, EntitiesLoadingEvent, EntitiesLoadedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { EntityAPI } from '@/services/MapEditor/infrastructure/api/EntityAPI'

export const actions: ActionTree<EntityState, any> = {
    [ActionTypes.GET_ENTITIES]: async ({ commit, dispatch }, data) => {
        try {
            commit(new EntitiesLoadingEvent());
            let res = await APIClient.shared.request(new EntityAPI.GetEntities(data.payload));
            commit(new EntitiesLoadedEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.GET_ENTITY_BY_ID]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new EntityAPI.GetEntityById(data.id));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    }
}
