import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    COORDINATE_SYSTEM_LOADING_EVENT = 'coordinate_system_loading_event',
    COORDINATE_SYSTEM_LOADED_EVENT = 'coordinate_system_loaded_event',
    COORDINATE_SYSTEM_CREATED_EVENT = 'coordinate_system_created_event',
    COORDINATE_SYSTEM_UPDATED_EVENT = 'coordinate_system_updated_event',
    COORDINATE_SYSTEM_DELETED_EVENT = 'coordinate_system_deleted_event' 
}

export class CoordinateSystemLoadingEvent implements FluxStandardAction {
    type = MutationTypes.COORDINATE_SYSTEM_LOADING_EVENT
}

export class CoordinateSystemLoadedEvent implements FluxStandardAction {
    type = MutationTypes.COORDINATE_SYSTEM_LOADED_EVENT
}

export class CoordinateSystemCreatedEvent implements FluxStandardAction {
    type = MutationTypes.COORDINATE_SYSTEM_CREATED_EVENT
    constructor(public location: string){}
}

export class CoordinateSystemUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.COORDINATE_SYSTEM_UPDATED_EVENT
}

export class CoordinateSystemDeletedEvent implements FluxStandardAction {
    type = MutationTypes.COORDINATE_SYSTEM_DELETED_EVENT
    constructor(public srid: number){}
}