import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import ProjectBuildsQueryRepositoryInterface from '../../../domain/repository/ProjectBuildsQueryRepositoryInterface'
import ProjectBuildsCountQuery from '../../query/ProjectBuildsCountQuery'

export default class ProjectBuildsCountHandler implements QueryHandlerInterface {
    private repo: ProjectBuildsQueryRepositoryInterface;

    constructor (repo: ProjectBuildsQueryRepositoryInterface) {
      this.repo = repo
    }

    execute (query: ProjectBuildsCountQuery): Promise<any> {
      return this.repo.getCount(query.getPayload())
    }
}
