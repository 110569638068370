import { ActionTree } from 'vuex'
import { SourceState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, SourceLoadingEvent, SourceLoadedEvent, SourceCreatedEvent, SourceUpdatedEvent, SourceDeletedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { SourceAPI } from '@/services/MapEditor/infrastructure/api/SourceAPI'

export const actions: ActionTree<SourceState, any> = {
    [ActionTypes.ADD_SOURCE]: async ({ commit, dispatch }, data) => {
        try {
            commit(new SourceLoadingEvent());
            let location = await APIClient.shared.request(new SourceAPI.AddSource(data.payload));
            commit(new SourceCreatedEvent(location));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.UPDATE_SOURCE]: async ({ commit, dispatch }, data) => {
        try {
            commit(new SourceLoadingEvent());
            let location = await APIClient.shared.request(new SourceAPI.UpdateSource(data.payload));
            commit(new SourceUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.DELETE_SOURCE]: async ({ commit, dispatch }, data) => {
        try {
            await APIClient.shared.request(new SourceAPI.DeleteSource(data.guid));
            commit(new SourceDeletedEvent(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }         
    },
    [ActionTypes.DELETE_PROJECT_SOURCE]: async ({ commit, dispatch }, data) => {
        try {
            await APIClient.shared.request(new SourceAPI.DeleteProjectSource(data.id, data.projectGuid));
            commit(new SourceDeletedEvent(data.id));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }         
    },
    [ActionTypes.GET_SOURCES]: async ({ commit, dispatch }, data) => {
        try {
            commit(new SourceLoadingEvent());
            let res = await APIClient.shared.request(new SourceAPI.GetSources(data.payload));
            commit(new SourceLoadedEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.COUNT_SOURCES]: async ({ commit, dispatch }, data) => {
        try {
            commit(new SourceLoadingEvent());
            let res = await APIClient.shared.request(new SourceAPI.CountSources(data.payload));
            commit(new SourceLoadedEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.GET_SOURCE_BY_GUID]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new SourceAPI.GetSourceByGuid(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    },
    [ActionTypes.GET_SOURCE_PROJECTS]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new SourceAPI.GetSourceProjects(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    },
    [ActionTypes.GET_TYPES]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new SourceAPI.GetTypes());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.GET_TYPE_PROPERTIES]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new SourceAPI.GetTypeProperties(data.val));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    }
}
