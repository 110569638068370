import { ProjectGroupDTO } from '@/services/MapEditor/domain/model/Project/ProjectGroup'

export interface ProjectGroupState {
  isLoading: boolean
  error: object
}

export const initialState = (): ProjectGroupState => {
  return {
    isLoading: false,
    error: null
  }
}