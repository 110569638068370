<template>
  <el-form label-width="120px" label-position="top" size="mini" class="editor_calendar">
    <!-- {{value}} -->
    <el-form-item>
      <span class="property_label">{{ $locale.interface_editor.component_editor.table.source_type }}</span>
      <editor-select
        :value="value.type"
        @change="$set(value ,'type', $event)"
        :options="{
          multiple: false,
          options: [
            { id: 'extended_object', name: 'Расширенная таблица' },
            { id: null, name: 'Без источника' },
            { id: 'requests', name: 'Запросы' }
          ]
        }"
      ></editor-select>
    </el-form-item>
    <template v-if="value.type === 'extended_object'">
      <el-form-item>
        <span class="property_label">{{ $locale.interface_editor.component_editor.table.extended_object }}</span>
        <editor-select :value="value.extendObjectId" @change="$set(value ,'extendObjectId', $event)" :options="{multiple: false, options: listExtendObject}"></editor-select>
      </el-form-item>
      <el-form-item>
        <span class="property_label">{{ $locale.interface_editor.component_editor.editor_chart.value_field }}</span>
        <el-select
          v-model="valueField"
          value-key="name"
          @change="$set(value ,'valueField', $event)">
          <el-option
            v-for="(item, index) in filedExtendObject"
            :key="index"
            :label="item.name"
            :value="item">
          </el-option>
        </el-select>
      </el-form-item>
    </template>
    <template v-if="value.type === 'requests'">
      <el-form-item>
        <span class="property_label">{{ $locale.interface_editor.component_editor.table.request }}</span>
        <editor-select
          :value="value.requestsId"
          @change="$set(value ,'requestsId', $event)"
          :options="{
            multiple: false,
            options: requests
          }"
        ></editor-select>
      </el-form-item>
    </template>
  </el-form>
</template>

<script>
import EditorSelect from './editor-select'

export default {
  name: 'editor-list',
  components: {
    EditorSelect
  },
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      listExtendObject: [],
      filedExtendObject: [],
      requests: [],
      valueField: {}
    }
  },
  watch: {
    value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    },
    'value.extendObjectId': {
      async handler (value) {
        if (value) {
          this.valueField = {}
          let response = await this.$http.get(`${this.$config.api}/bieditor/extended_object_fields?extended_object_id=${value}`)
          this.filedExtendObject = response.data.map((el) => ({ id: el.id, name: el.name }))
        }
      }
    },
    'value.type': {
      async handler (val) {
        this.requests = []
        this.listExtendObject = []
        this.filedExtendObject = []
        this.$set(this.value, 'extendObjectId', null)
        this.$set(this.value, 'requestsId', null)
        if (val === 'requests') {
          this.requests = await this.getRequests()
        }
        if (val === 'extended_object') {
          this.listExtendObject = await this.getExtendObjects()
        }
      }
    }
  },
  async mounted () {
    this.listExtendObject = await this.getExtendObjects()
    if (this.value.extendObjectId) {
      let res = await this.$http.get(`${this.$config.api}/bieditor/extended_object_fields?extended_object_id=${this.value.extendObjectId}`)
      this.filedExtendObject = res.data.map((el) => ({ id: el.id, name: el.name }))
      this.valueField = this.value.valueField
    }
    if (this.value.requestsId) {
      this.requests = await this.getRequests()
    }
  },
  methods: {
    async getRequests () {
      let response = await this.$http.get(`${this.$config.api}/bieditor/queries`)
      return response.data
    },
    async getExtendObjects () {
      let response = await this.$http.get(`${this.$config.api}/bieditor/extended_objects`)
      return response.data
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
