import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import HistoryQueryRepositoryInterface from '@/services/History/domain/repository/HistoryQueryRepositoryInterface'
import { GetByRecord } from '@/services/History/infrastructure/store/modules/History/action-types'

export default class HistoryQueryRepository extends AbstractQueryRepository implements HistoryQueryRepositoryInterface {
  getByRecord (registryId: number, recordId: number): Promise<any> {
    return store.dispatch(new GetByRecord(registryId, recordId))
  }
}
