import { ActionTree } from 'vuex'
import { TextureState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, TextureLoadingEvent, TextureLoadedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { TextureAPI } from '@/services/DigitalTwinEditor/infrastructure/api/TextureAPI'

export const actions: ActionTree<TextureState, any> = {
  [ActionTypes.GET_TEXTURES]: async ({ commit, dispatch }, data) => {
    try {
      commit(new TextureLoadingEvent())
      let res = await APIClient.shared.request(new TextureAPI.GetTextures(data.payload))
      commit(new TextureLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.COUNT_TEXTURES]: async ({ commit, dispatch }, data) => {
    try {
      commit(new TextureLoadingEvent())
      let res = await APIClient.shared.request(new TextureAPI.CountTextures(data.payload))
      commit(new TextureLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  }
}
