export interface RecordHistoryState {
  isLoading: boolean;
  error: object;
}

export const initialState = (): RecordHistoryState => {
  return {
    isLoading: false,
    error: null
  }
}
