import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class RowFormulaDTO {
  id?: Nullable<number>
  guid?: Nullable<string>
  name: string
  description: Nullable<string>
  object_id: Nullable<number>
  alias_field_id: Nullable<number>
  target_alias_value: Nullable<string>
  value: Nullable<string>
  ast: Nullable<string>
  prepared_statement: Nullable<string>
  has_target_rows: boolean
  alias: Nullable<string>
  target_row_formula_columns?: Array<number>
  logic_id?: number
  parent_tree_element_id?: Nullable<number>

  constructor ({
    id, guid, name, description, object_id, alias_field_id, target_alias_value,
    value, ast, prepared_statement, has_target_rows,
    alias, target_row_formula_columns, logic_id, parent_tree_element_id
  }) {
    this.id = id
    this.guid = guid
    this.name = name
    this.description = description
    this.object_id = object_id
    this.alias_field_id = alias_field_id
    this.target_alias_value = target_alias_value
    this.value = value
    this.ast = ast
    this.prepared_statement = prepared_statement
    this.has_target_rows = has_target_rows
    this.alias = alias
    this.target_row_formula_columns = target_row_formula_columns
    this.logic_id = logic_id
    this.parent_tree_element_id = parent_tree_element_id
  }

  static create (): RowFormulaDTO {
    return new RowFormulaDTO({
      id: null,
      guid: null,
      name: null,
      description: null,
      object_id: null,
      alias_field_id: null,
      target_alias_value: null,
      value: null,
      ast: null,
      prepared_statement: null,
      has_target_rows: false,
      alias: null,
      target_row_formula_columns: [],
      logic_id: null,
      parent_tree_element_id: null
    })
  }
}

export default class RowFormula extends AbstractBaseEntity {
  private id?: Nullable<number>
  private guid?: Nullable<string>
  private name: string
  private description: Nullable<string>
  private objectId: number
  private aliasFieldId: number
  private targetAliasValue: string
  private value: string
  private ast: string
  private preparedStatement: string
  private hasTargetRows: boolean
  private logicId: number
  private alias: Nullable<string>
  private parentTreeElementId?: Nullable<number>

  constructor (
    name: string,
    description: Nullable<string>,
    objectId: number,
    aliasFieldId: number,
    targetAliasValue: string,
    value: string,
    ast: string,
    preparedStatement: string,
    hasTargetRows: boolean,
    logicId: number,
    alias: Nullable<string>,
    parentTreeElementId?: Nullable<number>,
    id?: Nullable<number>,
    guid?: Nullable<string>
  ) {
    super()
    this.id = id
    this.guid = guid
    this.name = name
    this.description = description
    this.objectId = objectId
    this.aliasFieldId = aliasFieldId
    this.targetAliasValue = targetAliasValue
    this.value = value
    this.ast = ast
    this.preparedStatement = preparedStatement
    this.hasTargetRows = hasTargetRows
    this.logicId = logicId
    this.alias = alias
    this.parentTreeElementId = parentTreeElementId
  }

  static create (dto: RowFormulaDTO): RowFormula {
    return new RowFormula(
      dto.name,
      dto.description,
      dto.object_id,
      dto.alias_field_id,
      dto.target_alias_value,
      dto.value,
      dto.ast,
      dto.prepared_statement,
      dto.has_target_rows,
      dto.logic_id,
      dto.alias,
      dto.parent_tree_element_id,
      dto.id,
      dto.guid
    )
  }

  setId (id: number): void {
    this.id = id
  }

  getId (): number {
    return this.id
  }

  setGuid (guid: string): void {
    this.guid = guid
  }

  getGuid (): string {
    return this.guid
  }

  getName (): string {
    return this.name
  }

  setName (name: string): void {
    this.name = name
  }

  getDescription (): string {
    return this.description
  }

  setDescription (description: string): void {
    this.description = description
  }

  getObjectId (): number {
    return this.objectId
  }

  setObjectId (objectId: number): void {
    this.objectId = objectId
  }

  getAliasFieldId (): number {
    return this.aliasFieldId
  }

  setAliasFieldId (aliasFieldId: number): void {
    this.aliasFieldId = aliasFieldId
  }

  getTargetAliasValue (): string {
    return this.targetAliasValue
  }

  setTargetAliasValue (targetAliasValue: string): void {
    this.targetAliasValue = targetAliasValue
  }

  getValue (): string {
    return this.value
  }

  setValue (value: string): void {
    this.value = value
  }

  getAst (): string {
    return this.ast
  }

  setAst (ast: string): void {
    this.ast = ast
  }

  getPreparedStatement (): string {
    return this.preparedStatement
  }

  setPreparedStatement (preparedStatement: string): void {
    this.preparedStatement = preparedStatement
  }

  getHasTargetRows (): boolean {
    return this.hasTargetRows
  }

  setHasTargetRows (hasTargetRows: boolean): void {
    this.hasTargetRows = hasTargetRows
  }

  getLogicId (): number {
    return this.logicId
  }

  setLogicId (logicId: number): void {
    this.logicId = logicId
  }

  getParentTreeElementId (): Nullable<number> {
    return this.parentTreeElementId
  }

  setParentTreeElementId (parentTreeElementId?: Nullable<number>): void {
    this.parentTreeElementId = parentTreeElementId
  }

  getAlias (): Nullable<string> {
    return this.alias
  }

  setAlias (alias: Nullable<string>): void {
    this.alias = alias
  }
}
