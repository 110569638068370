<template>
  <div class="editor_box object_editor">
    <el-container>
      <el-header height="">
        {{ $locale.object_editor.main.title }}
      </el-header>
      <el-container>
        <Split style="height: calc(100vh - 96px);">
          <SplitArea :size="50">
            <div class="tool_box">
              <el-tooltip
                slot="reference"
                class="item"
                effect="dark"
                :content="$locale.object_editor.tooltip.add_entity"
                placement="top">
                <el-dropdown placement="bottom-start" trigger="click" @command="addEntity($event)">
                  <el-button
                    icon="el-icon-plus"
                    size="small"
                    plain>
                    {{ $locale.main.button.add }}
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="type" v-for="(type, index) in getEntityTypes()" :key="index">{{ $locale.object_editor.entity_types[type] || type }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-tooltip>
              <el-input class="search_input"
                        @keyup.enter.native="onSearch"
                        clearable
                        placeholder="Поиск"
                        size="small"
                        style="width: calc(100% - 120px)"
                        v-model="searchText">
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  :loading="isSearchButtonLoading"
                  :disabled="isDisableButton"
                  @click="onSearch">
                </el-button>
              </el-input>
              <el-popover trigger="click">
                <el-form>
                  <el-form-item>
                    <span class="property_label">{{ $t('object_editor.toolbar.entity_types') }}</span>
                    <editor-select
                      ref="types_select"
                      :value="filterOptions.types.value"
                      @change="$set(filterOptions.types ,'value', $event)"
                      :options="{
                        multiple: true,
                        clearable: true,
                        options: filterOptions.types.data
                      }"
                    ></editor-select>

                    <span class="property_label">{{ $t('object_editor.toolbar.visibility.visibility_in_grid') }}</span>
                    <el-radio-group v-model="filterOptions.visibility_in_grid">
                      <el-radio :label="true">{{ $t('object_editor.toolbar.visibility.yes') }}</el-radio>
                      <el-radio :label="false">{{ $t('object_editor.toolbar.visibility.no') }}</el-radio>
                      <el-radio :label="null">{{ $t('object_editor.toolbar.visibility.nothing') }}</el-radio>
                    </el-radio-group>

                    <span class="property_label">{{ $t('object_editor.toolbar.visibility.visibility_in_card') }}</span>
                    <el-radio-group v-model="filterOptions.visibility_in_card">
                      <el-radio :label="true">{{ $t('object_editor.toolbar.visibility.yes') }}</el-radio>
                      <el-radio :label="false">{{ $t('object_editor.toolbar.visibility.no') }}</el-radio>
                      <el-radio :label="null">{{ $t('object_editor.toolbar.visibility.nothing') }}</el-radio>
                    </el-radio-group>
                    <el-form-item>
                      <el-checkbox v-model="filterOptions.isLoadTree">{{ $t('object_editor.toolbar.is_load_tree') }}</el-checkbox>
                    </el-form-item>
                    <el-form-item>
                      <el-checkbox v-model="filterOptions.isFormulaOnly">{{ $t('object_editor.toolbar.is_formula_only') }}</el-checkbox>
                    </el-form-item>
                  </el-form-item>
                  <el-button class="filter_button_apply" @click="onSearch">{{ $t('main.button.apply') }}</el-button>
                  <el-button class="filter_button_cancel" @click="clearFilters">
                    <font-awesome-icon icon="trash"></font-awesome-icon>
                  </el-button>
                </el-form>
                <el-button class="filter_button" slot="reference" @click="1" size="small">
                  <font-awesome-icon icon="filter"></font-awesome-icon>
                </el-button>
              </el-popover>
            </div>
            <el-scrollbar :style="{height:'calc(100vh - 153px)'}" >
              <el-tree
                ref="tree"
                :key="isSearch ? 1 : 2"
                :lazy="!isSearch"
                :data="searchData"
                :props="props"
                node-key="id"
                :load="loadNode"
                :expand-on-click-node="false"
                :default-expand-all="isSearch"
                :default-expanded-keys="isSearch ? undefined : expanded"
                draggable
                @node-drop="moveRow"
                :allow-drop="allowDrop"
                :allow-drag="allowDrag">
                <span class="custom-tree-node" slot-scope="{ node, data }">
                  <span
                    :title="node.label.length > 33 ? node.label +' '+ data.id : ''"
                    :class="{ 'selected-node': editorModel.id === data.id }"
                    @click="openEditor(data, node)"
                  >
                    <font-awesome-icon icon="calculator" style="padding-right: 3px" v-if="getFormulaIcon(node, data)"/>
                    <i style="font-size: 16px;" :class="getTreeIcon(node, data)"></i>
                    <b :class="{'pl-5': data.entity_type_id === 'registry_group'}">{{ node.label.length > 33 ? `${node.label.substring(0, 33)}...` : node.label }}</b>
                    <span style="color: #7C838B; padding-left: 6px;"> {{ $locale.object_editor.entity_types[data.entity_type_id] || data.entity_type_id }} id {{data.id}}</span>
                  </span>
                  <span v-if="editorModel.id === data.id">
                    <el-dropdown @command="addEntity($event, data.id)" v-show="(getEntityTypes(data.entity_type_id) || []).length" trigger="click">
                      <el-button type="text" size="small"><i class="el-icon-plus el-icon--left"></i>{{ $locale.main.button.add }}</el-button>
                      <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item :command="type" v-for="(type, index) in getEntityTypes(data.entity_type_id)" :key="index">{{ $locale.object_editor.entity_types[type] || type }}</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                    <el-button type="text" size="small" style="color:red" @click="deleteEntity(data.id)"><i class="el-icon-delete el-icon--left"></i>{{ $locale.main.button.delete }}</el-button>
                    <el-button v-show="data.entity_type_id === 'registry'" type="text" size="small" @click="addMainTab({name: data.name, componentType: 'Registry', payload: {id: data.id}})"><i class="el-icon-check el-icon--left"></i>{{ $locale.main.button.preview }}</el-button>
                  </span>
                </span>
              </el-tree>
            </el-scrollbar>
          </SplitArea>
          <SplitArea :size="50" style="background-color: #F5F7F9">
            <div v-show="showEditor" class="tool_box">
              <div class="tool_header">
                {{ nameObject }} (<b>{{ $locale.object_editor.entity_types[editorModel.entity_type_id]}}</b>)
              </div>
            </div>
            <el-tabs v-if="showEditor" v-model="activeTab">
              <el-tab-pane :label="$locale.object_editor.tabs.settings" name="settings">
                <el-scrollbar style="height: calc(100vh - 188px - 40px);">
                  <el-form ref="form" class="object_form" :model="editorModel" :rules="rules" size="mini" label-position="top">
                    <div class="start_logic" v-if="editorModel.entity_type_id === 'registry'">
                      <el-button @click="openLogicEditor(editorModel)" type="text">{{ $locale.main.button.open_logic_editor }}</el-button>
                    </div>
                    <el-form-item :label="$locale.main.fields.name" prop="name">
                      <el-input v-model="editorModel.name"></el-input>
                    </el-form-item>
                    <el-form-item :label="$locale.main.fields.description">
                      <el-input type="textarea" v-model="editorModel.description"></el-input>
                    </el-form-item>
                    <el-form-item :label="$locale.main.fields.alias">
                      <el-input v-model="editorModel.alias"></el-input>
                    </el-form-item>

                    <component
                      v-for="(property, index) in editorModel.properties"
                      :label="$locale.object_editor.settings.properties[property.id] || property.id"
                      :key="index + '_' + editorModel.id"
                      :is="property.type.trim()"
                      :disabled="property.id"
                      v-model="property.value"
                      :entity-id="editorModel.id"
                      :entity-type-id="editorModel.entity_type_id"
                      :properties="editorModel.properties">
                    </component>

                    <el-form-item>
                      <el-button
                        :loading="logicEditorEntities.dependencies.loading"
                        size="mini"
                        @click="getDependenciesByEntityId(editorModel.id, editorModel.entity_type_id)">
                        {{ $t('main.fields.load_dependency_options') }}
                      </el-button>
                    </el-form-item>

                    <div class="dependencies_form">
                      <div class="dependency_title_container">
                        <span class="property_title">{{ $t('main.fields.dependency_options') }}</span>
                        <el-popover trigger="click" placement="bottom">
                          <el-scrollbar style="height: calc(100vh - 80%); overflow-x: hidden">
                            <el-form-item
                              v-for="item in logicEditorEntities.dashboardAndCardDependencies"
                              :key="item.guid"
                            >
                              <span>{{ `(id - ${item.id} - ${item.type}) ${item.name}`}}</span>
                            </el-form-item>
                          </el-scrollbar>
                          <el-button size="small" slot="reference" @click="1" type="text">
                            {{ $t('main.button.dependencies') }}
                          </el-button>
                        </el-popover>
                      </div>

                      <div class="container">
                        <div class="dependencies_form title_container">
                          <span class="dependency_title">{{ $t('main.fields.extended_objects') }}</span>
                        </div>
                        <el-select
                          style="margin-right: 15px"
                          value-key="guid"
                          v-model="logicEditorEntities.dependencies.extendedObjectOrQuerySelected"
                          size="mini"
                        >
                          <el-option
                            v-for="item in logicEditorEntities.dependencies.extendedObjectsAndQueries"
                            :label="item.name"
                            :value="item">
                            <span style="float: left">{{ item.name }}</span>
                            <span style="float: right; color: #8492a6; font-size: 13px">{{ `(id: ${item.id}) ${item.type}` }}</span>
                          </el-option>
                        </el-select>
                        <el-button
                          :disabled="logicEditorEntities.dependencies.extendedObjectOrQuerySelected === null"
                          size="mini"
                          @click="openBiEditor"
                        ><i class="el-icon-view"></i></el-button>
                      </div>

                      <div class="container">
                        <div class="dependencies_form title_container">
                          <span class="dependency_title">{{ $t('etl_editor.menu.tasks') }}</span>
                        </div>
                        <el-select
                          style="margin-right: 15px"
                          value-key="guid"
                          v-model="logicEditorEntities.dependencies.taskSelected"
                          size="mini"
                        >
                          <el-option
                            v-for="item in logicEditorEntities.dependencies.tasks"
                            :label="item.name"
                            :value="item">
                            <span style="float: left">{{ item.name }}</span>
                            <span style="float: right; color: #8492a6; font-size: 13px">{{ `(id: ${item.id})` }}</span>
                          </el-option>
                        </el-select>
                        <el-button
                          :disabled="logicEditorEntities.dependencies.taskSelected === null"
                          size="mini"
                          @click="openEtlEditor"
                        ><i class="el-icon-view"></i></el-button>
                      </div>

                      <div class="container">
                        <div class="dependencies_form title_container">
                          <span class="dependency_title">{{ $t('main.notification.notify') }}</span>
                        </div>
                        <el-select
                          style="margin-right: 15px"
                          value-key="guid"
                          v-model="logicEditorEntities.dependencies.notificationSelected"
                          size="mini"
                        >
                          <el-option
                            v-for="item in logicEditorEntities.dependencies.notifications"
                            :label="item.name"
                            :value="item">
                            <span style="float: left">{{ item.name }}</span>
                            <span style="float: right; color: #8492a6; font-size: 13px">{{ `(id: ${item.id})` }}</span>
                          </el-option>
                        </el-select>
                        <el-button
                          :disabled="logicEditorEntities.dependencies.notificationSelected === null"
                          size="mini"
                          @click="openNotificationEditor"
                        ><i class="el-icon-view"></i></el-button>
                      </div>
                    </div>
                  </el-form>
                </el-scrollbar>
                <div style="text-align: right;padding-right: 10px">
                  <el-button
                    type="primary"
                    size="small"
                    @click="save()"
                    icon="el-icon-success">
                    {{ $locale.main.button.save }}
                  </el-button>
                </div>
              </el-tab-pane>
              <el-tab-pane v-if="editorModel.entity_type_id === 'registry'" :label="$locale.object_editor.tabs.cards" name="cards">
                <el-button style="margin: 15px" size="small" @click="addCard(editorModel.id, $locale.object_editor.cards.default_name, true)">
                  <i class="el-icon-plus el-icon--left"></i> {{ $t('main.button.add') }}
                </el-button>
                <el-scrollbar style="height: calc(100vh - 265px);">
                  <div class="object_form">
                    <div
                      v-for="item in cards"
                      :key="item.id"
                      class="card_new_form"
                    >
                      <div class="first_container">
                        <span class="property_title">{{ item.id }}</span>
                      </div>
                      <div class="second_container">
                        <div v-if="!item.editing" style="display: flex;flex-direction: column; margin: 10px 0 10px 0;">
                          <span class="property_title">{{ item.name }}</span>
                          <span v-if="item.alias">{{ item.alias }}</span>
                        </div>

                        <div v-else>
                          <el-input size="small" v-model="item.name" :placeholder="$t('main.fields.name')"></el-input>
                          <el-input size="small" v-model="item.alias" :placeholder="$t('main.fields.alias')"></el-input>
                        </div>

                        <div style="margin: 5px 0 5px 0;">
                          <span style="color: #788591;">{{ $t('object_editor.tabs.state') }}</span>
                          <el-select
                            v-model="item.card_states"
                            size="mini"
                            multiple
                            collapse-tags
                            clearable
                            filterable
                            :disabled="!item.editing"
                          >
                            <el-option
                              v-for="card in cardStates"
                              :key="card.id"
                              :label="card.name"
                              :value="card.id">
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                      <div class="third_container">
                        <div style="display: flex; justify-content: flex-end; margin: 10px 0 10px 0;">
                          <el-checkbox v-if="!item.editing" :value="item.is_default">{{ $t('main.fields.default') }}</el-checkbox>
                          <el-checkbox v-else v-model="item.is_default">{{ $t('main.fields.default') }}</el-checkbox>
                        </div>

                        <div style="margin: 5px 0 5px 0;">
                          <span style="color: #788591;">{{ $t('main.fields.role') }}</span>
                          <el-select
                            v-model="item.card_roles"
                            size="mini"
                            multiple
                            collapse-tags
                            clearable
                            filterable
                            :disabled="!item.editing"
                          >
                            <el-option
                              v-for="role in cardRoles"
                              :key="role.id"
                              :label="role.name"
                              :value="role.id">
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                      <div class="fourth_container">
                        <el-button
                          v-if="item.editing"
                          type="primary"
                          size="mini"
                          @click="$set(item, 'editing', false);editCard(item)"><i class="el-icon-check"></i>
                        </el-button>
                        <el-tooltip v-if="!item.editing" :content="$t('main.tooltip.open_card')" placement="top">
                          <el-button
                            size="mini"
                            @click="openCardEditor(item.id)"><i class="el-icon-arrow-right"></i>
                          </el-button>
                        </el-tooltip>
                        <el-popover v-if="item.editing" trigger="click" placement="top">
                          <div style="text-align: right; margin: 0">
                            <el-button type="primary" size="mini" @click="deleteCard(item.id)">{{ $t('main.button.delete') }}</el-button>
                            <el-button size="mini" type="text" @click="$set(item, 'editing', false)">{{ $t('main.button.cancel') }}</el-button>
                          </div>
                          <el-button type="danger" slot="reference" size="mini" @click="1">
                            <i class="el-icon-delete"></i>
                          </el-button>
                        </el-popover>
                        <!--                        <el-button
                                                  size="mini"
                                                  type="info"
                                                  v-show="item.editing"
                                                  @click="$set(item, 'editing', false)"><i class="el-icon-close"></i>
                                                </el-button>-->
                        <el-tooltip v-if="!item.editing" :content="$t('main.tooltip.edit_card_properties')" placement="bottom">
                          <el-button
                            size="mini"
                            @click="$set(item, 'editing', true)"><i class="el-icon-edit"></i>
                          </el-button>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </el-scrollbar>
              </el-tab-pane>
              <el-tab-pane
                v-if="!isFormulaAllowed"
                :label="$t('object_editor.tabs.formula')"
                name="formula"
                lazy
              >
                <div class="extended_object" v-if="isNewFormula">
                  <div>
                    <div class="di-card">
                      <div class="di-card__body">
                        <el-scrollbar style="height: 100%;">
                          <formula-form
                            ref="formulaForm"
                            v-model="logicEditorEntities.formula"
                            :object-id="editorModel.object_id"
                            :logic-id="logicEditorEntities.logicId"
                            disabled-entity-id
                          ></formula-form>
                        </el-scrollbar>
                      </div>
                      <div class="di-card__footer">
                        <el-popover trigger="click" placement="top" v-model="logicEditorEntities.isVisiblePopoverFormulaDelete">
                          <div style="text-align: right; margin: 0">
                            <el-button type="primary" size="mini" @click="deleteFormula">{{ $t('main.button.delete') }}</el-button>
                            <el-button size="mini" type="text" @click="logicEditorEntities.isVisiblePopoverFormulaDelete = false">{{ $t('main.button.cancel') }}</el-button>
                          </div>
                          <el-button type="danger" size="small" slot="reference" @click="1">
                            {{ $t('main.button.delete') }}
                          </el-button>
                        </el-popover>

                        <div>
                          <el-button @click="recalculateFormula(logicEditorEntities.formula.guid)" icon="el-icon-success" type="default" size="small">
                            {{ $t('logic_editor.column_formula.pre_count') }}
                          </el-button>

                          <el-button type="primary" size="small" @click="saveFormula">
                            {{ $t('main.button.save') }}
                          </el-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <el-button @click="createFormulaDTO" icon="el-icon-success" type="primary" size="small">
                    {{ $t('main.button.add') }}
                  </el-button>
                </div>
              </el-tab-pane>
              <el-tab-pane
                v-if="!isStateAllowed"
                :label="$t('object_editor.tabs.state')"
                name="state"
              >
                <el-select v-model="logicEditorEntities.stateGuid" style="margin: 5px" size="mini" clearable filterable>
                  <el-option
                    v-for="item in logicEditorEntities.states"
                    :key="item.guid"
                    :label="item.name"
                    :value="item.guid">
                  </el-option>
                </el-select>

                <template v-if="isStateLoaded && logicEditorEntities.stateGuid">
                  <div>
                    <div class="di-card">
                      <div class="di-card__body">
                        <el-scrollbar style="height: 100%;">
                          <state-form
                            ref="stateForm"
                            v-model="logicEditorEntities.activeState"
                            :object-id="stateObjectId"
                          ></state-form>
                        </el-scrollbar>
                      </div>
                      <div class="di-card__footer">
                        <el-button @click="saveState" icon="el-icon-success" type="primary" size="small">
                          {{ $t('main.button.save') }}
                        </el-button>

                        <el-button @click="deleteState" icon="el-icon-delete" type="danger" size="small">
                          {{ $t('main.button.delete') }}
                        </el-button>
                      </div>
                    </div>
                  </div>
                </template>
              </el-tab-pane>
            </el-tabs>
          </SplitArea>
        </Split>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Entity from './Models/Entity'
import Node from 'element-ui/packages/tree/src/model/node'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCalculator, faFilter } from '@fortawesome/free-solid-svg-icons'

import boolean from './Properties/boolean'
import varchar from './Properties/varchar'
import integer from './Properties/integer'
import coordinate_system from './Properties/coordinate_system'
import geometry_type from './Properties/geometry_type'
import field from './Properties/field'
import field_array from './Properties/field_array'
import card from './Properties/card'
import unique_fields from './Properties/unique_fields'
import partition_field from './Properties/partition_field'
import address_level from './Properties/address_level'
import object_level from './Properties/object_level'
import address_type from './Properties/address_type'
import card_field from './Properties/card_field'
import Type from './Models/Type'
import Card from './Models/Card'

// API
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { RoleAPI } from '@/services/AccessEditor/infrastructure/api/RoleAPI'
import MainMixin from '@/services/LogicEditor/infrastructure/mixins/MainMixin.ts'
import FormulaForm from '../../services/LogicEditor/infrastructure/components/forms/FormulaForm.vue'
import StateForm from '@/services/LogicEditor/infrastructure/components/forms/StateForm/index.vue'
import { LogicEditorAPI } from '@/core/infrastructure/api/modules/LogicEditorAPI'
import { StateAPI } from '@/services/LogicEditor/infrastructure/api/StateAPI'
import { FormulaDTO } from '@/services/LogicEditor/domain/model/Formula'
import { FormulaAPI } from '@/services/LogicEditor/infrastructure/api/FormulaAPI'
import EditorSelect from '@/components/InterfaceEditor/components/editor/editor-select'

library.add(faCalculator, faFilter)

export default {
  name: 'ObjectEditor',

  mixins: [MainMixin],

  props: {
    entityId: {
      type: Number,
      default: null
    },
    expanded: {
      type: Array,
      default () {
        return []
      }
    }
  },

  components: {
    boolean,
    varchar,
    integer,
    field,
    field_array,
    card,
    unique_fields,
    coordinate_system,
    geometry_type,
    partition_field,
    address_level,
    object_level,
    address_type,
    card_field,
    FontAwesomeIcon,
    FormulaForm,
    StateForm,
    EditorSelect
  },

  inject: ['addMainTab'],

  data () {
    return {
      activeTab: 'settings',
      activeStateTab: '',
      loading: false,
      props: {
        isLeaf: 'leaf',
        label: 'name',
        children: 'child'
      },
      showEditor: false,
      editorModel: {},
      cards: [],
      activeNode: null,
      entityTypes: {},
      cardRoles: [],
      cardStates: [],
      rules: {
        name: [{ required: true, message: this.$locale.object_editor.rules.name, trigger: 'blur' }]
      },
      isSearch: false,
      searchText: undefined,
      searchData: [],
      isSearchButtonLoading: null,
      isDisableButton: true,
      defOpen: false,
      registryLogicGuid: null,
      logicEditorEntities: {
        formula: {},
        states: [],
        stateGuid: null,
        activeState: {},
        stateLoaded: false,
        logicId: null,
        formulaExcludingEntityTypes: [
          'registry',
          'registry_group',
          'field_group'
        ],
        stateExcludingEntityTypes: [
          'registry_group',
          'field_group'
        ],
        dashboardAndCardDependencies: [],
        dependencies: {
          extendedObjectOrQuerySelected: null,
          extendedObjectsAndQueries: [],
          notificationSelected: null,
          notifications: [],
          taskSelected: null,
          tasks: [],
          loading: false
        },
        isVisiblePopoverFormulaDelete: false,
        isFormulaLoaded: false
      },
      filterOptions: {
        types: {
          data: [],
          value: []
        },
        visibility_in_card: null,
        visibility_in_grid: null,
        isLoadTree: true,
        isFormulaOnly: false
      }
    }
  },

  computed: {
    nameObject () {
      let name = this.editorModel.name
      if (this.editorModel.entity_type_id === 'registry') {
        name += ` object_${this.editorModel.id}_ `
      } else if (this.editorModel.entity_type_id !== 'field_group' && this.editorModel.entity_type_id !== 'registry_group') {
        name += ` attr_${this.editorModel.id}_ `
      } else {
        name += ''
      }
      return name
    },

    isFormulaAllowed () {
      return this.logicEditorEntities.formulaExcludingEntityTypes.includes(this.editorModel.entity_type_id)
    },

    isStateAllowed () {
      return this.logicEditorEntities.stateExcludingEntityTypes.includes(this.editorModel.entity_type_id)
    },

    isStateLoaded () {
      return this.logicEditorEntities.stateLoaded
    },

    stateObjectId () {
      return this.editorModel.object_id ?? this.editorModel.id
    },

    isNewFormula () {
      return this.logicEditorEntities.formula.hasOwnProperty('guid')
    }
  },

  mounted () {
    this.loading = true

    if (this.entityId) {
      this.defOpen = true
    }

    this.getAllEntityTypes()

    this.loading = false
  },

  watch: {
    searchText (value) {
      if (value.length === 0) {
        this.isSearch = false
        this.isDisableButton = true
        this.isSearchButtonLoading = false
      } else {
        this.isDisableButton = false
      }
    },

    'editorModel': {
      async handler (value) {
        this.logicEditorEntities.stateLoaded = false
        this.logicEditorEntities.activeState = {}
        this.logicEditorEntities.stateGuid = null
        this.logicEditorEntities.formula = {
          sql_statements: {
            select: '',
            join: '',
            group_by: ''
          }
        }
        if (!this.logicEditorEntities.formulaExcludingEntityTypes.includes(value.entity_type_id)) {
          let formula = value.properties.find(property => property.id === 'formula_id')
          if (typeof formula !== 'undefined' && formula.value !== null) {
            this.logicEditorEntities.formula = await APIClient.shared.request(new LogicEditorAPI.GetFormulaById(formula.value))
          }
          await this.loadStates(value.object_id)
        }
        await this.clearDependencies()
      }
    },

    'logicEditorEntities.stateGuid': {
      handler (guid) {
        if (guid) {
          this.logicEditorEntities.stateLoaded = false
          APIClient.shared.request(new StateAPI.GetStateByGuid(guid)).then((response) => {
            this.logicEditorEntities.stateLoaded = true
            this.logicEditorEntities.activeState = response
          })
        }
      }
    }
  },

  methods: {
    async onSearch () {
      if (
        this.searchText ||
        this.filterOptions.types.value.length > 0 ||
        this.filterOptions.visibility_in_grid ||
        this.filterOptions.visibility_in_card ||
        this.filterOptions.isFormulaOnly
      ) {
        this.isSearchButtonLoading = true
        this.isSearch = true
        this.loading = true
        this.$nextTick(async () => {
          let data = await this.$http.get(
            `${this.$config.api}/objecteditor/entities/search`,
            {
              params: {
                search_param: this.searchText,
                types: this.filterOptions.types.value,
                is_load_tree: this.filterOptions.isLoadTree,
                is_formula: this.filterOptions.isFormulaOnly,
                visibility_in_grid: this.filterOptions.visibility_in_grid,
                visibility_in_card: this.filterOptions.visibility_in_card
              }
            }
          )
          this.searchData = data.data.data
          this.loading = false
          this.isSearchButtonLoading = false
        })
      }
    },

    async loadNode (node, resolve) {
      console.log(node)
      if (node.level === 0) {
        resolve(await this.loadEntities())
      } else {
        resolve(await this.loadEntities(node.data.id))
      }

      if (this.entityId && this.defOpen) {
        const n = this.$refs.tree.getNode(this.entityId)
        /*if (n) {
          await this.openEditor(n.data, n)
        }*/
      }
    },

    async loadEntities (entityId = null) {
      let data = []
      if (!entityId) {
        data = await new Entity().params({ root: true }).$get()
      } else {
        data = await new Entity({ id: entityId }).children().$get()
      }

      return data
    },

    async openEditor (data, node) {
      this.defOpen = false
      let entity = await Entity.$find(data.id)
      if (entity) {
        this.editorModel = entity
        this.showEditor = true
        this.activeNode = node
        this.activeTab = 'settings'
        if (entity.entity_type_id === 'registry') {
          await this.loadCards(data.id)
        }
      }
    },

    async loadCards (entityId) {
      let cards = await new Card().params({ entity_id: entityId, order: 'id', fields: ['id', 'name', 'is_default', 'entity_id', 'guid', 'alias', 'card_roles', 'card_states'] }).$get()
      this.cards = cards.map((item) => {
        item.card_roles = JSON.parse(item.card_roles)
        item.card_states = JSON.parse(item.card_states)
        return item
      })
      if (this.cardRoles.length === 0) {
        await this.loadRoles()
      }
      await this.loadStates(entityId)
    },

    async loadRoles () {
      // this.$http.get(`${this.$config.api}/accesseditor/roles`).then((response) => {
      //   this.cardRoles = response.data
      // })
      try {
        this.cardRoles = await APIClient.shared.request(new RoleAPI.GetRoles([]))
      } catch (error) {
        console.log({ error })
      }
    },

    async loadStates (registryId) {
      this.cardStates = []
      let logicData = await this.$http.get(`${this.$config.api}/v2/logiceditor/logic?entity_id=${registryId}`)
      let logicId = (logicData.data[0] || {}).id
      if (logicId) {
        let data = await this.$http.get(`${this.$config.api}/v2/logiceditor/states?logic_id=${logicId}`)
        let logicData = await this.$http.get(`${this.$config.api}/v2/logiceditor/logic_by_entity/${registryId}`)
        this.cardStates = data.data
        this.logicEditorEntities.logicId = logicId
        this.$set(this, 'registryLogicGuid', logicData.data.guid)
      }
    },

    addCard (entityId, name, isNewDesigner = false) {
      if (!entityId || !name) {
        return false
      }
      let card = new Card({})
      card.entity_id = entityId
      card.name = name
      card.save().then(async (response) => {
        if (isNewDesigner) {
          card.id = response.id
          card.sync(Object.assign(response, { structure: { version: 2 } }))
        }
        let newCard = await new Card().params({ fields: 'id,name,alias,is_default,entity_id,guid' }).find(response.id)
        this.$set(newCard, 'editing', true)
        this.cards.push(newCard)
      })
    },

    async deleteCard (cardId) {
      if (!cardId) {
        return false
      }
      let card = new Card({ id: cardId })
      await card.delete()
      this.cards = this.cards.filter((card) => { return card.id !== cardId })
    },

    openCardEditor (cardId) {
      if (!cardId || !this.editorModel.id) {
        return false
      }
      this.addMainTab({ name: this.cards.find((item) => { return item.id === cardId }).name,
        componentType: 'CardEditor',
        payload: {
          registry_id: this.editorModel.id,
          card_id: cardId
        } })
    },

    async editCard (card) {
      await card.save()
    },

    save () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (!this.editorModel.id) {
            return false
          }
          let entity = new Entity({ id: this.editorModel.id })
          entity.name = this.editorModel.name
          entity.description = this.editorModel.description
          entity.alias = this.editorModel.alias
          entity.properties = this.editorModel.properties
          let response = await entity.save()
          this.$set(this.activeNode.data, 'name', response.name)
        }
      })
    },

    getEntityTypes (parentType = null) {
      if (!parentType) {
        if (!this.entityTypes['root']) {
          new Type().params({ root: true }).$get().then((data) => {
            this.$set(this.entityTypes, 'root', data.map((item) => item.name()))
          })
        }
        return this.entityTypes['root']
      } else {
        if (!this.entityTypes[parentType]) {
          new Type({ id: parentType }).children().$get().then((data) => {
            this.$set(this.entityTypes, parentType, data.map((item) => item.name()))
          })
        }
        return this.entityTypes[parentType]
      }
    },

    async getAllEntityTypes () {
      this.$http.get(`${this.$config.api}/objecteditor/types/all`).then((response) => {
        this.$set(this.filterOptions.types, 'data', response.data.data.map((item) => {
          return { id: item, name: this.$t(`object_editor.entity_types.${item}`) }
        }))
      })
    },

    addEntity (type, parent = null) {
      this.$prompt(this.$locale.object_editor.add_entity.name_field, `${this.$locale.object_editor.add_entity.title} (${this.$locale.object_editor.entity_types[type] || type})`, {
        confirmButtonText: this.$locale.main.button.add,
        cancelButtonText: this.$locale.main.button.cancel,
        closeOnClickModal: false,
        inputValidator (value) {
          return !!(value || '').trim()
        }
      }).then(async ({ value }) => {
        let entity = new Entity({})
        entity.name = value
        entity.type_id = type
        entity.parent_id = parent
        let response = await entity.save()
        if (parent) {
          this.$refs.tree.append(response, parent)
        } else {
          let node = new Node({ parent: this.$refs.root, store: this.$refs.tree.store, data: response })
          node.level = 1
          this.$refs.tree.root.childNodes.push(node)
        }
      }).catch((error) => { console.log(error) })
    },

    deleteEntity (entityId) {
      if (!entityId) {
        return false
      }
      this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
        confirmButtonText: this.$locale.main.button.delete,
        cancelButtonText: this.$locale.main.button.cancel,
        type: 'warning'
      }).then(async () => {
        let entity = new Entity({ id: entityId })
        await entity.delete()
        this.$refs.tree.remove(entityId)
      }).catch((error) => { console.log(error) })
    },

    allowDrag (draggingNode) {
      // нельзя захватить registry_group
      if (draggingNode.data.entity_type_id === 'registry_group') {
        return false
      }
      return true
    },

    allowDrop (draggingNode, dropNode, type) {
      // console.log('draggingNode', draggingNode.data.parent_id)
      // console.log('dropNode', dropNode)
      // console.log('type', type)
      if (dropNode.data.entity_type_id === 'registry_group') {
        return false
      } else if (draggingNode.data.object_id !== dropNode.data.object_id) {
        return false
      } else if (draggingNode.data.parent_id === null || dropNode.data.parent_id === null) {
        return false
      } else if (type === 'inner' && draggingNode.data.entity_type_id === 'registry') {
        return false
      } else if (type === 'inner' && draggingNode.data.entity_type_id !== 'registry') {
        return false
      } else {
        return true
      }
    },

    async moveRow (draggingNode, dropNode, type) {
      let rowOrder = this.mediumRowOrder(draggingNode, dropNode)
      let out = { row_order: rowOrder, parent_id: (type === 'inner' ? dropNode.data.id : dropNode.data.parent_id) }
      draggingNode.data.parent_id = out.parent_id
      draggingNode.data.row_order = out.row_order || draggingNode.data.row_order
      await this.$http.put(`${this.$config.api}/objecteditor/entities/${draggingNode.data.id}/order`, out)
    },

    // средние значение row_order
    mediumRowOrder (draggingNode, dropNode) {
      if (draggingNode.data.parent_id === dropNode.data.parent_id) {
        // console.log('перенос внутри родителя')
        const a = dropNode.parent.childNodes
        let rowOrder = this.rowOrderMethods(draggingNode, a)
        return rowOrder
      } else {
        const a = dropNode.parent.childNodes
        // console.log('вынос за родителя', a)
        let rowOrder = this.rowOrderMethods(draggingNode, a)
        return rowOrder
      }
    },

    rowOrderMethods (draggingNode, a) {
      let rowOrder
      for (let i = 0; i < a.length; i++) {
        if (draggingNode.data.id === a[i].data.id) {
          if (!a[i - 1]) {
            // console.log('сосед сверху не найден')
            rowOrder = Math.round((a[i + 1].data.row_order - 3333))
          }
          if (!a[i + 1]) {
            // console.log('сосед снизу не найден')
            rowOrder = Math.round((1001 + a[i - 1].data.row_order))
          }
          if (a[i + 1] && a[i - 1]) {
            rowOrder = Math.round((a[i + 1].data.row_order + a[i - 1].data.row_order) / 2)
          }
        }
      }
      return rowOrder
    },

    openLogicEditor (model) {
      this.addMainTab({
        name: 'Редактор бизнес-логики',
        componentType: 'LogicEditor_v2',
        payload: { externalEntityGuid: this.registryLogicGuid }
      })
    },

    getTreeIcon (node, data) {
      if (data.entity_type_id === 'registry_group' && !node.expanded) {
        return 'el-icon-folder'
      } else if (data.entity_type_id === 'registry_group' && node.expanded) {
        return 'el-icon-folder-opened'
      }
    },

    getFormulaIcon (node, data) {
      return !!(data.properties && data.properties.find(property => property.id === 'formula_id') && data.properties.find(property => property.id === 'formula_id').value)
    },

    async saveFormula () {
      let me = this
      this.$refs.formulaForm.submit(async function () {
        me.editorModel = await Entity.$find(me.editorModel.id)
      })
    },

    saveState () {
      this.$refs.stateForm.submit(function () {})
    },

    deleteState () {
      APIClient.shared.request(new StateAPI.DeleteState(this.logicEditorEntities.activeState.guid)).then((response) => {
        this.loadStates(this.stateObjectId)
        this.logicEditorEntities.stateLoaded = false
        this.logicEditorEntities.activeState = {}
        this.logicEditorEntities.stateGuid = null
      })
    },

    recalculateFormula (guid) {
      this.$http.post(`${this.$config.api}/v2/logiceditor/formulas/${guid}/recalculate`)
    },

    async createFormulaDTO () {
      this.logicEditorEntities.formula = await FormulaDTO.create('column_formula', this.editorModel.id)
      this.$set(this.logicEditorEntities.formula, 'logic_id', this.logicEditorEntities.logicId)
      this.$set(this.logicEditorEntities.formula, 'name', this.editorModel.name)
    },

    deleteFormula () {
      APIClient.shared.request(new FormulaAPI.DeleteFormula(this.logicEditorEntities.formula.guid)).then((response) => {
        this.$set(this.logicEditorEntities, 'formula', {})
      })
    },

    async getDependenciesByEntityId (entityId, type) {
      if (!this.logicEditorEntities.stateExcludingEntityTypes.includes(type)) {
        await this.clearDependencies()
        this.logicEditorEntities.dependencies.loading = true

        this.$http.get(`${this.$config.api}/objecteditor/entities/${entityId}/dependencies`).then((response) => {
          let cards = JSON.parse(response.data.data.cards)
          let dashboards = JSON.parse(response.data.data.dashboards)
          let states = JSON.parse(response.data.data.states)
          let extendedObjects = JSON.parse(response.data.data.extended_objects)
          let queries = JSON.parse(response.data.data.queries)
          let notifications = JSON.parse(response.data.data.notifications)
          let tasks = JSON.parse(response.data.data.tasks)

          if (cards.length > 0) {
            cards.map((item) => {
              item.type = 'карточка'
              this.logicEditorEntities.dashboardAndCardDependencies.push(item)
            })
          }
          if (dashboards.length > 0) {
            dashboards.map((item) => {
              item.type = 'дашборд'
              this.logicEditorEntities.dashboardAndCardDependencies.push(item)
            })
          }
          if (states.length > 0) {
            states.map((item) => {
              this.logicEditorEntities.states.push(item)
            })
          }
          if (extendedObjects.length > 0) {
            extendedObjects.map((item) => {
              item.type = 'extended_object'
              this.logicEditorEntities.dependencies.extendedObjectsAndQueries.push(item)
            })
          }
          if (queries.length > 0) {
            queries.map((item) => {
              item.type = 'query'
              this.logicEditorEntities.dependencies.extendedObjectsAndQueries.push(item)
            })
          }
          if (notifications.length > 0) {
            notifications.map((item) => {
              this.logicEditorEntities.dependencies.notifications.push(item)
            })
          }
          if (tasks.length > 0) {
            tasks.map((item) => {
              this.logicEditorEntities.dependencies.tasks.push(item)
            })
          }

          this.logicEditorEntities.dependencies.loading = false
        })
      }
    },

    openBiEditor () {
      this.addMainTab({
        name: this.logicEditorEntities.dependencies.extendedObjectOrQuerySelected.name,
        componentType: 'BIEditor',
        payload: { externalExtendedObject: this.logicEditorEntities.dependencies.extendedObjectOrQuerySelected }
      })
    },

    openNotificationEditor () {
      this.addMainTab({
        name: 'Редактор уведомлений',
        componentType: 'NotificationEditor',
        payload: { externalNotificationGuid: this.logicEditorEntities.dependencies.notificationSelected.guid }
      })
    },

    openEtlEditor () {
      this.addMainTab({
        name: 'Редактор импорта/экспорта',
        componentType: 'EtlEditor',
        payload: { externalTaskId: this.logicEditorEntities.dependencies.taskSelected.id }
      })
    },

    async clearFilters () {
      this.$refs.types_select.clearValue()
      this.$set(this.filterOptions.types, 'value', [])
      this.$set(this.filterOptions, 'isLoadTree', true)
      this.$set(this.filterOptions, 'isFormulaOnly', false)
      this.$set(this.filterOptions, 'visibility_in_grid', null)
      this.$set(this.filterOptions, 'visibility_in_card', null)
      this.$set(this, 'isSearch', false)
    },

    async clearDependencies () {
      this.$set(this.logicEditorEntities, 'dashboardAndCardDependencies', [])
      this.$set(this.logicEditorEntities, 'states', [])
      this.$set(this.logicEditorEntities.dependencies, 'extendedObjectsAndQueries', [])
      this.$set(this.logicEditorEntities.dependencies, 'extendedObjectOrQuerySelected', null)
      this.$set(this.logicEditorEntities.dependencies, 'notifications', [])
      this.$set(this.logicEditorEntities.dependencies, 'notificationSelected', null)
      this.$set(this.logicEditorEntities.dependencies, 'tasks', [])
      this.$set(this.logicEditorEntities.dependencies, 'taskSelected', null)
    }
  }
}
</script>

<style lang="scss">
.object_editor .custom-tree-node {
  flex: 1;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  width: 90%;
  /* background-color: #EEF0F1; */
}

.object_editor .title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-left: 14px;
  margin-bottom: 14px;
  height: 28px;
  color: #2C2D35;
}

.object_editor .el-scrollbar__wrap {
  overflow-x: hidden;
}

.object_editor .object_form {
  padding: 15px;
}
.object_editor .card_new_form {
  margin: 10px 15px 15px 0;
  background: #FFFFFF;
  border: 1px solid #E8E9EC;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  display: flex;

  .first_container {
    display: flex;
    flex-direction: column;
    margin: 20px 0 15px 20px;
  }

  .second_container {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: space-between;
    margin: 10px 10px 20px;
  }

  .third_container {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: space-between;
    margin: 10px 10px 20px;
  }

  .fourth_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 25px 15px;
  }

  .el-button+.el-button {
    margin-left: 0;
  }
  /*box-shadow: 4px 1px 0px -3px rgba(27, 141, 229, 1) inset*/
  /*border: 3px solid #0D0F11;*/
}

.object_editor .dependencies_form {
  display: flex;
  flex-direction: column;

  .container {
    display: flex;
    align-items: center;
    margin-bottom: 28px;
  }

  .title_container {
    width: 200px;
  }

  .el-select {
    margin-right: 15px;
    width: 50%;
  }

  .dependency_title_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 32px;
    justify-content: space-between;
  }

  .dependency_title {
    color: #788591;
    font-weight: 400;
  }
}

.object_editor .property_title {
  font-weight: 500;
  font-style: normal;
  color: #2C2D35;
}

.object_editor .card_new_form_item {
  border: 2px solid red;
}

.object_editor .el-tree-node__children{
  border-left: 2px solid #4FA9F3;
  margin-left: 5px;
  /* padding-left: 10px; */
  /* box-shadow: 4px 1px 0px -3px rgba(27, 141, 229, 1) inset; */
}
.object_editor .el-tree-node.is-current{
  background-color: #EEF0F1;
  background-color: rgb(245 247 250);
}
.object_editor .el-tree-node.is-focusable.is-expanded{
  background-color: transparent;
}
.object_editor .pl-5 {
  padding-left: 5px;
}
.object_editor .start_logic {
  position: absolute;
  right: 40px;
  top: 10px;
  z-index: 3;
}

.object_editor .el-button--small {
  /*margin-bottom: 3px;*/
}

.object_editor .el-button.is-disabled {
  background-color: unset;
}

.object_editor .tool_box {
  display: flex;
  justify-content: space-between;
}

.di-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .el-scrollbar__view {
    padding: 20px;
  }

  &__body, &__footer {
    border-top: 1px solid #efefef;
  }

  &__body {
    height: 100%;
  }

  &__footer {
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }

  .el-form-item-group > .el-form-item__content {
    border: 1px solid #dcdfe6;
    padding: 5px 15px;
    border-radius: 4px;
  }
}

.extended_object {
  .el-dialog__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .el-dialog {
    margin: 0;

    .el-dialog__body {
      padding: 0;
    }
  }
}
</style>
