<template>
  <div :style="css">
    <span :class="colorClass" >
      <i :class="iconClass"></i> {{ value }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'indicator_field',
  props: ['value', 'css'],
  computed: {
    iconClass () {
      if (this.indicator > 0) {
        return 'el-icon-caret-top'
      } else if (this.indicator < 0) {
        return 'el-icon-caret-bottom'
      }
      return null
    },
    indicator () {
      return parseInt(this.value || 0)
    },
    colorClass () {
      if (this.indicator > 0) {
        return 'positive'
      } else if (this.indicator < 0) {
        return 'negative'
      }
      return null
    }
  }
}
</script>

<style scoped>
.positive {
  color: #67C23A;
}
.negative {
  color: #F56C6C;
}
</style>
