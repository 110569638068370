<template>
  <div :style="css">
    <el-progress :percentage="indicator" :color="customColors"></el-progress>
  </div>
</template>

<script>
export default {
  name: 'progress_field',
  props: ['value', 'css'],
  computed: {
    indicator () {
      return parseInt(this.value || 0)
    }
  },
  data () {
    return {
      customColors: [
        { color: '#f56c6c', percentage: 80 },
        { color: '#e6a23c', percentage: 90 },
        { color: '#5cb87a', percentage: 100 }
      ]
    }
  }
}
</script>

<style scoped>
</style>
