import { FluxStandardAction } from '@/core/domain/type/types'
import { MappingDTO } from '@/services/EtlEditor/domain/model/Mapping'

export enum ActionTypes {
  GET_MAPPING = 'get_mapping',
  GET_MAPPING_BY_GUID = 'get_mapping_by_guid',
  GET_MAPPING_ELEMENT_TYPES = 'get_mapping_types',
  CREATE_MAPPING = 'create_mapping',
  UPDATE_MAPPING = 'update_mapping',
  DELETE_MAPPING = 'delete_mapping'
}

export class GetMappings implements FluxStandardAction {
  type = 'Mapping/' + ActionTypes.GET_MAPPING
  constructor (public payload: object) {}
}

export class GetMappingByGuid implements FluxStandardAction {
  type = 'Mapping/' + ActionTypes.GET_MAPPING_BY_GUID
  constructor (public guid: string) {}
}

export class GetMappingElementTypes implements FluxStandardAction {
  type = 'Mapping/' + ActionTypes.GET_MAPPING_ELEMENT_TYPES
  constructor () {}
}

export class CreateMapping implements FluxStandardAction {
  type = 'Mapping/' + ActionTypes.CREATE_MAPPING
  constructor (public payload: MappingDTO) {}
}

export class UpdateMapping implements FluxStandardAction {
  type = 'Mapping/' + ActionTypes.UPDATE_MAPPING
  constructor (public payload: MappingDTO) {}
}

export class DeleteMapping implements FluxStandardAction {
  type = 'Mapping/' + ActionTypes.DELETE_MAPPING
  constructor (public guid: string) {}
}
