<template>
<div :style="css" :class="{'notExtended': !extended, 'hover_effect': clickTable}" >
  {{ validateNumber }}
</div>
</template>

<script>
import clickTableMixin from './click_table_mixin'
export default {
  props: ['value', 'extended', 'css', 'fixedNumber'],
  mixins: [clickTableMixin],
  name: 'float_field',
  computed: {
    validateNumber () {
      if (this.value && this.fixedNumber) {
        let fixed = Number(this.value).toFixed(+this.fixedNumber)
        return fixed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      } else {
        return this.value
      }
    }
  }
}
</script>

<style scoped>
  .notExtended {
    white-space: pre;
    text-align: center;
  }
  .hover_effect:hover {
    cursor: pointer;
    text-decoration: underline
  }
</style>
