import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { ModelInstanceDTO } from '@/services/DigitalTwinEditor/domain/model/Project/ModelInstance'

export namespace ModelInstanceAPI {

    export class AddModelInstance implements APIRequest<string> {
        response: string;
        path = '/digitaltwineditor/model_instances';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.headers.location
        constructor(public params: ModelInstanceDTO) {}
    }

    export class DeleteModelInstance implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.DELETE;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/digitaltwineditor/model_instances/${guid}`;
        }
    }

    export class GetModelInstanceByGuid implements APIRequest<ModelInstanceDTO> {
        response: ModelInstanceDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/digitaltwineditor/model_instances/${guid}`;
        }
    }

    export class UpdateModelInstance implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: ModelInstanceDTO) {
            this.path = `/digitaltwineditor/model_instances/${params.guid}`;
        }
    }
}