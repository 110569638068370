import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    PACKAGE_LOADING_EVENT = 'package_loading_event',
    PACKAGE_LOADED_EVENT = 'package_loaded_event'
}

export class PackageLoadingEvent implements FluxStandardAction {
    type = MutationTypes.PACKAGE_LOADING_EVENT
}

export class PackageLoadedEvent implements FluxStandardAction {
    type = MutationTypes.PACKAGE_LOADED_EVENT
}