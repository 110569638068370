import Model from './Model'
import Child from './Child'

export default class Menu extends Model {
  resource () {
    return 'menu'
  }

  children () {
    return this.hasMany(Child)
  }
  get child () {
    return []
  }
}
