<template>
  <div style="height: 560px;">
    <el-scrollbar :style="{height:'calc(100% - 50px)'}">
      <style-form
        ref="style_form"
        v-model="localStyle"
        :form-id="'style_form'"
        :mini="false"
        :type="styleType"
        :project-id="projectId"
      />
    </el-scrollbar>
    <div class="button-footer">
      <el-button type="primary" @click="yesButtonHandler">{{yesButtonLabel}}</el-button>
    </div>
  </div>
</template>

<script>
  import { APIClient } from '@/core/infrastructure/api/APIClient'
  import { StyleAPI } from '@/services/MapEditor/infrastructure/api/StyleAPI'
  import StyleForm from '@/services/MapEditor/infrastructure/components/StyleForm/index.vue'

  export default {
    name: 'StyleEditor',
    components: { StyleForm },
    props: {
      guid: {
        type: String,
        default: null
      },
      styleType: {
        type: String,
        default: null
      },
      projectId: {
        type: Number,
        default: null
      },
      editedStyle: {
        type: Object,
        default: () =>{
          return {properties: []}
        }
      }
    },
    data: function () {
      return {
        localStyle: null
      }
    },
    computed: {
      yesButtonLabel: function () {
        return this.guid ? 'Сохранить' : 'Создать'
      }
    },
    mounted () {

    },
    methods: {
      yesButtonHandler () {
        const me = this
        this.$refs.style_form.submit(()=>{me.$emit('style-created', me.styleType)})
        this.localStyle = null
        return
        if (this.guid) {
          //update

        } else {
          //create
          APIClient.shared.request(
            new StyleAPI.AddStyle(this.localStyle)
          ).then(r => me.$emit('style-created', me.styleType))
        }


        console.log(this.guid ? 'update' : 'create')
      },
      loadStyle (guid) {
        const me = this
        APIClient.shared.request(
          new StyleAPI.GetStyleByGuid(guid)
        ).then(r => me.localStyle = r)
      }
    }
  }
</script>

<style scoped>
  .button-footer {
    position: absolute;
    right: 25px;
    bottom: 25px;
  }
</style>
