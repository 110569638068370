import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import PointStyleImageCommandRepositoryInterface from '@/core/domain/repository/modules/PointStyleImageCommandRepositoryInterface'
import PointStyleImageCreateCommand from '@/core/application/command/modules/PointStyleImage/PointStyleImageCreateCommand'
import { PointStyleImageDTO } from '@/core/domain/model/modules/PointStyleImage'

export default class PointStyleImageCreateHandler implements CommandHandlerInterface
{
    private repo: PointStyleImageCommandRepositoryInterface;

    constructor(repo: PointStyleImageCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: PointStyleImageCreateCommand): Promise<void>
    {
        let item: PointStyleImageDTO = {
            name: command.getName(),
            image: command.getImage()
        };
        return this.repo.insert(item);
    }
}