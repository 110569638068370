export interface OpenWorldState {
    isLoading: boolean;
    location: string;
    error: object;
  }
  
  export const initialState = (): OpenWorldState => {
    return {
      isLoading: false,
      location: null,
      error: null
    }
  }