import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import SourceQueryRepositoryInterface from '../../../domain/repository/SourceQueryRepositoryInterface'
import SourceTypePropertiesQuery from '../../query/SourceTypePropertiesQuery'

export default class SourceTypePropertiesHandler implements QueryHandlerInterface
{
    private repo: SourceQueryRepositoryInterface;

    constructor(repo: SourceQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: SourceTypePropertiesQuery): Promise<any>
    {
        return this.repo.getTypeProperties(query.getType());
    }
}