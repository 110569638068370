import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    MAPPING_LOADING_EVENT = 'mapping_loading_event',
    MAPPING_LOADED_EVENT = 'mapping_loaded_event',
    MAPPING_CREATED_EVENT = 'mapping_created_event',
    MAPPING_UPDATED_EVENT = 'mapping_updated_event',
    MAPPING_DELETED_EVENT = 'mapping_deleted_event'
}

export class MappingLoadingEvent implements FluxStandardAction {
  type = MutationTypes.MAPPING_LOADING_EVENT
}

export class MappingLoadedEvent implements FluxStandardAction {
  type = MutationTypes.MAPPING_LOADED_EVENT
}

export class MappingCreatedEvent implements FluxStandardAction {
  type = MutationTypes.MAPPING_CREATED_EVENT
  constructor (public location: string) {}
}

export class MappingUpdatedEvent implements FluxStandardAction {
  type = MutationTypes.MAPPING_UPDATED_EVENT
  constructor (public location: string) {}
}

export class MappingDeletedEvent implements FluxStandardAction {
  type = MutationTypes.MAPPING_DELETED_EVENT
  constructor (public guid: string) {}
}
