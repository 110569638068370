import { Nullable } from '@/core/domain/type/types'

export class IAPIRuleGroup {
  id?: Nullable<number>
  guid?: Nullable<string>
  name: Nullable<string>
  parent_tree_element_id?: Nullable<number>
}

export default class RuleGroup {
  public id?: Nullable<number>
  public guid?: Nullable<string>
  public name: Nullable<string>
  public parent_tree_element_id?: Nullable<number>

  constructor (ruleGroup: IAPIRuleGroup) {
    this.id = ruleGroup.id
    this.guid = ruleGroup.guid
    this.name = ruleGroup.name
    this.parent_tree_element_id = ruleGroup.parent_tree_element_id
  }

  static create (name: Nullable<string> = null): RuleGroup {
    return new RuleGroup({
      name: name,
      parent_tree_element_id: null
    })
  }

  duplicate (parentId: number|null) {
    return new RuleGroup({
      name: this.name + ' Copy',
      parent_tree_element_id: parentId
    })
  }
}
