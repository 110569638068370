import { Nullable } from '@/core/domain/type/types'

export default class ProjectTreeElementOpenWorldCreateCommand
{
    private name: string;
    private description: Nullable<string>;
    private alias: Nullable<string>;
    private heightmap: Nullable<string>;
    private coordinateSystem: Nullable<number>;
    private startPoint: Array<number>;
    private isAutoBorders: boolean = true;
    private bordersBbox: Array<any>;
    private radiusOfBuffer: Nullable<number>;
    private parentId: Nullable<number>;

    constructor(name: string, description: Nullable<string>, alias: Nullable<string>, heightmap: Nullable<string>, coordinateSystem: Nullable<number>, startPoint: Array<number>, isAutoBorders: boolean = true, bordersBbox: Array<any>, radiusOfBuffer: Nullable<number>, parentId: Nullable<number>)
    {
        this.name = name;
        this.description = description;
        this.alias = alias;
        this.heightmap = heightmap;
        this.coordinateSystem = coordinateSystem ?? 4326;
        this.startPoint = startPoint;
        this.isAutoBorders = isAutoBorders;
        this.bordersBbox = bordersBbox;
        this.radiusOfBuffer = radiusOfBuffer;
        this.parentId = parentId;
    }

    getClassName(): string
    {
        return 'DigitalTwinProjectTreeElementOpenWorldCreateCommand';
    }

    getName(): string
    {
        return this.name;
    }

    getDescription(): Nullable<string>
    {
        return this.description;
    }

    getCoordinateSystem(): Nullable<number>
    {
        return this.coordinateSystem;
    }

    getRadiusOfBuffer(): number
    {
        return this.radiusOfBuffer;
    }

    getAlias(): Nullable<string>
    {
        return this.alias;
    }

    getHeightmap(): Nullable<string>
    {
        return this.heightmap;
    }

    getStartPoint(): Array<number>
    {
        return this.startPoint;
    }

    getAutoBorders(): boolean
    {
        return this.isAutoBorders;
    }

    getBordersBbox(): Array<any>
    {
        return this.bordersBbox;
    }

    getParentId(): Nullable<number>
    {
        return this.parentId;
    }
}