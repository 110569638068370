import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { ReleaseDTO } from '@/services/ReleaseEditor/domain/model/Release/Release'

export namespace ReleaseAPI {

    export class AddRelease implements APIRequest<string> {
        response: string;
        path = '/releaseeditor/releases';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.headers.location
        constructor(public params: ReleaseDTO) {}
    }

    export class UpdateRelease implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: ReleaseDTO) {
            this.path = `/releaseeditor/releases/${params.guid}`;
        }
    }

    export class DeleteRelease implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.DELETE;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/releaseeditor/releases/${guid}`;
        }
    }

    export class CountReleases implements APIRequest<ReleaseDTO> {
        response: ReleaseDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/releaseeditor/releases?*[func]=count'
        }
    }

    export class GetReleases implements APIRequest<ReleaseDTO> {
        response: ReleaseDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/releaseeditor/releases?' + (new URLSearchParams(payload).toString())
        }
    }

    export class GetReleaseByGuid implements APIRequest<ReleaseDTO> {
        response: ReleaseDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/releaseeditor/releases/${guid}`;
        }
    }
}