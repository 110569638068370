import Modeler from 'bpmn-js/lib/Modeler'

export default class ExportImportService {
  public static import (modeler: Modeler) {
    const input = document.createElement('input')
    input.type = 'file'

    input.onchange = (e: Event) => {
      const files = (<HTMLInputElement>e.target).files
      if (files[0] !== undefined) {
        const fr = new FileReader()
        fr.readAsText(files[0])
        fr.addEventListener('load', () => {
          modeler.importXML(fr.result).then(() => {
            modeler.get('canvas').zoom('fit-viewport')
          })
        })
      }
    }

    document.body.appendChild(input)
    input.click()
    document.body.removeChild(input)
  }

  public static export (modeler: Modeler) {
    modeler.saveXML({ format: true }).then((xml) => {
      const blob = new Blob([xml.xml], { type: 'application/xml' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'bpmn.bpmn'

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
  }
}
