import { FluxStandardAction } from '@/core/domain/type/types'
import { StateDTO } from '@/services/LogicEditor/domain/model/State'

export enum ActionTypes {
  GET_STATE_BY_GUID = 'get_state_by_guid',
  GET_STATE_OPERATION_TYPES = 'get_state_operation_types',
  GET_STATE_OPERATION_TYPE_ENTITY_TYPES = 'get_state_operation_type_entity_types',
  GET_STATE_FIELD_TYPES = 'get_state_field_types',
  GET_CONDITION_FIELD_TYPES = 'get_condition_field_types',
  CREATE_STATE = 'create_state',
  UPDATE_STATE = 'update_state',
  DELETE_STATE = 'delete_state'
}

export class GetStateByGuid implements FluxStandardAction {
  type = 'State/' + ActionTypes.GET_STATE_BY_GUID
  constructor (public guid: string) {}
}

export class GetStateOperationTypes implements FluxStandardAction {
  type = 'State/' + ActionTypes.GET_STATE_OPERATION_TYPES
  constructor () {}
}

export class GetStateOperationTypeEntityTypes implements FluxStandardAction {
  type = 'State/' + ActionTypes.GET_STATE_OPERATION_TYPE_ENTITY_TYPES
  constructor () {}
}

export class GetStateFieldTypes implements FluxStandardAction {
  type = 'State/' + ActionTypes.GET_STATE_FIELD_TYPES
  constructor () {}
}

export class GetConditionFieldTypes implements FluxStandardAction {
  type = 'State/' + ActionTypes.GET_CONDITION_FIELD_TYPES
  constructor () {}
}

export class CreateState implements FluxStandardAction {
  type = 'State/' + ActionTypes.CREATE_STATE
  constructor (public payload: StateDTO) {}
}

export class UpdateState implements FluxStandardAction {
  type = 'State/' + ActionTypes.UPDATE_STATE
  constructor (public payload: StateDTO) {}
}

export class DeleteState implements FluxStandardAction {
  type = 'State/' + ActionTypes.DELETE_STATE
  constructor (public guid: string) {}
}
