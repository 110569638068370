import { MutationTree } from 'vuex'
import { FeatureState, initialState } from './state'
import { MutationTypes, FeatureUpdatedEvent } from './mutation-types'

export const mutations: MutationTree<FeatureState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false;
    state.error = error;    
  },
  [MutationTypes.FEATURE_LOADING_EVENT]: (state) => {
    state.isLoading = true;
  },
  [MutationTypes.FEATURE_LOADED_EVENT]: (state) => {
    state.isLoading = false;
  },
  [MutationTypes.FEATURE_UPDATED_EVENT]: (state, action: FeatureUpdatedEvent) => {
    state.isLoading = false;
  }
}