import PluginNotFound from '@/core/domain/exception/PluginNotFound'

export default class PluginFactory {
  private static submodule: any
  static async build (project: string, pluginName: string, context: any, event: any) {
    let plugin = null

    try {
      // @ts-ignore
      plugin = await submodule.getPlugin(pluginName)
    } catch (e) {
      try {
        plugin = await import(`@/plugins/${project}/infrastructure/plugins/${pluginName}`)
      } catch {
        try {
          plugin = await import(`@/plugins/${project}/${pluginName}`)
        } catch {
          throw new PluginNotFound(`Plugin: ${pluginName} not found`)
        }
      }
    }

    // eslint-disable-next-line new-cap
    return new plugin.default(context, event)
  }
}
