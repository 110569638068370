import { FluxStandardAction, Nullable } from '@/core/domain/type/types'

export enum ActionTypes {
    GET_TEMPLATES = 'get_templates',
    COUNT_TEMPLATES = 'count_templates'
}

export class GetTemplates implements FluxStandardAction {
    type = 'DigitalTwinTemplate/' + ActionTypes.GET_TEMPLATES
    constructor (public payload: object) {}
}

export class CountTemplates implements FluxStandardAction {
    type = 'DigitalTwinTemplate/' + ActionTypes.COUNT_TEMPLATES
    constructor (public payload: object) {}
}
