import QueryInterface from '@/core/application/query/QueryInterface'

export default class UserAvatarQuery implements QueryInterface {
    private avatarId: number;

    constructor (
      avatarId: number
    ) {
      this.avatarId = avatarId
    }

    getClassName (): string {
      return 'UserAvatarQuery'
    }

    getAvatarId (): number {
      return this.avatarId
    }
}
