import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import SourceCommandRepositoryInterface from '@/services/MapEditor/domain/repository/SourceCommandRepositoryInterface'
import SourceCreateCommand from '@/services/MapEditor/application/command/SourceCreateCommand'
import { SourceDTO } from '@/services/MapEditor/domain/model/Source/Source'

export default class SourceCreateHandler implements CommandHandlerInterface
{
    private repo: SourceCommandRepositoryInterface;

    constructor(repo: SourceCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: SourceCreateCommand): Promise<void>
    {
        let item: SourceDTO = {
            name: command.getName(),
            type: command.getType(),
            properties: command.getProperties(),
            project_id: command.getProjectId()
        };
        return this.repo.insert(item);
    }
}