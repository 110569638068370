export default class ModelInstanceCreateCommand
{
    private elementId: number;
    private modelId: number;
    private position: Array<number>;
    private rotation: Array<number>;
    private usesHeightMapZ: boolean = false;
    private has3Dtiles: boolean = false;

    constructor(elementId: number, modelId: number, position: Array<number>, rotation: Array<number>, usesHeightMapZ: boolean = false, has3Dtiles: boolean = false)
    {
        this.modelId = modelId;
        this.elementId = elementId;
        this.position = position;
        this.rotation = rotation;
        this.usesHeightMapZ = usesHeightMapZ;
        this.has3Dtiles = has3Dtiles;
    }

    getClassName(): string
    {
        return 'ModelInstanceCreateCommand';
    }

    getElementId(): number
    {
        return this.elementId;
    }

    getModelId(): number
    {
        return this.modelId;
    }
    
    getPosition(): Array<number>
    {
        return this.position;
    }

    getRotation(): Array<number>
    {
        return this.rotation;
    }

    getUsesHeightMapZ(): boolean
    {
        return this.usesHeightMapZ;
    }

    getHas3Dtiles(): boolean
    {
        return this.has3Dtiles;
    }
}