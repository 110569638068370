import { MutationTree } from 'vuex'
import { ApprovalState, initialState } from './state'
import { MutationTypes, ApprovalCreatedEvent, ApprovalUpdatedEvent, ApprovalDeletedEvent } from './mutation-types'

export const mutations: MutationTree<ApprovalState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false
    state.error = error
  },
  [MutationTypes.APPROVAL_LOADING_EVENT]: (state) => {
    state.isLoading = true
  },
  [MutationTypes.APPROVAL_LOADED_EVENT]: (state) => {
    state.isLoading = false
  },
  [MutationTypes.APPROVAL_CREATED_EVENT]: (state, action: ApprovalCreatedEvent) => {
    state.isLoading = false
    state.location = action.location
  },
  [MutationTypes.APPROVAL_UPDATED_EVENT]: (state, action: ApprovalUpdatedEvent) => {
    state.isLoading = false
  },
  [MutationTypes.APPROVAL_DELETED_EVENT]: (state, action: ApprovalDeletedEvent) => {
    state.isLoading = false
    state.deletedApprovalGuid = action.guid
  }
}
