import UserCommandRepositoryInterface from '@/services/AccessEditor/domain/repository/UserCommandRepositoryInterface'
import { UserDTO, UserUploadDTO } from '@/services/AccessEditor/domain/model/User/User'
import store from '@/store'
import { AddUser, UpdateUser, DeleteUser, UploadUsers } from '@/services/AccessEditor/infrastructure/store/modules/User/action-types'

export default class UserCommandRepository implements UserCommandRepositoryInterface {
  insert (item: UserDTO): Promise<void> {
    return store.dispatch(new AddUser(item))
  }

  update (item: UserDTO): Promise<void> {
    return store.dispatch(new UpdateUser(item))
  }

  delete (guid: string): Promise<void> {
    return store.dispatch(new DeleteUser(guid))
  }

  upload (item: UserUploadDTO): Promise<void> {
    return store.dispatch(new UploadUsers(item))
  }
}
