import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { TreeElementDTO } from '@/services/EtlEditor/domain/model/TreeElement'

export namespace TreeElementAPI {
  export class GetTreeElements implements APIRequest<TreeElementDTO> {
    response: TreeElementDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (payload: string[][]) {
      this.path = `/v2/etleditor/tree_elements?${new URLSearchParams(payload).toString()}`
    }
  }

  export class GetTreeElementsTree implements APIRequest<any> {
    response: any;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (payload: string[][]) {
      this.path = `/v2/etleditor/tree_elements/tree?${new URLSearchParams(payload).toString()}`
    }
  }

  export class GetTreeElementByGuid implements APIRequest<TreeElementDTO> {
    response: TreeElementDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/etleditor/tree_elements/${guid}`
    }
  }

  export class GetTreeElementByElementGuid implements APIRequest<TreeElementDTO> {
    response: TreeElementDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (elementGuid: string) {
      this.path = `/v2/etleditor/tree_elements/${elementGuid}/element`
    }
  }
}
