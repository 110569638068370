import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    STYLE_LOADING_EVENT = 'style_loading_event',
    STYLE_LOADED_EVENT = 'style_loaded_event',
    STYLE_CREATED_EVENT = 'style_created_event',
    STYLE_UPDATED_EVENT = 'style_updated_event',
    STYLE_DELETED_EVENT = 'style_deleted_event' 
}

export class StyleLoadingEvent implements FluxStandardAction {
    type = MutationTypes.STYLE_LOADING_EVENT
}

export class StyleLoadedEvent implements FluxStandardAction {
    type = MutationTypes.STYLE_LOADED_EVENT
}

export class StyleCreatedEvent implements FluxStandardAction {
    type = MutationTypes.STYLE_CREATED_EVENT
    constructor(public location: string){}
}

export class StyleUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.STYLE_UPDATED_EVENT
}

export class StyleDeletedEvent implements FluxStandardAction {
    type = MutationTypes.STYLE_DELETED_EVENT
    constructor(public guid: string){}
}