import { render, staticRenderFns } from "./xref_multi_field.vue?vue&type=template&id=4a03bce0&scoped=true&"
import script from "./xref_multi_field.vue?vue&type=script&lang=js&"
export * from "./xref_multi_field.vue?vue&type=script&lang=js&"
import style0 from "./xref_multi_field.vue?vue&type=style&index=0&id=4a03bce0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a03bce0",
  null
  
)

export default component.exports