<template>
  <div class="window" :style="{zIndex: zIndex + 1}">
    <div class="overlay" @click="closeWindow(false)">
      <div class="detail-container"
           :style="{width: width, height: height, zIndex: zIndex}"
           @click="closeWindow(true)"
           ref="popover">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Clickoutside from 'element-ui/src/utils/clickoutside'
import { createPopper } from '@popperjs/core'
import { PopupManager } from 'element-ui/src/utils/popup'

export default {
  name: 'CustomPopover',
  directives: { Clickoutside },
  props: {
    width: String,
    height: String,
    active: {
      type: Boolean,
      default: false
    },
    positionX: Number,
    positionY: Number
  },
  data () {
    return {
      zIndex: 999,
      instance: undefined,
      isClickedOnContent: false
    }
  },
  beforeCreate () {
    this.zIndex = PopupManager.nextZIndex()
  },
  mounted () {
    const me = this
    const virtualElement = {
      getBoundingClientRect: () => ({
        width: 0,
        height: 0,
        top: me.positionY,
        right: me.positionX,
        bottom: me.positionY,
        left: me.positionX
      })
    }

    this.instance = createPopper(virtualElement, this.$refs.popover)
  },
  beforeDestroy () {
    this.instance.destroy()
  },
  methods: {
    closeWindow (isClickedOnContent) {
      if (isClickedOnContent) {
        this.isClickedOnContent = true
      } else {
        if (this.isClickedOnContent) {
          this.isClickedOnContent = false
        } else {
          this.$emit('update:active', false)
        }
      }
    },
    close () {
      let isOpened = JSON.parse(JSON.stringify(this.active))
      setTimeout(() => {
        if (isOpened) {
          this.$emit('update:active', false)
        }
      }, 50)
    }
  }
}
</script>

<style scoped>
.window {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: inline-block;
}

.detail-container {
  width: 100%;
  border: 1px solid #E9EBED;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 2px;
}
.detail-container:focus {
  outline: none;
}
.overlay {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  display: flex;
  align-items: center
}
</style>

<style>
.el-select-dropdown, .el-autocomplete-suggestion {
  /* Фикс наложения выпадающего списка el-autocomplete и el-select под модальным окном */
  z-index: 9999 !important;
}
</style>
