<template>
  <div style="height: 600px">
    <el-breadcrumb separator-class="el-icon-arrow-right" style="margin: 10px 0;">
      <el-breadcrumb-item
        v-for="item in breadcrumbs.filter((x, index)=>index <= breadcrumbs.findIndex(y=>y.id===activeEditor))"
      ><a @click="activeEditor = item.id">{{item.label}}</a></el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div v-if="activeEditor === 'style'" class="split-container">
      <style-editor
        ref="style_editor"
        :guid="styleGuid"
        :edited-style="styleForEdit"
        :style-type="styleType"
        :project-id="projectId"
        @style-created="updateStylesSelects"
        @style-edited="updateStylesSelects"
      ></style-editor>
    </div>
    <div v-if="activeEditor === 'templates'" class="split-container">
      <style-template-editor
        ref="style_template_editor"
        @create-style="createStyle"
        @edit-style="editStyle"
        @edited-template="templateEdited"
        @created-template="templateCreated"
        @deleted-template="templateEdited"
      ></style-template-editor>
    </div>
    <div v-if="activeEditor === 'groups'" class="split-container">
      <div class="header-buttons-pane">
        <el-button size="mini" @click="addGroup">Создать группу</el-button>
        <el-button style="float: right;" size="mini" @click="changeDisplayedEditor">Редактировать шаблоны стилей</el-button>
      </div>
      <Split>
        <SplitArea :size="30">
          <div class="column-title">Группы</div>
          <el-scrollbar :style="{height:'calc(100% - 29px)'}">
            <div
              v-for="item in groups"
              :key="item.guid"
              :class="{ 'group-item': true, 'selected-group': (selectedGroup && selectedGroup.guid === item.guid) }"
              @click="selectGroup(item)"
              >
              <div v-if="selectedGroup && selectedGroup.guid === item.guid && selectedGroupEditName" class="group-name">
                <el-input v-model="item.name" size="mini"></el-input>
              </div>
              <div v-else class="group-name">
                {{item.name}}
              </div>
              <div v-if="selectedGroup && selectedGroup.guid === item.guid" class="group-buttons">
                <el-button v-if="!selectedGroupEditName" size="mini" type="primary" plain icon="el-icon-edit" @click="editGroupName"></el-button>
                <el-button v-if="selectedGroupEditName" size="mini" type="success" plain icon="el-icon-check" @click="saveGroupName"></el-button>
                <el-popover
                  placement="top"
                  v-model="deletePopoverVisible"
                  >
                  <p>Вы уверены что хотите удалить группу "{{selectedGroup.name}}"</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="deletePopoverVisible = false">Отменить</el-button>
                    <el-button size="mini" type="danger" @click="deleteGroup">Удалить</el-button>
                  </div>
                  <el-button type="danger" plain size="mini" icon="el-icon-delete" slot="reference"></el-button>
                </el-popover>
              </div>
            </div>
          </el-scrollbar>
        </SplitArea>
        <SplitArea :size="33" style="text-align: center">
          <div class="column-title">Шаблоны группы</div>
          <el-scrollbar :style="{height:'calc(100% - 29px)'}">
            <draggable
              class="list-group"
              :list="groupTemplates"
              group="group_template"
              @change="log1"
            >
              <div
                v-for="item in groupTemplates"
                :key="item.guid"
                class="template-item"
              >
                {{item.name}}
              </div>
            </draggable>
          </el-scrollbar>
          </SplitArea>
        <SplitArea :size="33" style="text-align: center">
          <div class="column-title">Шаблоны не входящие в группу</div>
          <el-scrollbar :style="{height:'calc(100% - 29px)'}">
            <draggable
              class="list-group"
              :list="displayedTemplates"
              group="group_template">
              <div
                v-for="item in displayedTemplates"
                :key="item.guid"
                class="template-item"
              >
                {{item.name}}
              </div>
            </draggable>
          </el-scrollbar>
        </SplitArea>
      </Split>
    </div>
  </div>
</template>

<script>
  import { APIClient } from '@/core/infrastructure/api/APIClient'
  import { MapEditorAPI } from '@/core/infrastructure/api/modules/MapEditorAPI'
  import StyleTemplateEditor from '@/core/infrastructure/components/StyleTemplateEditor'
  import StyleEditor from '@/core/infrastructure/components/StyleEditor'
  import { ProjectAPI } from '@/services/MapEditor/infrastructure/api/ProjectAPI'

  import draggable from 'vuedraggable'

  export default {
    name: 'StyleTemplateGroupEditor',
    components: { draggable, StyleTemplateEditor, StyleEditor },
    data () {
      return {
        groups: [],
        selectedGroup: null,
        selectedGroupEditName: false,
        selectedGroupEditValue: null,
        groupTemplates: [],
        templates: [],
        displayedTemplates: [],
        properties: {
          children: 'children',
          label: 'name'
        },
        deletePopoverVisible: false,
        activeEditor: 'groups',
        breadcrumbs: [
          {
            id: 'groups',
            label: 'Группы шаблонов'
          },
          {
            id: 'templates',
            label: 'Шаблоны стилей'
          },
          {
            id: 'style',
            label: 'Стиль'
          },
        ],
        styleGuid: null,
        styleType: null,
        styleForEdit: {properties:[]},
        projectId: null,
      }
    },
    watch: {
      selectedGroup: {
        handler: async function (newValue) {
          this.selectedGroupEditName = false
          this.selectedGroupEditValue = null
          if (newValue) {
            await this.loadGroupTemplates(newValue.guid)
            this.displayedTemplates = this.templates.filter(x => !this.groupTemplates.find(y => x.guid === y.guid))
          } else {
            this.groupTemplates = []
            this.displayedTemplates = this.templates
          }
        },
      }
    },
    mounted () {
      this.loadGroups()
      this.loadTemplates()
      this.getProjectGuid()
    },
    methods: {
      async getProjectGuid () {

        const me = this
        //TODO КОСТЫЛЬ
        //сделать создание проекта для шаблонов всегда
        await APIClient.shared.request(
          new ProjectAPI.GetProjects([])
        ).then (async function (response) {
          if (response.length > 0) {
            me.projectId = response[0].id
          } else {
            await APIClient.shared.request(
              new ProjectAPI.AddProject({
                name: 'Проект для шаблонов стилей',
                description: 'в системе должен существовать хотя бы один проект'
              }, false)
            ).then(async function (r) {
              await APIClient.shared.request(
                new ProjectAPI.GetProjects([])
              ).then(rr => {
                me.projectId = rr[0].id
              })
            })
          }
        })
      },
      updateStylesSelects (type) {
        this.activeEditor = 'templates'
        //console.log('надо бы обновить выпадашки', type)
        //console.log(this.$refs)
      },
      editStyle (style, type) {
        console.log(style, type)
        this.styleType = type
        this.styleForEdit = style ? style : {properties:[]}
        this.activeEditor = 'style'
      },
      createStyle (styleType) {
        this.styleGuid = null
        this.styleForEdit = {properties:[]}
        this.styleType = styleType
        this.activeEditor = 'style'
      },
      templateCreated() {
        this.loadTemplates()
      },
      templateEdited() {
        this.loadTemplates()
        this.loadGroupTemplates(this.selectedGroup.guid)
      },
      changeDisplayedEditor () {
        this.activeEditor = 'templates'
      },
      editGroupName () {
        this.selectedGroupEditName = true
        this.selectedGroupEditValue = this.selectedGroup.name
      },
      saveGroupName () {
        this.selectedGroupEditName = false
        this.updateSelectedGroup()
      },
      deleteGroup () {
        const me = this
        const groupForDeleteName = this.selectedGroup.name
        APIClient.shared.request(
          new MapEditorAPI.DeleteStyleTemplateGroup(this.selectedGroup.guid)
        ).then(r => {
          me.loadGroups()
          me.$message.success(`Группа шаблонов слоев "${groupForDeleteName}" успешна удалена`)
        })
      },
      log1 (a) {
        if (a.hasOwnProperty('moved')) {
          return
        }
        this.updateSelectedGroup()
      },
      async updateSelectedGroup () {
        await APIClient.shared.request(
          new MapEditorAPI.PutStyleTemplateGroup(
            this.selectedGroup.guid,
            {
              name: this.selectedGroupEditName ? this.selectedGroupEditValue : this.selectedGroup.name,
              templates: this.groupTemplates.map(x => x.id)
            }
          )
        )
      },
      async loadTemplates () {
        const me = this
        await APIClient.shared.request(
          new MapEditorAPI.GetStyleTemplates()
        ).then(function (result) {
          me.templates = result
        })
      },
      async loadGroupTemplates (groupGuid) {
        const me = this
        await APIClient.shared.request(
          new MapEditorAPI.GetStyleTemplatesByGroupGuid(groupGuid)
        ).then(function (result) {
          me.groupTemplates = result
        })
      },
      async loadGroups () {
        const me = this
        await APIClient.shared.request(
          new MapEditorAPI.GetStyleTemplateGroups()
        ).then(function (result) {
          me.groups = result
        })
      },
      selectGroup (group) {
        this.selectedGroup = group
      },
      addGroup () {
        let maxId = 0
        this.groups.forEach(x => maxId = x.id > maxId ? x.id : maxId)
        const me = this
        APIClient.shared.request(
          new MapEditorAPI.AddStyleTemplateGroup(
            {
              name: 'Новая группа ' + ++maxId,
              templates: []
            }
          )
        ).then(r => {
          me.loadGroups()
        })
      }
    }
  }
</script>

<style scoped>
  .split-container /deep/ .gutter {
    height: calc(100% - 36px);
  }
  .split-container /deep/ .el-divider--horizontal {
    margin: 0 10px;
  }
  .group-name {
    float: left;
  }
  .group-buttons {
    float: right;
  }
  .column-title {
    text-align: center;
    padding: 5px;
  }
  .template-item {
    width: 100%;
    margin: 0px 0px;
    padding: 6px 0;
  }
  .template-item:hover {
    background-color: #F5F7FA;
    cursor: pointer;
  }
  .group-item {
    display: inline-block;
    width: calc(100% - 20px);
    margin: 0px 0px;
    padding: 6px;
  }
  .selected-group {
    background-color: #d5e0ed;;
  }
  .group-item:hover {
    background-color: #F5F7FA;
    cursor: pointer;
  }
  .column-button-pane {
    padding: 10px 10px 10px 0;
  }
  .group-buttons /deep/ .el-button {
    margin-left: 4px;
    padding: 2px;
  }
  .group-name /deep/ .el-input {
    height: 22px;
  }
  .header-buttons-pane {
    margin: 0 0 20px 0;
  }
  .split-container {
    height: 578px;
    padding: 10px 0;
  }
  .split-container /deep/ .el-scrollbar__wrap {
    overflow-y: scroll;
    overflow-x: visible;
  }
  .split-container /deep/ .split {
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .split-container /deep/ .el-scrollbar {
    height: 500px;
  }
</style>
