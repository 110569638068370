import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    TASK_LOADING_EVENT = 'task_loading_event',
    TASK_LOADED_EVENT = 'task_loaded_event'
}

export class TaskLoadingEvent implements FluxStandardAction {
    type = MutationTypes.TASK_LOADING_EVENT
}

export class TaskLoadedEvent implements FluxStandardAction {
    type = MutationTypes.TASK_LOADED_EVENT
}
