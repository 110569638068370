import { render, staticRenderFns } from "./html-container.vue?vue&type=template&id=24d4628a&scoped=true&"
import script from "./html-container.vue?vue&type=script&lang=js&"
export * from "./html-container.vue?vue&type=script&lang=js&"
import style0 from "./CKEditor.css?vue&type=style&index=0&id=24d4628a&prod&scoped=true&lang=css&"
import style1 from "./html-container.vue?vue&type=style&index=1&id=24d4628a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24d4628a",
  null
  
)

export default component.exports