<template>
    <div>
        <span class="registry_fields_label" style="display: inline-block;">{{$locale.interface_editor.component_editor.absolute_position_interface_designer.component_properties.fields}}</span>
        <el-button style="float: right" @click="addCondition">
            {{$locale.interface_editor.component_editor.filters.add_filter}}
        </el-button>
        <el-row v-if="value.length">
            <el-col :span="10">{{ $locale.interface_editor.component_editor.absolute_position_interface_designer.component_properties.field }}</el-col>
            <el-col :span="14">{{ $locale.interface_editor.component_editor.absolute_position_interface_designer.component_properties.color }}</el-col>
        </el-row>
        <el-row v-for="(field, index) in value" :key="index">
            <el-col :span="10">
                <el-input v-model="field.field"></el-input>
            </el-col>
            <el-col :span="10">
                <el-color-picker class="color_picker" v-model="field.color"></el-color-picker>
            </el-col>
            <el-col :span="4">
                <el-button @click="deleteCondition(index)"
                                         size="mini"
                                         type="danger"
                                         :title="$locale.main.button.delete">
                    <i class="el-icon-delete"></i>
                </el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
  name: 'indicator-fields-editor',
  props: {
    value: {
      type: Array,
      default () { return [] }
    }
  },
  methods: {
    addCondition () {
      if (typeof this.value === 'undefined') {
        this.$set(this, 'value', [])
      }
      this.value.push({
        field: null,
        color: null
      })
    },
    deleteCondition (index) {
      this.value.splice(index, 1)
    }
  },
  watch: {
    value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
