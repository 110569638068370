import { ActionTree } from 'vuex'
import { ConstraintState, initialState } from './state'
import { ActionTypes } from './action-types'
import {
  MutationTypes,
  ConstraintUpdatedEvent,
  ConstraintCreatedEvent,
  ConstraintLoadingEvent,
  ConstraintDeletedEvent,
  ConstraintLoadedEvent
} from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { ConstraintAPI } from '@/services/LogicEditor/infrastructure/api/ConstraintAPI'

export const actions: ActionTree<ConstraintState, any> = {
  [ActionTypes.GET_CONSTRAINT_TYPES]: async ({ commit, dispatch }, data) => {
    commit(new ConstraintLoadingEvent())

    return APIClient.shared.request(new ConstraintAPI.GetConstraintTypes())
      .then((response) => {
        commit(new ConstraintLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.GET_CONSTRAINT_BY_GUID]: async ({ commit, dispatch }, data) => {
    commit(new ConstraintLoadingEvent())

    return APIClient.shared.request(new ConstraintAPI.GetConstraintByGuid(data.guid))
      .then((response) => {
        commit(new ConstraintLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.CREATE_CONSTRAINT]: async ({ commit, dispatch }, data) => {
    commit(new ConstraintLoadingEvent())

    return APIClient.shared.request(new ConstraintAPI.CreateConstraint(data.payload))
      .then((location) => {
        commit(new ConstraintCreatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.UPDATE_CONSTRAINT]: async ({ commit, dispatch }, data) => {
    commit(new ConstraintLoadingEvent())

    return APIClient.shared.request(new ConstraintAPI.UpdateConstraint(data.payload))
      .then((location) => {
        commit(new ConstraintUpdatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.DELETE_CONSTRAINT]: async ({ commit, dispatch }, data) => {
    commit(new ConstraintLoadingEvent())

    return APIClient.shared.request(new ConstraintAPI.DeleteConstraint(data.guid))
      .then(() => {
        commit(new ConstraintDeletedEvent(data.guid))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  }
}
