<template>
  <div style="justify-content: space-between; display: flex;">
    <el-select
      clearable
      size="mini"
      v-model="localModel"
      filterable
      @clear="localModel = null;$emit('change', localModel)"
      @change="$emit('change', localModel)">
      <el-option
        v-for="icon in icons"
        :key="icon.value"
        :label="icon.label"
        :value="icon">
        <span style="float: left">{{ icon.label }}</span>
        <span style="float: right;">
          <i v-if="icon.type === 'cssClass'" class="icon-preview" :class="icon.value"></i>
          <el-image v-if="icon.type === 'src' && icon.value" :src="icon.value" fit="contain" class="icon-preview"></el-image>
        </span>
      </el-option>
    </el-select>

    <el-popover trigger="click" placement="bottom" width="250" v-model="showCustomIconDialog">
      <el-button slot="reference" class="el-icon-plus" type="primary"></el-button>

      <!-- <span>Имя</span>
      <el-input v-model="customIcon.label" @change="parseIcons()"></el-input> -->

      <span>Base64</span>
      <el-tooltip placement="left-end" :open-delay="500" effect="dark">
        <div slot="content" v-html="'Формат: data:[тип данных];base64,[base64]'"></div>
        <i class="el-icon-question"></i>
      </el-tooltip>
      <el-input v-model="customIcon.value"></el-input>

      <div v-if="customIcon.value" style="margin-top: 5px;">
        <span style="vertical-align: middle;">Icon</span>
        <el-image ref="preview" class="icon-preview" style="margin-left: 5px" :src="customIcon.value"></el-image>
      </div>

      <!-- <span>URL</span>
      <el-tooltip placement="left-end" :open-delay="500" effect="dark">
        <div slot="content" v-html="'Некоторые сайты выдают ошибку CORS при запросе к нему с другого домена, не отправляют заголовок Access-Control-Allow-Origin'"></div>
        <i class="el-icon-question"></i>
      </el-tooltip>
      <el-input></el-input> -->

      <div 
        class="file-drag-drop"
        @click="$refs.file.click()"
        @drop.prevent.stop="handleFile($event.dataTransfer.files[0])"
        @dragenter.prevent.stop
        @dragover.prevent.stop
        @dragleave.prevent.stop
      >
        <input type="file" ref="file" accept="image/*" @change="handleFile($event.target.files[0])" style="display: none;"/>
        <i class="el-icon-upload"></i>
        <span>Drag and Drop or click</span>
      </div>

      <el-button
        v-if="customIcon.value && customIcon.label"
        @click="localModel = icons.find(icon => icon.value === customIcon.value); showCustomIconDialog = false; $emit('change', localModel)"
        type="primary"
        class="el-icon-check"
      ></el-button>
    </el-popover>
  </div>
</template>

<script>
import Icons from '@/components/MenuEditor/components/el-icons'

export default {
  name: 'editor-icon',
  props: {
    value: {
      type: [Object, String],
      default: () => {
        return { type: null, value: null }
      }
    },
    options: {
      type: Object
    }
  },
  data () {
    return {
      localModel: typeof this.value === 'string' ? { type: 'cssClass', label: this.value, value: this.value } : this.value, // Переход с string на object (string использовался в listIcons a-file)
      icons: [],
      customIcon: {
        label: 'icon-custom-1',
        value: null
      },
      urlIcons: [],
      showCustomIconDialog: false
    }
  },
  watch: {
    customIcon: {
      handler () {
        this.parseIcons()
      },
      deep: true
    }
  },
  mounted () {
    if (typeof this.value === 'string') {
      // Изменить формат string (использовался в a-file) на object
      this.$emit('change', this.localModel)
    }

    if (this.localModel && this.localModel.type === 'src' && this.localModel.value) {
      // При загрузке компонента задана кастомная иконка
      this.customIcon.label = this.localModel.label
      this.customIcon.value = this.localModel.value
    }

    this.parseIcons()
  },
  methods: {
    async parseIcons () {
      let icons = []

      // Изображения из css классов
      const cssClassIcons = Icons.map(cssClassIcon => ({ type: 'cssClass', label: cssClassIcon, value: cssClassIcon }))
      icons.push(...cssClassIcons)

      // Загрузить изображения по URL
      let iconsSrc = await Promise.all(this.urlIcons.map(async urlIcon => ({ type: 'src', label: urlIcon.label, value: await this.imageUrlToBase64(urlIcon.value) })))
      // Отфильтровать изображения, где не удалось получить base64 (например при ошибке запроса к серверу)
      iconsSrc = iconsSrc.filter(iconBase64 => iconBase64.value)
      icons.push(...iconsSrc)

      if (this.customIcon.value && this.customIcon.label) {
        // Кастомная иконка
        icons.push({ type: 'src', label: this.customIcon.label, value: this.customIcon.value })
      }

      this.icons = icons
    },
    async imageUrlToBase64 (url) {
      let blob, base64

      // 1 способ: запрос из браузера
      try {
        const response = await fetch(url)
        blob = await response.blob()
      } catch (error) {
        // Может быть ошибка: отсутствует заголовок CORS «Access-Control-Allow-Origin»
        console.error(error)
      }

      // 2 способ: запрос через сервер, для обхода ограничения CORS заголовка
      // try {
      //   const { data } = await this.get(`${this.$config.api}/somedownloadservice?url=${url}`, { responseType: 'blob' })
      //   blob = new File([data], this.customIcon.label)
      // } catch (error) {
      //   console.error(error)
      // }
      
      if (blob) {
        base64 = await this.blobToBase64(blob)
      }

      return base64
    },
    blobToBase64 (blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => {
          if (reader.readyState === 2 /* DONE */) {
            return resolve(reader.result)
          } else {
            return reject({ message: 'Ошибка чтения файла', error: reader.error })
          }
        }
        reader.readAsDataURL(blob)
      })
    },
    async handleFile (file) {
      if (file.type.includes('image/')) {
        this.customIcon.value = await this.blobToBase64(file)
      }
    }
  }
}
</script>

<style scoped>
.icon-preview {
   font-size: 30px;
   width: 22px;
   height: 22px;
   vertical-align: middle;
}
.file-drag-drop {
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 10px;
  outline: 2px dashed lightgray;
  color: dimgray;
  cursor: pointer;
  transition: all 50ms;
}
.file-drag-drop:hover {
  background-color: #f4f6f7;
}
.file-drag-drop i {
  font-size: 4em;
  color: lightgrey;
}
.file-drag-drop span {
  font-size: 1.2em;
}
</style>
