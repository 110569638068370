export default class DetachTemplateFromProjectCommand
{
    private projectGuid: string;
    private id: number;

    constructor(projectGuid: string, id: number)
    {
        this.projectGuid = projectGuid;
        this.id = id;
    }

    getClassName(): string
    {
        return 'DetachTemplateFromProjectCommand';
    }

    getId(): number
    {
        return this.id;
    }

    getProjectGuid(): string
    {
        return this.projectGuid;
    }
}