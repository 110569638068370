import { ActionTree } from 'vuex'
import { ServiceState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, ServiceLoadingEvent, ServiceLoadedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { ServiceAPI } from '@/services/ReleaseEditor/infrastructure/api/ServiceAPI'

export const actions: ActionTree<ServiceState, any> = {
    [ActionTypes.GET_SERVICES]: async ({ commit, dispatch }, data) => {
        try {
            commit(new ServiceLoadingEvent());
            let res = await APIClient.shared.request(new ServiceAPI.GetServices(data.payload));
            commit(new ServiceLoadedEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    }
}
