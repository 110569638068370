
import Vue from 'vue'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { LogicEditorAPI } from '@/core/infrastructure/api/modules/LogicEditorAPI'

export default Vue.extend({
  name: 'StateFilter',
  props: {
    registryId: {
      type: Number
    },

    stateId: {
      type: Number,
      default: null
    }
  },

  watch: {
    localStateId: {
      handler: function (newValue) {
        this.$emit('change', newValue)
      }
    }
  },

  data: function () {
    return {
      localStateId: null,
      options: []
    }
  },

  async mounted () {
    const logic = await APIClient.shared.request(new LogicEditorAPI.GetLogicByRegistryId(this.registryId))
    this.options = await APIClient.shared.request(new LogicEditorAPI.GetStates([['logic_id', logic.id]]))
    this.localStateId = this.stateId
  }
})
