import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import OpenWorldQueryRepositoryInterface from '../../../domain/repository/OpenWorldQueryRepositoryInterface'
import OpenWorldGeojsonQuery from '../../query/OpenWorldGeojsonQuery'

export default class OpenWorldGeojsonHandler implements QueryHandlerInterface {
    private repo: OpenWorldQueryRepositoryInterface;

    constructor (repo: OpenWorldQueryRepositoryInterface) {
      this.repo = repo
    }

    execute (query: OpenWorldGeojsonQuery): Promise<any> {
      return this.repo.getOpenWorldGeojson(query.getPayload())
    }
}
