<template>
    <el-checkbox v-model="localValue" @change="$emit('change', localValue)"></el-checkbox>
</template>

<script>
export default {
  data () {
    return {
      localValue: this.value
    }
  },
  props: {
    value: Boolean
  },
  name: 'boolean-editor'
}
</script>

<style scoped>

</style>
