<template>
  <div >
    <div @click="select" >
      <img v-if="imageSrc" :src="imageSrc"/>
      <span v-if="!imageSrc">{{ alias }}</span>
    </div>
  </div>
</template>

<script>
import properties from './mixins/properties'
export default {
  name: 'image-block',
  mixins: [properties],
  props: {
    alias: {
      type: String,
      default: '',
      description: 'Псевдоним'
    },
    src: {
      type: String,
      default: null,
      description: 'Источник',
      editor: 'ImageSourceEditor'
    },
    componentStyle: {
      type: String,
      default: '',
      description: 'Стили'
    }
  },
  methods: {
    select () {
      this.$emit('select')
    }
  },
  computed: {
    imageSrc () {
      if (this.src) {
        if (this.src.startsWith('http')) {
          return this.src
        } else {
          return `${this.$config.api}${this.src}`
        }
      } else {
        return null
      }
    }
  }
}
</script>

<style scoped>
div, img{
  width: 100%;
  height: 100%;
}
</style>
