<template>
  <el-submenu v-if="item.length > 0" :index="ind2 + '-' + id">
    <template slot="title">{{name }}</template>
    <template v-for="a in item">
      <el-menu-item-group v-if="a.menu_type_id === 'title'" :key="a.id">
        <template v-if="a.menu_type_id === 'title' && a.is_visible" slot="title">{{a.name }}</template>
      </el-menu-item-group>

      <el-divider :key="a.id" v-if="a.menu_type_id === 'separator' && a.is_visible"></el-divider>
      <el-menu-item
        v-if="a.children.length === 0 && a.menu_type_id !== 'separator' && a.menu_type_id !== 'title' && a.is_visible"
        :key="a.id"
         @click="clickMenu(a.name, a.properties, a)"
      >
        <i class="system__menuIcon" :class="a.icon_value"></i>
        <span slot="title">{{a.name }}</span>
      </el-menu-item>
      <extra-menu-item
        v-if="a.children.length > 0"
        :item="a.children"
        :key="a.id"
        :id="a.id"
        :name="a.name"
        :clickMenu="clickMenu"
      ></extra-menu-item>
    </template>
  </el-submenu>
</template>

<script>
export default {
  name: 'extra-menu-item',
  props: ['name', 'item', 'id', 'clickMenu', 'ind2']
}
</script>

<style scoped>
.el-divider--horizontal {
  margin: 0px;
}
</style>
