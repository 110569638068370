import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    CONNECTION_LOADING_EVENT = 'connection_loading_event',
    CONNECTION_LOADED_EVENT = 'connection_loaded_event',
    CONNECTION_CREATED_EVENT = 'connection_created_event',
    CONNECTION_UPDATED_EVENT = 'connection_updated_event',
    CONNECTION_DELETED_EVENT = 'connection_deleted_event'
}

export class ConnectionLoadingEvent implements FluxStandardAction {
    type = MutationTypes.CONNECTION_LOADING_EVENT
}

export class ConnectionLoadedEvent implements FluxStandardAction {
    type = MutationTypes.CONNECTION_LOADED_EVENT
}

export class ConnectionCreatedEvent implements FluxStandardAction {
    type = MutationTypes.CONNECTION_CREATED_EVENT
    constructor (public location: string) {}
}

export class ConnectionUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.CONNECTION_UPDATED_EVENT
}

export class ConnectionDeletedEvent implements FluxStandardAction {
    type = MutationTypes.CONNECTION_DELETED_EVENT
    constructor (public guid: string) {}
}
