import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { BindServiceDTO } from '@/services/ReleaseEditor/domain/model/BindService/BindService'

export namespace BindServiceAPI {

    export class AddBindService implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: BindServiceDTO) {
            this.path = `/releaseeditor/releases/${params.release_guid}/services`;
        }
    }

    export class DeleteBindService implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.DELETE;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string, payload: string[][]) {
            console.log("constructor(guid: string, payload: string[][])=");
            console.log(guid);
            console.log(payload);
            this.path = `/releaseeditor/releases/${guid}/services?service_id=${payload}`;
        }
    }

    export class GetBindServices implements APIRequest<BindServiceDTO> {
        response: BindServiceDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = `/releaseeditor/releases/${payload['release_guid']}/services`
        }
    }
}