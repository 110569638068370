import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import FileCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/FileCommandRepositoryInterface'
import FileTransformCommand from '@/services/DigitalTwinEditor/application/command/FileTransformCommand'
import { TransformFileDTO } from '@/services/DigitalTwinEditor/domain/model/File/ModelFile'

export default class FileTransformHandler implements CommandHandlerInterface
{
    private repo: FileCommandRepositoryInterface;

    constructor(repo: FileCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: FileTransformCommand): Promise<void>
    {
        let item: TransformFileDTO = {
            model_id: command.getModelId(),
            guid: command.getGuid(),
            id: command.getId(),
            format: command.getFormat(),
            optimization: command.getOptimization()
        };
        return this.repo.transformFile(item);
    }
}