import { MutationTree } from 'vuex'
import { OpenWorldState } from './state'
import { MutationTypes, OpenWorldUpdatedEvent } from './mutation-types'

export const mutations: MutationTree<OpenWorldState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false;
    state.error = error;    
  },
  [MutationTypes.OPEN_WORLD_LOADING_EVENT]: (state) => {
    state.isLoading = true;
  },
  [MutationTypes.OPEN_WORLD_LOADED_EVENT]: (state) => {
    state.isLoading = false;
  },
  [MutationTypes.OPEN_WORLD_UPDATED_EVENT]: (state, action: OpenWorldUpdatedEvent) => {
    state.isLoading = false;
  }
}