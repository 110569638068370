import { FluxStandardAction } from '@/core/domain/type/types'
import RuleGroup from '@/services/TaskEditor/domain/model/RuleGroup'

export enum ActionTypes {
  GET_RULE_GROUP_BY_GUID = 'get_rule_group_by_id',
  CREATE_RULE_GROUP = 'create_rule_group',
  UPDATE_RULE_GROUP = 'update_rule_group',
  DELETE_RULE_GROUP = 'delete_rule_group'
}

export class GetRuleGroupByGuid implements FluxStandardAction {
  type = 'RuleGroup/' + ActionTypes.GET_RULE_GROUP_BY_GUID
  constructor (public guid: string) {}
}

export class CreateRuleGroup implements FluxStandardAction {
  type = 'RuleGroup/' + ActionTypes.CREATE_RULE_GROUP
  constructor (public payload: RuleGroup) {}
}

export class UpdateRuleGroup implements FluxStandardAction {
  type = 'RuleGroup/' + ActionTypes.UPDATE_RULE_GROUP
  constructor (public payload: RuleGroup) {}
}

export class DeleteRuleGroup implements FluxStandardAction {
  type = 'RuleGroup/' + ActionTypes.DELETE_RULE_GROUP
  constructor (public guid: string) {}
}
