import { ActionTree } from 'vuex'
import { BindServiceState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, BindServiceLoadingEvent, BindServiceLoadedEvent, BindServiceCreatedEvent, BindServiceDeletedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { BindServiceAPI } from '@/services/ReleaseEditor/infrastructure/api/BindServiceAPI'

export const actions: ActionTree<BindServiceState, any> = {
    [ActionTypes.ADD_BIND_SERVICE]: async ({ commit, dispatch }, data) => {
        try {
            commit(new BindServiceLoadingEvent());
            let location = await APIClient.shared.request(new BindServiceAPI.AddBindService(data.payload));
            commit(new BindServiceCreatedEvent(location));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.DELETE_BIND_SERVICE]: async ({ commit, dispatch }, data) => {
        try {
            await APIClient.shared.request(new BindServiceAPI.DeleteBindService(data.guid, data.payload));
            commit(new BindServiceDeletedEvent(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }         
    },
    [ActionTypes.GET_BIND_SERVICES]: async ({ commit, dispatch }, data) => {
        try {
            commit(new BindServiceLoadingEvent());
            let res = await APIClient.shared.request(new BindServiceAPI.GetBindServices(data.payload));
            commit(new BindServiceLoadedEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    }
}
