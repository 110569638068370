import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
  ERROR = 'error',
  CONSTRAINT_LOADING_EVENT = 'constraint_loading_event',
  CONSTRAINT_LOADED_EVENT = 'constraint_loaded_event',
  CONSTRAINT_CREATED_EVENT = 'constraint_created_event',
  CONSTRAINT_UPDATED_EVENT = 'constraint_updated_event',
  CONSTRAINT_DELETED_EVENT = 'constraint_deleted_event'
}

export class ConstraintLoadingEvent implements FluxStandardAction {
  type = MutationTypes.CONSTRAINT_LOADING_EVENT
}

export class ConstraintLoadedEvent implements FluxStandardAction {
  type = MutationTypes.CONSTRAINT_LOADED_EVENT
}

export class ConstraintCreatedEvent implements FluxStandardAction {
  type = MutationTypes.CONSTRAINT_CREATED_EVENT
  constructor (public location: string) {}
}

export class ConstraintUpdatedEvent implements FluxStandardAction {
  type = MutationTypes.CONSTRAINT_UPDATED_EVENT
  constructor (public location: string) {}
}

export class ConstraintDeletedEvent implements FluxStandardAction {
  type = MutationTypes.CONSTRAINT_DELETED_EVENT
  constructor (public guid: string) {}
}
