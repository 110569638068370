<template>
    <el-form-item :label="label">
      <el-input-number v-bind:value="value" v-on:input="$emit('input', $event)" controls-position="right"></el-input-number>
    </el-form-item>
</template>

<script>
export default {
  name: 'coordinateSystem',

  props: ['value', 'label']
}
</script>

<style scoped>

</style>
