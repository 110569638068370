import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'
import NameTooLong from '@/core/domain/exception/NameTooLong'
import NameMustNotBeEmpty from '@/core/domain/exception/NameMustNotBeEmpty'
import TreeElementType from '../TreeElementType'

export class ProjectDTO
{
    id?: Nullable<number>;
    guid?: Nullable<string>;
    name?: string;
    description?: string;
    alias?: string;
    templates?: Array<object> = [];
    template_id?: Nullable<number>;
    parent_id?: Nullable<number>;

    constructor({ id, guid, name, description, alias, templates, template_id, parent_id } : { id?: Nullable<number>, guid?: Nullable<string>, name?: string, description?: string, alias?: string, templates?: Array<object>, template_id?: Nullable<number>, parent_id?: Nullable<number>}) {
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.description = description;
        this.alias = alias;
        this.templates = templates ?? [];
        this.template_id = template_id;
        this.parent_id = parent_id;
    }
}

export class ProjectBuildDTO
{
    id?: Nullable<number>;
    guid?: Nullable<string>;
    name?: Nullable<string>;
    year?: Nullable<number>;
    month?: Nullable<number>;
    size?: Nullable<number>;
    extension?: Nullable<string>;
    template_form?: Nullable<string>;
    last_modified?: Nullable<string>;

    constructor ({id, guid, name, year, month, size, extension, template_form, last_modified} : {id?: Nullable<number>, guid?: Nullable<string>, name?: Nullable<string>, year?: Nullable<number>, month?: Nullable<number>, size?: Nullable<number>, extension?: Nullable<string>, template_form?: Nullable<string>, last_modified?: Nullable<string>}) {
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.month = month;
        this.size = size;
        this.extension = extension;
        this.template_form = template_form;
        this.last_modified = last_modified;
    }
}

export default class Project extends AbstractBaseEntity
{
    private id?: Nullable<number>;
    private guid?: Nullable<string>;
    private name: string;
    private description: Nullable<string>;
    private alias: Nullable<string>;
    private parentId: Nullable<number>;
    private templates: Array<object> = [];

    constructor(
        id: Nullable<number>,
        guid: Nullable<string>,
        name: string,
        description: Nullable<string>,
        alias: Nullable<string>,
        templates: Array<object> = [],
        parentId: Nullable<number>
    ) {
        super();
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.description = description;
        this.alias = alias;
        this.templates = templates;
        this.parentId = parentId;
        this.assertInvariants();
    }

    static create(dto: ProjectDTO): Project
    {
        return new Project(
            dto.id,
            dto.guid,
            dto.name,
            dto.description,
            dto.alias,
            dto.templates,
            dto.parent_id
        );
    }

    setId(id: number): void
    {
        this.id = id;
    }

    getId(): number
    {
        return this.id;
    }

    setGuid(guid: string): void
    {
        this.guid = guid;
    }

    getGuid(): string
    {
        return this.guid;
    }

    setName(name: string): void
    {
        if (name.length > 255) {
            throw new NameTooLong();
        }
        this.name = name;
    }

    getName(): string
    {
        return this.name;
    }

    assertInvariants(): void
    {
        if (this.name.length == 0) {
            throw new NameMustNotBeEmpty();
        }

        if (this.name.length > 255) {
            throw new NameTooLong();
        }
    }

    setDescription(description: Nullable<string>): void
    {
        this.description = description;
    }

    getDescription(): Nullable<string>
    {
        return this.description;
    }

    setAlias(alias: Nullable<string>): void
    {
        this.alias = alias;
    }

    getAlias(): Nullable<string>
    {
        return this.alias;
    }

    setTemplates(templates: Array<object>): void
    {
        this.templates = templates;
    }

    getTemplates(): Array<object>
    {
        return this.templates;
    }

    setParentId(parentId: Nullable<number>): void
    {
        this.parentId = parentId;
    }

    getParentId(): Nullable<number>
    {
        return this.parentId;
    }

    getType(): TreeElementType
    {
        return TreeElementType.PROJECT;
    }
}