import { FluxStandardAction } from '@/core/domain/type/types'
import { CommandDTO } from '@/services/LogicEditor/domain/model/Command'

export enum ActionTypes {
  GET_COMMAND = 'get_command',
  GET_COMMAND_BY_GUID = 'get_command_by_guid',
  GET_COMMAND_EVENT_TYPE_PROPERTIES = 'get_command_event_type_properties',
  GET_COMMAND_EVENT_TYPES = 'get_command_event_types',
  CREATE_COMMAND = 'create_command',
  UPDATE_COMMAND = 'update_command',
  DELETE_COMMAND = 'delete_command'
}

export class GetCommands implements FluxStandardAction {
  type = 'Command/' + ActionTypes.GET_COMMAND
  constructor (public payload: object) {}
}

export class GetCommandByGuid implements FluxStandardAction {
  type = 'Command/' + ActionTypes.GET_COMMAND_BY_GUID
  constructor (public guid: string) {}
}

export class GetCommandEventTypeProperties implements FluxStandardAction {
  type = 'Command/' + ActionTypes.GET_COMMAND_EVENT_TYPE_PROPERTIES
  constructor (public eventId: string) {}
}

export class GetCommandEventTypes implements FluxStandardAction {
  type = 'Command/' + ActionTypes.GET_COMMAND_EVENT_TYPES
  constructor () {}
}

export class CreateCommand implements FluxStandardAction {
  type = 'Command/' + ActionTypes.CREATE_COMMAND
  constructor (public payload: CommandDTO) {}
}

export class UpdateCommand implements FluxStandardAction {
  type = 'Command/' + ActionTypes.UPDATE_COMMAND
  constructor (public payload: CommandDTO) {}
}

export class DeleteCommand implements FluxStandardAction {
  type = 'Command/' + ActionTypes.DELETE_COMMAND
  constructor (public guid: string) {}
}
