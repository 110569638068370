import { FluxStandardAction, Nullable } from '@/core/domain/type/types'

export enum ActionTypes {
  GET_LOGIC_TREE_ELEMENTS = 'get_logic_tree_elements',
  GET_LOGIC_TREE_ELEMENTS_TREE = 'get_logic_tree_elements_tree',
  SEARCH_LOGIC_TREE_ELEMENTS = 'search_logic_tree_elements',
  GET_LOGIC_TREE_ELEMENT_BY_GUID = 'get_logic_tree_element_by_guid',
  GET_LOGIC_TREE_ELEMENT_BY_ELEMENT_GUID = 'get_logic_tree_element_by_element_guid'
}

export class GetLogicTreeElements implements FluxStandardAction {
  type = 'LogicTreeElement/' + ActionTypes.GET_LOGIC_TREE_ELEMENTS
  constructor (public payload: object) {}
}

export class GetLogicTreeElementsTree implements FluxStandardAction {
  type = 'LogicTreeElement/' + ActionTypes.GET_LOGIC_TREE_ELEMENTS_TREE
  constructor (public payload: object) {}
}

export class SearchLogicTreeElements implements FluxStandardAction {
  type = 'LogicTreeElement/' + ActionTypes.SEARCH_LOGIC_TREE_ELEMENTS
  constructor (public payload: object) {}
}

export class GetLogicTreeElementByGuid implements FluxStandardAction {
  type = 'LogicTreeElement/' + ActionTypes.GET_LOGIC_TREE_ELEMENT_BY_GUID
  constructor (public guid: string) {}
}

export class GetLogicTreeElementByElementGuid implements FluxStandardAction {
  type = 'LogicTreeElement/' + ActionTypes.GET_LOGIC_TREE_ELEMENT_BY_ELEMENT_GUID
  constructor (public elementGuid: string) {}
}
