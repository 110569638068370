import { AxiosResponse } from 'axios'
import { Nullable } from '@/core/domain/type/types'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { ProjectTreeElementDTO } from '@/services/MapEditor/domain/model/Project/ProjectTreeElement'

export namespace ProjectTreeElementAPI {

    export class AddProjectTreeProjectElement implements APIRequest<string> {
        response: string;
        path = '/mapeditor/project_tree_elements/projects';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.headers.location
        constructor(public params: ProjectTreeElementDTO) {}
    }

    export class AddProjectTreeGroupElement implements APIRequest<string> {
        response: string;
        path = '/mapeditor/project_tree_elements/groups';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.headers.location
        constructor(public params: ProjectTreeElementDTO) {}
    }

    export class PutProjectTreeProjectElement implements APIRequest<string> {
        response: string;
        path = '/mapeditor/project_tree_elements/projects';
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.headers.location
        constructor(public params: ProjectTreeElementDTO) {}
    }

    export class DeleteProjectTreeElement implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.DELETE;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/mapeditor/project_tree_elements/${guid}`;
        }
    }

    export class GetProjectTreeElementByGuid implements APIRequest<ProjectTreeElementDTO> {
        response: ProjectTreeElementDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/mapeditor/project_tree_elements/${guid}`;
        }
    }

    export class GetProjectTreeElements implements APIRequest<ProjectTreeElementDTO> {
        response: ProjectTreeElementDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(parentId: Nullable<number>) {
            if (parentId !== null) {
                this.path = `/mapeditor/project_tree_elements/?parent_id=${parentId}`;
            } else {
                this.path = `/mapeditor/project_tree_elements/`;
            }
        }
    }

    export class UpdateParent implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: ProjectTreeElementDTO) {
            this.path = `/mapeditor/project_tree_elements/${params.parent_id}/child`;
        }
    }

}