import { FluxStandardAction } from '@/core/domain/type/types'
import { RelatedObjectDTO } from '@/services/LogicEditor/domain/model/RelatedObject'

export enum ActionTypes {
  GET_RELATED_OBJECT_BY_GUID = 'get_related_object_by_id',
  CREATE_RELATED_OBJECT = 'create_related_object',
  UPDATE_RELATED_OBJECT = 'update_related_object',
  DELETE_RELATED_OBJECT = 'delete_related_object'
}

export class GetRelatedObjectByGuid implements FluxStandardAction {
  type = 'RelatedObject/' + ActionTypes.GET_RELATED_OBJECT_BY_GUID
  constructor (public guid: string) {}
}

export class CreateRelatedObject implements FluxStandardAction {
  type = 'RelatedObject/' + ActionTypes.CREATE_RELATED_OBJECT
  constructor (public payload: RelatedObjectDTO) {}
}

export class UpdateRelatedObject implements FluxStandardAction {
  type = 'RelatedObject/' + ActionTypes.UPDATE_RELATED_OBJECT
  constructor (public payload: RelatedObjectDTO) {}
}

export class DeleteRelatedObject implements FluxStandardAction {
  type = 'RelatedObject/' + ActionTypes.DELETE_RELATED_OBJECT
  constructor (public guid: string) {}
}
