import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    RELATED_OBJECT_LOADING_EVENT = 'related_object_loading_event',
    RELATED_OBJECT_LOADED_EVENT = 'related_object_loaded_event',
    RELATED_OBJECT_CREATED_EVENT = 'related_object_created_event',
    RELATED_OBJECT_UPDATED_EVENT = 'related_object_updated_event',
    RELATED_OBJECT_DELETED_EVENT = 'related_object_deleted_event'
}

export class RelatedObjectLoadingEvent implements FluxStandardAction {
  type = MutationTypes.RELATED_OBJECT_LOADING_EVENT
}

export class RelatedObjectLoadedEvent implements FluxStandardAction {
  type = MutationTypes.RELATED_OBJECT_LOADED_EVENT
}

export class RelatedObjectCreatedEvent implements FluxStandardAction {
  type = MutationTypes.RELATED_OBJECT_CREATED_EVENT
  constructor (public location: string) {}
}

export class RelatedObjectUpdatedEvent implements FluxStandardAction {
  type = MutationTypes.RELATED_OBJECT_UPDATED_EVENT
  constructor (public location: string) {}
}

export class RelatedObjectDeletedEvent implements FluxStandardAction {
  type = MutationTypes.RELATED_OBJECT_DELETED_EVENT
  constructor (public guid: string) {}
}
