import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import SourceQueryRepositoryInterface from '../../../domain/repository/SourceQueryRepositoryInterface'
import SourceProjectsQuery from '../../query/SourceProjectsQuery'

export default class SourceProjectsHandler implements QueryHandlerInterface
{
    private repo: SourceQueryRepositoryInterface;

    constructor(repo: SourceQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: SourceProjectsQuery): Promise<any>
    {
        return this.repo.getParentRecords(query.getGuid());
    }
}