<template>
  <tree-select
    v-bind:value="value"
    v-on:input="$emit('input', $event)"
    :options="options"
    :load-options="loadOptions"
    :multiple="multiple"
    :disabled="disabled"
    :placeholder="placeholder"
    class="custom_scrollbar"
    :normalizer="normalizer"
    :disable-branch-nodes="true"
    :clear-on-select="false"
    :append-to-body="appendToBody"
    :clearable="clearable"
    :clearValueText="clearValueText"
    :noChildrenText="noChildrenText"
    :noResultsText="noResultsText"
    :limitText="limitText"
    :limit="1"
    :matchKeys="matchKeys"
    @select="selectAlias"
    :value-consists-of="valueConsistsOf">
    <div slot="value-label" slot-scope="{ node }">{{ node.label }} <b> (id: {{ node.raw.id }})</b></div>
    <label v-if="standardTree" slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName }" :class="labelClassName">
      {{ node.label }}(id: {{node.id}}, <b>{{ $locale.object_editor.entity_types[node.raw.entity_type_id] || node.raw.entity_type_id }}</b>)
    </label>
    <label v-else slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName }" :class="labelClassName">
      <b>{{ node.label }}</b><br><span style="font-size: 10px">attr_{{node.id}}_</span><span style="float: right; font-size: 10px;">{{ $locale.object_editor.entity_types[node.raw.entity_type_id] || node.raw.entity_type_id }}</span>
    </label>
  </tree-select>
</template>

<script>
import { Treeselect, LOAD_ROOT_OPTIONS, LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Entity from '../ObjectEditor/Models/Entity'

const OBJECT_TYPE_ALL = 'all'
const OBJECT_TYPE_REGISTRY = 'registry'
const OBJECT_TYPE_FIELD = 'field'

export default {
  name: 'ObjectTree',

  components: {
    'tree-select': Treeselect
  },

  props: {
    selectAlias: {
      type: Function,
      default: function (value) {}
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    standardTree: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },
    objectType: {
      type: String,
      default: OBJECT_TYPE_ALL
    },
    appendToBody: {
      type: Boolean,
      default: true
    },
    value: {
      type: [String, Number, Array],
      default: null
    },
    parentId: {
      type: Number,
      default: null
    },
    placeholder: {
      type: String,
      default: 'Select'
    }
  },

  watch: {
    parentId (value) {
      this.options = null
    }
  },

  data () {
    return {
      options: null,
      matchKeys: ['name', 'id'],
      valueConsistsOf: 'LEAF_PRIORITY',
      clearValueText: 'Очистить',
      noChildrenText: 'Нет данных',
      noResultsText: 'Не найдено',
      limitText: count => `и еще ${count}`,
      normalizer (node) {
        return {
          id: node.id,
          label: node.name,
          children: node.children,
          isLeaf: node.leaf
        }
      },
      registryTypes: ['registry_group', 'registry']
    }
  },

  methods: {
    async loadOptions ({ action, parentNode, callback, instanceId }) {
      if (action === LOAD_ROOT_OPTIONS) {
        this.options = await this.loadObjects(this.parentId)
      } else if (action === LOAD_CHILDREN_OPTIONS) {
        if (typeof parentNode.children !== 'undefined') {
          parentNode.children = await this.loadObjects(parentNode.id, parentNode.entity_type_id)
        }
      }
    },
    async loadObjects (parentId = null, type = null) {
      let objects = []
      if (!parentId) {
        objects = await new Entity().params({ root: true }).$get()
      } else {
        if (this.objectType === OBJECT_TYPE_REGISTRY) {
          if (type === 'registry_group') {
            objects = await new Entity({ id: parentId }).children().$get()
          }
        } else {
          objects = await new Entity({ id: parentId }).children().$get()
        }
      }

      if (this.objectType === OBJECT_TYPE_REGISTRY) {
        objects.forEach(item => {
          if (item.entity_type_id === 'registry_group') {
            item.leaf = true
            item.children = null
          } else {
            item.leaf = false
          }
        })
      } else if (this.objectType === OBJECT_TYPE_ALL) {
        objects.forEach(item => {
          item.leaf = true
          item.children = null
        })
      } else if (this.objectType === OBJECT_TYPE_FIELD) {
        objects.forEach(item => {
          if (['registry_group', 'registry', 'field_group'].indexOf(item.entity_type_id) !== -1) {
            item.leaf = true
            item.children = null
          } else {
            item.leaf = false
          }
        })
      }

      return objects
    }
  }
}
</script>

<style scoped></style>
