import { ActionTree } from 'vuex'
import { LogsState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, LogLoadingEvent, LogLoadedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { LogsAPI } from '@/services/LogService/infrastructure/api/LogsAPI'

export const actions: ActionTree<LogsState, any> = {
  [ActionTypes.GET_RESOURCES]: async ({ commit, dispatch }, data) => {
    try {
      commit(new LogLoadingEvent())
      const res = await APIClient.shared.request(new LogsAPI.GetResources(data.payload))
      commit(new LogLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_LOGS_BY_RESOURCE]: async ({ commit, dispatch }, data) => {
    try {
      commit(new LogLoadingEvent())
      const res = await APIClient.shared.request(new LogsAPI.GetLogsByResource(data.payload, data.resource))
      commit(new LogLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_COUNT_BY_RESOURCE]: async ({ commit, dispatch }, data) => {
    try {
      commit(new LogLoadingEvent())
      const res = await APIClient.shared.request(new LogsAPI.GetCountByResource(data.payload, data.resource))
      commit(new LogLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_LOGS_BY_RESOURCE_AND_DATE]: async ({ commit, dispatch }, data) => {
    try {
      commit(new LogLoadingEvent())
      const res = await APIClient.shared.request(new LogsAPI.GetLogsByResourceAndDate(data.payload, data.resource))
      commit(new LogLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  }
}
