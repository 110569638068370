import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'
import TreeElementType from '../TreeElementType'
import Model from './Model'
import ModelGroup from './ModelGroup'


export class ModelTreeElementDTO
{
    id?: Nullable<number>;
    guid?: Nullable<string>;
    name?: Nullable<string>;
    alias?: Nullable<string>;
    row_order?: Nullable<number>;
    parent_id?: Nullable<number>;
    model_id?: Nullable<number>;
    model_group_id?: Nullable<number>;
    element_type?: Nullable<string>;
    element_guid?: Nullable<string>;

    constructor({ name, alias, model_id, model_group_id, parent_id}: { name?: Nullable<string>, alias?: Nullable<string>, model_id?: Nullable<number>, model_group_id?: Nullable<number>, parent_id?: Nullable<number>}) {
        this.name = name;
        this.alias = alias;
        this.model_id = model_id;
        this.model_group_id = model_group_id;
        this.parent_id = parent_id;
    }
}

export default class ModelTreeElement extends AbstractBaseEntity
{
    private id?: Nullable<number>;
    private guid?: Nullable<string>;
    private name?: Nullable<string>;
    private rowOrder?: Nullable<number> = 0;
    private parentId?: Nullable<number>;
    private modelId?: Nullable<number>;
    private modelGroupId?: Nullable<number>;
    private elementType?: Nullable<string>;
    private elementGuid?: Nullable<string>;
    private model?: Nullable<Model>;
    private group?: Nullable<ModelGroup>;

    constructor(
        id?: Nullable<number>,
        guid?: Nullable<string>,
        name?: Nullable<string>,
        rowOrder?:Nullable<number>,
        parentId?: Nullable<number>,
        modelId?: Nullable<number>,
        modelGroupId?: Nullable<number>,
        elementType?: Nullable<string>,
        elementGuid?: Nullable<string>
    ) {
        super();
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.rowOrder = rowOrder;
        this.parentId = parentId;
        this.modelId = modelId;
        this.modelGroupId = modelGroupId;
        this.elementType = elementType;
        this.elementGuid = elementGuid;
        if (elementType == TreeElementType.MODEL) {
            this.model = Model.create({guid: elementGuid, name: name});
            this.group = null;
        } else if (elementType == TreeElementType.MODEL_GROUP) {
            this.group = ModelGroup.create({guid: elementGuid, name: name});
            this.model = null;
        }
    }

    static create(dto: ModelTreeElementDTO): ModelTreeElement
    {
        return new ModelTreeElement(
            dto.id,
            dto.guid,
            dto.name,
            dto.row_order,
            dto.parent_id,
            dto.model_id,
            dto.model_group_id,
            dto.element_type,
            dto.element_guid
        );
    }

    getName(): string
    {
        return this.name;
    }

    setId(id: number): void
    {
        this.id = id;
    }

    getId(): number
    {
        return this.id;
    }

    setGuid(guid: string): void
    {
        this.guid = guid;
    }

    getGuid(): string
    {
        return this.guid;
    }

    getElementGuid(): string
    {
        return this.elementGuid;
    }

    setRowOrder(rowOrder: number): void
    {
        this.rowOrder = rowOrder;
    }

    getRowOrder(): number
    {
        return this.rowOrder;
    }

    getModel(): Nullable<Model>
    {
        return this.model;
    }

    getModelId(): Nullable<number>
    {
        return this.modelId;
    }

    getGroupId(): Nullable<number>
    {
        return this.modelGroupId;
    }

    getGroup(): Nullable<ModelGroup>
    {
        return this.group;
    }

    getParentId(): Nullable<number>
    {
        return this.parentId;
    }

    getElementType(): string
    {
        return this.elementType;
    }
}