import { MutationTree } from 'vuex'
import { LogicState, initialState } from './state'
import { MutationTypes, LogicCreatedEvent, LogicUpdatedEvent, LogicDeletedEvent } from './mutation-types'

export const mutations: MutationTree<LogicState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false
    state.error = error
  },
  [MutationTypes.LOGIC_LOADING_EVENT]: (state) => {
    state.isLoading = true
  },
  [MutationTypes.LOGIC_LOADED_EVENT]: (state) => {
    state.isLoading = false
  },
  [MutationTypes.LOGIC_CREATED_EVENT]: (state, action: LogicCreatedEvent) => {
    state.isLoading = false
    state.location = action.location
  },
  [MutationTypes.LOGIC_UPDATED_EVENT]: (state, action: LogicUpdatedEvent) => {
    state.isLoading = false
  },
  [MutationTypes.LOGIC_DELETED_EVENT]: (state, action: LogicDeletedEvent) => {
    state.isLoading = false
    state.deletedLogicGuid = action.guid
  }
}
