import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
  ERROR = 'error',
  PROCEDURE_LOADING_EVENT = 'logic_loading_event',
  PROCEDURE_LOADED_EVENT = 'logic_loaded_event',
  PROCEDURE_CREATED_EVENT = 'logic_created_event',
  PROCEDURE_UPDATED_EVENT = 'logic_updated_event',
  PROCEDURE_DELETED_EVENT = 'logic_deleted_event',

  PROCEDURE_STAGE_TYPE_LOADING_EVENT = 'procedure_stage_type_loading_event',
  PROCEDURE_STAGE_TYPE_LOADED_EVENT = 'procedure_stage_type_loaded_event',

  PROCEDURE_STAGE_LOADING_EVENT = 'procedure_stage_loading_event',
  PROCEDURE_STAGE_LOADED_EVENT = 'procedure_stage_loaded_event'
}

export class ProcedureLoadingEvent implements FluxStandardAction {
  type = MutationTypes.PROCEDURE_LOADING_EVENT
}

export class ProcedureLoadedEvent implements FluxStandardAction {
  type = MutationTypes.PROCEDURE_LOADED_EVENT
}

export class ProcedureCreatedEvent implements FluxStandardAction {
  type = MutationTypes.PROCEDURE_CREATED_EVENT
  constructor (public location: string) {}
}

export class ProcedureUpdatedEvent implements FluxStandardAction {
  type = MutationTypes.PROCEDURE_UPDATED_EVENT
  constructor (public location: string) {}
}

export class ProcedureDeletedEvent implements FluxStandardAction {
  type = MutationTypes.PROCEDURE_DELETED_EVENT
  constructor (public guid: string) {}
}

export class ProcedureStageTypeLoadingEvent implements FluxStandardAction {
  type = MutationTypes.PROCEDURE_STAGE_TYPE_LOADING_EVENT
}

export class ProcedureStageTypeLoadedEvent implements FluxStandardAction {
  type = MutationTypes.PROCEDURE_STAGE_TYPE_LOADED_EVENT
}

export class ProcedureStageLoadingEvent implements FluxStandardAction {
  type = MutationTypes.PROCEDURE_STAGE_LOADING_EVENT
}

export class ProcedureStageLoadedEvent implements FluxStandardAction {
  type = MutationTypes.PROCEDURE_STAGE_LOADED_EVENT
}
