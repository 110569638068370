export default class DownloadPrjCommand
{
    private guid: string;
    private name: string;

    constructor(guid: string, name: string)
    {
        this.guid = guid;
        this.name = name;
    }

    getClassName(): string
    {
        return 'DigitalTwinDownloadPrjCommand';
    }

    getGuid(): string
    {
        return this.guid;
    }

    getName(): string
    {
        return this.name;
    }
}