export interface LogicState {
  isLoading: boolean
  location: string
  deletedLogicGuid: string
  error: object
}

export const initialState = (): LogicState => {
  return {
    isLoading: false,
    location: null,
    deletedLogicGuid: null,
    error: null
  }
}
