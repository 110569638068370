import { Module } from 'vuex'
import { actions } from './actions'
import { mutations } from './mutations'
import { TaskState, initialState } from './state'

const store: Module<TaskState, any> = {
   namespaced: true,
   state: initialState,
   actions,
   mutations
}

export default store;