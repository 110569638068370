<template>
  <div>
    <el-select
      v-model="selectedItem"
      v-bind="getSelectConfig"
      :filterable="true"
      :loading="this.loading"
      :size="this.size"
      :remote="true"
      :remote-method="this.loadCS"
      :disabled="isDisabled"
      placeholder="Введите srid"
      :multiple="multiple"
    >
      <el-option
        v-for="item in selectItems"
        :key="item.value"
        :label="item.label"
        :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
  import { APIClient } from '@/core/infrastructure/api/APIClient'
  import { CoordinateSystemAPI } from '@/services/MapEditor/infrastructure/api/CoordinateSystemAPI'

  export default {
    name: 'CoordinateSystem',
    props: {
      value: {
        default: null
      },
      size: {
        type: String,
        default: null
      },
      options: {
        type: Array,
        default: function () {
          return []
        }
      },
      isDisabled: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: false
      }
    },
    watch: {
      selectedItem: {
        handler: function (newVal) {
          this.$emit('change-cs', newVal)
        }
      },
      options: {
        handler: function (newVal) {
          this.loadCS()
        },
        deep: true
      }
    },
    data: function () {
      return {
        selectItems: [],
        loading: false,
        selectedItem: this.value
      }
    },
    computed: {
      getSelectConfig () {
        return {
          'filterable': true,
          'loading': this.loading,
          'size': this.size,
          'remote': !this.loading,
          'remote-method': this.loadCS
        }
      }
    },
    async mounted () {
      this.loadCS()
    },
    methods: {
      async loadCS (query) {
        this.loading = true
        let items = []
        if (query) {
          let limit = 100
          let parameters = `limit=${limit}&order=srid:asc`
          if (query) {
            parameters += `&or[0][like][srid]=%25${query}%25`
            parameters += `&or[1][like][proj4text]=%25${query}%25`
            parameters += `&or[2][like][auth_name]=%25${query}%25`
          }
          items = await APIClient.shared.request(
            new CoordinateSystemAPI.GetCoordinateSystems(
              parameters
            )
          )
        }
        if (this.value) {
          if (Array.isArray(this.value)) {
            for(let i = 0; i < this.value.length; i++) {
              let a = await APIClient.shared.request(
                new CoordinateSystemAPI.GetCoordinateSystems(
                  `and[0][like][srid]=${this.value[i]}`
                ))
              items.push(a[0])
            }
          } else {
            let a = await APIClient.shared.request(
              new CoordinateSystemAPI.GetCoordinateSystems(
                `and[0][like][srid]=${this.value}`
              ))
            items.push(a[0])
          }
        }

        this.selectItems.splice(0)

        items.forEach((item) => {
          let label = ''
          let proj4text = item.proj4text
          if (item.description) {
            label = item.description
          } else if (item.auth_name !== 'EPSG') {
            label = item.auth_name
          } else {
            if (proj4text) {
              let aa = proj4text.indexOf('+title=')
              if (aa !== -1) {
                aa += 7
                let bb = proj4text.indexOf(' +', aa)
                if (bb === -1) {
                  bb = proj4text.length
                }
                label = proj4text.substring(aa, bb)
              }
            }
          }

          this.addFormattedOption({
            value: item.srid,
            label: '(EPSG:' + item.srid + ') ' + label,
            srid: item.srid
          })
        })

        if (!query && this.options.length > 0) {
          this.addFormattedOptions(this.options)
        }

        this.loading = false
      },
      addFormattedOptions(options) {
        options.forEach((x)=>this.addFormattedOption(x), this)
      },
      addFormattedOption(option) {
        if (!this.selectItems.find((x)=> x.value === option.value)) {
          this.selectItems.splice(this.selectItems.length, 0, option)
        }
      }
    }
  }
</script>

<style scoped>

</style>
