import { MutationTree } from 'vuex'
import { DagState, initialState } from './state'
import { MutationTypes, DagCreatedEvent } from './mutation-types'

export const mutations: MutationTree<DagState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false;
    state.error = error;    
  },
  [MutationTypes.DAG_LOADING_EVENT]: (state) => {
    state.isLoading = true;
  },
  [MutationTypes.DAG_LOADED_EVENT]: (state) => {
    state.isLoading = false;
  },
  [MutationTypes.DAG_CREATED_EVENT]: (state, action: DagCreatedEvent) => {
    state.location = action.location;
    state.isLoading = false;
  }
}