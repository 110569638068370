export default {
  template: `
    <div class="ag-header-cell-comp-wrapper">
      <div class="ag-cell-label-container">
        <div class="ag-header-cell-label">
        <span class="ag-header-cell-text">
          {{ params.title }}
          <div :style="{display: 'block', width: '100%'}">
            <div
              v-for="item in params.percentageOptions"
              :style="{display: 'inline-block'}">
            <div :style="{
              display: 'inline-block',
              background: item.color,
              width: '10px',
              height: '10px',
              marginRight: '5px'
            }"></div>
            <span :style="{
              marginRight: '5px'
            }">{{ item.name }}</span>
            </div>
          </div>
        </span>
        </div>
      </div>
    </div>
  `,
  data: function () {
    return {
    }
  },
  beforeMount () {
  },
  mounted () {
  },
  methods: {
  }
}
