import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { ModelTreeElementDTO } from '@/services/DigitalTwinEditor/domain/model/Model/ModelTreeElement'

export enum ActionTypes {
    ADD_MODEL_TREE_MODEL_ELEMENT = 'add_model_tree_model_element',
    ADD_MODEL_TREE_GROUP_ELEMENT = 'add_model_tree_group_element',
    DELETE_MODEL_TREE_ELEMENT = 'delete_model_tree_element',
    GET_MODEL_TREE_ELEMENT_BY_GUID = 'get_model_tree_element_by_guid',
    GET_MODEL_TREE_ELEMENTS = 'get_model_tree_elements'
}

export class AddModelTreeModelElement implements FluxStandardAction {
    type = 'ModelTreeElement/' + ActionTypes.ADD_MODEL_TREE_MODEL_ELEMENT
    constructor(public payload: ModelTreeElementDTO){}
}

export class AddModelTreeGroupElement implements FluxStandardAction {
    type = 'ModelTreeElement/' + ActionTypes.ADD_MODEL_TREE_GROUP_ELEMENT
    constructor(public payload: ModelTreeElementDTO){}
}

export class DeleteModelTreeElement implements FluxStandardAction {
    type = 'ModelTreeElement/' + ActionTypes.DELETE_MODEL_TREE_ELEMENT
    constructor(public guid: string){}
}

export class GetModelTreeElementByGuid implements FluxStandardAction {
    type = 'ModelTreeElement/' + ActionTypes.GET_MODEL_TREE_ELEMENT_BY_GUID
    constructor(public guid: string){}
}

export class GetModelTreeElements implements FluxStandardAction {
    type = 'ModelTreeElement/' + ActionTypes.GET_MODEL_TREE_ELEMENTS
    constructor(public parentId: Nullable<number>){}
}
