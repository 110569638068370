import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { ModelInstanceDTO } from '@/services/DigitalTwinEditor/domain/model/Project/ModelInstance'

export enum ActionTypes {
    ADD_MODEL_INSTANCE = 'add_model_instance',
    UPDATE_MODEL_INSTANCE = 'update_model_instance',
    DELETE_MODEL_INSTANCE = 'delete_model_instance',
    GET_MODEL_INSTANCE_BY_GUID = 'get_model_instance_by_guid'
}

export class AddModelInstance implements FluxStandardAction {
    type = 'ModelInstance/' + ActionTypes.ADD_MODEL_INSTANCE
    constructor(public payload: ModelInstanceDTO){}
}

export class UpdateModelInstance implements FluxStandardAction {
    type = 'ModelInstance/' + ActionTypes.UPDATE_MODEL_INSTANCE
    constructor(public payload: ModelInstanceDTO){}
}

export class DeleteModelInstance implements FluxStandardAction {
    type = 'ModelInstance/' + ActionTypes.DELETE_MODEL_INSTANCE
    constructor(public guid: string){}
}

export class GetModelInstanceByGuid implements FluxStandardAction {
    type = 'ModelInstance/' + ActionTypes.GET_MODEL_INSTANCE_BY_GUID
    constructor(public guid: string){}
}
