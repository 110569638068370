import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { ConfigurationElementDTO } from '@/services/SyncService/domain/model/ConfigurationElement/ConfigurationElement'

export namespace ConfigurationElementAPI {
  export class GetEditorEntities implements APIRequest<ConfigurationElementDTO> {
    response: ConfigurationElementDTO
    path: string
    method = HTTPMethod.GET
    parse = (data: AxiosResponse) => data.data
    constructor (payload: string) {
      this.path = '/syncservice/configuration_elements/tree/' + payload
    }
  }

  export class ConfigurationElementsExport implements APIRequest<ConfigurationElementDTO> {
    response: ConfigurationElementDTO
    path: string
    params: any
    method = HTTPMethod.POST
    parse = (data: AxiosResponse) => data.data
    constructor (payload: string[][]) {
      this.path = '/syncservice/configuration_elements/export'
      this.params = payload
    }
  }
}
