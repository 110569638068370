import { FluxStandardAction, Nullable } from '@/core/domain/type/types'

export enum ActionTypes {
  GET_TREE_ELEMENTS = 'get_tree_elements',
  GET_TREE_ELEMENTS_TREE = 'get_tree_elements_tree',
  GET_TREE_ELEMENT_BY_GUID = 'get_tree_element_by_guid',
  GET_TREE_ELEMENT_BY_ELEMENT_GUID = 'get_tree_element_by_element_guid'
}

export class GetTreeElements implements FluxStandardAction {
  type = 'TreeElement/' + ActionTypes.GET_TREE_ELEMENTS
  constructor (public payload: object) {}
}

export class GetTreeElementsTree implements FluxStandardAction {
  type = 'TreeElement/' + ActionTypes.GET_TREE_ELEMENTS_TREE
  constructor (public payload: object) {}
}

export class GetTreeElementByGuid implements FluxStandardAction {
  type = 'TreeElement/' + ActionTypes.GET_TREE_ELEMENT_BY_GUID
  constructor (public guid: string) {}
}

export class GetTreeElementByElementGuid implements FluxStandardAction {
  type = 'TreeElement/' + ActionTypes.GET_TREE_ELEMENT_BY_ELEMENT_GUID
  constructor (public elementGuid: string) {}
}
