<template>
    <v-runtime-template :template="template" ref="template-compiler"></v-runtime-template>
</template>

<script>
import VRuntimeTemplate from 'v-runtime-template'
import AStub from '@/components/InterfaceEditor/components/basic/a-stub.vue'

export default {
  components: {
    VRuntimeTemplate,
    AStub
  },
  name: 'html-container',
  props: {
    html: {
      type: String,
      default: null
    },
    components: {
      type: Array,
      default () { return [] }
    },
    model: {
      type: Object,
      default () { return {} }
    }
  },
  mounted () {
    let attributes = this.html.match(/\{{(.*?)\}}/g) || []
    let me = this
    let result = this.html
    attributes.forEach((attribute) => {
      attribute = attribute.replace('{{', '').replace('}}', '')
      result = result.replace(`{{${attribute}}}`,
        `<a-stub :attribute="'${attribute}'"/>`
      )
    })

    let div = document.createElement('div')
    div.classList.add('ck-content')
    div.innerHTML = result

    let components = div.getElementsByClassName('placeholder')
    for (let component of components) {
      let guid = component.getAttribute('guid')
      let cmp = this.components.find(item => item.guid === guid)
      if (cmp) {
        component.outerHTML = `<component
            class="accent-component placeholder"
           v-bind="this.components.find(item => item.guid === '${guid}').properties"
           ref="component_${guid}"
           v-model="model['${cmp.properties.name}']"
           :is="this.components.find(item => item.guid === '${guid}').type"
         ></component>`
      }
    }
    this.template = div.outerHTML
  },
  data () {
    return {
      template: null
    }
  },
  methods: {
    getRefs () {
      let result = {}
      for (let key in this.$refs['template-compiler'].$children[0].$refs) {
        result[key] = [this.$refs['template-compiler'].$children[0].$refs[key]]
      }

      return result
    }
  }
}
</script>

<style scoped src="./CKEditor.css">
</style>
<style scoped>
    .ck-content {
        padding: 0 var(--ck-spacing-standard)
    }
</style>
