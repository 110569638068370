import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    SERVICE_LOADING_EVENT = 'service_loading_event',
    SERVICE_LOADED_EVENT = 'service_loaded_event'
}

export class ServiceLoadingEvent implements FluxStandardAction {
    type = MutationTypes.SERVICE_LOADING_EVENT
}

export class ServiceLoadedEvent implements FluxStandardAction {
    type = MutationTypes.SERVICE_LOADED_EVENT
}
