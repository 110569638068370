export interface SchemaState {
    isLoading: boolean;
    error: object;
  }

export const initialState = (): SchemaState => {
  return {
    isLoading: false,
    error: null
  }
}
