<template>
  <div>
    <el-select
      v-model="value.stateId"
      :multiple="options.multiple"
      filterable
      :clearable="options.clearable"
      @clear="value.stateId = options.multiple ? [] : null; $emit('change', value)"
      @change="$emit('change', value)"
    >
      <el-option
        v-for="item in states"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      >
        <span style="float: left">{{ item.name }}</span>
        <span style="float: right; color: #8492a6; font-size: 13px">ID: {{ item.id }}</span>
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'editor-remote-list',
  props: {
    value: {
      type: Object
    },
    options: {
      type: Object
    }
  },
  data () {
    return {
      states: []
    }
  },
  async mounted () {
    if (this.value.stateId) {
      this.value.stateId = Number(this.value.stateId)
    }
    if (this.$attrs.otherProperties.registryId) {
      this.states = await this.getRegistryStates(this.$attrs.otherProperties.registryId)
    }
  },
  methods: {
    async getRegistryStates (registryId) {
      let logicData = await this.$http.get(`${this.$config.api}/v2/logiceditor/logic?entity_id=${registryId}`)
      let logicId = (logicData.data[0] || {}).id
      if (logicId) {
        let data = await this.$http.get(`${this.$config.api}/v2/logiceditor/states?logic_id=${logicId}`)
        return data.data
      }
      return []
    }
  }
}
</script>

<style scoped>

</style>
