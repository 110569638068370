import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    THEME_LOADING_EVENT = 'theme_loading_event',
    THEME_LOADED_EVENT = 'theme_loaded_event',
    THEME_CREATED_EVENT = 'theme_created_event',
    THEME_UPDATED_EVENT = 'theme_updated_event',
    THEME_DELETED_EVENT = 'theme_deleted_event'
}

export class ThemeLoadingEvent implements FluxStandardAction {
  type = MutationTypes.THEME_LOADING_EVENT
}

export class ThemeLoadedEvent implements FluxStandardAction {
  type = MutationTypes.THEME_LOADED_EVENT
}

export class ThemeCreatedEvent implements FluxStandardAction {
  type = MutationTypes.THEME_CREATED_EVENT
  constructor (public location: string) {}
}

export class ThemeUpdatedEvent implements FluxStandardAction {
  type = MutationTypes.THEME_UPDATED_EVENT
  constructor (public location: string) {}
}

export class ThemeDeletedEvent implements FluxStandardAction {
  type = MutationTypes.THEME_DELETED_EVENT
  constructor (public guid: string) {}
}
