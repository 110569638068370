import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    RULE_TREE_ELEMENT_LOADING_EVENT = 'rule_tree_element_loading_event',
    RULE_TREE_ELEMENT_LOADED_EVENT = 'rule_tree_element_loaded_event'
}

export class RuleTreeElementLoadingEvent implements FluxStandardAction {
  type = MutationTypes.RULE_TREE_ELEMENT_LOADING_EVENT
}

export class RuleTreeElementLoadedEvent implements FluxStandardAction {
  type = MutationTypes.RULE_TREE_ELEMENT_LOADED_EVENT
}
