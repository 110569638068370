import { ActionTree } from 'vuex'
import { RegionState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, RegionsLoadingEvent, RegionsLoadedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { RegionAPI } from '@/services/AddressService/infrastructure/api/RegionAPI'

export const actions: ActionTree<RegionState, any> = {
  [ActionTypes.GET_REGIONS]: async ({ commit, dispatch }, data) => {
    try {
      commit(new RegionsLoadingEvent())
      let res = await APIClient.shared.request(new RegionAPI.GetRegions())
      commit(new RegionsLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.UPDATE_FROM_FIAS]: async ({ commit, dispatch }, data) => {
    try {
      commit(new RegionsLoadingEvent())
      let res = await APIClient.shared.request(new RegionAPI.UpdateFromFIAS())
      commit(new RegionsLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.UPDATE_REGION]: async ({ commit, dispatch }, data) => {
    await APIClient.shared.request(new RegionAPI.UpdateRegion(data.payload))
  }
}
