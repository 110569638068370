import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    EXTRACTOR_LOADING_EVENT = 'extractor_loading_event',
    EXTRACTOR_LOADED_EVENT = 'extractor_loaded_event',
    EXTRACTOR_CREATED_EVENT = 'extractor_created_event',
    EXTRACTOR_UPDATED_EVENT = 'extractor_updated_event',
    EXTRACTOR_DELETED_EVENT = 'extractor_deleted_event'
}

export class ExtractorLoadingEvent implements FluxStandardAction {
  type = MutationTypes.EXTRACTOR_LOADING_EVENT
}

export class ExtractorLoadedEvent implements FluxStandardAction {
  type = MutationTypes.EXTRACTOR_LOADED_EVENT
}

export class ExtractorCreatedEvent implements FluxStandardAction {
  type = MutationTypes.EXTRACTOR_CREATED_EVENT
  constructor (public location: string) {}
}

export class ExtractorUpdatedEvent implements FluxStandardAction {
  type = MutationTypes.EXTRACTOR_UPDATED_EVENT
  constructor (public location: string) {}
}

export class ExtractorDeletedEvent implements FluxStandardAction {
  type = MutationTypes.EXTRACTOR_DELETED_EVENT
  constructor (public guid: string) {}
}
