export interface RequestState {
    isLoading: boolean;
    error: object;
  }

export const initialState = (): RequestState => {
  return {
    isLoading: false,
    error: null
  }
}
