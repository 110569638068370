import { FluxStandardAction } from '@/core/domain/type/types'
import RegionDTO from '@/services/AddressService/domain/model/Region'

export enum ActionTypes {
  GET_REGIONS = 'get_regions',
  UPDATE_REGION = 'update_region',
  UPDATE_FROM_FIAS = 'update_from_fias'
}

export class GetRegions implements FluxStandardAction {
  type = 'Region/' + ActionTypes.GET_REGIONS
  constructor (public payload: string[][]) {}
}

export class UpdateRegion implements FluxStandardAction {
  type = 'Region/' + ActionTypes.UPDATE_REGION
  constructor (public payload: RegionDTO) {}
}

export class UpdateFromFIAS implements FluxStandardAction {
  type = 'Region/' + ActionTypes.UPDATE_FROM_FIAS
  constructor () {}
}
