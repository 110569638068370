import ProjectTreeElementCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/ProjectTreeElementCommandRepositoryInterface'
import { ProjectTreeElementDTO } from '@/services/DigitalTwinEditor/domain/model/Project/ProjectTreeElement'
import store from '@/store'
import { AddProjectTreeLayerElement, AddProjectTreeOpenWorldElement, AddProjectTreeProjectElement, AddProjectTreeGroupElement, PutProjectTreeModelElement, DeleteProjectTreeElement } from '@/services/DigitalTwinEditor/infrastructure/store/modules/ProjectTreeElement/action-types'

export default class ProjectTreeElementCommandRepository implements ProjectTreeElementCommandRepositoryInterface
{
    insertLayer(item: ProjectTreeElementDTO): Promise<void>
    {
        return store.dispatch(new AddProjectTreeLayerElement(item));
    }

    insertOpenWorld(item: ProjectTreeElementDTO): Promise<void>
    {
        return store.dispatch(new AddProjectTreeOpenWorldElement(item));
    }

    insertProject(item: ProjectTreeElementDTO): Promise<void>
    {
        return store.dispatch(new AddProjectTreeProjectElement(item));
    }

    insertGroup(item: ProjectTreeElementDTO): Promise<void>
    {
        return store.dispatch(new AddProjectTreeGroupElement(item));
    }

    putModel(item: ProjectTreeElementDTO): Promise<void>
    {
        return store.dispatch(new PutProjectTreeModelElement(item));
    }

    delete(guid: string): Promise<void>
    {
        return store.dispatch(new DeleteProjectTreeElement(guid));
    }
}
