import StructureRepository from '@/services/RegistryTable/infrastructure/domain/repository/StructureRepository'
import ExtendedObjectColumnsRepository
  from '@/components/InterfaceEditor/components/editor/Columns/repository/ExtendedObjectColumnsRepository'

export default class ColumnsRepository {
  private readonly entityId: number;
  private readonly type: string;
  private registryStructureRepository: StructureRepository;
  private extendedObjectColumnsRepository: ExtendedObjectColumnsRepository

  constructor (type: string, entityId: number) {
    this.type = type
    this.entityId = entityId

    this.registryStructureRepository = new StructureRepository()
    this.extendedObjectColumnsRepository = new ExtendedObjectColumnsRepository()
  }

  async get () {
    let columns = []
    switch (this.type) {
      case 'registry':
        columns = await this.getRegistryColumns(this.entityId)
        break
      case 'extended_object':
        columns = await this.getExtendedObjectColumns(this.entityId)
        break
    }

    return columns
  }

  private async getRegistryColumns (registryId: number): Promise<[]> {
    const { columns } = await this.registryStructureRepository.getById(registryId)

    return columns
  }

  private async getExtendedObjectColumns (extendedObjectId: number): Promise<[]> {
    const columns = await this.extendedObjectColumnsRepository.getById(extendedObjectId)

    return columns
  }
}
