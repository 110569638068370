import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    ADDRESSES_LOADING_EVENT = 'addresses_loading_event',
    ADDRESSES_LOADED_EVENT = 'addresses_loaded_event'
}

export class AddressesLoadingEvent implements FluxStandardAction {
    type = MutationTypes.ADDRESSES_LOADING_EVENT
}

export class AddressesLoadedEvent implements FluxStandardAction {
    type = MutationTypes.ADDRESSES_LOADED_EVENT
}
