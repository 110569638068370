import ModelGroupCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/ModelGroupCommandRepositoryInterface'
import { ModelGroupDTO } from '@/services/DigitalTwinEditor/domain/model/Model/ModelGroup'
import store from '@/store'
import { UpdateModelGroup } from '@/services/DigitalTwinEditor/infrastructure/store/modules/ModelGroup/action-types'

export default class ModelGroupCommandRepository implements ModelGroupCommandRepositoryInterface
{
    update(item: ModelGroupDTO): Promise<void>
    {
        return store.dispatch(new UpdateModelGroup(item));
    }
}
