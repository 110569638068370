<template>
  <div class="properties_panel">
    <el-scrollbar :style="{height:'100%'}" wrap-style="overflow-x:hidden;">
      <div class="header">{{ elementName }}</div>
      <div class="property">
        <div class="label">
          {{ $t('process_editor.element_properties.id') }}
        </div>
        <el-input readonly size="mini" :value="properties.id"/>
      </div>
      <div v-loading="loading">
        <component
          v-if="this.editor"
          :is="this.editor"
          :attributes="properties.attributes"
          :extensions="properties.extensions"
          :element="activeElement"
          @change-extension="changeProperty('extensions', $event.name, $event.value)"
          @change-attribute="changeProperty('attributes', $event.name, $event.value)"
          @remove-extension="removeProperty('extensions', $event.filter)"
          ></component>
        <el-alert v-if="error" type="error" :title="error || ''" :closable="false"/>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import PropertiesService from '@/services/BPMNEditor/infrastructure/service/PropertiesService'

export default {
  name: 'PropertiesPanel',
  props: {
    activeElement: Object
  },
  inject: ['getModeler'],
  data () {
    return {
      properties: {},
      editor: null,
      error: null,
      loading: false
    }
  },
  watch: {
    activeElement: {
      handler (element) {
        if (element && Object.keys(element).length > 0 && element.type) {
          this.properties = PropertiesService.parse(element)
        } else {
          this.properties = {}
        }
      },
      immediate: true
    },
    'properties.id': {
      handler () {
        this.loading = true
        this.editor = () => import(`./PropertyEditors/${this.properties.type}Editor`)
          .then((_) => {
            this.error = null
            return _
          })
          .catch(() => {
            console.error(this.$t('process_editor.editor_not_found'), this.properties.type)
            this.error = this.$t('process_editor.editor_not_found')
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  },
  computed: {
    elementName () {
      let elementName = this.snakeCaseElementType
      return this.$te(`process_editor.elements.${elementName}`) ? this.$t(`process_editor.elements.${elementName}`) : elementName
    },
    snakeCaseElementType () {
      let elementName = this.toSnakeCase(this.properties?.type || '')
      if (elementName[0] === '_') {
        elementName = elementName.substring(1)
      }

      return elementName
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getModeler().on('elements.changed', (event) => {
        (event.elements || []).forEach((element) => {
          if (element === this.activeElement) {
            this.properties = PropertiesService.parse(element)
          }
        })
      })
    })
  },
  methods: {
    changeProperty (
      type, attribute, value
    ) {
      PropertiesService.change(
        this.getModeler(),
        this.activeElement,
        type,
        attribute,
        value
      )
    },
    removeProperty (
      type, filter
    ) {
      PropertiesService.remove(
        this.getModeler(),
        this.activeElement,
        type,
        filter
      )
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../main.scss";
</style>
