import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import TaskQueryRepositoryInterface from '@/services/Databus/domain/repository/TaskQueryRepositoryInterface'
import store from '@/store'
import { GetTasks } from '@/services/Databus/infrastructure/store/modules/DatabusTask/action-types'

export default class TaskQueryRepository extends AbstractQueryRepository implements TaskQueryRepositoryInterface
{
    get(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new GetTasks(payload));
    }
}