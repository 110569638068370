export interface TransformerState {
  isLoading: boolean
  location: string
  deletedTransformerGuid: string
  error: object
}

export const initialState = (): TransformerState => {
  return {
    isLoading: false,
    location: null,
    deletedTransformerGuid: null,
    error: null
  }
}
