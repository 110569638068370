import store from '@/store'
import RegionDTO from '@/services/AddressService/domain/model/Region'
import { UpdateFromFIAS, UpdateRegion } from '@/services/AddressService/infrastructure/store/modules/Region/action-types'
import RegionCommandRepositoryInterface
  from '@/services/AddressService/domain/repository/RegionCommandRepositoryInterface'

export default class RegionCommandRepository implements RegionCommandRepositoryInterface {
  update (item: RegionDTO): Promise<void> {
    return store.dispatch(new UpdateRegion(item))
  }

  updateFromFIAS (): Promise<void> {
    return store.dispatch(new UpdateFromFIAS())
  }
}
