import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { PackageDTO } from '@/services/Databus/domain/model/Package/Package'

export enum ActionTypes {
    GET_PACKAGES = 'get_packages',
    COUNT_PACKAGES = 'count_packages',
    DOWNLOAD_PACKAGE = 'download_package'
}

export class GetPackages implements FluxStandardAction {
    type = 'Package/' + ActionTypes.GET_PACKAGES
    constructor(public payload: object){}
}

export class CountPackages implements FluxStandardAction {
    type = 'Package/' + ActionTypes.COUNT_PACKAGES
    constructor(public payload: object){}
}

export class DownloadPackage implements FluxStandardAction {
    type = 'Package/' + ActionTypes.DOWNLOAD_PACKAGE
    constructor(public topic: string, public guid: string, public fileName: string){}
}