import { ProjectTreeElementDTO } from '@/services/MapEditor/domain/model/Project/ProjectTreeElement'

export interface ProjectTreeElementState {
  isLoading: boolean;
  location: string;
  deletedElementGuid: string;
  error: object
}

export const initialState = (): ProjectTreeElementState => {
  return {
    isLoading: false,
    location: null,
    deletedElementGuid: null,
    error: null
  }
}