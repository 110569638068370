export interface PluginState {
  isLoading: boolean
  location: string
  deletedPluginGuid: string
  error: object
}

export const initialState = (): PluginState => {
  return {
    isLoading: false,
    location: null,
    deletedPluginGuid: null,
    error: null
  }
}
