export default class ModelInstanceUpdateCommand
{
    private guid: string;
    private position: Array<number>;
    private rotation: Array<number>;

    constructor(guid: string, position: Array<number>, rotation: Array<number>)
    {
        this.guid = guid;
        this.position = position;
        this.rotation = rotation;
    }

    getClassName(): string
    {
        return 'ModelInstanceUpdateCommand';
    }

    getGuid(): string
    {
        return this.guid;
    }
    
    getPosition(): Array<number>
    {
        return this.position;
    }

    getRotation(): Array<number>
    {
        return this.rotation;
    } 
}