import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import OpenWorldCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/OpenWorldCommandRepositoryInterface'
import OpenWorldUpdateCommand from '@/services/DigitalTwinEditor/application/command/OpenWorldUpdateCommand'
import { OpenWorldDTO } from '@/services/DigitalTwinEditor/domain/model/OpenWorld/OpenWorld'

export default class OpenWorldUpdateHandler implements CommandHandlerInterface
{
    private repo: OpenWorldCommandRepositoryInterface;

    constructor(repo: OpenWorldCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: OpenWorldUpdateCommand): Promise<void>
    {
        let item: OpenWorldDTO = {
            guid: command.getGuid(),
            name: command.getName(),
            description: command.getDescription(),
            alias: command.getAlias(),
            heightmap: command.getHeightmap(),
            coordinate_system_id: command.getCoordinateSystem(),
            start_point: command.getStartPoint(),
            is_auto_borders: command.getAutoBorders(),
            borders_bbox: command.getBordersBbox(),
            radius_of_buffer: command.getRadiusOfBuffer(),
            parent_id: command.getParentId()
        };
        return this.repo.update(item);
    }
}