export default class SourceUpdateCommand
{
    private guid: string;
    private name: string;
    private type: string;
    private properties: object;

    constructor(guid: string, name: string, type: string, properties: object)
    {
        this.guid = guid;
        this.name = name;
        this.type = type;
        this.properties = properties;
    }

    getGuid(): string
    {
        return this.guid;
    }

    getName(): string
    {
        return this.name;
    }

    getType(): string
    {
        return this.type;
    }

    getProperties(): object
    {
        return this.properties;
    }

    getClassName(): string
    {
        return 'SourceUpdateCommand';
    }    
}