import { MutationTree } from 'vuex'
import { SettingState, initialState } from './state'
import { MutationTypes, SettingCreatedEvent, SettingUpdatedEvent, SettingDeletedEvent } from './mutation-types'

export const mutations: MutationTree<SettingState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false;
    state.error = error;    
  },
  [MutationTypes.SETTING_LOADING_EVENT]: (state) => {
    state.isLoading = true;
  },
  [MutationTypes.SETTING_LOADED_EVENT]: (state) => {
    state.isLoading = false;
  },
  [MutationTypes.SETTING_CREATED_EVENT]: (state, action: SettingCreatedEvent) => {
    state.location = action.location;
    state.isLoading = false;
  },
  [MutationTypes.SETTING_UPDATED_EVENT]: (state, action: SettingUpdatedEvent) => {
    state.isLoading = false;
  },
  [MutationTypes.SETTING_DELETED_EVENT]: (state, action: SettingDeletedEvent) => {
    state.isLoading = false;
  }
}