<template>
  <div class="split-container">
    <Split>
      <SplitArea :size="50">
        <div class="header-buttons-pane">
          <el-button size="mini" @click="addTemplate">Создать шаблон стиля</el-button>
        </div>
        <el-scrollbar :style="{height:'calc(100% - 64px)'}">
          <div
            v-for="item in templates"
            :key="item.guid"
            :class="{ 'template-item': true, 'selected-template': (selectedTemplate && selectedTemplate.guid === item.guid) }"
            @click="selectTemplate(item)"
          >
            <div class="template-name">
              {{item.name}}
            </div>
            <div v-if="selectedTemplate && selectedTemplate.guid === item.guid" class="template-buttons">
              <el-popover
                placement="top"
                v-model="deletePopoverVisible"
              >
                <p>Вы уверены что хотите удалить шаблон "{{selectedTemplate.name}}"</p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="deletePopoverVisible = false">Отменить</el-button>
                  <el-button size="mini" type="danger" @click="deleteTemplate">Удалить</el-button>
                </div>
                <el-button type="danger" plain size="mini" icon="el-icon-delete" slot="reference"></el-button>
              </el-popover>
            </div>
          </div>
        </el-scrollbar>
      </SplitArea>
      <SplitArea :size="50">
        <div v-if="selectedTemplate" style="height: 100%">
          <el-scrollbar :style="{height:'calc(100% - 29px)'}">
            <div class="template-settings-item">
              <div class="template-settings-item-label">
                Наименование
              </div>
              <div class="template-settings-item-value">
                <el-input v-model="selectedTemplate.name"></el-input>
              </div>
            </div>
            <div class="template-settings-item">
              <div class="template-settings-item-label">
                <el-checkbox style="margin: 0 5px;" v-model="selectedTemplate.is_active"></el-checkbox> Активный
              </div>
            </div>
            <div v-if="selectedTemplate.source" class="template-settings-item">
              <div class="template-settings-item-label">
                Тип источника
              </div>
              <div class="template-settings-item-value">
                <el-select
                  v-model="selectedTemplate.source.type"
                  :key="`${selectedTemplate.guid}_source`"
                  disabled
                >
                  <el-option
                    v-for="option in sourceTypeOptions"
                    :key="option.id"
                    :label="option.label"
                    :value="option.id"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div v-if="selectedTemplate.source.type === 'registry'" class="template-settings-item">
              <div class="template-settings-item-label">
                Реестр
              </div>
              <div class="template-settings-item-value">
                <registry-select-tree
                  v-model="selectedTemplate.source.registryId"
                  type="registry"
                  value-as="number"
                  :key="`${selectedTemplate.guid}_registry`"
                  @change="prepareFiltersConfig"
                >
                </registry-select-tree>
              </div>
            </div>
            <div v-if="selectedTemplate.source.type === 'registry' && selectedTemplate.source.registryId && fields.length > 0" class="template-settings-item">
              <div class="template-settings-item-label">
                Условие
              </div>
              <div class="template-settings-item-value">
                <query-builder
                  ref="queryBuilder"
                  v-model="selectedTemplate.condition.rule"
                  :key="`${selectedTemplate.guid}`"
                  :field-type-to-operation-type="stateOperationTypeEntityTypes"
                  :operation-types="operationTypes"
                  :fields="fields"
                  :components="dashboardComponents"
                  @changeScript="scriptChanged"
                  @changeQuery="queryChanged"
                ></query-builder>
              </div>
            </div>
            <div class="template-settings-item">
              <div class="template-settings-item-label">
                Стили
              </div>
              <div
                class="template-settings-item-value"
                v-for="item in ['point', 'linestring', 'polygon', 'label']"
              >
                <style-select
                  :value="selectedTemplate[`style_${item}_id`]"
                  :type="item"
                  :key="`${selectedTemplate.guid}_${item}`"
                  @edit-style="styleEdit"
                  @create-style="styleCreate"
                  @change="selectedTemplate[`style_${item}_id`] = parseInt($event)"
                ></style-select>
              </div>
            </div>
          </el-scrollbar>
          <div class="template-settings-footer-panel">
            <el-button size="mini" type="primary" plain @click="saveTemplate" style="float: right;">Сохранить</el-button>
          </div>
        </div>
      </SplitArea>
    </Split>
  </div>
</template>

<script>
  import { APIClient } from '@/core/infrastructure/api/APIClient'
  import { MapEditorAPI } from '@/core/infrastructure/api/modules/MapEditorAPI'
  import RegistrySelectTree from '@/components/Common/RegistrySelectTree'
  import { ObjectEditorAPI } from '@/core/infrastructure/api/modules/ObjectEditorAPI'
  import { StateAPI } from '@/services/LogicEditor/infrastructure/api/StateAPI'
  import QueryBuilder from '@/components/Common/QueryBuilder/index.vue'
  import StyleSelect from '@/core/infrastructure/components/StyleSelect'

  const SOURCE_TYPES = [
    { id: 'registry', label: 'Реестр' }
  ]

  export default {
    name: 'StyleTemplateEditor',
    components: { RegistrySelectTree, QueryBuilder, StyleSelect },
    inject: ['getComponents'],
    data () {
      return {
        templates: [],
        selectedTemplate: null,
        sourceTypeOptions: SOURCE_TYPES,
        stateOperationTypeEntityTypes: [],
        operationTypes: [],
        fields: [],
        dashboardComponents: this.getComponents(),
        deletePopoverVisible: false
      }
    },
    watch: {
      selectedTemplate: {
        handler: function (newValue, oldValue) {
          //this.prepareFiltersConfig()
          if (newValue && newValue.source && newValue.source.registryId) {
            if (!oldValue
              || !oldValue.source
              || !oldValue.source.registryId
              || (newValue.source.registryId && oldValue.source.registryId !== newValue.source.registryId)) {
              console.log('вот так будет', newValue.source.registryId)
              this.prepareFiltersConfig(newValue.source.registryId)
            }
          }
        },
        deep: true
      }
    },
    mounted () {
      this.loadTemplates()
    },
    methods: {
      addTemplate() {
        let maxId = 0
        this.templates.forEach(x => maxId = x.id > maxId ? x.id : maxId)
        const me = this
        APIClient.shared.request(
          new MapEditorAPI.AddStyleTemplate(
            {
              name: 'Новый шаблон ' + ++maxId,
              source: '{\"type\": \"registry\", \"registryId\": null}',
              condition: '{\"query\": {}, \"script\": {}, \"rule\": { \"logical_operator\": \"and\",\"children\": []}}',
              is_active: true
            }
          )
        ).then(r => {
          me.loadTemplates()
          me.$emit('created-template')
        })
      },
      styleEdit(style, type) {
        this.$emit('edit-style', style, type)
      },
      styleCreate(styleType) {
        this.$emit('create-style', styleType)
      },
      async deleteTemplate () {
        const me = this
        const templateForDeleteName = this.selectedTemplate.name
        APIClient.shared.request(
          new MapEditorAPI.DeleteStyleTemplate(this.selectedTemplate.guid)
        ).then(r => {
          //me.loadGroups()
          me.templates.splice(me.templates.findIndex(x=>x.guid===me.selectedTemplate.guid), 1)
          me.$message.success(`Шаблон стилей "${templateForDeleteName}" успешно удален`)
          me.$emit('deleted-template')
        })
      },
      async saveTemplate () {
        const me = this
        await APIClient.shared.request(
          new MapEditorAPI.UpdateStyleTemplateGroup(
            this.selectedTemplate.guid,
            {
              guid: this.selectedTemplate.guid,
              name: this.selectedTemplate.name,
              condition: JSON.stringify(this.selectedTemplate.condition),
              source: JSON.stringify(this.selectedTemplate.source),
              is_active: this.selectedTemplate.is_active,
              style_point_id: this.selectedTemplate.style_point_id,
              style_linestring_id: this.selectedTemplate.style_linestring_id,
              style_polygon_id: this.selectedTemplate.style_polygon_id,
              style_label_id: this.selectedTemplate.style_label_id
            }
          )
        ).then(function (r) {
          me.templates.splice(me.templates.findIndex(y=>y.guid === me.selectedTemplate.guid), 1, me.selectedTemplate)
          me.$emit('edited-template')
        })
      },
      async loadTemplates () {
        const me = this
        await APIClient.shared.request(
          new MapEditorAPI.GetStyleTemplates()
        ).then(function (result) {
          me.templates = result.map(
            function (x) {
              return {
                id: x.id,
                guid: x.guid,
                name: x.name,
                source: JSON.parse(x.source),
                condition: JSON.parse(x.condition),
                is_active: x.is_active,
                style_point_id: x.style_point_id,
                style_linestring_id: x.style_linestring_id,
                style_polygon_id: x.style_polygon_id,
                style_label_id: x.style_label_id
              }
            }
          )
        })
      },
      selectTemplate(item) {
        this.selectedTemplate = JSON.parse(JSON.stringify(item))
        //this.selectedTemplate.name = '' +
      },
      //component complex filter settings
      async prepareFiltersConfig (entityId) {
        this.isLoading = true
        this.operationTypes = await APIClient.shared.request(
          new StateAPI.GetStateOperationTypes()
        )
        this.stateOperationTypeEntityTypes = await APIClient.shared.request(
          new StateAPI.GetStateOperationTypeEntityTypes()
        )
        const fields = await this.getEntityFields(entityId)
        this.fields = this.buildFieldList(fields.data)
        this.isLoading = false
      },
      scriptChanged (value) {
        this.selectedTemplate.condition.script = value
      },
      queryChanged (value) {
        this.selectedTemplate.condition.query = value
      },
      async getEntityFields (entityId) {
        let fields = await APIClient.shared.request(
          new ObjectEditorAPI.GetEntities({
            'parent_id': entityId,
            'show_children': true,
            'properties[is_visible_in_grid]': true
          })
        )
        return fields
      },
      buildFieldList (fields) {
        let fieldList = []
        fields.forEach((field) => {
          if ([
            'string_field', 'text_field', 'integer_field',
            'float_field', 'date_field', 'time_field',
            'datetime_field', 'boolean_field', 'xref_field',
            'xref_multi_field', 'field_group'
          ].indexOf(field.entity_type_id) !== -1) {
            let alias = `attr_${field.id}_`
            if (field.entity_type_id === 'xref_field') {
              alias += 'id'
            }
            let qbField = {
              id: field.id,
              alias: alias,
              objectId: field.object_id,
              name: field.name,
              fieldTypeId: field.entity_type_id,
              children: []
            }

            if (field.entity_type_id === 'field_group' && field.children.length) {
              qbField.children.push(...this.buildFieldList(field.children))
            }

            fieldList.push(qbField)
          }
        })
        return fieldList
      }
    }
  }
</script>

<style scoped>
  .header-buttons-pane {
    margin: 0 0 20px 0;
  }
  .template-buttons {
    float: right;
  }
  .template-buttons /deep/ .el-button {
    margin-left: 4px;
    padding: 2px;
  }
  .template-settings-footer-panel {
    margin: 0 10px;
  }
  .template-settings-item {
    padding: 7px 15px;
  }
  .template-settings-item-label {
    margin-bottom: 5px;
  }
  .template-settings-item-value {}
  .template-name {
    float: left;
  }
  .template-item:hover {
    background-color: #F5F7FA;
    cursor: pointer;
  }
  .template-item {
    display: inline-block;
    width: calc(100% - 20px);
    margin: 0px 0px;
    padding: 6px;
  }
  .selected-template {
    background-color: #d5e0ed;;
  }
  .split-container /deep/ .el-scrollbar__wrap {
    overflow-y: scroll;
    overflow-x: visible;
  }
  .split-container /deep/ .split {
    overflow-y: hidden;
    overflow-x: hidden;
  }
</style>
