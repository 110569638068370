<template>
  <div>
    <el-select v-model="localValue" filterable clearable @change="$emit('input', localValue)">
      <el-option
        v-for="item in states"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      >
        <span style="float: left">{{ item.name }}</span>
        <span style="float: right; color: #8492a6; font-size: 13px">ID: {{ item.id }}</span>
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'editor-states',
  props: {
    value: {
      type: Number
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      localValue: this.value,
      states: []
    }
  },
  watch: {
    value: {
      handler (value) {
        this.localValue = value
      }
    },
    options: {
      async handler () {
        if (this.options.registryId) {
          this.states = await this.getRegistryStates(this.options.registryId)
        }
      },
      deep: true
    }
  },
  async mounted () {
    if (this.options.registryId) {
      this.states = await this.getRegistryStates(this.options.registryId)
    }
  },
  methods: {
    async getRegistryStates (registryId) {
      const logicData = await this.$http.get(`${this.$config.api}/v2/logiceditor/logic?entity_id=${registryId}`)
      const logicId = (logicData.data[0] || {}).id
      if (logicId) {
        const data = await this.$http.get(`${this.$config.api}/v2/logiceditor/states?logic_id=${logicId}`)
        return data.data
      }
      return []
    }
  }
}
</script>

<style scoped>

</style>
