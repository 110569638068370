<template>
  <div>
    <template v-if="isShowLabel">
      <el-tooltip v-if="_tooltip" :open-delay="300" class="item" effect="dark" :content="_tooltip" placement="top">
        <span
          class="property_label"
          style="display: inline-block;"
        >
          {{ _title || $t('interface_editor.component_editor.filters.filter') }}
        </span>
      </el-tooltip>

      <span
        v-else
        class="property_label"
        style="display: inline-block;"
      >
        {{ _title || $t('interface_editor.component_editor.filters.filter') }}
      </span>
    </template>

    <div style="display: flex; align-items: center;">
      <el-button type="primary" size="mini" style="flex: 5" @click="addFilter">
        {{ $t('interface_editor.component_editor.filters.add_filter') }}
      </el-button>

      <el-tooltip v-if="isShowButtonTooltip" placement="top-end" :open-delay="700" effect="dark">
        <div slot="content" v-html="_tooltip"></div>
        <i class="el-icon-question" style="flex: 1; text-align: center; font-size: 1.2em;"></i>
      </el-tooltip>
    </div>

    <div v-for="(condition, index) in localValue" :key="index" style="margin-top: 10px; margin-bottom: 10px; border-left: 2px solid #E4E7ED; padding-left: 5px">
      <el-row :gutter="5" style="margin-bottom: 3px">
        <!-- Псевдоним атрибута -->
        <el-col :span="isFilter ? 12 : 24">
          <el-tooltip placement="left-end" :open-delay="700" effect="dark">
            <div slot="content">
              <p><b>Псевдоним атрибута (первое поле)</b></p>
              <p>Для Реестра: attr_N_</p>
              <p>Для Расширенной таблицы: some_name</p>
            </div>

            <el-input v-model="condition.alias" placeholder="Псевдоним" size="mini" />
          </el-tooltip>
        </el-col>

        <!-- Равенство -->
        <el-col :span="12" v-if="isFilter">
          <el-tooltip placement="left-end" :open-delay="700" effect="dark">
            <div slot="content">
              <p><b>Режим сравнения полей</b></p>
              <p><b>Равно</b> - точно сравнит поля</p>
              <p><b>Ссылка</b> - сравнит значение/я ссылочного (второго) поля со значением первого (Чекбокс Ссылка аналогичен)</p>
              <p><b>Поиск</b> - сравнение LIKE (на краях значения первого поля встанут %)</p>
            </div>

            <el-select v-model="condition.equalsType" :disabled="!_showEqualsTypes" @change="changeOperator($event, condition)" placeholder="Операторы" size="mini">
              <el-option-group
                v-for="group in _getOperations"
                :key="group.id"
                :label="group.name"
              >
                <el-option
                  v-for="operator in group.children"
                  :key="operator.id"
                  :label="operator.name"
                  :value="operator.id"
                ></el-option>
              </el-option-group>
            </el-select>
          </el-tooltip>
        </el-col>
      </el-row>

      <el-row v-if="!['is_not_null', 'is_null'].includes(condition.equalsType)" :gutter="5">
        <!-- Тип фильтра -->
        <el-col :span="12">
          <el-tooltip placement="top-end" :open-delay="1000" effect="dark">
            <div slot="content">
              <p><b>Тип фильтра</b></p>
              <p><b>Поле</b> - проверяет равенство значений <b>Псевдонима атрибута</b> и выбранного <b>поля</b> карточки</p>
              <p><b>Константа</b> - проверяет равенство значений <b>Псевдонима атрибута</b> и введённой <b>константы</b></p>
              <p><b>Текущий пользователь</b> - проверяет равенство ID пользователя и введённого <b>ID пользователя</b></p>
            </div>

            <el-select v-model="condition.type" placeholder="Тип фильтра" size="mini">
              <el-option
                v-for="(item, index) in _filterTypes"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-tooltip>
        </el-col>

        <!-- Опредлеям значение по которому фильтруем -->
        <el-col :span="12">
          <template v-if="condition.type === 'field'">
            <el-tooltip placement="left-end" :open-delay="700" effect="dark">
              <div slot="content">
                <p><b>Поле карточки</b></p>
                <p>Псевдоним атрибута будет сравнён с значением в поле карточки</p>
              </div>

              <el-select v-model="condition.attribute" filterable size="mini">
                <el-option
                  v-for="(item, index) in components.filter((cmp) => cmp.properties.name)"
                  :key="index"
                  :label="`${item.name} (${item.properties.name})`"
                  :value="item.properties.name"
                ></el-option>
              </el-select>
            </el-tooltip>
          </template>

          <template v-else-if="condition.type === 'registry_field'">
            <el-tooltip placement="left-end" :open-delay="700" effect="dark">
              <div slot="content">
                <p><b>{{ $t('interface_editor.component_editor.filters.registry_field') }}</b></p>
                <p>{{ $t('interface_editor.component_editor.filters.registry_field_tooltip') }}</p>
              </div>

              <el-select v-model="condition.attribute" filterable size="mini">
                <el-option
                  v-for="(item, index) in registryAttrs"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-tooltip>
          </template>

          <template v-else>
            <el-tooltip placement="left-end" :open-delay="700" effect="dark">
              <div slot="content">
                <p><b>Константа</b></p>
                <p>Для сравнения со значением <b>Псевдонима атрибута будет сравнём с константой (строго определённое значение)</b></p>
              </div>

              <el-input v-model="condition.attribute" :disabled="condition.type === 'current_user'" size="mini" />
            </el-tooltip>
          </template>
        </el-col>
      </el-row>

      <el-row :gutter="5">
        <el-col v-if="isFilter" :span="12">
          <el-tooltip placement="left-end" :open-delay="700" effect="dark">
            <div slot="content">
              <p>Если второе поле - ссылка</p>
              <p>Выбор в Списке: значения <b>Ссылка</b> - аналогичен этому чекбоксу</p>
            </div>

            <el-checkbox v-model="condition.isXref" size="mini" :disabled="!_showXrefOption">
              {{ $t('interface_editor.component_editor.filters.xref') }}
            </el-checkbox>
          </el-tooltip>

          <el-checkbox v-if="isImport" v-model="condition.isKey" size="mini">
            {{ $t('etl_editor.form.table.is_key') }}
          </el-checkbox>

          <el-checkbox v-else v-model="condition.isActive">{{ $t('interface_editor.component_editor.filters.included') }}</el-checkbox>

          <!-- Отложенный фильтр -->
          <el-tooltip placement="left-end" :open-delay="700" effect="dark">
            <div slot="content">
              <div>Фильтр не будет срабатываеть "на лету"</div>
              <div>Фильтр сработает после клика на кнопку с действием <b>"Обновлять компоненты"</b></div>
              <div>Фильтр применяться по выбранным в таблице записям по условию <b>"Содержится в"</b></div>
            </div>
          <el-checkbox class="setTimeCheckbox" :disabled="condition.equalsType !== 'in'" v-model="condition.setTimeFilter">{{ $t('interface_editor.component_editor.filters.deferred_filter') }}</el-checkbox>
          </el-tooltip>

        </el-col>

        <!-- Второе поле - ссылка -->
        <el-col :span="isFilter ? 12 : 24">
          <el-button
            @click="deleteFilter(index)"
            style="float: right"
            size="mini"
            type="danger"
            :title="$t('main.button.delete')"
            icon="el-icon-delete"
          ></el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
const MATH_OPERATIONS = [
  {
    id: 'general',
    name: 'Основное',
    children: [
      { id: 'eq', name: 'Равно' },
      { id: 'neq', name: 'Не равно' },
      { id: 'lt', name: 'Меньше' },
      { id: 'gt', name: 'Больше' },
      { id: 'lte', name: 'Меньше или равно' },
      { id: 'gte', name: 'Больше или равно' },
      { id: 'is_null', name: 'Пусто' },
      { id: 'is_not_null', name: 'Не пусто' }
    ]
  },
  {
    id: 'xref',
    name: 'Мн. ссылки',
    children: [
      { id: 'in', name: 'Содержится в' },
      { id: 'not_in', name: 'Не содержится в' }
    ]
  }
]

const DEFAULT_OPERATIONS = [
  {
    id: 'general',
    name: 'Основное',
    children: [
      { id: 'eq', name: 'Равно' },
      { id: 'neq', name: 'Неравно' },
      { id: 'lte', name: 'Меньше либо равно' },
      { id: 'gte', name: 'Больше либо равно' },
      { id: 'lt', name: 'Меньше' },
      { id: 'gt', name: 'Больше' },
      { id: 'between', name: 'В промежутке' },
      { id: 'is_null', name: 'Пусто' },
      { id: 'is_not_null', name: 'Не пусто' },
      { id: 'in', name: 'Содержится в (in)' },
      { id: 'not_in', name: 'Не содержится в (not in)' }
    ]
  },
  {
    id: 'text',
    name: 'Текстовые поля',
    children: [
      { id: 'cs', name: 'Содержит' },
      { id: 'ncs', name: 'Не содержит' },
      { id: 'se', name: 'Искать' }
    ]
  },
  {
    id: 'xref',
    name: 'Мн. ссылки',
    children: [
      { id: 'equals_any', name: 'Равен любому' },
      { id: 'not_equals_any', name: 'Не равен любому' },
      { id: 'equals_all', name: 'Все равны' },
      { id: 'not_equals_all', name: 'Все не равны' }
    ]
  }
]

const FIELD_AND_CONSTANT_FILTER_TYPES = [
  { id: 'field', name: 'поле' },
  { id: 'constant', name: 'константа' }
]

const DEFAULT_FILTER_TYPES = [
  { id: 'field', name: 'поле' },
  { id: 'constant', name: 'константа' },
  { id: 'current_user', name: 'текущий пользователь' },
  { id: 'registry_field', name: 'поле таблицы' }
]

export default {
  name: 'editor-filters',

  inject: ['getComponents'],

  props: {
    value: {
      type: Array,
      default () {
        return []
      }
    },

    options: {
      type: Object,
      default () {
        return {
          title: null,
          showEqualsTypes: true,
          showXrefOption: true
        }
      }
    },

    title: {
      type: String,
      default: null
    },

    type: {
      type: String,
      default: null
    },

    showXrefOption: {
      type: Boolean,
      default: true
    },

    showEqualsTypes: {
      type: Boolean,
      default: true
    },

    tooltip: {
      type: String,
      default: null
    },

    isImport: {
      type: Boolean,
      default: false
    },

    registryAttrs: {
      type: Array,
      default () { return [] }
    },

    // Структура операций
    isOnlyMathOperation: {
      type: Boolean,
      default: false
    },

    // Структура фильтров
    isFieldAndConstant: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    _title () {
      return this.title || (this.options ? this.options.title : '')
    },

    _type () {
      return this.type || (this.options ? this.options.type : null) || 'filter'
    },

    _tooltip () {
      return this.tooltip || (this.options ? this.options.tooltip : null)
    },

    _showXrefOption () {
      return this.options.showXrefOption ? this.options.showXrefOption : this.showXrefOption
    },

    _showEqualsTypes () {
      return this.options.showEqualsTypes ? this.options.showEqualsTypes : this.showEqualsTypes
    },

    isFilter () {
      return this._type === 'filter'
    },

    isShowLabel () {
      if (this.options && 'isShowLabel' in this.options) {
        return this.options.isShowLabel
      }

      return true
    },

    isShowButtonTooltip () {
      if (!this._tooltip) {
        return false
      }

      if (this.options && 'isShowLabel' in this.options) {
        return !this.options.isShowLabel
      }

      return true
    },
    _getOperations () {
      if (this.isOnlyMathOperation) {
        return MATH_OPERATIONS
      }
      return DEFAULT_OPERATIONS
    },
    _filterTypes () {
      if (this.isFieldAndConstant) {
        return FIELD_AND_CONSTANT_FILTER_TYPES
      }
      return DEFAULT_FILTER_TYPES
    }
  },

  watch: {
    value: {
      handler (value) {
        this.localValue = value
      },
      deep: true
    },

    localValue: {
      handler: function (value) {
        this.$emit('change', value)
      },
      deep: true
    }
  },

  data () {
    return {
      localValue: [],
      components: this.getComponents()
    }
  },

  mounted () {
    this.localValue = this.value

    // Fix
    for (const value of this.localValue) {
      if (typeof value.isKey === 'undefined') {
        value.isKey = false
      }

      if (typeof value.isActive === 'undefined') {
        value.isActive = true
      }

      if (typeof value.setTimeFilter === 'undefined') {
        value.setTimeFilter = false
      }
    }
  },

  methods: {
    changeOperator (value, condition) {
      if (['equals_any', 'not_equals_any', 'equals_all', 'not_equals_all'].includes(value)) {
        condition.isXref = true
      }
    },

    addFilter () {
      if (typeof this.value === 'undefined') {
        this.$set(this, 'value', [])
      }
      this.localValue.push({
        attribute: null,
        alias: null,
        type: 'field',
        isXref: false,
        equalsType: 'eq',
        isKey: false,
        isActive: true,
        setTimeFilter: false
      })
    },

    deleteFilter (index) {
      this.localValue.splice(index, 1)
    }
  }
}
</script>
