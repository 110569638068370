import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ProjectTreeElementCommandRepositoryInterface from '@/services/MapEditor/domain/repository/ProjectTreeElementCommandRepositoryInterface'
import ProjectTreeElementProjectCreateCommand from '@/services/MapEditor/application/command/ProjectTreeElementProjectCreateCommand'
import { ProjectTreeElementDTO } from '@/services/MapEditor/domain/model/Project/ProjectTreeElement'

export default class ProjectTreeElementProjectCreateHandler implements CommandHandlerInterface
{
    private repo: ProjectTreeElementCommandRepositoryInterface;

    constructor(repo: ProjectTreeElementCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: ProjectTreeElementProjectCreateCommand): Promise<void>
    {
        let item: ProjectTreeElementDTO = {
            name: command.getName(),
            description: command.getDescription(),
            parent_id: command.getParentId()
        };
        return this.repo.insertProject(item);
    }
}