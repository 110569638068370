import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import NotificationDTO, { INotification } from '@/services/NotificationEditor/domain/model/NotificationDTO'

export namespace NotificationAPI {
  export class GetNotification implements APIRequest<NotificationDTO[]> {
    response: NotificationDTO[];
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse<INotification[]>) => data.data.map(item => new NotificationDTO(item))
    constructor (payload: string[][]) {
      this.path = `/notificationeditor/notifications?${new URLSearchParams(payload).toString()}`
    }
  }

  export class GetNotificationById implements APIRequest<NotificationDTO> {
    response: NotificationDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse<INotification>) => new NotificationDTO(data.data)
    constructor (id: string) {
      this.path = `/notificationeditor/notifications/${id}`
    }
  }

  export class CreateNotification implements APIRequest<NotificationDTO> {
    response: NotificationDTO;
    path = '/notificationeditor/notifications';
    method = HTTPMethod.POST;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.headers.location
    constructor (public params: NotificationDTO) {}
  }

  export class UpdateNotification implements APIRequest<NotificationDTO> {
    response: NotificationDTO;
    path: string;
    method = HTTPMethod.PUT;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.headers.location
    constructor (public params: NotificationDTO) {
      this.path = `/notificationeditor/notifications/${params.id}`
    }
  }

  export class DeleteNotification implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.DELETE;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (id: string) {
      this.path = `/notificationeditor/notifications/${id}`
    }
  }
}
