import QueryInterface from '@/core/application/query/QueryInterface'

export default class CoordinateSystemBySridQuery implements QueryInterface
{
    private srid: number;

    constructor(srid: number)
    {
        this.srid = srid;
    }

    getSrid(): number
    {
        return this.srid;
    }

    getClassName(): string
    {
        return 'CoordinateSystemBySridQuery';
    }
}