import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import LayerCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/LayerCommandRepositoryInterface'
import LayerUpdateCommand from '@/services/DigitalTwinEditor/application/command/LayerUpdateCommand'
import { LayerDTO } from '@/services/DigitalTwinEditor/domain/model/Layer/Layer'

export default class LayerUpdateHandler implements CommandHandlerInterface
{
    private repo: LayerCommandRepositoryInterface;

    constructor(repo: LayerCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: LayerUpdateCommand): Promise<void>
    {
        let item: LayerDTO = {
            guid: command.getGuid(),
            name: command.getName(),
            description: command.getDescription(),
            alias: command.getAlias(),
            parent_id: command.getParentId()
        };
        return this.repo.update(item);
    }
}