<template>
  <div>
    <el-form-item label="URL">
      <el-input type="text" v-model="value.externalURL"></el-input>
    </el-form-item>
    <el-form-item label="Система координат">
      <coordinate-system
        :value="value.nativeCoordinateSystemId"
        @change-cs="value.nativeCoordinateSystemId = $event"
      ></coordinate-system>
    </el-form-item>
    <el-form-item label="Поле с геометрией">
      <el-input type="text" v-model="value.geometryField"></el-input>
    </el-form-item>
  </div>
</template>

<script>
import CoordinateSystem from '@/core/infrastructure/components/CoordinateSystem'

export default {
  name: 'WFS',
  components: { CoordinateSystem },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          externalURL: null
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
