import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { ActivityDTO } from '@/services/ActivityService/domain/model/Activity/Activity'
import { EventDTO } from '@/services/ActivityService/domain/model/Event/Event'

export namespace EventAPI {
    export class GetEvents implements APIRequest<EventDTO> {
        response: EventDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor (payload: string[][]) {
          this.path = '/eventservice/events?' + (new URLSearchParams(payload).toString())
        }
    }

    export class EventsExport implements APIRequest<EventDTO> {
      response: EventDTO;
      path: string;
      method = HTTPMethod.GET;
      parse = (data: AxiosResponse) => data.data
      constructor (payload: string[][]) {
        this.path = '/eventservice/events/export?' + (new URLSearchParams(payload).toString())
      }
    }
}
