import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { DagDTO } from '@/services/Databus/domain/model/Dag/Dag'

export enum ActionTypes {
    GET_DAGS = 'get_dags',
    RUN_DAG = 'run_dag'
}

export class GetDags implements FluxStandardAction {
    type = 'Dag/' + ActionTypes.GET_DAGS
    constructor(public payload: object){}
}

export class RunDag implements FluxStandardAction {
    type = 'Dag/' + ActionTypes.RUN_DAG
    constructor(public payload: DagDTO){}
}