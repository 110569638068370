import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import SourceCommandRepositoryInterface from '@/services/MapEditor/domain/repository/SourceCommandRepositoryInterface'
import SourceUpdateCommand from '@/services/MapEditor/application/command/SourceUpdateCommand'
import { SourceDTO } from '@/services/MapEditor/domain/model/Source/Source'
import RegionCommandRepositoryInterface
  from '@/services/AddressService/domain/repository/RegionCommandRepositoryInterface'
import RegionUpdateCommand from '@/services/AddressService/application/command/RegionUpdateCommand'
import RegionDTO from '@/services/AddressService/domain/model/Region'

export default class RegionUpdateHandler implements CommandHandlerInterface {
  private repository: RegionCommandRepositoryInterface;

  constructor (repository: RegionCommandRepositoryInterface) {
    this.repository = repository
  }

  execute (command: RegionUpdateCommand): Promise<void> {
    let region: RegionDTO = {
      code: command.getCode(),
      is_updatable: command.getIsUpdatable(),
      has_houses: command.getHasHouses(),
      has_address_objects: command.getHasAddressObjects()
    }
    return this.repository.update(region)
  }
}
