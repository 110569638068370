<template>
  <div>
    <el-checkbox
      :label="$t(`interface_editor.component_editor.table.optimize_data_loading`)"
      v-model="localModel.showOptimizeOptions"
      @change="$emit('change', localModel)"
    >
    </el-checkbox>
    <el-input
      v-if="localModel.showOptimizeOptions"
      v-model="localModel.value"
    >
    </el-input>
  </div>
</template>

<script>
export default {
  name: 'editor-optimize-columns-load',
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      localModel: this.value
    }
  }
}
</script>

<style scoped>

</style>
