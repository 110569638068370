
// Компонент: выпадашка с уведомлениями в колокольчике
// MessageNotify - всплывашка
import mixins from 'vue-typed-mixins'
import MessageNotify from '@/components/System/Notification/MessageNotify.vue'
import Messages from '@/components/System/Models/Messages'
import { getCardId } from '@/helpers'
import mixin from '@/components/System/Notification/mixins/index'
import CardsWrapper from '@/components/Registry/CardsWrapper.vue'

export default mixins(mixin).extend({
  name: 'Notification',

  components: {
    MessageNotify
  },

  props: ['addMainTab'],

  data () {
    return {
      key: 1,
      listNotify: [],
      visible: false,
      countNotify: null,
      loading: false
    }
  },
  watch: {
    message: {
      handler: async function (val, oldval) {
        console.log(val)
        try {
          if (val) {
            await new Messages().params({ limit: 1 }).$get()
            this.loadNotifyes(val)
            if (val.shows_popup_message) {
              const h = this.$createElement
              this.$notify({
                message: h(MessageNotify, { key: this.key++, props: { val, addMainTab: this.addMainTab } }),
                position: 'bottom-right',
                duration: 5000
              })
            }
          }
        } catch (error) {
          this.$store.dispatch('WSDisconnect')
          console.log(error, 'not messages')
        }
      }
    },
    getNotify: {
      handler: function (val) {
        let obj = { id: val }
        this.readMessages(null, obj)
      }
    }
  },
  computed: {
    message () {
      return this.$store.getters['message']
    },
    socket () {
      return this.$store.getters['isConnected']
    },
    getNotify () {
      return this.$store.getters['Notify/getNotify']
    }
  },
  methods: {
    // Открыть карточку внешнего реестра
    async openCardOuter (data, dataRegistryByRecord) {
      let { openCard, readOnly, cardId, recordXref } = await this.validationData(data, dataRegistryByRecord)
      if (!openCard) return
      // получить id карточки внешнего реестра
      const card: any = (cardId) ? { id: cardId } : await getCardId(this, { registryId: openCard.external_object_id, recordId: recordXref })
      const dataCard = { registryId: openCard.external_object_id, recordId: recordXref, cardId: card?.id || null, initialData: {}, readOnly }
      this.addMainTab({
        name: card?.name || 'Новая вкладка',
        componentType: 'CardsWrapper',
        payload: {
          openCard: dataCard,
          showBackBreadcrumb: false,
          showCloseCardBtn: true,
          isOpenInNewtab: true,
          showBreadcrumbEmpty: false
        }
      })
      this.loading = false
    },
    async openCard (data) {
      let dataRegistryByRecord = await this.getDataRegistryByRecord({ registryId: data.notification[0].object_id, recordId: data.record_id })
      if (dataRegistryByRecord.is_deleted) {
        this.$message.error(`Запись удалена из системы`)
        return
      }
      if (!data.notification[0].interaction_type) return false
      // Открыть карточку внешнего реестра
      if (data.notification[0].interaction_type === 'open_external_card') {
        this.openCardOuter(data, dataRegistryByRecord)
        return
      }

      this.loading = true
      try {
        let currentCardId = data.notification[0]?.current_card_id || null
        const card: any = (currentCardId) ? { id: currentCardId } : await getCardId(this, { registryId: data.notification[0].object_id, recordId: data.record_id })
        const dataCard = { registryId: data.notification[0].object_id, recordId: data.record_id, cardId: card?.id || null, initialData: {} }
        this.addMainTab({
          name: card?.name || 'Новая вкладка',
          componentType: 'CardsWrapper',
          payload: {
            openCard: dataCard,
            showBackBreadcrumb: false,
            showCloseCardBtn: true,
            isOpenInNewtab: true,
            showBreadcrumbEmpty: false
          }
        })
        this.loading = false
        this.visible = false
      } catch (error) {
        this.loading = false
        this.visible = false
      }
    },
    async loadNotifyes (val) {
      val.create_date = val.create_date.split('.')[0]
      if (val.author[0] && val.author[0].avatar_id) {
        let res = await this.$http.get(`${this.$config.api}/registryservice/files/${val.author[0].avatar_id}`)
        val.avatar = res.data
      }
      this.listNotify.unshift(val)
      let count = await this.$http.post(`${this.$config.api}/${this.$config.notification_publisher}/messages`, { '*': { func: 'count' }, where: { is_null: 'read_date' } }, { hideNotification: true })
      this.countNotify = count.data[0].count
    },
    async loadAvatarUser () {
      try {
        let params = {
          limit: 4,
          offset: 0,
          order: 'create_date:desc'
        }
        let notify = await new Messages().params(params).$get()
        this.listNotify = notify
        let usersWithAvatar = []
        let uniqueUsers = []
        usersWithAvatar = notify.reduce((acc, item) => {
          if (item.author[0] && item.author[0].avatar_id) {
            acc.push({
              author_id: item.author_id,
              avatar_id: item.author[0].avatar_id
            })
          }
          return acc
        }, [])
        // оставить уникальных юзеров
        uniqueUsers = usersWithAvatar.filter((item, index, self) =>
          index === self.findIndex((t) => (
            t.author_id === item.author_id
          ))
        )
        uniqueUsers.forEach(item => {
          this.$http.get(`${this.$config.api}/registryservice/files/${item.avatar_id}`).then((res) => {
            this.$set(item, 'avatar', res.data)
          }).then(() => {
            for (let i = 0; i < this.listNotify.length; i++) {
              for (let j = 0; j < uniqueUsers.length; j++) {
                if (this.listNotify[i].author_id === uniqueUsers[j].author_id) {
                  // this.$set(this.listNotify[i], 'fullName', this.users[j].fullName)
                  this.$set(this.listNotify[i], 'avatar', uniqueUsers[j].avatar)
                }
              }
            }
          }).catch((error) => {
            this.loading = false
            console.log(error)
          })
        })
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    async readMessages (e, val = null) {
      if (val) {
        this.listNotify.find(item => { if (item.id === val.id) item.read_date = true })
        await this.$http.put(`${this.$config.api}/${this.$config.notification_publisher}/messages/read`, { message_id: val.id }, { hideNotification: true })
      } else {
        this.listNotify.forEach(element => { element.read_date = true })
        await this.$http.put(`${this.$config.api}/${this.$config.notification_publisher}/messages/read-all`)
      }
      let count = await this.$http.post(`${this.$config.api}/${this.$config.notification_publisher}/messages`, { '*': { func: 'count' }, where: { is_null: 'read_date' } }, { hideNotification: true })
      this.countNotify = count.data[0].count
    },
    openCenterNotify () {
      this.addMainTab({ name: 'Центр уведомлений', componentType: 'NotificationCenter' })
      this.visible = false
    }
  },
  async created () {
    let count = await this.$http.post(`${this.$config.api}/${this.$config.notification_publisher}/messages`, { '*': { func: 'count' }, where: { is_null: 'read_date' } }, { hideNotification: true })
    this.countNotify = count.data[0].count
    await this.loadAvatarUser()
  }
})
