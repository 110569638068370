import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { RouteDTO } from '@/services/Databus/domain/model/Route/Route'

export enum ActionTypes {
    ADD_ROUTE = 'add_route',
    UPDATE_ROUTE = 'update_route',
    DELETE_ROUTE = 'delete_route',
    GET_ROUTES = 'get_routes',
    COUNT_ROUTES = 'count_routes',
    GET_ROUTE_BY_GUID = 'get_route_by_guid'
}

export class AddRoute implements FluxStandardAction {
    type = 'Route/' + ActionTypes.ADD_ROUTE
    constructor(public payload: RouteDTO){}
}

export class UpdateRoute implements FluxStandardAction {
    type = 'Route/' + ActionTypes.UPDATE_ROUTE
    constructor(public payload: RouteDTO){}
}

export class DeleteRoute implements FluxStandardAction {
    type = 'Route/' + ActionTypes.DELETE_ROUTE
    constructor(public guid: string){}
}

export class GetRoutes implements FluxStandardAction {
    type = 'Route/' + ActionTypes.GET_ROUTES
    constructor(public payload: object){}
}

export class CountRoutes implements FluxStandardAction {
    type = 'Route/' + ActionTypes.COUNT_ROUTES
    constructor(public payload: object){}
}

export class GetRouteByGuid implements FluxStandardAction {
    type = 'Route/' + ActionTypes.GET_ROUTE_BY_GUID
    constructor(public guid: string){}
}