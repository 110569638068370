import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    CSS_CLASS_LOADING_EVENT = 'css_class_loading_event',
    CSS_CLASS_LOADED_EVENT = 'css_class_loaded_event',
    CSS_CLASS_CREATED_EVENT = 'css_class_created_event',
    CSS_CLASS_UPDATED_EVENT = 'css_class_updated_event',
    CSS_CLASS_DELETED_EVENT = 'css_class_deleted_event'
}

export class CssClassLoadingEvent implements FluxStandardAction {
  type = MutationTypes.CSS_CLASS_LOADING_EVENT
}

export class CssClassLoadedEvent implements FluxStandardAction {
  type = MutationTypes.CSS_CLASS_LOADED_EVENT
}

export class CssClassCreatedEvent implements FluxStandardAction {
  type = MutationTypes.CSS_CLASS_CREATED_EVENT
  constructor (public location: string) {}
}

export class CssClassUpdatedEvent implements FluxStandardAction {
  type = MutationTypes.CSS_CLASS_UPDATED_EVENT
  constructor (public location: string) {}
}

export class CssClassDeletedEvent implements FluxStandardAction {
  type = MutationTypes.CSS_CLASS_DELETED_EVENT
  constructor (public guid: string) {}
}
