import AbstractEntityDeleteCommand from './AbstractEntityDeleteCommand'
import CommandHandlerInterface from './CommandHandlerInterface'
import AbstractCommandRepository from '../../domain/repository/AbstractCommandRepository'

export default abstract class AbstractByGuidQueryHandler implements CommandHandlerInterface
{
    private repo: AbstractCommandRepository;

    constructor(repo: AbstractCommandRepository)
    {
        this.repo = repo;
    }

    execute(command: AbstractEntityDeleteCommand): Promise<any>
    {
        return this.repo.delete(command.getGuid(), command.getPayload());
    }
}