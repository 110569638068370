<template>
  <div style="border: 2px dashed #80808059; padding: 5px">
       <!-- <div>Выбранный атрибут</div>
        <el-input size="medium" readonly :value="localModel.value"></el-input> -->
        <!-- <div>{{localModel}}</div> -->
    <div>Атрибут</div>
     <el-form-item>
      <el-checkbox v-model="localModel.defaultString">Ввод вручную</el-checkbox>
      <el-input
        v-show="localModel.defaultString"
        v-model.trim="localModel.value"
        @input="inputString"
      ></el-input>
    </el-form-item>
    <div v-show="!localModel.defaultString">
      <el-form-item label="Источник">
        <el-select
          v-model="localModel.selectSource"
          @change="$set(localModel, 'selectSource', $event)"
           size="medium"
        >
          <el-option
            v-for="(item, index) in sourceTypes"
            :key="index"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="localModel.selectSource === 'registry'" label="Выбрать поле">
        <object-tree :model="localModel" @change="change"></object-tree>
      </el-form-item>
      <template v-if="localModel.selectSource === 'extended_object'">
        <extended-object :model="localModel" @change="change"></extended-object>
      </template>
      <template v-if="localModel.selectSource === 'query'">
        <query-source :model="localModel" @change="change"></query-source>
      </template>
    </div>
  </div>
</template>

<script>
// Выбор источника: Реестр, Расширенная таблица, Запрос
import ObjectTree from '@/components/Common/ObjectTreeWithSearch.vue'
import ExtendedObject from '@/components/Common/ExtendedObject.vue'
import QuerySource from '@/components/Common/QuerySource.vue'

const SOURCE_TYPES = [
  { id: 'registry', name: 'Реестр' },
  { id: 'extended_object', name: 'Расширенная таблица' },
  { id: 'query', name: 'Запрос' }
]
export default {
  name: 'editor-data-source',
  components: {
    ObjectTree,
    ExtendedObject,
    QuerySource
  },
  props: {
    // string - старое значение value
    value: {
      type: [String, Object]
    }
  },
  data () {
    return {
      localModel: {
        defaultString: true,
        selectSource: null,
        extendedObjectId: null,
        queryId: null,
        selectField: '',
        selectExtendedField: {},
        selectQueryField: {},
        value: ''
      }
    }
  },
  computed: {
    sourceTypes () {
      return SOURCE_TYPES
    },
    showSearchTip () {
      return this.search && !this.searchLoading && !this.searchLoaded
    }
  },
  watch: {
    value: {
      handler (value, oldValue) {
        // console.log('watch value editor-data-source', { value }, { oldValue })
        let attrViaTree = { ...this.attrViaTree, ...{ value: value ?? oldValue } }
        // console.log({ attrViaTree })
        this.$emit('change-property', { guid: this.guid, name: 'attrViaTree', value: attrViaTree })
      },
      deep: true
    },
    'localModel.selectSource': {
      handler (value, oldValue) {
        // console.log({oldValue})
        if (value === 'registry') {
          this.localModel.selectExtendedField = {}
          this.localModel.selectQueryField = {}
          this.localModel.extendedObjectId = ''
          this.localModel.queryId = ''
        }
        if (value === 'extended_object') {
          this.localModel.selectField = ''
          this.localModel.selectQueryField = {}
          this.localModel.queryId = ''
        }
        if (value === 'query') {
          this.localModel.selectExtendedField = {}
          this.localModel.extendedObjectId = ''
          this.localModel.selectField = ''
        }
        if (oldValue) {
          // console.log('очищен value')
          this.localModel.value = ''
          this.$emit('change', this.localModel)
        }
        // console.log({ value })
      }
    }
  },
  methods: {
    inputString (e) {
      // console.warn('Посылаю emit change inputString:', this.localModel)
      this.$emit('change', this.localModel)
    },
    change (e) {
      // console.log(this.localModel)
      this.$emit('change', this.localModel)
    }
  },
  mounted () {
    if (this.value?.selectSource) {
      this.localModel = this.value
    } else {
      this.localModel = Object.assign({}, this.localModel, { value: this.value.value })
    }
  }
}
</script>

<style lang="scss"></style>
