import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { RequestTreeDTO } from '@/services/ApiService/domain/model/Request/RequestTree'
import { Request } from '@/services/ApiService/domain/model/Request/Request'
import { RequestParameter } from '@/services/ApiService/domain/model/Request/RequestParameter'

export namespace RequestAPI {
  export class GetRequests implements APIRequest<RequestTreeDTO> {
    response: RequestTreeDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (payload: string[][]) {
      this.path = '/api/requests_tree?' + (new URLSearchParams(payload).toString())
    }
  }
  export class CreateRequest implements APIRequest<RequestTreeDTO> {
    response: RequestTreeDTO;
    path = '/api/requests_tree';
    method = HTTPMethod.POST;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: object) {}
  }
  export class DeleteRequest implements APIRequest<RequestTreeDTO> {
    response: RequestTreeDTO;
    path: string;
    method = HTTPMethod.DELETE;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = '/api/requests_tree/' + guid
    }
  }
  export class GetRequestSchemaEntities implements APIRequest<{}> {
    response: {};
    path = '/api/entities/is_schema';
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
  }
  export class UpdateRequestEntity implements APIRequest<Request> {
    response: Request;
    path: string;
    method = HTTPMethod.PUT;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: Request, entityType: string) {
      this.path = `/api/${entityType}/${params.guid}`
    }
  }
  export class GetRequestParameters implements APIRequest<RequestParameter> {
    response: RequestParameter;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/api/request/${guid}/param`
    }
  }
  export class CreateRequestParameter implements APIRequest<RequestParameter> {
    response: RequestParameter;
    path: string;
    method = HTTPMethod.POST;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: object, public requestGuid: string) {
      this.path = `/api/request/${requestGuid}/param`
    }
  }
  export class DeleteRequestParameter implements APIRequest<RequestParameter> {
    response: RequestParameter;
    path: string;
    method = HTTPMethod.DELETE;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = '/api/request/param/' + guid
    }
  }
  export class UpdateRequestParameter implements APIRequest<RequestParameter> {
    response: RequestParameter;
    path: string;
    method = HTTPMethod.PUT;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: Request) {
      this.path = `/api/request/param/${params.guid}`
    }
  }
  export class ImportStructure implements APIRequest<{}> {
    response: RequestTreeDTO;
    path = '/api/sync/import';
    method = HTTPMethod.POST;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: object) {}
  }
}
