import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import GroupDTO from '@/services/NotificationEditor/domain/model/GroupDTO'

export namespace GroupAPI {
  export class GetGroup implements APIRequest<GroupDTO> {
    response: GroupDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (payload: string[][]) {
      this.path = `/notificationeditor/groups?${new URLSearchParams(payload).toString()}`
    }
  }

  export class GetGroupById implements APIRequest<GroupDTO> {
    response: GroupDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (id: string) {
      this.path = `/notificationeditor/groups/${id}`
    }
  }

  export class CreateGroup implements APIRequest<string> {
    response: string;
    path = '/notificationeditor/groups';
    method = HTTPMethod.POST;
    hideGroup = false;
    parse = (data: AxiosResponse) => data.headers.location
    constructor (public params: GroupDTO) {}
  }

  export class UpdateGroup implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.PUT;
    hideGroup = false;
    parse = (data: AxiosResponse) => data.headers.location
    constructor (public params: GroupDTO) {
      this.path = `/notificationeditor/groups/${params.id}`
    }
  }

  export class DeleteGroup implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.DELETE;
    hideGroup = false;
    parse = (data: AxiosResponse) => data.data
    constructor (id: string) {
      this.path = `/notificationeditor/groups/${id}`
    }
  }
}
