<template>
  <div>
    <el-button @click="addField">
      {{$locale.interface_editor.component_editor.filters.add_filter}}
    </el-button>
    <div class="addition-fields" v-for="(field, index) in value.additionalFields" :key="index">
      <el-row :gutter="5">
        <el-col :span="11">
          <el-input v-model="field.attribute"/>
        </el-col>
        <el-col :span="8">
          <el-select v-model="field.type">
            <el-option
              v-for="(item, index) in $locale.interface_editor.component_editor.editor_tasks_source.additional_fields_operations"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-col>
        <el-col :span="1">
          <el-button @click="removeField(index)"
                     size="mini"
                     type="danger"
                     :title="$locale.main.button.delete">
            <i class="el-icon-delete"/>
          </el-button>
        </el-col>
      </el-row>
      <el-row :gutter="5">
        <el-col>
          <el-checkbox v-model="field.hasCondition" @change="removeCondition(index)">
            {{$locale.interface_editor.component_editor.editor_tasks_source.condition_moving}}
          </el-checkbox>
        </el-col>
      </el-row>
      <div v-if="field.hasCondition">
        <el-row :gutter="5">
          <el-col :span="11">
            <el-select v-model="field.condition.operator">
              <el-option
                v-for="(item, index) in operators"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :span="13">
            <el-input v-model="field.condition.value"/>
          </el-col>
        </el-row>
        <el-row :gutter="5">
          <el-col>
            <el-input v-model="field.condition.textError" placeholder="Текст ошибки"/>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "additional-fields",
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      operators: [
        { id: '===', name: 'Равно' },
        { id: '!==', name: 'Неравно' },
        { id: '<=', name: 'Меньше либо равно' },
        { id: '>=', name: 'Больше либо равно' },
        { id: '<', name: 'Меньше' },
        { id: '>', name: 'Больше' },
      ]
    }
  },
  methods: {
    addField () {
      if (typeof this.value.additionalFields === 'undefined') {
        this.$set(this.value, 'additionalFields', [])
      }
      this.value.additionalFields.push({
        attribute: null,
        type: null,
        hasCondition: false,
        condition: {
          operator: null,
          value: null,
          textError: null
        }
      })
    },
    removeField (index) {
      this.value.additionalFields.splice(index, 1)
    },
    removeCondition (index) {
      this.value.additionalFields[index].condition = {
        operator: null,
        value: null
      }
    }
  }
}
</script>

<style scoped>
  .addition-fields .el-row {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .addition-fields {
    margin-top: 5px;
    padding: 2px;
    border-radius: 3px;
    border: 1px solid #DCDFE6;
  }
</style>
