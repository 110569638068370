import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'
import NameTooLong from '@/core/domain/exception/NameTooLong'
import NameMustNotBeEmpty from '@/core/domain/exception/NameMustNotBeEmpty'
import TreeElementType from '../TreeElementType'

export class OpenWorldDTO
{
    id?: Nullable<number>;
    guid?: Nullable<string>;
    name?: string;
    description?: string;
    alias?: string;
    heightmap?: string = 'fabdem';
    coordinate_system_id?: Nullable<number> = 4326;
    start_point?: Array<number>;    
    borders_bbox?: Array<any>;
    is_auto_borders?: boolean = true;
    buffer_geojson?: Array<any>;
    radius_of_buffer?: Nullable<number> = 500;
    command?: string;
    min_zoom?: Nullable<number>;
    max_zoom?: Nullable<number>;
    force_tiling?: boolean = false;
    apply_new_styles?: boolean = false;
    num_threads?: Nullable<number>;
    style_id?: Nullable<string>;
    value?: Nullable<string>;
    parent_id?: Nullable<number>;

    constructor({ id, guid, name, description, alias, heightmap, coordinate_system_id, start_point, borders_bbox, is_auto_borders, buffer_geojson, radius_of_buffer, command, min_zoom, max_zoom, force_tiling, apply_new_styles, num_threads, style_id, value, parent_id } : { id?: Nullable<number>, guid?: Nullable<string>, name?: string, description?: string, alias?: string, heightmap?: string, coordinate_system_id?: Nullable<number>, start_point?: Array<number>, borders_bbox?: Array<any>, is_auto_borders?: boolean, buffer_geojson?: Array<any>, radius_of_buffer?: Nullable<number>, command?: string, min_zoom?: Nullable<number>, max_zoom?: Nullable<number>, force_tiling?: boolean, apply_new_styles?: boolean, num_threads?: Nullable<number>, style_id?: Nullable<string>,value?: Nullable<string>, parent_id?: Nullable<number>}) {
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.description = description;
        this.alias = alias;
        this.heightmap = heightmap ?? 'fabdem';
        this.coordinate_system_id = coordinate_system_id ?? 4326;
        this.start_point = start_point ?? [0, 0, 0];
        this.is_auto_borders = is_auto_borders ?? true;
        this.borders_bbox = borders_bbox ?? [];
        this.buffer_geojson = buffer_geojson;
        this.radius_of_buffer = radius_of_buffer ?? 500;
        this.command = command;
        this.min_zoom = min_zoom ?? 3;
        this.max_zoom = max_zoom ?? 5;
        this.force_tiling = force_tiling ?? false;
        this.apply_new_styles = apply_new_styles ?? false;
        this.num_threads = num_threads ?? 1;
        this.style_id = style_id;
        this.value = value;
        this.parent_id = parent_id;
    }
}

export default class OpenWorld extends AbstractBaseEntity
{
    private id?: Nullable<number>;
    private guid?: Nullable<string>;
    private name: string;
    private description: Nullable<string>;
    private alias: Nullable<string>;
    private heightmap: Nullable<string>;
    private coordinateSystem: Nullable<number>;
    private startPoint: Array<number>;
    private isAutoBorders: boolean = true;
    private bordersBbox: Array<any>;
    private radiusOfbuffer: Nullable<number> = 500;
    private parentId: Nullable<number>;

    constructor(
        id: Nullable<number>,
        guid: Nullable<string>,
        name: string,
        description: Nullable<string>,
        alias: Nullable<string>,
        heightmap: Nullable<string>,
        coordinateSystem: Nullable<number>,
        startPoint: Array<number>,
        isAutoBorders: boolean,
        bordersBbox: Array<any>,
        radiusOfbuffer: Nullable<number>,
        parentId: Nullable<number>
    ) {
        super();
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.description = description;
        this.alias = alias;
        this.heightmap = heightmap;
        this.coordinateSystem = coordinateSystem;
        this.startPoint = startPoint;
        this.isAutoBorders = isAutoBorders;
        this.bordersBbox = bordersBbox;
        this.radiusOfbuffer = radiusOfbuffer;
        this.parentId = parentId;
        this.assertInvariants();
    }

    static create(dto: OpenWorldDTO): OpenWorld
    {
        return new OpenWorld(
            dto.id,
            dto.guid,
            dto.name,
            dto.description,
            dto.alias,
            dto.heightmap,
            dto.coordinate_system_id,
            dto.start_point,
            dto.is_auto_borders,
            dto.borders_bbox,
            dto.radius_of_buffer,
            dto.parent_id
        );
    }

    setId(id: number): void
    {
        this.id = id;
    }

    getId(): number
    {
        return this.id;
    }

    setGuid(guid: string): void
    {
        this.guid = guid;
    }

    getGuid(): string
    {
        return this.guid;
    }

    setName(name: string): void
    {
        if (name.length > 255) {
            throw new NameTooLong();
        }
        this.name = name;
    }

    getName(): string
    {
        return this.name;
    }

    setHeightmap(heightmap: Nullable<string>): void
    {
        this.heightmap = heightmap;
    }

    getHeightmap(): Nullable<string>
    {
        return this.heightmap;
    }

    assertInvariants(): void
    {
        if (this.name.length == 0) {
            throw new NameMustNotBeEmpty();
        }

        if (this.name.length > 255) {
            throw new NameTooLong();
        }
    }

    setDescription(description: Nullable<string>): void
    {
        this.description = description;
    }

    getDescription(): Nullable<string>
    {
        return this.description;
    }

    setAlias(alias: Nullable<string>): void
    {
        this.alias = alias;
    }

    getAlias(): Nullable<string>
    {
        return this.alias;
    }

    setCoordinateSystem(coordinateSystem: Nullable<number>): void
    {
        this.coordinateSystem = coordinateSystem;
    }

    getCoordinateSystem(): Nullable<number>
    {
        return this.coordinateSystem;
    }

    setStartPoint(startPoint: Array<number>): void
    {
        this.startPoint = startPoint;
    }

    getStartPoint(): Array<number>
    {
        return this.startPoint;
    }

    getAutoBorders(): boolean
    {
        return this.isAutoBorders;
    }

    setAutoBorders(isAutoBorders: boolean): void
    {
        this.isAutoBorders = isAutoBorders;
    }

    getBordersBbox(): Array<any>
    {
        return this.bordersBbox;
    }

    setBordersBbox(bordersBbox: Array<any>): void
    {
        this.bordersBbox = bordersBbox;
    }

    setParentId(parentId: Nullable<number>): void
    {
        this.parentId = parentId;
    }

    getParentId(): Nullable<number>
    {
        return this.parentId;
    }

    getType(): TreeElementType
    {
        return TreeElementType.OPEN_WORLD;
    }
}