import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import DagRepositoryInterface from '../../../domain/repository/DagRepositoryInterface'
import DagRunCommand from '../../command/DagRunCommand'
import { DagDTO } from '@/services/Databus/domain/model/Dag/Dag'

export default class DagRunHandler implements QueryHandlerInterface
{
    private repo: DagRepositoryInterface;

    constructor(repo: DagRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: DagRunCommand): Promise<any>
    {
        let item: DagDTO = {
            name: command.getDagId()
        };
        return this.repo.run(item);
    }
}
