
import Vue from 'vue'
import TreeSelect from '@/components/Common/TreeSelect.vue'
import Condition from '@/core/infrastructure/components/FilterType/Condition.vue'
import Constant from '@/core/infrastructure/components/FilterType/Constant/index.vue'
import CurrentTableField from '@/core/infrastructure/components/FilterType/CurrentTableField.vue'
import ExternalTableField from '@/core/infrastructure/components/FilterType/ExternalTableField.vue'
import Parameter from '@/core/infrastructure/components/FilterType/Parameter.vue'
import Sql from '@/core/infrastructure/components/FilterType/Sql.vue'
import UsersTable from '@/core/infrastructure/components/FilterType/UsersTable.vue'

export default Vue.extend({
  name: 'StateFieldForm',

  props: {
    value: Object,

    stateFieldTypes: {
      type: Array,
      default () {
        return []
      }
    },

    fields: {
      type: Array,
      default () {
        return []
      }
    },

    conditionFieldTypes: {
      type: Array,
      default () {
        return []
      }
    },

    targetObjectId: {
      type: Number,
      required: true
    }
  },

  components: {
    TreeSelect,

    Constant,
    Condition,
    CurrentTableField,
    ExternalTableField,
    Parameter,
    Sql,
    UsersTable
  },

  computed: {
    showFieldValueInput () {
      return ['current_user', 'current_datetime'].indexOf(this.model.state_field_type_id) === -1
    },

    isExternal () {
      return this.model.state_field_type_id === 'external_table_field'
    },

    fieldType () {
      let fieldType = null

      if (this.model.field_id !== null) {
        let field = this.findFieldById(this.model.field_id, this.fields)

        if (field) {
          fieldType = field.entity_type_id
        }
      }

      return fieldType
    },

    isPropValue () {
      return ['constant', 'parameter', 'sql'].indexOf(this.model.state_field_type_id) !== -1
    },

    rules () {
      return {
        field_id: [
          { required: true, message: this.$t('logic_editor_v2.rules.field'), trigger: 'change' }
        ],
        state_field_type_id: [
          { required: true, message: this.$t('logic_editor_v2.rules.type'), trigger: 'change' }
        ],
        users_field_id: [
          { required: true, message: this.$t('logic_editor_v2.rules.users_field'), trigger: 'change' }
        ],
        condition_field_type_id: [
          { required: true, message: this.$t('logic_editor_v2.rules.condition'), trigger: 'change' }
        ],
        current_table_field_id: [
          { required: true, message: this.$t('logic_editor_v2.rules.condition'), trigger: 'change' }
        ],
        'properties.name': [
          { required: true, message: this.$t('logic_editor_v2.rules.parameter_name'), trigger: 'blur' }
        ],
        'properties.value': [
          { required: true, message: this.$t('logic_editor_v2.rules.parameter_value'), trigger: 'blur' }
        ],
        'properties.sql': [
          { required: true, message: this.$t('logic_editor_v2.rules.parameter_value'), trigger: 'blur' }
        ],
        'properties.field_id': [
          { required: true, message: this.$t('logic_editor_v2.rules.parameter_value'), trigger: 'change' }
        ]
      }
    }
  },

  watch: {
    value: {
      handler: function (value) {
        this.model = value
      },
      deep: true
    },

    model: {
      handler: function (value) {
        this.$emit('input', value)
      },
      deep: true
    },

    fieldType () {
      this.loadXrefList()
    }
  },

  data () {
    return {
      model: this.value,

      treeProps: {
        children: 'children',
        isLeaf: 'isLeaf',
        label: 'name',
        disabled: (data, node) => {
          return [
            'boolean_field', 'xref_field', 'xref_multi_field',
            'string_field', 'integer_field', 'float_field',
            'date_field', 'time_field', 'datetime_field',
            'text_field', 'json_field', 'address_field',
            'gar_address_field', 'address_multi_field', 'xml_field',
            'geometry_field', 'file_field' // добавлен file_field
          ].indexOf(data.entity_type_id) === -1
        }
      },

      mappedValueProp: {
        constant: 'value',
        sql: 'sql',
        parameter: 'name'
      },

      mappedValidProp: {
        condition: 'condition_field_type_id',
        constant: 'properties.value',
        current_table_field: 'current_table_field_id',
        external_table_field: 'properties',
        parameter: 'properties.name',
        sql: 'properties.sql',
        target_table_field: 'target_table_field_id',
        users_table: 'users_field_id'
      },

      mappedFilterType: {
        condition: 'Condition',
        constant: 'Constant',
        current_table_field: 'CurrentTableField',
        external_table_field: 'ExternalTableField',
        parameter: 'Parameter',
        sql: 'Sql',
        users_table: 'UsersTable'
      },

      xrefList: []
    }
  },

  methods: {
    validate () {
      let isValid = false

      this.$refs.form.validate((valid) => {
        if (!valid) {
          return false
        }

        isValid = valid
      })

      return isValid
    },

    renderContent (h, { node, data, store }) {
      return h('span', {
        class: 'custom-tree-node'
      }, [
        h(
          'span', {
            class: 'node-label'
          }, [
            h(
              'span',
              {
                class: 'node-label__name'
              },
              node.label
            ),
            h(
              'span',
              {
                class: 'node-label__info'
              },
              `${this.$t('object_editor.entity_types.' + data.entity_type_id)} id ${data.id}`
            )
          ])
      ])
    },

    renderOptionLabel (item) {
      return `${item.name} (id: ${item.id}, ${this.$t('object_editor.entity_types.' + item.entity_type_id)})`
    },

    findFieldById (id, items) {
      let field = null

      for (let i = 0; i < items.length; i++) {
        if (items[i].id === id) {
          field = items[i]
          break
        }

        if (items[i].children.length > 0) {
          field = this.findFieldById(id, items[i].children)

          if (field) {
            break
          }
        }
      }

      return field
    },

    changeField (value) {
      this.model.current_table_field_id = null
      this.model.condition_field_type_id = null
      this.model.users_field_id = null
      this.model.properties = {
        name: null,
        value: null,
        field_id: null,
        registry_id: null,
        sql: null
      }
    },

    changeFieldType (value) {
      if (value === 'constant') {
        this.loadXrefList()
      }

      this.model.current_table_field_id = null
      this.model.condition_field_type_id = null
      this.model.users_field_id = null
      this.model.properties = {
        name: null,
        value: null,
        registry_id: null,
        field_id: null,
        sql: null,
        is_array: false
      }

      if (this.fieldType === 'boolean_field' && value === 'constant') {
        this.model.properties.value = false
      }
    },

    loadXrefList () {
      if (
        ['xref_field', 'xref_multi_field'].indexOf(this.fieldType) !== -1 &&
        this.model.state_field_type_id === 'constant'
      ) {
        this.$http
          .get(`${this.$config.api}/registryservice/xref/${this.model.field_id}/data`)
          .then((response) => {
            this.xrefList = response.data
          })
          .catch((error) => console.log(error))
      }
    }
  }
})
