import { ActionTree } from 'vuex'
import { StrokeStyleState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, StrokeStyleLoadingEvent, StrokeStyleLoadedEvent, StrokeStyleCreatedEvent, StrokeStyleUpdatedEvent, StrokeStyleDeletedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { StrokeStyleAPI } from '@/core/infrastructure/api/modules/StrokeStyleAPI'

export const actions: ActionTree<StrokeStyleState, any> = {
    [ActionTypes.ADD_STROKE_STYLE]: async ({ commit, dispatch }, data) => {
        try {
            commit(new StrokeStyleLoadingEvent());
            let location = await APIClient.shared.request(new StrokeStyleAPI.AddStrokeStyle(data.payload));
            commit(new StrokeStyleCreatedEvent(location));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.UPDATE_STROKE_STYLE]: async ({ commit, dispatch }, data) => {
        try {
            commit(new StrokeStyleLoadingEvent());
            let location = await APIClient.shared.request(new StrokeStyleAPI.UpdateStrokeStyle(data.payload));
            commit(new StrokeStyleUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.DELETE_STROKE_STYLE]: async ({ commit, dispatch }, data) => {
        try {
            await APIClient.shared.request(new StrokeStyleAPI.DeleteStrokeStyle(data.guid));
            commit(new StrokeStyleDeletedEvent(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }         
    },
    [ActionTypes.GET_STROKE_STYLES]: async ({ commit, dispatch }, data) => {
        try {
            commit(new StrokeStyleLoadingEvent());
            let res = await APIClient.shared.request(new StrokeStyleAPI.GetStrokeStyles(data.payload));
            commit(new StrokeStyleLoadedEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.GET_STROKE_STYLE_BY_GUID]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new StrokeStyleAPI.GetStrokeStyleByGuid(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    }
}
