import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import DataRepositoryInterface from '@/services/RegistryTable/domain/repository/DataRepositoryInterface'
import DataQuery from '@/services/RegistryTable/application/query/DataQuery'

export default class DataHandler implements QueryHandlerInterface {
  private repository: DataRepositoryInterface;

  constructor (repository: DataRepositoryInterface) {
    this.repository = repository
  }

  execute (query: DataQuery): Promise<any> {
    return this.repository.get(query.getRegistryId(), query.getPayload())
  }
}
