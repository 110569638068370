import { ActionTree } from 'vuex'
import { ProjectGroupState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, ProjectGroupUpdatedEvent, ProjectGroupLoadingEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { ProjectGroupAPI } from '@/services/DigitalTwinEditor/infrastructure/api/ProjectGroupAPI'

export const actions: ActionTree<ProjectGroupState, any> = {
    [ActionTypes.UPDATE_PROJECT_GROUP]: async ({ commit, dispatch }, data) => {
        try {
            commit(new ProjectGroupLoadingEvent());
            let location = await APIClient.shared.request(new ProjectGroupAPI.UpdateProjectGroup(data.payload));
            commit(new ProjectGroupUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.GET_PROJECT_GROUP_BY_GUID]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new ProjectGroupAPI.GetProjectGroupByGuid(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    }
}
