import { ActionTree } from 'vuex'
import { CommandState } from '@/services/LogicEditor/infrastructure/store/modules/Command/state'
import { ActionTypes } from './action-types'
import { MutationTypes, CommandUpdatedEvent, CommandCreatedEvent, CommandLoadingEvent, CommandDeletedEvent, CommandLoadedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { CommandAPI } from '@/services/LogicEditor/infrastructure/api/CommandAPI'

export const actions: ActionTree<CommandState, any> = {
  [ActionTypes.GET_COMMAND]: async ({ commit }, data) => {
    commit(new CommandLoadingEvent())

    return APIClient.shared.request(new CommandAPI.GetCommand(data.payload))
      .then((response) => {
        commit(new CommandLoadedEvent())

        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },

  [ActionTypes.GET_COMMAND_EVENT_TYPES]: async ({ commit }, data) => {
    commit(new CommandLoadingEvent())

    return APIClient.shared.request(new CommandAPI.GetCommandEventTypes())
      .then((response) => {
        commit(new CommandLoadedEvent())

        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },

  [ActionTypes.GET_COMMAND_EVENT_TYPE_PROPERTIES]: async ({ commit }, data) => {
    commit(new CommandLoadingEvent())

    return APIClient.shared.request(new CommandAPI.GetCommandEventTypeProperties(data.eventId))
      .then((response) => {
        commit(new CommandLoadedEvent())

        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },

  [ActionTypes.GET_COMMAND_BY_GUID]: async ({ commit }, data) => {
    commit(new CommandLoadingEvent())

    return APIClient.shared.request(new CommandAPI.GetCommandByGuid(data.guid))
      .then((response) => {
        commit(new CommandLoadedEvent())

        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },

  [ActionTypes.CREATE_COMMAND]: async ({ commit }, data) => {
    commit(new CommandLoadingEvent())

    return APIClient.shared.request(new CommandAPI.CreateCommand(data.payload))
      .then((location) => {
        commit(new CommandCreatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },

  [ActionTypes.UPDATE_COMMAND]: async ({ commit }, data) => {
    commit(new CommandLoadingEvent())

    return APIClient.shared.request(new CommandAPI.UpdateCommand(data.payload))
      .then((location) => {
        commit(new CommandUpdatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },

  [ActionTypes.DELETE_COMMAND]: async ({ commit }, data) => {
    commit(new CommandLoadingEvent())

    return APIClient.shared.request(new CommandAPI.DeleteCommand(data.guid))
      .then(() => {
        commit(new CommandDeletedEvent(data.guid))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  }
}
