import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import StyleQueryRepositoryInterface from '@/services/MapEditor/domain/repository/StyleQueryRepositoryInterface'
import store from '@/store'
import { GetTypes, GetTypeProperties, GetStyleByGuid, GetStyles, CountStyles, GetStyleProjects } from '@/services/MapEditor/infrastructure/store/modules/Style/action-types'
import { GetProjectStyles } from '@/services/MapEditor/infrastructure/store/modules/Project/action-types'

export default class StyleQueryRepository extends AbstractQueryRepository implements StyleQueryRepositoryInterface
{
    get(payload: string[][]): Promise<any>
    {
        return store.dispatch(new GetStyles(payload));
    }

    getByGuid(guid: string): Promise<any>
    {
        return store.dispatch(new GetStyleByGuid(guid));
    }

    getCount(payload: string[][]): Promise<any>
    {
        return store.dispatch(new CountStyles(payload));
    }

    getTypes(): Promise<any>
    {
        return store.dispatch(new GetTypes());
    }

    getTypeProperties(val: string): Promise<any>
    {
        return store.dispatch(new GetTypeProperties(val));
    }

    getByJoinTable(projectGuid: string, payload: string[][]): Promise<any>
    {
        return store.dispatch(new GetProjectStyles(projectGuid, payload));
    }

    getParentRecords(guid: string): Promise<any>
    {
        return store.dispatch(new GetStyleProjects(guid));
    }
}