import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import RouteQueryRepositoryInterface from '../../../domain/repository/RouteQueryRepositoryInterface'
import RoutesCountQuery from '../../query/RoutesCountQuery'

export default class RoutesCountHandler implements QueryHandlerInterface
{
    private repo: RouteQueryRepositoryInterface;

    constructor(repo: RouteQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: RoutesCountQuery): Promise<any>
    {
        return this.repo.getCount(query.getPayload());
    }
}