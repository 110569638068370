<template>
    <el-row>
        <el-col :span="18">
            <el-input size="small" v-model="data.value" v-on:input="onChange()" placeholder="Please input"></el-input>
        </el-col>
        <el-col :span="6">
            <el-select size="small" v-model="data.type" @change="onChange" placeholder="Select">
            <el-option
                    v-for="item in types"
                    :key="item.type"
                    :label="item.name"
                    :value="item.type">
            </el-option>
            </el-select>
        </el-col>
    </el-row>
</template>

<script>
import mixin from './mixin'
export default {
  mixins: [mixin],
  data () {
    return {
      data: {
        value: null,
        type: null
      },
      types: this.$locale.registry.filter.fields.string_field.types
    }
  },
  methods: {
    render () {
      if (this.value) {
        let properties = this.value.split(',')
        this.data.type = properties.shift()
        this.data.value = properties.join(',')
      }
    },
    onChange () {
      let value = null
      if (this.data.type && this.data.value) {
        value = `${this.data.type},${this.data.value}`
      }
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped>

</style>
