import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import ResourceDTO from '@/services/LogService/domain/model/Resource/Resource'
import LogDTO from '@/services/LogService/domain/model/Log/Log'
import CounterDTO from '@/services/LogService/domain/model/Counter/Counter'

export namespace LogsAPI {
  export class GetResources implements APIRequest<ResourceDTO> {
    response: ResourceDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data.map(item => new ResourceDTO(item))
    constructor (payload: string[][] | object) {
      this.path = '/logservice/resources'
    }
  }

  export class GetLogsByResource implements APIRequest<LogDTO[]> {
    response: LogDTO[];
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse<any[]>) => data.data.map(item => new LogDTO(item))
    constructor (payload: string[][], resource: string) {
      this.path = `/logservice/logs/all/${resource}?` + (new URLSearchParams(payload).toString())
    }
  }

  export class GetCountByResource implements APIRequest<CounterDTO> {
    response: CounterDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => new CounterDTO(data.data)
    constructor (payload: string[][], resource: string) {
      this.path = `/logservice/logs/count/${resource}?` + (new URLSearchParams(payload).toString())
    }
  }

  export class GetLogsByResourceAndDate implements APIRequest<LogDTO[]> {
    response: LogDTO[];
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse<any[]>) => data.data.map(item => new LogDTO(item))
    constructor (payload: string[][], resource: string) {
      this.path = `/logservice/logs/date/${resource}?` + (new URLSearchParams(payload).toString())
    }
  }
}
