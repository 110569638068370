<template>
  <div class="editor-document">
    <el-form-item>
      <span class="property_label">{{ $t('interface_editor.component_editor.button_action.block_alias') }}</span>
      <el-select v-model="blockInfo.blockAlias" multiple clearable @change="onChange">
        <el-option
          v-for="(item, index) in containers"
          :key="index"
          :label="item.alias"
          :value="item.alias"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-checkbox v-model="blockInfo.hideInterface" @change="onChange" >
        <span class="property_label">{{ $t('interface_editor.component_editor.button_action.hide_interface') }}</span>
      </el-checkbox>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'editor-print-page',
  props: {
    value: Object,
    containers: Array
  },
  data () {
    return {
      blockInfo: this.value
    }
  },
  mounted () {
    console.log(this.value)
  },
  methods: {
    onChange (value) {
      this.$emit('change', value)
    }
  }
}
</script>

<style>

</style>
