import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import TelegramChatDTO, { ITelegramChat } from '@/services/NotificationEditor/domain/model/TelegramChatDTO'

export namespace TelegramChatAPI {
  export class GetTelegramChat implements APIRequest<TelegramChatDTO[]> {
    response: TelegramChatDTO[];
    path: string;
    method = HTTPMethod.GET;
    hideNotification = true;

    constructor (payload: string[][] = []) {
      this.path = `/notificationeditor/telegram_chats?${new URLSearchParams(payload).toString()}`
    }

    parse (data: AxiosResponse<ITelegramChat[]>) {
      return data.data.map((item) => new TelegramChatDTO(item))
    }
  }
}
