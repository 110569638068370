<template>
    <el-form-item>
      <el-checkbox v-bind:value="JSON.parse(value)" :disabled="disabled === 'contains_geometry'" v-on:input="$emit('input', $event)">
        {{ label }}
      </el-checkbox>
    </el-form-item>
</template>

<script>
export default {
  name: 'boolean',

  props: ['value', 'disabled', 'label', 'properties']
}
</script>

<style scoped>

</style>
