
import { VueContext } from 'vue-context'
import ProjectGroupForm from '@/services/DigitalTwinEditor/infrastructure/components/Project/GroupForm.vue'
import LayerForm from '@/services/DigitalTwinEditor/infrastructure/components/Project/LayerForm.vue'
import OpenWorldForm from '@/services/DigitalTwinEditor/infrastructure/components/Project/OpenWorldForm.vue'
import StyleEditor from '@/services/DigitalTwinEditor/infrastructure/components/Project/StyleEditor.vue'
import ProjectForm from '@/services/DigitalTwinEditor/infrastructure/components/Project/ProjectForm.vue'
import ModelForm from '@/services/DigitalTwinEditor/infrastructure/components/Model/ModelForm.vue'
import ModelViewer from '@/services/DigitalTwinEditor/infrastructure/components/Model/ModelViewer.vue'
import ModelGroupForm from '@/services/DigitalTwinEditor/infrastructure/components/Model/GroupForm.vue'
import LayerUpdateCommand from '@/services/DigitalTwinEditor/application/command/LayerUpdateCommand'
import ModelUpdateCommand from '@/services/DigitalTwinEditor/application/command/ModelUpdateCommand'
import ModelGroupUpdateCommand from '@/services/DigitalTwinEditor/application/command/ModelGroupUpdateCommand'
import OpenWorldUpdateCommand from '@/services/DigitalTwinEditor/application/command/OpenWorldUpdateCommand'
import ProjectGroupUpdateCommand from '@/services/DigitalTwinEditor/application/command/ProjectGroupUpdateCommand'
import ProjectUpdateCommand from '@/services/DigitalTwinEditor/application/command/ProjectUpdateCommand'
import StyleUpdateCommand from '@/services/DigitalTwinEditor/application/command/StyleUpdateCommand'

export default {
  name: 'EditPanel',
  components: {
    VueContext,
    ProjectGroupForm,
    LayerForm,
    OpenWorldForm,
    StyleEditor,
    ProjectForm,
    ModelForm,
    ModelViewer,
    ModelGroupForm
  },
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  methods: {
    openEditForm (elementDto, treeElement) {
      let exists = false;
      for (let idx in this.tabs) {
        if (this.tabs[idx].guid === elementDto.guid) {
          exists = true;
          break;
        }
      }
      if (!exists) {
        elementDto.treeElement = treeElement;
        this.tabs.push(elementDto);
      }
      this.activeTab = elementDto.guid;
    },
    editPanelTab (guid, action) {
      if (action === 'remove') {
        for (let idx in this.tabs) {
          if (this.tabs[idx].guid === guid) {
            this.tabs.splice(idx, 1);
            break;
          }
        }
      }
    },
    closePanel (item) {
      for (let idx in this.tabs) {
        if (this.tabs[idx].guid === item.guid) {
          this.tabs.splice(idx, 1);
          break;
        }
      }
    },
    saveElement (elementDto) {
      let command;
      if (elementDto.element_type == 'project_group') {
        command = new ProjectGroupUpdateCommand(
          elementDto.guid,
          elementDto.name,
          elementDto.alias,
          elementDto.parent_id
        );
      } else if (elementDto.element_type == 'layer') {
        command = new LayerUpdateCommand(
          elementDto.guid,
          elementDto.name,
          elementDto.description,
          elementDto.alias,
          elementDto.parent_id
        );
      } else if (elementDto.element_type == 'project') {
        command = new ProjectUpdateCommand(
          elementDto.guid,
          elementDto.name,
          elementDto.description,
          elementDto.alias,
          elementDto.parent_id
        );
      } else if (elementDto.element_type == 'open_world') {
        command = new OpenWorldUpdateCommand(
          elementDto.guid,
          elementDto.name,
          elementDto.description,
          elementDto.alias,
          elementDto.heightmap,
          elementDto.coordinate_system_id,
          elementDto.start_point,
          elementDto.is_auto_borders,
          elementDto.borders_bbox,
          elementDto.radius_of_buffer,
          elementDto.parent_id
        );
      } else if (elementDto.element_type == 'model') {
        command = new ModelUpdateCommand(
          elementDto.guid,
          elementDto.name,
          elementDto.description,
          elementDto.alias,
          elementDto.coordinate_system_id,
          elementDto.position,
          elementDto.rotation,
          elementDto.files,
          elementDto.parent_id
        );
      } else if (elementDto.element_type == 'model_group') {
        command = new ModelGroupUpdateCommand(
          elementDto.guid,
          elementDto.name,
          elementDto.alias,
          elementDto.parent_id
        );
      } else if (elementDto.element_type == 'carto_style') {
        command = new StyleUpdateCommand(
          elementDto.open_world_guid,
          elementDto.id,
          elementDto.value
        );
      }
      this.getCommandBus().execute(command).then((response) => {
        elementDto.treeElement.name = elementDto.name;
      });
    },
    removeTreeElementByGuid (elementGuid) {
      for (let idx in this.tabs) {
        if (this.tabs[idx].guid === elementGuid) {
          this.tabs.splice(idx, 1);
          break;
        }
      }
    }
  },
  data () {
    return {
      activeTab: null,
      treeElement: null,
      tabs: []
    }
  },
  mounted() {
    this.getEventBus().$on('openEditForm', this.openEditForm);
    this.getEventBus().$on('removeTreeElementByGuid', this.removeTreeElementByGuid);    
  }
}

