<template>
  <div>
    <el-button type="text" @click="dialogSettingsVisible = true">{{ $locale.main.button.edit }}</el-button>

    <el-dialog :title="$locale.map_editor.entities.layers" :modal="false"
               :visible.sync="dialogSettingsVisible"
               class="window">
      <configurator
        v-model="layers"
      ></configurator>
    </el-dialog>
  </div>
</template>

<script>
import Configurator from './Configurator'
export default {
  name: 'map-layers',
  components: { Configurator },
  props: ['other-properties', 'value', 'options'],
  data () {
    return {
      layers: this.value,
      dialogSettingsVisible: false
    }
  },
  watch: {
    layers: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.window > /deep/ .el-dialog {
  width: 80%;
}
</style>
