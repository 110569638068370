import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    LAYER_LOADING_EVENT = 'layer_loading_event',
    LAYER_LOADED_EVENT = 'layer_loaded_event',
    LAYER_UPDATED_EVENT = 'layer_updated_event'
}

export class LayerLoadingEvent implements FluxStandardAction {
    type = MutationTypes.LAYER_LOADING_EVENT
}

export class LayerLoadedEvent implements FluxStandardAction {
    type = MutationTypes.LAYER_LOADED_EVENT
}

export class LayerUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.LAYER_UPDATED_EVENT
}
