export class ExtendedObjectColumnsFactory {
  static build (columns: { id, name, children }[]): object[] {
    let result = []
    columns.forEach((column) => {
      const columnType = 'stringField'
      const types = [columnType]
      let object = {
        headerName: column.name,
        field: column.name,
        children: undefined,
        type: types,
        enableRowGroup: true,
        guid: this.generateGuid(),
        columnType: columnType
      }

      if (column.children) {
        object.children = ExtendedObjectColumnsFactory.build(column.children)
      }

      result.push(object)
    })

    return result
  }

  private static generateGuid () {
    let d = new Date().getTime()
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      d += performance.now()
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = (d + Math.random() * 16) % 16 | 0
      d = Math.floor(d / 16)
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })
  }
}
