<template>
  <div class="input-anchor">
    <div class="input-anchor-col" style="float: left">
      <treeselect
        v-model="x"
        :placeholder="$locale.main.fields.select"
        :normalizer="(node) => {return {id: node.id, label: node.name}}"
        :options="values"
        :clear-value-text="$locale.main.message.clear"
        :loading-text="$locale.main.message.loading"
        :disable-immediate-search="true"
        :async="false"
        :cache-options="false"
        :append-to-body="false"
        :clearable="false"
        :delete-removes="false"
        :backspace-removes="false"
        @select="selectx"
        @input="inputx"
        ></treeselect>
    </div>
    <div class="input-anchor-col" style="float: left; margin-left: 10px;">
      <treeselect
        v-model="y"
        :placeholder="$locale.main.fields.select"
        :normalizer="(node) => {return {id: node.id, label: node.name}}"
        :options="values"
        :clear-value-text="$locale.main.message.clear"
        :loading-text="$locale.main.message.loading"
        :disable-immediate-search="true"
        :async="false"
        :cache-options="false"
        :append-to-body="false"
        :clearable="false"
        :delete-removes="false"
        :backspace-removes="false"
        @select="selecty"
        @input="inputy"
      ></treeselect>
    </div>
  </div>
</template>

<script type="text/babel">
  import Treeselect from '@bingosoftnn/vue-treeselect'

  export default {
    name: 'InputAnchor',

    inject: {
      elForm: {
        default: ''
      }
    },

    components: {
      Treeselect
    },

    data() {
      return {
        values: [{
            id: 'top',
            name: this.$locale.map_editor.style_form.anchor_values.top
        },{
            id: 'bottom',
            name: this.$locale.map_editor.style_form.anchor_values.bottom
        },{
            id: 'left',
            name: this.$locale.map_editor.style_form.anchor_values.left
        },{
            id: 'right',
            name: this.$locale.map_editor.style_form.anchor_values.right
        },{
            id: 'center',
            name: this.$locale.map_editor.style_form.anchor_values.center
        }],
        x: "center",
        y: "center"
      };
    },

    methods: {
      selectx(record) {},
      inputx(value) {
        this.$nextTick(() => {
          this.$emit('change', value, this.y);
        });
      },
      selecty(record) {},
      inputy(value) {
        this.$nextTick(() => {
          this.$emit('change', this.x, value);
        });
      }
    },

    mounted() {
      this.$nextTick(() => {
        this.$emit('init', (value) => {
          this.x = value[0];
          this.y = value[1];
        });
      });
    }
  };
</script>
