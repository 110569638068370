<template>
  <div>
    <el-form-item>
      <span class="property_label">{{ $t('interface_editor.component_editor.table.registry') }}</span>
      <registry-select
        :append-to-body="true"
        :value="value.registryId"
        @change="$set(value, 'registryId', $event)"
      ></registry-select>
    </el-form-item>
    <el-form-item>
      <span class="property_label">{{ $t('interface_editor.component_editor.editor_calendar.field_id') }}</span>
      <editor-select
        ref="record_id"
        :value="value.recordId"
        @change="$set(value,'recordId', $event)"
        :options="{
                multiple: false,
                clearable: true,
                options: components
              }"
      ></editor-select>
    </el-form-item>
    <el-form-item>
      <span class="property_label">{{ $t('interface_editor.component_editor.editor_calendar.card_id') }}</span>
      <editor-select
        ref="card_id"
        :value="value.cardId"
        @change="$set(value,'cardId', $event)"
        :options="{
                multiple: false,
                clearable: true,
                options: cards
              }"
      ></editor-select>
    </el-form-item>
  </div>
</template>

<script>
import RegistrySelect from '@/components/Common/RegistrySelectTreeWithSearch.vue'
import EditorSelect from './editor-select'
import Card from '@/components/ObjectEditor/Models/Card'

export default {
  name: 'editor-frame',
  components: {
    RegistrySelect,
    EditorSelect
  },
  inject: ['getComponents'],
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          registryId: null,
          cardId: null,
          recordId: null
        }
      }
    }
  },
  watch: {
    value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    },
    'value.registryId': {
      handler (value) {
        if (value) {
          this.loadCards(value)
          this.$refs.card_id.clearValue()
        }
      }
    }
  },
  data () {
    return {
      components: [],
      cards: []
    }
  },
  mounted () {
    this.components = this.getComponents().map(item => {
      return {
        name: `${item.properties.name ?? item.name} (${item.properties.editorAlias ?? 'нет алиаса'})`,
        id: item.properties.name
      }
    })
    if (this.value.registryId) {
      this.loadCards(this.value.registryId)
    }
  },
  methods: {
    async loadCards (entityId) {
      let cards = await new Card().params({ entity_id: entityId, order: 'id', fields: ['id', 'name', 'is_default', 'entity_id', 'guid', 'alias', 'card_roles', 'card_states'] }).$get()
      this.cards = cards.map((item) => {
        return {
          id: item.id,
          name: `${item.name} (${item.id})`
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
