
import StyleQuery from '@/services/DigitalTwinEditor/application/query/StyleQuery'

export default {
  name: 'StyleEditor',
  props: {
    value: {
      type: Object,
      default: () => { return {} }
    }
  },
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  data () {
    return {
      element_type: "carto_style",
      options: {
        tabSize: 2,
        mode: 'text/css',
        theme: 'base16-light',
        styleActiveLine: true,
        styleSelectedText: true,
        readonly: true
      }
    }
  },
  async mounted () {
    let payload = {
      guid: this.value.open_world_guid,
      id: this.value.id
    };
    await this.getQueryBus().execute(
      new StyleQuery(payload)
    ).then(data => {
      this.$set(this.value, 'value', data);
    })
  }
}

