<template>
  <el-input
    v-bind:value="value"
    v-on:input="$emit('input', $event)"
    :rows="2"
    :placeholder="placeholder"
  ></el-input>
</template>

<script>
export default {
  name: 'StringInput',

  props: {
    value: String,

    placeholder: String
  }
}
</script>

<style type="text/css"></style>
