import { Nullable } from '@/core/domain/type/types'

export interface IAPIRuleTreeElement {
  id: Nullable<number>
  guid: Nullable<string>
  name: Nullable<string>
  parent_id: Nullable<number>
  rule_id: Nullable<number>
  group_id: Nullable<number>
  element_type: Nullable<string>
  element_guid: Nullable<string>
  row_order: Nullable<number>
  is_leaf: Nullable<boolean>
}

export default class RuleTreeElement {
  public id: Nullable<number>
  public guid: Nullable<string>
  public name: Nullable<string>
  public parent_id: Nullable<number>
  public rule_id: Nullable<number>
  public group_id: Nullable<number>
  public element_type: Nullable<string>
  public element_guid: Nullable<string>
  public row_order: Nullable<number>
  public is_leaf: Nullable<boolean>

  constructor (ruleTreeElement: IAPIRuleTreeElement) {
    this.id = ruleTreeElement.id
    this.guid = ruleTreeElement.guid
    this.name = ruleTreeElement.name
    this.parent_id = ruleTreeElement.parent_id
    this.rule_id = ruleTreeElement.rule_id
    this.group_id = ruleTreeElement.group_id
    this.element_type = ruleTreeElement.element_type
    this.element_guid = ruleTreeElement.element_guid
    this.row_order = ruleTreeElement.row_order
    this.is_leaf = ruleTreeElement.is_leaf
  }

  static load (data: IAPIRuleTreeElement): RuleTreeElement {
    return new RuleTreeElement(data)
  }

  static loadMany (data: Array<IAPIRuleTreeElement>): Array<RuleTreeElement> {
    const ruleTreeElements: Array<RuleTreeElement> = []

    data.forEach((item) => {
      ruleTreeElements.push(this.load(item))
    })

    return ruleTreeElements
  }
}
