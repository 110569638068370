import { ActionTree } from 'vuex'
import { DagState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, DagLoadingEvent, DagLoadedEvent, DagCreatedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { DagAPI } from '@/services/Databus/infrastructure/api/DagAPI'

export const actions: ActionTree<DagState, any> = {
    [ActionTypes.GET_DAGS]: async ({ commit, dispatch }, data) => {
        try {
            commit(new DagLoadingEvent());
            let res = await APIClient.shared.request(new DagAPI.GetDags(data.payload));
            commit(new DagLoadedEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.RUN_DAG]: async ({ commit, dispatch }, data) => {
        try {
            commit(new DagLoadingEvent());
            let location = await APIClient.shared.request(new DagAPI.RunDag(data.payload));
            commit(new DagCreatedEvent(location));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    }
}
