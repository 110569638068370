import { ActionTree } from 'vuex'
import { RuleGroupState, initialState } from './state'
import { ActionTypes } from './action-types'
import {
  MutationTypes,
  RuleGroupLoadingEvent,
  RuleGroupCreatedEvent,
  RuleGroupUpdatedEvent,
  RuleGroupDeletedEvent,
  RuleGroupLoadedEvent
} from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { RuleGroupAPI } from '@/services/TaskEditor/infrastructure/api/RuleGroupAPI'

export const actions: ActionTree<RuleGroupState, any> = {
  [ActionTypes.GET_RULE_GROUP_BY_GUID]: async ({ commit, dispatch }, data) => {
    commit(new RuleGroupLoadingEvent())

    return APIClient.shared.request(new RuleGroupAPI.GetRuleGroupByGuid(data.guid))
      .then((response) => {
        commit(new RuleGroupLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.CREATE_RULE_GROUP]: async ({ commit, dispatch }, data) => {
    commit(new RuleGroupLoadingEvent())

    return APIClient.shared.request(new RuleGroupAPI.CreateRuleGroup(data.payload))
      .then((location) => {
        commit(new RuleGroupCreatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.UPDATE_RULE_GROUP]: async ({ commit, dispatch }, data) => {
    commit(new RuleGroupLoadingEvent())

    return APIClient.shared.request(new RuleGroupAPI.UpdateRuleGroup(data.payload))
      .then((location) => {
        commit(new RuleGroupUpdatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.DELETE_RULE_GROUP]: async ({ commit, dispatch }, data) => {
    commit(new RuleGroupLoadingEvent())

    return APIClient.shared.request(new RuleGroupAPI.DeleteRuleGroup(data.guid))
      .then(() => {
        commit(new RuleGroupDeletedEvent(data.guid))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  }
}
