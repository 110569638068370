import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { DeploymentDTO } from '@/services/ReleaseEditor/domain/model/Deployment/Deployment'

export namespace DeploymentAPI {

    export class GetDeployments implements APIRequest<DeploymentDTO> {
        response: DeploymentDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/releaseeditor/deployments?' + (new URLSearchParams(payload).toString())
        }
    }

}