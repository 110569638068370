import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import TextureQueryRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/TextureQueryRepositoryInterface'
import store from '@/store'
import { GetTextures, CountTextures } from '@/services/DigitalTwinEditor/infrastructure/store/modules/Texture/action-types'

export default class TextureQueryRepository extends AbstractQueryRepository implements TextureQueryRepositoryInterface {
  get (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetTextures(payload))
  }

  getCount (payload: string[][] | object): Promise<any> {
    return store.dispatch(new CountTextures(payload))
  }
}
