<template>
  <div>
    <el-form-item label="Расширенный реестр">
      <el-select
        v-model="value.entityId">
        <el-option
          v-for="item in list"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="Геометрия">
      <select-tree
        :clearable="true"
        ref="entity_tree"
        :dataTable="fields"
        class="custom_scrollbar"
        :appendToBody='false'
        v-model="value.geometryField"/>
    </el-form-item>
    <el-form-item label="Метки значений">
      <select-tree
        :clearable="true"
        ref="entity_tree"
        :dataTable="fields"
        class="custom_scrollbar"
        :appendToBody='false'
        v-model="value.marksField"/>
    </el-form-item>
    <el-form-item label="Система координат">
      <coordinate-system
        :value="value.nativeCoordinateSystemId"
        @change-cs="value.nativeCoordinateSystemId = $event"
      ></coordinate-system>
    </el-form-item>
    <el-form-item label="Способ обработки">
      <el-select
        v-model="value.srsHandlingType">
        <el-option
          v-for="item in $locale.interface_editor.component_editor.map_layers.srs_handling_types"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>
  </div>
</template>

<script>
import SelectTree from '@/core/infrastructure/components/TreeSelect'
import CoordinateSystem from '@/core/infrastructure/components/CoordinateSystem'
export default {
  components: {
    SelectTree,
    CoordinateSystem
  },
  name: 'ExtendedObject',
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          entityId: null,
          geometryField: null,
          marksField: null
        }
      }
    }
  },
  data () {
    return {
      fields: [],
      list: []
    }
  },
  mounted () {
    this.loadList()
    this.loadFields(this.value.entityId)
  },
  watch: {
    'value.entityId': {
      handler (value) {
        this.loadFields(value)
        this.value.geometryField = null
        this.value.marksField = null
      },
      deep: true
    }
  },
  methods: {
    async loadList () {
      let response = await this.$http.get(`${this.$config.api}/bieditor/extended_objects`)
      this.list = response.data
    },
    async loadFields (extendedObjectId) {
      if (!extendedObjectId) {
        return false
      }
      let response = await this.$http.get(`${this.$config.api}/bieditor/extended_objects/${extendedObjectId}`)
      this.fields = response.data.extended_object_fields.map((item) => {
        item.id = item.name
        return item
      })
    }
  }
}
</script>

<style scoped>

</style>
