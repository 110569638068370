import { ActionTree } from 'vuex'
import { GroupState } from '@/services/NotificationEditor/infrastructure/store/modules/NotificationGroup/state'
import { ActionTypes } from './action-types'
import {
  MutationTypes,
  TelegramChatLoadingEvent,
  TelegramChatLoadedEvent
} from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { TelegramChatAPI } from '@/services/NotificationEditor/infrastructure/api/TelegramChatAPI'

export const actions: ActionTree<GroupState, any> = {
  [ActionTypes.GET_TELEGRAM_CHATS]: async ({ commit, dispatch }, data) => {
    commit(new TelegramChatLoadingEvent())

    return APIClient.shared.request(new TelegramChatAPI.GetTelegramChat(data.payload))
      .then((response) => {
        commit(new TelegramChatLoadedEvent())

        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  }
}
