<template>
<div>

</div>
</template>

<script>
export default {
  name: 'separator',
  data () {
    return {

    }
  }
}
</script>

<style scoped>
</style>
