import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import CoordinateSystemQueryRepositoryInterface from '../../../domain/repository/CoordinateSystemQueryRepositoryInterface'
import CoordinateSystemBySridQuery from '../../query/CoordinateSystemBySridQuery'

export default class CoordinateSystemBySridHandler implements QueryHandlerInterface
{
    private repo: CoordinateSystemQueryRepositoryInterface;

    constructor(repo: CoordinateSystemQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: CoordinateSystemBySridQuery): Promise<any>
    {
        return this.repo.getBySrid(query.getSrid());
    }
}