import { Nullable } from '@/core/domain/type/types'

export default class ModelGroupUpdateCommand
{
    private guid: string;
    private name: string;
    private alias: Nullable<string>;
    private parentId: Nullable<number>;

    constructor(guid: string, name: string, alias: Nullable<string>, parentId: Nullable<number>)
    {
        this.guid = guid;
        this.name = name;
        this.alias = alias;
        this.parentId = parentId;
    }

    getGuid(): string
    {
        return this.guid;
    }

    getName(): string
    {
        return this.name;
    }

    getAlias(): Nullable<string>
    {
        return this.alias;
    }

    getParentId(): Nullable<number>
    {
        return this.parentId;
    }

    getClassName(): string
    {
        return 'ModelGroupUpdateCommand';
    }    
}