import { ActionTree } from 'vuex'
import { TaskState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, TaskLoadingEvent, TaskLoadedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { TaskAPI } from '@/services/DigitalTwinEditor/infrastructure/api/TaskAPI'

export const actions: ActionTree<TaskState, any> = {
  [ActionTypes.GET_TASKS]: async ({ commit, dispatch }, data) => {
    try {
      commit(new TaskLoadingEvent())
      let res = await APIClient.shared.request(new TaskAPI.GetTasks(data.payload))
      commit(new TaskLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.COUNT_TASKS]: async ({ commit, dispatch }, data) => {
    try {
      commit(new TaskLoadingEvent())
      let res = await APIClient.shared.request(new TaskAPI.CountTasks(data.payload))
      commit(new TaskLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  }
}
