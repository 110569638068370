import QueryInterface from './QueryInterface'

export default abstract class AbstractByGuidQuery implements QueryInterface {
    private guid: string;

    constructor (guid: string) {
      this.guid = guid
    }

    getGuid (): string {
      return this.guid
    }

    abstract getClassName(): string;
}
