<template>
    <div>
      <el-table-column
        class-name="collapsed"
        align="center" fixed="left"
        :width="1"
      >
      </el-table-column>
      <selectableColumn v-if="isSelectable"></selectableColumn>
      <edit-column :treeTable="treeTable" :showButton="showButton" @edit-record="$emit('edit-record', $event)" @row-edit="$emit('row-edit', $event)"></edit-column>
      <table-header
        v-for="(header, index) in columns.filter(item => !item.horizontalParent)"
        :key="index"
        :prop="header.value"
        :hidden="header.hidden"
        :columns="columns"
        :fast-edit="header.fastEdit"
        :under-label="header.underLabel"
        :type-col="header.type"
        :editable="header.isEdit"
        :label="header.text"
        :children="header.children"
        :width="header.width"
        :fixed="header.fixed"
        :htmlTemplate="header.htmlTemplate"
        :css="header.css"
        :open-card="header.card"
        :fixedNumber="header.fixedNumber"
        :cssHeader="header.cssHeader"
        :click-type="header.clickType"
        :action="header.action"
        :old-dashboard-action="header.dashboard"
        :old-action-card="header.card"
        :extended="header.extended"
        :grouped="groupedAttributes[header.value]"
        :horizontal-items="columns.filter(item => (item.horizontalParent || {}).value === header.value)"
        @update-layout="$emit('update-layout')"
        @update-editable-model="$emit('update-editable-model', $event)"
        @init-fast-edit="$emit('init-fast-edit', $event)"
      ></table-header>
    </div>
</template>

<script>
import tableHeader from './tableHeader'
import selectableColumn from './SelectableColumn'
import editColumn from './EditColumn'
import ModelMixin from '@/components/InterfaceEditor/components/ModelMixin'

export default {
  // props: ['isGrouped', 'columns', 'groupedAttributes'],
  mixins: [ModelMixin],
  props: {
    isGrouped: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Array,
      default () { return [] }
    },
    groupedAttributes: {
      type: Object,
      default () { return {} }
    },
    isSelectable: {
      type: Boolean,
      default: true
    },
    showButton: {
      type: Object
    },
    treeTable: {
      type: Boolean
    }
  },
  components: {
    selectableColumn,
    tableHeader,
    editColumn
  },
  name: 'Columns'
}
</script>

<style scoped>

</style>
