
import { VueContext } from 'vue-context'

export default {
  name: 'GroupForm',
  components: {
    VueContext
  },
  props: ['value'],
  computed: {
    dto: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  data () {
    return {
      element_type: "project_group",
      groupRules: {
        name: {
          required: true,
          message: this.$locale.digitaltwin_editor.errors.name_required,
          trigger: 'input'
        }
      }
    }
  }
}

