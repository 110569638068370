export interface ProcedureState {
  isLoading: boolean
  location: string
  deletedProcedureGuid: string
  error: object
}

export const initialState = (): ProcedureState => {
  return {
    isLoading: false,
    location: null,
    deletedProcedureGuid: null,
    error: null
  }
}
