import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import TaskQueryRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/TaskQueryRepositoryInterface'
import store from '@/store'
import { GetTasks, CountTasks } from '@/services/DigitalTwinEditor/infrastructure/store/modules/Task/action-types'

export default class TaskQueryRepository extends AbstractQueryRepository implements TaskQueryRepositoryInterface {
  get (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetTasks(payload))
  }

  getCount (payload: string[][] | object): Promise<any> {
    return store.dispatch(new CountTasks(payload))
  }
}
