<script type="ts">
import { Stack, StackItem } from 'vue-stack-grid'
import ElImage from 'element-ui/packages/image/src/main.vue'
import ElButton from 'element-ui/packages/button/src/button.vue'
import PointStyleImageCreateCommand from '@/core/application/command/modules/PointStyleImage/PointStyleImageCreateCommand'
import PointStyleImageCreateHandler from '@/core/application/command/modules/PointStyleImage/PointStyleImageCreateHandler'
import PointStyleImageUpdateCommand from '@/core/application/command/modules/PointStyleImage/PointStyleImageUpdateCommand'
import PointStyleImageUpdateHandler from '@/core/application/command/modules/PointStyleImage/PointStyleImageUpdateHandler'
import PointStyleImageDeleteCommand from '@/core/application/command/modules/PointStyleImage/PointStyleImageDeleteCommand'
import PointStyleImageDeleteHandler from '@/core/application/command/modules/PointStyleImage/PointStyleImageDeleteHandler'
import PointStyleImagesQuery from '@/core/application/query/modules/PointStyleImage/PointStyleImagesQuery'
import PointStyleImagesHandler from '@/core/application/query/modules/PointStyleImage/PointStyleImagesHandler'
import PointStyleImageByGuidQuery from '@/core/application/query/modules/PointStyleImage/PointStyleImageByGuidQuery'
import PointStyleImageByGuidHandler from '@/core/application/query/modules/PointStyleImage/PointStyleImageByGuidHandler'
import PointStyleImageCommandRepository from '@/core/infrastructure/domain/repository/modules/PointStyleImageCommandRepository'
import PointStyleImageQueryRepository from '@/core/infrastructure/domain/repository/modules/PointStyleImageQueryRepository'
import PointStyleImage, { PointStyleImageDTO } from '@/core/domain/model/modules/PointStyleImage'

export default {
    name: 'PointIconSet',
    props: {        
        showControls: {
            type: Boolean,
            default: true
        },
        value: {
            type: [Object, Array],
            default: () => {}
        }
    },
    components: {
        Stack, StackItem 
    },
    data() {
        return {
            dto: new PointStyleImage({}),
            items: [],
            itemsLoaded: false,
            iconValue: null,
            pointStyleImageCommandRepository: new PointStyleImageCommandRepository(),
            pointStyleImageQueryRepository: new PointStyleImageQueryRepository(),
            pointStyleImageCreateHandler: new PointStyleImageCreateHandler(this.getPointStyleImageCommandRepository()),
            pointStyleImageUpdateHandler: new PointStyleImageUpdateHandler(this.getPointStyleImageCommandRepository()),
            pointStyleImageDeleteHandler: new PointStyleImageDeleteHandler(this.getPointStyleImageCommandRepository()),
            pointStyleImageByGuidHandler: new PointStyleImageByGuidHandler(this.getPointStyleImageQueryRepository()),
            pointStyleImagesHandler: new PointStyleImagesHandler(this.getPointStyleImageQueryRepository())
        };
    },
    watch: {
        iconValue(val) {
            this.$emit('change', val);
        }
    },
    methods: {
        getPointStyleImageCommandRepository() {
            return this.pointStyleImageCommandRepository || (new PointStyleImageCommandRepository());
        },
        getPointStyleImageQueryRepository() {
            return this.pointStyleImageQueryRepository || (new PointStyleImageQueryRepository());
        },
        renderIconStack() {
            if (this.items.length > 0) {
                return this.$createElement(Stack, {
                    props: {
                        columnMinWidth: 82,
                        gutterWidth: 8,
                        gutterHeight: 8
                    },
                    ref: "iconSet"
                },[
                    ...this.renderIconItems()
                ]);
            }
            return null;
        },
        renderIconItems() {
            let components = [];
            for (let i = 0; i < this.items.length; i += 1) {
                let item = this.items[i];
                components.push(
                    this.$createElement("span",
                        {
                            on: {
                                click: (el) => {
                                    this.iconValue = {"guid": item.guid, "extension": item.extension};
                                }
                            }
                        },[
                        this.$createElement(StackItem, {
                            props: {},
                            class: {
                                "selected": this.iconValue ? item.guid == this.iconValue.guid : false
                            }
                        },[
                            this.$createElement(ElImage, {
                                props: {
                                    src: `${this.$config.api}/files/mapeditor/images/${item.guid}.${item.extension}`
                                },
                                style: {
                                    width: "24px",
                                    height: "24px"
                                }
                            })
                        ])
                    ])
                );
            }
            return components;            
        },
        renderControls() {
            return this.$createElement("span", {
                style: {
                    "width": "40px",
                    "padding-left": "4px",
                    "display": (this.showControls ? "block" : "none")
                }
            }, [
                this.$createElement("div", {},[
                    this.$createElement(ElButton, {
                        props: {
                            type: "info",
                            circle: true,
                            icon: "el-icon-plus",
                            size: "mini",
                            plain: true
                        },
                        on: {
                            click: () => {
                                this.$emit('addPointIcon', (item) => {
                                    this.items.push({
                                        guid: item.getGuid(),
                                        extension: item.getExtension()
                                    });
                                    this.$refs.iconSet.update();
                                });
                            }
                        }
                    }, [])
                ]),
                this.$createElement("div", {},[
                    this.$createElement(ElButton, {
                        props: {
                            type: "info",
                            circle: true,
                            icon: "el-icon-delete",
                            size: "mini",
                            plain: true
                        },
                        on: {
                            click: () => {
                                if (this.iconValue == null) {
                                    this.$message({
                                        message: this.$locale.main.message.select_image,
                                        type: 'warning'
                                    });
                                } else {
                                    this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
                                        confirmButtonText: this.$locale.main.button.delete,
                                        cancelButtonText: this.$locale.main.button.cancel,
                                        type: 'warning'
                                    }).then(async () => {
                                        let guid = this.iconValue['guid'];
                                        this.pointStyleImageDeleteHandler.execute(
                                            new PointStyleImageDeleteCommand(
                                                this.iconValue['guid']
                                            )
                                        ).then((response) => {
                                            this.iconValue = null;
                                            for (let i = 0; i < this.items.length; i += 1) {
                                                let item = this.items[i];
                                                if (item.guid == guid) {
                                                    this.items.splice(i, 1);
                                                    this.$refs.iconSet.update();
                                                    break;
                                                }
                                            }
                                        });

                                    }).catch((error) => { console.log(error); })
                                }
                            }
                        }
                    }, [])
                ])                 
            ]);
        }
    },
    render (createElement) {
        if (this.value) {
            this.iconValue = this.value;
        }
        if (!this.itemsLoaded) {
            this.itemsLoaded = true;
            this.pointStyleImagesHandler.execute(new PointStyleImagesQuery({})).then((data) => {
                this.items = data;
            });
        }        
        return createElement("div", {
            style: {
                "display": "flex",
                "overflow": "hidden"
            }
        },[
            this.$createElement("div", {
                class: {
                    "point-icon-set": true,
                    "custom_scrollbar": true
                },
                style: {
                    "flex": "100%"
                }
            },[
                this.renderIconStack()
            ]),
            this.renderControls()
        ]);
    }
};
</script>
