import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    RULE_GROUP_LOADING_EVENT = 'rule_group_loading_event',
    RULE_GROUP_LOADED_EVENT = 'rule_group_loaded_event',
    RULE_GROUP_CREATED_EVENT = 'rule_group_created_event',
    RULE_GROUP_UPDATED_EVENT = 'rule_group_updated_event',
    RULE_GROUP_DELETED_EVENT = 'rule_group_deleted_event'
}

export class RuleGroupLoadingEvent implements FluxStandardAction {
  type = MutationTypes.RULE_GROUP_LOADING_EVENT
}

export class RuleGroupLoadedEvent implements FluxStandardAction {
  type = MutationTypes.RULE_GROUP_LOADED_EVENT
}

export class RuleGroupCreatedEvent implements FluxStandardAction {
  type = MutationTypes.RULE_GROUP_CREATED_EVENT
  constructor (public location: string) {}
}

export class RuleGroupUpdatedEvent implements FluxStandardAction {
  type = MutationTypes.RULE_GROUP_UPDATED_EVENT
  constructor (public location: string) {}
}

export class RuleGroupDeletedEvent implements FluxStandardAction {
  type = MutationTypes.RULE_GROUP_DELETED_EVENT
  constructor (public guid: string) {}
}
