import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import ModelQueryRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/ModelQueryRepositoryInterface'
import store from '@/store'
import { GetModelByGuid } from '@/services/DigitalTwinEditor/infrastructure/store/modules/Model/action-types'

export default class ModelQueryRepository extends AbstractQueryRepository implements ModelQueryRepositoryInterface
{
    getByGuid(guid: string): Promise<any>
    {
        return store.dispatch(new GetModelByGuid(guid));
    }
}