import { FluxStandardAction } from '@/core/domain/type/types'
import { LayerTreeElementDTO } from '@/services/MapEditor/domain/model/Layer/LayerTreeElement'

export enum MutationTypes {
    ERROR = 'error',
    LAYER_TREE_ELEMENT_LOADING_EVENT = 'layer_tree_element_loading_event',
    LAYER_TREE_ELEMENT_CREATED_EVENT = 'layer_tree_element_created_event',
    LAYER_TREE_ELEMENT_DELETED_EVENT = 'layer_tree_element_deleted_event',
    LAYER_TREE_ELEMENT_UPDATED_EVENT = 'layer_tree_element_updated_event'      
}

export class LayerTreeElementLoadingEvent implements FluxStandardAction {
    type = MutationTypes.LAYER_TREE_ELEMENT_LOADING_EVENT
}

export class LayerTreeElementCreatedEvent implements FluxStandardAction {
    type = MutationTypes.LAYER_TREE_ELEMENT_CREATED_EVENT
    constructor(public location: string){}
}

export class LayerTreeElementDeletedEvent implements FluxStandardAction {
    type = MutationTypes.LAYER_TREE_ELEMENT_DELETED_EVENT
    constructor(public guid: string){}
}

export class LayerTreeElementUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.LAYER_TREE_ELEMENT_UPDATED_EVENT
}