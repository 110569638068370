import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ProjectCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/ProjectCommandRepositoryInterface'
import AttachTemplateToProjectCommand from '@/services/DigitalTwinEditor/application/command/AttachTemplateToProjectCommand'
import { TemplateDTO } from '@/services/DigitalTwinEditor/domain/model/Project/Template'

export default class AttachTemplateToProjectHandler implements CommandHandlerInterface
{
    private repo: ProjectCommandRepositoryInterface;

    constructor(repo: ProjectCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: AttachTemplateToProjectCommand): Promise<void>
    {
        const item: TemplateDTO = {
            project_guid: command.getProjectGuid(),
            id: command.getId()
        };
        return this.repo.attachTemplateToProject(item);
    }
}