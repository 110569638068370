import { render, staticRenderFns } from "./CertificateList.vue?vue&type=template&id=67ec047a&scoped=true&"
import script from "./CertificateList.vue?vue&type=script&lang=js&"
export * from "./CertificateList.vue?vue&type=script&lang=js&"
import style0 from "./CertificateList.vue?vue&type=style&index=0&id=67ec047a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67ec047a",
  null
  
)

export default component.exports