import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import EntityQueryRepositoryInterface from '../../../domain/repository/EntityQueryRepositoryInterface'
import EntitiesQuery from '../../query/EntitiesQuery'

export default class EntitiesHandler implements QueryHandlerInterface
{
    private repo: EntityQueryRepositoryInterface;

    constructor(repo: EntityQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: EntitiesQuery): Promise<any>
    {
        return this.repo.get(query.getPayload());
    }
}