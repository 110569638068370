<template>
  <el-select
    v-model="localValue"
    size="mini"
    @change="change"
  >
    <el-option
      v-for="(item, index) in sizeList"
      :key="index"
      :value="item.value"
      :label="item.label"
    ></el-option>
  </el-select>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'editor-size',

  props: {
    value: String
  },

  data () {
    return {
      localValue: this.value || null,

      sizeList: [
        { value: null, label: 'Максимальный' },
        { value: 'medium', label: 'Средний' },
        { value: 'small', label: 'Маленький' },
        { value: 'mini', label: 'Минимальный' }
      ]
    }
  },

  methods: {
    change (value) {
      this.$emit('change', value)
    }
  }
})
</script>

<style scoped>

</style>
