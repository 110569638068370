import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'
import EventType from '@/services/History/domain/model/EventType'
import { Nullable } from '@/core/domain/type/types'

export default class Event extends AbstractBaseEntity {
  private readonly _user: Nullable<string>;
  private readonly _type: EventType;
  private readonly _date: Date;
  private readonly _deltas: string[][];

  constructor (
    user: Nullable<string>,
    type: EventType,
    date: Date,
    deltas: string[][]
  ) {
    super()
    this._user = user
    this._type = type
    this._date = date
    this._deltas = deltas
  }

  get deltas (): string[][] {
    return this._deltas
  }

  get date (): Date {
    return this._date
  }

  get type (): EventType {
    return this._type
  }

  get user (): Nullable<string> {
    return this._user
  }
}
