
import Vue from 'vue'
import APIErrorMixin from '@/APIErrorMixin'

export default Vue.extend({
  computed: {
    showAuth (): boolean {
      const excludeRoutes: string[] = ['LoginPage', 'OAuthPage', 'EsiaPage', 'FastEsia']

      return !this.$store.getters['Authorization/isAuthenticated'] && !excludeRoutes.includes(this.$route.name)
    }
  },
  mixins: [APIErrorMixin],

  data () {
    return {
      hiddenErrors: [
        'record_constraint_forbidden',
        'record_constraint_notify',
        'need_to_sign_record_before_approve_exception'
      ],
      themes: [],
      themeIndex: -1
    }
  },
  created () {
    if (this.$config.refresh_page_alert_disabled === 0) {
      if (process.env.NODE_ENV !== 'development') {
        let me = this
        window.addEventListener('beforeunload', function (event) {
          event.returnValue = 'Обновление страницы?'
          me.$router.push('/')
        })
      }
    }
  },
  methods: {
    goToLogin () {
      const redirectUrl = window.location.pathname

      if (redirectUrl) {
        localStorage.setItem('redirect_url', redirectUrl)
      }

      this.$router.push(`/login`)
    }

    // switchThemes () {
    //   const length = this.themes.length
    //
    //   if (length > 0) {
    //     this.themeIndex++
    //
    //     if (this.themeIndex > length - 1) {
    //       this.themeIndex = 0
    //     }
    //
    //     const currentTheme = this.themes[this.themeIndex] || null
    //
    //     if (currentTheme) {
    //       localStorage.setItem('theme-link', `${this.$config.api}/files/csseditor/themes/theme-${currentTheme.id}.min.css`)
    //     }
    //
    //     const themeTag = document.getElementById('themeCss')
    //     const customThemeLink = localStorage.getItem('theme-link')
    //
    //     if (themeTag && customThemeLink) {
    //       themeTag.setAttribute('href', customThemeLink)
    //     }
    //
    //     console.log(themeTag, customThemeLink)
    //   }
    // }
  },
  mounted () {
    // await this.$http.get(`${this.$config.api}/csseditor/themes`)
    //   .then((response) => {
    //     this.themes = response.data
    //   })

    // TODO: небольшой костыль для подключения кастомных стилей, настраиваемых через редактор CSS
    const themeTag = document.getElementById('themeCss')
    const themeLink = localStorage.getItem('theme-link')
    const defaultThemeLink = `${this.$config.api}/files/csseditor/themes/default.min.css`

    // Если установлена статичная тема, или тема вообще не установлена, то нужно выполнить запрос к файлу для проверки его сущевствования
    // Если файл темы сущевствует и к нему есть доступ, то приминяем его в качестве главного CSS
    if (themeLink === '/element/index.css' || !themeLink) {
      this.$http.get(defaultThemeLink)
        .then(() => {
          localStorage.setItem('theme-link', defaultThemeLink)

          if (themeTag) {
            themeTag.setAttribute('href', `${defaultThemeLink}?v=${Math.random()}`)
          }
        })
    }
  }
})
