export interface NotificationState {
  isLoading: boolean;
  location: string;
  deletedNotificationId: number;
  data: object;
  error: object;
}

export const initialState = (): NotificationState => {
  return {
    isLoading: false,
    location: null,
    deletedNotificationId: null,
    data: null,
    error: null
  }
}
