import { FluxStandardAction } from '@/core/domain/type/types'
import { ThemeDTO } from '@/services/CssEditor/domain/model/Theme'

export enum ActionTypes {
  GET_THEME = 'get_theme',
  GET_THEME_BY_GUID = 'get_theme_by_guid',
  CREATE_THEME = 'create_theme',
  UPDATE_THEME = 'update_theme',
  DELETE_THEME = 'delete_theme'
}

export class GetThemes implements FluxStandardAction {
  type = 'Theme/' + ActionTypes.GET_THEME
  constructor (public payload: object) {}
}

export class GetThemeByGuid implements FluxStandardAction {
  type = 'Theme/' + ActionTypes.GET_THEME_BY_GUID
  constructor (public guid: string) {}
}

export class CreateTheme implements FluxStandardAction {
  type = 'Theme/' + ActionTypes.CREATE_THEME
  constructor (public payload: ThemeDTO) {}
}

export class UpdateTheme implements FluxStandardAction {
  type = 'Theme/' + ActionTypes.UPDATE_THEME
  constructor (public payload: ThemeDTO) {}
}

export class DeleteTheme implements FluxStandardAction {
  type = 'Theme/' + ActionTypes.DELETE_THEME
  constructor (public guid: string) {}
}
