import { ActionTree } from 'vuex'
import { PluginState, initialState } from '@/services/LogicEditor/infrastructure/store/modules/Plugin/state'
import { ActionTypes } from './action-types'
import { MutationTypes, PluginLoadingEvent, PluginLoadedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { PluginAPI } from '@/services/LogicEditor/infrastructure/api/PluginAPI'

export const actions: ActionTree<PluginState, any> = {
  [ActionTypes.GET_PLUGIN]: async ({ commit, dispatch }, data) => {
    commit(new PluginLoadingEvent())

    return APIClient.shared.request(new PluginAPI.GetPlugins())
      .then((response) => {
        commit(new PluginLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  }
}
