import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { GroupDTO } from '@/services/EtlEditor/domain/model/Group'

export namespace GroupAPI {
  export class GetGroupByGuid implements APIRequest<GroupDTO> {
    response: GroupDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/etleditor/groups/${guid}`
    }
  }

  export class CreateGroup implements APIRequest<string> {
    response: string;
    path = '/v2/etleditor/groups';
    method = HTTPMethod.POST;
    parse = (data: AxiosResponse) => data.headers.location
    constructor (public params: GroupDTO) {}
  }

  export class UpdateGroup implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.PUT;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: GroupDTO) {
      this.path = `/v2/etleditor/groups/${params.guid}`
    }
  }

  export class DeleteGroup implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.DELETE;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/etleditor/groups/${guid}`
    }
  }
}
