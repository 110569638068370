import { ActionTree } from 'vuex'
import { ConfigurationElementState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, ConfigurationElementLoadingEvent, ConfigurationElementLoadedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import {
  ConfigurationElementAPI
} from '@/services/SyncService/infrastructure/api/ConfigurationElementAPI'

export const actions: ActionTree<ConfigurationElementState, any> = {
  [ActionTypes.GET_EDITOR_ENTITIES]: async ({ commit, dispatch }, data) => {
    try {
      commit(new ConfigurationElementLoadingEvent())
      let res = await APIClient.shared.request(new ConfigurationElementAPI.GetEditorEntities(data.payload))
      commit(new ConfigurationElementLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.EXPORT]: async ({ commit, dispatch }, data) => {
    try {
      commit(new ConfigurationElementLoadingEvent())
      let res = await APIClient.shared.request(new ConfigurationElementAPI.ConfigurationElementsExport(data.payload))
      commit(new ConfigurationElementLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  }
}
