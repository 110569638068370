import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    MODEL_LOADING_EVENT = 'model_loading_event',
    MODEL_LOADED_EVENT = 'model_loaded_event',
    MODEL_UPDATED_EVENT = 'model_updated_event'
}

export class ModelLoadingEvent implements FluxStandardAction {
    type = MutationTypes.MODEL_LOADING_EVENT
}

export class ModelLoadedEvent implements FluxStandardAction {
    type = MutationTypes.MODEL_LOADED_EVENT
}

export class ModelUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.MODEL_UPDATED_EVENT
}
