import { ActionTree } from 'vuex'
import { RasterState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, RasterLoadingEvent, RasterLoadedEvent, RasterCreatedEvent, RasterUpdatedEvent, RasterDeletedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { RasterAPI } from '@/services/RasterLibrary/infrastructure/api/RasterAPI'

export const actions: ActionTree<RasterState, any> = {
    [ActionTypes.ADD_RASTER]: async ({ commit, dispatch }, data) => {
        try {
            commit(new RasterLoadingEvent());
            let location = await APIClient.shared.request(new RasterAPI.AddRaster(data.payload));
            commit(new RasterCreatedEvent(location));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.UPDATE_RASTER]: async ({ commit, dispatch }, data) => {
        try {
            commit(new RasterLoadingEvent());
            let location = await APIClient.shared.request(new RasterAPI.UpdateRaster(data.payload));
            commit(new RasterUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.DELETE_RASTER]: async ({ commit, dispatch }, data) => {
        try {
            await APIClient.shared.request(new RasterAPI.DeleteRaster(data.guid, data.payload));
            commit(new RasterDeletedEvent(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }         
    },
    [ActionTypes.GET_RASTERS]: async ({ commit, dispatch }, data) => {
        try {
            commit(new RasterLoadingEvent());
            let res = await APIClient.shared.request(new RasterAPI.GetRasters(data.payload));
            commit(new RasterLoadedEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.GET_RASTER_BY_GUID]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new RasterAPI.GetRasterByGuid(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    },
    [ActionTypes.COUNT_RASTERS]: async ({ commit, dispatch }, data) => {
        try {
            commit(new RasterLoadingEvent());
            let res = await APIClient.shared.request(new RasterAPI.CountRasters(data.payload));
            commit(new RasterLoadingEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
}
