import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import PackageCommandRepositoryInterface from '../../../domain/repository/PackageCommandRepositoryInterface'
import PackageFileDownloadCommand from '../../command/PackageFileDownloadCommand'

export default class PackageFileDownloadHandler implements QueryHandlerInterface
{
    private repo: PackageCommandRepositoryInterface;

    constructor(repo: PackageCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: PackageFileDownloadCommand): Promise<any>
    {
        return this.repo.download(command.getTopic(), command.getGuid(), command.getFileName());
    }
}
