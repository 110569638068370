<template>
  <registry-select-tree
    style="width: 100%;"
    type="field"
    value-as="number"
    v-bind:value="value"
    v-on:input="$emit('input', $event)"
    :parent-id="objectId"
    :placeholder="placeholder">
  </registry-select-tree>
</template>

<script>
import RegistrySelectTree from '@/components/Common/RegistrySelectTree'

export default {
  name: 'CurrentTableField',

  props: {
    value: Number,

    placeholder: {
      type: String,
      default: 'Current Table Field'
    },

    objectId: {
      type: Number,
      required: true
    }
  },

  components: {
    RegistrySelectTree
  }
}
</script>

<style scoped>

</style>
