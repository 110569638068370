import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { ModelDTO } from '@/services/DigitalTwinEditor/domain/model/Model/Model'

export enum ActionTypes {
    UPDATE_MODEL = 'update_model',
    GET_MODEL_BY_GUID = 'get_model_by_guid'
}

export class UpdateModel implements FluxStandardAction {
    type = 'Model/' + ActionTypes.UPDATE_MODEL
    constructor(public payload: ModelDTO){}
}

export class GetModelByGuid implements FluxStandardAction {
    type = 'Model/' + ActionTypes.GET_MODEL_BY_GUID
    constructor(public guid: string){}
}
