import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { ConstraintDTO } from '@/services/LogicEditor/domain/model/Constraint'

export namespace ConstraintAPI {
  export class GetConstraintByGuid implements APIRequest<ConstraintDTO> {
    response: ConstraintDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => new ConstraintDTO(data.data)
    constructor (guid: string) {
      this.path = `/v2/logiceditor/constraints/${guid}`
    }
  }

  export class GetConstraintTypes implements APIRequest<object[]> {
    response: object[];
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor () {
      this.path = `/v2/logiceditor/constraint_types`
    }
  }

  export class CreateConstraint implements APIRequest<string> {
    response: string;
    path = '/v2/logiceditor/constraints';
    method = HTTPMethod.POST;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.headers.location
    constructor (public params: ConstraintDTO) {}
  }

  export class UpdateConstraint implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.PUT;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: ConstraintDTO) {
      this.path = `/v2/logiceditor/constraints/${params.guid}`
    }
  }

  export class DeleteConstraint implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.DELETE;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/logiceditor/constraints/${guid}`
    }
  }
}
