<template>
  <el-input v-bind:value="value" v-on:input="$emit('input', $event)" :placeholder="placeholder" type="textarea" :rows="1"></el-input>
</template>

<script>
export default {
  name: 'Sql',

  props: {
    value: String,

    placeholder: {
      type: String,
      default: 'Sql'
    }
  }
}
</script>

<style scoped>

</style>
