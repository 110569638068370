import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ModelGroupCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/ModelGroupCommandRepositoryInterface'
import ModelGroupUpdateCommand from '@/services/DigitalTwinEditor/application/command/ModelGroupUpdateCommand'
import { ModelGroupDTO } from '@/services/DigitalTwinEditor/domain/model/Model/ModelGroup'

export default class ModelGroupUpdateHandler implements CommandHandlerInterface
{
    private repo: ModelGroupCommandRepositoryInterface;

    constructor(repo: ModelGroupCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: ModelGroupUpdateCommand): Promise<void>
    {
        let item: ModelGroupDTO = {
            guid: command.getGuid(),
            name: command.getName(),
            alias: command.getAlias(),
            parent_id: command.getParentId()
        };
        return this.repo.update(item);
    }
}