import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import FileCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/FileCommandRepositoryInterface'
import AttachFileToModelCommand from '@/services/DigitalTwinEditor/application/command/AttachFileToModelCommand'
import { ModelFileDTO } from '@/services/DigitalTwinEditor/domain/model/File/ModelFile'

export default class AttachFileToModelHandler implements CommandHandlerInterface
{
    private repo: FileCommandRepositoryInterface;

    constructor(repo: FileCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: AttachFileToModelCommand): Promise<void>
    {
        const item: ModelFileDTO = {
            model_id: command.getModelId(),
            name: command.getName(),
            file: command.getFile()
        };
        return this.repo.insert(item);
    }
}