import { ActionTree } from 'vuex'
import { MappingState, initialState } from '@/services/EtlEditor/infrastructure/store/modules/Mapping/state'
import { ActionTypes } from './action-types'
import { MutationTypes, MappingUpdatedEvent, MappingCreatedEvent, MappingLoadingEvent, MappingDeletedEvent, MappingLoadedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { MappingAPI } from '@/services/EtlEditor/infrastructure/api/MappingAPI'

export const actions: ActionTree<MappingState, any> = {
  [ActionTypes.GET_MAPPING]: async ({ commit, dispatch }, data) => {
    try {
      commit(new MappingLoadingEvent())
      let response = await APIClient.shared.request(new MappingAPI.GetMappings(data.payload))
      commit(new MappingLoadedEvent())
      return response
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_MAPPING_BY_GUID]: async ({ commit, dispatch }, data) => {
    try {
      return await APIClient.shared.request(new MappingAPI.GetMappingByGuid(data.guid))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_MAPPING_ELEMENT_TYPES]: async ({ commit, dispatch }, data) => {
    try {
      return await APIClient.shared.request(new MappingAPI.GetMappingElementTypes())
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.CREATE_MAPPING]: async ({ commit, dispatch }, data) => {
    try {
      commit(new MappingLoadingEvent())
      let location = await APIClient.shared.request(new MappingAPI.CreateMapping(data.payload))
      commit(new MappingCreatedEvent(location))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.UPDATE_MAPPING]: async ({ commit, dispatch }, data) => {
    try {
      commit(new MappingLoadingEvent())
      let location = await APIClient.shared.request(new MappingAPI.UpdateMapping(data.payload))
      commit(new MappingUpdatedEvent(location))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.DELETE_MAPPING]: async ({ commit, dispatch }, data) => {
    try {
      await APIClient.shared.request(new MappingAPI.DeleteMapping(data.guid))
      commit(new MappingDeletedEvent(data.guid))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  }
}
