import { FluxStandardAction } from '@/core/domain/type/types'

export enum ActionTypes {
  GET_PLUGIN = 'get_plugin'
}

export class GetPlugins implements FluxStandardAction {
  type = 'Plugin/' + ActionTypes.GET_PLUGIN
  constructor () {}
}
