<template>
    <div>
        <el-button type="text" @click="dialogSettingsVisible = true">{{ $locale.main.button.edit }}</el-button>

        <el-dialog :title="$locale.interface_editor.registry_headers.title"
                   :modal="false"
                   :visible.sync="dialogSettingsVisible" :width="size">
            <html-interface-designer
                    :style-html="this.otherProperties[this.options.style]"
                    :width="otherProperties.listWidth || options.width"
                    :height="options.height"
                    :initial-blocks="value"
                    @save="save"
                ></html-interface-designer>
        </el-dialog>
    </div>
</template>

<script>
import HtmlInterfaceDesigner from '../basic/HtmlInterfaceDesigner'
export default {
  name: 'editor-html-design',
  components: {
    HtmlInterfaceDesigner
  },
  props: {
    value: {
      type: Array,
      default: () => {
        return []
      }
    },
    otherProperties: {
      type: Object
    },
    options: {
      type: Object,
      default: () => {
        return {
          width: 100,
          height: 100
        }
      }
    }
  },
  computed: {
    size () {
      let width = (this.otherProperties.listWidth || this.options.width) + 500
      return `${width}px`
    }
  },
  data () {
    return {
      dialogSettingsVisible: false
    }
  },
  methods: {
    save (blocks) {
      this.$emit('change', blocks)
    }
  }
}
</script>

<style scoped>

</style>
