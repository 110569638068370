import { FluxStandardAction } from '@/core/domain/type/types'
import { ProjectDTO } from '@/services/MapEditor/domain/model/Project/Project'

export enum MutationTypes {
    ERROR = 'error',
    PROJECT_LOADING_EVENT = 'project_loading_event',
    PROJECT_LOADED_EVENT = 'project_loaded_event',
    PROJECT_UPDATED_EVENT = 'project_updated_event',
    PROJECT_CREATED_EVENT = 'project_created_event',
    PROJECT_DELETED_EVENT = 'project_deleted_event'  
}

export class ProjectLoadingEvent implements FluxStandardAction {
    type = MutationTypes.PROJECT_LOADING_EVENT
}

export class ProjectLoadedEvent implements FluxStandardAction {
    type = MutationTypes.PROJECT_LOADED_EVENT
}

export class ProjectCreatedEvent implements FluxStandardAction {
    type = MutationTypes.PROJECT_CREATED_EVENT
    constructor(public location: string){}
}

export class ProjectUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.PROJECT_UPDATED_EVENT
}

export class ProjectDeletedEvent implements FluxStandardAction {
    type = MutationTypes.PROJECT_DELETED_EVENT
    constructor(public guid: string){}
}