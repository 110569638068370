import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class ConditionFieldTypeDTO {
  id?: Nullable<string>
  name?: Nullable<string>

  constructor ({ id, name }) {
    this.id = id
    this.name = name
  }
}

export class StateFieldTypeDTO {
  id?: Nullable<string>
  name?: Nullable<string>

  constructor ({ id, name }) {
    this.id = id
    this.name = name
  }
}

export class StateOperationTypeDTO {
  id?: Nullable<string>
  name?: Nullable<string>

  constructor ({ id, name }) {
    this.id = id
    this.name = name
  }
}

export interface IStateFieldProperties {
  name?: Nullable<string>
  value?: Nullable<any>
  field_id?: Nullable<string|number>
  registry_id?: Nullable<number>
  sql?: Nullable<string>
}

export class StateFieldDTO {
  id?: Nullable<number>
  guid?: Nullable<string>
  field_id: Nullable<number>
  state_field_type_id: Nullable<string>
  condition_field_type_id: Nullable<number>
  users_field_id: Nullable<number>
  current_table_field_id: Nullable<number>
  properties: Nullable<IStateFieldProperties>
  is_parameter?: Nullable<boolean>

  constructor (
    { id, guid, field_id, state_field_type_id, condition_field_type_id, users_field_id, current_table_field_id, is_parameter, properties } : {
      id?: Nullable<number>,
      guid?: Nullable<string>,
      field_id: Nullable<number>,
      state_field_type_id: Nullable<string>,
      condition_field_type_id: Nullable<number>,
      users_field_id: Nullable<number>,
      current_table_field_id: Nullable<number>,
      properties: Nullable<IStateFieldProperties>,
      is_parameter?: Nullable<boolean>
    }
  ) {
    this.id = id
    this.guid = guid
    this.field_id = field_id
    this.state_field_type_id = state_field_type_id
    this.condition_field_type_id = condition_field_type_id
    this.users_field_id = users_field_id
    this.current_table_field_id = current_table_field_id
    this.properties = properties
    this.is_parameter = is_parameter
  }

  static create (): StateFieldDTO {
    return new StateFieldDTO({
      field_id: null,
      state_field_type_id: null,
      condition_field_type_id: null,
      users_field_id: null,
      current_table_field_id: null,
      properties: {
        name: null,
        value: null,
        field_id: null,
        registry_id: null,
        sql: null
      },
      is_parameter: false
    })
  }
}

export class StateDTO {
  id?: Nullable<number>
  guid?: Nullable<string>
  name: Nullable<string>
  description: Nullable<string>
  is_complex: Nullable<boolean>
  properties: Nullable<object>
  state_fields: Nullable<Array<StateFieldDTO>>
  alias: Nullable<string>
  logic_id?: Nullable<number>
  parent_tree_element_id?: Nullable<number>

  constructor ({ id, guid, name, description, is_complex, properties, state_fields, alias, logic_id, parent_tree_element_id }) {
    this.id = id
    this.guid = guid
    this.name = name
    this.description = description
    this.is_complex = is_complex
    this.properties = properties
    if (state_fields && Array.isArray(state_fields)) {
      this.state_fields = state_fields.map(item => new StateFieldDTO(item))
    } else {
      this.state_fields = []
    }
    this.logic_id = logic_id
    this.alias = alias
    this.parent_tree_element_id = parent_tree_element_id
  }

  static create (): StateDTO {
    return new StateDTO({
      id: null,
      guid: null,
      name: null,
      description: null,
      is_complex: false,
      state_fields: [],
      properties: { filters: { children: [], logical_operator: 'and' } },
      alias: null,
      logic_id: null,
      parent_tree_element_id: null
    })
  }
}

export default class State extends AbstractBaseEntity {
  private id?: Nullable<number>
  private guid?: Nullable<string>
  private name: string
  private description: Nullable<string>
  private isComplex: boolean
  private properties: Nullable<object>
  private stateFields: Array<StateFieldDTO>
  private logicId: number
  private alias: Nullable<string>
  private parentTreeElementId?: Nullable<number>

  constructor (
    name: string,
    description: Nullable<string>,
    isComplex: boolean,
    stateFields: Array<StateFieldDTO>,
    properties: Nullable<object>,
    logicId: number,
    alias: Nullable<string>,
    parentTreeElementId?: Nullable<number>,
    id?: Nullable<number>,
    guid?: Nullable<string>
  ) {
    super()
    this.id = id
    this.guid = guid
    this.name = name
    this.description = description
    this.isComplex = isComplex
    this.properties = properties
    this.stateFields = stateFields
    this.alias = alias
    this.logicId = logicId
    this.parentTreeElementId = parentTreeElementId
  }

  static create (dto: StateDTO): State {
    return new State(
      dto.name,
      dto.description,
      dto.is_complex,
      dto.state_fields,
      dto.properties,
      dto.logic_id,
      dto.alias,
      dto.parent_tree_element_id,
      dto.id,
      dto.guid
    )
  }

  setId (id: number): void {
    this.id = id
  }

  getId (): number {
    return this.id
  }

  setGuid (guid: string): void {
    this.guid = guid
  }

  getGuid (): string {
    return this.guid
  }

  getName (): string {
    return this.name
  }

  setName (name: string): void {
    this.name = name
  }

  getDescription (): string {
    return this.description
  }

  setDescription (description: string): void {
    this.description = description
  }

  getIsComplex (): boolean {
    return this.isComplex
  }

  setIsComplex (isComplex: boolean): void {
    this.isComplex = isComplex
  }

  getProperties (): Nullable<object> {
    return this.properties
  }

  setProperties (properties?: Nullable<object>): void {
    this.properties = properties
  }

  getStateFields (): Array<StateFieldDTO> {
    return this.stateFields
  }

  setStateFields (stateFields: Array<StateFieldDTO>): void {
    this.stateFields = stateFields
  }

  getLogicId (): number {
    return this.logicId
  }

  setLogicId (logicId: number): void {
    this.logicId = logicId
  }

  getParentTreeElementId (): Nullable<number> {
    return this.parentTreeElementId
  }

  setParentTreeElementId (parentTreeElementId?: Nullable<number>): void {
    this.parentTreeElementId = parentTreeElementId
  }

  getAlias (): Nullable<string> {
    return this.alias
  }

  setAlias (alias: Nullable<string>): void {
    this.alias = alias
  }
}
