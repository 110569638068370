import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { StrokeStyleDTO } from '@/core/domain/model/modules/StrokeStyle'

export namespace StrokeStyleAPI {

    export class AddStrokeStyle implements APIRequest<string> {
        response: string;
        path = '/mapeditor/stroke_styles';
        method = HTTPMethod.POST;
        hasFiles = true;
        parse = (data: AxiosResponse) => data.headers.location
        constructor (public params: StrokeStyleDTO) {}
    }

    export class UpdateStrokeStyle implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        hasFiles = true;
        parse = (data: AxiosResponse) => data.data
        constructor (public params: StrokeStyleDTO) {
          this.path = `/mapeditor/stroke_styles/${params.guid}`
        }
    }

    export class DeleteStrokeStyle implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.DELETE;
        parse = (data: AxiosResponse) => data.data
        constructor (guid: string) {
          this.path = `/mapeditor/stroke_styles/${guid}`
        }
    }

    export class GetStrokeStyles implements APIRequest<StrokeStyleDTO> {
        response: StrokeStyleDTO;
        path: string;
        method = HTTPMethod.GET;
        hideNotification = true;
        parse = (data: AxiosResponse) => data.data
        constructor (payload: string[][]) {
          this.path = '/mapeditor/stroke_styles?' + (new URLSearchParams(payload).toString())
        }
    }

    export class GetStrokeStyleByGuid implements APIRequest<StrokeStyleDTO> {
        response: StrokeStyleDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor (guid: string) {
          this.path = `/mapeditor/stroke_styles/${guid}`
        }
    }
}
