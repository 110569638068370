export default {
  methods: {
    checkConditions (data, sourceRegistry = false, fields = {}) {
      if (!data || data.type === 'never') {
        return false
      } else if (data.type === 'always') {
        return true
      }
      let result = false
      data.conditions.forEach((condition) => {
        if (!condition.attribute || !condition.type) {
          return false
        }
        let conditionResult = false
        let attribute
        if (sourceRegistry) {
          attribute = fields[`attr_${condition.attribute}_`]
        } else {
          attribute = this.getModel()[condition.attribute]
        }
        try {
          attribute = JSON.parse(attribute)
        } catch (e) {

        }
        if (attribute instanceof Array) {
          attribute = attribute.map(item => item.id || item).join('|||')
        }
        let value = condition.value
        if (value === 'user_id') {
          value = this.$store.getters['Authorization/userId']
        }
        if (value === 'role_id') {
          value = this.$store.getters['Authorization/roleId']
        }
        switch (condition.type) {
          case 'contains_in_array':
            let attrArray = (attribute || '').toString().split('|||')
            conditionResult = attrArray.includes(value.toString())
            break

          case 'not_contains_in_array':
            let _attrArray = (attribute || '').toString().split('|||')
            conditionResult = !_attrArray.includes(value.toString())
            break
          case 'contains':
            const _attr = (attribute || '').toString().split('|||')
            _attr.forEach((item) => {
              if (item.indexOf(value) !== -1) {
                conditionResult = true
              }
            })
            break
          case 'not_contains':
            let attr = attribute + ''
            let valueArray = value + ''
            if (attr?.length && valueArray?.length) {
              let arrAttr = attr.split(',')
              let arrvalue = valueArray.split(',')
              // сравнить элементы двух массивов
              conditionResult = !arrAttr.some(el => arrvalue.includes(el))
            }
            break
          case 'equals':
            if (attribute == value) {
              conditionResult = true
            }
            break
          case 'not_equals':
            if (attribute != value) {
              conditionResult = true
            }
            break
          case 'is_null':
            if (
              attribute === null ||
              typeof attribute === 'undefined' ||
              attribute === ''
            ) {
              conditionResult = true
            }
            break
          case 'is_not_null':
            if (
              attribute !== null &&
              typeof attribute !== 'undefined' &&
              attribute !== ''
            ) {
              conditionResult = true
            }
            break
          default:
            break
        }
        condition.result = conditionResult
      })

      if (data.condition_type === 'and') {
        if (data.conditions.length === data.conditions.filter(item => item.result).length) {
          result = true
        }
      } else if (data.condition_type === 'or') {
        if (data.conditions.find(item => item.result)) {
          result = true
        }
      }

      return result
    }
  }
}
