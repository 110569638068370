import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { PackageDTO } from '@/services/Databus/domain/model/Package/Package'

export namespace PackageAPI {

    export class CountPackages implements APIRequest<PackageDTO> {
        response: PackageDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/databusservice/packages?*[func]=count'
        }
    }

    export class GetPackages implements APIRequest<PackageDTO> {
        response: PackageDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/databusservice/packages?' +  (new URLSearchParams(payload).toString())
        }
    }

    export class DownloadPackage implements APIRequest<PackageDTO> {
        response: PackageDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data;
        blob = true;
        fileName: string;
        constructor(topic: string, guid: string, fileName: string) {
            this.fileName = fileName;
            this.path = `/databusservice/packages/${guid}?topic=` + topic;
        }
    }
}