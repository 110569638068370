import { ActionTree } from 'vuex'
import { PointStyleImageState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, PointStyleImageLoadingEvent, PointStyleImageLoadedEvent, PointStyleImageCreatedEvent, PointStyleImageUpdatedEvent, PointStyleImageDeletedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { PointStyleImageAPI } from '@/core/infrastructure/api/modules/PointStyleImageAPI'

export const actions: ActionTree<PointStyleImageState, any> = {
    [ActionTypes.ADD_POINT_STYLE_IMAGE]: async ({ commit, dispatch }, data) => {
        try {
            commit(new PointStyleImageLoadingEvent());
            let location = await APIClient.shared.request(new PointStyleImageAPI.AddPointStyleImage(data.payload));
            commit(new PointStyleImageCreatedEvent(location));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.UPDATE_POINT_STYLE_IMAGE]: async ({ commit, dispatch }, data) => {
        try {
            commit(new PointStyleImageLoadingEvent());
            let location = await APIClient.shared.request(new PointStyleImageAPI.UpdatePointStyleImage(data.payload));
            commit(new PointStyleImageUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.DELETE_POINT_STYLE_IMAGE]: async ({ commit, dispatch }, data) => {
        try {
            await APIClient.shared.request(new PointStyleImageAPI.DeletePointStyleImage(data.guid));
            commit(new PointStyleImageDeletedEvent(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }         
    },
    [ActionTypes.GET_POINT_STYLE_IMAGES]: async ({ commit, dispatch }, data) => {
        try {
            commit(new PointStyleImageLoadingEvent());
            let res = await APIClient.shared.request(new PointStyleImageAPI.GetPointStyleImages(data.payload));
            commit(new PointStyleImageLoadedEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.GET_POINT_STYLE_IMAGE_BY_GUID]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new PointStyleImageAPI.GetPointStyleImageByGuid(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    }
}
