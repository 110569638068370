<script type="ts">
import MessageBox from 'element-ui/packages/message-box/src/main.vue'

export default {
  extends: MessageBox,
  name: 'ElErrorMessageBox',
  data() {
    return {
      type: 'warning'
    }
  }
}

</script>