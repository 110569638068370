import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { FormulaDTO } from '@/services/LogicEditor/domain/model/Formula'
import { AxiosResponse } from 'axios'

export namespace LogicEditorAPI {
  export class GetFormulaByGuid implements APIRequest<FormulaDTO> {
    response: FormulaDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/logiceditor/formulas/${guid}`
    }
  }

  export class GetFormulaById implements APIRequest<FormulaDTO> {
    response: FormulaDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (id: number) {
      this.path = `/v2/logiceditor/formulas/${id}`
    }
  }

  export class CreateFormula implements APIRequest<string> {
    response: string;
    path = '/v2/logiceditor/formulas';
    method = HTTPMethod.POST;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.headers.location
    // eslint-disable-next-line no-useless-constructor
    constructor (public params: FormulaDTO) {}
  }

  export class UpdateFormula implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.PUT;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: FormulaDTO) {
      this.path = `/v2/logiceditor/formulas/${params.guid}`
    }
  }

  export class GetStates implements APIRequest<object[]> {
    path: string;
    response: object[];
    method = HTTPMethod.GET;

    parse = (response) => {
      return response.data
    }

    constructor (params: string[][] = []) {
      this.path = `/v2/logiceditor/states?${new URLSearchParams(params).toString()}`
    }
  }

  export class GetLogicByRegistryId implements APIRequest<object[]> {
    path: string;
    response: object[];
    method = HTTPMethod.GET;

    parse = (response) => {
      return response.data[0] || null
    }

    constructor (registryId: number) {
      this.path = `/v2/logiceditor/logic?entity_id=${registryId}`
    }
  }
}
