import Vue from 'vue'

export default Vue.extend({
  template: `
  <el-row class="ag-simple-filter-body-wrapper" style="height: 40px;display: flex;align-items: center;justify-content: center;">
    <select style="width: 100%;" v-model="value" placeholder="Выбрать" @change="toggleFilter($event)">
      <option
        v-for="item in types"
        :key="item.type"
        :label="item.name"
        :value="item.type"
      ></option>
    </select>
  </el-row>
  `,
  name: 'booleanFilter',
  data: function () {
    return {
      value: null,
      isActive: false,
      types: [
        {
          'type': '1',
          'name': 'Да'
        },
        {
          'type': '0',
          'name': 'Нет'
        },
        {
          'type': null,
          'name': 'Без фильтра'
        }
      ]
    }
  },
  methods: {
    toggleFilter (event) {
      this.value = event?.target?.value || null
      this.params.filterChangedCallback()
    },

    isFilterActive () {
      if (this.value >= 0) {
        return true
      }
      return false
    },
    getModel () {
      return {
        filterType: 'boolean',
        type: this.isFilterActive() ? 'active' : 'non-active',
        value: this.value
      }
    },
    setModel (model) {
      this.toggleFilter(model)
    },
    onFloatingFilterChanged (value) {
      this.setModel(value)
    }
  }
})
