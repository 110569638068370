import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    TRANSFORMER_LOADING_EVENT = 'transformer_loading_event',
    TRANSFORMER_LOADED_EVENT = 'transformer_loaded_event',
    TRANSFORMER_CREATED_EVENT = 'transformer_created_event',
    TRANSFORMER_UPDATED_EVENT = 'transformer_updated_event',
    TRANSFORMER_DELETED_EVENT = 'transformer_deleted_event'
}

export class TransformerLoadingEvent implements FluxStandardAction {
  type = MutationTypes.TRANSFORMER_LOADING_EVENT
}

export class TransformerLoadedEvent implements FluxStandardAction {
  type = MutationTypes.TRANSFORMER_LOADED_EVENT
}

export class TransformerCreatedEvent implements FluxStandardAction {
  type = MutationTypes.TRANSFORMER_CREATED_EVENT
  constructor (public location: string) {}
}

export class TransformerUpdatedEvent implements FluxStandardAction {
  type = MutationTypes.TRANSFORMER_UPDATED_EVENT
  constructor (public location: string) {}
}

export class TransformerDeletedEvent implements FluxStandardAction {
  type = MutationTypes.TRANSFORMER_DELETED_EVENT
  constructor (public guid: string) {}
}
