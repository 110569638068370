import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'

export namespace HistoryAPI {

  export class GetByRecord implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (registryId: number, recordId: number) {
      this.path = `/historyservice/objects/${registryId}/records/${recordId}`
    }
  }
}
