<template style="height: 100%">
  <el-container class="registry_table" style="height: 100%;">
    <import-log ref="log" :object-id="id" :show="logDialog" :close="closeLog"></import-log>
    <el-dialog :title="$locale.registry.toolbar.import" :visible.sync="dialogFormVisible" width="30%" append-to-body>
      <el-form size="small">
        <el-form-item :label="$locale.registry.toolbar.select_file">
          <el-upload
            ref="importUpload"
            action="#"
            :headers="getHeaders()"
            :limit="1"
            :multiple="false"
            :auto-upload="false"
            :show-message="false"
            class="etl-upload">
            <el-button slot="trigger" type="primary">{{$locale.registry.toolbar.btn_select}}</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible = false">{{$locale.registry.toolbar.btn_cancel}}</el-button>
        <el-button size="small" type="primary" @click="importSubmit()">{{$locale.registry.toolbar.btn_import}}</el-button>
      </span>
    </el-dialog>
    <el-header v-show="!hiddenHeader">
      <el-row class="toolbar">
        <el-col :span="14">
          <el-button v-show="!showButton.update" @click="loadData" size="small" plain icon="el-icon-refresh-right">
            {{ mini ? '' : (width > 895 ? $locale.main.button.update : '')}}
          </el-button>
          <el-button v-show="!showButton.add" v-if="!readonly" @click="initAddRecord" plain size="small" icon="el-icon-plus">
            {{ mini ? '' : (width > 1060 ? $locale.main.button.add : '')}}
          </el-button>
          <el-button v-show="!showButton.add_existing && isShowInitAddExisting" v-if="!readonly" @click="initAddExisting()" plain size="small" icon="el-icon-finished">
            {{ mini ? '' : (width > 1060 ? $locale.main.button.add_existing : '')}}
          </el-button>
          <el-button v-show="!showButton.delete" v-if="!readonly" @click="deleteRecord(selected)" size="small" type="danger" plain icon="el-icon-delete">
            {{ mini ? '' : (width > 1310 ? $locale.main.button.delete : '')}}
          </el-button>
          <el-button v-show="!showButton.detach && isShowDetach" v-if="!readonly" @click="detachRecord(selected)" size="small" type="danger" plain icon="el-icon-scissors">
            {{ mini ? '' : (width > 1310 ? $locale.main.button.detach : '')}}
          </el-button>
          <template>
            <el-dropdown :hide-on-click="false" v-show="!showButton.export">
              <el-button type="text">
                <i class="el-icon-upload2 el-icon&#45;&#45;left"></i>
                {{mini ? '' : (width > 1710 ? $locale.registry.toolbar.export : '')}}<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu class="custom-scrollbar" slot="dropdown">
                <el-dropdown-item>
                    <span class="el-dropdown-link" @click="defaultExport">
                      Экспорт текущего реестра
                    </span>
                </el-dropdown-item>
                <template v-if="exportTemplate.length > 0">
                  <el-dropdown-item v-for="(item, index) in exportTemplate" :key="item.task_id" :index="index" :divided="!index">
                    <span class="el-dropdown-link" @click="taskExport(item)">
                      {{item.task_name}}
                    </span>
                  </el-dropdown-item>
                </template>
                <template v-else>
                  <el-dropdown-item disabled divided>
                    <span class="el-dropdown-link">
                      {{$locale.registry.toolbar.template_empty}}
                    </span>
                  </el-dropdown-item>
                </template>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown :hide-on-click="false" v-show="!showButton.import">
              <el-button type="text">
                <i class="el-icon-download el-icon--left"></i>
                {{mini ? '' : (width > 1710 ? $locale.registry.toolbar.import : '')}}<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu class="custom-scrollbar" slot="dropdown">
                <template v-if="importTemplate.length > 0">
                  <el-dropdown-item v-for="(item, index) in importTemplate" :key="item.task_id" :index="index">
                    <span class="el-dropdown-link" @click="openImportDialog(item.task_id)">
                      {{item.task_name}}
                    </span>
                  </el-dropdown-item>
                </template>
                <template v-else>
                  <el-dropdown-item disabled>
                    <span class="el-dropdown-link">
                      {{$locale.registry.toolbar.template_empty}}
                    </span>
                  </el-dropdown-item>
                </template>
                <el-dropdown-item divided>
                  <span class="el-dropdown-link" @click="showImportLog()">
                    Лог импорта
                  </span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown @command="viewsHandler" v-show="!showButton.views">
              <span class="el-dropdown-link">
                <i class="el-icon-s-grid el-icon--left"></i><i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="a">Сохранить мой вид</el-dropdown-item>
                <el-dropdown-item command="b">Сбросить мой вид</el-dropdown-item>
                <el-dropdown-item command="c">Сохранить вид по умолчанию</el-dropdown-item>
                <el-dropdown-item command="d">Сбросить вид по умолчанию</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <el-dropdown @command="selectGroup" v-show="!showButton.group">
            <el-badge :hidden="!isGrouped" is-dot class="item"><span class="el-dropdown-link">
              <i class="el-icon-s-operation el-icon--left"></i>
              {{mini ? '' : (selectColumn ? selectColumn : $locale.registry.toolbar.group)}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span></el-badge>
              <el-dropdown-menu slot="dropdown">
                <el-scrollbar wrap-style="height: 200px;">
                  <el-dropdown-item command="a">{{ $locale.registry.toolbar.cancel_group}}</el-dropdown-item>
                  <el-dropdown-item :disabled="isGrouped" v-for="item in columns" :key="item.id" :command='item'>{{item.text}}</el-dropdown-item>
                </el-scrollbar>
              </el-dropdown-menu>
           </el-dropdown>
        </el-col>

                <el-col :span="6" style="text-align: right">
                    <el-input v-if="showSearch" v-model="searchModel" @clear="loadData"
                              @keyup.enter.native="loadData" clearable size="mini" width="200px"
                              :placeholder="$locale.main.fields.search"><i slot="prefix"
                                                                           class="el-input__icon el-icon-search"></i>
                    </el-input>
                </el-col>
                <el-col :span="4" style="float: right; text-align: end">
                    <el-popover
                            placement="bottom"
                            class="filter"
                            trigger="click">
                        <registry-filter key="registry-filter" @search="activeFilters = $event;loadData(true)"
                                         class="filter"
                                         :available_fields="filterFields" :registry_id="id"></registry-filter>
                        <template slot="reference">
                            <el-button v-show="!showButton.filter" type="text">
                                <el-badge :hidden="!activeFilters.length" is-dot class="item">
                                    {{ $locale.registry.filters }}
                                </el-badge>
                                <i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                        </template>
                    </el-popover>
                </el-col>
                <!-- </el-col> -->
            </el-row>
        </el-header>
        <el-table
                :indent="16"
                class="registry custom_scrollbar edit_column_btn"
                :class="{'borderNone' : showVerticalLine}"
                :row-class-name="tableRowClassName"
                lazy
                :load="loadTree"
                :tree-props="treeProps"
                v-loading="loading"
                :data="data.data"
                :key="isGrouped ? 1 : 2"
                :span-method="arraySpanMethod"
                stripe
                :header-cell-style="createdStyleHeader"
                border
                height="100%"
                :style="`width: 100%; ${CSS}`"
                ref="table"
                @selection-change="selectionChange"
                @header-dragend="changeWidth"
                @row-contextmenu="showContextMenu"
                @cell-click="cellClick"
                @row-dblclick="handleRowDblClick"
                row-key="guid"
                highlight-current-row
        >
            <template slot="empty">
                <span>Пусто</span>
            </template>
            <columns
                    :isGrouped="isGrouped"
                    @edit-record="$emit('edit-record', $event)"
                    @init-fast-edit="initFastEdit"
                    @update-layout="updateLayout"
                    :show-button="showButton"
                    @row-edit="rowEditHandler"
                    @update-editable-model="updateEditableModel"
                    :columns="columns"
                    :groupedAttributes="groupedAttributes"
                    :treeTable="treeTable"
            ></columns>
        </el-table>
        <el-pagination
                @current-change="handleCurrentChange"
                :page-size="pageLimitRegistry"
                layout="total, prev, pager, next"
                :total="data.count">
        </el-pagination>
    <slot></slot>
    </el-container>
</template>

<script>
import Registry from './Models/Registry'
import RegistryFilter from './Filter'
import Columns from './Columns.vue'
import FastEditor from '@/components/Registry/FastEditor'
import RegistryCard from '@/components/RegistryCard'
import ImportLog from '@/components/Registry/ImportLog'

import { APIClient } from '@/core/infrastructure/api/APIClient'

export default {
  name: 'RegistryTable',
  components: {
    RegistryFilter,
    Columns,
    RegistryCard,
    ImportLog
  },
  inject: {
    openRegistryCard: {
      default: () => {}
    },
    openTabModalWindow: {
      default: () => {}
    },
    _getModel: {
      from: 'getModel',
      default: () => () => {
        return {}
      }
    }
  },
  props: {
    readonly: {},
    headers: {},
    id: {},
    'outer-xref': {},
    xrefId: {},
    mini: {},
    'page-limit': {},
    'state-id': {},
    'default-group': {},
    'show-search': {},
    'search-attributes': {},
    'show-button': {},
    'show-vertical-line': {},
    'cell-click-plugin': {},
    rowDoubleClicked: {
      type: Boolean
    },
    styleTableHeader: {},
    'tree-table': {},
    'ignore-fast-card': {},
    'state-params': {},
    CSS: {},
    // родителеский компонент с фильтром (a-table)
    isDataFilter: {
      type: Boolean,
      default: false
    },
    type: {
      type: String
    },
    tableAddExisting: {
      type: Object
    },
    defaults: {
      type: Array
    }
  },
  data () {
    return {
      logDialog: false,
      dialogFormVisible: false,
      hiddenHeader: null,
      showHistory: false,
      historyRecordId: null,
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      formLabelWidth: '120px',
      data: {
        data: [],
        count: 0
      },
      selectColumn: this.$locale.registry.toolbar.group,
      groupedHeaders: [],
      loading: false,
      searchModel: null,
      isIncludingDeleted: null,
      fields: null,
      columns: this.headers || [],
      filterFields: [],
      selected: [],
      registry: null,
      activeFilters: [],
      additionalFilters: [],
      editableModel: {},
      width: window.innerWidth,
      initialData: [],
      isGrouped: false,
      groupedAttributes: {},
      currentPage: 0,
      numberColumns: [],
      pageLimitRegistry: 100,
      availableInputColumnTypes: [
        'string_field',
        'text_field',
        'integer_field',
        'float_field',
        'boolean_field',
        'time_field',
        'date_field',
        'datetime_field',
        'xref_field',
        'xref_multi_field'
      ],
      availableColumnTypes: [
        'string_field',
        'boolean_field',
        'string_field',
        'boolean_field',
        'date_field',
        'datetime_field',
        'float_field',
        'integer_field',
        'text_field',
        'xref_field',
        'xref_multi_field',
        'file_field'
      ],
      exportTemplate: [],
      importTemplate: [],
      useTemplate: null
    }
  },
  computed: {
    treeProps () {
      if (this.treeTable) {
        return { children: 'children', hasChildren: 'hasChildren' }
      }
      return {}
    },
    loadParams () {
      let filter = JSON.parse(JSON.stringify(this.activeFilters || []))
      filter = [...filter, ...JSON.parse(JSON.stringify(this.additionalFilters || []))]
      if (this.outerXref && this.outerXref.id && this.outerXref.value) {
        let xrefObject = JSON.parse(JSON.stringify(this.outerXref.value))
        if (Array.isArray(xrefObject) && xrefObject.length > 0 && xrefObject[0].hasOwnProperty('id')) {
          filter.push(`attr_${this.outerXref.id}_,eqx,${xrefObject[0].id}`)
        } else {
          filter.push(`attr_${this.outerXref.id}_,eqx,${this.outerXref.value}`)
        }
      }
      if (this.treeTable && this.registry.id && this.defaultGroup) {
        filter.push(`${this.defaultGroup},eq,[]`)
      }
      return {
        filter: filter,
        offset: this.currentPage,
        limit: this.pageLimitRegistry,
        state_id: this.stateId,
        state_params: this.stateParams,
        search_value: this.searchModel,
        search_fields: this.searchAttributes,
        isIncludingDeleted: this.isIncludingDeleted,
        fields: this.$parent?.settings?.optimizeOptions || this.$parent?.optimizeOptions?.value
      }
    },
    createdStyleHeader () {
      if (this.styleTableHeader) {
        const str = this.styleTableHeader
        const properties = str.split('; ')
        let style = properties.reduce((acc, item) => {
          acc[item.split(': ')[0]] = item.split(': ')[1]
          return acc
        }, {})
        return style
      } else {
        return ''
      }
    },
    isShowInitAddExisting () {
      return (this.outerXref && this.outerXref.is) || this.type === 'xref'
    },
    isShowDetach () {
      return (this.outerXref && this.outerXref.is) || this.type === 'xref'
    }
  },
  watch: {
    async id () {
      if (!this.id) {
        return false
      }
      this.registry = new Registry({ id: this.id })
      let me = this
      let structure = await this.registry.structure().first()
        .catch(() => {
          me.error = true
        })
      if (!structure) {
        return false
      }
      this.structure = structure
      this.renderFilter(this.structure.filter)
      this.loadData()
      this.$http
        .get(`${this.$config.api}/etleditor/objects/tasks?loader_object_id=${this.registry.id}&is_visible_in_registry=true`)
        .then(response => {
          me.importTemplate.push(...response.data)
        })
      this.$http
        .get(`${this.$config.api}/etleditor/objects/tasks?extractor_object_id=${this.registry.id}&is_visible_in_registry=true`)
        .then(response => {
          me.exportTemplate.push(...response.data)
        })
    },
    headers (value) {
      this.columns = value
    },
    showButton: {
      handler: function (val, oldVal) {
        let btnForHeader = { ...val }
        delete btnForHeader.edit
        if (!Object.values(btnForHeader).includes(false) && !this.showSearch) {
          this.hiddenHeader = true
        } else {
          this.hiddenHeader = false
        }
      },
      deep: true,
      immediate: true
    },
    pageLimit: {
      handler: function (val) {
        this.pageLimitRegistry = val || 100
      },
      immediate: true
    },
    treeTable: {
      handler: function (val) {
        this.loadData()
      }
    }
  },
  created () {
    window.addEventListener('resize', this.updateWidth, false)
  },
  async mounted () {
    this.loading = true
    if (!this.id) {
      this.error = true
      this.loading = false
      return false
    }
    this.registry = new Registry({ id: this.id })
    let me = this
    let structure = await this.registry.structure().first()
      .catch(() => {
        me.error = true
      })
    if (!structure) {
      return false
    }
    this.structure = structure
    this.name = structure.name
    let property = this.structure.properties.find((item) => item.id === 'page_rows_count')
    if (property && !this.pageLimit) {
      this.pageLimitRegistry = parseInt(property.value)
    } else {
      this.pageLimitRegistry = this.pageLimit
    }
    if (this.headers.length === 0) {
      this.renderColumns(this.structure.columns)
    }
    this.renderFilter(this.structure.filter)
    // потом loadData не нужный, когда есть родитель-компонент с фильтром
    if (!this.isDataFilter) {
      await this.loadData()
    }
    this.loading = false
    this.$refs.table && this.$refs.table.doLayout()
    this.$http
      .get(`${this.$config.api}/etleditor/objects/tasks?loader_object_id=${this.registry.id}&is_visible_in_registry=true`)
      .then(response => {
        me.importTemplate.push(...response.data)
      })
    this.$http
      .get(`${this.$config.api}/etleditor/objects/tasks?extractor_object_id=${this.registry.id}&is_visible_in_registry=true`)
      .then(response => {
        me.exportTemplate.push(...response.data)
      })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.updateWidth, false)
  },
  methods: {
    getRawData () {
      return this._getRawData(this)
    },
    getModel () {
      return this._getModel(this)
    },
    detachRecord (selectedRows) {
      if (!selectedRows.length) {
        this.$alert(this.$locale.registry.errors.not_selected, this.$locale.main.message.error, {
          confirmButtonText: this.$locale.main.button.ok
        })

        return false
      }

      if (this.type !== 'xref' && (!this.outerXref || !this.outerXref.id || !this.outerXref.value)) {
        this.$alert(this.$locale.registry.errors.not_selected, this.$locale.main.message.error, {
          confirmButtonText: this.$locale.main.button.ok
        })

        return false
      }

      let me = this
      this.$confirm(this.$locale.registry.message_detach.message, this.$locale.registry.message_detach.title, {
        confirmButtonText: this.$locale.main.button.ok,
        cancelButtonText: this.$locale.main.button.cancel,
        type: 'warning'
      }).then(() => {
        selectedRows.forEach(function (selectedRecord) {
          const recordIndex = me.data.data.findIndex((record) => record.id === selectedRecord.id)

          if (me.type === 'xref') {
            let modelXref = me.getModel()[me.xrefId] || []
            if (typeof modelXref === 'string') {
              try {
                modelXref = JSON.parse(modelXref)
              } catch (error) {
                console.error(error)
              }
            }
            if (Array.isArray(modelXref)) {
              modelXref = modelXref.map(xrefId => Number(xrefId.id || xrefId))
            }
            modelXref = modelXref.filter(item => item !== selectedRecord.id)
            me.$set(me.getModel(), me.xrefId, modelXref)
          } else {
            let data = {
              xrefId: me.outerXref.id,
              xrefValueId: me.outerXref.value
            }
            me.$http.put(`${me.$config.api}/registryservice/registry/${me.id}/records/${selectedRecord.id}/detach`, data, { hideNotification: true })
              .then(() => {
                me.$notify({
                  title: me.$locale.main.message.success,
                  message: me.$locale.main.message.deleted,
                  type: 'success'
                })
                me.data.data.splice(recordIndex, 1)
              })
              .catch(() => {
                me.$notify.error({
                  title: me.$locale.main.message.error,
                  message: me.$locale.main.message.not_saved
                })
              })
          }
        })
      })
    },
    showImportLog () {
      this.logDialog = true
      this.$refs.log.load()
    },
    closeLog () {
      this.logDialog = false
    },
    async loadTree (tree, treeNode, resolve) {
      let params = {
        filter: [`${this.defaultGroup},eqx,${tree.id}`],
        limit: this.pageLimitRegistry
      }
      let response = await this.registry.data().params(params).get()
      // this.data.count = response.count
      // let response = await this.$http
      //   .get(`${this.$config.api}/registryservice/registry/${this.registry.id}/data?filter[0]=${this.defaultGroup},eqx,${tree.id} `)
      response.data.forEach(item => {
        this.$set(item, 'children', [])
        this.$set(item, 'hasChildren', true)
      })
      resolve(response.data)
    },
    initAddRecord () {
      let quickAddCard = (this.structure.properties || []).find((item) => item.id === 'quick_add_card') || {}
      if (quickAddCard.value && quickAddCard.value.card_id && !this.ignoreFastCard) {
        const h = this.$createElement
        let customClass = 'custom_scrollbar '
        if (quickAddCard.value.width) {
          customClass += `window_width_${quickAddCard.value.width}`
        }
        let me = this
        let initialData = {}
        if (this.outerXref && this.outerXref.id && this.outerXref.value) {
          initialData[`attr_${this.outerXref.id}_`] = this.outerXref.value
        }

        let defaults = this.getDefaultsForCard()
        defaults.forEach((item) => {
          initialData[item.key] = item.value
        })

        this.$msgbox({
          customClass: customClass,
          message: h('registry-card', {
            style: {
              height: quickAddCard.value?.height || '400px'
            },
            props: {
              cardId: quickAddCard.value.card_id,
              registryId: this.registry.id,
              parentContext: null,
              model: {},
              quick: true,
              initialData: initialData
            },
            on: {
              'quick-add': async function (data) {
                let card = await me.getCard(data)
                if (card) {
                  me.openRegistryCard({
                    registryId: me.registry.id,
                    cardId: card.id,
                    cardName: card.name,
                    recordId: null,
                    initialData: data,
                    registry: me,
                    preventUserCard: true
                  })
                }
                me.$msgbox.close()
              },
              cancelChanges: function () {
                me.$msgbox.close()
              }
            },
            key: this.generateGuid()
          }),
          showCancelButton: false,
          showConfirmButton: false,
          closeOnClickModal: false
        })
      } else {
        this.$emit('open-card', null)
      }
    },
    getDefaultsForCard () {
      let defaults = []
      if (this.defaults) {
        this.defaults.forEach((item) => {
          if (!item.type || item.type === 'field') {
            if (this.getModel()[item.attribute] && item.alias) {
              defaults.push({
                key: item.alias,
                value: this.getModel()[item.attribute]
              })
            }
          } else if (item.type === 'constant' && item.alias) {
            defaults.push({
              key: item.alias,
              value: item.attribute
            })
          } else if (item.type === 'current_user') {
            defaults.push({
              key: item.alias,
              value: this.$store.getters['Authorization/userId']
            })
          }
        })
      }
      return defaults
    },
    async getCard (recordData = null) {
      let data = await this.$http.post(`${this.$config.api}/registryservice/registry/${this.registry.id}/card`,
        recordData, { hideNotification: true })
      return data.data[0]
    },
    cellClick (row, column, cell) {
      if (this.cellClickPlugin) {
        import(`@/plugins/${this.$config.project}/${this.cellClickPlugin}`)
          .then((Plugin) => {
            let instance = new Plugin.default(this)
            instance.execute(row, column, cell)
          }).catch(() => {
            console.log(`Plugin: ${this.cellClickPlugin} not found`)
          })
      }
    },
    getHeaders () {
      return {
        Authorization: localStorage.getItem('user-token')
      }
    },
    initFastEdit (data) {
      console.log(data, 'from table')
      const h = this.$createElement
      let columns = this.columns.filter(item => item.fixed).concat(data.children.map((item) => {
        if (item.isEdit) {
          item.editable = true
        }
        return item
      }).filter(item => item.editable))
      let records = []
      this.data.data.forEach((item) => {
        if (!item.attr_4046_) {
          return false
        }
        let record = {}
        record.id = item.id
        record.status = null
        columns.forEach((column) => {
          record[column.value] = item[column.value]
        })
        records.push(record)
      })

      this.$msgbox({
        title: `${this.$locale.interface_editor.registry_headers.title} - ${data.column.label}`,
        customClass: 'window_width_75',
        message: h(FastEditor, { props: { columns: columns, data: records }, key: this.generateGuid() }),
        showCancelButton: true,
        showConfirmButton: true,
        closeOnClickModal: false,
        confirmButtonText: this.$locale.main.button.save,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            records.filter(item => (item.status === 'edited' || item.status === 'error')).forEach((record) => {
              let recordData = JSON.parse(JSON.stringify(record))
              columns.forEach((column) => {
                if (!column.editable) {
                  delete recordData[column.value]
                }
              })
              this.$set(record, 'status', 'loading')
              this.$http.put(`${this.$config.api}/registryservice/registry/${this.id}/records/${record.id}`, recordData, { hideNotification: true })
                .then(() => {
                  this.$set(record, 'status', 'success')
                  this.updateRecord(record.id)
                })
                .catch(() => {
                  this.$set(record, 'status', 'error')
                })
            })
          } else {
            done()
          }
        }
      })
    },
    showContextMenu (row, column, event) {
      event.preventDefault()
      let recordId = row.id
      this.openTabModalWindow('History', { registryId: this.id, recordId: recordId })
      return false
      const h = this.$createElement
      this.$msgbox({
        customClass: 'window_width_75',
        message: h(History, { props: { registryId: this.id, recordId: recordId }, key: this.generateGuid() }),
        showCancelButton: false,
        showConfirmButton: false,
        closeOnClickModal: true
      })
    },
    addFilter (filter) {
      this.additionalFilters.push(filter)
    },
    clearFilters () {
      this.additionalFilters = []
    },
    importSubmit () {
      if (!this.$refs.importUpload.uploadFiles.length) {
        return
      }

      if (this.useTemplate) {
        const form = new FormData()

        form.append('file', this.$refs.importUpload.uploadFiles[0].raw)

        if (this.outerXref && this.outerXref.id && this.outerXref.value) {
          form.append('payload', JSON.stringify({
            'constant_fields': [
              { 'field_id': this.outerXref.id, 'value': this.outerXref.value, 'is_key': true }
            ]
          }))
        }

        this.$http.request({
          url: `${this.$config.api}/etleditor/import/${this.useTemplate}`,
          method: 'POST',
          data: form,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          this.$notify({
            title: this.$locale.registry.notify_import.title,
            message: this.$locale.registry.notify_import.success,
            type: 'success'
          })
        }).catch((error) => {
          this.$notify({
            title: this.$locale.registry.notify_import.title,
            message: this.$locale.registry.notify_import.error,
            type: 'error'
          })
        })
      }
    },
    defaultExport () {
      this.$confirm(this.$locale.registry.message_export.message, this.$locale.registry.message_export.title, {
        confirmButtonText: this.$locale.main.button.ok,
        cancelButtonText: this.$locale.main.button.cancel,
        type: 'warning'
      }).then(() => {
        let params = { ids: [], xrefs: [] }

        if (this.selected.length > 0) {
          this.selected.forEach(row => {
            params.ids.push(row.id)
          })
        } else {
          params.ids = null
        }

        if (this.outerXref) {
          if (this.outerXref.id) {
            params.xrefs.push({ id: this.outerXref.id, value: this.outerXref.value })
          }
        } else {
          params.xrefs = null
        }

        this
          .$http({
            method: 'post',
            url: `${this.$config.api}/etleditor/export/${this.registry.id}/${this.$store.getters['Authorization/roleId']}`,
            responseType: 'arraybuffer',
            data: params
          })
          .then(response => {
            let blob = new Blob([response.data], { type: response.headers['content-type'] })
            let url = window.URL.createObjectURL(blob)
            window.open(url)
          })
      })
    },
    openImportDialog (taskId) {
      this.useTemplate = taskId
      this.dialogFormVisible = true
    },
    taskExport (task) {
      this.$confirm(`${this.$locale.registry.message_export.task_message} "${task.task_name}"?`, this.$locale.registry.message_export.title, {
        confirmButtonText: this.$locale.main.button.ok,
        cancelButtonText: this.$locale.main.button.cancel,
        type: 'warning'
      }).then(() => {
        let params = { ids: [], xrefs: [] }

        if (this.selected.length > 0) {
          this.selected.forEach(row => {
            params.ids.push(row.id)
          })
        } else {
          params.ids = null
        }

        if (this.outerXref) {
          if (this.outerXref.id) {
            params.xrefs.push({ id: this.outerXref.id, value: this.outerXref.value })
          }
        } else {
          params.xrefs = null
        }

        this
          .$http({
            method: 'post',
            url: `${this.$config.api}/etleditor/export/${task.task_id}`,
            responseType: 'arraybuffer',
            data: params
          })
          .then(response => {
            let blob = new Blob([response.data], { type: response.headers['content-type'] })
            let url = window.URL.createObjectURL(blob)
            window.open(url)
          })
      })
    },
    arraySpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (row.isGrouped && columnIndex === 2) {
        // return [1, 2]
      }
    },
    updateLayout () {
      this.$nextTick(() => {
        this.$refs.table && this.$refs.table.doLayout()
        // console.log('update layout table')
      })
    },
    handleCancelRowEdit (row) {
      row.isEdit = false
      this.$refs.table.doLayout()
    },
    handleRowDblClick (row) {
      if (row.children) return false
      if (this.editableModel.id) {
        let editedRow = this.data.data.find((record) => record.id === this.editableModel.id)
        if (editedRow) {
          this.$set(editedRow, 'isEdit', false)
        }
      }
      this.$set(this.editableModel, 'id', row.id)
      this.$set(row, 'isEdit', true)

      if (this.rowDoubleClicked) {
        this.$emit('edit-record', row)
      }
    },
    updateEditableModel (data) {
      this.$set(this.editableModel, data.name, data.value)
    },
    rowEditHandler (row) {
      this.$set(row, 'isEdit', false)
      const recordId = this.editableModel.id
      if (Object.keys(this.editableModel).length <= 1) {
        this.editableModel = {}
        return false
      }
      this.$http.put(`${this.$config.api}/registryservice/registry/${this.id}/records/${this.editableModel.id}`, this.editableModel).then(() => {
        this.updateRecord(recordId)
      })
      this.editableModel = {}
    },
    toCamelCase (s) {
      return s.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
          .replace('-', '')
          .replace('_', '')
      })
    },
    findNumberColumns (columns) {
      columns.forEach((column) => {
        if (['integerField', 'floatField'].includes(column.type)) {
          this.numberColumns.push(column.value)
        }
        if ((column.children || []).length > 0) {
          this.findNumberColumns(column.children)
        }
      })
    },
    renderColumns (columns) {
      if (!columns) {
        return false
      }
      this.columns = []
      let me = this
      columns.sort((a, b) => b.row_order - a.row_order).forEach((column) => {
        me.columns.push(me.buildColumn(column))
      })
    },
    buildColumn (column) {
      let me = this

      let data = {
        text: column.name,
        value: `attr_${column.id}_`,
        type: (me.availableColumnTypes.includes(column.entity_type_id) ? me.toCamelCase(column.entity_type_id) : me.toCamelCase('string_field')),
        properties: column.properties,
        width: me.getPropertyValue(column.properties, 'width', 100),
        fixed: JSON.parse(me.getPropertyValue(column.properties, 'is_fixed', false)),
        extended: JSON.parse(me.getPropertyValue(column.properties, 'is_extended', false)),
        column_id: column.id,
        children: []
      }

      if (column.children !== null) {
        column.children.sort((a, b) => b.row_order - a.row_order).forEach((child) => {
          data.children.push(me.buildColumn(child))
        })
      }

      return data
    },
    getPropertyValue (properties, propertyName, defaultValue) {
      let property = properties.find(
        function (element) {
          return element.id === propertyName
        }
      )

      return property ? property.value : defaultValue
    },
    renderFilter (fields) {
      this.filterFields = fields
    },
    initAddExisting () {
      if (this.outerXref) {
        // Вн. ссылка
        return this.openTabModalWindow('RegistryAddExisting', { registryId: this.id, outerXref: this.outerXref, grid: this })
      }

      if (this.type === 'xref') {
        // Мн. ссылка
        return this.openTabModalWindow('RegistryAddExisting', { registryId: this.id, xrefId: this.xrefId, grid: this })
      }
    },
    deleteRecord (selectedRows) {
      if (!selectedRows.length) {
        this.$alert(this.$locale.main.message.select_record, this.$locale.main.message.attention, {
          confirmButtonText: this.$locale.main.button.ok
        })

        return false
      }
      let me = this
      this.$confirm(this.$locale.registry.message_delete.message, this.$locale.registry.message_delete.title, {
        confirmButtonText: this.$locale.main.button.delete,
        cancelButtonText: this.$locale.main.button.cancel,
        type: 'warning'
      }).then(() => {
        selectedRows.forEach(function (selectedRecord) {
          let recordIndex = me.data.data.findIndex((record) => record.id === selectedRecord.id)
          me.$http.delete(`${me.$config.api}/registryservice/registry/${me.id}/records/${selectedRecord.id}`, { hideNotification: true })
            .then(() => {
              me.$notify({
                title: me.$locale.main.message.success,
                message: me.$locale.main.message.deleted,
                type: 'success'
              })
              me.data.data.splice(recordIndex, 1)
            })
            .catch((error) => {
              if (error.response.data.error === 'not_allowed') {
                me.$notify.error({
                  title: me.$locale.main.message.error,
                  message: me.$locale.main.message.not_allowed
                })
              } else {
                me.$notify.error({
                  title: me.$locale.main.message.error,
                  message: me.$locale.main.message.not_saved
                })
              }
            })
        })
      })
    },
    handleCurrentChange (val) {
      val--
      this.currentPage = (val * this.pageLimitRegistry)
      this.loadData()
    },
    tableRowClassName ({ row, rowIndex }) {
      if (!row) {
        return null
      }

      if (row.is_deleted) {
        return 'deleted-row'
      }
      return row.isGrouped ? 'grouped-row' : ''
    },
    selectGroup (val) {
      this.selectColumn = val.text
      this.applyGrouping(val.value)
    },
    applyGrouping (params) {
      this.data.data = JSON.parse(JSON.stringify(this.initialData))
      this.isGrouped = false
      this.groupedHeaders = {}
      this.groupedAttributes = {}
      if (!params) {
        return true
      }
      const groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x)
          return rv
        }, {})
      }

      if (this.numberColumns.length === 0) {
        this.findNumberColumns(this.columns)
      }

      const groupingData = groupBy(this.data.data, params)
      const column = this.columns.find((item) => item.value === params)
      let newData = []
      for (let key in groupingData) {
        let record = {}
        record.guid = this.generateGuid()
        // record[params] = key
        let name = key || 'Пусто'
        if (['xrefField', 'xrefMultiField'].includes(column.type)) {
          name = JSON.parse(key)
          if (name.length > 0) {
            name = name[0].name
          } else {
            name = 'Пусто'
          }
        }
        record[this.$refs.table.columns.find((item) => !!item.property).property] = `${name} (${groupingData[key].length})`
        record.isGrouped = true
        record.children = groupingData[key]
        this.numberColumns.forEach((header) => {
          record[header] = record.children.map(item => item[header] || 0).reduce((a, b) => (parseFloat(a) + parseFloat(b)), 0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        })
        newData.push(record)
      }

      this.isGrouped = true
      this.groupedAttributes[params] = true
      // this.groupedHeaders = JSON.parse(JSON.stringify(this.columns))
      // this.groupedHeaders.splice(0, 0, this.groupedHeaders.splice(this.groupedHeaders.findIndex((item) => item.value === params), 1)[0])
      this.$nextTick(() => {
        this.data.data = newData
      })
    },
    viewsHandler (command) {
      let address = ''
      let method = ''
      switch (command) {
        case 'a':
          address = `${this.$config.api}/registryservice/registry/${this.id}/view`
          method = 'POST'
          break
        case 'b':
          address = `${this.$config.api}/registryservice/registry/${this.id}/view`
          method = 'DELETE'
          break
        case 'c':
          address = `${this.$config.api}/registryservice/registry/${this.id}/view?default=true`
          method = 'POST'
          break
        case 'd':
          address = `${this.$config.api}/registryservice/registry/${this.id}/view?default=true`
          method = 'DELETE'
          break
        default:
      }
      if (method === 'POST') {
        let params = this.getTreeProperties(this.columns)
        // console.log(params)
        this.$http.post(address, params)
      } else {
        this.$http.delete(address)
      }
    },
    async loadData (anotherPage = false) {
      if (!this.registry || (this.outerXref && this.outerXref.id && !this.outerXref.value)) {
        this.error = true
        this.loading = false
        return false
      }
      this.loading = true
      if (anotherPage) this.loadParams.offset = 0
      // древовидная таблица
      if (this.treeTable && this.registry.id && this.defaultGroup) {
        // let response = await this.$http.get(`${this.$config.api}/registryservice/registry/${this.registry.id}/data?filter[0]=${this.defaultGroup},eq,[] `)
        // this.data.data = response.data.data
        this.data = await this.registry.data().params(this.loadParams).get()
        this.data.data.forEach(item => {
          this.$set(item, 'children', [])
          this.$set(item, 'hasChildren', true)
        })
      } else {
        this.data = await this.registry.data().params(this.loadParams).get()
        this.initialData = JSON.parse(JSON.stringify(this.data.data))
        if (this.defaultGroup) {
          this.applyGrouping(this.defaultGroup)
        }
      }
      this.updateLayout()
      this.loading = false
    },
    selectionChange (val) {
      this.selected = val
    },
    changeWidth (newWidth, oldWidth, column, event) {
      this.columns = this.findAndChangeInTree(this.columns, column.property, newWidth)
    },
    findAndChangeInTree (tree, findValue, changeValue) {
      tree.forEach((item) => {
        if (item.value === findValue) {
          item.width = changeValue
        }
        if (Array.isArray(item.children)) {
          item.children = this.findAndChangeInTree(item.children, findValue, changeValue)
        }
      })
      return tree
    },
    getTreeProperties (tree) {
      let prop = []
      tree.forEach((item) => {
        let tmp = {
          'field_id': item.column_id || item.id,
          'width': item.width || 100,
          'children': this.getTreeProperties(item.children)
        }
        prop.push(tmp)
      })
      return prop
    },
    async addRecord (recordId) {
      if (!recordId) {
        return false
      }

      this.data.data.unshift(await this.registry.data().find(recordId))
    },
    async updateRecord (recordId) {
      if (!recordId) {
        return false
      }
      let findInTree = (tree, recordId, data) => {
        tree.forEach((item, i) => {
          if (parseInt(item.id) === parseInt(recordId)) {
            this.$set(tree, i, data)
          }
          if (item.children) {
            findInTree(item.children, recordId, data)
          }
        })
      }

      let data = await this.registry.data().find(recordId)
      findInTree(this.data.data, recordId, data)
      /* let recordIndex = this.data.data.findIndex((record) => record.id === recordId)
      if (recordIndex !== -1) {
        let dataRecords = await this.registry.data().find(recordId)
        this.$set(this.data.data, recordIndex, dataRecords)
      } */
    },
    updateWidth () {
      this.width = window.innerWidth
    }
  }
}
</script>

<style src="./Registry.css">

</style>
<style>
.window_width_25 {
    width: 25%;
    max-height: 80%;
    overflow-y: auto;
}
.window_width_50 {
    width: 50%;
    max-height: 80%;
    overflow-y: auto;
}
.window_width_75 {
    width: 75%;
    max-height: 80%;
    overflow-y: auto;
}
.window_width_100 {
    width: 100%;
    max-height: 80%;
    overflow-y: auto;
}
</style>
