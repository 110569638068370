<template>
    <div style="display: inline;">
        <component
                v-for="(component, index) in components"
                :key="component.guid"
                v-bind="component.properties"
                :ref="'component_' + component.guid"
                :is="component.type"
                v-model="model[component.properties.name]"
        ></component>
    </div>
</template>

<script>
export default {
  name: 'default-container',
  props: {
    components: {
      type: Array,
      default () { return [] }
    },
    model: {
      type: Object,
      default () { return {} }
    }
  },
  methods: {
    getRefs () {
      return this.$refs
    }
  }
}
</script>

<style scoped>
</style>
