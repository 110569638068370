import { MutationTree } from 'vuex'
import { TaskState, initialState } from './state'
import { MutationTypes, TaskCreatedEvent, TaskUpdatedEvent, TaskDeletedEvent } from './mutation-types'

export const mutations: MutationTree<TaskState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false
    state.error = error
  },
  [MutationTypes.TASK_LOADING_EVENT]: (state) => {
    state.isLoading = true
  },
  [MutationTypes.TASK_CREATED_EVENT]: (state, action: TaskCreatedEvent) => {
    state.isLoading = false
    state.location = action.location
  },
  [MutationTypes.TASK_UPDATED_EVENT]: (state, action: TaskUpdatedEvent) => {
    state.isLoading = false
  },
  [MutationTypes.TASK_DELETED_EVENT]: (state, action: TaskDeletedEvent) => {
    state.isLoading = false
    state.deletedTaskGuid = action.guid
  }
}
