import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';

export namespace LoggerAPI {

    export class AddToLog implements APIRequest<string> {
        response: string;
        path = '/netcorespatialservice/debug1';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: unknown) {}
    }

}