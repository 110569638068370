<template>
  <div :style="css" :class="{'hover_effect': clickTable}">
    <span :class="{'notExtended': !extended}" v-html="value"></span>
  </div>
</template>

<script>
import clickTableMixin from './click_table_mixin'
export default {
  name: 'text_field',
  mixins: [clickTableMixin],
  props: {
    value: String,
    extended: Boolean,
    css: String
  }
}
</script>

<style scoped>
  .notExtended {
    white-space: pre;
  }
  .hover_effect:hover {
    cursor: pointer;
    text-decoration: underline
  }
</style>
