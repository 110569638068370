<template>
  <div>
    <el-form size="mini" style="padding: 10px">
      <el-form-item label="Ассоциированное поле">
        <el-select v-model="value.properties.field" clearable>
          <el-option
            v-for="item in selectItems"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Адрес по умолчанию">
        <el-input v-model="value.properties.defaultAddress"></el-input>
      </el-form-item>
      <el-row>
        <el-col :span="20">
          <span style="margin-right: 10px;">Включить определение адреса</span>
          <el-checkbox v-model="value.properties.isDefineAddressEnable"></el-checkbox>
        </el-col>
        <el-col :span="4" v-if="value.properties.isDefineAddressEnable">
          <a href="https://dadata.ru/api/find-address/#model" target="_blank">справка</a>
        </el-col>
      </el-row>
      <div v-if="value.properties.isDefineAddressEnable">
        <el-row class="defineAddressMapping" type="flex" justify="space-around">
          <el-col :span="10">Поле</el-col>
          <el-col :span="10">Значение</el-col>
          <el-col :span="4">
            <el-button @click="defineAddressMappingAdd">Добавить</el-button>
          </el-col>
        </el-row>
        <el-row
          :span="24"
          type="flex"
          justify="space-around"
          class="defineAddressMapping"
          v-for="mappingItem in value.properties.defineAddressMapping"
        >
          <el-col :span="10">
            <el-select
              v-model="mappingItem.field_id"
            >
              <el-option
                v-for="(item, index) in components.filter((cmp) => cmp.properties.name)"
                :key="index"
                :label="`${item.name} (${item.properties.name})`"
                :value="item.properties.name"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="10">
            <el-input v-model="mappingItem.value_id"></el-input>
          </el-col>
          <el-col :span="4">
            <el-button type="text" @click="defineAddressMappingRemove(mappingItem)">удалить</el-button>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </div>
</template>
<script>
export default {
  name: 'positionOnEditor',
  inject: ['getComponents'],
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      selectItems: [],
      selectedField: null,
      components: [],
      defaultAddress: null
    }
  },
  mounted () {
    if (!this.value.properties || Array.isArray(this.value.properties)) {
      this.$set(this.value, 'properties', { field: null, defaultAddress: null })
    }
    if (!this.value.properties.field) {
      this.$set(this.value.properties, 'field', null)
    }
    if (!this.value.properties.defaultAddress) {
      this.$set(this.value.properties, 'defaultAddress', null)
    }
    if (!this.value.properties.isDefineAddressEnable) {
      this.$set(this.value.properties, 'isDefineAddressEnable', false)
    }
    if (!this.value.properties.defineAddressMapping) {
      this.$set(this.value.properties, 'defineAddressMapping', [])
    }

    this.components = this.getComponents()
    this.components.forEach((item) => {
      let id = false
      let name = false
      if (item.properties && item.properties.name) {
        // только если проставлен атрибут или это поле из рестра
        id = item.properties.name
        name = item.initialType.includes('registry/') ? item.name : item.properties.name
      }
      if (id) {
        this.selectItems.splice(this.selectItems.length, 0, {
          label: name || item.name,
          value: id
        })
      }
    })
  },
  methods: {
    defineAddressMappingAdd () {
      this.value.properties.defineAddressMapping.splice(
        this.value.properties.defineAddressMapping.length,
        0,
        {
          field_id: null,
          value_id: null
        }
      )
    },
    defineAddressMappingRemove (item) {
      this.value.properties.defineAddressMapping.splice(
        this.value.properties.defineAddressMapping.findIndex((i) => i === item),
        1
      )
    },
    selectChange () {
      if (this.selectedField === null) {
        this.value.properties = {}
        return
      }
      this.value.properties = { field: this.selectedField, defaultAddress: this.defaultAddress }
    }
  }
}
</script>

<style scoped>
  .defineAddressMapping {
    margin: 1px 0px;
  }
  .defineAddressMapping /deep/ div {
    text-align: center;
  }
</style>
