<template>
  <div>
    <el-button type="text" @click="dialogSettingsVisible = true">{{ $locale.main.button.edit }}</el-button>
    <el-dialog
        :title="$locale.interface_editor.component_editor.editor_title_chart.edit_chart"
        :modal="false"
        :width="'25%'"
        :visible.sync="dialogSettingsVisible">
      <el-form label-position="top" :model="model">
        <el-form-item label="Заголовок">
          <el-input type="text" size="small" style="width: 185px; margin-bottom: 10px" v-model="model.text"></el-input>
        </el-form-item>
        <el-form-item :label="$locale.interface_editor.component_editor.editor_title_chart.pos_chart">
          <el-select v-model="model.align" size="small">
            <el-option label="Слева" value="left"></el-option>
            <el-option label="По центру" value="center"></el-option>
            <el-option label="Справа" value="right"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$locale.interface_editor.component_editor.editor_title_chart.font_size">
          <el-input type="text" size="small" style="width: 185px; margin-bottom: 10px" v-model="model.style.fontSize" placeholder="12px"></el-input>
        </el-form-item>
        <el-form-item :label="$locale.interface_editor.component_editor.editor_title_chart.color_title" >
          <div class="block">
            <el-color-picker class="color_picker" size="mini" v-model="model.style.color"></el-color-picker>
            <el-input style="width: 147px" type="text" size="small" v-model="model.style.color" placeholder="#19D29A"></el-input>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'editor-title-chart',
  props: {
    value: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      dialogSettingsVisible: false,
      model: {
        text: '',
        align: 'right',
        style: {
          fontSize: '16px',
          color: '#FFF'
        }
      }
    }
  },
  watch: {
    dialogSettingsVisible: {
      handler (value) {
        if (!value) {
          this.$emit('change', this.model)
        }
      }
    }
  },
  mounted () {
    this.model = { ...this.model, ...this.value }
  }
}
</script>

<style scoped>
.block {
  display: inline-flex;
  align-items: center;
  height: 40px;
}
.color_picker {
  margin-right: 10px;
}
</style>
