import { FluxStandardAction } from '@/core/domain/type/types'
import GroupDTO from '@/services/NotificationEditor/domain/model/GroupDTO'

export enum ActionTypes {
  GET_GROUP = 'get_group',
  GET_GROUP_BY_GUID = 'get_group_by_id',
  CREATE_GROUP = 'create_group',
  UPDATE_GROUP = 'update_group',
  DELETE_GROUP = 'delete_group'
}

export class GetGroup implements FluxStandardAction {
  type = 'NotificationGroup/' + ActionTypes.GET_GROUP
  constructor (public payload: object) {}
}

export class GetGroupById implements FluxStandardAction {
  type = 'NotificationGroup/' + ActionTypes.GET_GROUP_BY_GUID
  constructor (public id: number) {}
}

export class CreateGroup implements FluxStandardAction {
  type = 'NotificationGroup/' + ActionTypes.CREATE_GROUP
  constructor (public payload: GroupDTO) {}
}

export class UpdateGroup implements FluxStandardAction {
  type = 'NotificationGroup/' + ActionTypes.UPDATE_GROUP
  constructor (public payload: GroupDTO) {}
}

export class DeleteGroup implements FluxStandardAction {
  type = 'NotificationGroup/' + ActionTypes.DELETE_GROUP
  constructor (public id: number) {}
}
