<template>
  <Split style="height: 800px; width: 100%">
    <SplitArea :size="50" style="padding: 0 10px;">
      <el-row>
        <el-button type="text" @click="addFilter">Добавить фильтр</el-button>
      </el-row>
      <div v-for="(filter, index) in filters">
        <el-row>
          <div style="float: left;">
            {{filter.name}}
          </div>
          <el-button type="text" style="float: right;" @click="changeActiveFilter(filter)">Редактировать</el-button>
          <el-button type="text" style="float: right; color: red;" @click="removeFilterByIndex(index)">Удалить</el-button>
        </el-row>
      </div>
    </SplitArea>
    <SplitArea :size="50" style="padding: 0 10px;">
      <template v-if="activeFilter">
        <el-form>
          <el-form-item label="Наименование">
            <el-input v-model="activeFilter.name"/>
          </el-form-item>
          <el-form-item labe="Фильтр">
            <editor-conditions
              :value-watcher="true"
              :value="activeFilter.filter"
              @change="activeFilter.filter = $event"/>
          </el-form-item>
          <el-form-item label="Скрывать слои">
            <layers-tree
              ref="tree"
              :layers="localLayers"
              :showLayersOpacity="false"
              :is-fullscreen="false"
              @layer-select="layerChecked"
            ></layers-tree>
          </el-form-item>
        </el-form>
      </template>
    </SplitArea>
  </Split>
</template>
<script>
  import LayersTree from '@/components/InterfaceEditor/components/basic/Map/ToolsPanel/LayersTree'
  import EditorFilters from '@/components/InterfaceEditor/components/editor/editor-filters'
  import EditorConditions from '../editor-conditions'

  export const FILTER = {
    name: null,
    filter: { condition_type: 'and', conditions: [], type: 'condition' },
    layerGuids: []
  }

  export default {
    name: 'MapLayerFilters',
    components: { EditorConditions, LayersTree, EditorFilters },
    props: {
      layers: {
        type: Array,
        default: () => {
          return []
        }
      },
      value: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    data: () => {
      return {
        dialogSettingsVisible: false,
        filters: [],
        activeFilter: null,
        localLayers: []
      }
    },
    mounted () {
      this.filters = this.value
      this.localLayers = this.layers
    },
    watch: {
      filters: {
        handler: function (newValue, oldValue) {
          this.$emit('input', newValue)
        },
        deep: true
      }
    },
    methods: {
      removeFilterByIndex (index) {
        this.filters.splice(index, 1)
      },
      changeActiveFilter (a) {
        this.$set(this, 'activeFilter', a)
        this.$nextTick(() => {
          this.$refs.tree.setCheckedLayerGuids(this.activeFilter.layerGuids)
        })
      },
      setTreeHide (item, values) {
        if (values.includes(item.guid)) {
          item.hide = true
        }
        if (item.type === 'group') {
          item.children.forEach((child) => {
            this.setTreeHide(child, values)
          }, this)
        }
      },
      addFilter () {
        const newFilter = JSON.parse(JSON.stringify(FILTER))
        newFilter.name = 'Наименование_' + this.filters.length
        this.filters.splice(this.filters.length, 0, newFilter)
      },
      layerChecked(layerData, checked) {
        this.activeFilter.layerGuids = this.$refs['tree'].getCheckedKeys(false)
      }
    }
  }
</script>
