import ModelMixin from '@/components/InterfaceEditor/components/ModelMixin'

export default {
  mixins: [ModelMixin],
  methods: {
    getProperties () {
      let properties = Object.assign({}, this.$vnode.componentOptions.Ctor.options.props)
      for (let property in properties) {
        if (properties.hasOwnProperty(property)) {
          if (properties[property].frozen) {
            delete properties[property]
          }
        }
      }
      return properties
    }
  },
  mounted () {
    this.$emit('rendered')
  }
}
