export default {
  // for filter panel
  page: 'Страница',
  more: 'ещё',
  to: 'к',
  of: 'из',
  next: 'Следующая',
  last: 'Последняя',
  first: 'Первая',
  previous: 'Предыдущая',
  loadingOoo: 'Загрузка...',

  // for set filter
  selectAll: 'Выделить всё',
  searchOoo: 'Поиск...',
  blanks: 'Ничего не найдено',

  // for number filter and text filter
  filterOoo: 'Фильтровать...',
  applyFilter: 'ОК',
  resetFilter: 'Сбросить',
  equals: 'Равно',
  notEqual: 'Не равно',

  // for number filter
  lessThan: 'Меньше чем',
  greaterThan: 'Больше чем',
  lessThanOrEqual: 'Меньше или равно',
  greaterThanOrEqual: 'Больше или равно',
  inRange: 'В промежутке',

  // for text filter
  contains: 'Содержит',
  notContains: 'Не содержит',
  startsWith: 'Начинается с',
  endsWith: 'Заканчивается',
  isNull: 'Пусто',
  notIsNull: 'Не пусто',

  // filter conditions
  andCondition: '"И"',
  orCondition: '"ИЛИ"',

  // the header of the default group column
  group: 'Группа',

  // tool panel
  columns: 'Столбцы',
  filters: 'Фильтры',
  rowGroupColumns: 'Столбцы группировки по строкам',
  rowGroupColumnsEmptyMessage: 'Перетащите сюда для группировки по строкам',
  valueColumns: 'Столбцы со значениями',
  pivotMode: 'Режим сводной таблицы',
  groups: 'Группы',
  values: 'Значения',
  pivots: 'Заголовки столбцов',
  valueColumnsEmptyMessage: 'Перетащите сюда для агрегации',
  pivotColumnsEmptyMessage: 'Перетащите сюда, чтобы задать заголовки столбам',
  toolPanelButton: 'Панель инструментов',

  // other
  noRowsToShow: 'Нет данных',
  loadingError: 'Ошибка расчета данных. Проверьте правила группировки, сортировки и расчета итогов.',

  // enterprise menu
  pinColumn: 'Закрепить колонку',
  valueAggregation: 'Агрегация по значению',
  autosizeThiscolumn: 'Автоматически задавать размер этой колонки',
  autosizeAllColumns: 'Автоматически задавать размер всем колонкам',
  groupBy: 'Группировать по',
  ungroupBy: 'Разгруппировать по',
  resetColumns: 'Сбросить столбцы',
  expandAll: 'Развернуть всё',
  collapseAll: 'Свернуть всё',
  toolPanel: 'Панель инструментов',
  export: 'Экспорт',
  csvExport: 'Экспорт в CSV',
  excelExport: 'Экспорт в Excel (.xlsx)',
  excelXmlExport: 'Экспорт в XML (.xml)',

  // enterprise menu pinning
  pinLeft: 'Закрепить слева <<',
  pinRight: 'Закрепить справа >>',
  noPin: 'Не закреплять <>',

  // enterprise menu aggregation and status bar
  sum: 'Сумма',
  min: 'Минимум',
  max: 'Максимум',
  none: 'Пусто',
  count: 'Количество',
  avg: 'Среднее',
  filteredRows: 'Отфильтрованные',
  selectedRows: 'Выделенные',
  totalRows: 'Всего строк',
  totalAndFilteredRows: 'Строк',

  // standard menu
  copy: 'Копировать',
  copyWithHeaders: 'Копировать с заголовком',
  ctrlC: 'Ctrl+C',
  paste: 'Вставить',
  ctrlV: 'Ctrl+V',

  // Корпоративное меню (графики)
  pivotChartAndPivotMode: 'Сводная диаграмма & режим сведения',
  pivotChart: 'Сводная диаграмма',
  chartRange: 'Диапазон диаграммы',

  columnChart: 'Столбиковая диаграмма',
  groupedColumn: 'Сгруппированная',
  stackedColumn: 'Сложенная',
  normalizedColumn: '100% Сложенная',

  barChart: 'Панель',
  groupedBar: 'Сгруппированная',
  stackedBar: 'Сложенная',
  normalizedBar: '100% Сложенная',

  pieChart: 'Круговая диаграмма',
  pie: 'Круговая диаграмма',
  doughnut: 'Кольцевая диаграмма',

  line: 'Линия',

  xyChart: 'X Y (Разброс)',
  scatter: 'Диаграмма рассеяния',
  bubble: 'Пузырьковая диаграмма',

  areaChart: 'Область',
  area: 'Диаграмма с областями',
  stackedArea: 'Сложенная',
  normalizedArea: '100% Сложенная',

  histogramChart: 'Гистограмма',

  // Графики
  pivotChartTitle: 'Сводная диаграмма',
  rangeChartTitle: 'График диапазона',
  settings: 'Настройки',
  data: 'Данные',
  format: 'Формат',
  categories: 'Категории',
  defaultCategory: '(Пусто)',
  series: 'Серии',
  xyValues: 'X Y Значения',
  paired: 'Парный режим',
  axis: 'Ось',
  navigator: 'Навигация',
  color: 'Цвет',
  thickness: 'Толщина',
  xType: 'X Тип',
  automatic: 'Автоматически',
  category: 'Категория',
  number: 'Число',
  time: 'Время',
  xRotation: 'X Поворот',
  yRotation: 'Y Поворот',
  ticks: 'Отметки',
  width: 'Ширина',
  height: 'Высота',
  length: 'Длина',
  padding: 'Внутренний отступ',
  spacing: 'Отступ',
  chart: 'Диаграмма',
  title: 'Заголовок',
  titlePlaceholder: 'Заголовок диаграммы - двойной щелчок для редактирования',
  background: 'Фон',
  font: 'Шрифт',
  top: 'Верх',
  right: 'Право',
  bottom: 'Низ',
  left: 'Лево',
  labels: 'Метки',
  size: 'Размер',
  minSize: 'Минимальный размер',
  maxSize: 'Максимальный размер',
  legend: 'Легенда',
  position: 'Позиция',
  markerSize: 'Размер маркера',
  markerStroke: 'Обводка маркера',
  markerPadding: 'Внутренний отступ маркера',
  itemSpacing: 'Расстояние между предметами',
  itemPaddingX: 'Внутренний отступ предмета по X',
  itemPaddingY: 'Внутренний отступ предмета по Y',
  layoutHorizontalSpacing: 'Горизонтальный отступ',
  layoutVerticalSpacing: 'Вертикальный отступ',
  strokeWidth: 'Ширина обводки',
  offset: 'Смещение',
  offsets: 'Смещения',
  tooltips: 'Всплывающие подсказки',
  callout: 'Вызов',
  markers: 'Маркеры',
  shadow: 'Тень',
  blur: 'Размытие',
  xOffset: 'Смещение по X',
  yOffset: 'Смещение по Y',
  lineWidth: 'Ширина линии',
  normal: 'Нормальный',
  bold: 'Жирный',
  italic: 'Наклоненный',
  boldItalic: 'Жирный наклоненный',
  predefined: 'Предопределенный',
  fillOpacity: 'Непрозрачность заливки',
  strokeOpacity: 'Непрозрачность линии',
  histogramBinCount: 'Количество сегментов',
  columnGroup: 'Столбец',
  barGroup: 'Панель',
  pieGroup: 'Круговая',
  lineGroup: 'Линейная',
  scatterGroup: 'X Y (Разброс)',
  areaGroup: 'Зональная',
  histogramGroup: 'Гистограмма',
  groupedColumnTooltip: 'Сгруппированная',
  stackedColumnTooltip: 'Сложенная',
  normalizedColumnTooltip: '100% Сложенная',
  groupedBarTooltip: 'Сгруппированная',
  stackedBarTooltip: 'Сложенная',
  normalizedBarTooltip: '100% Сложенная',
  pieTooltip: 'Круговая',
  doughnutTooltip: 'Кольцевая',
  lineTooltip: 'Линейная',
  groupedAreaTooltip: 'Зональная',
  stackedAreaTooltip: 'Сложенная',
  normalizedAreaTooltip: '100% Сложенная',
  scatterTooltip: 'Рассеяния',
  bubbleTooltip: 'Пузырьковая',
  histogramTooltip: 'Гистограмма',
  noDataToChart: 'Нет данных для представления в виде диаграммы.',
  pivotChartRequiresPivotMode:
    'Для сводной диаграммы необходимо включить режим сводной диаграммы.',
  chartSettingsToolbarTooltip: 'Меню',
  chartLinkToolbarTooltip: 'Связать с сеткой',
  chartUnlinkToolbarTooltip: 'Не связывать с сеткой',
  chartDownloadToolbarTooltip: 'Загрузить диаграмму',

  // ARIA
  ariaHidden: 'скрытый',
  ariaVisible: 'видимый',
  ariaChecked: 'проверенный',
  ariaUnchecked: 'непроверенный',
  ariaIndeterminate: 'неопределенный',
  ariaColumnSelectAll: 'Переключить на выделение всех столбцов',
  ariaInputEditor: 'Редактор ввода',
  ariaDateFilterInput: 'Ввод фильтра даты',
  ariaFilterInput: 'Ввод фильтра',
  ariaFilterColumnsInput: 'Ввод фильтра столбцов',
  ariaFilterValue: 'Значение фильтра',
  ariaFilterFromValue: 'Фильтровать от значения',
  ariaFilterToValue: 'Фильтровать до значения',
  ariaFilteringOperator: 'Оператор фильтрации',
  ariaColumnToggleVisibility: 'переключить видимость столбца',
  ariaColumnGroupToggleVisibility: 'переключить видимость группы столбцов',
  ariaRowSelect: 'Нажмите ПРОБЕЛ для выделения данной строки',
  ariaRowDeselect: 'Нажмите ПРОБЕЛ для снятия выделения данной строки',
  ariaRowToggleSelection: 'Нажмите ПРОБЕЛ, чтобы переключить выделение строки',
  ariaRowSelectAll: 'Нажмите ПРОБЕЛ, чтобы переключить выделение всех строк',
  ariaSearch: 'Поиск',
  ariaSearchFilterValues: 'Поиск значений по фильтру'
}
