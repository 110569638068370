<template>
  <div :style="css" :class="{'hover_effect': clickTable}">
    <span :class="{'notExtended': !extended}">{{value}}</span>
  </div>
</template>

<script>
import clickTableMixin from './click_table_mixin'
export default {
  mixins: [clickTableMixin],
  props: ['value', 'extended', 'css'],
  name: 'string_field'
}

</script>

<style scoped>
  .notExtended {
    white-space: pre;
  }
  .hover_effect:hover {
    cursor: pointer;
    text-decoration: underline
  }
</style>
