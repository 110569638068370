<template>
  <div>
    <el-button type="text" @click="dialogSettingsVisible = true">Редактировать</el-button>

    <el-dialog :title="'Группы шаблонов стилей'" :modal="false"
               :visible.sync="dialogSettingsVisible"
               class="window">
      <el-button type="text" @click="openStyleTemplateGroupEditor">Редактировать группы шаблонов стилей</el-button>
      <el-dialog
        :title="title"
        :visible.sync="groupsEditorVisible"
        append-to-body
      >
        <style-template-group-editor
          @change-is-group-editor="isGroupEditor = !isGroupEditor"
        ></style-template-group-editor>
      </el-dialog>
      <el-select
        v-model="localValue"
        multiple
      >
        <el-option
          v-for="item in items"
          :key="item.guid"
          :label="item.name"
          :value="item.guid"
        >
        </el-option>
      </el-select>

    </el-dialog>
  </div>
</template>

<script>
  import { APIClient } from '@/core/infrastructure/api/APIClient'
  import { MapEditorAPI } from '@/core/infrastructure/api/modules/MapEditorAPI'
  import StyleTemplateGroupEditor from '@/core/infrastructure/components/StyleTemplateGroupEditor'

  export default {
    name: 'MapStyleTemplateGroups',
    components: { StyleTemplateGroupEditor },
    props: {
      value: {
        type: Array,
        default: function () {
          return []
        }
      }
    },
    computed: {
      title: function () {
        return this.isGroupEditor ? 'Настройка групп шаблонов стилей' : 'Настройка шаблонов стилей'
      }
    },
    data () {
      return {
        dialogSettingsVisible: false,
        localValue: this.value,
        items: [],
        groupsEditorVisible: false,
        isGroupEditor: true
      }
    },
    watch: {
      localValue: function (newValue) {
        this.$emit('change', newValue)
      }
    },
    mounted() {
      this.loadItems()
    },
    methods: {
      async loadItems() {
        const me = this
        await APIClient.shared.request(
          new MapEditorAPI.GetStyleTemplateGroups()
        ).then(function (result) {
          me.items = result
        })
      },
      openStyleTemplateGroupEditor() {
        this.groupsEditorVisible = true
      }
    }
  }
</script>

<style scoped>

</style>
