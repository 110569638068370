import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import ModelGroupQueryRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/ModelGroupQueryRepositoryInterface'
import store from '@/store'
import { GetModelGroupByGuid } from '@/services/DigitalTwinEditor/infrastructure/store/modules/ModelGroup/action-types'

export default class ModelGroupQueryRepository extends AbstractQueryRepository implements ModelGroupQueryRepositoryInterface
{
    getByGuid(guid: string): Promise<any>
    {
        return store.dispatch(new GetModelGroupByGuid(guid));
    }
}