import { IServerSideGetRowsRequest } from 'ag-grid-community/dist/lib/interfaces/iServerSideDatasource'
import { ColumnApi } from 'ag-grid-community/dist/lib/columns/columnApi'

export default class PivotColumnsBuilder {
  public static build (request: IServerSideGetRowsRequest, pivotFields: string[], columnApi: ColumnApi): void {
    const pivotColumns = this.createPivotColumns(request, pivotFields, columnApi)
    columnApi.setPivotResultColumns(pivotColumns)
  }

  private static createPivotColumns (request: IServerSideGetRowsRequest, pivotFields: string[], columnApi: ColumnApi) {
    function addPivotColumn (columnId: string, parts: string[], result: { groupId, children }[], columnApi: ColumnApi): { groupId, children }[] {
      if (parts.length === 0) return []
      const first = parts.shift()
      const existing = result.filter(function (r) {
        return r.groupId === first
      })[0]
      if (existing) {
        existing['children'] = addPivotColumn(columnId, parts, existing.children, columnApi)
      } else {
        let colDef: { groupId, headerName, children, field, cellRenderer } = {
          groupId: undefined,
          headerName: undefined,
          children: undefined,
          field: undefined,
          cellRenderer: undefined
        }

        let isGroup = parts.length > 0
        if (isGroup) {
          colDef['groupId'] = first
          colDef['headerName'] = first
        } else {
          let valueCol = request.valueCols.filter(function (r) {
            return r.field === first
          })[0]
          let column = columnApi.getColumn(first)
          if (column && column.getColDef().cellRenderer) {
            colDef['cellRenderer'] = column.getColDef().cellRenderer
          }
          colDef['colId'] = columnId.replace('$$', '_')
          colDef['headerName'] = valueCol.displayName
          colDef['field'] = columnId.replace('$$', '_')
        }

        let children = addPivotColumn(columnId, parts, [], columnApi)
        if (children.length > 0) {
          colDef['children'] = children
        }
        result.push(colDef)
      }
      return result
    }

    if (request.pivotMode && request.pivotCols.length > 0) {
      let secondaryCols = []
      let resultedPivotFields = []
      request.valueCols.forEach((item) => {
        resultedPivotFields = [...resultedPivotFields, ...pivotFields.map((pivotField) => `${pivotField}$$${item.id}`)]
      })

      resultedPivotFields.forEach(function (field) {
        addPivotColumn(field, field.split('$$'), secondaryCols, columnApi)
      })
      return secondaryCols
    }

    return []
  }
}
