import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ModelInstanceCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/ModelInstanceCommandRepositoryInterface'
import ModelInstanceCreateCommand from '@/services/DigitalTwinEditor/application/command/ModelInstanceCreateCommand'
import { ModelInstanceDTO } from '@/services/DigitalTwinEditor/domain/model/Project/ModelInstance'

export default class ModelInstanceCreateHandler implements CommandHandlerInterface
{
    private repo: ModelInstanceCommandRepositoryInterface;

    constructor(repo: ModelInstanceCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: ModelInstanceCreateCommand): Promise<void>
    {
        const item: ModelInstanceDTO = {
            element_id: command.getElementId(),
            model_id: command.getModelId(),
            position: command.getPosition(),
            rotation: command.getRotation(),
            uses_heightmap_z: command.getUsesHeightMapZ(),
            has_3d_tiles: command.getHas3Dtiles()
        };
        return this.repo.insert(item);
    }
}