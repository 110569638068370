
import { VueContext } from 'vue-context'

export default {
  name: 'CesiumViewer',
  components: {
    VueContext
  },
  props: ['guid'],
  data () {
    return {
    }
  },
  mounted() {
  }
}
