<template>
  <div :style="css || 'text-align: center'" v-if="value" :class="{'hover_effect': clickTable}">
    {{ value | moment("DD.MM.Y") }}
  </div>
</template>

<script>
import clickTableMixin from './click_table_mixin'
export default {
  mixins: [clickTableMixin],
  name: 'date_field',
  props: {
    value: String,
    css: String
  }
}
</script>

<style scoped>
.hover_effect:hover {
    cursor: pointer;
    text-decoration: underline
  }
</style>
