export default {
  computed: {
    clickTable () {
      if (typeof this.$attrs.action !== 'undefined' && Object.keys(this.$attrs.action).length !== 0 && this.$attrs.action.type) {
        return true
      }
      return !(this.$attrs['open-card'] && Object.keys(this.$attrs['open-card']).length === 0)
    }
  }
}
