import { ActionTree } from 'vuex'
import { ModelState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, ModelUpdatedEvent, ModelLoadingEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { ModelAPI } from '@/services/DigitalTwinEditor/infrastructure/api/ModelAPI'

export const actions: ActionTree<ModelState, any> = {
    [ActionTypes.UPDATE_MODEL]: async ({ commit, dispatch }, data) => {
        try {
            commit(new ModelLoadingEvent());
            let location = await APIClient.shared.request(new ModelAPI.UpdateModel(data.payload));
            commit(new ModelUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.GET_MODEL_BY_GUID]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new ModelAPI.GetModelByGuid(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    }
}
