import Vue from 'vue'
import CommandBus from '@/core/application/command/service/CommandBus'
import CommandBusHandlerLocator from '@/core/application/command/service/CommandBusHandlerLocator'
import QueryBus from '@/core/application/query/service/QueryBus'
import QueryBusHandlerLocator from '@/core/application/query/service/QueryBusHandlerLocator'

import ApprovalDeleteCommand from '@/services/LogicEditor/application/command/ApprovalDeleteCommand'
import CommandDeleteCommand from '@/services/LogicEditor/application/command/CommandDeleteCommand'
import ConstraintDeleteCommand from '@/services/LogicEditor/application/command/ConstraintDeleteCommand'
import ListenerDeleteCommand from '@/services/LogicEditor/application/command/ListenerDeleteCommand'
import LogicDeleteCommand from '@/services/LogicEditor/application/command/LogicDeleteCommand'
import StateDeleteCommand from '@/services/LogicEditor/application/command/StateDeleteCommand'
import LogicGroupDeleteCommand from '@/services/LogicEditor/application/command/LogicGroupDeleteCommand'
import RelatedObjectDeleteCommand from '@/services/LogicEditor/application/command/RelatedObjectDeleteCommand'
import FormulaDeleteCommand from '@/services/LogicEditor/application/command/FormulaDeleteCommand'

import ApprovalCreateHandler from '@/services/LogicEditor/application/handler/command/ApprovalCreateHandler'
import ApprovalUpdateHandler from '@/services/LogicEditor/application/handler/command/ApprovalUpdateHandler'
import ApprovalDeleteHandler from '@/services/LogicEditor/application/handler/command/ApprovalDeleteHandler'
import RelatedObjectCreateHandler from '@/services/LogicEditor/application/handler/command/RelatedObjectCreateHandler'
import RelatedObjectUpdateHandler from '@/services/LogicEditor/application/handler/command/RelatedObjectUpdateHandler'
import RelatedObjectDeleteHandler from '@/services/LogicEditor/application/handler/command/RelatedObjectDeleteHandler'
import CommandCreateHandler from '@/services/LogicEditor/application/handler/command/CommandCreateHandler'
import CommandUpdateHandler from '@/services/LogicEditor/application/handler/command/CommandUpdateHandler'
import CommandDeleteHandler from '@/services/LogicEditor/application/handler/command/CommandDeleteHandler'
import ConstraintCreateHandler from '@/services/LogicEditor/application/handler/command/ConstraintCreateHandler'
import ConstraintUpdateHandler from '@/services/LogicEditor/application/handler/command/ConstraintUpdateHandler'
import ConstraintDeleteHandler from '@/services/LogicEditor/application/handler/command/ConstraintDeleteHandler'
import ListenerCreateHandler from '@/services/LogicEditor/application/handler/command/ListenerCreateHandler'
import ListenerUpdateHandler from '@/services/LogicEditor/application/handler/command/ListenerUpdateHandler'
import ListenerDeleteHandler from '@/services/LogicEditor/application/handler/command/ListenerDeleteHandler'
import LogicCreateHandler from '@/services/LogicEditor/application/handler/command/LogicCreateHandler'
import LogicUpdateHandler from '@/services/LogicEditor/application/handler/command/LogicUpdateHandler'
import LogicDeleteHandler from '@/services/LogicEditor/application/handler/command/LogicDeleteHandler'
import StateCreateHandler from '@/services/LogicEditor/application/handler/command/StateCreateHandler'
import StateUpdateHandler from '@/services/LogicEditor/application/handler/command/StateUpdateHandler'
import StateDeleteHandler from '@/services/LogicEditor/application/handler/command/StateDeleteHandler'
import LogicGroupCreateHandler from '@/services/LogicEditor/application/handler/command/LogicGroupCreateHandler'
import LogicGroupUpdateHandler from '@/services/LogicEditor/application/handler/command/LogicGroupUpdateHandler'
import LogicGroupDeleteHandler from '@/services/LogicEditor/application/handler/command/LogicGroupDeleteHandler'
import FormulaCreateHandler from '@/services/LogicEditor/application/handler/command/FormulaCreateHandler'
import FormulaUpdateHandler from '@/services/LogicEditor/application/handler/command/FormulaUpdateHandler'
import FormulaDeleteHandler from '@/services/LogicEditor/application/handler/command/FormulaDeleteHandler'

import RelatedObjectCommandRepository from '@/services/LogicEditor/infrastructure/domain/repository/RelatedObjectCommandRepository'
import LogicCommandRepository from '@/services/LogicEditor/infrastructure/domain/repository/LogicCommandRepository'
import ListenerCommandRepository from '@/services/LogicEditor/infrastructure/domain/repository/ListenerCommandRepository'
import CommandCommandRepository from '@/services/LogicEditor/infrastructure/domain/repository/CommandCommandRepository'
import ConstraintCommandRepository from '@/services/LogicEditor/infrastructure/domain/repository/ConstraintCommandRepository'
import ApprovalCommandRepository from '@/services/LogicEditor/infrastructure/domain/repository/ApprovalCommandRepository'
import StateCommandRepository from '@/services/LogicEditor/infrastructure/domain/repository/StateCommandRepository'
import LogicGroupCommandRepository from '@/services/LogicEditor/infrastructure/domain/repository/LogicGroupCommandRepository'
import FormulaCommandRepository from '@/services/LogicEditor/infrastructure/domain/repository/FormulaCommandRepository'

import ApprovalQueryRepository from '@/services/LogicEditor/infrastructure/domain/repository/ApprovalQueryRepository'
import RelatedObjectQueryRepository from '@/services/LogicEditor/infrastructure/domain/repository/RelatedObjectQueryRepository'
import LogicQueryRepository from '@/services/LogicEditor/infrastructure/domain/repository/LogicQueryRepository'
import ListenerQueryRepository from '@/services/LogicEditor/infrastructure/domain/repository/ListenerQueryRepository'
import PluginQueryRepository from '@/services/LogicEditor/infrastructure/domain/repository/PluginQueryRepository'
import CommandQueryRepository from '@/services/LogicEditor/infrastructure/domain/repository/CommandQueryRepository'
import StateQueryRepository from '@/services/LogicEditor/infrastructure/domain/repository/StateQueryRepository'
import LogicGroupQueryRepository from '@/services/LogicEditor/infrastructure/domain/repository/LogicGroupQueryRepository'
import ConstraintQueryRepository from '@/services/LogicEditor/infrastructure/domain/repository/ConstraintQueryRepository'
import LogicTreeElementQueryRepository from '@/services/LogicEditor/infrastructure/domain/repository/LogicTreeElementQueryRepository'
import FormulaQueryRepository from '@/services/LogicEditor/infrastructure/domain/repository/FormulaQueryRepository'

import ApprovalByGuidHandler from '@/services/LogicEditor/application/handler/query/ApprovalByGuidHandler'
import ApprovalStagesByApprovalIdHandler from '@/services/LogicEditor/application/handler/query/ApprovalStagesByApprovalIdHandler'
import ApprovalStageTypesHandler from '@/services/LogicEditor/application/handler/query/ApprovalStageTypesHandler'
import CommandByGuidHandler from '@/services/LogicEditor/application/handler/query/CommandByGuidHandler'
import CommandEventTypePropertiesHandler from '@/services/LogicEditor/application/handler/query/CommandEventTypePropertiesHandler'
import CommandsHandler from '@/services/LogicEditor/application/handler/query/CommandsHandler'
import ListenerByGuidHandler from '@/services/LogicEditor/application/handler/query/ListenerByGuidHandler'
import FormulaByGuidHandler from '@/services/LogicEditor/application/handler/query/FormulaByGuidHandler'
import RelatedObjectByGuidHandler from '@/services/LogicEditor/application/handler/query/RelatedObjectByGuidHandler'
import LogicByGuidHandler from '@/services/LogicEditor/application/handler/query/LogicByGuidHandler'
import LogicHandler from '@/services/LogicEditor/application/handler/query/LogicHandler'
import StateByGuidHandler from '@/services/LogicEditor/application/handler/query/StateByGuidHandler'
import StateOperationTypesHandler from '@/services/LogicEditor/application/handler/query/StateOperationTypesHandler'
import StateOperationTypeEntityTypesHandler from '@/services/LogicEditor/application/handler/query/StateOperationTypeEntityTypesHandler'
import StateFieldTypesHandler from '@/services/LogicEditor/application/handler/query/StateFieldTypesHandler'
import ConditionFieldTypesHandler from '@/services/LogicEditor/application/handler/query/ConditionFieldTypesHandler'
import LogicGroupByGuidHandler from '@/services/LogicEditor/application/handler/query/LogicGroupByGuidHandler'
import ConstraintByGuidHandler from '@/services/LogicEditor/application/handler/query/ConstraintByGuidHandler'
import ConstraintTypesHandler from '@/services/LogicEditor/application/handler/query/ConstraintTypesHandler'
import LogicTreeElementsTreeHandler from '@/services/LogicEditor/application/handler/query/LogicTreeElementsTreeHandler'
import LogicTreeElementByElementGuidHandler from '@/services/LogicEditor/application/handler/query/LogicTreeElementByElementGuidHandler'
import LogicTreeElementByGuidHandler from '@/services/LogicEditor/application/handler/query/LogicTreeElementByGuidHandler'
import LogicTreeElementsHandler from '@/services/LogicEditor/application/handler/query/LogicTreeElementsHandler'
import LogicTreeElementsSearchHandler from '@/services/LogicEditor/application/handler/query/LogicTreeElementsSearchHandler'
import PluginsHandler from '@/services/LogicEditor/application/handler/query/PluginsHandler'
import CommandEventTypesHandler from '@/services/LogicEditor/application/handler/query/CommandEventTypesHandler'

import ApprovalByGuidQuery from '@/services/LogicEditor/application/query/ApprovalByGuidQuery'
import CommandByGuidQuery from '@/services/LogicEditor/application/query/CommandByGuidQuery'
import LogicByGuidQuery from '@/services/LogicEditor/application/query/LogicByGuidQuery'
import RelatedObjectByGuidQuery from '@/services/LogicEditor/application/query/RelatedObjectByGuidQuery'
import ListenerByGuidQuery from '@/services/LogicEditor/application/query/ListenerByGuidQuery'
import FormulaByGuidQuery from '@/services/LogicEditor/application/query/FormulaByGuidQuery'
import StateByGuidQuery from '@/services/LogicEditor/application/query/StateByGuidQuery'
import LogicGroupByGuidQuery from '@/services/LogicEditor/application/query/LogicGroupByGuidQuery'
import ConstraintByGuidQuery from '@/services/LogicEditor/application/query/ConstraintByGuidQuery'

import { ApprovalDTO } from '@/services/LogicEditor/domain/model/Approval'
import { CommandDTO } from '@/services/LogicEditor/domain/model/Command'
import { ConstraintDTO } from '@/services/LogicEditor/domain/model/Constraint'
import { FormulaDTO } from '@/services/LogicEditor/domain/model/Formula'
import { ListenerDTO } from '@/services/LogicEditor/domain/model/Listener'
import { LogicDTO } from '@/services/LogicEditor/domain/model/Logic'
import { LogicGroupDTO } from '@/services/LogicEditor/domain/model/LogicGroup'
import { RelatedObjectDTO } from '@/services/LogicEditor/domain/model/RelatedObject'
import { RowFormulaDTO } from '@/services/LogicEditor/domain/model/RowFormula'
import { StateDTO } from '@/services/LogicEditor/domain/model/State'

// FEAT
import { ApprovalStageDTO } from '@/services/LogicEditor/domain/model/ApprovalStage'
import ApprovalStageByGuidQuery from '@/services/LogicEditor/application/query/ApprovalStageByGuidQuery'
import ApprovalStageByGuidHandler from '@/services/LogicEditor/application/handler/query/ApprovalStageByGuidHandler'
import ApprovalStageQueryRepository from '@/services/LogicEditor/infrastructure/domain/repository/ApprovalStageQueryRepository'
import ApprovalStageCommandRepository from '@/services/LogicEditor/infrastructure/domain/repository/ApprovalStageCommandRepository'
import ApprovalStageCreateHandler from '@/services/LogicEditor/application/handler/command/ApprovalStageCreateHandler'
import ApprovalStageUpdateHandler from '@/services/LogicEditor/application/handler/command/ApprovalStageUpdateHandler'
import ApprovalStageDeleteHandler from '@/services/LogicEditor/application/handler/command/ApprovalStageDeleteHandler'

// FIX
import RowFormulaByGuidQuery from '@/services/LogicEditor/application/query/RowFormulaByGuidQuery'
import RowFormulaByGuidHandler from '@/services/LogicEditor/application/handler/query/RowFormulaByGuidHandler'
import RowFormulaDeleteCommand from '@/services/LogicEditor/application/command/RowFormulaDeleteCommand'
import RowFormulaQueryRepository from '@/services/LogicEditor/infrastructure/domain/repository/RowFormulaQueryRepository'
import RowFormulaCommandRepository from '@/services/LogicEditor/infrastructure/domain/repository/RowFormulaCommandRepository'
import RowFormulaCreateHandler from '@/services/LogicEditor/application/handler/command/RowFormulaCreateHandler'
import RowFormulaUpdateHandler from '@/services/LogicEditor/application/handler/command/RowFormulaUpdateHandler'
import RowFormulaDeleteHandler from '@/services/LogicEditor/application/handler/command/RowFormulaDeleteHandler'
import { ProcedureDTO } from '@/services/LogicEditor/domain/model/Procedure'
import ProcedureCreateHandler from '@/services/LogicEditor/application/handler/command/ProcedureCreateHandler'
import ProcedureCommandRepository
  from '@/services/LogicEditor/infrastructure/domain/repository/ProcedureCommandRepository'
import ProcedureUpdateHandler from '@/services/LogicEditor/application/handler/command/ProcedureUpdateHandler'
import ProcedureDeleteHandler from '@/services/LogicEditor/application/handler/command/ProcedureDeleteHandler'
import ProcedureByGuidHandler from '@/services/LogicEditor/application/handler/query/ProcedureByGuidHandler'
import ProceduresHandler from '@/services/LogicEditor/application/handler/query/ProceduresHandler'
import ProcedureQueryRepository from '@/services/LogicEditor/infrastructure/domain/repository/ProcedureQueryRepository'
import ProcedureDeleteCommand from '@/services/LogicEditor/application/command/ProcedureDeleteCommand'
import ProcedureByGuidQuery from '@/services/LogicEditor/application/query/ProcedureByGuidQuery'

export default {
  data: () => {
    return {
      defaultModel: {
        approval: ApprovalDTO,
        approval_stage: ApprovalStageDTO,
        command: CommandDTO,
        constraint: ConstraintDTO,
        view_constraint: ConstraintDTO,
        disable_constraint: ConstraintDTO,
        formula: FormulaDTO,
        listener: ListenerDTO,
        logic: LogicDTO,
        group: LogicGroupDTO,
        related_object: RelatedObjectDTO,
        row_formula: RowFormulaDTO,
        state: StateDTO,
        procedure: ProcedureDTO
      },

      form: {
        approval: 'ApprovalForm',
        approvalStage: 'ApprovalStageForm',
        logic: 'LogicForm',
        state: 'StateForm',
        group: 'LogicGroupForm',
        constraint: 'ConstraintForm',
        view_constraint: 'ViewConstraintForm',
        disable_constraint: 'DisableConstraintForm',
        listener: 'ListenerForm',
        command: 'CommandForm',
        related_object: 'RelatedObjectForm',
        formula: 'FormulaForm',
        row_formula: 'RowFormulaForm',
        procedure: 'ProcedureForm'
      },

      queryByGuid: {
        approval: ApprovalByGuidQuery,
        logic: LogicByGuidQuery,
        state: StateByGuidQuery,
        group: LogicGroupByGuidQuery,
        constraint: ConstraintByGuidQuery,
        view_constraint: ConstraintByGuidQuery,
        disable_constraint: ConstraintByGuidQuery,
        command: CommandByGuidQuery,
        listener: ListenerByGuidQuery,
        formula: FormulaByGuidQuery,
        row_formula: RowFormulaByGuidQuery,
        related_object: RelatedObjectByGuidQuery,
        approval_stage: ApprovalStageByGuidQuery,
        procedure: ProcedureByGuidQuery
      },

      deleteCommands: {
        logic: LogicDeleteCommand,
        state: StateDeleteCommand,
        group: LogicGroupDeleteCommand,
        approval: ApprovalDeleteCommand,
        command: CommandDeleteCommand,
        listener: ListenerDeleteCommand,
        formula: FormulaDeleteCommand,
        row_formula: RowFormulaDeleteCommand,
        constraint: ConstraintDeleteCommand,
        view_constraint: ConstraintDeleteCommand,
        disable_constraint: ConstraintDeleteCommand,
        related_object: RelatedObjectDeleteCommand,
        procedure: ProcedureDeleteCommand
      },

      eventBus: new Vue(),

      commandBus: new CommandBus(
        new CommandBusHandlerLocator({
          'ApprovalCreateCommand': new ApprovalCreateHandler(
            new ApprovalCommandRepository()
          ),
          'ApprovalUpdateCommand': new ApprovalUpdateHandler(
            new ApprovalCommandRepository()
          ),
          'ApprovalDeleteCommand': new ApprovalDeleteHandler(
            new ApprovalCommandRepository()
          ),
          'ApprovalStageCreateCommand': new ApprovalStageCreateHandler(
            new ApprovalStageCommandRepository()
          ),
          'ApprovalStageUpdateCommand': new ApprovalStageUpdateHandler(
            new ApprovalStageCommandRepository()
          ),
          'ApprovalStageDeleteCommand': new ApprovalStageDeleteHandler(
            new ApprovalStageCommandRepository()
          ),
          'RelatedObjectCreateCommand': new RelatedObjectCreateHandler(
            new RelatedObjectCommandRepository()
          ),
          'RelatedObjectUpdateCommand': new RelatedObjectUpdateHandler(
            new RelatedObjectCommandRepository()
          ),
          'RelatedObjectDeleteCommand': new RelatedObjectDeleteHandler(
            new RelatedObjectCommandRepository()
          ),
          'CommandCreateCommand': new CommandCreateHandler(
            new CommandCommandRepository()
          ),
          'CommandUpdateCommand': new CommandUpdateHandler(
            new CommandCommandRepository()
          ),
          'CommandDeleteCommand': new CommandDeleteHandler(
            new CommandCommandRepository()
          ),
          'ConstraintCreateCommand': new ConstraintCreateHandler(
            new ConstraintCommandRepository()
          ),
          'ConstraintUpdateCommand': new ConstraintUpdateHandler(
            new ConstraintCommandRepository()
          ),
          'ConstraintDeleteCommand': new ConstraintDeleteHandler(
            new ConstraintCommandRepository()
          ),
          'ListenerCreateCommand': new ListenerCreateHandler(
            new ListenerCommandRepository()
          ),
          'ListenerUpdateCommand': new ListenerUpdateHandler(
            new ListenerCommandRepository()
          ),
          'ListenerDeleteCommand': new ListenerDeleteHandler(
            new ListenerCommandRepository()
          ),
          'LogicCreateCommand': new LogicCreateHandler(
            new LogicCommandRepository()
          ),
          'LogicUpdateCommand': new LogicUpdateHandler(
            new LogicCommandRepository()
          ),
          'LogicDeleteCommand': new LogicDeleteHandler(
            new LogicCommandRepository()
          ),
          'StateCreateCommand': new StateCreateHandler(
            new StateCommandRepository()
          ),
          'StateUpdateCommand': new StateUpdateHandler(
            new StateCommandRepository()
          ),
          'StateDeleteCommand': new StateDeleteHandler(
            new StateCommandRepository()
          ),
          'LogicGroupCreateCommand': new LogicGroupCreateHandler(
            new LogicGroupCommandRepository()
          ),
          'LogicGroupUpdateCommand': new LogicGroupUpdateHandler(
            new LogicGroupCommandRepository()
          ),
          'LogicGroupDeleteCommand': new LogicGroupDeleteHandler(
            new LogicGroupCommandRepository()
          ),
          'FormulaCreateCommand': new FormulaCreateHandler(
            new FormulaCommandRepository()
          ),
          'FormulaUpdateCommand': new FormulaUpdateHandler(
            new FormulaCommandRepository()
          ),
          'FormulaDeleteCommand': new FormulaDeleteHandler(
            new FormulaCommandRepository()
          ),
          'RowFormulaCreateCommand': new RowFormulaCreateHandler(
            new RowFormulaCommandRepository()
          ),
          'RowFormulaUpdateCommand': new RowFormulaUpdateHandler(
            new RowFormulaCommandRepository()
          ),
          'RowFormulaDeleteCommand': new RowFormulaDeleteHandler(
            new RowFormulaCommandRepository()
          ),
          'ProcedureCreateCommand': new ProcedureCreateHandler(
            new ProcedureCommandRepository()
          ),
          'ProcedureUpdateCommand': new ProcedureUpdateHandler(
            new ProcedureCommandRepository()
          ),
          'ProcedureDeleteCommand': new ProcedureDeleteHandler(
            new ProcedureCommandRepository()
          )
        })
      ),

      queryBus: new QueryBus(
        new QueryBusHandlerLocator({
          'ApprovalByGuidQuery': new ApprovalByGuidHandler(
            new ApprovalQueryRepository()
          ),
          'ApprovalStagesByApprovalIdQuery': new ApprovalStagesByApprovalIdHandler(
            new ApprovalQueryRepository()
          ),
          'ApprovalStageTypesQuery': new ApprovalStageTypesHandler(
            new ApprovalQueryRepository()
          ),
          'ApprovalStageByGuidQuery': new ApprovalStageByGuidHandler(
            new ApprovalStageQueryRepository()
          ),
          'RelatedObjectByGuidQuery': new RelatedObjectByGuidHandler(
            new RelatedObjectQueryRepository()
          ),
          'StateByGuidQuery': new StateByGuidHandler(
            new StateQueryRepository()
          ),
          'StateOperationTypesQuery': new StateOperationTypesHandler(
            new StateQueryRepository()
          ),
          'StateOperationTypeEntityTypesQuery': new StateOperationTypeEntityTypesHandler(
            new StateQueryRepository()
          ),
          'StateFieldTypesQuery': new StateFieldTypesHandler(
            new StateQueryRepository()
          ),
          'ConditionFieldTypesQuery': new ConditionFieldTypesHandler(
            new StateQueryRepository()
          ),
          'ConstraintByGuidQuery': new ConstraintByGuidHandler(
            new ConstraintQueryRepository()
          ),
          'ConstraintTypesQuery': new ConstraintTypesHandler(
            new ConstraintQueryRepository()
          ),
          'CommandByGuidQuery': new CommandByGuidHandler(
            new CommandQueryRepository()
          ),
          'CommandEventTypePropertiesQuery': new CommandEventTypePropertiesHandler(
            new CommandQueryRepository()
          ),
          'CommandsQuery': new CommandsHandler(
            new CommandQueryRepository()
          ),
          'ListenerByGuidQuery': new ListenerByGuidHandler(
            new ListenerQueryRepository()
          ),
          'FormulaByGuidQuery': new FormulaByGuidHandler(
            new FormulaQueryRepository()
          ),
          'RowFormulaByGuidQuery': new RowFormulaByGuidHandler(
            new RowFormulaQueryRepository()
          ),
          'LogicByGuidQuery': new LogicByGuidHandler(
            new LogicQueryRepository()
          ),
          'LogicQuery': new LogicHandler(
            new LogicQueryRepository()
          ),
          'LogicGroupByGuidQuery': new LogicGroupByGuidHandler(
            new LogicGroupQueryRepository()
          ),
          'LogicTreeElementsTreeQuery': new LogicTreeElementsTreeHandler(
            new LogicTreeElementQueryRepository()
          ),
          'LogicTreeElementByElementGuidQuery': new LogicTreeElementByElementGuidHandler(
            new LogicTreeElementQueryRepository()
          ),
          'LogicTreeElementByGuidQuery': new LogicTreeElementByGuidHandler(
            new LogicTreeElementQueryRepository()
          ),
          'LogicTreeElementsQuery': new LogicTreeElementsHandler(
            new LogicTreeElementQueryRepository()
          ),
          'LogicTreeElementsSearchQuery': new LogicTreeElementsSearchHandler(
            new LogicTreeElementQueryRepository()
          ),
          'PluginsQuery': new PluginsHandler(
            new PluginQueryRepository()
          ),
          'CommandEventTypesQuery': new CommandEventTypesHandler(
            new CommandQueryRepository()
          ),
          'ProcedureByGuidQuery': new ProcedureByGuidHandler(
            new ProcedureQueryRepository()
          ),
          'ProceduresQuery': new ProceduresHandler(
            new ProcedureQueryRepository()
          )
        })
      )
    }
  },

  provide () {
    return {
      getEventBus: this.getEventBus,
      getCommandBus: this.getCommandBus,
      getQueryBus: this.getQueryBus
    }
  },

  methods: {
    getEventBus () {
      return this.eventBus
    },

    getCommandBus () {
      return this.commandBus
    },

    getQueryBus () {
      return this.queryBus
    }
  }
}
