import { MutationTree } from 'vuex'
import { GroupState } from './state'
import { MutationTypes, GroupCreatedEvent, GroupUpdatedEvent, GroupDeletedEvent } from './mutation-types'

export const mutations: MutationTree<GroupState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false
    state.error = error
  },
  [MutationTypes.GROUP_LOADING_EVENT]: (state) => {
    state.isLoading = true
  },
  [MutationTypes.GROUP_LOADED_EVENT]: (state) => {
    state.isLoading = false
  },
  [MutationTypes.GROUP_CREATED_EVENT]: (state, action: GroupCreatedEvent) => {
    state.isLoading = false
    state.location = action.location
  },
  [MutationTypes.GROUP_UPDATED_EVENT]: (state, action: GroupUpdatedEvent) => {
    state.isLoading = false
    state.location = action.location
  },
  [MutationTypes.GROUP_DELETED_EVENT]: (state, action: GroupDeletedEvent) => {
    state.isLoading = false
    state.deletedGroupId = action.id
  }
}
