import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { ModelDTO } from '@/services/DigitalTwinEditor/domain/model/Model/Model'
import { ModelFileDTO } from '@/services/DigitalTwinEditor/domain/model/File/ModelFile'

export namespace ModelAPI {

    export class UpdateModel implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: ModelDTO) {
            this.path = `/digitaltwineditor/models/${params.guid}`;
        }
    }

    export class GetModelByGuid implements APIRequest<ModelDTO> {
        response: ModelDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/digitaltwineditor/models/${guid}`;
        }
    }

    export class GetModelFiles implements APIRequest<Array<ModelFileDTO>> {
        response: Array<ModelFileDTO>;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(modelGuid: string, public params: string[][]) {
            this.path = `/digitaltwineditor/models/${modelGuid}/files`;
        }
    }
}