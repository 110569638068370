import { ActionTree } from 'vuex'
import { TransformerState, initialState } from '@/services/EtlEditor/infrastructure/store/modules/Transformer/state'
import { ActionTypes } from './action-types'
import { MutationTypes, TransformerUpdatedEvent, TransformerCreatedEvent, TransformerLoadingEvent, TransformerDeletedEvent, TransformerLoadedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { TransformerAPI } from '@/services/EtlEditor/infrastructure/api/TransformerAPI'

export const actions: ActionTree<TransformerState, any> = {
  [ActionTypes.GET_TRANSFORMER]: async ({ commit, dispatch }, data) => {
    try {
      commit(new TransformerLoadingEvent())
      let response = await APIClient.shared.request(new TransformerAPI.GetTransformer(data.payload))
      commit(new TransformerLoadedEvent())
      return response
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_TRANSFORMER_BY_GUID]: async ({ commit, dispatch }, data) => {
    try {
      return await APIClient.shared.request(new TransformerAPI.GetTransformerByGuid(data.guid))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_TRANSFORMER_TYPE_PROPERTIES]: async ({ commit, dispatch }, data) => {
    try {
      return await APIClient.shared.request(new TransformerAPI.GetTransformerTypeProperties(data.transformerTypeId))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.CREATE_TRANSFORMER]: async ({ commit, dispatch }, data) => {
    try {
      commit(new TransformerLoadingEvent())
      let location = await APIClient.shared.request(new TransformerAPI.CreateTransformer(data.payload))
      commit(new TransformerCreatedEvent(location))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.UPDATE_TRANSFORMER]: async ({ commit, dispatch }, data) => {
    try {
      commit(new TransformerLoadingEvent())
      let location = await APIClient.shared.request(new TransformerAPI.UpdateTransformer(data.payload))
      commit(new TransformerUpdatedEvent(location))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.DELETE_TRANSFORMER]: async ({ commit, dispatch }, data) => {
    try {
      await APIClient.shared.request(new TransformerAPI.DeleteTransformer(data.guid))
      commit(new TransformerDeletedEvent(data.guid))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  }
}
