import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { Request } from '@/services/ApiService/domain/model/Request/Request'
import { RequestParameter } from '@/services/ApiService/domain/model/Request/RequestParameter'

export enum ActionTypes {
  GET_REQUESTS = 'get_requests',
  CREATE_REQUEST = 'create_request',
  DELETE_REQUEST = 'delete_request',
  GET_REQUEST_SCHEME_ENTITIES = 'get_request_scheme_entities',
  UPDATE_REQUEST_ENTITY = 'update_request_entity',
  GET_REQUEST_PARAMETERS = 'get_request_parameters',
  CREATE_REQUEST_PARAMETER = 'create_request_parameter',
  DELETE_REQUEST_PARAMETER = 'delete_request_parameter',
  UPDATE_REQUEST_PARAMETER = 'update_request_parameter',
  GET_EXPORT_STRUCTURE = 'get_export_structure',
  IMPORT_STRUCTURE = 'import_structure'
}

export class GetRequests implements FluxStandardAction {
    type = 'Request/' + ActionTypes.GET_REQUESTS
    constructor (public payload: object) {}
}

export class CreateRequest implements FluxStandardAction {
    type = 'Request/' + ActionTypes.CREATE_REQUEST
    constructor (public payload: object) {}
}

export class DeleteRequest implements FluxStandardAction {
  type = 'Request/' + ActionTypes.DELETE_REQUEST
  constructor (public guid: string) {}
}

export class GetRequestSchemeEntities implements FluxStandardAction {
  type = 'Request/' + ActionTypes.GET_REQUEST_SCHEME_ENTITIES
}

export class UpdateRequestEntity implements FluxStandardAction {
  type = 'Request/' + ActionTypes.UPDATE_REQUEST_ENTITY
  constructor (public payload: Request, public entityType: string) {}
}

export class GetRequestParameters implements FluxStandardAction {
  type = 'Request/' + ActionTypes.GET_REQUEST_PARAMETERS
  constructor (public guid: string) {}
}

export class CreateRequestParameter implements FluxStandardAction {
  type = 'Request/' + ActionTypes.CREATE_REQUEST_PARAMETER
  constructor (public payload: object, public requestGuid: string) {}
}

export class DeleteRequestParameter implements FluxStandardAction {
  type = 'Request/' + ActionTypes.DELETE_REQUEST_PARAMETER
  constructor (public guid: string) {}
}

export class UpdateRequestParameter implements FluxStandardAction {
  type = 'Request/' + ActionTypes.UPDATE_REQUEST_PARAMETER
  constructor (public payload: RequestParameter) {}
}

export class GetExportStructure implements FluxStandardAction {
  type = 'Request/' + ActionTypes.GET_EXPORT_STRUCTURE
  constructor (public id: number) {}
}

export class ImportStructure implements FluxStandardAction {
  type = 'Request/' + ActionTypes.IMPORT_STRUCTURE
  constructor (public payload: object) {}
}
