import { ActionTree } from 'vuex'
import { MigrationState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, MigrationLoadingEvent, MigrationLoadedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { MigrationAPI } from '@/services/SyncService/infrastructure/api/MigrationAPI'

export const actions: ActionTree<MigrationState, any> = {
  [ActionTypes.GET_MIGRATIONS]: async ({ commit, dispatch }, data) => {
    try {
      commit(new MigrationLoadingEvent())
      let res = await APIClient.shared.request(new MigrationAPI.GetMigrations(data.payload))
      commit(new MigrationLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.COUNT_MIGRATIONS]: async ({ commit, dispatch }, data) => {
    try {
      commit(new MigrationLoadingEvent())
      let res = await APIClient.shared.request(new MigrationAPI.CountMigrations(data.payload))
      commit(new MigrationLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  }
}
