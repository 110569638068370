import LayerCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/LayerCommandRepositoryInterface'
import { LayerDTO } from '@/services/DigitalTwinEditor/domain/model/Layer/Layer'
import store from '@/store'
import { UpdateLayer } from '@/services/DigitalTwinEditor/infrastructure/store/modules/Layer/action-types'

export default class LayerCommandRepository implements LayerCommandRepositoryInterface
{
    update(item: LayerDTO): Promise<void>
    {
        return store.dispatch(new UpdateLayer(item));
    }
}
