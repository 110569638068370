import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    SOURCE_LOADING_EVENT = 'source_loading_event',
    SOURCE_LOADED_EVENT = 'source_loaded_event'
}

export class SourceLoadingEvent implements FluxStandardAction {
  type = MutationTypes.SOURCE_LOADING_EVENT
}

export class SourceLoadedEvent implements FluxStandardAction {
  type = MutationTypes.SOURCE_LOADED_EVENT
}
