import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    TEMPLATE_LOADING_EVENT = 'template_loading_event',
    TEMPLATE_CREATED_EVENT = 'template_created_event',
    TEMPLATE_UPDATED_EVENT = 'template_updated_event',
    TEMPLATE_DELETED_EVENT = 'template_deleted_event'
}

export class TemplateLoadingEvent implements FluxStandardAction {
  type = MutationTypes.TEMPLATE_LOADING_EVENT
}

export class TemplateCreatedEvent implements FluxStandardAction {
  type = MutationTypes.TEMPLATE_CREATED_EVENT
  constructor (public location: string) {}
}

export class TemplateUpdatedEvent implements FluxStandardAction {
  type = MutationTypes.TEMPLATE_UPDATED_EVENT
  constructor (public location: string) {}
}

export class TemplateDeletedEvent implements FluxStandardAction {
  type = MutationTypes.TEMPLATE_DELETED_EVENT
  constructor (public guid: string) {}
}
