import { ActionTree } from 'vuex'
import { OpenWorldState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, OpenWorldUpdatedEvent, OpenWorldLoadingEvent, OpenWorldLoadedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { OpenWorldAPI } from '@/services/DigitalTwinEditor/infrastructure/api/OpenWorldAPI'

export const actions: ActionTree<OpenWorldState, any> = {
    [ActionTypes.UPDATE_OPEN_WORLD]: async ({ commit, dispatch }, data) => {
        try {
            commit(new OpenWorldLoadingEvent());
            await APIClient.shared.request(new OpenWorldAPI.UpdateOpenWorld(data.payload));
            commit(new OpenWorldUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.GET_OPEN_WORLD_BY_GUID]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new OpenWorldAPI.GetOpenWorldByGuid(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    },
    [ActionTypes.SET_DEFAULT_TEXTURES]: async ({ commit, dispatch }, data) => {
        try {
            commit(new OpenWorldLoadingEvent());
            await APIClient.shared.request(new OpenWorldAPI.SetDefaultTextures(data.payload));
            commit(new OpenWorldUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.SET_TERRAIN]: async ({ commit, dispatch }, data) => {
        try {
            commit(new OpenWorldLoadingEvent());
            await APIClient.shared.request(new OpenWorldAPI.SetTerrain(data.payload));
            commit(new OpenWorldUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.SET_LANDSCAPE]: async ({ commit, dispatch }, data) => {
        try {
            commit(new OpenWorldLoadingEvent());
            await APIClient.shared.request(new OpenWorldAPI.SetLandscape(data.payload));
            commit(new OpenWorldUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.STOP_TILING]: async ({ commit, dispatch }, data) => {
        try {
            commit(new OpenWorldLoadingEvent());
            await APIClient.shared.request(new OpenWorldAPI.StopTiling(data.payload));
            commit(new OpenWorldUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.UPDATE_STYLE]: async ({ commit, dispatch }, data) => {
        try {
            commit(new OpenWorldLoadingEvent());
            await APIClient.shared.request(new OpenWorldAPI.UpdateStyle(data.payload));
            commit(new OpenWorldUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.GET_LANDSCAPE_TEXTURES]: async ({ commit, dispatch }, data) => {
        try {
            commit(new OpenWorldLoadingEvent())
            let res = await APIClient.shared.request(new OpenWorldAPI.GetLandscapeTextures(data.payload))
            commit(new OpenWorldLoadedEvent())
            return res
        } catch (error) {
            commit(MutationTypes.ERROR, error)
        }
    },
    [ActionTypes.COUNT_LANDSCAPE_TEXTURES]: async ({ commit, dispatch }, data) => {
        try {
            commit(new OpenWorldLoadingEvent())
            let res = await APIClient.shared.request(new OpenWorldAPI.CountLandscapeTextures(data.payload))
            commit(new OpenWorldLoadedEvent())
            return res
        } catch (error) {
            commit(MutationTypes.ERROR, error)
        }
    },
    [ActionTypes.GET_OPEN_WORLD_GEOJSON]: async ({ commit, dispatch }, data) => {
        try {
            commit(new OpenWorldLoadingEvent())
            let res = await APIClient.shared.request(new OpenWorldAPI.GetOpenWorldGeojson(data.payload))
            commit(new OpenWorldLoadedEvent())
            return res
        } catch (error) {
            commit(MutationTypes.ERROR, error)
        }
    },
    [ActionTypes.GET_OPEN_WORLD_STYLE]: async ({ commit, dispatch }, data) => {
        try {
            commit(new OpenWorldLoadingEvent())
            let res = await APIClient.shared.request(new OpenWorldAPI.GetOpenWorldStyle(data.payload))
            commit(new OpenWorldLoadedEvent())
            return res
        } catch (error) {
            commit(MutationTypes.ERROR, error)
        }
    }
}
