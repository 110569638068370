import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { ProjectGroupDTO } from '@/services/MapEditor/domain/model/Project/ProjectGroup'

export enum ActionTypes {
    UPDATE_PROJECT_GROUP = 'update_project_group'
}

export class UpdateProjectGroup implements FluxStandardAction {
    type = 'ProjectGroup/' + ActionTypes.UPDATE_PROJECT_GROUP
    constructor(public payload: ProjectGroupDTO){}
}