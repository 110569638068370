import { FluxStandardAction, Nullable } from '@/core/domain/type/types'

export enum ActionTypes {
    GET_SNAPSHOTS = 'get_snapshots',
    COUNT_SNAPSHOTS = 'count_snapshots',
    FILTER_SNAPSHOTS = 'filter_snapshots',
    GET_SCHEMA_FILTER = 'schema_filter_values',
    GET_TABLES_BY_SCHEMAS = 'tables_filter_values'
}

export class GetSnapshots implements FluxStandardAction {
  type = 'Snapshot/' + ActionTypes.GET_SNAPSHOTS
  constructor (public payload: object) {}
}

export class CountSnapshots implements FluxStandardAction {
  type = 'Snapshot/' + ActionTypes.COUNT_SNAPSHOTS
  constructor (public payload: object) {}
}

export class FilterSnapshots implements FluxStandardAction {
  type = 'Snapshot/' + ActionTypes.FILTER_SNAPSHOTS
  constructor (public payload: object) {}
}

export class GetSchemaFilter implements FluxStandardAction {
  type = 'Snapshot/' + ActionTypes.GET_SCHEMA_FILTER
  constructor () {}
}

export class GetTablesFilter implements FluxStandardAction {
  type = 'Snapshot/' + ActionTypes.GET_TABLES_BY_SCHEMAS
  constructor (public payload: object) {}
}
