import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import StrokeStyleCommandRepositoryInterface from '@/core/domain/repository/modules/StrokeStyleCommandRepositoryInterface'
import StrokeStyleCreateCommand from '@/core/application/command/modules/StrokeStyle/StrokeStyleCreateCommand'
import { StrokeStyleDTO } from '@/core/domain/model/modules/StrokeStyle'

export default class StrokeStyleCreateHandler implements CommandHandlerInterface
{
    private repo: StrokeStyleCommandRepositoryInterface;

    constructor(repo: StrokeStyleCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: StrokeStyleCreateCommand): Promise<void>
    {
        let item: StrokeStyleDTO = {
            name: command.getName(),
            description: command.getDescription(),
            pattern: command.getPattern()
        };
        return this.repo.insert(item);
    }
}