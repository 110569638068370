import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { TemplateDTO } from '@/services/EtlEditor/domain/model/Template'

export namespace TemplateAPI {
  export class GetTemplateByGuid implements APIRequest<TemplateDTO> {
    response: TemplateDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/etleditor/templates/${guid}`
    }
  }

  export class CreateTemplate implements APIRequest<string> {
    response: string;
    path = '/v2/etleditor/templates';
    method = HTTPMethod.POST;
    parse = (data: AxiosResponse) => data.headers.location
    constructor (public params: TemplateDTO) {}
  }

  export class UpdateTemplate implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.PUT;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: TemplateDTO) {
      this.path = `/v2/etleditor/templates/${params.guid}`
    }
  }

  export class DeleteTemplate implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.DELETE;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/etleditor/templates/${guid}`
    }
  }
}
