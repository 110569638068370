import { ActionTree } from 'vuex'
import { RelatedObjectState, initialState } from './state'
import { ActionTypes } from './action-types'
import {
  MutationTypes,
  RelatedObjectLoadingEvent,
  RelatedObjectCreatedEvent,
  RelatedObjectUpdatedEvent,
  RelatedObjectDeletedEvent,
  RelatedObjectLoadedEvent
} from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { RelatedObjectAPI } from '@/services/LogicEditor/infrastructure/api/RelatedObjectAPI'

export const actions: ActionTree<RelatedObjectState, any> = {
  [ActionTypes.GET_RELATED_OBJECT_BY_GUID]: async ({ commit, dispatch }, data) => {
    commit(new RelatedObjectLoadingEvent())

    return APIClient.shared.request(new RelatedObjectAPI.GetRelatedObjectByGuid(data.guid))
      .then((response) => {
        commit(new RelatedObjectLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.CREATE_RELATED_OBJECT]: async ({ commit, dispatch }, data) => {
    commit(new RelatedObjectLoadingEvent())

    return APIClient.shared.request(new RelatedObjectAPI.CreateRelatedObject(data.payload))
      .then((location) => {
        commit(new RelatedObjectCreatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.UPDATE_RELATED_OBJECT]: async ({ commit, dispatch }, data) => {
    commit(new RelatedObjectLoadingEvent())

    return APIClient.shared.request(new RelatedObjectAPI.UpdateRelatedObject(data.payload))
      .then((location) => {
        commit(new RelatedObjectUpdatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.DELETE_RELATED_OBJECT]: async ({ commit, dispatch }, data) => {
    commit(new RelatedObjectLoadingEvent())

    return APIClient.shared.request(new RelatedObjectAPI.DeleteRelatedObject(data.guid))
      .then(() => {
        commit(new RelatedObjectDeletedEvent(data.guid))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  }
}
