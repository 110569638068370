export interface ILog {
  _id: string
  resource: string
  type: string
  ip: string
  pid: number
  message: string
  date: string
}

export default class LogDTO {
  public _id: string
  public resource: string
  public type: string
  public ip: string
  public pid: number
  public message: string
  public date: string

  constructor (data: ILog) {
    this._id = data._id
    this.resource = data.resource
    this.type = data.type
    this.ip = data.ip
    this.pid = data.pid
    this.message = data.message
    this.date = new Date(data.date).toLocaleString()
  }
}
