<template>
  <el-select
    v-bind:value="value"
    v-on:input="$emit('input', $event)"
    :placeholder="placeholder"
    :multiple="true"
  >
    <el-option
      v-for="item in xrefList"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    ></el-option>
  </el-select>
</template>

<script>
export default {
  name: 'XrefMultiInput',

  props: {
    value: Array,

    placeholder: String,

    xrefList: Array
  }
}
</script>

<style type="text/css"></style>
