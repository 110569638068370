import { FluxStandardAction } from '@/core/domain/type/types'
import { RowFormulaDTO } from '@/services/LogicEditor/domain/model/RowFormula'

export enum ActionTypes {
  GET_ROW_FORMULA = 'get_row_formula',
  GET_ROW_FORMULA_BY_GUID = 'get_row_formula_by_guid',
  CREATE_ROW_FORMULA = 'create_row_formula',
  UPDATE_ROW_FORMULA = 'update_row_formula',
  DELETE_ROW_FORMULA = 'delete_row_formula'
}

export class GetRowFormulaByGuid implements FluxStandardAction {
  type = 'RowFormula/' + ActionTypes.GET_ROW_FORMULA_BY_GUID
  constructor (public guid: string) {}
}

export class CreateRowFormula implements FluxStandardAction {
  type = 'RowFormula/' + ActionTypes.CREATE_ROW_FORMULA
  constructor (public payload: RowFormulaDTO) {}
}

export class UpdateRowFormula implements FluxStandardAction {
  type = 'RowFormula/' + ActionTypes.UPDATE_ROW_FORMULA
  constructor (public payload: RowFormulaDTO) {}
}

export class DeleteRowFormula implements FluxStandardAction {
  type = 'RowFormula/' + ActionTypes.DELETE_ROW_FORMULA
  constructor (public guid: string) {}
}
