import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ProjectCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/ProjectCommandRepositoryInterface'
import ProjectBuildCommand from '@/services/DigitalTwinEditor/application/command/ProjectBuildCommand'
import { ProjectDTO } from '@/services/DigitalTwinEditor/domain/model/Project/Project'

export default class ProjectBuildHandler implements CommandHandlerInterface
{
    private repo: ProjectCommandRepositoryInterface;

    constructor(repo: ProjectCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: ProjectBuildCommand): Promise<void>
    {
        let item: ProjectDTO = {
            guid: command.getGuid(),
            template_id: command.getTemplate()
        };
        return this.repo.buildProject(item);
    }
}