import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import TextureQueryRepositoryInterface from '../../../domain/repository/TextureQueryRepositoryInterface'
import TexturesCountQuery from '../../query/TexturesCountQuery'

export default class TexturesCountHandler implements QueryHandlerInterface {
    private repo: TextureQueryRepositoryInterface;

    constructor (repo: TextureQueryRepositoryInterface) {
      this.repo = repo
    }

    execute (query: TexturesCountQuery): Promise<any> {
      return this.repo.getCount(query.getPayload())
    }
}
