<template>
  <div>
    <p v-if="message" v-html="message"></p>
    <hr/>
    <div id="list" v-loading="loading">
      <el-scrollbar wrap-style="max-height: 388px;">
        <certificate-list
          v-model="certificate"
          v-bind:certificates="certificates"
          v-if="certificates.length && step === 1"
        >
        </certificate-list>
        <info id="singning-info" v-if="step == 3 && data" v-bind:data="data"></info>
        <el-row v-if="archive && status==='success'" type="flex" justify="center">
          <el-button plain @click="download(archive)" size="small">Cкачать {{archive.name}}.{{archive.extension}}</el-button>
        </el-row>
      </el-scrollbar>
    </div>
    <el-progress :percentage="getPersentage" id="progress" v-bind:status="status"></el-progress>
    <el-row type="flex" justify="center">
      <el-button
        size="small"
        v-on:click="next"
        v-if="btn_next_visible"
        type="primary"
      >Далее
      </el-button>
      <el-button size="small" v-on:click="close" v-if="step<7">Отмена</el-button>
      <el-button size="small" v-on:click="close" v-if="step==7" type="success">Готово</el-button>
    </el-row>
  </div>
</template>

<script>
import CriptoHandler from './models/CriptoHandler.ts';
import CertificateList from './components/CertificateList';
import Info from './components/Info';
import CommandExecutor from '@/core/infrastructure/service/CommandExecutor';
import EDSQueryRepository from "@/core/infrastructure/domain/repository/modules/EDSQueryRepository";

export default {
  name: 'EDS',
  props: ['command', 'context', 'callback'],
  data () {
    return {
      certificate: {},
      message: '',
      crypto: new CriptoHandler(),
      loading: true,
      certificates: [],
      percentage: 0,
      step: 0,
      status: null,
      files: [],
      data: null,
      archive: null,
      btn_next_visible: false,
      needRollback: false,
      queryRepository: new EDSQueryRepository(),
      fileWithInfoAboutField: null,
      fileWithPrevSignedInfo: null,
      xmlToSign: undefined
    }
  },
  computed: {
    getPersentage: function () {
      return Math.ceil(this.step * 100 / 7)
    }
  },
  components: {
    CertificateList,
    Info,
    EDSQueryRepository
  },
  async mounted () {
    this.next()
  },
  methods: {
    async next () {
      this.step++
      console.log('' + this.step + ': ' + this.message)
      this.loading = true

      try {
        if (this.step === 1) {
          this.message = 'Загрузка плагина для работы с ЭП.Пожалуйста, подождите..'
          await this.selectCertificate()

          this.message = 'Выберите сертификат'
          this.btn_next_visible = true
        }else if (this.step === 2) {
          this.btn_next_visible = false
          try {
            this.message = 'Выполнение команды до подписания'
            if(this.command.before_command_id){
              await CommandExecutor.execute(this.context, this.command.before_command_id, false)
              this.needRollback = true
            }
            return this.next()
          } catch (error) {
            error.commandId = this.command.before_command_id
            throw {
              message: 'Не удалось выполнить команду до подписания',
              error,
              'stage': 'commandBefore'
            }
          }
        }else if (this.step === 3) {
          console.log(this.certificate)
          this.message = 'Получение данных, которые будут подписаны. Пожалуйста, подождите...'

          // Делаем запрос к бэку и получаем поля к подписанию
          let data = await this.getInfo()
          this.command.is_first_signing = data.isFirstSigning
          this.data = data.fields
          this.xmlToSign = data.xml_to_sign
          this.fileWithPrevSignedInfo  =  data.fileWithInfoAboutField

          // Файловые поля отбираются в отдельный массив
          let files = []
          this.data.forEach(function(field){
            if(field.type==="file_field" && field.value){
              let filesField = JSON.parse(field.value)
              filesField.forEach(function(file){
                files.push(file)
              })
            }
          })

          this.files = files
          this.message = 'Пожалуйста, проверьте набор подписываемых данных'
          this.btn_next_visible = true; // Тпеперь делаем кнопку "Далее" видимой.
          // Здесь пользователь изучает набор подписываемых данных прежде чем нажать кнопку "Продолжить"
        } else if (this.step === 4) {

          this.btn_next_visible = false // Кнопка "Далее" невидима, этап проскакивает безакцептно.
          if((!this.command.is_multiple_sign || this.command.is_first_signing) && this.data.length > 0) {
            console.log("createFileWithAllInformation");
            // Формируем файл с данными записи для команды одиночного п.. или для первого п.. команды можественного п..
            this.fileWithInfoAboutField = await this.createFileWithAllInformation();
          } else {
            console.log("fileWithPrevSignedInfo");
            // Если мы добавляем подписанта к существующей подписи, то берем файл с данными предыдущей подписи
            this.fileWithInfoAboutField =  this.fileWithPrevSignedInfo;
          }

          this.files.push(this.fileWithInfoAboutField) // Добавляем файл с подписываемыми нефайловыми полями к остальным

          // Если нечего подписывать или сертификат у нас серверный, то мы просто идем дальше.
          if ((this.data.length === 0 && !this.xmlToSign?.hashByAlgorithmCertificate) || this.certificate.Server) {
            // Вызов клиентского подписания в этом случае нужно пропустить.
            return this.next()
          }

          this.message = 'Подписание данных. Пожалуйста, подождите...'
          console.log(this);
          await this.signing() // Вызов клиентского подписания
          return this.next()
        } else if(this.step === 5){
          this.message = this.certificate.Server ? 'Идет подписание данных.' : 'Сохранение данных по подписанию'
          const data = await this.generateArchiveAndSave() // Вызов серверного подписания произойдет на бэке.
          this.archive = data.eds_data
          return this.next()
        } else if(this.step === 6){
          if(this.command.after_command_id){
            this.message = 'Выполнение команды после подписания'
            await CommandExecutor.execute(this.context, this.command.after_command_id, false)
          }
          return await this.next()
        } else if(this.step === 7){
          if(this.callback && typeof this.callback === 'function') {
            this.message = 'Выполнение команд внутри маршрута согласования'
            await this.callback()
          }
          this.message = 'Выполнено'
          this.status = 'success'
        }
      }catch (error) {
        console.log(error)
        let message = error.message ? error.message: 'Не удалось выполнить подписание'
        this.message = message
        this.status = 'exception'
        this.btn_next_visible = false

        await this.setLogs({
          'error': error.error,
          'message': error.message,
          'stage': error.stage
        })
        if(this.step >= 3){
          try{
            if(this.command.cancel_command_id){
              this.message = message + '<br> Выполнение команды в случае ошибки'
              this.needRollback = false
              await CommandExecutor.execute(this.context, this.command.cancel_command_id, false)
            }
          }catch (error) {
            console.log(error)
            this.message = message + '<br> Не удалось выполнить команду в случае ошибки'
            await this.setLogs({
              'error': error.error,
              'message': error.message,
              'stage': 'cancelCommand'
            })
          }
        }
      }
      this.loading = false
    },
    async selectCertificate (certificateId) {
      return new Promise(async (resolve, reject) => {
        try{
          var certs = this.certificates;
          // Для люого типа кроме серверного добавляем клиентские сертификаты в список
          if (this.command.sign_type !== 'server') {
            let clientCertificates
            await this.crypto.setProvider()

            clientCertificates = await this.crypto.getCertificates()
            certs = clientCertificates.concat(certs)
            console.log("Client Certificates")
          }

          // Для любого типа кроиме клиентского добавляем серверные сертификаты в список
          if(this.command.sign_type !== 'client') {
            let serverCertificates = await this.queryRepository.getServerCertificates()
            certs = serverCertificates.concat(certs);
            console.log("Server certificates")
          }
          console.log(this.command);

          var filterList = this.command.check_certificate_fields ?? [];
          var getUserData = this.$store.getters['Authorization/userAttributeData']

          // Первый перебор - получим значение полей
          filterList.forEach(function (filter) {
            // Добавим поле с промисом в котором работает запрос
            filter.userFieldValue = getUserData('attr_' + filter.userFieldId + '_')
          })
          var c = 0;

          // Идем по всему списку сертификатов
          while(c++ < certs.length) { 
            var add = true;
            var subject = certs[c-1]['Subject'];
            var i = 0;

            // Для каждого сертификата проверяем каждое условие
            while(i++ < filterList.length) {
              var filter = filterList[i-1];
              var filedValue = await filter.userFieldValue; // Здесь заросы должны отработать
              add &= subject[filter.certificateField] == filedValue;  // Условие - строгое равенство полей
            };

            if(add) {
              this.certificates.push(certs[c-1]);
            }

          };

          // Если нет сертификатов в списке - выкидываем ошибку
          if(this.certificates.length < 1) {
            throw {'message': 'Отсутствуют сертификаты для '
              + (this.command.sign_type !== 'server' ? this.command.sign_type !== 'client'
                ?  'клиентского или серверного' : 'клиентского' : 'серверного')
                + ' подписания'}
          }

          resolve(this.certificates[certificateId])
        }catch (error) {
          let stage = 'selectCertificate'
          let message = error.message ? error.message : 'Не удалось получить сертификаты для подписания'
          reject({  message, stage, error })
        }
      })
    },
    setLogs({error, message, stage}) {
      var registryId = this.context.getCard().getRegistryId(),
          recordId = this.context.getModel()['id']
      try{
        this.$http.post(`${this.$config.api}/cryptoservice/log/error`, {registryId, recordId, error, message, stage})
      }catch (e) {
        console.log(e)
      }
    },
    getInfo () {
      return new Promise(async (resolve, reject) => {
        try {
          let data = await this.queryRepository.getSigningFields(
            this.context.getModel()['id'],
            this.command.id,
            {parameters: { action: 'get', command: this.command, certificate: this.certificate}}
          )
          if (!data.hasOwnProperty('isFirstSigning') ||
            !data.hasOwnProperty('fields') ||
            !data.hasOwnProperty('fileWithInfoAboutField')
          ) {
            throw {
              error: data
            }
          }
          resolve(data)
        }catch (error) {
          let stage = 'getInfo'
          let message = error.message ? error.message: 'Не удалось получить подписываемые данные'
          reject({error, message, stage})
        }
      })
    },
    signing () {
      return new Promise(async (resolve, reject) => {
        try {
          if (!this.command.is_multiple_sign || this.command.is_first_signing) {
            if (this.data.length > 0) {
              for (var i = 0; i < this.files.length; i++) {
                this.files[i].signHash = await this.getSignHash(this.files[i].hashByAlgorithmCertificate, this.certificate)
              }
            }
            console.log('signing xml')
            if (this.xmlToSign?.hashByAlgorithmCertificate) {
              const signHash = await this.getSignHash(this.xmlToSign?.hashByAlgorithmCertificate, this.certificate, true)
              this.$set(this.xmlToSign, 'signHash', signHash)
            }
            console.log('end signing xml')
          } else {
            for (var i = 0; i < this.files.length; i++) {
              this.files[i].signHash = await this.createCoSignHash(this.files[i].hashByAlgorithmCertificate, this.files[i].contentSigFile, this.certificate)
            }
          }
          resolve()
        }catch (error) {
          let message = error.message? error.message : `Ошибка на этапе подписания`
          reject({error, message, stage: 'signing'})
        }
      })
    },
    back () {
      this.step--
      this.loading = false
      this.status = null
      this.btn_next_visible = true
    },
    download (file) {
      let me = this
      this.$http({
        method: 'get',
        url: `${this.$config.api}/files/${this.getFilePath(file)}`,
        responseType: 'blob'
      }).then(function (response) {
        let blob = new Blob([response.data])
        me.downloadBlob(blob, this.getFileName(file))
      })
    },
    generateArchiveAndSave(){
      return new Promise(async (resolve, reject) =>{
        try{
          let certificateBase64 = ''

          if(!this.certificate.Server) {
              certificateBase64 = await this.crypto.getCertificateBase64(this.certificate.Thumbprint)
          }

          let response = await this.queryRepository.generateArchiveAndSave(
            this.context.getModel()['id'],
            this.command.id,
            {
              parameters: {
                action: 'save',
                files: this.files,
                certificate: this.certificate,
                certificateBase64: certificateBase64,
                data: this.data,
                xml_to_sign: this.xmlToSign,
                isFirstSigning: this.command.is_first_signing
              }
            }
          )
          resolve(response)
        }catch (error) {
          let message = error.message ? error.message : 'Не удалось выполнить сохранение!'
          reject({error, message, stage: 'generateArchiveAndSave'})
        }
      })
    },
    createCoSignHash(hashByAlgorithmCertificate, contentSigFile, certificate){
      return new Promise(async(resolve, reject) => {
        try {
          let signHash = await this.crypto.createCoSignHash(hashByAlgorithmCertificate, contentSigFile, certificate)
          resolve(signHash)
        } catch (error) {
          let message = `КриптоПро (подписание хэша): ${error.message}`
          reject({error, message, stage: 'createCoSignHash'})
        }
      })
    },
    getSignHash(hashByAlgorithmCertificate, certificate, xml = false){
      return new Promise(async(resolve, reject) => {
        try {
          let signHash
          if (xml) {
            signHash = await this.crypto.signHashRaw(hashByAlgorithmCertificate, certificate)
          } else {
            signHash = await this.crypto.signHash(hashByAlgorithmCertificate, certificate)
          }
          resolve(signHash)
        } catch (error) {
          let message = `КриптоПро (подписание хэша): ${error.message}`
          reject({error, message, stage: 'getSignHash'})
        }
      })
    },
    createFileWithAllInformation(){
      return new Promise(async(resolve, reject) => {
        try {
          let file = await this.queryRepository.createFileWithAllInformation(
            this.context.getModel()['id'],
            this.command.id,
            {
              parameters: {
                action: 'create_file',
                content: document.getElementById('singning-info').innerText,
                certificate: this.certificate
              }
            }
          )
          if(!this.certificates.server && !file.hasOwnProperty('hashByAlgorithmCertificate')){
            throw {
              error: data
            }
          }
          resolve(file);
        } catch (error) {
          let message = 'Не удалось создать файл с выбраными полями'
          reject({error, message, stage: 'createFileWithAllInformation'})
        }
      })
    },
    downloadBlob (blob, filename) {
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = filename
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    async close () {
      if (this.command.cancel_command_id && this.status !== 'success' && this.needRollback) {
          CommandExecutor.execute(this.context, this.command.cancel_command_id, false)
        .catch((error)=>{
          console.log(error)
        }).finally(()=>this.$parent.close())
      } else {
        this.$parent.close()
      }
    }
  }
}
</script>
<style scoped>
  #singning-info hr {
    margin: 10px 10px;
  }

  #singning-info ol {
    padding-left: 20px;
  }

  #singning-info #progress {
    margin-left: 35px;
  }

  .el-scrollbar {
    opacity: 1 !important;
  }

  .el-scrollbar__bar {
    opacity: 1 !important;
  }

  .el-scrollbar__wrap {
    margin-bottom: 0px !important;
    overflow: scroll;
    overflow-x: auto;
    overflow-y: auto;
  }

  .el-progress {
    margin: 20px;
  }

  #list {
    width: 100%;
    min-height: 50px;
  }
</style>
