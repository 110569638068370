export default class FileTransformCommand
{
    private modelId: number;
    private guid: string;
    private id: number;
    private format: string;
    private optimization: object;

    constructor(modelId: number, guid: string, id: number, format: string, optimization: object = null)
    {
        this.modelId = modelId;
        this.guid = guid;
        this.id = id;
        this.format = format;
        this.optimization = optimization;
    }

    getModelId(): number
    {
        return this.modelId;
    }

    getId(): number
    {
        return this.id;
    }

    getGuid(): string
    {
        return this.guid;
    }

    getFormat(): string
    {
        return this.format;
    }

    getOptimization(): object
    {
        return this.optimization;
    }

    getClassName(): string
    {
        return 'FileTransformCommand';
    }
}