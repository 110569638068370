<template>
  <el-form label-width="120px" label-position="top" size="mini">
    <el-form-item>
      <span class="property_label">{{ $locale.interface_editor.component_editor.table.source_type }}</span>
      <editor-select :value="value.type" @change="$set(value ,'type', $event)" :options="{multiple: false, options: dataTypes}"></editor-select>
    </el-form-item>
    <div style="height: 75px" v-if="isSoursePlugin">
      <span class="property_label">{{ $locale.interface_editor.component_editor.table.plugin }}</span>
      <editor-plugin :value="value.plugin" @change="$set(value ,'plugin', $event)"></editor-plugin>
    </div>

 <!-- ExtendObject -->
    <template v-if="isSourseExtendObject">
      <el-form-item>
        <span class="property_label">{{ $locale.interface_editor.component_editor.table.extended_object }}</span>
        <editor-select :value="value.extendObject" @change="$set(value ,'extendObject', $event)" :options="{multiple: false, options: listExtendObject}"></editor-select>
      </el-form-item>
      <el-checkbox v-model="value.isHorizontal" v-if="options.type === 'donut'">{{$locale.interface_editor.component_editor.editor_chart.is_horizontal}}</el-checkbox>
      <el-form-item v-if="!(options.type === 'donut' && value.isHorizontal)">
        <span class="property_label">{{ $locale.interface_editor.component_editor.editor_chart.categorie }}</span>
        <el-select
          v-model="categorie"
          value-key="name"
          @change="$set(value ,'categorie', categorie)"
        >
          <el-option
            v-for="(item, index) in filedExtendObject"
            :key="index"
            :label="item.name"
            :value="item">
          </el-option>
        </el-select>
      </el-form-item>
    </template>
 <!-- Requests -->
    <template v-if="isSourseRequests">
      <el-form-item>
        <span class="property_label">{{ $locale.interface_editor.component_editor.table.request }}</span>
        <editor-select
          :value="value.requestsId"
          @change="$set(value ,'requestsId', $event)"
          :options="{
            multiple: false,
            options: requests
          }"
        ></editor-select>
      </el-form-item>

      <el-form-item>
        <span class="property_label">{{ $locale.interface_editor.component_editor.editor_chart.categorie }}</span>
        <el-input type="text" size="small" v-model="value.categorieRequests"></el-input>
      </el-form-item>
    </template>

    <template v-if="showEditor">
      <el-form-item v-if="!isSoursePlugin && !(options.type === 'donut' && value.isHorizontal)">
        <span class="property_label">{{ $locale.interface_editor.component_editor.editor_chart.value_field }}</span>
        <el-select
          v-model="valueField"
          value-key="name"
          @change="$set(value ,'valueField', valueField )"
        >
          <el-option
            v-for="(item, index) in filedExtendObject"
            :key="index"
            :label="item.name"
            :value="item">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="!(options.type === 'donut' && value.isHorizontal)">
        <span class="property_label">{{ $locale.interface_editor.component_editor.editor_color_donutchart.add_color }}</span>
        <editorColorChart :value="value.colors" @change="$set(value ,'colors', $event)"/>
      </el-form-item>
      <el-form-item v-if="!isSoursePlugin && (options.type === 'donut' && value.isHorizontal)">
        <span class="property_label">{{ $locale.interface_editor.component_editor.editor_chart.value_field }}</span>
        <el-button type="text" @click="dialogSettingsVisible = true">{{ $locale.main.button.edit }}</el-button>
        <el-dialog
          :title="$locale.interface_editor.component_editor.editor_color_donutchart.edit_color"
          :modal="false"
          :width="'25%'"
          :visible.sync="dialogSettingsVisible">
          <el-button class="button_block" size="small" @click="addColor">{{$locale.interface_editor.component_editor.editor_color_donutchart.add_color}}</el-button>
          <div v-for="(item, index) in value.seriesFields" :key="index" class="main_block">
            <div class="block" >
              <el-color-picker class="color_picker" v-model="item.color"></el-color-picker>
              <el-input type="text" size="small" v-model="item.color" placeholder="#19D29A"></el-input>
              <el-select
                v-model="item.field"
                value-key="name"
              >
                <el-option
                  v-for="(item, index) in filedExtendObject"
                  :key="index"
                  :label="item.name"
                  :value="item.name">
                </el-option>
              </el-select>
              <br>
              <el-input type="text" size="small" v-model="item.name"></el-input>
              <el-button @click="deleteColor(index)" class="color_picker__remove" icon="el-icon-close" size="medium" circle></el-button>
            </div>
          </div>
        </el-dialog>
      </el-form-item>
    </template>
    <el-form-item v-else>
      <span class="property_label">{{ $locale.interface_editor.component_editor.editor_title_series.edit_series }}</span>
      <editorSeriesChart
        :options="{
          type: options.type,
          filedExtendObject: filedExtendObject,
          extendObject: value.extendObject
        }"
        :typeSource='value.type'
        :value="value.series"
        @change="$set(value ,'series', $event)"
        />
    </el-form-item>
    <el-form-item>
      <span class="property_label">{{ $locale.interface_editor.component_editor.editor_title_chart.edit_chart }}</span>
      <editorTitleChart :value="value.title" @change="$set(value ,'title', $event)"/>
    </el-form-item>
    <el-form-item>
      <span class="property_label">{{ $locale.interface_editor.component_editor.editor_legend.edit_legend }}</span>
      <editorLegendChart :value="value.legend" @change="$set(value ,'legend', $event)"/>
    </el-form-item>
  </el-form>
</template>

<script>
import EditorTitleChart from './editor-title-chart.vue'
import EditorLegendChart from './editor-legend-chart.vue'
import EditorColorChart from './editor-color-donutchart.vue'
import EditorSeriesChart from './editor-chart-series.vue'
import EditorSelect from '../editor-select.vue'
import EditorPlugin from '../editor-plugins.vue'

export default {
  name: 'editor-chart',
  components: {
    EditorTitleChart,
    EditorLegendChart,
    EditorColorChart,
    EditorSeriesChart,
    EditorSelect,
    EditorPlugin
  },
  props: {
    value: {
      type: Object,
      default () {
        return {}
      }
    },
    options: {
      type: Object
    }
  },
  data () {
    return {
      dataTypes: [
        { id: 'extend_object', name: 'Расширенная таблица' },
        { id: 'plugin', name: 'Плагин' },
        { id: 'requests', name: 'Запрос' }
      ],
      requests: [],
      listExtendObject: [],
      seriesFields: [],
      filedExtendObject: [],
      categorie: {},
      categorieRequests: null,
      valueField: {},
      dialogSettingsVisible: false
    }
  },
  computed: {
    showEditor () {
      if (this.value.type === 'plugin' || this.options.type === 'donut') {
        return true
      } else {
        return false
      }
    },
    isSoursePlugin () {
      return this.value.type === 'plugin'
    },
    isSourseExtendObject () {
      return this.value.type === 'extend_object'
    },
    isSourseRequests () {
      return this.value.type === 'requests'
    }
  },
  watch: {
    value: {
      handler (value) {
        console.log('emit', value)
        this.$emit('change', value)
      }
    },
    deep: true,
    'value.type': {
      async handler (value) {
        this.valueField = {}
        this.categorie = {}
        if (value === 'extend_object') {
          this.value.plugin = ''
        } else {
          this.value.categorie = {}
          this.value.extendObject = ''
        }
        if (value === 'requests') {
          this.requests = await this.getRequests()
        }
      }
    },
    'value.extendObject': {
      async handler (value) {
        if (value) {
          this.filedExtendObject = []
          this.categorie = {}
          this.valueField = {}
          let response = await this.$http.get(`${this.$config.api}/bieditor/extended_object_fields?extended_object_id=${value}`)
          this.filedExtendObject = response.data.map((el) => ({ id: el.id, name: el.name }))
        }
      }
    }
  },
  async mounted () {
    let response = await this.$http.get(`${this.$config.api}/bieditor/extended_objects`)
    this.listExtendObject = response.data
    if (this.value.extendObject) {
      let response = await this.$http.get(`${this.$config.api}/bieditor/extended_object_fields?extended_object_id=${this.value.extendObject}`)
      this.filedExtendObject = response.data.map((el) => ({ id: el.id, name: el.name }))
      this.categorie = this.value.categorie
      this.valueField = this.value.valueField
      this.seriesFields = this.value.seriesFields
    }
    if (this.value.requestsId) {
      this.requests = await this.getRequests()
      this.categorieRequests = this.value.categorieRequests
    }
  },
  methods: {
    addColor () {
      if (typeof this.value.seriesFields === 'undefined') {
        this.value.seriesFields = []
      }
      this.value.seriesFields.push({ color: '', field: null, name: null })
    },
    deleteColor (index) {
      this.value.seriesFields.splice(index, 1)
    },
    async getRequests () {
      const response = await this.$http.get(`${this.$config.api}/bieditor/queries`)

      return response.data
    }
  }
}
</script>

<style scoped>
.block {
  display: inline-flex;
  align-items: center;
  height: 40px;
}
.color_picker {
  margin-right: 10px;
}
.button_block {
  display: block;
  margin-bottom: 10px;
}
.color_picker__remove {
  margin-left: 10px;
  border: none;
  display: none;
}
.main_block:hover .color_picker__remove {
  display: block;
}
</style>
