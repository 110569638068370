import { ActionTree } from 'vuex'
import { GeoJsonState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, GeoJsonLoadingEvent, GeoJsonLoadedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { GeoJsonAPI } from '@/services/MapEditor/infrastructure/api/GeoJsonAPI'

export const actions: ActionTree<GeoJsonState, any> = {
    [ActionTypes.GET_LAYER_GEOJSON]: async ({ commit, dispatch }, data) => {
        try {
            console.log(data, data.payload);
            //commit(new GeoJsonLoadingEvent());
            //let res = await APIClient.shared.request(new GeoJsonAPI.GetLayerGeoJson(data.payload));
            //commit(new GeoJsonLoadedEvent());
            //return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    }
}
