import { ActionTree } from 'vuex'
import { ProjectState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, ProjectCreatedEvent, ProjectUpdatedEvent, ProjectLoadingEvent, ProjectDeletedEvent, ProjectLoadedEvent } from './mutation-types'

import { APIClient } from '@/core/infrastructure/api/APIClient'
import { ProjectAPI } from '@/services/MapEditor/infrastructure/api/ProjectAPI'

export const actions: ActionTree<ProjectState, any> = {
    [ActionTypes.ADD_PROJECT]: async ({ commit, dispatch }, data) => {
        try {
            commit(new ProjectLoadingEvent());
            let location = await APIClient.shared.request(new ProjectAPI.AddProject(data.payload));
            commit(new ProjectCreatedEvent(location));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.UPDATE_PROJECT]: async ({ commit, dispatch }, data) => {
        try {
            commit(new ProjectLoadingEvent());
            await APIClient.shared.request(new ProjectAPI.UpdateProject(data.payload));
            commit(new ProjectUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.DELETE_PROJECT]: async ({ commit, dispatch }, data) => {
        try {
            await APIClient.shared.request(new ProjectAPI.DeleteProject(data.guid));
            commit(new ProjectDeletedEvent(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }         
    },
    [ActionTypes.GET_PROJECTS]: async ({ commit, dispatch }, data) => {
        try {
            commit(new ProjectLoadingEvent());
            let res = await APIClient.shared.request(new ProjectAPI.GetProjects(data.payload));
            commit(new ProjectLoadedEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.COUNT_PROJECTS]: async ({ commit, dispatch }, data) => {
        try {
            commit(new ProjectLoadingEvent());
            let res = await APIClient.shared.request(new ProjectAPI.CountProjects(data.payload));
            commit(new ProjectLoadedEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.GET_PROJECT_BY_GUID]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new ProjectAPI.GetProjectByGuid(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    },
    [ActionTypes.GET_PROJECT_SOURCES]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new ProjectAPI.GetProjectSources(data.projectGuid, data.payload));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    },
    [ActionTypes.GET_PROJECT_LAYERS]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new ProjectAPI.GetProjectLayers(data.projectGuid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    },
    [ActionTypes.GET_PROJECT_STYLES]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new ProjectAPI.GetProjectStyles(data.projectGuid, data.payload));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    },
    [ActionTypes.GET_PROJECT_COORDINATE_SYSTEMS]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new ProjectAPI.GetProjectCoordinateSystems(data.projectGuid, data.payload));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    }
}
