import QueryHandlerInterface from '../QueryHandlerInterface'
import QueryInterface from '../QueryInterface'
import QueryHandlerMap from '../QueryHandlerMap'
import { Nullable } from '@/core/domain/type/types'

export default class QueryBusHandlerLocator
{
    private handlers: object;

    constructor(handlers: object) {
        this.handlers = handlers;
    }

    getHandler(query: QueryInterface): Nullable<QueryHandlerInterface>
    {
        return this.handlers[query.getClassName()];
    }
}