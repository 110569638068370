import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { CssClassDTO, CssClassTreeDTO } from '@/services/CssEditor/domain/model/CssClass'

export namespace CssClassAPI {
  export class GetCssClass implements APIRequest<CssClassDTO[]> {
    response: CssClassDTO[];
    path: string;
    method = HTTPMethod.GET;
    parse = (res: AxiosResponse) => res.data.map(item => new CssClassDTO(item))
    constructor (payload?: string[][]) {
      this.path = '/csseditor/css_classes'

      if (payload) {
        this.path += `?${new URLSearchParams(payload).toString()}`
      }
    }
  }

  export class GetCssClassTreeByGuid implements APIRequest<CssClassTreeDTO> {
    response: CssClassTreeDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (res: AxiosResponse) => res.data.map(item => new CssClassTreeDTO(item))[0] || null
    constructor (guid: string) {
      this.path = '/csseditor/css_classes_tree?guid=' + guid
    }
  }

  export class GetCssClassTree implements APIRequest<CssClassTreeDTO[]> {
    response: CssClassTreeDTO[];
    path: string;
    method = HTTPMethod.GET;
    parse = (res: AxiosResponse) => res.data.map(item => new CssClassTreeDTO(item))
    constructor (payload?: string[][]) {
      this.path = '/csseditor/css_classes_tree'

      if (payload) {
        this.path += `?${new URLSearchParams(payload).toString()}`
      }
    }
  }

  export class GetCssClassSearch implements APIRequest<CssClassTreeDTO[]> {
    response: CssClassTreeDTO[];
    path = '/csseditor/css_classes_search';
    method = HTTPMethod.POST;
    parse = (res: AxiosResponse) => res.data.map(item => new CssClassTreeDTO(item));
    hideNotification = true;
    constructor (public params: object) {}
  }

  export class GetCssClassByGuid implements APIRequest<CssClassDTO> {
    response: CssClassDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => new CssClassDTO(data.data)
    constructor (guid: string) {
      this.path = `/csseditor/css_classes/${guid}`
    }
  }

  export class CreateCssClass implements APIRequest<string> {
    response: string;
    path = '/csseditor/css_classes';
    method = HTTPMethod.POST;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.headers.location
    constructor (public params: CssClassDTO) {}
  }

  export class UpdateCssClass implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.PUT;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: CssClassDTO) {
      this.path = `/csseditor/css_classes/${params.guid}`
    }
  }

  export class DeleteCssClass implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.DELETE;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/csseditor/css_classes/${guid}`
    }
  }
}
