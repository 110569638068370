<template>
  <div>
    <span class="property_label" style="display: inline-block;">{{ $t(`interface_editor.${label}`) }}</span>
    <el-tooltip
      v-if="options && options.tooltip && options.tooltip.show"
      :content="$t(`interface_editor.${options.tooltip.content}`)"
      :open-delay="300"
      placement="top-start"
      effect="dark"
    >
      <div slot="content" v-html="$t(`interface_editor.${options.tooltip.content}`)"></div>
      <i class="el-icon-question"></i>
    </el-tooltip>
    <el-input
      v-model="localValue"
      v-on:input="onChange"
      type="number"
      size="mini"
    ></el-input>
  </div>
</template>

<script>
export default {
  name: 'editor-number',
  props: {
    value: Number,
    options: Object,
    label: String
  },
  data () {
    return {
      localValue: this.value
    }
  },
  watch: {
    value () {
      this.localValue = this.value
    }
  },
  methods: {
    onChange (value) {
      if (!value) {
        this.$emit('change', null)
      } else {
        this.$emit('change', (this.options && this.options.isFloat === true) ? parseFloat(value) : parseInt(value))
      }
    }
  }
}
</script>

<style scoped>

</style>
