import { ActionTree } from 'vuex'
import { RuleState, initialState } from './state'
import { ActionTypes } from './action-types'
import {
  MutationTypes,
  RuleUpdatedEvent,
  RuleCreatedEvent,
  RuleLoadingEvent,
  RuleDeletedEvent,
  RuleLoadedEvent
} from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { RuleAPI } from '@/services/TaskEditor/infrastructure/api/RuleAPI'

export const actions: ActionTree<RuleState, any> = {
  [ActionTypes.GET_RULE_BY_GUID]: async ({ commit, dispatch }, data) => {
    commit(new RuleLoadingEvent())

    return APIClient.shared.request(new RuleAPI.GetRuleByGuid(data.guid))
      .then((response) => {
        commit(new RuleLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.GET_RULE_FIELD_TYPES]: async ({ commit, dispatch }, data) => {
    commit(new RuleLoadingEvent())

    return APIClient.shared.request(new RuleAPI.GetRuleFieldTypes())
      .then((response) => {
        commit(new RuleLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.CREATE_RULE]: async ({ commit, dispatch }, data) => {
    commit(new RuleLoadingEvent())

    return APIClient.shared.request(new RuleAPI.CreateRule(data.payload))
      .then((location) => {
        commit(new RuleCreatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.UPDATE_RULE]: async ({ commit, dispatch }, data) => {
    commit(new RuleLoadingEvent())

    return APIClient.shared.request(new RuleAPI.UpdateRule(data.payload))
      .then((location) => {
        commit(new RuleUpdatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.DELETE_RULE]: async ({ commit, dispatch }, data) => {
    commit(new RuleLoadingEvent())

    return APIClient.shared.request(new RuleAPI.DeleteRule(data.guid))
      .then(() => {
        commit(new RuleDeletedEvent(data.guid))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  }
}
