export interface ListenerState {
  isLoading: boolean
  location: string
  deletedListenerGuid: string
  error: object
}

export const initialState = (): ListenerState => {
  return {
    isLoading: false,
    location: null,
    deletedListenerGuid: null,
    error: null
  }
}
