import { ActionTree } from 'vuex'
import { RouteState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, RouteLoadingEvent, RouteLoadedEvent, RouteCreatedEvent, RouteUpdatedEvent, RouteDeletedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { RouteAPI } from '@/services/Databus/infrastructure/api/RouteAPI'

export const actions: ActionTree<RouteState, any> = {
    [ActionTypes.ADD_ROUTE]: async ({ commit, dispatch }, data) => {
        try {
            commit(new RouteLoadingEvent());
            let location = await APIClient.shared.request(new RouteAPI.AddRoute(data.payload));
            commit(new RouteCreatedEvent(location));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.UPDATE_ROUTE]: async ({ commit, dispatch }, data) => {
        try {
            commit(new RouteLoadingEvent());
            let location = await APIClient.shared.request(new RouteAPI.UpdateRoute(data.payload));
            commit(new RouteUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.DELETE_ROUTE]: async ({ commit, dispatch }, data) => {
        try {
            await APIClient.shared.request(new RouteAPI.DeleteRoute(data.guid));
            commit(new RouteDeletedEvent(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }         
    },
    [ActionTypes.GET_ROUTES]: async ({ commit, dispatch }, data) => {
        try {
            commit(new RouteLoadingEvent());
            let res = await APIClient.shared.request(new RouteAPI.GetRoutes(data.payload));
            commit(new RouteLoadedEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.COUNT_ROUTES]: async ({ commit, dispatch }, data) => {
        try {
            commit(new RouteLoadingEvent());
            let res = await APIClient.shared.request(new RouteAPI.CountRoutes(data.payload));
            commit(new RouteLoadedEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.GET_ROUTE_BY_GUID]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new RouteAPI.GetRouteByGuid(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    }
}
