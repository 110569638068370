<template>
  <div id="eds-certificate-list">
    <el-radio-group class="group-radio"  v-model="certificateId" @change="select" style="width: 100%;">
      <certificate-item
      v-for="(certificate, index) of certificates" :key="index"
      v-bind:certificate="certificate"
      v-bind:index="index"
      >
      </certificate-item>
    </el-radio-group>
  </div>
</template>

<script>
import CertificateItem from './CertificateItem'
export default {
  name: 'CertificateList',
  props: ['value', 'certificates'],
  components: {
    CertificateItem
  },
  data () {
    return {
      certificateId: 0,
    }
  },
  methods: {
    select (val) {
      this.$emit('input', this.certificates[val])
    }
  },
  mounted() {
    this.select(this.certificateId)
  }
}
</script>

<style scoped>
.el-radio.is-bordered {
    height: auto;
    padding-bottom: 10px;
    margin-bottom: 5px;
    margin-right: 0px;
    display: flex;
    align-items: center;
}
.group-radio>label:last-child{
  margin-bottom: 0;
}
</style>
