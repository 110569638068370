import Model from './Model'

export default class Expanded extends Model {
  resource () {
    return 'expanded'
  }
  name () {
    return Object.values(this).join('')
  }
}
