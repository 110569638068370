import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import OpenWorldQueryRepositoryInterface from '../../../domain/repository/OpenWorldQueryRepositoryInterface'
import StyleQuery from '../../query/StyleQuery'

export default class StyleHandler implements QueryHandlerInterface {
    private repo: OpenWorldQueryRepositoryInterface;

    constructor (repo: OpenWorldQueryRepositoryInterface) {
      this.repo = repo
    }

    execute (query: StyleQuery): Promise<any> {
      return this.repo.getStyle(query.getPayload())
    }
}
