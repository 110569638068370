import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'
import RouteType from "@/services/Databus/domain/model/Route/RouteType";

export class RouteDTO
{
    id?: Nullable<number>;
    guid?: Nullable<string>;
    topic?: Nullable<string>;
    task_id?: Nullable<number>;
    file_name?: Nullable<string>;
    priority?: Nullable<number>;
    type: Nullable<string>;
    commands?: Nullable<Array<number>>;
    properties?: object = [];

    constructor({ id, guid, topic, task_id, file_name, priority, type, commands, properties = []}: {id?: Nullable<number>, guid?: Nullable<string>, topic?: Nullable<string>, task_id?: Nullable<number>, file_name?: Nullable<string>, priority?: Nullable<number>, type?: Nullable<string>, commands?: Nullable<Array<number>>, properties?: object}) {
        this.id = id;
        this.guid = guid;
        this.topic = topic;
        this.task_id = task_id;
        this.file_name = file_name;
        this.priority = priority;
        this.type = type;
        this.commands = commands;
        this.properties = properties;
    }
}

export default class Route extends AbstractBaseEntity
{
    private id?: Nullable<number>;
    private guid?: Nullable<string>;
    private topic: string;
    private taskId?: Nullable<number>;
    private fileName?: Nullable<string>;
    private priority?: Nullable<number>;
    private type?: Nullable<RouteType>;
    private commands: Nullable<Array<number>>;
    private properties: object;

    constructor(
        id: Nullable<number>,
        guid: Nullable<string>,
        topic: string,
        taskId: Nullable<number>,
        fileName: Nullable<string>,
        priority: Nullable<number>,
        type: Nullable<RouteType>,
        commands: Nullable<Array<number>>,
        properties: object = {}
    ) {
        super();
        this.id = id;
        this.guid = guid;
        this.topic = topic;
        this.taskId = taskId;
        this.fileName = fileName;
        this.priority = priority;
        this.type = type;
        this.commands = commands;
        this.properties = properties;
    }

    static create(dto: RouteDTO): Route
    {
        let routeType = null;
        if (!(dto.type == null || dto.type === "")) {
            routeType =  RouteType[dto.type.toUpperCase()];
        }
        return new Route(
            dto.id,
            dto.guid,
            dto.topic,
            dto.task_id,
            dto.file_name,
            dto.priority,
            routeType,
            dto.commands,
            dto.properties
        );
    }

    setId(id: number): void
    {
        this.id = id;
    }

    getId(): number
    {
        return this.id;
    }

    setGuid(guid: string): void
    {
        this.guid = guid;
    }

    getGuid(): string
    {
        return this.guid;
    }

    setTopic(topic: string): void
    {
        this.topic = topic;
    }

    getTopic(): string
    {
        return this.topic;
    }

    setTaskId(taskId: number): void
    {
        this.taskId = taskId;
    }

    getTaskId(): Nullable<number>
    {
        return this.taskId;
    }

    setFileName(fileName: string): void
    {
        this.fileName = fileName;
    }

    getFileName(): Nullable<string>
    {
        return this.fileName;
    }

    setPriority(priority: number): void
    {
        this.priority = priority;
    }

    getPriority(): Nullable<number>
    {
        return this.priority;
    }

    setType(type: RouteType): void
    {
        this.type = type;
    }

    getType(): Nullable<RouteType>
    {
        return this.type;
    }

    setCommands(commands: Nullable<Array<number>>): void
    {
        this.commands = commands;
    }

    getCommands(): Nullable<Array<number>>
    {
        return this.commands;
    }

    setProperties(properties: object): void
    {
        this.properties = properties;
    }

    getProperties(): object
    {
        return this.properties;
    }
}
