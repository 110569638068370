<template>
  <div>
    <span class="property_label" style="display: inline-block;">{{ $t(`interface_editor.${label}`) }}</span>

    <el-tooltip
      v-if="options && options.tooltip && options.tooltip.show"
      :content="$t(`interface_editor.${options.tooltip.content}`)"
      :open-delay="300"
      placement="top-start"
      effect="dark"
    >
      <div slot="content" v-html="$t(`interface_editor.${options.tooltip.content}`)"></div>
      <i class="el-icon-question"></i>
    </el-tooltip>

    <el-input
      v-if="isRemoveSpaces"
      v-model.trim="localValue"
      v-on:input="$emit('change', localValue)"
      size="mini"
    ></el-input>

    <el-input
      v-else
      v-model="localValue"
      v-on:input="$emit('change', localValue)"
      size="mini"
    ></el-input>
  </div>
</template>

<script>
export default {
  name: 'editor-string',
  props: {
    value: String,
    options: Object,
    label: String
  },
  data () {
    return {
      localValue: this.value
    }
  },
  watch: {
    value () {
      this.localValue = this.value
    }
  },
  computed: {
    isRemoveSpaces: function () {
      return !!this.options?.removeSpaces
    }
  }
}
</script>

<style scoped>

</style>
