import { MutationTree } from 'vuex'
import { TaskState, initialState } from './state'
import { MutationTypes } from './mutation-types'

export const mutations: MutationTree<TaskState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false
    state.error = error
  },
  [MutationTypes.TASK_LOADING_EVENT]: (state) => {
    state.isLoading = true
  },
  [MutationTypes.TASK_LOADED_EVENT]: (state) => {
    state.isLoading = false
  }
}
