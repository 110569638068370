import { FluxStandardAction, Nullable } from '@/core/domain/type/types'

export enum ActionTypes {
    GET_EVENTS = 'get_events',
    EVENTS_EXPORT = 'events_export'
}

export class GetEvents implements FluxStandardAction {
    type = 'Event/' + ActionTypes.GET_EVENTS
    constructor (public payload: object) {}
}

export class EventsExport implements FluxStandardAction {
  type = 'Event/' + ActionTypes.EVENTS_EXPORT
  constructor (public payload: object) {}
}
