<template>
  <div :style="css || 'text-align: center'" v-if="value" :class="{'hover_effect': clickTable}">
    {{ value | moment("MMM.Y") }}
  </div>
</template>

<script>
import clickTableMixin from './click_table_mixin'
export default {
  name: 'month_field',
  mixins: [clickTableMixin],
  props: {
    value: String,
    css: String
  }
}
</script>

<style scoped>
.hover_effect:hover {
    cursor: pointer;
    text-decoration: underline
  }
</style>
