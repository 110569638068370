import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import CoordinateSystemQueryRepositoryInterface from '../../../domain/repository/CoordinateSystemQueryRepositoryInterface'
import CoordinateSystemsCountQuery from '../../query/CoordinateSystemsCountQuery'

export default class CoordinateSystemsCountHandler implements QueryHandlerInterface
{
    private repo: CoordinateSystemQueryRepositoryInterface;

    constructor(repo: CoordinateSystemQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: CoordinateSystemsCountQuery): Promise<any>
    {
        return this.repo.getCount(query.getPayload());
    }
}