import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import OpenWorldCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/OpenWorldCommandRepositoryInterface'
import StyleUpdateCommand from '@/services/DigitalTwinEditor/application/command/StyleUpdateCommand'
import { OpenWorldDTO } from '@/services/DigitalTwinEditor/domain/model/OpenWorld/OpenWorld'

export default class StyleUpdateHandler implements CommandHandlerInterface
{
    private repo: OpenWorldCommandRepositoryInterface;

    constructor(repo: OpenWorldCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: StyleUpdateCommand): Promise<void>
    {
        let item: OpenWorldDTO = {
            guid: command.getGuid(),
            style_id: command.getStyleId(),
            value: command.getValue()
        };
        return this.repo.updateStyle(item);
    }
}