export default {
  namespaced: true,
  state: {
    isConstructorMode: false
  },
  mutations: {
    toggleConstructorMode (state, payload) {
      state.isConstructorMode = !payload
    }
  },
  getters: {
    getConstructorMode: (state) => {
      return state.isConstructorMode
    }
  }
}
