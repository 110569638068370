import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { TopicDTO } from '@/services/Databus/domain/model/Topic/Topic'

export namespace TopicAPI {

    export class AddTopic implements APIRequest<string> {
        response: string;
        path = '/databusservice/topics';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.headers.location
        constructor(public params: TopicDTO) {}
    }

    export class UpdateTopic implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: TopicDTO) {
            this.path = `/databusservice/topics/${params.guid}`;
        }
    }

    export class DeleteTopic implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.DELETE;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/databusservice/topics/${guid}`;
        }
    }

    export class CountTopics implements APIRequest<TopicDTO> {
        response: TopicDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/databusservice/topics?*[func]=count'
        }
    }

    export class GetTopics implements APIRequest<TopicDTO> {
        response: TopicDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/databusservice/topics?' +  (new URLSearchParams(payload).toString())
        }
    }

    export class GetTopicByGuid implements APIRequest<TopicDTO> {
        response: TopicDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/databusservice/topics/${guid}`;
        }
    }
}