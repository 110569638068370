import { MutationTree } from 'vuex'
import { ReleaseState, initialState } from './state'
import { MutationTypes, ReleaseCreatedEvent, ReleaseUpdatedEvent, ReleaseDeletedEvent } from './mutation-types'

export const mutations: MutationTree<ReleaseState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false;
    state.error = error;    
  },
  [MutationTypes.RELEASE_LOADING_EVENT]: (state) => {
    state.isLoading = true;
  },
  [MutationTypes.RELEASE_LOADED_EVENT]: (state) => {
    state.isLoading = false;
  },
  [MutationTypes.RELEASE_CREATED_EVENT]: (state, action: ReleaseCreatedEvent) => {
    state.location = action.location;
    state.isLoading = false;
  },
  [MutationTypes.RELEASE_UPDATED_EVENT]: (state, action: ReleaseUpdatedEvent) => {
    state.isLoading = false;
  },
  [MutationTypes.RELEASE_DELETED_EVENT]: (state, action: ReleaseDeletedEvent) => {
    state.isLoading = false;
  }
}