
import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import SnapshotRepositoryInterface from '../../../domain/repository/SnapshotRepositoryInterface'
import SnapshotsCountQuery from '../../query/SnapshotsCountQuery'

export default class SnapshotsCountHandler implements QueryHandlerInterface
{
    private repo: SnapshotRepositoryInterface;

    constructor(repo: SnapshotRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: SnapshotsCountQuery): Promise<any>
    {
        return this.repo.getCount(query.getPayload());
    }
}