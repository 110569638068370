import { FluxStandardAction, Nullable } from '@/core/domain/type/types'

export enum ActionTypes {
    GET_TASKS = 'get_tasks',
    COUNT_TASKS = 'count_tasks'
}

export class GetTasks implements FluxStandardAction {
    type = 'DigitalTwinTask/' + ActionTypes.GET_TASKS
    constructor (public payload: object) {}
}

export class CountTasks implements FluxStandardAction {
    type = 'DigitalTwinTask/' + ActionTypes.COUNT_TASKS
    constructor (public payload: object) {}
}
