<template>
  <el-table-column :width="treeTable ? '120' : '32'" align="center" fixed="left">
    <template slot-scope="scope">
      <el-button
        v-if="!scope.row.isEdit && scope.row.id"
        :disabled="scope.row.loading"
        plain
        :class="{'editColumn_displayNone' : showButton.edit}"
        size="small"
        @click="showButton.edit ? '': $emit('edit-record', scope.row)"
        :icon="scope.row.loading ? 'el-icon-loading' : 'el-icon-edit'"
        style="border: none;"
      ></el-button>
      <el-button
        v-else-if="scope.row.isEdit"
        plain
        size="small"
        :class="{'editColumn_displayNone' : showButton.edit}"
        @click="showButton.edit ? '': $emit('row-edit', scope.row)"
        icon="el-icon-check"
        style="border: none;"
      ></el-button>
    </template>
  </el-table-column>
</template>

<script>
export default {
  // props: ['isGrouped', 'showButton','treeTable'],
  props: {
    isGrouped: {
      type: Boolean
    },
    treeTable: {
      type: Boolean
    },
    showButton: {
      type: Object
    }
  },
  name: 'EditColumn'
}
</script>

<style scoped>
  .editColumn_displayNone {
    opacity: 0;
    cursor: default;
  }
</style>
