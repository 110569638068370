import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import ProjectBuildsQueryRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/ProjectBuildsQueryRepositoryInterface'
import store from '@/store'
import { GetProjectBuilds, CountProjectBuilds } from '@/services/DigitalTwinEditor/infrastructure/store/modules/Project/action-types'

export default class ProjectBuildsQueryRepository extends AbstractQueryRepository implements ProjectBuildsQueryRepositoryInterface {
  get (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetProjectBuilds(payload))
  }

  getCount (payload: string[][] | object): Promise<any> {
    return store.dispatch(new CountProjectBuilds(payload))
  }
}
