<template>
  <el-select style="width: 100%"  size="mini" v-model="data.values" multiple clearable @change="onChange">
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.name.length > 50 ? item.name.substring(0, 50) + '...' : item.name"
      :value="item.id">
    </el-option>
  </el-select>
</template>

<script>
import mixin from './mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      data: {
        values: []
        // type: null
      },
      options: []
    }
  },
  methods: {
    async render () {
      if (this.value) {
        let properties = this.value.split(',')
        properties.shift()
        //        this.data.type = properties.shift()
        console.log(properties, properties.length, properties.length > 0)
        this.data.values = properties.length > 0 ? properties.filter((item) => item !== '').map((item) => parseInt(item)) : []
      }
      if (this.options.length === 0) {
        let response = await this.$http.get(`${this.$config.api}/registryservice/xref/${this.attributeId}/data`)
        this.options = response.data
      }
    },
    onChange () {
      let value = null
      if (this.data.values.length > 0) {
        value = `eqx,${this.data.values.join(',')}`
      }
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped>

</style>
