<template>
  <el-time-picker
    style="width: 100%;"
    v-bind:value="value"
    v-on:input="$emit('input', $event)"
    format="hh:mm:ss"
    value-format="hh:mm:ss"
    :placeholder="placeholder"
  ></el-time-picker>
</template>

<script>
export default {
  name: 'TimeInput',

  props: {
    value: String,

    placeholder: String
  }
}
</script>

<style type="text/css"></style>
