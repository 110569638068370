import { Nullable } from '@/core/domain/type/types'

export default class LayerUpdateCommand
{
    private guid: string;
    private name: string;
    private description: Nullable<string>;
    private alias: Nullable<string>;
    private parentId: Nullable<number>;

    constructor(guid: string, name: string, description: Nullable<string>, alias: Nullable<string>, parentId: Nullable<number>)
    {
        this.guid = guid;
        this.name = name;
        this.description = description;
        this.alias = alias;
        this.parentId = parentId;
    }

    getGuid(): string
    {
        return this.guid;
    }

    getName(): string
    {
        return this.name;
    }

    getDescription(): Nullable<string>
    {
        return this.description;
    }

    getAlias(): Nullable<string>
    {
        return this.alias;
    }

    getParentId(): Nullable<number>
    {
        return this.parentId;
    }

    getClassName(): string
    {
        return 'DigitalTwinLayerUpdateCommand';
    }    
}