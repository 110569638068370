<template>
  <el-form label-width="120px" label-position="top" size="mini" class="yandex_maps">
    <!-- {{value}} -->
    <el-form-item :label="$locale.interface_editor.component_editor.editor_yandex_maps.width">
      <el-input v-model="value.width" placeholder="100%"></el-input>
    </el-form-item>
    <el-form-item :label="$locale.interface_editor.component_editor.editor_yandex_maps.height">
      <el-input v-model="value.height" placeholder="300px"></el-input>
    </el-form-item>
    <p>{{$locale.interface_editor.component_editor.editor_yandex_maps.center_card}}</p>
    <el-form-item :label="$locale.interface_editor.component_editor.editor_yandex_maps.latitude">
      <el-input v-model="value.centerLatitude"></el-input>
    </el-form-item>
    <el-form-item :label="$locale.interface_editor.component_editor.editor_yandex_maps.longitude">
      <el-input v-model="value.centerLongitude"></el-input>
    </el-form-item>
    <el-form-item :label="$locale.interface_editor.component_editor.editor_yandex_maps.limit">
      <el-input v-model="value.limit" placeholder="25"></el-input>
    </el-form-item>
    <el-form-item class="checkbox" :label="$locale.interface_editor.component_editor.editor_yandex_maps.polyline">
      <el-checkbox v-model="value.isClickPolyline"></el-checkbox>
    </el-form-item>
    <template v-if="value.isClickPolyline">
      <el-form-item :label="$locale.interface_editor.component_editor.editor_yandex_maps.polyline_coords">
        <selectTree class="extraLine_select" :clearable="false" :standartTree='false' :dataTable="listField" v-model="value.polylineCards"/>
      </el-form-item>
    </template>
    <el-form-item class="checkbox" :label="$locale.interface_editor.component_editor.editor_yandex_maps.click_coords">
      <el-checkbox v-model="value.isClickCoords"></el-checkbox>
    </el-form-item>
    <template v-if="value.isClickCoords">
      <el-form-item :label="$locale.interface_editor.component_editor.editor_yandex_maps.latitude">
        <selectTree class="extraLine_select" :clearable="false" :standartTree='false' :dataTable="listField" v-model="value.cardLatitude"/>
      </el-form-item>
      <el-form-item :label="$locale.interface_editor.component_editor.editor_yandex_maps.longitude">
        <selectTree class="extraLine_select" :clearable="false" :standartTree='false' :dataTable="listField" v-model="value.cardLongitude"/>
      </el-form-item>
    </template>
    <el-form-item :label="$locale.interface_editor.component_editor.table.source_type">
      <editor-select
        :value="value.type"
        @change="$set(value ,'type', $event)"
        :options="{multiple: false, options: data_source_types}"
      ></editor-select>
    </el-form-item>
    <el-form-item v-if="value.type === 'extend_object'">
      <editor-select
        :value="value.extendObject"
        @change="$set(value ,'extendObject', $event)"
        :options="{multiple: false, options: listExtendObject}"
      ></editor-select>
    </el-form-item>
    <el-form-item :label="$locale.interface_editor.component_editor.table.registry" v-if="value.type === 'registry'">
      <!-- <editor-registry-select :value="value.registryId" @change="$set(value ,'registryId', $event)"></editor-registry-select> -->
      <registry-select :value="value.registryId" @change="$set(value ,'registryId', $event)"></registry-select>
    </el-form-item>
    <template v-if="value.type === 'registry' || value.type === 'extend_object'">
      <el-form-item :label="$locale.interface_editor.component_editor.editor_yandex_maps.latitude">
        <selectTree class="extraLine_select" :clearable="false" :standartTree='false' :dataTable="listField" v-model="value.coords.latitude"/>
      </el-form-item>
      <el-form-item :label="$locale.interface_editor.component_editor.editor_yandex_maps.longitude">
        <selectTree class="extraLine_select" :clearable="false" :standartTree='false' :dataTable="listField" v-model="value.coords.longitude"/>
      </el-form-item>
      <el-form-item :label="$locale.interface_editor.component_editor.editor_yandex_maps.text_label">
        <selectTree class="extraLine_select" :clearable="false" :standartTree='false' :dataTable="listField" v-model="value.iconCaption"/>
      </el-form-item>
      <el-form-item :label="$locale.interface_editor.component_editor.editor_yandex_maps.icon_color">
        <selectTree class="extraLine_select" :clearable="false" :standartTree='false' :dataTable="listField" v-model="value.iconColor"/>
      </el-form-item>
      <el-form-item :label="$locale.interface_editor.component_editor.editor_yandex_maps.baloon">
        <selectTree class="extraLine_select" :clearable="true" :standartTree='false' :dataTable="listField" v-model="value.descriptionBalloon"/>
      </el-form-item>
    </template>
    <el-form-item :label="$locale.interface_editor.component_editor.table.plugin" v-if="value.type === 'plugin'">
      <editor-plugin :value="value.plugin" @change="$set(value ,'plugin', $event)"></editor-plugin>
    </el-form-item>
    <el-form-item :label="$locale.interface_editor.component_editor.editor_yandex_maps.click_marker">
      <editor-plugin :value="value.markerPlugin" @change="$set(value ,'markerPlugin', $event)"></editor-plugin>
    </el-form-item>
    <p>{{this.$locale.interface_editor.component_editor.editor_yandex_maps.key_license}}<el-input v-model="value.keyLicense"></el-input></p>
    <el-button type="text" @click="dialogSettingsVisible = true">{{ $locale.interface_editor.component_editor.editor_yandex_maps.control }}</el-button>
    <el-dialog
      :title="$locale.interface_editor.component_editor.editor_yandex_maps.control"
      :modal="false"
      :visible.sync="dialogSettingsVisible">
      <el-checkbox-group v-model="value.controls">
        <el-checkbox style="display: block" v-for="item in controlsElemMap" :label="item.value" :key="item.value">{{item.name}}</el-checkbox>
      </el-checkbox-group>
    </el-dialog>
  </el-form>
</template>

<script>
import EditorPlugin from './editor-plugins'
import EditorSelect from './editor-select'
// import EditorRegistrySelect from './editor-registry-select'
import Registry from '@/components/Models/EntityModel'
import SelectTree from '@/core/infrastructure/components/TreeSelect'

import RegistrySelect from '@/components/Common/RegistrySelectTreeWithSearch.vue'
export default {
  name: 'editor-yandex-maps',
  components: {
    EditorPlugin,
    EditorSelect,
    SelectTree,
    // EditorRegistrySelect,
    RegistrySelect
  },
  props: {
    value: {
      type: Object
    },
    filters: {
      type: Array,
      editor: 'Filters'
    }
  },
  data () {
    return {
      data_source_types: [
        { id: 'registry', name: 'Реестр' },
        { id: 'plugin', name: 'Плагин' },
        { id: 'extend_object', name: 'Расширенная таблица' }
      ],
      listField: [],
      field: null,
      listExtendObject: [],
      dialogSettingsVisible: false,
      controlsElemMap: [
        {
          value: 'fullscreenControl',
          name: this.$locale.interface_editor.component_editor.editor_yandex_maps.full_screen_control
        },
        {
          value: 'geolocationControl',
          name: this.$locale.interface_editor.component_editor.editor_yandex_maps.geolocation_control
        },
        {
          value: 'routeEditor',
          name: this.$locale.interface_editor.component_editor.editor_yandex_maps.route_editor
        },
        {
          value: 'rulerControl',
          name: this.$locale.interface_editor.component_editor.editor_yandex_maps.ruler_control
        },
        {
          value: 'searchControl',
          name: this.$locale.interface_editor.component_editor.editor_yandex_maps.search_control
        },
        {
          value: 'trafficControl',
          name: this.$locale.interface_editor.component_editor.editor_yandex_maps.traffic_control
        },
        {
          value: 'typeSelector',
          name: this.$locale.interface_editor.component_editor.editor_yandex_maps.type_selector
        },
        {
          value: 'zoomControl',
          name: this.$locale.interface_editor.component_editor.editor_yandex_maps.zoom_control
        }
        // {
        //   value: 'routeButtonControl',
        //   name: this.$locale.interface_editor.component_editor.editor_yandex_maps.route_button_control
        // },
        // {
        //   value: 'routePanelControl',
        //   name: this.$locale.interface_editor.component_editor.editor_yandex_maps.route_panel_control
        // }
      ]
    }
  },
  watch: {
    'value.registryId': {
      async handler (id) {
        if (id) {
          let registryTree = await Registry.params({ parent_id: id, show_children: true }).get()
          this.listField = registryTree.data
        }
      },
      immediate: true
    },
    'value.extendObject': {
      async handler (id) {
        if (id) {
          let response = await this.$http.get(`${this.$config.api}/bieditor/extended_object_fields?extended_object_id=${id}`)
          this.listField = response.data.map((el) => ({ id: el.id, name: el.name }))
        }
      },
      immediate: true
    },
    'value.type': {
      handler (val) {
        this.$set(this.value, 'extendObject', null)
        this.$set(this.value, 'registryId', null)
        this.value.coords.latitude = this.value.coords.longitude = this.value.iconCaption = this.value.iconColor = null
        this.listField = []
      }
    },
    value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    }
  },
  async mounted () {
    const control = [
      'fullscreenControl',
      'geolocationControl',
      'routeEditor',
      'rulerControl',
      'searchControl',
      'trafficControl',
      'typeSelector',
      'zoomControl'
    ]
    if (!this.value.controls) this.$set(this.value, 'controls', control)
    let response = await this.$http.get(`${this.$config.api}/bieditor/extended_objects`)
    this.listExtendObject = response.data
  }
}
</script>

<style>
.yandex_maps .el-form-item__label {
  padding: 0px;
}
.yandex_maps .checkbox .el-form-item__content {
  display: inline-block;
  margin-left: 10px;
}
</style>
