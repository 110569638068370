<template>
  <div>
    <!-- Действие -->
    <EditorButtonAction :value="settings.action" @change="$set(settings, 'action', $event)"></EditorButtonAction>

    <div class="setting-label">{{$t('interface_editor.block_settings.plugin')}}</div>
    <EditorPlugin :value="settings.pluginName" @change="$set(settings, 'pluginName', $event)"></EditorPlugin>

    <div class="setting-label">{{$t('interface_editor.block_settings.css_cont_hover')}}</div>
    <codemirror ref="editorContainerHoverStyle" v-model="settings.containerHoverStyle" class="block_style_code" :options="codeEditorOptions" style="height: 150px;"></codemirror>
  </div>
</template>

<script>
import EditorButtonAction from '@/components/InterfaceEditor/components/editor/editor-button-action.vue'
import EditorPlugin from '@/components/InterfaceEditor/components/editor/editor-plugins.vue'

export default {
  name: 'InteractiveSettings',
  components: {
    EditorButtonAction,
    EditorPlugin
  },
  props: {
    settings: {
      type: Object,
      default () {
        return {
          action: { _: null /* Акцент сохраняет пустые объекты как массив */ },
          pluginName: null,
          containerHoverStyle: ''
        }
      }
    }
  },
  data () {
    return {
      codeEditorOptions: {
        tabSize: 2,
        mode: 'text/css',
        theme: 'base16-light',
        styleActiveLine: true,
        styleSelectedText: true
      }
    }
  },
  watch: {
    settings: {
      deep: true,
      handler (value) {
        this.$emit('change', value)
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.editorContainerHoverStyle.codemirror.refresh()
    })
  }
}
</script>

<style scoped lang="scss">
.tab {
  color: #949FA8;
  padding: 3px;
  display: flex;
  &:hover, &.active {
    outline: 1px solid #E9EBED;
  }
  & .number {
    color: #606266;
  }
  & .label:hover {
    color: #606266;
    cursor: pointer;
    border-bottom: 1px dashed #606266;
  }
  & .delete i:hover {
    color: #606266;
    cursor: pointer;
  }
}
::v-deep .el-input--mini .el-input__inner {
  height: 16px;
  line-height: 16px;
  border: none;
  font-size: 14px;
  padding: 0;
}
.setting-label:first-child {
  padding-top: 10px;
}
.setting-label {
  padding-top: 20px;
  & svg {
    margin-right: 5px;
    cursor: pointer;
    color: #949FA8;
    float: right;
  }
  & svg:hover {
    color: #606266;
  }
  & .additional {
    color: #949FA8;
    cursor: pointer;
  }
}
</style>
<style src="../../../main.scss" scoped lang="scss"></style>
