<template>
  <el-collapse class="editor-wrapper" value="editor-input-label">
    <el-collapse-item :title="$locale.interface_editor.component_editor.editor_gantt_new.source_type_table" name="editor-table-source">
      <el-form label-width="120px" label-position="top" size="mini">
        <!-- -->
        <el-form-item>
          <span class="property_label">{{ $t('interface_editor.component_editor.table.source_type') }}</span>
          <editor-select :value="value.table_source.type" @change="$set(value.table_source, 'type', $event)" :options="{multiple: false, options: data_source_types}"></editor-select>
        </el-form-item>
        <el-form-item v-if="value.table_source.type === 'registry'">
          <registry-select
            :append-to-body="false"
            :label="$t('interface_editor.component_editor.table.registry')"
            :value="value.table_source.entityId"
            @change="$set(value.table_source, 'entityId', $event)"
          ></registry-select>
        </el-form-item>
        <el-form-item v-if="value.table_source.type === 'query'">
            <span class="property_label">{{ $t('interface_editor.component_editor.table.request') }}</span>
            <editor-select
              :value="value.table_source.entityId"
              @change="$set(value.table_source ,'entityId', $event)"
              :options="{
            multiple: false,
            options: table_data
          }"
            ></editor-select>
        </el-form-item>
        <el-form-item>
          <span class="property_label">{{ $t('interface_editor.component_editor.table.column') }}</span>
          <editor-registry-headers
            :value="value.table_source.columns"
            :is-gantt="true"
            :otherProperties="{list: {}}" :options="{ objectId: value.table_source.entityId }"
            @change="$set(value.table_source, 'columns', $event)"
          ></editor-registry-headers>
        </el-form-item>
        <!-- Ключевое поле -->
        <el-tooltip placement="left-end" :open-delay="700" effect="dark">
          <div slot="content">{{ $t('interface_editor.component_editor.editor_gantt_new.tooltips.key_field') }}</div>
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_new.key_field') }}</span>
            <editor-select
              :value="value.table_source.key_field"
              @change="$set(value.table_source ,'key_field', $event)"
              :options="{
            multiple: false,
            options: value.table_source.attrs
          }"
            ></editor-select>
          </el-form-item>
        </el-tooltip>
        <!-- Группировка -->
        <el-tooltip placement="left-end" :open-delay="700" effect="dark">
          <div slot="content">
            {{ $t('interface_editor.component_editor.editor_gantt_new.tooltips.group_by.fixed') }}
            <br><p>{{ $t('interface_editor.component_editor.editor_gantt_new.tooltips.group_by.dynamic') }}</p>
          </div>
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_new.group_by') }}</span>
            <editor-select
              :value="value.use_group_by_attr"
              @change="$set(value ,'use_group_by_attr', $event)"
              :options="{
            multiple: false,
            options: grouping_options
          }"
            ></editor-select>
          </el-form-item>
        </el-tooltip>
        <!-- Группировка (аттрибут) -->
        <el-form-item v-if="value.use_group_by_attr !== 'none'">
          <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_new.attr') }}</span>
          <editor-select
            :value="value.group_by_attr"
            @change="$set(value ,'group_by_attr', $event)"
            :options="{
            multiple: false,
            options: value.table_source.attrs
          }"
          ></editor-select>
        </el-form-item>
      </el-form>
      <!-- Табличная часть -->
      <el-collapse-item :title="$t('interface_editor.component_editor.editor_gantt_new.filters')">
        <editor-filters
          :value="value.table_source.filters"
          @change="$set(value.table_source ,'filters', $event)"
        ></editor-filters>
      </el-collapse-item>
    </el-collapse-item>
    <!-- Календарная часть -->
    <el-collapse-item :title="$t('interface_editor.component_editor.editor_gantt_new.source_type_timeline')" name="editor-timeline-source">
      <el-form label-width="120px" label-position="top" size="mini">
        <!-- Отдельный источник -->
        <el-form-item>
          <el-checkbox v-model="value.is_separated_source"/>
          <span class="property_label" style="display: inline-block;">{{ $t('interface_editor.component_editor.editor_gantt_new.is_separated_source') }}</span>
        </el-form-item>
        <!-- Сохранять значение строки при передвижении плашки -->
        <el-form-item>
          <el-tooltip placement="left-end" :open-delay="700" effect="dark">
            <div slot="content">{{ $t('interface_editor.component_editor.editor_gantt_new.tooltips.chart_item_save_date_on_move') }}</div>
            <el-form-item>
              <el-checkbox v-model="value.is_save_date_on_move_item"/>
              <span class="property_label" style="display: inline-block;">{{ $t('interface_editor.component_editor.editor_gantt_new.chart_item_save_date_on_move') }}</span>
            </el-form-item>
          </el-tooltip>
        </el-form-item>
        <!-- Если is_separated_source активна -->
        <el-form-item v-if="value.is_separated_source">
          <span class="property_label">{{ $t('interface_editor.component_editor.table.source_type') }}</span>
          <editor-select
            :value="value.timeline_source.type"
            @change="$set(value.timeline_source, 'type', $event)"
            :options="{multiple: false, options: data_source_types}"></editor-select>
          <el-form-item v-if="value.timeline_source.type === 'registry'">
            <registry-select
              :append-to-body="false"
              :label="$t('interface_editor.component_editor.table.registry')"
              :value="value.timeline_source.entityId"
              @change="$set(value.timeline_source, 'entityId', $event)"
            ></registry-select>
          </el-form-item>
          <el-form-item v-if="value.timeline_source.type === 'query'">
            <span class="property_label">{{ $t('interface_editor.component_editor.table.request') }}</span>
            <editor-select
              :value="value.timeline_source.entityId"
              @change="$set(value.timeline_source ,'entityId', $event)"
              :options="{
            multiple: false,
            options: table_data
          }"
            ></editor-select>
          </el-form-item>
          <!-- Ключевое поле -->
          <el-tooltip placement="left-end" :open-delay="700" effect="dark">
            <div slot="content">{{ $t('interface_editor.component_editor.editor_gantt_new.tooltips.key_field') }}</div>
            <el-form-item>
              <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_new.key_field') }}</span>
              <editor-select
                :value="value.timeline_source.key_field"
                @change="$set(value.timeline_source ,'key_field', $event)"
                :options="{
             multiple: false,
             options: value.timeline_source.attrs
          }"
              ></editor-select>
            </el-form-item>
          </el-tooltip>
        </el-form-item>
        <!-- Настройки календарной части -->
        <el-form-item>
          <el-form-item v-if="value.is_separated_source">
            <el-collapse>
              <!-- -->
              <el-collapse-item :title="$t('interface_editor.component_editor.editor_gantt_new.link_attribute')" name="editor-link-attributes">
                <!-- Атрибут для связи источников (табличная) -->
                <el-tooltip placement="left-end" :open-delay="700" effect="dark">
                  <div slot="content">{{ $t('interface_editor.component_editor.editor_gantt_new.tooltips.source_type_timeline') }}</div>
                  <el-form-item>
                    <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_new.source_type_table') }}</span>
                    <editor-select
                      :value="value.table_source.link_attribute"
                      @change="$set(value.table_source, 'link_attribute', $event)"
                      :options="{
                      multiple: false,
                      options: value.table_source.attrs
                    }"
                    ></editor-select>
                  </el-form-item>
                </el-tooltip>
                <!-- Атрибут для связи источников (календарная) -->
                <el-tooltip placement="left-end" :open-delay="700" effect="dark">
                  <div slot="content">{{ $t('interface_editor.component_editor.editor_gantt_new.tooltips.source_type_timeline') }}</div>
                  <el-form-item>
                    <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_new.source_type_timeline') }}</span>
                    <editor-select
                      :value="value.timeline_source.link_attribute"
                      @change="$set(value.timeline_source, 'link_attribute', $event)"
                      :options="{
                      multiple: false,
                      options: value.timeline_source.attrs
                    }"
                    ></editor-select>
                  </el-form-item>
                </el-tooltip>
              </el-collapse-item>
              <!-- Фильтры -->
              <el-collapse-item :title="$t('interface_editor.component_editor.editor_gantt_new.filters')">
                <editor-filters
                  :value="value.timeline_source.filters"
                  @change="$set(value.timeline_source ,'filters', $event)"
                ></editor-filters>
              </el-collapse-item>
            </el-collapse>
          </el-form-item>
          <!-- Лейбл плашки -->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_new.row_keys.label') }}</span>
            <el-tooltip placement="left-end" :open-delay="700" effect="dark">
              <div slot="content">{{ $t('interface_editor.component_editor.editor_gantt_new.tooltips.label') }}</div>
              <editor-select
                :value="value.row_keys.label"
                @change="$set(value.row_keys, 'label', $event)"
                :options="{
                    multiple: false,
                    options: value.is_separated_source ? value.timeline_source.attrs : value.table_source.attrs
                  }"
              ></editor-select>
          </el-tooltip>
          </el-form-item>
        <!--<editor-string
            :value="value.row_keys.tooltip"
            :label="$locale.interface_editor.component_editor.editor_gantt_new.row_keys.tooltip"
            @change="$set(value.row_keys, 'tooltip', $event)"
          ></editor-string>-->
          <!-- Дата начала плашки -->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_new.row_keys.start_date') }}</span>
            <el-tooltip placement="left-end" :open-delay="700" effect="dark">
              <div slot="content">{{ $t('interface_editor.component_editor.editor_gantt_new.tooltips.start_date') }}</div>
              <editor-select
                :value="value.row_keys.start_date"
                @change="$set(value.row_keys, 'start_date', $event)"
                :options="{
                    multiple: false,
                    options: value.is_separated_source ? value.timeline_source.attrs : value.table_source.attrs
                  }"
              ></editor-select>
            </el-tooltip>
          </el-form-item>
          <!-- Дата окончания плашки -->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_new.row_keys.end_date') }}</span>
            <el-tooltip placement="left-end" :open-delay="700" effect="dark">
              <div slot="content">{{ $t('interface_editor.component_editor.editor_gantt_new.tooltips.end_date') }}</div>
              <editor-select
                :value="value.row_keys.end_date"
                @change="$set(value.row_keys, 'end_date', $event)"
                :options="{
                  multiple: false,
                  options: value.is_separated_source ? value.timeline_source.attrs : value.table_source.attrs
                }"
              ></editor-select>
            </el-tooltip>
          </el-form-item>
          <!-- Масштаб диаграммы по умолчанию -->
          <span class="property_label" style="display: inline-block;">{{ $t('interface_editor.component_editor.editor_gantt_new.zoom_default_type') }}</span>
          <editor-select
            :value="value.zoom.level"
            @change="$set(value.zoom, 'level', $event)"
            :options="{
              multiple: false,
              clearable: true,
              options: value.options
            }"
          ></editor-select>
          <!-- Фон плашки -->
          <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_new.chart_item_background_color') }}</span>
          <editor-select
            :value="value.chart_item_background_color"
            @change="$set(value, 'chart_item_background_color', $event)"
            :options="{
                    multiple: false,
                    options: value.is_separated_source ? value.timeline_source.attrs : value.table_source.attrs
                  }"
          ></editor-select>
          <!-- Стили плашки -->
          <editor-string
            :value="value.row_keys.style"
            :label="$t('interface_editor.component_editor.editor_gantt_new.row_keys.style')"
            @change="$set(value.row_keys, 'style', $event)"
          ></editor-string>
          <!-- Режим наслоения плашки -->
          <el-form-item v-if="value.is_separated_source">
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_new.chart_item_overlap') }}</span>
            <editor-select
              :value="value.chart_item_overlap"
              @change="$set(value, 'chart_item_overlap', $event)"
              :options="{
                      multiple: false,
                      options: chart_item_overlap
                    }"
            ></editor-select>
          <!-- -->
          </el-form-item>
            <!-- Тип отображения плашки -->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_new.chart_item_type') }}</span>
            <el-tooltip placement="left-end" :open-delay="700" effect="dark">
              <div slot="content">{{ $t('interface_editor.component_editor.editor_gantt_new.tooltips.chart_item_type') }}</div>
              <editor-select
                :value="value.chart_item_type"
                @change="$set(value, 'chart_item_type', $event)"
                :options="{
                      multiple: false,
                      options: chart_item_type
                    }"
              ></editor-select>
            </el-tooltip>
          </el-form-item>
            <!-- Прогресс на плашке -->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_new.chart_item_progress') }}</span>
            <el-tooltip placement="left-end" :open-delay="700" effect="dark">
              <div slot="content">{{ $t('interface_editor.component_editor.editor_gantt_new.tooltips.chart_item_progress') }}</div>
              <editor-select
                clearable
                :value="value.chart_item_progress"
                @change="$set(value, 'chart_item_progress', $event)"
                :options="{
                      multiple: false,
                      clearable: true,
                      options: value.is_separated_source ? value.timeline_source.attrs : value.table_source.attrs
                    }"
              ></editor-select>
            </el-tooltip>
          </el-form-item>
        </el-form-item>
        <!-- Отображение связей -->
        <el-form-item>
          <el-checkbox v-model="value.is_display_relations"/>
          <span class="property_label" style="display: inline-block;">{{ $t('interface_editor.component_editor.editor_gantt_new.display_relations') }}</span>
          <!-- Тип линии связи -->
          <el-form-item v-if="value.is_display_relations">
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_new.relation_line_type') }}</span>
            <editor-select
              :value="value.chart_item_relation_line_type"
              @change="$set(value, 'chart_item_relation_line_type', $event)"
              :options="{
                    multiple: false,
                    options: chart_item_relation_line_types
                  }"
            ></editor-select>
            <!-- Родительская задача -->
            <el-form-item>
              <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_new.parent_task') }}</span>
              <el-tooltip placement="left-end" :open-delay="700" effect="dark">
                <div slot="content">{{ $t('interface_editor.component_editor.editor_gantt_new.tooltips.parent_task') }}</div>
                <editor-select
                  :value="value.chart_item_parent_task"
                  @change="$set(value, 'chart_item_parent_task', $event)"
                  :options="{
                    multiple: false,
                    options: value.is_separated_source ? value.timeline_source.attrs : value.table_source.attrs
                  }"
                ></editor-select>
              </el-tooltip>
            </el-form-item>

            <el-form-item>
              <span class="property_label" style="display: inline-block;">{{ $t('interface_editor.component_editor.editor_gantt_new.chart_items_movement') }}</span>
              <editor-select
                :value="value.chart_items_movement"
                @change="$set(value, 'chart_items_movement', $event)"
                :options="{
              multiple: false,
              clearable: true,
              options: value.chart_items_movement_options
            }"
              ></editor-select>
            </el-form-item>
          </el-form-item>
        </el-form-item>
        <!-- Действия при клике на плашку -->
        <el-form-item>
          <editor-button-action
            :value='value.chart_item_action'
            :label="$t('interface_editor.component_editor.editor_gantt_new.chart_item_action')"
            :source-id="value.chart_item_action.card.registryId"
            source-type="registry"
            :is-headers="true"
            @change="$set(value, 'chart_item_action', $event)">
          </editor-button-action>
        </el-form-item>
        <!-- Название плагина -->
        <el-form-item>
          <editor-plugin-name
            :value="value.chart_item_action.plugin_name"
            @change="$set(value.chart_item_action, 'plugin_name', $event)">
          </editor-plugin-name>
        </el-form-item>
      </el-form>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import EditorSelect from './editor-select'
import EditorString from './editor-string'
import EditorRegistrySelect from './editor-registry-select'
import EditorRegistryHeaders from './editor-registry-headers'
import EditorButtonAction from './editor-button-action'
import EditorPluginName from './editor-plugins'
import EditorFilters from './editor-filters'
import { buildFilters } from '@/components/InterfaceEditor/components/utils'
import RegistrySelect from '@/components/Common/RegistrySelectTreeWithSearch.vue'

export default {
  name: 'editor-gantt-source',
  components: {
    EditorPluginName,
    EditorButtonAction,
    EditorString,
    EditorSelect,
    EditorRegistrySelect,
    EditorRegistryHeaders,
    EditorFilters,
    RegistrySelect
  },
  inject: {
    openRegistryCard: {
      default: () => {}
    }
  },
  props: {
    value: {
      type: Object
    },
    sourceType: {
      type: String
    },
    sourceId: {
      type: Number
    }
  },
  data () {
    return {
      table_data: [],
      timeline_data: [],
      data_source_types: [
        { id: 'extended_object', name: 'Расширенная таблица' },
        { id: 'query', name: 'Запросы' },
        { id: 'registry', name: 'Реестр' }
      ],
      grouping_options: [
        { id: 'none', name: 'Отсутствует' },
        { id: 'fixed', name: '1 уровень (фиксированное поле)' },
        { id: 'dynamic', name: 'Динамическая (ссылка "само на себя")' }
      ],
      chart_item_overlap: [
        { id: false, name: 'Друг под другом' },
        { id: true, name: 'Наложение' }
      ],
      chart_item_type: [
        { id: 'task', name: 'Задача' },
        { id: 'project', name: 'Проект' },
        { id: 'milestone', name: 'Веха' }
      ],
      chart_item_relation_line_types: [
        { id: 'straight', name: 'Прямая' },
        { id: 'square', name: 'Квадратная' },
        { id: 'square-alt', name: 'Квадратная (обтекающая)' },
        { id: 'smooth', name: 'Плавная' }
      ]
    }
  },
  watch: {
    value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    },
    'value.table_source.type': {
      async handler (type) {
        if (type) {
          this.table_data = await this.getData(this.value.table_source.type)
          this.$set(this.value.table_source, 'entityId', null)
          this.$set(this.value.table_source, 'registryId', null)
          this.$set(this.value.table_source, 'columns', [])
          this.$set(this.value.table_source, 'link_attribute', null)
          this.$set(this.value, 'row_keys', { label: null, start_date: null, end_date: null, tooltip: null })
          this.$set(this.value, 'use_group_by_attr', false)
          this.$set(this.value, 'group_by_attr', null)
          this.$set(this.value, 'chart_item_background_color', null)
        }
      }
    },
    'value.timeline_source.type': {
      async handler (type) {
        if (type) {
          this.timeline_data = await this.getData(this.value.timeline_source.type)
          this.$set(this.value.timeline_source, 'entityId', null)
          this.$set(this.value.timeline_source, 'registryId', null)
          this.$set(this.value.timeline_source, 'columns', [])
          this.$set(this.value.timeline_source, 'link_attribute', null)
          this.$set(this.value, 'row_keys', { label: null, start_date: null, end_date: null, tooltip: null })
          this.$set(this.value, 'use_group_by_attr', false)
          this.$set(this.value, 'group_by_attr', null)
          this.$set(this.value, 'chart_item_background_color', null)
        }
      }
    },
    'value.zoom.type': {
      handler (value) {
        this.$set(this.value.zoom, 'level', value)
      }
    },
    'value.table_source.entityId': {
      async handler (value) {
        let entityId = this.getEntityId(this.value.table_source.type, this.value.table_source)
        let rowData = await this.getRowData(value, this.value.table_source.type)
        if (rowData) {
          this.$set(this.value.table_source, 'attrs', Object.entries(rowData[0]).map(function (item) { return { id: item[0], name: item[0] } }))
        }
      }
    },
    'value.timeline_source.entityId': {
      async handler (value) {
        let entityId = this.getEntityId(this.value.timeline_source.type, this.value.timeline_source)
        let rowData = await this.getRowData(value, this.value.timeline_source.type)
        if (rowData) {
          this.$set(this.value.timeline_source, 'attrs', Object.entries(rowData[0]).map(function (item) { return { id: item[0], name: item[0] } }))
        }
      }
    }
  },
  async mounted () {
    if (this.value.table_source.type) {
      let entityId = this.getEntityId(this.value.table_source.type, this.value.table_source)
      this.table_data = await this.getData(this.value.table_source.type)
      let rowData = await this.getRowData(entityId, this.value.table_source.type)
      if (rowData) {
        this.$set(this.value.table_source, 'attrs', Object.entries(rowData[0]).map(function (item) { return { id: item[0], name: item[0] } }))
      }
    }
    if (this.value.is_separated_source) {
      let entityId = this.getEntityId(this.value.timeline_source.type, this.value.timeline_source)
      this.timeline_data = await this.getData(this.value.timeline_source.type)
      let rowData = await this.getRowData(entityId, this.value.timeline_source.type)
      if (rowData) {
        this.$set(this.value.timeline_source, 'attrs', Object.entries(rowData[0]).map(function (item) { return { id: item[0], name: item[0] } }))
      }
    }
  },
  methods: {
    async getData (type) {
      let data = []
      switch (type) {
        case 'extended_object':
          data = await this.$http.get(`${this.$config.api}/bieditor/extended_objects`)
          break
        case 'query':
          data = await this.$http.get(`${this.$config.api}/bieditor/queries`)
          break
      }

      return data.data
    },
    async getRowData (entityId, sourceType) {
      let result
      let response

      switch (sourceType) {
        case 'query':
          response = await this.$http.post(
            `${this.$config.api}/datawarehouseservice/query/${entityId}`,
            typeof buildFilters.where !== 'undefined' ? buildFilters : null,
            { hideNotification: true }
          )
          result = response.data
          break
        case 'registry':
          response = await this.$http.post(
            `${this.$config.api}/registryservice/registry/${entityId}`,
            typeof buildFilters.where !== 'undefined' ? buildFilters : null,
            { hideNotification: true }
          )
          result = response.data.data
          break
      }
      return result
    },
    getEntityId (entityType, data) {
      let entityId
      switch (entityType) {
        case 'query':
          entityId = data.entityId
          break
        case 'registry':
          entityId = data.entityId
          break
      }
      return entityId
    }
  }
}
</script>

<style scoped>
th {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #7D7D7D;
}
td {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;

  text-align: center;

  color: #2C2D35;
}
th, td {
  /*opacity: 0.2;*/
  border-bottom: 1px solid rgba(124, 126, 128, 0.2);
  padding: 10px;
  width: 100px;
}
table {
  border-collapse: collapse;
  width: 100%;
}
thead th {
  position: sticky;
  top: 0;
  /* background: white; */
}
.table-fixed-head tbody tr:nth-child(even) {
  background-color: rgb(250 250 250);
}
.list_toolbar {
  position: sticky;
  top: 0;
}
.editor-wrapper {
  border-left: 2px dashed #E4E7ED;
  padding-left: 10px;
}
</style>
