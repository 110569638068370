import Mark from './mark.es6'
export default function markWord (context) {
  let instance = new Mark(context)
  instance.markRegExp(/СУММА/,
    {
      'element': 'span',
      'className': 'accent-function',
      'contenteditable': 'false',
      'function': 'sum',
      'exclude': [
        'span',
        '.accent-function *'
      ]
    })
  instance.markRegExp(/ГОД/,
    {
      'element': 'span',
      'className': 'accent-function',
      'contenteditable': 'false',
      'function': 'year',
      'exclude': [
        'span',
        '.accent-function *'
      ]
    })
  instance.markRegExp(/СРЕДНЕЕ/,
    {
      'element': 'span',
      'className': 'accent-function',
      'contenteditable': 'false',
      'function': 'avg',
      'exclude': [
        'span',
        '.accent-function *'
      ]
    })
  instance.markRegExp(/СЦЕПИТЬ/,
    {
      'element': 'span',
      'className': 'accent-function',
      'contenteditable': 'false',
      'function': 'concat',
      'exclude': [
        'span',
        '.accent-function *'
      ]
    })
  instance.markRegExp(/КОЛИЧЕСТВО/,
    {
      'element': 'span',
      'className': 'accent-function',
      'contenteditable': 'false',
      'function': 'count',
      'exclude': [
        'span',
        '.accent-function *'
      ]
    })
  instance.markRegExp(/ДАТА_В_СТРОКУ/,
    {
      'element': 'span',
      'className': 'accent-function',
      'contenteditable': 'false',
      'function': 'date_to_string',
      'exclude': [
        'span',
        '.accent-function *'
      ]
    })
  instance.markRegExp(/ДЕНЬ/,
    {
      'element': 'span',
      'className': 'accent-function',
      'contenteditable': 'false',
      'function': 'day',
      'exclude': [
        'span',
        '.accent-function *'
      ]
    })
  instance.markRegExp(/РАЗНИЦА/,
    {
      'element': 'span',
      'className': 'accent-function',
      'contenteditable': 'false',
      'function': 'datediff',
      'exclude': [
        'span',
        '.accent-function *'
      ]
    })
  instance.markRegExp(/СЕЙЧАС/,
    {
      'element': 'span',
      'className': 'accent-function',
      'contenteditable': 'false',
      'function': 'now',
      'exclude': [
        'span',
        '.accent-function *'
      ]
    })
  instance.markRegExp(/\s(ПУСТО)\s/g,
    {
      'element': 'span',
      'className': 'accent-function',
      'contenteditable': 'false',
      'function': 'empty',
      'exclude': [
        'span',
        '.accent-function *'
      ]
    })
  instance.markRegExp(/МАКСИМУМ/,
    {
      'element': 'span',
      'className': 'accent-function',
      'contenteditable': 'false',
      'function': 'max',
      'exclude': [
        'span',
        '.accent-function *'
      ]
    })
  instance.markRegExp(/МИНИМУМ/,
    {
      'element': 'span',
      'className': 'accent-function',
      'contenteditable': 'false',
      'function': 'min',
      'exclude': [
        'span',
        '.accent-function *'
      ]
    })
  instance.markRegExp(/ПОЛЕ_ПО_ССЫЛКЕ/,
    {
      'element': 'span',
      'className': 'accent-function',
      'contenteditable': 'false',
      'function': 'field_by_xref',
      'exclude': [
        'span',
        '.accent-function *'
      ]
    })
  instance.markRegExp(/МЕСЯЦ/,
    {
      'element': 'span',
      'className': 'accent-function',
      'contenteditable': 'false',
      'function': 'month',
      'exclude': [
        'span',
        '.accent-function *'
      ]
    })
  instance.markRegExp(/НЕ_ПУСТО\s/g,
    {
      'element': 'span',
      'className': 'accent-function',
      'contenteditable': 'false',
      'function': 'not_empty',
      'exclude': [
        'span',
        '.accent-function *'
      ]
    })
  instance.markRegExp(/ОКРУГЛВВЕРХ/,
    {
      'element': 'span',
      'className': 'accent-function',
      'contenteditable': 'false',
      'function': 'ceil',
      'exclude': [
        'span',
        '.accent-function *'
      ]
    })
  instance.markRegExp(/ОКРУГЛВНИЗ/,
    {
      'element': 'span',
      'className': 'accent-function',
      'contenteditable': 'false',
      'function': 'floor',
      'exclude': [
        'span',
        '.accent-function *'
      ]
    })
  instance.markRegExp(/И\s/g,
    {
      'element': 'span',
      'className': 'accent-operator',
      'contenteditable': 'false',
      'statement': 'and',
      'exclude': [
        'span',
        '.accent-operator *'
      ]
    })
  instance.markRegExp(/ИНАЧЕ\s/g,
    {
      'element': 'span',
      'className': 'accent-operator',
      'contenteditable': 'false',
      'statement': 'else',
      'exclude': [
        'span',
        '.accent-operator *'
      ]
    })
  instance.markRegExp(/ИНАЧЕ_ЕСЛИ/,
    {
      'element': 'span',
      'className': 'accent-operator',
      'contenteditable': 'false',
      'statement': 'when',
      'exclude': [
        'span',
        '.accent-operator *'
      ]
    })
  instance.markRegExp(/ЕСЛИ/,
    {
      'element': 'span',
      'className': 'accent-operator',
      'contenteditable': 'false',
      'statement': 'case_when',
      'exclude': [
        'span',
        '.accent-operator *'
      ]
    })
  instance.markRegExp(/В\s/g,
    {
      'element': 'span',
      'className': 'accent-operator',
      'contenteditable': 'false',
      'statement': 'in',
      'exclude': [
        'span',
        '.accent-operator *'
      ]
    })
  instance.markRegExp(/НЕТ/,
    {
      'element': 'span',
      'className': 'accent-operator',
      'contenteditable': 'false',
      'statement': 'false',
      'exclude': [
        'span',
        '.accent-operator *'
      ]
    })
  instance.markRegExp(/НЕ\s/g,
    {
      'element': 'span',
      'className': 'accent-operator',
      'contenteditable': 'false',
      'statement': 'not',
      'exclude': [
        'span',
        '.accent-operator *'
      ]
    })
  instance.markRegExp(/НЕ_В/,
    {
      'element': 'span',
      'className': 'accent-operator',
      'contenteditable': 'false',
      'statement': 'not_in',
      'exclude': [
        'span',
        '.accent-operator *'
      ]
    })
  instance.markRegExp(/ИЛИ/,
    {
      'element': 'span',
      'className': 'accent-operator',
      'contenteditable': 'false',
      'statement': 'or',
      'exclude': [
        'span',
        '.accent-operator *'
      ]
    })
  instance.markRegExp(/ТОГДА/,
    {
      'element': 'span',
      'className': 'accent-operator',
      'contenteditable': 'false',
      'statement': 'then',
      'exclude': [
        'span',
        '.accent-operator *'
      ]
    })
  instance.markRegExp(/ГДЕ/,
    {
      'element': 'span',
      'className': 'accent-operator',
      'contenteditable': 'false',
      'statement': 'where',
      'exclude': [
        'span',
        '.accent-operator *'
      ]
    })
  instance.markRegExp(/(ДА)\s/g,
    {
      'element': 'span',
      'className': 'accent-operator',
      'contenteditable': 'false',
      'statement': 'true',
      'exclude': [
        'span',
        '.accent-operator *'
      ]
    })
}
