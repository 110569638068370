export interface ModelTreeElementState {
  isLoading: boolean;
  location: string;
  deletedElementGuid: string;
  error: object
}

export const initialState = (): ModelTreeElementState => {
  return {
    isLoading: false,
    location: null,
    deletedElementGuid: null,
    error: null
  }
}