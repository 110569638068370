<template>
  <el-form-item :label="label" style="border: 1px dashed #1e171752; padding: 10px;" class="regidtry_table_card">
    <el-select clearable v-model="localModel.card_id">
      <el-option
        v-for="item in options"
        :key="item.id"
        :label="item.name"
        :value="item.id">
        <span style="float: left">{{ item.name }}</span>
        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.id }}</span>
      </el-option>
    </el-select>
    <div style="display: flex;">
      <div style="display: flex;flex-direction: column; margin-right: 7%;">
      <span class="color_text">Ширина:</span>
        <el-select style="width: 100px" v-model="localModel.width">
          <el-option
            v-for="item in widthOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div>
        <span class="color_text">Высота:</span>
        <el-input v-model="localModel.height" placeholder="400px"></el-input>
      </div>
    </div>
  </el-form-item>
</template>

<script>
import Card from '../Models/Card'

export default {
  name: 'card',

  props: ['value', 'entity-id', 'label'],

  data () {
    return {
      localModel: this.value || { card_id: null, width: '25', height: '400px' },
      options: [],
      widthOptions: [{ id: '25', name: '25%' },
        { id: '50', name: '50%' },
        { id: '75', name: '75%' },
        { id: '100', name: '100%' }]
    }
  },

  watch: {
    localModel: {
      deep: true,
      handler (value) {
        this.$emit('input', value)
      }
    }
  },

  mounted () {
    this.loadData()
  },

  methods: {
    async loadData () {
      this.options = await new Card().params({
        entity_id: this.entityId,
        order: 'id',
        fields: ['id', 'name']
      }).$get()
    }
  }
}
</script>

<style scoped>
.regidtry_table_card .color_text {
    color: #606266;
}
</style>
