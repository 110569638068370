import { ActionTree } from 'vuex'
import { TaskState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, TaskLoadingEvent, TaskCreatedEvent, TaskUpdatedEvent, TaskDeletedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { TaskAPI } from '@/services/EtlEditor/infrastructure/api/TaskAPI'

export const actions: ActionTree<TaskState, any> = {
  [ActionTypes.GET_TASK_BY_GUID]: async ({ commit, dispatch }, data) => {
    try {
      return await APIClient.shared.request(new TaskAPI.GetTaskByGuid(data.guid))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.CREATE_TASK]: async ({ commit, dispatch }, data) => {
    try {
      commit(new TaskLoadingEvent())
      let location = await APIClient.shared.request(new TaskAPI.CreateTask(data.payload))
      commit(new TaskCreatedEvent(location))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.UPDATE_TASK]: async ({ commit, dispatch }, data) => {
    try {
      commit(new TaskLoadingEvent())
      let location = await APIClient.shared.request(new TaskAPI.UpdateTask(data.payload))
      commit(new TaskUpdatedEvent(location))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.DELETE_TASK]: async ({ commit, dispatch }, data) => {
    try {
      await APIClient.shared.request(new TaskAPI.DeleteTask(data.guid))
      commit(new TaskDeletedEvent(data.guid))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_TASK_OBJECTS]: async ({ commit, dispatch }, data) => {
    try {
      return await APIClient.shared.request(new TaskAPI.GetTaskObjects(data.payload))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_TASK_OBJECT_BY_TASK_ID]: async ({ commit, dispatch }, data) => {
    try {
      return await APIClient.shared.request(new TaskAPI.GetTaskObjectByTaskId(data.taskId))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  }
}
