import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    DEPLOYMENT_LOADING_EVENT = 'deployment_loading_event',
    DEPLOYMENT_LOADED_EVENT = 'deployment_loaded_event'
}

export class DeploymentLoadingEvent implements FluxStandardAction {
    type = MutationTypes.DEPLOYMENT_LOADING_EVENT
}

export class DeploymentLoadedEvent implements FluxStandardAction {
    type = MutationTypes.DEPLOYMENT_LOADED_EVENT
}
