<template>
  <el-row>
    <el-col :span="8">
      <el-select size="small" v-model="data.type" placeholder="Select" @change="onChange">
        <el-option
          v-for="item in items"
          :key="item.type"
          :label="item.name"
          :value="item.type">
        </el-option>
      </el-select>
    </el-col>
    <el-col :span="16">
      <el-date-picker v-show="['eq','lt','gt'].includes(data.type)"
                      size="small"
                      value-format="yyyy-MM-dd HH:mm"
                      format="dd.MM.yyyy HH:mm"
                      v-model="data.value"
                      type="datetime"
                      :picker-options="{ firstDayOfWeek: 1 }"
                      @change="onChange"
                      placeholder="Pick a day">
      </el-date-picker>
      <el-date-picker
        v-show="['bt'].includes(data.type)"
        size="small"
        value-format="yyyy-MM-dd HH:mm"
        format="dd.MM.yyyy HH:mm"
        @change="onChange"
        v-model="data.value_range"
        :picker-options="{ firstDayOfWeek: 1 }"
        type="datetimerange"
        range-separator="-"
      >
      </el-date-picker>
    </el-col>
  </el-row>
</template>

<script>
import mixin from './mixin'
export default {
  name: 'datetime_field',
  mixins: [mixin],
  data () {
    return {
      data: {
        type: null,
        value: null,
        value_range: []
      },
      items: this.$locale.registry.filter.fields.date_field.types
    }
  },
  methods: {
    render () {
      if (this.value) {
        let properties = this.value.split(',')
        this.data.type = properties.shift()
        switch (this.data.type) {
          case 'eq':case 'gt':case 'lt':
            this.data.value = properties.join(',')
            break
          case 'bt':
            this.data.value_range = properties
            break
        }
      }
    },
    onChange () {
      let value = null
      if (this.data.type && (this.data.value || this.data.value_range.length)) {
        switch (this.data.type) {
          case 'eq':case 'gt':case 'lt':
            value = `${this.data.type},${this.data.value}`
            break
          case 'bt':
            value = `${this.data.type},${this.data.value_range.join(',')}`
            break
        }
      }
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped>
  .el-date-editor--daterange.el-input__inner, .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%;
  }
</style>
