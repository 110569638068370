import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    ACTIVITY_LOADING_EVENT = 'activity_loading_event',
    ACTIVITY_LOADED_EVENT = 'activity_loaded_event'
}

export class ActivityLoadingEvent implements FluxStandardAction {
    type = MutationTypes.ACTIVITY_LOADING_EVENT
}

export class ActivityLoadedEvent implements FluxStandardAction {
    type = MutationTypes.ACTIVITY_LOADED_EVENT
}