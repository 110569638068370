<template>
  <div class="editor-xref-autocomplete">
    <el-form-item>
      <el-checkbox v-model="model.is_active">Режим заполнения</el-checkbox>
    </el-form-item>

    <template v-if="model.is_active">
      <template v-if="objectId">
        <el-form-item>
          <template v-slot:label>
            <span class="property_label">Соотношение данных</span>
            <el-checkbox :value="model.button_only" @change="$set(model, 'button_only', $event)">Заполнять только по кнопке</el-checkbox>

            <el-button style="float:right;" type="success" icon="el-icon-plus" @click="addField()" circle></el-button>
          </template>

          <div class="field-item" v-for="(field, index) in model.fields" :key="index">
            <el-row :gutter="5">
              <el-col :span="12">
                <registry-select-tree
                  v-model="model.fields[index].property"
                  type="field"
                  value-as="number"
                  :parent-id="objectId"
                ></registry-select-tree>
              </el-col>

              <el-col :span="12">
                <el-select v-model="model.fields[index].attribute" placeholder="Атрибут">
                  <el-option
                    v-for="(item, cmpIndex) in components.filter((cmp) => cmp.properties.name)"
                    :key="cmpIndex"
                    :label="`${item.properties.label} (${item.properties.name})`"
                    :value="item.properties.name"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>

            <el-row :gutter="5">
              <el-col :span="12">
                <el-checkbox v-model="model.fields[index].isXref">Ссылка</el-checkbox>
              </el-col>

              <el-col :span="12">
                <div style="display: flex;justify-content: flex-end;width: 100%;">
                  <el-button type="danger" icon="el-icon-delete" circle @click="deleteField(index)"></el-button>
                </div>
              </el-col>
            </el-row>

            <el-row :gutter="5">
              <el-col :span="12">
                <el-checkbox v-model="model.fields[index].isAddress">Адрес</el-checkbox>
              </el-col>
            </el-row>
          </div>
        </el-form-item>
      </template>

      <template v-else>
        <p>Необходимо определить реестр компонента, для настройки автозаполнения. <b>Реестр: {{ objectId || 'не определён' }} | Тип: {{ type }}</b></p>
      </template>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
import RegistrySelectTree from '@/core/infrastructure/components/RegistrySelectTree.vue'
import { deepClone } from '@/components/InterfaceEditor/components/utils'

const defaultModel = () => deepClone({
  // Определяет включено ли поведение
  is_active: false,

  // Определяет включено ли заполнение по кнопке
  button_only: false,

  // Мэппинг полей реестра с атрибутами карточки
  fields: []
})

export default Vue.extend({
  name: 'editor-xref-autocomplete',

  props: {
    value: {
      type: Object,
      default () {
        return defaultModel()
      }
    },

    type: {
      type: String,
      default: 'current',
      validate: function (value) {
        return [
          'current', // По текущему реестру
          'external' // По внешнему реестру
        ].includes(value)
      }
    },

    otherProperties: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  components: {
    RegistrySelectTree
  },

  inject: {
    getComponents: {
      default: () => () => null
    },

    getRegistryId: {
      default: () => () => null
    }
  },

  computed: {
    attributeId () {
      return parseInt(/attr_([0-9]+)_/i.exec(this.otherProperties.name) ? /attr_([0-9]+)_/i.exec(this.otherProperties.name)[1] : 0)
    },

    components () {
      if (this.getComponents) {
        return this.getComponents()
      }

      return []
    }
  },

  watch: {
    model: {
      handler: function (value) {
        this.$emit('change', value)
      },
      deep: true
    }
  },

  data () {
    return {
      model: defaultModel(),

      objectId: null
    }
  },

  mounted () {
    if (this.attributeId) {
      this.$http.get(`${this.$config.api}/objecteditor/entities/${this.attributeId}/xref`).then((response) => {
        if (response.data.data) {
          this.objectId = response.data.data.xref_object_id
        }
      })
    }

    this.model = this.value || defaultModel()

    this.fixModel()
  },

  methods: {
    fixModel () {
      if (!Array.isArray(this.model.fields)) {
        this.model.fields = []
      }

      this.model.fields.forEach(item => {
        if (typeof item.isAddress === 'undefined') {
          item.isAddress = false
        }
      })
    },

    addField () {
      this.model.fields.push({
        property: null,
        attribute: null,
        isXref: false,
        isAddress: false
      })
    },

    deleteField (index) {
      this.model.fields.splice(index, 1)
    }
  }
})
</script>

<style lang="scss">
  .editor-xref-autocomplete {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;

    .field-item {
      margin-bottom: 5px;
    }

    .property_label {
      display: inline-block;
    }
  }
</style>
