import { FluxStandardAction } from '@/core/domain/type/types'
import { ConstraintDTO } from '@/services/LogicEditor/domain/model/Constraint'

export enum ActionTypes {
  GET_CONSTRAINT_TYPES = 'get_constraint_types',
  GET_CONSTRAINT_BY_GUID = 'get_constraint_by_guid',
  CREATE_CONSTRAINT = 'create_constraint',
  UPDATE_CONSTRAINT = 'update_constraint',
  DELETE_CONSTRAINT = 'delete_constraint'
}

export class GetConstraintTypes implements FluxStandardAction {
  type = 'Constraint/' + ActionTypes.GET_CONSTRAINT_TYPES
  constructor () {}
}

export class GetConstraintByGuid implements FluxStandardAction {
  type = 'Constraint/' + ActionTypes.GET_CONSTRAINT_BY_GUID
  constructor (public guid: string) {}
}

export class CreateConstraint implements FluxStandardAction {
  type = 'Constraint/' + ActionTypes.CREATE_CONSTRAINT
  constructor (public payload: ConstraintDTO) {}
}

export class UpdateConstraint implements FluxStandardAction {
  type = 'Constraint/' + ActionTypes.UPDATE_CONSTRAINT
  constructor (public payload: ConstraintDTO) {}
}

export class DeleteConstraint implements FluxStandardAction {
  type = 'Constraint/' + ActionTypes.DELETE_CONSTRAINT
  constructor (public guid: string) {}
}
