import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ProjectTreeElementCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/ProjectTreeElementCommandRepositoryInterface'
import ProjectTreeElementModelCreateCommand from '@/services/DigitalTwinEditor/application/command/ProjectTreeElementModelCreateCommand'
import { ModelDTO } from '@/services/DigitalTwinEditor/domain/model/Model/Model'

export default class ProjectTreeElementModelCreateHandler implements CommandHandlerInterface
{
    private repo: ProjectTreeElementCommandRepositoryInterface;

    constructor(repo: ProjectTreeElementCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: ProjectTreeElementModelCreateCommand): Promise<void>
    {
        const item: ModelDTO = {
            id: command.getModelId(),
            parent_id: command.getParentId()
        };
        return this.repo.putModel(item);
    }
}