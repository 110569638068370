import Vue from 'vue'
export default Vue.extend({
  name: 'booleanFloatingFilter',
  template: `
    <el-row style="display: flex;align-items: center;justify-content: center; width: 100%;">
      <select style="width: 100%;" v-model="value" placeholder="Выбрать" @change="valueChanged($event)">
        <option
          v-for="item in types"
          :key="item.type"
          :label="item.name"
          :value="item.type"
        ></option>
    </select>
  </el-row>
    `,
  data: function () {
    return {
      value: null,
      types: [
        {
          type: '1',
          name: 'Да'
        },
        {
          type: '0',
          name: 'Нет'
        },
        {
          type: null,
          name: 'Без фильтра'
        }
      ]
    }
  },
  methods: {
    valueChanged (event) {
      this.params.parentFilterInstance(function (instance) {
        instance.onFloatingFilterChanged(event)
      })
    },
    onParentModelChanged (parentModel) {
      this.value = !parentModel ? null : parentModel.value
    }
  }
})
