import axios from 'axios'

export default {
  namespaced: true,
  state: {
    tabs: [
      {
        title: '',
        icon: 'el-icon-s-home',
        component: 'WelcomePage',
        payload: {},
        guid: 'welcome-page'
      }
    ]
  },
  mutations: {
    /* Добавить tabs */
    addTab (state, payload) {
      state.tabs.push(payload)
    },
    closeTab (state, guid) {
      state.tabs = state.tabs.filter((tab) => {
        return tab.guid !== guid
      })
    }
  },
  getters: {
    getTabs (state) {
      return state.tabs
    },
    getCount (state) {
      return state.tabs.length
    }
  }
}
