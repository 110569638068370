import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    MODEL_FILE_LOADING_EVENT = 'model_file_loading_event',
    MODEL_FILE_CREATED_EVENT = 'model_file_created_event',
    MODEL_FILE_DELETED_EVENT = 'model_file_deleted_event',
    MODEL_FILE_UPDATED_EVENT = 'model_file_updated_event',
}

export class ModelFileLoadingEvent implements FluxStandardAction {
    type = MutationTypes.MODEL_FILE_LOADING_EVENT
}

export class ModelFileCreatedEvent implements FluxStandardAction {
    type = MutationTypes.MODEL_FILE_CREATED_EVENT
    constructor(public location: string){}
}

export class ModelFileDeletedEvent implements FluxStandardAction {
    type = MutationTypes.MODEL_FILE_DELETED_EVENT
    constructor(public guid: string){}
}

export class ModelFileUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.MODEL_FILE_UPDATED_EVENT
}
