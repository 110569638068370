import { Nullable } from '@/core/domain/type/types'

export default class ModelTreeElementGroupCreateCommand
{
    private name: string;
    private alias: Nullable<string>;

    private parentId: Nullable<number>;

    constructor(name: string,  alias: Nullable<string>, parentId: Nullable<number>)
    {
        this.name = name;
        this.alias = alias;
        this.parentId = parentId;
    }

    getClassName(): string
    {
        return 'ModelTreeElementGroupCreateCommand';
    }

    getName(): string
    {
        return this.name;
    }

    getAlias(): Nullable<string>
    {
        return this.alias;
    }

    getParentId(): Nullable<number>
    {
        return this.parentId;
    }
}