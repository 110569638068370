<template>
  <el-upload
    ref="importUpload"
    action="#"
    :headers="getHeaders()"
    :limit="1"
    :multiple="false"
    :auto-upload="false"
    :show-message="false"
    :on-change="test"
    class="etl-upload">
    <el-button slot="trigger" type="primary">{{$locale.registry.toolbar.btn_select}}</el-button>
  </el-upload>
</template>

<script>
export default {
  name: 'etl-import',

  methods: {
    getHeaders () {
      return {
        Authorization: localStorage.getItem('user-token')
      }
    },
    test(file) {
      this.$emit('uploaded', file)
    }
  }
}
</script>

<style scoped>
.label {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #7D7D7D;
  display: block;
  padding: 5px 5px 0px 5px;
}
</style>
