import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import Rule from '@/services/TaskEditor/domain/model/Rule'

export namespace RuleAPI {
  export class GetRuleByGuid implements APIRequest<Rule> {
    response: Rule;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => new Rule(data.data)
    constructor (guid: string) {
      this.path = `/taskeditor/rules/${guid}`
    }
  }

  export class GetRuleFieldTypes implements APIRequest<any> {
    response: any;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor () {
      this.path = `/taskeditor/rule_field_types`
    }
  }

  export class CreateRule implements APIRequest<string> {
    response: string;
    path = '/taskeditor/rules';
    method = HTTPMethod.POST;
    parse = (data: AxiosResponse) => data.headers.location
    constructor (public params: Rule) {}
  }

  export class UpdateRule implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.PUT;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: Rule) {
      this.path = `/taskeditor/rules/${params.guid}`
    }
  }

  export class DeleteRule implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.DELETE;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/taskeditor/rules/${guid}`
    }
  }
}
