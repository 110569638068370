<script type="ts">
import { Stack, StackItem } from 'vue-stack-grid'
import ElImage from 'element-ui/packages/image/src/main.vue'
import ElButton from 'element-ui/packages/button/src/button.vue'
import Pattern from '@bingo_soft/mapmanager/src/Infrastructure/Util/Pattern'

export default {
    name: 'FillPatternSet',
    props: {        
        showControls: {
            type: Boolean,
            default: true
        },
        value: {
            type: String,
            default: "empty"
        }
    },
    components: {
        Stack, StackItem 
    },
    data() {
        return {
            items: Pattern.getDefaultFillPatterns(),
            fillValue: null
        };
    },
    watch: {
        fillValue(val) {
            this.$emit('change', val);
        }
    },
    methods: {
        renderFillStack() {
            if (this.items.size) {
                return this.$createElement(Stack, {
                    props: {
                        columnMinWidth: 62,
                        gutterWidth: 8,
                        gutterHeight: 8
                    },
                    ref: "fillSet"
                },[
                    ...this.renderFillItems()
                ]);
            }
            return null;
        },
        renderFillItems() {
            let components = [];     
            this.items.forEach((value, key) => {
                components.push(
                    this.$createElement("span", {}, [
                        this.$createElement(StackItem, {
                            props: {},
                            class: {
                                "selected": key == this.fillValue
                            }
                        },[
                            this.$createElement("div",
                                {
                                    on: {
                                        click: (el) => {
                                            this.fillValue = key;
                                        }
                                    },
                                    style: {
                                        "width": "60px",
                                        "height": "60px",
                                        "background-image": `url(${value})`,
                                        "background-repeat": "repeat"
                                    }
                                },[
                                
                            ])
                        ])
                    ])
                );
            });
            return components;            
        }
    },
    render (createElement) {
        if (this.value) {
            this.fillValue = this.value;
        }
        return createElement("div", {
            style: {
                "display": "flex",
                "overflow": "hidden"
            }
        },[
            this.$createElement("div", {
                class: {
                    "polygon-fill-set": true,
                    "custom_scrollbar": true
                },
                style: {
                    "flex": "100%"
                }
            },[
                this.renderFillStack()
            ])
        ]);
    }
};
</script>
