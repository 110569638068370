import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import SourceCommandRepositoryInterface from '@/services/MapEditor/domain/repository/SourceCommandRepositoryInterface'
import SourceUpdateCommand from '@/services/MapEditor/application/command/SourceUpdateCommand'
import { SourceDTO } from '@/services/MapEditor/domain/model/Source/Source'

export default class SourceUpdateHandler implements CommandHandlerInterface
{
    private repo: SourceCommandRepositoryInterface;

    constructor(repo: SourceCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: SourceUpdateCommand): Promise<void>
    {
        let item: SourceDTO = {
            guid: command.getGuid(),
            name: command.getName(),
            type: command.getType(),
            properties: command.getProperties()
        };
        return this.repo.update(item);
    }
}