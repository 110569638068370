import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'
import NameTooLong from '@/core/domain/exception/NameTooLong'
import NameMustNotBeEmpty from '@/core/domain/exception/NameMustNotBeEmpty'
import SourceType from './SourceType'

export class SourceDTO
{
    id?: Nullable<number>;
    guid?: Nullable<string>;
    name?: string;
    type?: string;
    source_type_id?: string;
    properties?: object = [];
    project_id?: number;

    constructor({ id, guid, name, type, properties = [], project_id}: {id?: Nullable<number>, guid?: Nullable<string>, name?: string, type?: string, properties?: object, project_id?: number}) {
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.type = type;
        this.source_type_id = type;
        this.properties = properties;
        this.project_id = project_id;
    }
}


export default class Source extends AbstractBaseEntity
{
    private id?: Nullable<number>;
    private guid?: Nullable<string>;
    private name: string;
    private type: SourceType;
    private properties: object;

    constructor(
        id: Nullable<number>,
        guid: Nullable<string>,
        name: string,
        type: SourceType,
        properties: object = {}
    ) {
        super();
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.type = type;
        this.properties = properties;
        this.assertInvariants();
    }

    static create(dto: SourceDTO): Source
    {
        let type = dto.type || dto.source_type_id;
        return new Source(
            dto.id,
            dto.guid,
            dto.name,
            SourceType[type.toUpperCase()],
            dto.properties
        );
    }

    setId(id: number): void
    {
        this.id = id;
    }

    getId(): number
    {
        return this.id;
    }

    setGuid(guid: string): void
    {
        this.guid = guid;
    }

    getGuid(): string
    {
        return this.guid;
    }

    setName(name: string): void
    {
        if (name.length > 255) {
            throw new NameTooLong();
        }
        this.name = name;
    }

    getName(): string
    {
        return this.name;
    }

    assertInvariants(): void
    {
        if (this.name.length == 0) {
            throw new NameMustNotBeEmpty();
        }

        if (this.name.length > 255) {
            throw new NameTooLong();
        }
    }

    setType(type: SourceType): void
    {
        this.type = type;
    }

    getType(): SourceType
    {
        return this.type;
    }

    setProperties(properties: object): void
    {
        this.properties = properties;
    }

    getProperties(): object
    {
        return this.properties;
    }
}
