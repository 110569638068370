import { FluxStandardAction, Nullable } from '@/core/domain/type/types'

export enum ActionTypes {
    UPDATE_FEATURE = 'update_feature'
}

export class UpdateFeature implements FluxStandardAction {
    type = 'Feature/' + ActionTypes.UPDATE_FEATURE
    constructor(public payload: { [key: string]: string; }){}
}
