<template>
  <el-form label-width="120px" label-position="top" size="mini" class="editor_approval">
    <el-form-item>
      <el-form-item label="Простая">
        <el-switch v-model="value.isSimple"></el-switch>
      </el-form-item>
      <el-form-item v-if="!value.isSimple" label="Вертикальное направление">
        <el-switch v-model="value.directionVertical"></el-switch>
      </el-form-item>
      <el-form-item>
        <span class="property_label">{{ $locale.interface_editor.component_editor.table.source_type }}</span>
        <el-select
          :value="value.type"
          value-key="id"
          filterable
          @change="$set(value ,'type', $event )"
        >
          <el-option
            v-for="(item, index) in $locale.interface_editor.component_editor.editor_stages.source_type"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form-item>
    <el-form-item v-show="value.type === 'approval'">
      <div v-if="logicRec">
        <el-button @click="openLogicEditor" type="text">{{ $locale.main.button.open_logic_editor }}</el-button>
        <span class="property_label">Маршрут согласования</span>
        <editor-select
          :value="value.approvalId"
          @change="$set(value ,'approvalId', $event)"
          :options="{
            multiple: false,
            options: approvals
          }"
        ></editor-select>
      </div>
      <div v-else>
        <span class="property_label">Отсутствует связанная запись в редакторе бизнес логики!</span>
      </div>
    </el-form-item>
    <el-form-item v-if="value.type === 'registry'">
      <span class="property_label">{{ $locale.interface_editor.component_editor.table.registry }}</span>
      <editor-registry-select :value="value.registryId"
                              @change="$set(value ,'registryId', $event)"></editor-registry-select>
    </el-form-item>
    <el-form-item v-if="value.type === 'extend_object'">
      <span class="property_label">{{ $locale.interface_editor.component_editor.table.extended_object }}</span>
      <editor-select
        :value="value.extendObject"
        @change="$set(value ,'extendObject', $event)"
        :options="{multiple: false, options: extendObjects}"
      ></editor-select>
    </el-form-item>
    <template v-if="value.type === 'registry' || value.type === 'extend_object'">
      <el-form-item>
        <span class="property_label">{{ $locale.interface_editor.component_editor.editor_stages.title }}</span>
        <selectTree class="extraLine_select" :clearable="false" :standartTree='false' :dataTable="listField"
                    v-model="value.title"/>
      </el-form-item>
      <el-form-item>
        <span class="property_label">{{ $locale.interface_editor.component_editor.editor_stages.content }}</span>
        <selectTree class="extraLine_select" :clearable="false" :standartTree='false' :dataTable="listField"
                    v-model="value.content"/>
      </el-form-item>
    </template>
    <template v-if="value.type === 'request'">
      <el-form-item>
        <span class="property_label">{{ $locale.interface_editor.component_editor.table.request }}</span>
        <editor-select
          :value="value.requestsId"
          @change="$set(value ,'requestsId', $event)"
          :options="{
            multiple: false,
            options: requests
          }"
        ></editor-select>
      </el-form-item>
      <el-form-item>
        <span class="property_label">{{ $locale.interface_editor.component_editor.editor_stages.title }}</span>
        <el-input type="text" size="small" v-model="value.title"></el-input>
      </el-form-item>
      <el-form-item>
        <span class="property_label">{{ $locale.interface_editor.component_editor.editor_stages.content }}</span>
        <el-input type="text" v-model="value.description"></el-input>
      </el-form-item>
      <editor-open-card v-bind:value="value" v-bind:isTreeSelect="true" v-bind:query="true"></editor-open-card>
      <el-form-item>
        <span class="property_label">{{ $locale.logic_editor.type }}</span>
        <el-select
          :value="value.card.type"
          value-key="id"
          filterable
          @change="$set(value.card ,'type', $event )"
        >
          <el-option
            v-for="(item, index) in $locale.interface_editor.component_editor.editor_stages.open_card_types"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </template>
    <el-form-item>
      <span class="property_label">{{ $locale.interface_editor.component_editor.editor_stages.space }}</span>
      <el-input type="text" size="small" v-model="value.space"></el-input>
    </el-form-item>
    <editor-status-stages v-bind:statuses="value.statuses" v-on:changeStatuses="changeStatuses"></editor-status-stages>
  </el-form>
</template>

<script>
import EditorSelect from './editor-select'
import EditorStatusStages from './editor-status-stages'
import EditorOpenCard from './editor-open-card'
import EditorRegistrySelect from './editor-registry-select'
import Registry from '@/components/Models/EntityModel'
import SelectTree from '@/core/infrastructure/components/TreeSelect'

export default {
  name: 'editor-stages',
  inject: {
    addMainTab: {
      default: () => {
      }
    },
    getRegistryId: {
      default: () => {
      }
    }
  },
  props: {
    value: {
      type: Object
    }
  },
  components: {
    SelectTree,
    EditorSelect,
    EditorOpenCard,
    EditorRegistrySelect,
    EditorStatusStages
  },
  data () {
    return {
      logicRec: null,
      listField: [],
      requests: [],
      approvals: [],
      extendObjects: [],
      dialogSettingsVisible: false
    }
  },
  watch: {
    'value.isSimple': {
      async handler (val) {
        if (val) {
          this.value.directionVertical = false
        }
      },
      immediate: true
    },
    'value.registryId': {
      async handler (id) {
        if (id) {
          let registryTree = await Registry.params({ parent_id: id, show_children: true }).get()
          this.listField = registryTree.data
        }
      },
      immediate: true
    },
    'value.extendObject': {
      async handler (id) {
        if (id) {
          this.listField = await this.getExtendObjects(id)
        }
      },
      immediate: true
    },
    'value.type': {
      async handler (val) {
        this.$set(this.value, 'extendObject', null)
        this.$set(this.value, 'registryId', null)
        this.$set(this.value, 'requestsId', null)
        this.value.title = this.value.content = null
        this.listField = []
        this.requests = []
        if (val === 'request') {
          this.requests = await this.getRequests()
        }
        if (val === 'extend_object') {
          this.extendObjects = await this.getExtendObjects()
        }
        if (val === 'approval' && this.logicRec) {
          this.extendObjects = await this.getApprovals()
        }
      }
    },
    value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    }
  },
  async mounted () {
    this.logicRec = await this.getLogicRec()
    this.requests = await this.getRequests()
    this.extendObjects = await this.getExtendObjects()
    if (this.logicRec) {
      this.approvals = await this.getApprovals()
    }
  },
  methods: {
    changeStatuses (statuses) {
      this.$set(this.value, 'statuses', statuses)
    },
    openLogicEditor () {
      let params = {
        name: 'Редактор бизнес-логики',
        componentType: 'LogicEditor_v2'
      }
      if (this.logicRec.registry_id) {
        params.payload = { registryId: this.logicRec.registry_id }
      }
      this.addMainTab(params)
    },
    async getLogicRec () {
      let response = await this.$http.get(`${this.$config.api}/v2/logiceditor/logic?entity_id=${this.getRegistryId()}`)
      return response.data[0]
    },
    async getApprovals () {
      let res = await this.$http.get(`${this.$config.api}/v2/logiceditor/approvals?logic_id=${this.logicRec.id}`)
      return res.data
    },
    async getRequests () {
      let response = await this.$http.get(`${this.$config.api}/bieditor/queries`)
      return response.data
    },
    async getExtendObjects (id = null) {
      if (id) {
        let listField = await this.$http.get(`${this.$config.api}/bieditor/extended_object_fields?extended_object_id=${id}`)
        return listField.data.map((el) => ({ id: el.id, name: el.name }))
      }
      let extendObjects = await this.$http.get(`${this.$config.api}/bieditor/extended_objects`)
      return extendObjects.data
    }
  }
}
</script>

<style lang="scss">
  .editor_calendar .el-form-item__label {
    padding: 0px;
  }

  .editor_calendar .el-form-item {
    margin-bottom: 10px;
  }

  .editor_calendar .checkbox .el-form-item__content {
    display: inline-block;
    margin-left: 10px;
  }
</style>
