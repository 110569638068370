import { MutationTree } from 'vuex'
import { RowFormulaState, initialState } from './state'
import { MutationTypes, RowFormulaCreatedEvent, RowFormulaUpdatedEvent, RowFormulaDeletedEvent } from './mutation-types'

export const mutations: MutationTree<RowFormulaState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false
    state.error = error
  },
  [MutationTypes.ROW_FORMULA_LOADING_EVENT]: (state) => {
    state.isLoading = true
  },
  [MutationTypes.ROW_FORMULA_LOADED_EVENT]: (state) => {
    state.isLoading = false
  },
  [MutationTypes.ROW_FORMULA_CREATED_EVENT]: (state, action: RowFormulaCreatedEvent) => {
    state.isLoading = false
    state.location = action.location
  },
  [MutationTypes.ROW_FORMULA_UPDATED_EVENT]: (state, action: RowFormulaUpdatedEvent) => {
    state.isLoading = false
  },
  [MutationTypes.ROW_FORMULA_DELETED_EVENT]: (state, action: RowFormulaDeletedEvent) => {
    state.isLoading = false
    state.deletedRowFormulaGuid = action.guid
  }
}
