<template>
    <div style="height: 100%;" class="custom_scrollbar interfaceViewer" :style="stylesTabs">
      <component :is="'style'" v-if="CSS">
        {{ CSS }}
      </component>
        <el-tooltip v-if="isConstructorMode" class="item" effect="dark" :content="contentMessageForConstructorMode" placement="left">
          <i class="el-icon-s-tools constructor" @click="openInEditor"></i>
        </el-tooltip>
        <el-tabs :tab-position="tabPosition" @tab-click="clickTab" class="tabs customTab_card wrapper_flex" v-model="activeTabGuid" v-if="isUseTabs" v-loading="loading"
            :style="stylesActiveTabs" :class="userClass">
          <el-tab-pane
            class="tabPaine"
            v-for="tab in computedTabs.filter((item) => !item.isHide && (!item.parentGuid || (computedTabs.find(t => t.guid === item.parentGuid) || {}).expanded))"
            :key="tab.guid"
            :name="tab.guid"
            :disabled="tab.isBlocked">
            <span
              class="custom_text"
              slot="label"
              :class="tab.isCss ? tab.cssStyleTab.icon : ''"
              :style="(tab.isCss ? tab.cssStyleTab.css : '')">
              <i :class="{'el-icon-plus': !tab.expanded, 'el-icon-minus': tab.expanded}" v-if="computedTabs.find(item => item.parentGuid === tab.guid)" @click="showChildTabs(tab)"></i>
              {{tab.label}}
            </span>
            <div class="custom_scrollbar" style="height: 100%; overflow: auto">
              <layout
                :layout-data.sync="layout[tab.guid]"
                :containers="getContainersFromTab(tab.guid)"
                :components="components"
                :model="model"
                :ref="`layout_${tab.guid}`"
                :is-active="(activeTabGuid === tab.guid) || loadedTabs.includes(tab.guid)"
                :registry-id="registryId"
                :msgbox="msgbox"
              ></layout>
            </div>
          </el-tab-pane>
        </el-tabs>
      <div v-else style="height: 100%;overflow: auto">
        <layout
          :layout-data.sync="layout.null"
          :containers="getContainersFromTab()"
          :components="components"
          :model="model"
          :ref="`layout`"
          :is-active="true"
          :registry-id="registryId"
          :msgbox="msgbox"
        ></layout>
      </div>
      </div>
</template>

<script>
import Layout from './layout/index.vue'
import conditionsMixin from '@/components/InterfaceEditor/components/conditions_mixin'
import Dashboard from '@/components/DashboardEditor/Models/Dashboard'

export default {
  mixins: [conditionsMixin],
  components: {
    Layout
  },
  name: 'InterfaceViewer',
  props: {
    model: {
      type: Object,
      default () { return {} }
    },
    'registry-record-id': {},
    'registry-id': {},
    'msgbox': {},
    dashboardId: {
      type: Number
    },
    registryCard: {
      type: Object
    }
  },
  data () {
    return {
      containers: [],
      components: [],
      tabs: [],
      isUseTabs: false,
      tabPosition: 'top',
      activeTabGuid: null,
      loadedTabs: [],
      loading: true,
      background: {
        urlImage: null,
        fitImage: null,
        bgColor: null
      },
      CSS: null,
      userClass: null
    }
  },
  inject: {
    getParentDashboard: {
      default: () => {}
    },
    addMainTab: {
      default: () => {}
    },
    addMainTabInWindow: {
      default: () => {}
    }
  },
  mounted () {
    /* Пробросить функцию получения компонентов дашборда в этот же дашборд */
    if (typeof this.getParentDashboard === 'function' && this.getParentDashboard()) { this.getParentDashboard().getDashboardComponents = this.getDashboardComponents }
  },
  watch: {
    computedTabs: {
      handler (value) {
        let activeTab = value.find((item) => item.guid === this.activeTabGuid)

        if (activeTab.isHide === true) {
          this.activeTabGuid = this.computedTabs.filter((item) => !item.isHide)[0].guid
        } else if (activeTab.isBlocked === true) {
          let index = value.filter(item => (!item.isHide && !item.isBlocked) || item.guid === this.activeTabGuid).indexOf(activeTab)
          let activeTabIndex = 0
          if ((index + 1) <= (value.filter(item => (!item.isHide && !item.isBlocked) || item.guid === this.activeTabGuid).length - 1)) {
            activeTabIndex = index + 1
          }
          this.activeTabGuid = value.filter(item => (!item.isHide && !item.isBlocked) || item.guid === this.activeTabGuid)[activeTabIndex].guid
        }
      },
      immediate: false
    }
  },
  provide () {
    return {
      getModel: this.getModel,
      getDashboardComponents: this.getDashboardComponents,
      getComponents: this.getComponentsAsArray,
      isEditor: function () {
        return false
      }
    }
  },
  computed: {
    computedTabs () {
      return this.tabs.map(item => {
        return Object.assign(item,
          { isHide: this.checkConditions(item.hideTabs) },
          { isCss: this.checkConditions(item.cssTabs) },
          { isBlocked: this.checkConditions(item.blockedTabs) }
        )
      })
    },
    layout () {
      let layout = { null: [] }
      this.tabs.forEach((tab) => {
        if (!layout[tab.guid]) {
          layout[tab.guid] = []
        }
      })
      this.containers.forEach((container) => {
        if (!layout[container.tabGuid]) {
          layout[container.tabGuid] = []
        }
        layout[container.tabGuid].push(container)
      })

      return layout
    },
    activeTab () {
      let me = this
      return this.tabs.find((tab) => {
        return tab.guid === me.activeTabGuid
      }) || {}
    },
    stylesTabs () {
      let style = {}
      if (!this.isUseTabs && this.background.urlImage) {
        if (this.background.urlImage.startsWith('http')) {
          style['background-image'] = `url(${this.background.urlImage})`
        } else {
          style['background-image'] = `url(${this.$config.api}${this.background.urlImage})`
        }
        style['background-size'] = this.background.fitImage
      }
      if (!this.isUseTabs && this.background.bgColor) {
        style['background-color'] = this.background.bgColor
      }

      return style
    },
    stylesActiveTabs () {
      let style = {}
      if (this.activeTab.urlImage) {
        if (this.activeTab.urlImage.startsWith('http')) {
          style['background-image'] = `url(${this.activeTab.urlImage})`
        } else {
          style['background-image'] = `url(${this.$config.api}${this.activeTab.urlImage})`
        }
        style['background-size'] = this.activeTab.fitImage
      }
      if (this.activeTab.bgColor) {
        style['background-color'] = this.activeTab.bgColor
      }

      return style
    },
    isConstructorMode () {
      return this.$store.getters['ConstructorMode/getConstructorMode']
    },
    contentMessageForConstructorMode () {
      if (this.dashboardId) {
        return `Открыть дашборд ID: ${this.dashboardId}`
      } else {
        return `
        Открыть карточку: Реестр ID: ${this.registryCard.registryId},
        Карточка ID: ${this.registryCard.cardId}
        Запись ID: ${this.registryCard.recordId}
        `
      }
    }
  },
  methods: {
    showChildTabs (tab) {
      this.$set(tab, 'expanded', !tab.expanded)
    },
    clickTab (tab) {
      if (!this.loadedTabs.includes(tab.name)) {
        this.loadedTabs.push(tab.name)
      }
    },
    getModel () {
      return this.model
    },
    getDashboardComponents () {
      let result = {}
      Object.values(this.$refs).forEach((layout) => {
        if (layout) {
          let issetLayout = (Array.isArray(layout) ? layout[0] : layout)
          if (issetLayout) {
            result = { ...result, ...(Array.isArray(layout) ? layout[0] : layout).getLayoutComponents() }
          }
        }
      })

      return result
    },
    getComponentsAsArray () {
      let answer = []
      for (let key in this.components) {
        if (this.components.hasOwnProperty(key)) {
          answer.push(Object.assign(this.components[key], {
            name: `${this.components[key].group}/${this.components[key].initialType}`
          }))
        }
      }

      return answer
    },
    getContainersFromTab (guid = null) {
      return this.containers.filter((item) => {
        return item.tabGuid === guid
      })
    },
    async loadState (json) {
      this.components = []
      if (!json) {
        json = { components: [], containers: [], tabs: [] }
      }

      if (json.tabs && json.tabs.length > 0) {
        this.tabs = JSON.parse(JSON.stringify(json.tabs))
        this.isUseTabs = true
        this.tabPosition = json.tabPosition || 'top'
        this.activeTabGuid = this.computedTabs.filter((item) => !item.isHide)[0].guid
        this.loadedTabs.push(this.activeTabGuid)
      }
      this.CSS = json.CSS
      this.userClass = json.userClass
      this.containers = JSON.parse(JSON.stringify(json.containers))
      this.background.urlImage = json.urlImage
      this.background.fitImage = json.fitImage
      this.background.bgColor = json.bgColor
      const promises = json.components.map(this.loadComponent)
      await Promise.all(promises)
      /* json.components.forEach(item => {
        // this.loadComponent(item.initialType)
        //  .then((res) => {
        //    console.log(res)
        this.components.push({
          type: item.type,
          name: item.name,
          guid: item.guid,
          orderId: item.orderId,
          initialType: item.initialType,
          containerGuid: item.containerGuid,
          properties: item.properties
        })
        // })
        // .catch(() => {
        //    console.log('error load ' + item.name)
        //  })
      }) */
      this.loading = false
    },
    async loadComponent (item) {
      if (!item.initialType) {
        return null
      }
      let component = () => import(`@/components/InterfaceEditor/components/${item.initialType}.vue`)
      this.components.push({
        type: component,
        name: item.name,
        guid: item.guid,
        orderId: item.orderId,
        initialType: item.initialType,
        containerGuid: item.containerGuid,
        properties: item.properties
      })
    },
    async getDashboard () {
      let dashboard = await new Dashboard().find(this.dashboardId)
      return dashboard
    },
    async getCardId (registryId, recordId = null) {
      let url = `${this.$config.api}/registryservice/registry/${registryId}/card`
      if (recordId) {
        url = `${this.$config.api}/registryservice/registry/${registryId}/records/${recordId}/card`
      }
      let data = await this.$http.get(url)

      return data.data[0]
    },
    async openInEditor () {
      if (this.dashboardId) {
        let { name } = await this.getDashboard()
        this.addMainTab({ name, componentType: 'DashboardEditorViewer', payload: { dashboardId: this.dashboardId } })
      } else {
        let { name } = await this.getCardId(this.registryCard.registryId, this.registryCard.recordId)
        if (typeof this.addMainTabInWindow === 'function') {
          this.addMainTabInWindow({
            name: name,
            componentType: 'CardEditor',
            payload: {
              registry_id: this.registryCard.registryId,
              card_id: this.registryCard.cardId
            }
          })
          return
        }
        this.addMainTab({
          name: name,
          componentType: 'CardEditor',
          payload: {
            registry_id: this.registryCard.registryId,
            card_id: this.registryCard.cardId
          }
        })
      }
    }
  }
}
</script>

<style>
  .interfaceViewer .tabPaine {
    height: 100%;
  }
  .interfaceViewer .tabs {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .interfaceViewer .tabs.el-tabs.el-tabs--left,
  .interfaceViewer .tabs.el-tabs.el-tabs--right {
    display: block;
  }
  .interfaceViewer .tabs .el-tabs__header .is-top .el-tabs__nav-scroll{
    padding-left: 10px;
  }
   .interfaceViewer .custom_text:before {
    font-family: 'element-icons'!important;
  }
  .interfaceViewer .custom_text {
    font-family: 'Roboto', sans-serif !important;
  }
  .constructor {
    position: absolute;
    right: 10px;
    top: 4px;
    z-index: 666;
    font-size: 25px;
    background: #fff;
    color: #898383;
    border-radius: 11%;
    padding: 2px;
    cursor: pointer;
}
</style>
