export default class StructureQuery {
  private readonly registryId: number;

  constructor (registryId: number) {
    this.registryId = registryId
  }

  getRegistryId (): number {
    return this.registryId
  }

  getClassName (): string {
    return 'StructureQuery'
  }
}
