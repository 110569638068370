import { FluxStandardAction } from '@/core/domain/type/types'
import { FormulaDTO } from '@/services/LogicEditor/domain/model/Formula'

export enum ActionTypes {
  GET_FORMULA = 'get_formula',
  GET_FORMULA_BY_GUID = 'get_formula_by_guid',
  CREATE_FORMULA = 'create_formula',
  UPDATE_FORMULA = 'update_formula',
  DELETE_FORMULA = 'delete_formula'
}

export class GetFormulaByGuid implements FluxStandardAction {
  type = 'Formula/' + ActionTypes.GET_FORMULA_BY_GUID
  constructor (public guid: string) {}
}

export class CreateFormula implements FluxStandardAction {
  type = 'Formula/' + ActionTypes.CREATE_FORMULA
  constructor (public payload: FormulaDTO) {}
}

export class UpdateFormula implements FluxStandardAction {
  type = 'Formula/' + ActionTypes.UPDATE_FORMULA
  constructor (public payload: FormulaDTO) {}
}

export class DeleteFormula implements FluxStandardAction {
  type = 'Formula/' + ActionTypes.DELETE_FORMULA
  constructor (public guid: string) {}
}
