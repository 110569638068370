import { FluxStandardAction } from '@/core/domain/type/types'
import { LayerGroupDTO } from '@/services/MapEditor/domain/model/Layer/LayerGroup'

export enum MutationTypes {
    ERROR = 'error',
    LAYER_GROUP_LOADING_EVENT = 'layer_group_loading_event',
    LAYER_GROUP_LOADED_EVENT = 'layer_group_loaded_event',
    LAYER_GROUP_CREATED_EVENT = 'layer_group_created_event',
    LAYER_GROUP_UPDATED_EVENT = 'layer_group_updated_event' 
}

export class LayerGroupLoadingEvent implements FluxStandardAction {
    type = MutationTypes.LAYER_GROUP_LOADING_EVENT
}

export class LayerGroupLoadedEvent implements FluxStandardAction {
    type = MutationTypes.LAYER_GROUP_LOADED_EVENT
}

export class LayerGroupCreatedEvent implements FluxStandardAction {
    type = MutationTypes.LAYER_GROUP_CREATED_EVENT
    constructor(public location: string){}
}

export class LayerGroupUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.LAYER_GROUP_UPDATED_EVENT
}