<template>
  <div>
    <el-form>
      <el-form-item label="Включить привязку к вершинам">
        <el-checkbox v-model="value.properties.snap.enable"></el-checkbox>
      </el-form-item>
      <el-form-item label="Радиус" v-if="value.properties.snap.enable">
        <el-input v-model="value.properties.snap.radius"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  export default {
    name: 'BindingRadius',
    props: {
      value: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    mounted() {

      if (!this.value.properties) {
        this.$set(this.value, 'properties', { })
      }
      if (!this.value.properties.snap) {
        this.$set(this.value.properties, 'snap', { enable: false, radius: 0 })
      }
      if (this.value.properties.snap.enable === undefined) {
        this.updateValue('enable', false)
      }
      if (this.value.properties.snap.radius === undefined) {
        this.updateValue('radius', 0)
      }
    },
    methods: {
      updateValue(itemName, item) {
        this.$set(this.value.properties.snap, itemName, item)
      }
    }
  }
</script>

<style scoped>

</style>
