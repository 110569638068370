import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { ProjectGroupDTO } from '@/services/DigitalTwinEditor/domain/model/Project/ProjectGroup'

export enum ActionTypes {
    UPDATE_PROJECT_GROUP = 'update_project_group',
    GET_PROJECT_GROUP_BY_GUID = 'get_project_group_by_guid'
}

export class UpdateProjectGroup implements FluxStandardAction {
    type = 'DigitalTwinProjectGroup/' + ActionTypes.UPDATE_PROJECT_GROUP
    constructor(public payload: ProjectGroupDTO){}
}

export class GetProjectGroupByGuid implements FluxStandardAction {
    type = 'DigitalTwinProjectGroup/' + ActionTypes.GET_PROJECT_GROUP_BY_GUID
    constructor(public guid: string){}
}
