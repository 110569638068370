import { MutationTree } from 'vuex'
import { LayerState, initialState } from './state'
import { MutationTypes, LayerCreatedEvent, LayerUpdatedEvent, LayerDeletedEvent } from './mutation-types'

export const mutations: MutationTree<LayerState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false;
    state.error = error;    
  },
  [MutationTypes.LAYER_LOADING_EVENT]: (state) => {
    state.isLoading = true;
  },
  [MutationTypes.LAYER_LOADED_EVENT]: (state) => {
    state.isLoading = false;
  },
  [MutationTypes.LAYER_CREATED_EVENT]: (state, action: LayerCreatedEvent) => {
    state.location = action.location;
    state.isLoading = false;
  },
  [MutationTypes.LAYER_UPDATED_EVENT]: (state, action: LayerUpdatedEvent) => {
    state.isLoading = false;
  },
  [MutationTypes.LAYER_DELETED_EVENT]: (state, action: LayerDeletedEvent) => {
    state.isLoading = false;
  }
}