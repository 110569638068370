import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import EventQueryRepositoryInterface from '../../../domain/repository/EventQueryRepositoryInterface'
import EventsCountQuery from '../../query/EventsCountQuery'

export default class EventsCountHandler implements QueryHandlerInterface
{
    private repo: EventQueryRepositoryInterface;

    constructor(repo: EventQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: EventsCountQuery): Promise<any>
    {
        return this.repo.getCount(query.getPayload());
    }
}