import { FluxStandardAction, Nullable } from '@/core/domain/type/types'

export enum ActionTypes {
  GET_RULE_TREE_ELEMENTS = 'get_rule_tree_elements',
  GET_RULE_TREE_ELEMENT_BY_GUID = 'get_rule_tree_element_by_guid',
  GET_RULE_TREE_ELEMENT_BY_ELEMENT_GUID = 'get_rule_tree_element_by_element_guid'
}

export class GetRuleTreeElements implements FluxStandardAction {
  type = 'RuleTreeElement/' + ActionTypes.GET_RULE_TREE_ELEMENTS
  constructor (public payload: object) {}
}

export class GetRuleTreeElementByGuid implements FluxStandardAction {
  type = 'RuleTreeElement/' + ActionTypes.GET_RULE_TREE_ELEMENT_BY_GUID
  constructor (public guid: string) {}
}

export class GetRuleTreeElementByElementGuid implements FluxStandardAction {
  type = 'RuleTreeElement/' + ActionTypes.GET_RULE_TREE_ELEMENT_BY_ELEMENT_GUID
  constructor (public elementGuid: string) {}
}
