import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import { GetAddresses } from '@/services/AddressService/infrastructure/store/modules/Address/action-types'
import AddressQueryRepositoryInterface
  from '@/services/AddressService/domain/repository/AddressQueryRepositoryInterface'

export default class AddressQueryRepository extends AbstractQueryRepository implements AddressQueryRepositoryInterface {
  get (payload: string[][]): Promise<any> {
    return store.dispatch(new GetAddresses(payload))
  }
}
