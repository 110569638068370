import FileCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/FileCommandRepositoryInterface'
import { ModelFileDTO, TransformFileDTO } from '@/services/DigitalTwinEditor/domain/model/File/ModelFile'
import store from '@/store'
import { AttachFileToModel, DeleteModelFile, TransformFile } from '@/services/DigitalTwinEditor/infrastructure/store/modules/ModelFile/action-types'

export default class FileCommandRepository implements FileCommandRepositoryInterface
{
    insert(item: ModelFileDTO): Promise<void>
    {
        return store.dispatch(new AttachFileToModel(item));
    }

    delete(guid: string, payload: string[][]): Promise<void>
    {
        return store.dispatch(new DeleteModelFile(guid, payload));
    }

    transformFile(item: TransformFileDTO): Promise<void>
    {
        return store.dispatch(new TransformFile(item));
    }
}
