export interface StyleState {
  isLoading: boolean;
  location: string;
  error: object;
}

export const initialState = (): StyleState => {
  return {
    isLoading: false,
    location: null,
    error: null
  }
}