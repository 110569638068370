<template>
  <div class="card_source">
    <div class="header">
      <div>
        <el-checkbox v-model="isUse" border size="mini">Использовать альтернативный источник</el-checkbox>
        <el-button size="mini" style="margin-left: 5px;vertical-align: bottom;" @click="fill">Заполнить из карточки</el-button>
        <el-input
          size="mini"
          style="float: right; width: 40%"
          placeholder="Поиск..."
          v-model="searchText">
        </el-input>
      </div>
    </div>
    <div class="fields_select">
      <div class="tree_control">
        <el-button size="mini" @click="fillAll" type="text">Выделить все</el-button>
        <el-button size="mini" @click="clearAll" style="margin-left: 5px; color: #f56c6c" type="text">Сбросить все</el-button>
        <span class="status">Выбрано полей: {{ fields.length }}</span>
      </div>
      <el-scrollbar :style="{height:'calc(100% - 30px)'}" wrap-style="overflow-x:hidden;" ref="scroll">
      <el-tree
        ref="tree"
        node-key="id"
        v-loading="loading"
        :data="registryFields"
        :props="props"
        :filter-node-method="filterNode"
        @check-change="handleCheckChange"
        show-checkbox
        default-expand-all
        :default-checked-keys="fields"
        :expand-on-click-node="false">
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span
              :title="node.label.length > 33 ? node.label +' '+ data.id : ''"
            >
              <font-awesome-icon icon="calculator" style="padding-right: 3px" v-if="getFormulaIcon(node, data)"/>
              <i style="font-size: 16px;" :class="getTreeIcon(node, data)"></i>
              <b>{{
                  node.label.length > 33 ? `${node.label.substring(0, 33)}...` : node.label
                }}</b>
              <span style="color: #7C838B; padding-left: 6px;"> {{
                  $locale.object_editor.entity_types[data.entity_type_id] || data.entity_type_id
                }} id {{ data.id }}</span>
            </span>
          </span>
        </el-tree>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCalculator } from '@fortawesome/free-solid-svg-icons'
library.add(faCalculator)

export default {
  name: 'RegistryCardSource',
  props: {
    registryId: Number,
    fields: {
      type: Array,
      default: () => []
    },
    isActive: {
      type: Boolean,
      default: false
    },
    cardFields: {
      type: Array,
      default: () => []
    }
  },
  components: {
    FontAwesomeIcon
  },
  mounted () {
    this.loadRegistryFields()
  },
  data () {
    return {
      searchText: null,
      isUse: this.isActive,
      registryFields: [],
      loading: false,
      props: {
        isLeaf: 'leaf',
        label: 'name',
        children: 'children'
      }
    }
  },
  watch: {
    searchText (value) {
      this.$refs.tree.filter(value)
    },
    isUse (value) {
      this.$emit('update:is-active', value)
    }
  },
  methods: {
    fillAll () {
      this.$refs.tree.setCheckedKeys(this.registryFields.map((item) => item.id))
    },
    clearAll () {
      this.$refs.tree.setCheckedKeys([])
    },
    fill () {
      this.$refs.tree.setCheckedKeys(this.cardFields)
    },
    filterNode (value, data) {
      if ((value || '').trim().length === 0) {
        return true
      }
      return data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
        data.id.toString().indexOf(value) !== -1
    },
    getTreeIcon (node, data) {
      if (data.entity_type_id === 'registry_group' && !node.expanded) {
        return 'el-icon-folder'
      } else if (data.entity_type_id === 'registry_group' && node.expanded) {
        return 'el-icon-folder-opened'
      }
    },
    getFormulaIcon (node, data) {
      return !!(data.properties && data.properties.find(property => property.id === 'formula_id') && data.properties.find(property => property.id === 'formula_id').value)
    },
    handleCheckChange () {
      this.$emit('update:fields', this.$refs.tree.getCheckedKeys(true))
    },
    async loadRegistryFields () {
      if (!this.registryId) {
        console.warn('RegistryId is null')
        return false
      }
      this.loading = true
      const { data } = await this.$http.get(`${this.$config.api}/objecteditor/entities?parent_id=${this.registryId}&show_children=true`)
        .finally(() => {
          this.loading = false
        })

      this.registryFields = data.data
    }
  }
}
</script>

<style scoped lang="scss">
.card_source {
  width: 100%;
  height: 100%;
  .header {
    padding: 0 24px;
  }
  .tree_control {
    padding: 0 24px;
    .status {
      padding-top: 8px;
      font-size: 12px;
      color: #606266;
      float: right;
    }
  }
  .fields_select {
    height: calc(100% - 28px);
  }
}
.custom-tree-node {
  flex: 1;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  width: 90%;
}
::v-deep .el-tree-node__children{
  border-left: 2px solid #4FA9F3;
  margin-left: 5px;
}

::v-deep .el-tree-node.is-focusable.is-expanded{
  background-color: transparent;
}
</style>
