import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import AddressesQuery from '@/services/AddressService/application/query/AddressesQuery'
import RegionQueryRepositoryInterface from '@/services/AddressService/domain/repository/RegionQueryRepositoryInterface'

export default class RegionsHandler implements QueryHandlerInterface {
  private repository: RegionQueryRepositoryInterface;

  constructor (repository: RegionQueryRepositoryInterface) {
    this.repository = repository
  }

  execute (query: AddressesQuery): Promise<any> {
    return this.repository.get(query.getPayload())
  }
}
