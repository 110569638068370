import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    TREE_ELEMENT_LOADING_EVENT = 'tree_element_loading_event',
    TREE_ELEMENT_LOADED_EVENT = 'tree_element_loaded_event'
}

export class TreeElementLoadingEvent implements FluxStandardAction {
  type = MutationTypes.TREE_ELEMENT_LOADING_EVENT
}

export class TreeElementLoadedEvent implements FluxStandardAction {
  type = MutationTypes.TREE_ELEMENT_LOADED_EVENT
}
