<template>
  <div class="html-interface-designer">
    <div class="draggable-block">
      <div :style="style">
          <div style="width:100%; height: 100%">
              <draggable-block
                      v-for="block in blocks"
                      :key="block.guid"
                      :ref="block.guid"
                      :component="block.component"
                      :properties="block.properties"
                      :width="block.width"
                      :height="block.height"
                      :x="block.x"
                      :y="block.y"
                      @select="select(block.guid)"
                      @change-size="onChangeSize(block.guid, $event)"
                      @change-active="block.isActive = $event"
              ></draggable-block>
          </div>
      </div>
    </div>
    <div style="flex: 1; padding-left: 10px;">
      <div class="settings_title">{{ $locale.main.button.settings }}</div>
        <div style="display: flex;">
            <el-select v-model="activeBlockGuid" clearable style="padding-right: 5px;">
                <el-option
                        v-for="item in blocks"
                        :key="item.guid"
                        :label="getNameOption(item)"
                        :value="item.guid">
                    <span>{{ getNameOption(item) }}</span>
                </el-option>
            </el-select>
            <el-dropdown @command="addBlock" trigger="click">
              <el-button type="primary">
                {{ $locale.main.button.add }}
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(component, index) in components" :key="index" :command="component">{{ component.name }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
        </div>
        <el-form v-if="activeBlockGuid" style="margin-top: 18px;" size="mini" @submit.native.prevent>
            <div v-for="(property, index) in getBlockPropertiesFields(activeBlockGuid)" :key="`${index}_${activeBlockGuid}`">
                <span v-if="property.label" class="registry_fields_label" style="display: inline-block">
                    {{ property.label }}
                </span>
                <el-form-item>
                    <component
                        v-bind="property.bind"
                        :is="property.type"
                        @change="updateBlockProperty($event, property.name, activeBlockGuid)"
                    ></component>
                </el-form-item>
            </div>
        </el-form>
        <el-button type="danger" plain icon="el-icon-delete" @click="deleteBlock(activeBlockGuid);activeBlockGuid=null" v-if="activeBlockGuid">Удалить</el-button>
    </div>
  </div>
</template>

<script>
import DraggableBlock from './DraggableBlock'

import StringEditor from './Editors/String'
import BooleanEditor from './Editors/Boolean'
import ImageSourceEditor from './Editors/ImageSource'
import StyleConditionsEditor from './Editors/StyleConditions'
import InteractiveEditor from './Editors/Interactive'
import IndicatorFieldsEditor from './Editors/IndicatorFields'
const Editors = {
  'String': 'StringEditor',
  'Boolean': 'BooleanEditor'
}

export default {
  name: 'HTML-interface-designer',
  components: {
    DraggableBlock,
    StringEditor,
    BooleanEditor,
    ImageSourceEditor,
    StyleConditionsEditor,
    InteractiveEditor,
    IndicatorFieldsEditor
  },
  provide () {
    return {
      isDesigner () {
        return true
      }
    }
  },
  props: {
    styleHtml: {
      type: String,
      default: () => {
        return ''
      }
    },
    initialBlocks: {
      type: Array,
      default: () => {
        return []
      }
    },
    width: {
      type: Number,
      default: 300
    },
    height: {
      type: Number,
      default: 300
    }
  },
  data () {
    return {
      activeBlockGuid: null,
      components: [
        {
          type: 'TextLabel',
          name: 'Текст'
        },
        {
          type: 'ImageBlock',
          name: 'Изображение'
        },
        {
          type: 'IndicatorsBlock',
          name: 'Блок индикаторов'
        }
      ],
      blocks: this.initialBlocks.map((block) => {
        if (Array.isArray(block.properties)) {
          block.properties = {}
        }
        return block
      })
    }
  },
  watch: {
    blocks: {
      immediate: false,
      deep: true,
      handler () {
        this.$nextTick(() => {
          this.$emit('save', this.getBlocks())
        })
      }
    }
  },
  methods: {
    getNameOption (item) {
      return item.properties.alias ? item.properties.alias : item.properties.text ? item.properties.text : item.properties.guid
    },
    select (guid) {
      this.activeBlockGuid = guid
    },
    deleteBlock (guid) {
      this.blocks = this.blocks.filter(block => block.guid !== guid)
    },
    onChangeSize (guid, size) {
      let block = this.blocks.find((item) => item.guid === guid)
      if (!block) {
        console.warn(`block (guid=${guid}) - not found`)
        return false
      }
      this.$set(block, 'width', size.width)
      this.$set(block, 'height', size.height)
      this.$set(block, 'x', size.x)
      this.$set(block, 'y', size.y)
    },
    getBlocks () {
      return this.blocks
    },
    addBlock (component) {
      let guid = this.generateGuid()

      this.blocks.push({
        guid: guid,
        width: 100,
        height: 100,
        x: 0,
        y: 0,
        component: component.type,
        properties: { alias: component.name },
        isActive: false
      })
      setTimeout(() => {
        if (!this.loading) {
          this.activeBlockGuid = guid
        }
      }, 500)
    },
    getBlockPropertiesFields (guid) {
      let answer = []
      let component = this.$refs[guid][0].$refs.component
      let properties = component.getProperties()
      for (let property in properties) {
        let type = properties[property].editor ? properties[property].editor : Editors[properties[property].type.name]
        answer.push({
          label: properties[property].description,
          name: property,
          type: type,
          bind: {
            value: component.$props[property]
          }
        })
      }
      return answer
    },
    updateBlockProperty (value, property, guid) {
      let block = this.blocks.find((item) => item.guid === guid)
      this.$set(block.properties, property, value)
    }
  },
  computed: {
    style () {
      let style = `width: ${this.width}px;`
      if (this.styleHtml) {
        let hasHeight = this.styleHtml.split(';').some(item => {
          return item.split(':')[0].trim() === 'height'
        })
        style = `${style} ${this.styleHtml};`
        if (hasHeight) {
          return style
        }
      }
      return `${style} height: ${this.height}px;`
    }
  }
}
</script>

<style scoped>
.html-interface-designer {
  padding: 10px;
  display: flex;
}
.draggable-block {
  margin-top: 10px;
  background: #E9EBED;
  height: fit-content;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
  margin-bottom: 10px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.settings_title{
  font-size: 16px;
  text-align: center;
}
</style>
