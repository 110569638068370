import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ProjectTreeElementCommandRepositoryInterface from '@/services/MapEditor/domain/repository/ProjectTreeElementCommandRepositoryInterface'
import ProjectTreeElementGroupCreateCommand from '@/services/MapEditor/application/command/ProjectTreeElementGroupCreateCommand'
import { ProjectTreeElementDTO } from '@/services/MapEditor/domain/model/Project/ProjectTreeElement'

export default class ProjectTreeElementGroupCreateHandler implements CommandHandlerInterface
{
    private repo: ProjectTreeElementCommandRepositoryInterface;

    constructor(repo: ProjectTreeElementCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: ProjectTreeElementGroupCreateCommand): Promise<void>
    {
        const item: ProjectTreeElementDTO = {
            name: command.getName(),
            parent_id: command.getParentId()
        };
        return this.repo.insertGroup(item);
    }
}