import { MutationTree } from 'vuex'
import { AffineTransformationState, initialState } from './state'
import { MutationTypes, AffineTransformationCreatedEvent, AffineTransformationLoadingEvent, AffineTransformationDeletedEvent } from './mutation-types'

export const mutations: MutationTree<AffineTransformationState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false;
    state.error = error;    
  },
  [MutationTypes.AFFINE_TRANSFORMATION_LOADING_EVENT]: (state) => {
    state.isLoading = true;
  },
  [MutationTypes.AFFINE_TRANSFORMATION_CREATED_EVENT]: (state, action: AffineTransformationCreatedEvent) => {
    state.location = action.location;
    state.isLoading = false;
  },
  [MutationTypes.AFFINE_TRANSFORMATION_DELETED_EVENT]: (state, action: AffineTransformationDeletedEvent) => {
    state.isLoading = false;
  }
}