import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    REQUEST_LOADING_EVENT = 'request_loading_event',
    REQUEST_LOADED_EVENT = 'request_loaded_event'
}

export class RequestLoadingEvent implements FluxStandardAction {
    type = MutationTypes.REQUEST_LOADING_EVENT
}

export class RequestLoadedEvent implements FluxStandardAction {
    type = MutationTypes.REQUEST_LOADED_EVENT
}
