<template>
  <ul id="main" :style="css">
    <li v-for="(value, index) in localValue" :key="index">
      <ul>
        <li>
          Файл:
          <span>
            <el-link
              style="white-space: pre"
              icon="el-icon-document"
              @click="download(value)"
            >
              {{ value.name }}
            </el-link>
          </span>
        </li>
        <li>Размер файла: {{ value.size }} байт</li>
      </ul>
    </li>
  </ul>
</template>

<script>
export default {
  props: ["value", "css"],
  name: "file_field",
  computed: {
    localValue() {
      let parsed = JSON.parse(this.value || "[]");
      if (parsed instanceof Array && parsed.length > 0) {
        return parsed.map((file) => {
          return {
            name: `${file.alias}.${file.extension}`,
            hashByAlgorithmCertificate: file.hashByAlgorithmCertificate,
            size: `${file.size}`,
            url: `${this.$config.api}/files/${file.dir}/${file.name}.${file.extension}`,
          };
        });
      }

      return [];
    },
  },
  methods: {
    download(file) {
      let me = this;
      this.$http({
        method: "get",
        url: file.url,
        responseType: "blob",
      }).then(function (response) {
        let blob = new Blob([response.data]);
        me.downloadBlob(blob, file.name);
      });
    },
    downloadBlob(blob, filename) {
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  }
};
</script>

<style scoped>
ul#main {
   padding-left: 20px;
}
ul#main>li{
  list-style: disc;
}
ul > li {
  list-style-type: none;
}
ul {
  padding-left: 0;
}
</style>
