import AbstractByIdQuery from './AbstractByIdQuery'
import QueryHandlerInterface from './QueryHandlerInterface'
import AbstractQueryRepository from '../../domain/repository/AbstractQueryRepository'

export default abstract class AbstractByIdQueryHandler implements QueryHandlerInterface
{
    private repo: AbstractQueryRepository;

    constructor(repo: AbstractQueryRepository)
    {
        this.repo = repo;
    }

    execute(query: AbstractByIdQuery): Promise<any>
    {
        return this.repo.getById(query.getId());
    }
}