import { FluxStandardAction } from '@/core/domain/type/types'
import { LogicDTO } from '@/services/LogicEditor/domain/model/Logic'

export enum ActionTypes {
  GET_LOGIC = 'get_logic',
  GET_LOGIC_BY_GUID = 'get_logic_by_guid',
  CREATE_LOGIC = 'create_logic',
  UPDATE_LOGIC = 'update_logic',
  DELETE_LOGIC = 'delete_logic'
}

export class GetLogic implements FluxStandardAction {
  type = 'Logic/' + ActionTypes.GET_LOGIC
  constructor (public payload: object) {}
}

export class GetLogicByGuid implements FluxStandardAction {
  type = 'Logic/' + ActionTypes.GET_LOGIC_BY_GUID
  constructor (public guid: string) {}
}

export class CreateLogic implements FluxStandardAction {
  type = 'Logic/' + ActionTypes.CREATE_LOGIC
  constructor (public payload: LogicDTO) {}
}

export class UpdateLogic implements FluxStandardAction {
  type = 'Logic/' + ActionTypes.UPDATE_LOGIC
  constructor (public payload: LogicDTO) {}
}

export class DeleteLogic implements FluxStandardAction {
  type = 'Logic/' + ActionTypes.DELETE_LOGIC
  constructor (public guid: string) {}
}
