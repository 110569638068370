import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import LandscapeTexturesQueryRepositoryInterface from '../../../domain/repository/LandscapeTexturesQueryRepositoryInterface'
import LandscapeTexturesCountQuery from '../../query/LandscapeTexturesCountQuery'

export default class LandscapeTexturesCountHandler implements QueryHandlerInterface {
    private repo: LandscapeTexturesQueryRepositoryInterface;

    constructor (repo: LandscapeTexturesQueryRepositoryInterface) {
      this.repo = repo
    }

    execute (query: LandscapeTexturesCountQuery): Promise<any> {
      return this.repo.getCount(query.getPayload())
    }
}
