import { FluxStandardAction } from '@/core/domain/type/types'
import { ProjectGroupDTO } from '@/services/MapEditor/domain/model/Project/ProjectGroup'

export enum MutationTypes {
    ERROR = 'error',
    PROJECT_GROUP_LOADING_EVENT = 'project_group_loading_event',
    PROJECT_GROUP_UPDATED_EVENT = 'project_group_updated_event'  
}

export class ProjectGroupLoadingEvent implements FluxStandardAction {
    type = MutationTypes.PROJECT_GROUP_LOADING_EVENT
}

export class ProjectGroupUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.PROJECT_GROUP_UPDATED_EVENT
}