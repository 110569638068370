<template>
  <el-form label-width="120px" label-position="top" size="mini" class="editor_tree">
    <el-button type="text" @click="dialogSettingsVisible = true">{{$locale.interface_editor.component_editor.editor_tree.button_visibility}}</el-button>
    <el-dialog
      :title="$locale.interface_editor.component_editor.editor_tree.button_visibility"
      :modal="false"
      :visible.sync="dialogSettingsVisible">
      <el-form-item>
        <editor-conditions
          v-model="value.addBtnHidden"
          :options="{
            label: $locale.interface_editor.component_editor.editor_tree.add_btn_hidden,
            sourceRegistry: true,
            fieldsRegistry: fieldsRegistry
            }"/>
        <editor-conditions
          v-model="value.deleteBtnHidden"
          :options="{
            label: $locale.interface_editor.component_editor.editor_tree.delete_btn_hidden,
            sourceRegistry: true,
            fieldsRegistry: fieldsRegistry
            }"/>
        <editor-conditions
          v-model="value.openBtnHidden"
          :options="{
            label: $locale.interface_editor.component_editor.editor_tree.open_btn_hidden,
            sourceRegistry: true,
            fieldsRegistry: fieldsRegistry
            }"/>
      </el-form-item>
    </el-dialog>
    <el-form-item>
      <span class="property_label">{{ $locale.interface_editor.component_editor.table.source_type }}</span>
      <editor-select
        :value="value.type"
        @change="$set(value ,'type', $event)"
        :options="{
          multiple: false,
          options: [
            { id: 'registry', name: 'Реестр' },
            { id: 'extend_object', name: 'Расширенная таблица' },
            { id: 'requests', name: 'Запросы' }
          ]
        }"
      ></editor-select>
    </el-form-item>
    <el-form-item v-if="value.type === 'registry'">
      <!-- <span class="property_label">{{ $locale.interface_editor.component_editor.table.registry }}</span>
      <editor-registry-select :value="value.registryId" @change="$set(value ,'registryId', $event)"></editor-registry-select> -->
      <registry-select
        :label="$locale.interface_editor.component_editor.editor_open_card.select_registry"
        :value="value.registryId"
        @change="$set(value ,'registryId', $event)"
      ></registry-select>
      <el-form-item v-if="value.registryId">

        <span class="property_label">{{ $locale.interface_editor.component_editor.table.key_field }}</span>
        <el-select v-model="value.keyField">
          <el-option v-for="(item) in fieldsRegistry"
          :key="item.id"
          :value="item.id"
          :label="item.name + ` (attr_${item.id}_)`">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form-item>
    <el-form-item>
      <span class="property_label">{{ $locale.interface_editor.component_editor.registry_headers.html_template }}</span>
      <el-input
        class="htmlTemplate"
        type="textarea"
        :autosize="{ minRows: 3, maxRows: 6}"
        v-model="value.htmlTemplate">
      </el-input>
    </el-form-item>
    <el-form-item>
      <span class="property_label">{{ $locale.interface_editor.component_editor.editor_tree.limit }}</span>
      <el-input-number
        controls-position="right"
        v-model="value.limit"
        :disabled="value.loadAllData">
      </el-input-number>
      <el-form-item>
      <span class="property_label">
        <el-checkbox v-model="value.loadAllData">{{ $locale.interface_editor.component_editor.editor_tree.load_all_data }}</el-checkbox>
      </span>
      </el-form-item>
      <el-form-item>
      <span class="property_label">
        <el-checkbox v-model="value.searchModeEnabled">{{ $locale.interface_editor.component_editor.editor_tree.search_mode_enabled }}</el-checkbox>
      </span>
      </el-form-item>
      <el-form-item>
      <span class="property_label">
        <el-checkbox v-model="value.openInWindow">{{ $locale.interface_editor.component_editor.editor_tree.open_card_enabled }}</el-checkbox>
      </span>
        <el-form-item v-if="value.openInWindow">
          <div v-if="value.type === 'registry'">
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_open_card.registry_card') }}</span>
            <el-select
                :value="value.cardId"
                @change="$set(value ,'cardId', $event);$set(value ,'cardIdSource', 'registry')"
                clearable
            >
              <el-option v-for="(item, index) in cards" :key="index" :label="item.name" :value="item.id">
                <span style="float: left">{{ item.name }} (id: {{ item.id }})</span>
              </el-option>
            </el-select>
          </div>

          <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.window_title }}</span>
          <el-input v-model="value.windowTitle" />
          <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.window_width }}</span>
          <editor-select
              :value="value.windowWidth"
              @change="$set(value, 'windowWidth', $event)"
              :options="{
                  multiple: false,
                  options: [
                    { id: '25', name:'25%' },
                    { id: '50', name:'50%' },
                    { id: '75', name:'75%' },
                    { id: '100', name:'100%' },
                  ]
                }"
          ></editor-select>
          <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.window_height }} (ex: 400px)</span>
          <el-input :value="value.windowHeight" @input="$set(value, 'windowHeight', $event)"></el-input>
        </el-form-item>
      </el-form-item>
    </el-form-item>
  </el-form>
</template>

<script>
import EditorSelect from './editor-select'
// import EditorRegistrySelect from './editor-registry-select'
import EditorConditions from './editor-conditions'
import RegistrySelect from '@/components/Common/RegistrySelectTreeWithSearch.vue'
import Card from '@/components/ObjectEditor/Models/Card'
export default {
  name: 'editor-tree',
  components: {
    EditorSelect,
    // EditorRegistrySelect,
    EditorConditions,
    RegistrySelect
  },
  props: {
    value: {
      type: Object,
      openInWindow: Boolean
    }
  },
  data () {
    return {
      fieldsRegistry: [],
      dialogSettingsVisible: false,
      cards: []
    }
  },
  watch: {
    value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    },
    'value.registryId': {
      async handler (val) {
        if (val) {
          this.fieldsRegistry = await this.getFieldsRegistry(val)
        }
      }
    }
  },
  async created () {
    await this.loadCards(this.value.registryId)
  },
  async mounted () {
    if (this.value.type === 'registry' && this.value.registryId) {
      this.fieldsRegistry = await this.getFieldsRegistry(this.value.registryId)
    }
  },
  methods: {
    async loadCards (entityId) {
      this.cards = await new Card().params({ entity_id: entityId, fields: ['id', 'name'] }).$get()
    },
    async getFieldsRegistry (registryId) {
      let data = await this.$http.get(`${this.$config.api}/objecteditor/entities?parent_id=${registryId}&show_children=true`)
      return data.data.data
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
