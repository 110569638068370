<template>
  <div>
    <input type="file" @change="changeFileHandler" accept=".json" text="Выберите файл">
  </div>
</template>

<script>
  export default {
    name: 'FileLoader',
    data: function () {
      return {
        filename: null
      }
    },
    methods: {
      changeFileHandler (event) {
        const file = event.target.files[0];
        if (file) {
          this.filename = file.name
        }
        const reader = new FileReader();

        reader.onload = e => this.$emit("load", e.target.result);
        reader.readAsText(file);
      }
    }
  }
</script>

<style scoped>

</style>
