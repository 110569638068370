export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      },
      readonly: true
    }
  },
  methods: {
    getProperties () {
      let properties = Object.assign({}, this.$vnode.componentOptions.Ctor.options.props)
      for (let property in properties) {
        if (properties.hasOwnProperty(property)) {
          if (properties[property].readonly) {
            delete properties[property]
          }
        }
      }
      return properties
    }
  }
}
