import { Nullable } from '@/core/domain/type/types'

export default class ProjectTreeElementModelCreateCommand
{
    private modelId: number;
    private parentId: Nullable<number>;

    constructor(modelId: number, parentId: Nullable<number>)
    {
        this.modelId = modelId;
        this.parentId = parentId;
    }

    getClassName(): string
    {
        return 'DigitalTwinProjectTreeElementModelCreateCommand';
    }

    getModelId(): number
    {
        return this.modelId;
    }

    getParentId(): Nullable<number>
    {
        return this.parentId;
    }
}