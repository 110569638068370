<template>
    <span v-html="localValue"></span>
</template>

<script>
import PropertiesMixin from '@/components/InterfaceEditor/components/PropertiesMixin'

export default {
  name: 'a-stub',
  mixins: [PropertiesMixin],
  inject: {
    getRawData: {
      default: () => () => {}
    },
    forceUpdateSettingsPanel: {
      default: () => () => {}
    }
  },
  props: {
    editorAlias: {
      type: String,
      description: 'Псевдоним'
    },
    attribute: {
      type: String
    }
  },
  computed: {
    localValue () {
      let value = this.getRawData()[this.attribute]
      try {
        value = JSON.parse(value)
      } catch (e) {

      }
      if (value instanceof Array) {
        value = value.map(item => item.name).join(',')
      }

      return value
    }
  },
  watch: {
    editorAlias () {
      this.forceUpdateSettingsPanel()
    }
  }
}
</script>

<style scoped>

</style>
