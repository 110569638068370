export interface TemplateState {
  isLoading: boolean
  location: string
  deletedTemplateGuid: string
  error: object
}

export const initialState = (): TemplateState => {
  return {
    isLoading: false,
    location: null,
    deletedTemplateGuid: null,
    error: null
  }
}
