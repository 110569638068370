import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { OpenWorldDTO } from '@/services/DigitalTwinEditor/domain/model/OpenWorld/OpenWorld'

export enum ActionTypes {
    UPDATE_OPEN_WORLD = 'update_open_world',
    GET_OPEN_WORLD_BY_GUID = 'get_open_world_by_guid',
    SET_DEFAULT_TEXTURES = 'set_default_textures',
    SET_TERRAIN = 'set_terrain',
    SET_LANDSCAPE = 'set_landscape',
    STOP_TILING = 'stop_tiling',
    UPDATE_STYLE = 'update_style',
    GET_LANDSCAPE_TEXTURES = 'get_landscape_textures',
    COUNT_LANDSCAPE_TEXTURES = 'count_landscape_textures',
    GET_OPEN_WORLD_GEOJSON = 'get_open_world_geojson',
    GET_OPEN_WORLD_STYLE = 'get_open_world_style'
}

export class UpdateOpenWorld implements FluxStandardAction {
    type = 'OpenWorld/' + ActionTypes.UPDATE_OPEN_WORLD
    constructor(public payload: OpenWorldDTO){}
}

export class GetOpenWorldByGuid implements FluxStandardAction {
    type = 'OpenWorld/' + ActionTypes.GET_OPEN_WORLD_BY_GUID
    constructor(public guid: string){}
}

export class SetDefaultTextures implements FluxStandardAction {
    type = 'OpenWorld/' + ActionTypes.SET_DEFAULT_TEXTURES
    constructor(public payload: OpenWorldDTO){}
}

export class SetTerrain implements FluxStandardAction {
    type = 'OpenWorld/' + ActionTypes.SET_TERRAIN
    constructor(public payload: OpenWorldDTO){}
}

export class SetLandscape implements FluxStandardAction {
    type = 'OpenWorld/' + ActionTypes.SET_LANDSCAPE
    constructor(public payload: OpenWorldDTO){}
}

export class StopTiling implements FluxStandardAction {
    type = 'OpenWorld/' + ActionTypes.STOP_TILING
    constructor(public payload: OpenWorldDTO){}
}

export class UpdateStyle implements FluxStandardAction {
    type = 'OpenWorld/' + ActionTypes.UPDATE_STYLE
    constructor(public payload: OpenWorldDTO){}
}

export class GetLandscapeTextures implements FluxStandardAction {
    type = 'OpenWorld/' + ActionTypes.GET_LANDSCAPE_TEXTURES
    constructor (public payload: object) {}
}

export class CountLandscapeTextures implements FluxStandardAction {
    type = 'OpenWorld/' + ActionTypes.COUNT_LANDSCAPE_TEXTURES
    constructor (public payload: object) {}
}

export class GetOpenWorldGeojson implements FluxStandardAction {
    type = 'OpenWorld/' + ActionTypes.GET_OPEN_WORLD_GEOJSON
    constructor(public payload: object){}
}

export class GetOpenWorldStyle implements FluxStandardAction {
    type = 'OpenWorld/' + ActionTypes.GET_OPEN_WORLD_STYLE
    constructor(public payload: object){}
}