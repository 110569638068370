import { MutationTree } from 'vuex'
import { ExtractorState, initialState } from './state'
import { MutationTypes, ExtractorCreatedEvent, ExtractorUpdatedEvent, ExtractorDeletedEvent } from './mutation-types'

export const mutations: MutationTree<ExtractorState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false
    state.error = error
  },
  [MutationTypes.EXTRACTOR_LOADING_EVENT]: (state) => {
    state.isLoading = true
  },
  [MutationTypes.EXTRACTOR_LOADED_EVENT]: (state) => {
    state.isLoading = false
  },
  [MutationTypes.EXTRACTOR_CREATED_EVENT]: (state, action: ExtractorCreatedEvent) => {
    state.isLoading = false
    state.location = action.location
  },
  [MutationTypes.EXTRACTOR_UPDATED_EVENT]: (state, action: ExtractorUpdatedEvent) => {
    state.isLoading = false
  },
  [MutationTypes.EXTRACTOR_DELETED_EVENT]: (state, action: ExtractorDeletedEvent) => {
    state.isLoading = false
    state.deletedExtractorGuid = action.guid
  }
}
