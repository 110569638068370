import { FluxStandardAction } from '@/core/domain/type/types'

export enum ActionTypes {
    GET_ADDRESSES = 'get_addresses',
}

export class GetAddresses implements FluxStandardAction {
    type = 'Address/' + ActionTypes.GET_ADDRESSES
    constructor (public payload: string[][]) {}
}
