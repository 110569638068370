<template>
  <div>
    <span class="property_label">{{ $locale.interface_editor.component_editor.editor_stages.status_approval }}</span>
    <el-row>
      <el-button v-for="(item, id) of customStatuses" :key="id" type="text" @click="setVisible(item)">{{item.title}}</el-button>
    </el-row>
    <el-button @click="add" type="primary">Добавить</el-button>
    <el-button @click="setDefaultStatuses" type="primary">Сбросить значения</el-button>
    <el-dialog
      title="Редактирование вида статуса"
      :visible.sync="isVisible"
      width="30%">
      <el-form size="mini">
        <el-form-item :label="$locale.interface_editor.component_editor.editor_stages.name">
          <el-input v-model="visibleItem.title"></el-input>
        </el-form-item>
        <el-form-item :label="$locale.interface_editor.component_editor.editor_stages.color">
          <el-color-picker v-model="visibleItem.color"></el-color-picker>
        </el-form-item>
        <el-form-item :label="$locale.menu_editor.label.tag">
          <el-select clearable filterable v-model="visibleItem.icon">
            <el-option
              v-for="item in listTag"
              :key="item.value"
              :label="item.label"
              class="menu_icon"
              :value="item.value">
              <span style="float: left">{{ item.label }}</span>
              <div style="float: right;font-size: 30px;width: 22px;height: 22px" :class="item.value"></div>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-button type="danger" icon="el-icon-delete" @click="deleteItem">Удалить</el-button>
      <el-button type="primary" @click="setValue">Закрыть</el-button>
    </el-dialog>
  </div>
</template>

<script>
import Icons from '../../../MenuEditor/components/el-icons'

export default {
  name: 'editor-status-stages',
  props: {
    statuses: Array
  },
  data () {
    return {
      visibleItem: {},
      isVisible: false,
      listTag: Icons.map(el => ({ value: el, label: el })),
      arrStatuses: this.statuses.length === 0 ? this.defaultItems : this.statuses,
      defaultItems: [
        { id: 0, title: 'wait', color: null, icon: null },
        { id: 1, title: 'process', color: null, icon: null },
        { id: 2, title: 'finish', color: null, icon: null },
        { id: 3, title: 'error', color: null, icon: null },
        { id: 4, title: 'success', color: null, icon: null }
      ]
    }
  },
  computed: {
    customStatuses () {
      return this.arrStatuses
    }
  },
  watch: {
    arrStatuses: function () {
      this.$emit('changeStatuses', this.arrStatuses)
    },
    visibleItem: {
      handler: function (value) {
        var stages = []
        this.arrStatuses.forEach((status) => {
          if (status.id === value.id) {
            status = value
          }
          stages.push(status)
        })
        this.arrStatuses = stages
      },
      deep: true
    }
  },
  methods: {
    setDefaultStatuses () {
      this.arrStatuses = this.defaultItems
    },
    setValue () {
      const index = this.arrStatuses.findIndex((i) => i.id === this.visibleItem.id)
      this.arrStatuses[index] = this.visibleItem
      this.isVisible = false
    },
    getHeaders () {
      return {
        Authorization: localStorage.getItem('user-token')
      }
    },
    setVisible (item) {
      this.isVisible = true
      this.visibleItem = item
    },
    add () {
      let id = Math.max.apply(Math, this.arrStatuses.map(function (o) { return o.id }))
      id = id + 1
      this.arrStatuses.push({
        'title': 'default',
        'color': null,
        'icon': null,
        'id': id
      })
      this.setVisible(this.arrStatuses[this.arrStatuses.length - 1])
    },
    deleteItem () {
      this.arrStatuses = this.arrStatuses.filter((i) => i.id !== this.visibleItem.id)
      this.isVisible = false
    }
  }
}
</script>

<style scoped>
  .el-form-item--mini .el-form-item__content, .el-form-item--mini .el-form-item__label{
    line-height: 5px;
}
</style>
