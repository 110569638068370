import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import EntityQueryRepositoryInterface from '@/services/MapEditor/domain/repository/EntityQueryRepositoryInterface'
import store from '@/store'
import { GetEntities, GetEntityById } from '@/services/MapEditor/infrastructure/store/modules/Entity/action-types'

export default class EntityQueryRepository extends AbstractQueryRepository implements EntityQueryRepositoryInterface
{
    getById(id: number): Promise<any>
    {
        return store.dispatch(new GetEntityById(id));
    }

    get(payload: string[][]): Promise<any>
    {
        return store.dispatch(new GetEntities(payload));
    }
}