import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { CoordinateSystemDTO } from '@/services/MapEditor/domain/model/Geometry/CoordinateSystem'

export namespace CoordinateSystemAPI {

    export class AddCoordinateSystem implements APIRequest<string> {
        response: string;
        path = '/mapeditor/coordinate_systems';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.headers.location
        constructor(public params: CoordinateSystemDTO) {}
    }

    export class UpdateCoordinateSystem implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: CoordinateSystemDTO) {
            this.path = `/mapeditor/coordinate_systems/${params.srid}`;
        }
    }

    export class DeleteCoordinateSystem implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.DELETE;
        parse = (data: AxiosResponse) => data.data
        constructor(srid: number) {
            this.path = `/mapeditor/coordinate_systems/${srid}`;
        }
    }

    export class DeleteProjectCoordinateSystem implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.DELETE;
        parse = (data: AxiosResponse) => data.data
        constructor(srid: number, projectGuid: string) {
            this.path = `/mapeditor/projects/${projectGuid}/coordinate_systems/?id=${srid}`;
        }
    }

    export class CountCoordinateSystems implements APIRequest<CoordinateSystemDTO> {
        response: CoordinateSystemDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/mapeditor/coordinate_systems?*[func]=count'
        }
    }

    export class GetCoordinateSystems implements APIRequest<CoordinateSystemDTO> {
        response: CoordinateSystemDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/mapeditor/coordinate_systems?' +  (new URLSearchParams(payload).toString())
        }
    }

    export class GetCoordinateSystemBySrid implements APIRequest<CoordinateSystemDTO> {
        response: CoordinateSystemDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(srid: number) {
            this.path = `/mapeditor/coordinate_systems/${srid}`;
        }
    }

    export class GetCoordinateSystemsByUserId implements APIRequest<CoordinateSystemDTO> {
        response: CoordinateSystemDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(userId: number) {
            this.path = `/mapeditor/coordinate_systems_user/${userId}`;
        }
    }
}