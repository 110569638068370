<template>
  <el-form-item label="URL">
    <el-input type="text" v-model="value.externalURL"></el-input>
  </el-form-item>
</template>

<script>
export default {
  name: 'WMS',
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          externalURL: null
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
