/*
  IMask
  0 - Любая цифра
  a - Любая буква
  * - Любой символ

  [] - Необязательное
  {} - В значении без маски<br>
  ` - Остановить сдвиг символов (при удалении до `)
  \\  - Экранировать символ. Например: \\0

  Подробнее: https://imask.js.org/guide.html#masked-pattern
*/

export default {
  data () {
    return {
      // Список выбора для editor-input-type
      // typeof maskTemplates[n].value === 'string' потому, что нужно сохранять выбранный шаблон на сервере. Полностью корректно сохраняется только string
      maskTemplatesOptions: [
        /*
          Примеры
        */
        // {
        //   label: 'some',
        //   value: 'some'
        // },
        // {
        //   label: 'someRegex',
        //   value: 'someRegex'
        // },
        // {
        //   label: 'someFunction',
        //   value: 'someFunction'
        // },
        // {
        //   label: 'someArray',
        //   value: 'someArray'
        // },

        {
          label: 'Телефон',
          value: 'phone'
        },
        {
          label: 'ИНН физ./ИП.',
          value: 'innIndividual'
        },
        {
          label: 'ИНН юр.',
          value: 'innLegalEntity'
        },
        {
          label: 'ОГРН',
          value: 'ogrn'
        },
        {
          label: 'СНИЛС',
          value: 'snils'
        },
        {
          label: 'Кадастровый номер',
          value: 'cadastralNumber'
        }
      ],

      /*
        Свойства объекта шаблона:
          mask - Маска:
            - String - Строка с плейсхолдерами вида: 0, a, * и т.д.
            - Array - Массив объектов с mask, выбор маски через dispatch (TODO: зависимость lazy от чекбокса)
            - Function - Валидатор, не работает как маска, return true - разрешает ввести символ
            - Regex - Валидатор, не работает как маска, проверяет строку на совпадение с регулярным выражением
          lazy - Направляющая маски: Не назначен (по умолчанию) == чекбокс "Направляющая маски" (!this.maskSettings.isMaskGuide). false - есть, true - нету
          prepare - Функция модификатор введённого текста
          dispatch - (Если mask == Array) Функция для выбора объекта маски в массиве mask - вызывается посимвольно слева направо с места вставки символа до конца

        Подробнее: https://imask.js.org/guide.html
      */
      maskTemplates: {
        /*
          Примеры
        */
        // some: {
        //   mask: '\\a-****-b',
        //   prepare: this.maskMethods.upperCase
        // },
        // someArray: {
        //   mask: [{ mask: '00-\\a', someTag: '1' }, { mask: '000-\\b', someTag: '2', lazy: true }],
        //   dispatch (appended, dynamicMasked) {
        //     // Первый символ
        //     const firstChar = (dynamicMasked.value + appended)[0]
        //     // Вернуть объект маски из массива mask
        //     return dynamicMasked.compiledMasks.find(mask => mask.someTag === firstChar)
        //   }
        // },
        // someFunction: {
        //   mask: value => value.length < 5 // Строка меньше 5 символов
        // },
        // someRegex: {
        //   mask: /^\d+$/ // Строка только из цифр
        // },

        phone: {
          /*
            Стандарт E.164
            +7 — код страны - 1-3 цифры
            Код зоны нумерации (код города) — 3-5 цифр
            Местный номер — 5-7 цифр.
            Длина российского номера не более 12 символов (вместе с +7)
            Максимум 15 цифр

            +7 (123) 555-66-77 +71235556677
          */
          mask: '+{7} (000) 000-00-00'
        },
        innIndividual: {
          // Физ/ИП - 12 цифр
          mask: '000000000000'
        },
        innLegalEntity: {
          // Юр - 10 цифр
          mask: '0000000000'
        },
        ogrn: {
          // ОГРН - 13 цифр
          mask: '0000000000000'
        },
        snils: {
          // СНИЛС - 11 цифр
          mask: '000-000-000 00'
        },
        cadastralNumber: {
          /*
            АА:ВВ:ССССССС:КК
              АА — кадастровый округ 2 цифры.
              ВВ — кадастровый район 2 цифры.
              ССССССС — кадастровый квартал 7 цифр.
              КК — номер объекта недвижимости от 1 цифры.
          */
          mask: '00:00:0000000:0[000000]'
        }
      },
      maskMethods: {
        upperCase (value) {
          return value.toUpperCase()
        }
      }
    }
  }
}
