import { FluxStandardAction } from '@/core/domain/type/types'
import { GroupDTO } from '@/services/EtlEditor/domain/model/Group'

export enum ActionTypes {
  GET_GROUP_BY_GUID = 'get_group_by_id',
  CREATE_GROUP = 'create_group',
  UPDATE_GROUP = 'update_group',
  DELETE_GROUP = 'delete_group'
}

export class GetGroupByGuid implements FluxStandardAction {
  type = 'Group/' + ActionTypes.GET_GROUP_BY_GUID
  constructor (public guid: string) {}
}

export class CreateGroup implements FluxStandardAction {
  type = 'Group/' + ActionTypes.CREATE_GROUP
  constructor (public payload: GroupDTO) {}
}

export class UpdateGroup implements FluxStandardAction {
  type = 'Group/' + ActionTypes.UPDATE_GROUP
  constructor (public payload: GroupDTO) {}
}

export class DeleteGroup implements FluxStandardAction {
  type = 'Group/' + ActionTypes.DELETE_GROUP
  constructor (public guid: string) {}
}
