<template>
  <div>
    <el-form-item label="Запрос">
      <el-select
        v-model="value.entityId">
        <el-option
          v-for="item in list"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="Геометрия">
      <el-input v-model="value.geometryField"></el-input>
    </el-form-item>
    <el-form-item label="Метки значений">
      <el-input v-model="value.marksField"></el-input>
    </el-form-item>
    <el-form-item label="Система координат">
      <coordinate-system
        :value="value.nativeCoordinateSystemId"
        @change-cs="value.nativeCoordinateSystemId = $event"
      ></coordinate-system>
    </el-form-item>
    <el-form-item label="Способ обработки">
      <el-select
        v-model="value.srsHandlingType">
        <el-option
          v-for="item in $locale.interface_editor.component_editor.map_layers.srs_handling_types"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>
  </div>
</template>

<script>
import CoordinateSystem from '@/core/infrastructure/components/CoordinateSystem'

export default {
  name: 'Query',
  components: { CoordinateSystem },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          entityId: null,
          geometryField: null,
          marksField: null
        }
      }
    }
  },
  data () {
    return {
      list: []
    }
  },
  mounted () {
    this.loadList()
  },
  methods: {
    async loadList () {
      let response = await this.$http.get(`${this.$config.api}/bieditor/queries`)
      this.list = response.data
    }
  }
}
</script>

<style scoped>

</style>
