<template>
  <div>
    <el-button type="text" @click="dialogSettingsVisible = true">{{ $locale.main.button.edit }}</el-button>
    <el-dialog title="Отображение метрик геометрии" :modal="false"
               :visible.sync="dialogSettingsVisible"
               class="window">
      <el-col :span="24">
        <span style="margin-right: 10px">Включить отображение длины/площади</span>
        <el-checkbox v-model="value.enable" @change="changeValue"></el-checkbox>
      </el-col>
      <el-col :span="24" v-if="value.enable">
        <span style="margin-right: 10px">Добавить переключение единиц измерения</span>
        <el-checkbox v-model="value.enableSwitch" @change="changeValue"></el-checkbox>
      </el-col>
      <el-col :span="24" v-if="value.enable">
        <span style="margin-right: 10px">Единицы отображения длины/площади</span>
        <el-select v-model="value.units" @change="changeValue" style="width: auto;">
          <el-option
            v-for="type in measureUnits"
            :key="type.id"
            :label="type.name"
            :value="type.id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="24" v-if="value.enable">
        <span style="margin-right: 10px">Количество чисел после запятой</span>
        <el-input type="number" v-model="value.numbersAfterDot" style="width: auto;"></el-input>
      </el-col>
    </el-dialog>
  </div>
</template>

<script>
  const MEASURE_UNITS = [
    { id: 'meters', name: 'Метры'},
    { id: 'kilometers', name: 'Километры' }
  ]
  export default {
    name: 'FeatureMetrics',
    props: {
      value: {
        type: Object
      }
    },
    data: function () {
      return {
        measureUnits: MEASURE_UNITS,
        dialogSettingsVisible: false
      }
    },
    methods: {
      changeValue() {
        this.$emit('change', this.value)
      }
    }
  }
</script>

<style scoped>
  .window > /deep/ .el-dialog {
    width: 540px;
    height: 210px;
  }
</style>
