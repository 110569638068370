import Model from './Model'

export default class ExtendedObject extends Model {
  resource () {
    return 'extended_objects'
  }

  data () {
    return '/data'
  }
}
