import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    STATE_LOADING_EVENT = 'state_loading_event',
    STATE_LOADED_EVENT = 'state_loaded_event',
    STATE_CREATED_EVENT = 'state_created_event',
    STATE_UPDATED_EVENT = 'state_updated_event',
    STATE_DELETED_EVENT = 'state_deleted_event'
}

export class StateLoadingEvent implements FluxStandardAction {
  type = MutationTypes.STATE_LOADING_EVENT
}

export class StateLoadedEvent implements FluxStandardAction {
  type = MutationTypes.STATE_LOADED_EVENT
}

export class StateCreatedEvent implements FluxStandardAction {
  type = MutationTypes.STATE_CREATED_EVENT
  constructor (public location: string) {}
}

export class StateUpdatedEvent implements FluxStandardAction {
  type = MutationTypes.STATE_UPDATED_EVENT
  constructor (public location: string) {}
}

export class StateDeletedEvent implements FluxStandardAction {
  type = MutationTypes.STATE_DELETED_EVENT
  constructor (public guid: string) {}
}
