import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import TaskRegistryEntity, { IAPITaskRegistryEntity } from '@/services/TaskEditor/domain/model/TaskRegistryEntity'

export namespace TaskRegistryEntityAPI {
  export class GetTaskRegistryEntities implements APIRequest<object> {
    response: object;
    path = `/taskeditor/task_registry_entities`;
    method = HTTPMethod.GET;
    parse = (response: AxiosResponse<object>) => response.data
  }

  export class GetTaskRegistryEntityByProperty implements APIRequest<IAPITaskRegistryEntity> {
    response: IAPITaskRegistryEntity;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse<IAPITaskRegistryEntity>) => TaskRegistryEntity.load(data.data)
    constructor (property: string) {
      this.path = `/taskeditor/task_registry_entities/${property}`
    }
  }

  export class UpdateTaskRegistryEntities implements APIRequest<object> {
    response: object;
    path = `/taskeditor/task_registry_entities`;
    method = HTTPMethod.PUT;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    // eslint-disable-next-line no-useless-constructor
    constructor (public params: object) {}
  }
}
