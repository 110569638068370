
import { VueContext } from 'vue-context'
import TasksCountQuery from '@/services/DigitalTwinEditor/application/query/TasksCountQuery'
import TasksQuery from '@/services/DigitalTwinEditor/application/query/TasksQuery'

export default {
  name: 'TaskList',
  components: {
    VueContext
  },
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  data () {
    return {
        loading: false,
        tasks: [],
        tasksPageSize: 0,
        tasksCurrentPage: 0,
        tasksPageLimit: 100,
        tasksCount: 0,
        allTasksCount: 0
    }
  },
  methods: {
    loadTasks (callback) {
      if (this.allTasksCount === 0) {
        this.countAndLoadTasks(callback)
      } else {
        this.loadPageTasks(callback)
      }
    },
    async countAndLoadTasks (callback) {
      await this.getQueryBus().execute(
        new TasksCountQuery(this.prepareUrlQueryParams(true))
      ).then(data => {
        this.tasksCount = data[0].count
        this.loadPageTasks(callback)
      })
    },
    async loadPageTasks (callback) {
      await this.getQueryBus().execute(
        new TasksQuery(this.prepareUrlQueryParams())
      ).then(data => {
        this.tasksPageSize = this.tasksPageLimit
        this.tasks = data
        if (typeof callback === 'function') {
          callback()
        }
      })
    },
    handleTasksPageChange (val) {
      val--
      this.tasksCurrentPage = (val * this.tasksPageLimit)
      this.loadTasks()
    },
    prepareUrlQueryParams (forCount = false) {
      let params = {}
      if (!forCount) {
        params['limit'] = this.tasksPageLimit
        params['offset'] = this.tasksCurrentPage
      }
      return params
    },
    viewErrorMessage (scope) {
      this.$message({
        type: 'error',
        dangerouslyUseHTMLString: true,
        message: scope.row.error_message
      });
    },
    viewCommandPayload (scope) {
      this.$message({
        type: 'info',
        dangerouslyUseHTMLString: true,
        message: scope.row.command_payload
      });
    }
  },
  mounted () {
    this.loadTasks();
  }
}

