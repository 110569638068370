import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    NOTIFICATION_LOADING_EVENT = 'notification_loading_event',
    NOTIFICATION_LOADED_EVENT = 'notification_loaded_event',
    NOTIFICATION_CREATED_EVENT = 'notification_created_event',
    NOTIFICATION_UPDATED_EVENT = 'notification_updated_event',
    NOTIFICATION_DELETED_EVENT = 'notification_deleted_event'
}

export class NotificationLoadingEvent implements FluxStandardAction {
  type = MutationTypes.NOTIFICATION_LOADING_EVENT
}

export class NotificationLoadedEvent implements FluxStandardAction {
  type = MutationTypes.NOTIFICATION_LOADED_EVENT
}

export class NotificationCreatedEvent implements FluxStandardAction {
  type = MutationTypes.NOTIFICATION_CREATED_EVENT
  constructor (public location: string) {}
}

export class NotificationUpdatedEvent implements FluxStandardAction {
  type = MutationTypes.NOTIFICATION_UPDATED_EVENT
  constructor (public location: string) {}
}

export class NotificationDeletedEvent implements FluxStandardAction {
  type = MutationTypes.NOTIFICATION_DELETED_EVENT
  constructor (public id: number) {}
}
