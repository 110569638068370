import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ModelTreeElementCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/ModelTreeElementCommandRepositoryInterface'
import ModelTreeElementModelCreateCommand from '@/services/DigitalTwinEditor/application/command/ModelTreeElementModelCreateCommand'
import { ModelDTO } from '@/services/DigitalTwinEditor/domain/model/Model/Model'

export default class ModelTreeElementModelCreateHandler implements CommandHandlerInterface
{
    private repo: ModelTreeElementCommandRepositoryInterface;

    constructor(repo: ModelTreeElementCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: ModelTreeElementModelCreateCommand): Promise<void>
    {
        const item: ModelDTO = {
            name: command.getName(),
            alias: command.getAlias(),
            coordinate_system_id: command.getCoordinateSystem(),
            position: command.getPosition(),
            rotation: command.getRotation(),
            files: command.getFiles(),
            parent_id: command.getParentId()
        };
        return this.repo.insertModel(item);
    }
}