import { ActionTree } from 'vuex'
import { StateState } from './state'
import { ActionTypes } from './action-types'
import {
  MutationTypes,
  StateUpdatedEvent,
  StateCreatedEvent,
  StateLoadingEvent,
  StateDeletedEvent,
  StateLoadedEvent
} from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { StateAPI } from '@/services/LogicEditor/infrastructure/api/StateAPI'

export const actions: ActionTree<StateState, any> = {
  [ActionTypes.GET_STATE_BY_GUID]: async ({ commit, dispatch }, data) => {
    commit(new StateLoadingEvent())

    return APIClient.shared.request(new StateAPI.GetStateByGuid(data.guid))
      .then((response) => {
        commit(new StateLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.GET_STATE_OPERATION_TYPES]: async ({ commit, dispatch }, data) => {
    commit(new StateLoadingEvent())

    return APIClient.shared.request(new StateAPI.GetStateOperationTypes())
      .then((response) => {
        commit(new StateLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.GET_STATE_OPERATION_TYPE_ENTITY_TYPES]: async ({ commit, dispatch }, data) => {
    commit(new StateLoadingEvent())

    return APIClient.shared.request(new StateAPI.GetStateOperationTypeEntityTypes())
      .then((response) => {
        commit(new StateLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.GET_STATE_FIELD_TYPES]: async ({ commit, dispatch }, data) => {
    commit(new StateLoadingEvent())

    return APIClient.shared.request(new StateAPI.GetStateFieldTypes())
      .then((response) => {
        commit(new StateLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.GET_CONDITION_FIELD_TYPES]: async ({ commit, dispatch }, data) => {
    commit(new StateLoadingEvent())

    return APIClient.shared.request(new StateAPI.GetConditionFieldTypes())
      .then((response) => {
        commit(new StateLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.CREATE_STATE]: async ({ commit, dispatch }, data) => {
    commit(new StateLoadingEvent())

    return APIClient.shared.request(new StateAPI.CreateState(data.payload))
      .then((response) => {
        commit(new StateCreatedEvent(response))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.UPDATE_STATE]: async ({ commit, dispatch }, data) => {
    commit(new StateLoadingEvent())

    return APIClient.shared.request(new StateAPI.UpdateState(data.payload))
      .then((response) => {
        commit(new StateUpdatedEvent(response))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.DELETE_STATE]: async ({ commit, dispatch }, data) => {
    commit(new StateLoadingEvent())

    return APIClient.shared.request(new StateAPI.DeleteState(data.guid))
      .then((response) => {
        commit(new StateDeletedEvent(response))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  }
}
