<script>

import tabs from 'element-ui/packages/tabs/src/tabs.vue'

export default {
  extends: tabs,
  name: 'ElCollapsibleTabs',
  props: {
    collapseTo: {
      default: 'left',
      type: String
    },
    collapseEvent: {
      type: Function,
      default: () => { console.log('click!') }
    }
  },
  methods: {},
  render (h) {
    let {
      type,
      handleTabClick,
      handleTabRemove,
      handleTabAdd,
      currentName,
      panes,
      editable,
      addable,
      tabPosition,
      stretch,
      collapseTo,
      collapseEvent
    } = this
    const newButton = editable || addable
      ? (
        <span
          class="el-tabs__new-tab"
          on-click={ handleTabAdd }
          tabindex="0"
          on-keydown={ (ev) => { if (ev.keyCode === 13) { handleTabAdd() } } }
        >
          <i class="el-icon-plus"></i>
        </span>
      )
      : null
    const collapseButton = (
      <span
        class={`el-tabs__collapse-tab`}
        on-click={ collapseEvent }
        tabindex="0"
      >
        <i class={`el-icon-d-arrow-${collapseTo}`}></i>
      </span>
    )
    const navData = {
      props: {
        currentName,
        onTabClick: handleTabClick,
        onTabRemove: handleTabRemove,
        editable,
        type,
        panes,
        stretch
      },
      ref: 'nav'
    }
    const header = (
      <div class={['el-tabs__header', `is-${tabPosition}`]}>
        {newButton}{collapseButton}
        <tab-nav { ...navData }></tab-nav>
      </div>
    )
    const panels = (
      <div class="el-tabs__content">
        {this.$slots.default}
      </div>
    )
    return (
      <div class={{
        'el-tabs': true,
        'el-tabs--card': type === 'card',
        [`el-tabs--${tabPosition}`]: true,
        'el-tabs--border-card': type === 'border-card'
      }}>
        { tabPosition !== 'bottom' ? [header, panels] : [panels, header] }
      </div>
    )
  }
}
</script>