import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import OpenWorldCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/OpenWorldCommandRepositoryInterface'
import SetDefaultTexturesCommand from '@/services/DigitalTwinEditor/application/command/SetDefaultTexturesCommand'
import { OpenWorldDTO } from '@/services/DigitalTwinEditor/domain/model/OpenWorld/OpenWorld'

export default class SetDefaultTexturesHandler implements CommandHandlerInterface
{
    private repo: OpenWorldCommandRepositoryInterface;

    constructor(repo: OpenWorldCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: SetDefaultTexturesCommand): Promise<void>
    {
        let item: OpenWorldDTO = {
            guid: command.getGuid()
        };
        return this.repo.setDefaultTextures(item);
    }
}