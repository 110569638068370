import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    POINT_STYLE_IMAGE_LOADING_EVENT = 'point_style_image_loading_event',
    POINT_STYLE_IMAGE_LOADED_EVENT = 'point_style_image_loaded_event',
    POINT_STYLE_IMAGE_CREATED_EVENT = 'point_style_image_created_event',
    POINT_STYLE_IMAGE_UPDATED_EVENT = 'point_style_image_updated_event',
    POINT_STYLE_IMAGE_DELETED_EVENT = 'point_style_image_deleted_event' 
}

export class PointStyleImageLoadingEvent implements FluxStandardAction {
    type = MutationTypes.POINT_STYLE_IMAGE_LOADING_EVENT
}

export class PointStyleImageLoadedEvent implements FluxStandardAction {
    type = MutationTypes.POINT_STYLE_IMAGE_LOADED_EVENT
}

export class PointStyleImageCreatedEvent implements FluxStandardAction {
    type = MutationTypes.POINT_STYLE_IMAGE_CREATED_EVENT
    constructor(public location: string){}
}

export class PointStyleImageUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.POINT_STYLE_IMAGE_UPDATED_EVENT
}

export class PointStyleImageDeletedEvent implements FluxStandardAction {
    type = MutationTypes.POINT_STYLE_IMAGE_DELETED_EVENT
    constructor(public guid: string){}
}