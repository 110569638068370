import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { ConnectionDTO } from '@/services/AccessEditor/domain/model/Connection/Connection'


export namespace ConnectionAPI {

    export class AddConnection implements APIRequest<string> {
        response: string;
        path = '/v2/accesseditor/connections';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.headers.location
        constructor (public params: ConnectionDTO) {}
    }

    export class UpdateConnection implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor (public params: ConnectionDTO) {
          this.path = `/v2/accesseditor/connections/${params.guid}`
        }
    }

    export class DeleteConnection implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.DELETE;
        parse = (data: AxiosResponse) => data.data
        constructor (guid: string) {
          this.path = `/v2/accesseditor/connections/${guid}`
        }
    }

    export class GetConnections implements APIRequest<ConnectionDTO> {
        response: ConnectionDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor (payload: string[][]) {
          this.path = '/v2/accesseditor/connections?' + (new URLSearchParams(payload).toString())
        }
    }

    export class GetCountConnections implements APIRequest<ConnectionDTO> {
        response: ConnectionDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor (payload: string[][]) {
            this.path = '/v2/accesseditor/connections?*[func]=count&' + (new URLSearchParams(payload).toString())
        }
    }

    export class GetConnectionPropertirs implements APIRequest<ConnectionDTO> {
        response: ConnectionDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor (payload: string[][]) {
            this.path = '/v2/accesseditor/connections/properties?' + (new URLSearchParams(payload).toString())
        }
    }

}
