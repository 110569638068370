import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'
import TreeElementType from '../TreeElementType'
import Layer from '../Layer/Layer'
import OpenWorld from '../OpenWorld/OpenWorld'
import Model from '../Model/Model'
import Project from './Project'
import ProjectGroup from './ProjectGroup'

export class ProjectTreeElementDTO
{
    id?: Nullable<number>;
    guid?: Nullable<string>;
    name?: Nullable<string>;
    alias?: Nullable<string>;
    row_order?: Nullable<number>;
    parent_id?: Nullable<number>;
    layer_id?: Nullable<number>;
    open_world_id?: Nullable<number>;
    model_id?: Nullable<number>;
    project_id?: Nullable<number>;
    project_group_id?: Nullable<number>;
    element_type?: Nullable<string>;
    element_guid?: Nullable<string>;
    element_id?: Nullable<number>;

    constructor({ name, alias, layer_id, open_world_id, model_id, project_id, project_group_id, parent_id}: { name?: Nullable<string>, alias?: Nullable<string>, layer_id?: Nullable<number>, open_world_id?: Nullable<number>, model_id?: Nullable<number>, project_id?: Nullable<number>, project_group_id?: Nullable<number>, parent_id?: Nullable<number>}) {
        this.name = name;
        this.alias = alias;
        this.layer_id = layer_id;
        this.open_world_id = open_world_id;
        this.model_id = model_id;
        this.project_id = project_id;
        this.project_group_id = project_group_id;
        this.parent_id = parent_id;
    }
}

export default class ProjectTreeElement extends AbstractBaseEntity
{
    private id?: Nullable<number>;
    private guid?: Nullable<string>;
    private name?: Nullable<string>;
    private alias?: Nullable<string>;
    private rowOrder?: Nullable<number> = 0;
    private parentId?: Nullable<number>;
    private layerId?: Nullable<number>;
    private openWorldId?: Nullable<number>;
    private modelId?: Nullable<number>;
    private projectId?: Nullable<number>;
    private groupId?: Nullable<number>;
    private elementType?: Nullable<string>;
    private elementGuid?: Nullable<string>;
    private elementId?: Nullable<number>;
    private layer?: Nullable<Layer>;
    private openWorld?: Nullable<OpenWorld>;
    private model?: Nullable<Model>;
    private project?: Nullable<Project>;
    private group?: Nullable<ProjectGroup>;

    constructor(
        id?: Nullable<number>,
        guid?: Nullable<string>,
        name?: Nullable<string>,
        alias?: Nullable<string>,
        rowOrder?:Nullable<number>,
        parentId?: Nullable<number>,
        layerId?: Nullable<number>,
        openWorldId?: Nullable<number>,
        modelId?: Nullable<number>,
        projectId?: Nullable<number>,
        groupId?: Nullable<number>,
        elementType?: Nullable<string>,
        elementGuid?: Nullable<string>,
        elementId?: Nullable<number>
    ) {
        super();
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.alias = alias;
        this.rowOrder = rowOrder;
        this.parentId = parentId;
        this.layerId = layerId;
        this.openWorldId = openWorldId;
        this.modelId = modelId;
        this.layerId = layerId;
        this.projectId = projectId;
        this.groupId = groupId;
        this.elementType = elementType;
        this.elementGuid = elementGuid;
        this.elementId = elementId;
        if (elementType == TreeElementType.LAYER) {
            this.layer = Layer.create({id: elementId, guid: elementGuid, name: name, alias: alias, parent_id: parentId});
            this.openWorld = null;
            this.model = null;
            this.project = null;
            this.group = null;
        } else if (elementType == TreeElementType.OPEN_WORLD) {
            this.openWorld = OpenWorld.create({id: elementId, guid: elementGuid, name: name, alias: alias, parent_id: parentId});
            this.layer = null;
            this.model = null;
            this.project = null;
            this.group = null;
        } else if (elementType == TreeElementType.MODEL) {
            this.model = Model.create({id: elementId, guid: elementGuid, name: name, alias: alias, parent_id: parentId});
            this.layer = null;
            this.openWorld = null;
            this.project = null;
            this.group = null;
        } else if (elementType == TreeElementType.PROJECT) {
            this.project = Project.create({id: elementId, guid: elementGuid, name: name, alias: alias, parent_id: parentId});
            this.layer = null;
            this.openWorld = null;
            this.model = null;
            this.group = null;
        } else if (elementType == TreeElementType.PROJECT_GROUP) {
            this.group = ProjectGroup.create({id: elementId, guid: elementGuid, name: name, alias: alias, parent_id: parentId});
            this.layer = null;
            this.openWorld = null;
            this.model = null;
            this.project = null;
        }
    }

    static create(dto: ProjectTreeElementDTO): ProjectTreeElement
    {
        return new ProjectTreeElement(
            dto.id,
            dto.guid,
            dto.name,
            dto.alias,
            dto.row_order,
            dto.parent_id,
            dto.layer_id,
            dto.open_world_id,
            dto.model_id,
            dto.project_id,
            dto.project_group_id,
            dto.element_type,
            dto.element_guid,
            dto.element_id
        );
    }

    setAlias(alias: string): void
    {
        this.alias = alias;
    }

    getAlias(): string
    {
        return this.alias;
    }

    setName(name: string): void
    {
        this.name = name;
    }

    getName(): string
    {
        return this.name;
    }

    setId(id: number): void
    {
        this.id = id;
    }

    getId(): number
    {
        return this.id;
    }

    setGuid(guid: string): void
    {
        this.guid = guid;
    }

    getGuid(): string
    {
        return this.guid;
    }

    getElementGuid(): string
    {
        return this.elementGuid;
    }

    setRowOrder(rowOrder: number): void
    {
        this.rowOrder = rowOrder;
    }

    getRowOrder(): number
    {
        return this.rowOrder;
    }

    getLayer(): Nullable<Layer>
    {
        return this.layer;
    }

    getLayerId(): Nullable<number>
    {
        return this.layerId;
    }

    getOpenWorld(): Nullable<OpenWorld>
    {
        return this.openWorld;
    }

    getOpenWorldId(): Nullable<number>
    {
        return this.openWorldId;
    }

    getModel(): Nullable<Model>
    {
        return this.model;
    }

    getModelId(): Nullable<number>
    {
        return this.modelId;
    }

    getProject(): Nullable<Project>
    {
        return this.project;
    }

    getProjectId(): Nullable<number>
    {
        return this.projectId;
    }

    getGroupId(): Nullable<number>
    {
        return this.groupId;
    }

    getGroup(): Nullable<ProjectGroup>
    {
        return this.group;
    }

    getParentId(): Nullable<number>
    {
        return this.parentId;
    }

    getElementType(): string
    {
        return this.elementType;
    }
}