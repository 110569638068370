
import { VueContext } from 'vue-context'
import TexturesCountQuery from '@/services/DigitalTwinEditor/application/query/TexturesCountQuery'
import TexturesQuery from '@/services/DigitalTwinEditor/application/query/TexturesQuery'

export default {
  name: 'TextureList',
  components: {
    VueContext
  },
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  data () {
    return {
        loading: false,
        textures: [],
        texturesPageSize: 0,
        texturesCurrentPage: 0,
        texturesPageLimit: 100,
        texturesCount: 0,
        allTexturesCount: 0
    }
  },
  methods: {
    loadTextures (callback) {
      if (this.allTexturesCount === 0) {
        this.countAndLoadTextures(callback)
      } else {
        this.loadPageTextures(callback)
      }
    },
    async countAndLoadTextures (callback) {
      await this.getQueryBus().execute(
        new TexturesCountQuery(this.prepareUrlQueryParams(true))
      ).then(data => {
        this.texturesCount = data[0].count
        this.loadPageTextures(callback)
      })
    },
    async loadPageTextures (callback) {
      await this.getQueryBus().execute(
        new TexturesQuery(this.prepareUrlQueryParams())
      ).then(data => {
        this.texturesPageSize = this.texturesPageLimit
        this.textures = data
        if (typeof callback === 'function') {
          callback()
        }
      })
    },
    handleTexturesPageChange (val) {
      val--
      this.texturesCurrentPage = (val * this.texturesPageLimit)
      this.loadTextures()
    },
    prepareUrlQueryParams (forCount = false) {
      let params = {}
      if (!forCount) {
        params['limit'] = this.texturesPageLimit
        params['offset'] = this.texturesCurrentPage
      }
      return params
    },
    viewErrorMessage (scope) {
      this.$message({
        type: 'error',
        dangerouslyUseHTMLString: true,
        message: scope.row.error_message
      });
    },
    viewCommandPayload (scope) {
      this.$message({
        type: 'info',
        dangerouslyUseHTMLString: true,
        message: scope.row.command_payload
      });
    }
  },
  mounted () {
    this.loadTextures();
  }
}

