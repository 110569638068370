import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    MODEL_GROUP_LOADING_EVENT = 'model_group_loading_event',
    MODEL_GROUP_LOADED_EVENT = 'model_group_loaded_event',
    MODEL_GROUP_UPDATED_EVENT = 'model_group_updated_event'
}

export class ModelGroupLoadingEvent implements FluxStandardAction {
    type = MutationTypes.MODEL_GROUP_LOADING_EVENT
}

export class ModelGroupLoadedEvent implements FluxStandardAction {
    type = MutationTypes.MODEL_GROUP_LOADED_EVENT
}

export class ModelGroupUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.MODEL_GROUP_UPDATED_EVENT
}
