export interface ICounter {
  count: number
}

export default class CounterDTO {
  count: number

  constructor (data: ICounter) {
    this.count = data.count
  }
}
