import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { LayerDTO } from '@/services/DigitalTwinEditor/domain/model/Layer/Layer'

export enum ActionTypes {
    UPDATE_LAYER = 'update_layer',
    GET_LAYER_BY_GUID = 'get_layer_by_guid'
}

export class UpdateLayer implements FluxStandardAction {
    type = 'DigitalTwinLayer/' + ActionTypes.UPDATE_LAYER
    constructor(public payload: LayerDTO){}
}

export class GetLayerByGuid implements FluxStandardAction {
    type = 'DigitalTwinLayer/' + ActionTypes.GET_LAYER_BY_GUID
    constructor(public guid: string){}
}
