import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'

export namespace AddressAPI {

  export class GetAddresses implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (payload: string[][]) {
      this.path = '/fiasaddressservice/addresses?' + (new URLSearchParams(payload).toString())
    }
  }
}
