<template>
  <el-checkbox
    v-bind:value="typeof value === 'string' ? JSON.parse(value) : value"
    v-on:input="$emit('input', $event)"
    :label="`Да / Нет`"
  ></el-checkbox>
</template>

<script>
export default {
  name: 'FlagInput',

  props: {
    value: Boolean,

    placeholder: String
  }
}
</script>

<style type="text/css"></style>
