import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import { GetRegions } from '@/services/AddressService/infrastructure/store/modules/Region/action-types'
import RegionQueryRepositoryInterface from '@/services/AddressService/domain/repository/RegionQueryRepositoryInterface'

export default class RegionQueryRepository extends AbstractQueryRepository implements RegionQueryRepositoryInterface {
  get (payload: string[][]): Promise<any> {
    return store.dispatch(new GetRegions(payload))
  }
}
