
import { VueContext } from 'vue-context'
import MapManager from '@bingo_soft/mapmanager'
import SourceType from '@bingo_soft/mapmanager/src/Domain/Model/Source/SourceType'
import FeatureCollection from '@bingo_soft/mapmanager/src/Domain/Model/Feature/FeatureCollection'
import Treeselect from '@bingosoftnn/vue-treeselect'
import SetDefaultTexturesCommand from '@/services/DigitalTwinEditor/application/command/SetDefaultTexturesCommand'
import SetTerrainCommand from '@/services/DigitalTwinEditor/application/command/SetTerrainCommand'
import StopTilingCommand from '@/services/DigitalTwinEditor/application/command/StopTilingCommand'
import SetLandscapeCommand from '@/services/DigitalTwinEditor/application/command/SetLandscapeCommand'
import LandscapeTexturesQuery from '@/services/DigitalTwinEditor/application/query/LandscapeTexturesQuery'
import LandscapeTexturesCountQuery from '@/services/DigitalTwinEditor/application/query/LandscapeTexturesCountQuery'
import OpenWorldGeojsonQuery from '@/services/DigitalTwinEditor/application/query/OpenWorldGeojsonQuery'
import CesiumViewer from './CesiumViewer.vue'

export default {
  name: 'OpenWorldForm',
  components: {
    VueContext,
    Treeselect,
    CesiumViewer
  },
  props: ['value'],
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  watch: {
    getBuildLandscapeError (err) {
      if (err.status == 423) {
        this.$message({
          message: this.$locale.digitaltwin_editor.messages[err.message],
          type: 'warning'
        })
      }
    },
    currentStyle (val) {
      console.log("style to edit ", val);
    }
  },
  computed: {
    dto: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }      
    },
    getBuildLandscapeError () {
      return this.$store.getters['OpenWorld/getError'];
    }
  },
  data () {
    return {
      element_type: "layer",
      formRules: {
        name: {
          required: true,
          message: this.$locale.digitaltwin_editor.errors.name_required,
          trigger: 'input'
        }
      },
      coordinate_systems: [{
        id: 3857,
        label: 'EPSG 3857'
      },{
        id: 4326,
        label: 'EPSG 4326'
      }],
      heightmaps: [{
        id: 'fabdem',
        label: `${this.$locale.digitaltwin_editor.project_panel.heightmap} FABDEM`
      },{
        id: 'asterdem',
        label: `${this.$locale.digitaltwin_editor.project_panel.heightmap} ASTERDEM`
      }],
      styles: [{
        id: 'addressing',
        label: 'addressing.mss'
      },{
        id: 'admin',
        label: 'admin.mss'
      },{
        id: 'aerialways',
        label: 'aerialways.mss'
      },{
        id: 'amenity-points',
        label: 'amenity-points.mss'
      },{
        id: 'buildings',
        label: 'buildings.mss'
      },{
        id: 'ferry-routes',
        label: 'ferry-routes.mss'
      },{
        id: 'fonts',
        label: 'fonts.mss'
      },{
        id: 'golf',
        label: 'golf.mss'
      },{
        id: 'landcover',
        label: 'landcover.mss'
      },{
        id: 'placenames',
        label: 'placenames.mss'
      },{
        id: 'power',
        label: 'power.mss'
      },{
        id: 'road-colors-generated',
        label: 'road-colors-generated.mss'
      },{
        id: 'roads',
        label: 'roads.mss'
      },{
        id: 'shapefiles',
        label: 'shapefiles.mss'
      },{
        id: 'stations',
        label: 'stations.mss'
      },{
        id: 'style',
        label: 'style.mss'
      },{
        id: 'tourism',
        label: 'tourism.mss'
      },{
        id: 'water',
        label: 'water.mss'
      },{
        id: 'water-features',
        label: 'water-features.mss'
      }],
      currentStyle: null,
      stylesVisible: false,
      textures: [],
      landscapeTexturesPageSize: 0,
      landscapeTexturesCurrentPage: 0,
      landscapeTexturesPageLimit: 100,
      landscapeTexturesCount: 0,
      texturesLeftSize: 100,
      texturesRightSize: 0,
      map: null,
      terrainVisible: false,
      buildTileVisible: false,
      tileDto: {
        min_zoom: 3,
        max_zoom: 5,
        force_tiling: false,
        num_threads: 1
      }
    }
  },
  methods: {
    addBboxPoint () {
      this.dto.borders_bbox.push([0, 0]);
    },
    removeBboxPoint (scope) {
      this.dto.borders_bbox.splice(scope.$index, 1);
    },
    setDefaultTextures () {
      this.$confirm(this.$locale.digitaltwin_editor.messages.confirm_setting_default_textures, this.$locale.main.message.attention, {
        confirmButtonText: this.$locale.main.button.ok,
        cancelButtonText: this.$locale.main.button.cancel,
        type: 'warning'
      }).then(async () => {
        this.getCommandBus().execute(
          new SetDefaultTexturesCommand(
            this.dto.guid
          )
        ).then((response) => {
          this.loadLandscapeTextures();
        });
      });
    },
    loadLandscapeTextures (callback) {
      if (this.landscapeTexturesCount === 0) {
        this.countAndLoadLandscapeTextures(callback)
      } else {
        this.loadPageLandscapeTextures(callback)
      }
    },
    async countAndLoadLandscapeTextures (callback) {
      await this.getQueryBus().execute(
        new LandscapeTexturesCountQuery(this.prepareLandscapeTexturesUrlQueryParams(true))
      ).then(data => {
        this.landscapeTexturesCount = data[0].count;
        this.loadPageLandscapeTextures(callback);
      });
    },
    async loadPageLandscapeTextures (callback) {
      await this.getQueryBus().execute(
        new LandscapeTexturesQuery(this.prepareLandscapeTexturesUrlQueryParams())
      ).then(data => {
        this.landscapeTexturesPageSize = this.landscapeTexturesPageLimit;
        this.textures = data;
        if (typeof callback === 'function') {
          callback()
        }
      })
    },
    handleLandscapeTexturesPageChange (val) {
      val--;
      this.landscapeTexturesCurrentPage = (val * this.landscapeTexturesPageLimit);
      this.loadLandscapeTextures();
    },
    prepareLandscapeTexturesUrlQueryParams (forCount = false) {
      let params = {}
      params['open_world_guid'] = this.dto.guid;
      if (!forCount) {
        params['limit'] = this.landscapeTexturesPageLimit
        params['offset'] = this.landscapeTexturesCurrentPage
      }
      return params
    },
    showMap () {
      let loadGeojson = false;
      if (this.texturesRightSize == 0) {
        this.texturesLeftSize = 50;
        this.texturesRightSize = 50;
        loadGeojson = true;
      } else {
        this.texturesLeftSize = 100;
        this.texturesRightSize = 0;
      }
      if (this.map == null) {
        let optsMap = {
          base_layer: 'osm',
          declared_coordinate_system_id: 3857,
          controls: []
        }
        this.$nextTick(() => {
          this.map = MapManager.createMap(this.dto.guid, optsMap);
          MapManager.fitFeatures(this.map, FeatureCollection.createFromGeoJSON(this.dto.buffer_geojson, 4326, 3857));
          this.updateMapSize();
          this.loadGeojson();
        });
      } else if (loadGeojson) {
        this.loadGeojson();
      }
    },
    async loadGeojson() {
      await this.getQueryBus().execute(
        new OpenWorldGeojsonQuery(this.dto)
      ).then(data => {
        const layer = MapManager.createLayerFromGeoJSON(
          JSON.stringify(data), {
            srs_handling: {
              native_coordinate_system_id: 3857,
              declared_coordinate_system_id: 3857,
              srs_handling_type: "forced_declared"
            },
            style: {
              style_in_feature_attribute: true,
              style_attribute: "texture_path"
            }
          }
        );
        MapManager.addLayer(this.map, layer);
      })
    },
    handleFeatureSelection(data, node) {
      console.log(data, node);
    },
    updateMapSize() {
      if (this.map !== null) {
        this.$nextTick(() => {
          MapManager.updateSize(this.map);
        });
      }
    },
    buildTerrain() {
      if (this.dto.heightmap !== null) {
        this.$confirm(this.$locale.digitaltwin_editor.messages.confirm_setting_terrain, this.$locale.main.message.attention, {
          confirmButtonText: this.$locale.main.button.ok,
          cancelButtonText: this.$locale.main.button.cancel,
          type: 'warning'
        }).then(async () => {
          this.getCommandBus().execute(
            new SetTerrainCommand(
              this.dto.guid
            )
          ).then((response) => {
            
          });
        });
      }
    },
    buildLandscape() {      
      this.tileDto = {
        min_zoom: 3,
        max_zoom: 5,
        force_tiling: false,
        apply_new_styles: false,
        num_threads: 1,
      };
      this.buildTileVisible = true;
    },
    runBuildTile() {
      this.getCommandBus().execute(
        new SetLandscapeCommand(
          this.dto.guid,
          this.tileDto.min_zoom,
          this.tileDto.max_zoom,
          this.tileDto.num_threads,
          this.tileDto.force_tiling,
          this.tileDto.apply_new_styles          
        )
      ).then((response) => {
        this.buildTileVisible = false;
      });
    },
    showTerrain() {
      this.terrainVisible = !this.terrainVisible;
      if (this.terrainVisible) {
      }
    },
    stopTilingOperation(command) {
      this.$confirm(this.$locale.digitaltwin_editor.messages.confirm_stop_tiling_operation, this.$locale.main.message.attention, {
        confirmButtonText: this.$locale.main.button.ok,
        cancelButtonText: this.$locale.main.button.cancel,
        type: 'warning'
      }).then(async () => {
        this.getCommandBus().execute(
          new StopTilingCommand(
            this.dto.guid,
            command
          )
        ).then((response) => {
          
        });
      });
    },
    editStyles() {
      this.stylesVisible = true;
    },
    editStyle(scope) {
      this.stylesVisible = false;
      let styleDto = {
        id: scope.row.id,
        name: scope.row.label,
        guid: this.dto.guid + "-" + scope.row.id,
        element_type: 'carto_style',
        open_world_guid: this.dto.guid
      };
      this.getEventBus().$emit('openEditForm', styleDto);
    }
  },
  mounted () {
    //this.loadLandscapeTextures();
  }
}

