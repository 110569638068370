<template>
  <el-dialog
    :visible.sync="showDialog"
    :append-to-body="true"
    title="Редактирование правила"
  >
    <el-form size="mini" style="padding: 10px" class="form">
      <el-form-item label="Наименование">
        <el-input type="text" v-model="value.name"></el-input>
      </el-form-item>
      <el-form-item label="Условия">
        <query-builder
          ref="queryBuilder"
          v-if="sourceType === 'Registry' && queryBuilderSettings.fields.length > 0"
          v-model="value.rule"
          :field-type-to-operation-type="queryBuilderSettings.stateOperationTypeEntityTypes"
          :operation-types="queryBuilderSettings.operationTypes"
          :fields="queryBuilderSettings.fields"
          @changeScript="scriptChanged"
          @changeQuery="queryChanged"
          :key="value.guid"
        ></query-builder>
        <el-input
          v-else-if="sourceType === 'WFS'"
          v-model="value.script"
        ></el-input>
      </el-form-item>
      <div style="padding: 10px">
        <el-radio-group v-model="activeStyleType" size="small" style="padding: 0 0 20px 0;">
          <el-radio-button v-for="type in styleTypes"
                           :key="type.id"
                           :label="type.id">
            {{ type.name }}
          </el-radio-button>
        </el-radio-group>
        <style-form
          v-model="value.style[activeStyleType]"
          :mini="true"
          :type="activeStyleType"
          :key="`${value.guid}_${activeStyleType}`"></style-form>
      </div>

    </el-form>
  </el-dialog>
</template>

<script>
import StyleForm from '@/services/MapEditor/infrastructure/components/StyleForm/index.vue'
import QueryBuilder from '@/components/Common/QueryBuilder/index.vue'

export default {
  name: 'RuleConfig',
  components: {
    StyleForm,
    QueryBuilder
  },
  props: {
    value: {
      type: Object
    },
    queryBuilderSettings: {
      type: Object,
      required: true
    },
    sourceType: {
      type: String
    }
  },
  data: function () {
    return {
      activeStyleType: 'point',
      styleTypes: [
        { id: 'point', name: 'Точка' },
        { id: 'linestring', name: 'Линия' },
        { id: 'polygon', name: 'Полигон' },
        { id: 'label', name: 'Лэйбл' }
      ],
      showDialog: false
    }
  },
  methods: {
    showRuleConfig () {
      this.showDialog = true
    },
    scriptChanged (newValue) {
      this.value.script = newValue
    },
    queryChanged (newValue) {
      this.value.query = newValue
    }
  }
}
</script>

<style scoped>
  .form /deep/ .el-form-item__label {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    color: #7D7D7D;
    display: block;
    padding: 5px;
  }
</style>
