import { GetterTree } from 'vuex'
import { NotificationState } from './state'

export const getters: GetterTree<NotificationState, any> = {
  isNotificationLoading: state => state.isLoading,
  getLocation: state => state.location,
  getDeletedNotificationId: state => state.deletedNotificationId,
  getData: state => state.data,
  getError: state => state.error
}
