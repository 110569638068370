import { ActionTree } from 'vuex'
import { RequestState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, RequestLoadedEvent, RequestLoadingEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { RequestAPI } from '@/services/ApiService/infrastructure/api/RequestAPI'
import { SyncAPI } from '@/services/ApiService/infrastructure/api/SynctAPI'

export const actions: ActionTree<RequestState, any> = {
  [ActionTypes.GET_REQUESTS]: async ({ commit, dispatch }, data) => {
    try {
      commit(new RequestLoadingEvent())
      let res = await APIClient.shared.request(new RequestAPI.GetRequests(data.payload))
      commit(new RequestLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.CREATE_REQUEST]: async ({ commit, dispatch }, data) => {
    try {
      commit(new RequestLoadingEvent())
      let res = await APIClient.shared.request(new RequestAPI.CreateRequest(data.payload))
      commit(new RequestLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.DELETE_REQUEST]: async ({ commit, dispatch }, data) => {
    try {
      commit(new RequestLoadingEvent())
      let res = await APIClient.shared.request(new RequestAPI.DeleteRequest(data.guid))
      commit(new RequestLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_REQUEST_SCHEME_ENTITIES]: async ({ commit, dispatch }, data) => {
    try {
      commit(new RequestLoadingEvent())
      let res = await APIClient.shared.request(new RequestAPI.GetRequestSchemaEntities())
      commit(new RequestLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.UPDATE_REQUEST_ENTITY]: async ({ commit, dispatch }, data) => {
    try {
      commit(new RequestLoadingEvent())
      let res = await APIClient.shared.request(new RequestAPI.UpdateRequestEntity(data.payload, data.entityType))
      commit(new RequestLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_REQUEST_PARAMETERS]: async ({ commit, dispatch }, data) => {
    try {
      commit(new RequestLoadingEvent())
      let res = await APIClient.shared.request(new RequestAPI.GetRequestParameters(data.guid))
      commit(new RequestLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.CREATE_REQUEST_PARAMETER]: async ({ commit, dispatch }, data) => {
    try {
      commit(new RequestLoadingEvent())
      let res = await APIClient.shared.request(new RequestAPI.CreateRequestParameter(data.payload, data.requestGuid))
      commit(new RequestLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.DELETE_REQUEST_PARAMETER]: async ({ commit, dispatch }, data) => {
    try {
      commit(new RequestLoadingEvent())
      let res = await APIClient.shared.request(new RequestAPI.DeleteRequestParameter(data.guid))
      commit(new RequestLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.UPDATE_REQUEST_PARAMETER]: async ({ commit, dispatch }, data) => {
    try {
      commit(new RequestLoadingEvent())
      let res = await APIClient.shared.request(new RequestAPI.UpdateRequestParameter(data.payload))
      commit(new RequestLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_EXPORT_STRUCTURE]: async ({ commit, dispatch }, data) => {
    try {
      commit(new RequestLoadingEvent())
      let res = await APIClient.shared.request(new SyncAPI.GetExportStructure(data.id))
      commit(new RequestLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.IMPORT_STRUCTURE]: async ({ commit, dispatch }, data) => {
    try {
      commit(new RequestLoadingEvent())
      let res = await APIClient.shared.request(new RequestAPI.ImportStructure(data.payload))
      commit(new RequestLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  }
}
