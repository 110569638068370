<template>
  <div>
    <el-button type="text" @click="dialogSettingsVisible = true">{{ $locale.main.button.edit }}</el-button>
    <el-dialog
      :title="$locale.interface_editor.show_button.title"
      :modal="false"
      :visible.sync="dialogSettingsVisible">
      <div v-for="(value, name, index) in buttonVisibleSettings" :key="index">
        <el-checkbox v-model="buttonVisibleSettings[name]"><i :class="iconBtn[name]"></i> {{$locale.interface_editor.show_button.name_btn[name]}}</el-checkbox>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'editor-show-button',
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      dialogSettingsVisible: false,
      buttonVisibleSettings: {},
      defaultSetting: {
        update: false,
        add: false,
        add_existing: false,
        delete: false,
        export: false,
        import: false,
        views: false,
        group: false,
        filter: false,
        edit: false,
        detach: false
      },
      iconBtn: {
        update: 'el-icon-refresh-right',
        add: 'el-icon-plus',
        add_existing: 'el-icon-finished',
        delete: 'el-icon-delete',
        detach: 'el-icon-scissors',
        export: 'el-icon-upload2',
        import: 'el-icon-download',
        views: 'el-icon-s-grid',
        group: 'el-icon-s-operation',
        filter: 'el-icon-arrow-down',
        edit: 'el-icon-edit'
      }
    }
  },
  watch: {
    dialogSettingsVisible: {
      handler (val) {
        if (!val) this.$emit('change', this.buttonVisibleSettings)
      }
    }
  },
  mounted () {
    this.value ? this.buttonVisibleSettings = { ...this.defaultSetting, ...this.value } : this.buttonVisibleSettings = this.defaultSetting
  }
}
</script>

<style>

</style>
