<template>
  <div>
    <el-button type="text" @click="dialogSettingsVisible = true">{{ $locale.main.button.edit }}</el-button>
    <el-dialog
      :title="$locale.interface_editor.component_editor.editor_title_series.edit_series"
      :modal="false"
      :width="'35%'"
      :visible.sync="dialogSettingsVisible">
      <el-button size="small" style="display: block" @click="addSeries">{{$locale.interface_editor.component_editor.editor_title_series.add_series}}</el-button>
      <div style="height: 450px; overflow-y: auto" class="custom_scrollbar">
        <div v-for="(item, index) in series" :key="index" class="main_block">
          <el-form label-position="top" label-width="100px">
            <el-form-item :label="$locale.interface_editor.component_editor.editor_title_series.name">
              <el-input type="text" size="small" v-model="item.name"></el-input>
            </el-form-item>
            <el-form-item v-if="options.type === 'column'" :label="$locale.interface_editor.component_editor.editor_title_series.type" >
              <el-select v-model="item.type" v-if="options.type === 'column'" size="small">
                <el-option
                  v-for="(item, index) in listTypeChart"
                  :key="index"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$locale.interface_editor.component_editor.editor_title_series.opacity" v-if="options.type === 'column' && item.type === 'area'">
                <el-input-number
                  v-model="item.opacity"
                  :min="0" :max="1"
                  :step="0.1"
                  size="small">
                </el-input-number>
              </el-form-item>
            <el-form-item :label="$locale.interface_editor.component_editor.editor_title_series.field">
              <el-select
                v-if="typeSource ==='extend_object'"
                v-model="item.field"
                size="small"
                value-key="name"
                filterable>
                <el-option
                  v-for="(item, index) in options.filedExtendObject"
                  :key="index"
                  :label="item.name"
                  :value="item">
                </el-option>
              </el-select>
              <el-input v-if="typeSource ==='requests'" type="text" size="small" v-model="item.fieldRequest"></el-input>
              </el-form-item>
            <el-form-item :label="$locale.interface_editor.component_editor.editor_title_series.categorie" >
              <el-select
                v-if="typeSource ==='extend_object'"
                v-model="item.category"
                size="small"
                value-key="name"
                clearable
                filterable>
                <el-option
                  v-for="(item, index) in options.filedExtendObject"
                  :key="index"
                  :label="item.name"
                  :value="item">
                </el-option>
              </el-select>
              <el-input v-if="typeSource ==='requests'" type="text" size="small" v-model="item.category.name"></el-input>
            </el-form-item>
            <el-form-item
            :label="!item.category.id ? $locale.interface_editor.component_editor.editor_title_series.color : $locale.interface_editor.component_editor.editor_title_series.color_category"
            :label-width="!item.category.id ? '120px' : '125px'">
              <div style="display: flex; align-items: center;">
              <colorEdit :value='item.colorCategory' @change="item.colorCategory = $event" v-show="!!item.category.id"/>
                <template v-if="!item.category.id" >
                  <el-color-picker class="color_picker" v-model="item.color" ></el-color-picker >
                  <el-input type="text" size="small" style="width: 50%" v-model="item.color" placeholder="#19D29A"></el-input>
                </template>
                <el-button style="margin-left: auto" @click="deleteSeries(index)" type="danger" size="small">
                  {{$locale.interface_editor.component_editor.editor_title_series.remove}}
                </el-button>
              </div>
            </el-form-item>
            </el-form>
          </div>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import colorEdit from './editor-color-donutchart.vue'
export default {
  name: 'editor-series-donutchart',
  // props: ['value', 'options'],
  props: {
    value: {},
    options: {
      type: Object,
      default () {
        return {}
      }
    },
    typeSource: {
      type: String,
      default: ''
    }
  },
  components: {
    colorEdit
  },
  data () {
    return {
      series: [],
      showCategory: false,
      dialogSettingsVisible: false,
      listTypeChart: [
        { id: 'line', name: 'Линия' },
        { id: 'column', name: 'Столбец' },
        { id: 'area', name: 'С областями' }
      ]
    }
  },
  methods: {
    addSeries () {
      this.series.push({
        field: '',
        fieldRequest: '',
        name: '',
        color: '',
        colorCategory: ['#69B3E7', '#3F95DA', '#0977CB', '#0058B9', '#0039A3', '#001489'],
        type: null,
        opacity: 1,
        category: {
          id: null,
          name: null
        }
      })
    },
    deleteSeries (index) {
      this.series.splice(index, 1)
    }
  },
  computed: {
    seriesEdit () {
      return this.series
        .map((el) => {
          return {
            name: el.name,
            color: el.color,
            type: el.type,
            opacity: el.opacity,
            field: {
              id: el.field.id,
              name: el.field.name
            },
            fieldRequest: el.fieldRequest,
            category: {
              id: el.category.id,
              name: el.category.name,
              color: el.colorCategory
            }
          }
        })
    }
  },
  watch: {
    dialogSettingsVisible: {
      handler (value) {
        if (!value) {
          this.$emit('change', this.seriesEdit)
        }
      }
    },
    'options.extendObject': {
      handler (val, old) {
        this.series = []
      }
    }
  },
  mounted () {
    if (this.value) {
      this.series = this.value
      for (let i = 0; i < this.series.length; i++) {
        this.$set(this.series[i], 'colorCategory', this.series[i].category.color)
      }
    }
  }
}
</script>

<style scoped>
.block {
  display: inline-flex;
  align-items: center;
  height: 40px;
}
.color_picker {
  margin-right: 10px;
}
.button_block {
  display: block;
  margin-bottom: 10px;
}
.color_picker__remove {
  margin-left: 10px;
  border: none;
  /* display: none; */
}
.main_block {
  margin-top: 10px;
  border-bottom: 2px solid rgb(0, 0, 0);
}
/* .main_block:hover .color_picker__remove {
  display: block;
} */
</style>
