import PointStyleImageCommandRepositoryInterface from '@/core/domain/repository/modules/PointStyleImageCommandRepositoryInterface'
import { PointStyleImageDTO } from '@/core/domain/model/modules/PointStyleImage'
import store from '@/store'
import { AddPointStyleImage, UpdatePointStyleImage, DeletePointStyleImage } from '@/core/infrastructure/store/modules/PointStyleImage/action-types'

export default class PointStyleImageCommandRepository implements PointStyleImageCommandRepositoryInterface
{
    insert(item: PointStyleImageDTO): Promise<void>
    {
        return store.dispatch(new AddPointStyleImage(item));
    }

    update(item: PointStyleImageDTO): Promise<void>
    {
        return store.dispatch(new UpdatePointStyleImage(item));
    }

    delete(guid: string): Promise<void>
    {
        return store.dispatch(new DeletePointStyleImage(guid));
    }
}
