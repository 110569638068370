import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    PLUGIN_LOADING_EVENT = 'plugin_loading_event',
    PLUGIN_LOADED_EVENT = 'plugin_loaded_event'
}

export class PluginLoadingEvent implements FluxStandardAction {
  type = MutationTypes.PLUGIN_LOADING_EVENT
}

export class PluginLoadedEvent implements FluxStandardAction {
  type = MutationTypes.PLUGIN_LOADED_EVENT
}
