import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { FormulaDTO } from '@/services/LogicEditor/domain/model/Formula'

export namespace FormulaAPI {
  export class GetFormulaByGuid implements APIRequest<FormulaDTO> {
    response: FormulaDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => new FormulaDTO(data.data)
    constructor (guid: string) {
      this.path = `/v2/logiceditor/formulas/${guid}`
    }
  }

  export class CreateFormula implements APIRequest<string> {
    response: string;
    path = '/v2/logiceditor/formulas';
    method = HTTPMethod.POST;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.headers.location
    constructor (public params: FormulaDTO) {}
  }

  export class UpdateFormula implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.PUT;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: FormulaDTO) {
      this.path = `/v2/logiceditor/formulas/${params.guid}`
    }
  }

  export class DeleteFormula implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.DELETE;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/logiceditor/formulas/${guid}`
    }
  }
}
