export interface TelegramChatState {
  isLoading: boolean;
  error: object;
}

export const initialState = (): TelegramChatState => {
  return {
    isLoading: false,
    error: null
  }
}
