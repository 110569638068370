import { ActionTree } from 'vuex'
import { ApprovalStageState, initialState } from './state'
import { ActionTypes } from './action-types'
import {
  MutationTypes,
  ApprovalStageUpdatedEvent,
  ApprovalStageCreatedEvent,
  ApprovalStageLoadingEvent,
  ApprovalStageDeletedEvent,
  ApprovalStageLoadedEvent
} from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { ApprovalStageAPI } from '@/services/LogicEditor/infrastructure/api/ApprovalStageAPI'

export const actions: ActionTree<ApprovalStageState, any> = {
  [ActionTypes.GET_APPROVAL_STAGE_BY_GUID]: async ({ commit, dispatch }, data) => {
    commit(new ApprovalStageLoadingEvent())

    return APIClient.shared.request(new ApprovalStageAPI.GetApprovalStageByGuid(data.guid))
      .then((response) => {
        commit(new ApprovalStageLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.CREATE_APPROVAL_STAGE]: async ({ commit, dispatch }, data) => {
    commit(new ApprovalStageLoadingEvent())

    return APIClient.shared.request(new ApprovalStageAPI.CreateApprovalStage(data.payload))
      .then((location) => {
        commit(new ApprovalStageCreatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.UPDATE_APPROVAL_STAGE]: async ({ commit, dispatch }, data) => {
    commit(new ApprovalStageLoadingEvent())

    return APIClient.shared.request(new ApprovalStageAPI.UpdateApprovalStage(data.payload))
      .then((location) => {
        commit(new ApprovalStageUpdatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.DELETE_APPROVAL_STAGE]: async ({ commit, dispatch }, data) => {
    commit(new ApprovalStageLoadingEvent())

    return APIClient.shared.request(new ApprovalStageAPI.DeleteApprovalStage(data.guid))
      .then(() => {
        commit(new ApprovalStageDeletedEvent(data.guid))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  }
}
