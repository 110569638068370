<template>
  <el-row style="margin-bottom: 5px" class="editor-options">
    <span class="property_label" style="display: inline-block;">{{title || $t(`interface_editor.component_editor.options.option`)}}</span>
    <el-button
      style="float: right"
      @click="addOption"
    >{{$t(`interface_editor.component_editor.options.add_option`)}}</el-button>
    <template v-if="type === 'basic' || type === 'button'">
      <el-row v-for="(condition, index) in value" :key="index" class="condition">
        <el-col :span="7">
          <el-tooltip placement="left-end" :content="$t(`interface_editor.name`)" effect="dark">
            <el-input v-model="condition.name"></el-input>
          </el-tooltip>
          <el-checkbox v-if="isCheckbox" v-model="condition.default">{{$t(`interface_editor.default_check`)}}</el-checkbox>
        </el-col>
        <el-col :span="12">
          <el-tooltip placement="left-end" effect="dark" :content="$t(`interface_editor.options_edit_user`)">
            <el-input v-model="condition.value"></el-input>
          </el-tooltip>
        </el-col>
        <el-col :span="5" style="float: right;">
          <el-button @click="deleteOption(index)" size="mini" type="danger">
            <i class="el-icon-delete"></i>
          </el-button>
        </el-col>
      </el-row>
    </template>
    <template v-else-if="type === 'lights'">
      <el-row v-for="(condition, index) in value" :key="index" class="condition">
        <el-col :span="15">
          <el-input v-model="condition.value" :placeholder="$t(`interface_editor.value`)"></el-input>
        </el-col>
        <el-col :span="15">
          <div style="display: flex;">
            <el-input v-model="condition.color" :placeholder="$t(`interface_editor.component_editor.options.color`)"></el-input>
            <el-color-picker v-model="condition.color" :title="$t(`interface_editor.component_editor.options.color`)"></el-color-picker>
           </div>
        </el-col>
        <el-col :span="15">
          <el-input v-model="condition.tooltip" :placeholder="$t(`interface_editor.component_editor.options.tooltip`)"></el-input>
        </el-col>
        <el-col :span="2" :offset="3">
          <el-button @click="deleteOption(index)" size="mini" type="danger" :title="$t(`interface_editor.component_editor.options.remove`)">
            <i class="el-icon-delete"></i>
          </el-button>
        </el-col>
      </el-row>
    </template>
  </el-row>
</template>

<script>
// Компонент используется в a-radio, a-checkbox
// TODO разделить этот компонент на два
export default {
  name: 'editor-options',
  props: {
    title: String,
    value: {
      type: Array,
      default () {
        return []
      }
    },
    otherProperties: {
      type: Object
    }
  },
  computed: {
    isCheckbox () {
      return Object.keys(this.otherProperties).includes('defaultCheck')
    }
  },
  data () {
    return {
      type: this.otherProperties?.type || 'basic'
    }
  },
  watch: {
    'otherProperties.type': {
      handler (value) {
        this.type = value
      }
    },
    value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    }
  },
  methods: {
    addOption () {
      if (typeof this.value === 'undefined') {
        this.$set(this, 'value', [])
      }
      this.value.push({
        name: null,
        attribute: null,
        color: null,
        alias: null
      })
    },
    deleteOption (index) {
      this.value.splice(index, 1)
    }
  },
  mounted () {
    if (this.isCheckbox && this.otherProperties.defaultCheck) {
      this.$set(this.value[0], 'default', true)
    }
  }
}
</script>

<style lang="scss">
  .editor-options {
    .condition {
      background-color: #f5f5f5;
      margin-bottom: 8px;
      border-left: 2px solid green;
      padding: 4px;
    }
  }
</style>
