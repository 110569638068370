import QueryInterface from '@/core/application/query/QueryInterface'

export default class SourceTypePropertiesQuery implements QueryInterface
{
    private type: string;

    constructor(type: string)
    {
        this.type = type;
    }

    getType(): string
    {
        return this.type;
    }

    getClassName(): string
    {
        return 'SourceTypePropertiesQuery';
    }
}