import { ActionTree } from 'vuex'
import { RuleTreeElementState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, RuleTreeElementLoadingEvent, RuleTreeElementLoadedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { RuleTreeElementAPI } from '@/services/TaskEditor/infrastructure/api/RuleTreeElementAPI'

export const actions: ActionTree<RuleTreeElementState, any> = {
  [ActionTypes.GET_RULE_TREE_ELEMENTS]: async ({ commit, dispatch }, data) => {
    commit(new RuleTreeElementLoadingEvent())

    return APIClient.shared.request(new RuleTreeElementAPI.GetRuleTreeElements(data.payload))
      .then((response) => {
        commit(new RuleTreeElementLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.GET_RULE_TREE_ELEMENT_BY_GUID]: async ({ commit, dispatch }, data) => {
    commit(new RuleTreeElementLoadingEvent())

    return APIClient.shared.request(new RuleTreeElementAPI.GetRuleTreeElementByGuid(data.guid))
      .then((response) => {
        commit(new RuleTreeElementLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.GET_RULE_TREE_ELEMENT_BY_ELEMENT_GUID]: async ({ commit, dispatch }, data) => {
    commit(new RuleTreeElementLoadingEvent())

    return APIClient.shared.request(new RuleTreeElementAPI.GetRuleTreeElementByElementGuid(data.elementGuid))
      .then((response) => {
        commit(new RuleTreeElementLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  }
}
