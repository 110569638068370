import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { ServiceDTO } from '@/services/ReleaseEditor/domain/model/Service/Service'

export namespace ServiceAPI {

    export class GetServices implements APIRequest<ServiceDTO> {
        response: ServiceDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/releaseeditor/services?' + (new URLSearchParams(payload).toString())
        }
    }

}