enum EventType
{
    PACKAGE_READ_SUCCESS = 'package_read_success',
    PACKAGE_READ_FAILURE = 'package_read_failure',
    PACKAGE_WRITE_SUCCESS = 'package_write_success',
    PACKAGE_WRITE_FAILURE = 'package_write_failure',
    SMEV_REQUEST = 'smev_request',
    SMEV_RESPONSE = 'smev_response',
    SMEV_REJECT = 'smev_reject',
    SMEV_ERROR = 'smev_error',
    SMEV_STATUS = 'smev_status',
    SMEV_RESPONSE_ROUTING = 'smev_response_routing'
};

export default EventType;