import Vue from 'vue'
import './filterStyle.css'

export default Vue.extend({
  template: `
        <div class="data_picker">
            <el-date-picker
              v-model="date"
              type="date"
              size="small"
              format="dd.MM.yyyy"
              :picker-options="{ firstDayOfWeek: 1 }"
              style="width: 100%"
              @change="changed"
              popper-class="ag-custom-component-popup"
              placeholder="Дата">
            </el-date-picker>
        </div>
    `,
  name: 'dateFilter',
  data: function () {
    return {
      date: null,
      isActive: false
    }
  },
  methods: {
    changed (val) {
      this.date = val || null
      this.params.onDateChanged()
    },
    getDate () {
      return this.date
    },
    setDate (date) {
      this.date = date || null
    }
  }
})
