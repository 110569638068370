import { FluxStandardAction } from '@/core/domain/type/types'
import { LogicGroupDTO } from '@/services/LogicEditor/domain/model/LogicGroup'

export enum ActionTypes {
  GET_LOGIC_GROUP_BY_GUID = 'get_logic_group_by_id',
  CREATE_LOGIC_GROUP = 'create_logic_group',
  UPDATE_LOGIC_GROUP = 'update_logic_group',
  DELETE_LOGIC_GROUP = 'delete_logic_group'
}

export class GetLogicGroupByGuid implements FluxStandardAction {
  type = 'LogicGroup/' + ActionTypes.GET_LOGIC_GROUP_BY_GUID
  constructor (public guid: string) {}
}

export class CreateLogicGroup implements FluxStandardAction {
  type = 'LogicGroup/' + ActionTypes.CREATE_LOGIC_GROUP
  constructor (public payload: LogicGroupDTO) {}
}

export class UpdateLogicGroup implements FluxStandardAction {
  type = 'LogicGroup/' + ActionTypes.UPDATE_LOGIC_GROUP
  constructor (public payload: LogicGroupDTO) {}
}

export class DeleteLogicGroup implements FluxStandardAction {
  type = 'LogicGroup/' + ActionTypes.DELETE_LOGIC_GROUP
  constructor (public guid: string) {}
}
