<template>
  <div class="affine-transformation-form">
    <el-form label-position="top" :rules="formRules" :ref="formId" v-if="dto" class="affine-transformation-form-inner-body" :model="dto">
      <el-form-item :label="$locale.main.fields.name">
        <el-input v-model="dto.name"></el-input>        
      </el-form-item>
      <el-form-item :label="$locale.main.fields.description">
        <el-input v-model="dto.description" type="textarea"></el-input>        
      </el-form-item>
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$locale.map_editor.affine_transformation_panel.target_cs">
            <treeselect
              v-model="dto.type"
              :placeholder="$locale.main.fields.select"
              :normalizer="(node) => {return {id: node.srid, label: node.auth_name}}"
              :options="types"
              :clear-value-text="$locale.main.message.clear"
              :loading-text="$locale.main.message.loading"
              :disable-immediate-search="true"
              :async="true"
              :cache-options="false"
              :append-to-body="true"
              :load-options="loadCoordinateSystems"
              @open="loadCoordinateSystems"
            ></treeselect>       
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item>
            <SelectOptions />
          </el-form-item>
          </el-col>
      </el-row>
      <el-row class="affine-transformation-formula">
        <el-col :span="1"><b>X = </b></el-col>
        <el-col :span="5">
          <el-form-item label="x0">
            <el-input-number v-model="dto.x0" :precision="16" :step="0.1" controls-position="right"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="1" class="affine-transformation-plus">+</el-col>
        <el-col :span="5">
          <el-form-item label="a">
            <el-input-number v-model="dto.a" :precision="16" :step="0.1" controls-position="right"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="1" class="affine-transformation-plus">+</el-col>
        <el-col :span="5">
          <el-form-item label="b">
            <el-input-number v-model="dto.b" :precision="16" :step="0.1" controls-position="right"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="1" class="affine-transformation-plus">+</el-col>
        <el-col :span="5">
          <el-form-item label="c">
            <el-input-number v-model="dto.c" :precision="16" :step="0.1" controls-position="right"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="affine-transformation-formula">
        <el-col :span="1"><b>Y = </b></el-col>
        <el-col :span="5">
          <el-form-item label="y0">
            <el-input-number v-model="dto.y0" :precision="16" :step="0.1" controls-position="right"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="1" class="affine-transformation-plus">+</el-col>
        <el-col :span="5">
          <el-form-item label="d">
            <el-input-number v-model="dto.d" :precision="16" :step="0.1" controls-position="right"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="1" class="affine-transformation-plus">+</el-col>
        <el-col :span="5">
          <el-form-item label="e">
            <el-input-number v-model="dto.e" :precision="16" :step="0.1" controls-position="right"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="1" class="affine-transformation-plus">+</el-col>
        <el-col :span="5">
          <el-form-item label="f">
            <el-input-number v-model="dto.f" :precision="16" :step="0.1" controls-position="right"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="affine-transformation-formula">
        <el-col :span="1"><b>Z = </b></el-col>
        <el-col :span="5">
          <el-form-item label="z0">
            <el-input-number v-model="dto.z0" :precision="16" :step="0.1" controls-position="right"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="1" class="affine-transformation-plus">+</el-col>
        <el-col :span="5">
          <el-form-item label="d">
            <el-input-number v-model="dto.g" :precision="16" :step="0.1" controls-position="right"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="1" class="affine-transformation-plus">+</el-col>
        <el-col :span="5">
          <el-form-item label="e">
            <el-input-number v-model="dto.h" :precision="16" :step="0.1" controls-position="right"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="1" class="affine-transformation-plus">+</el-col>
        <el-col :span="5">
          <el-form-item label="f">
            <el-input-number v-model="dto.i" :precision="16" :step="0.1" controls-position="right"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script type="ts">
import AffineTransformation, { AffineTransformationDTO } from '@/services/MapEditor/domain/model/Geometry/AffineTransformation'
import AffineTransformationCreateCommand from '@/services/MapEditor/application/command/AffineTransformationCreateCommand'
import AffineTransformationUpdateCommand from '@/services/MapEditor/application/command/AffineTransformationUpdateCommand'
import AffineTransformationByGuidQuery from '@/services/MapEditor/application/query/AffineTransformationByGuidQuery'
import Treeselect from '@bingosoftnn/vue-treeselect'
import SelectOptions from '@/core/infrastructure/components/SelectOptions.vue'

export default {
  name: 'AffineTransformationForm',
  props: ['formId', 'parentFormId', 'projectId', 'projectGuid', 'affineTransformation', 'submitCallback', 'affineTransformationCreatedCallback', 'affineTransformationUpdatedCallback'],
  inject: ['getEventBus', 'getCommandBus', 'getQueryBus'],
  components: {
    Treeselect,
    SelectOptions
  },
  watch: {
    affineTransformationCreated: function (location) {
      this.getQueryBus().execute(
        new AffineTransformationByGuidQuery(
          location.replace('/affine_transformations/', '')
        )
      ).then((affineTransformationDto) => {
        let affineTransformation = AffineTransformation.create(affineTransformationDto);
        if (typeof this.affineTransformationCreatedCallback == 'function') {
          this.affineTransformationCreatedCallback(affineTransformation);
        }
        this.getEventBus().$emit('affineTransformationCreated', affineTransformation, this.parentFormId);        
      }); 
    } 
  },
  data() {
    return {
      dto: null,      
      formRules: {
        name: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        x0: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        y0: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        a: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        b: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        c: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        d: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        e: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        f: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        }
      }     
    }
  },
  computed: {
    affineTransformationCreated() {
      return this.$store.getters['AffineTransformation/getLocation'];
    }
  },
  methods: {
    async submit(callback) {
      let form = this.$refs[this.formId];
      form.validate(valid => {
        if (valid) {
          if (typeof this.affineTransformation !== 'undefined') {
            this.affineTransformation.setGuid(this.dto.guid);  
            this.affineTransformation.setName(this.dto.name);
            this.affineTransformation.setDescription(this.dto.description);
            this.affineTransformation.setX0(this.dto.x0);
            this.affineTransformation.setY0(this.dto.y0);
            this.affineTransformation.setZ0(this.dto.z0);
            this.affineTransformation.setA(this.dto.a);
            this.affineTransformation.setB(this.dto.b);
            this.affineTransformation.setC(this.dto.c);
            this.affineTransformation.setD(this.dto.d);
            this.affineTransformation.setE(this.dto.e);
            this.affineTransformation.setF(this.dto.f);
            this.affineTransformation.setG(this.dto.g);
            this.affineTransformation.setH(this.dto.h);
            this.affineTransformation.setI(this.dto.i);

            this.getCommandBus().execute(
              new AffineTransformationUpdateCommand(
                this.affineTransformation.getGuid(),  
                this.affineTransformation.getName(),
                this.affineTransformation.getDescription(),
                this.affineTransformation.getX0(),
                this.affineTransformation.getY0(),
                this.affineTransformation.getZ0(),
                this.affineTransformation.getA(),
                this.affineTransformation.getB(),
                this.affineTransformation.getC(),
                this.affineTransformation.getD(),
                this.affineTransformation.getE(),
                this.affineTransformation.getF(),
                this.affineTransformation.getG(),
                this.affineTransformation.getH(),
                this.affineTransformation.getI()
              )
            ).then((response) => {
              if (typeof callback == 'function') {
                callback();
              }
              if (typeof this.affineTransformationUpdatedCallback == 'function') {
                this.affineTransformationUpdatedCallback(this.affineTransformation);
              }
              this.getEventBus().$emit('AffineTransformationUpdated', this.affineTransformation);
            });
          } else {
            let affineTransformation = affineTransformation.create(this.dto);
            this.getCommandBus().execute(
              new AffineTransformationCreateCommand(
                this.affineTransformation.getName(),
                this.affineTransformation.getDescription(),
                this.affineTransformation.getX0(),
                this.affineTransformation.getY0(),
                this.affineTransformation.getZ0(),
                this.affineTransformation.getA(),
                this.affineTransformation.getB(),
                this.affineTransformation.getC(),
                this.affineTransformation.getD(),
                this.affineTransformation.getE(),
                this.affineTransformation.getF(),
                this.affineTransformation.getG(),
                this.affineTransformation.getH(),
                this.affineTransformation.getI(),
                this.projectId
              )
            ).then((response) => {
              if (typeof callback == 'function') {
                callback(response);
              }              
            });
          }
        }
      });
    },
    async loadCoordinateSystems() {
      return [];
    }
  },
  mounted() {
    if (typeof this.affineTransformation !== 'undefined') {
      this.dto = new AffineTransformationDTO({
        guid: this.affineTransformation.getGuid(),
        name: this.affineTransformation.getName(),
        description: this.affineTransformation.getDescription(),
        x0: this.affineTransformation.getX0(),
        y0: this.affineTransformation.getY0(),
        z0: this.affineTransformation.getZ0(),
        a: this.affineTransformation.getA(),
        b: this.affineTransformation.getB(),
        c: this.affineTransformation.getC(),
        d: this.affineTransformation.getD(),
        e: this.affineTransformation.getE(),
        f: this.affineTransformation.getF(),
        g: this.affineTransformation.getG(),
        h: this.affineTransformation.getH(),
        i: this.affineTransformation.getI()
      });
    } else {      
      this.dto = new AffineTransformationDTO();
    }
  }
}
</script>