export interface GroupState {
  isLoading: boolean;
  location: string;
  deletedGroupId: number;
  error: object;
}

export const initialState = (): GroupState => {
  return {
    isLoading: false,
    location: null,
    deletedGroupId: null,
    error: null
  }
}
