export default class RoutingService {
  public static location: string
  private static activeViews: string[] = []
  private static lastActiveRoute: {
    tabGuid: string,
    detailing: {containerGuid: string}[],
    constraints: {registryId: number, cards: number[]}[]
  } = null

  private static isLaunched: boolean = false;

  public static parseCurrentViewInfo (routingSettings: [], blocks: [], context: any): [] {
    if (!this.isLaunched) {
      this.activeViews = this.parseLocation()
      this.isLaunched = true
    }
    return this.prepareBlocks(routingSettings, blocks, context)
  }

  private static prepareBlocks (routeSettings: [], blocks: [], context: any) {
    if (this.activeViews.length === 0) {
      return blocks
    }

    const view = this.activeViews.shift()

    if (this.isNumeric(view)) {
      // если идем уже по детализации
      if (!this.lastActiveRoute) {
        // return blocks
      }
      const registryId = view
      const recordId = this.activeViews[0]
      if (this.isNumeric(recordId)) {
        this.activeViews.shift()
      } else {
        return blocks
      }

      const cardId = this.activeViews[0]
      if (this.isNumeric(cardId)) {
        this.activeViews.shift()
      } else {
        return blocks
      }

      const detailing = (this.lastActiveRoute?.detailing || []).shift()

      if (registryId && recordId && cardId) {
        const constraints = (this.lastActiveRoute?.constraints || [])
        if (constraints.length > 0) {
          const registryConstraint = constraints.find((_) => _.registryId === parseInt(registryId))
          if (!registryConstraint) {
            return blocks
          }
          if ((registryConstraint.cards || []).length > 0 && !registryConstraint.cards.includes(parseInt(cardId))) {
            return blocks
          }
        }
        if (detailing?.containerGuid) {
          const detailingBlock = this.findBlockByGuid(blocks, detailing.containerGuid)
          if (!detailingBlock) {
            return blocks
          }
          if (!detailingBlock.hasOwnProperty('properties') || Array.isArray(detailingBlock.properties)) {
            context.$set(detailingBlock, 'properties', {})
          }
          context.$set(detailingBlock.properties, 'contentType', 'registry')
          context.$set(detailingBlock.properties, 'contentDefaults', {
            type: 'card',
            properties: {
              registry_id: registryId,
              record_id: recordId,
              card_id: cardId
            }
          })
        } else {
          if (context.openRegistryCard && typeof context.openRegistryCard === 'function') {
            context.openRegistryCard({
              registryId,
              cardId,
              recordId
            })
          }
        }
      }

      return blocks
    } else {
      // если идем по вкладкам
      const route: { alias: string, tabGuid: string, children: [], detailing: [], constraints: [] } = routeSettings.find((route: { alias: string, guid: string }) => route.alias === view)
      if (!route) {
        return blocks
      }

      const activeTabBlock = this.findBlockByTabGuid(blocks, route.tabGuid)
      if (!activeTabBlock) {
        return blocks
      }

      activeTabBlock.tabs.saveActiveTab = true
      activeTabBlock.tabs.activeGuid = route.tabGuid
      this.lastActiveRoute = route

      if (activeTabBlock?.children.length > 0) {
        activeTabBlock.children = this.prepareBlocks(route.children, activeTabBlock.children, context)
      }

      return blocks
    }
  }

  private static findBlockByTabGuid (arr: any[], tabGuid: string) {
    for (const obj of arr) {
      if (obj.tabs?.use) {
        let activeTab = obj.tabs.list.find((tab) => tab.guid === tabGuid)
        if (activeTab) {
          return obj
        }
      }
      const nestedObj = this.findBlockByTabGuid(obj.children || [], tabGuid)
      if (nestedObj) return nestedObj
    }
  }

  private static findBlockByGuid (arr: any[], guid: string) {
    for (const obj of arr) {
      if (obj.guid === guid) {
        return obj
      }
      const nestedObj = this.findBlockByTabGuid(obj.children || [], guid)
      if (nestedObj) return nestedObj
    }
  }

  private static parseLocation () {
    return this.location.split('/').filter((item) => item.length > 0)
  }

  private static isNumeric (value: string) {
    return /^\d+$/.test(value)
  }
}
