<template>
    <el-input size="small" v-model="localValue" v-on:input="$emit('change', localValue)"/>
</template>

<script>
export default {
  data () {
    return {
      localValue: this.value
    }
  },
  props: {
    value: String
  },
  name: 'string-editor'
}
</script>

<style scoped>

</style>
