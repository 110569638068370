import { FluxStandardAction } from '@/core/domain/type/types'
import { RasterDTO } from '@/services/RasterLibrary/domain/model/Raster/Raster'

export enum ActionTypes {
    GET_RASTERS = 'get_rasters',
    GET_RASTER_BY_GUID = 'get_raster_by_guid',
    COUNT_RASTERS = 'count_rasters',
    ADD_RASTER = 'add_raster',
    UPDATE_RASTER = 'update_raster',
    DELETE_RASTER = 'delete_raster'
}

export class GetRasters implements FluxStandardAction {
    type = 'Raster/' + ActionTypes.GET_RASTERS
    constructor(public payload: string[][]){}
}

export class GetRasterByGuid implements FluxStandardAction {
    type = 'Raster/' + ActionTypes.GET_RASTER_BY_GUID
    constructor(public guid: string){}
}

export class CountRasters implements FluxStandardAction {
    type = 'Raster/' + ActionTypes.COUNT_RASTERS
    constructor(public payload: string[][]){}
}

export class AddRaster implements FluxStandardAction {
    type = 'Raster/' + ActionTypes.ADD_RASTER
    constructor(public payload: FormData){}
}

export class UpdateRaster implements FluxStandardAction {
    type = 'Raster/' + ActionTypes.UPDATE_RASTER
    constructor(public payload: RasterDTO){}
}

export class DeleteRaster implements FluxStandardAction {
    type = 'Raster/' + ActionTypes.DELETE_RASTER
    constructor(public guid: string, public payload: unknown){}
}