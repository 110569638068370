import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import ProjectGroupQueryRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/ProjectGroupQueryRepositoryInterface'
import store from '@/store'
import { GetProjectGroupByGuid } from '@/services/DigitalTwinEditor/infrastructure/store/modules/ProjectGroup/action-types'

export default class ProjectGroupQueryRepository extends AbstractQueryRepository implements ProjectGroupQueryRepositoryInterface
{
    getByGuid(guid: string): Promise<any>
    {
        return store.dispatch(new GetProjectGroupByGuid(guid));
    }
}