export interface ICssClassTreeDTO {
  id: number;
  guid: string;
  parent_guid: string|null;
  name: string;
  description: string|null;
  type: string;
  row_order: number;
  is_leaf: boolean;
  alias: string|null;
  order_type: number;
  is_search?: boolean;
  children?: ICssClassTreeDTO[];
}

export class CssClassTreeDTO {
  id: number;
  guid: string;
  parent_guid: string|null;
  name: string;
  description: string|null;
  type: string;
  row_order: number;
  is_leaf: boolean;
  alias: string|null;
  order_type: number;
  is_search: boolean;
  children?: ICssClassTreeDTO[];

  constructor (data: ICssClassTreeDTO) {
    this.id = data.id
    this.guid = data.guid
    this.parent_guid = data.parent_guid
    this.name = data.name
    this.description = data.description
    this.alias = data.alias
    this.type = data.type
    this.order_type = data.row_order
    this.is_leaf = data.is_leaf
    this.row_order = data.row_order
    this.is_search = !!data.is_search

    if (data.children) {
      this.children = []

      for (const child of data.children) {
        this.children.push(new CssClassTreeDTO(child))
      }
    }
  }
}

export interface ICssClassDTO {
  id?: number;
  guid?: string;
  name: string;
  description: string|null;
  alias: string|null;
  class_name: string;
  css: string;
  group_id: number|null;
  miniature_id: number|null;
  tags: string|null;
  row_order: number;
}

export class CssClassDTO {
  id?: number;
  guid?: string;
  name: string;
  description: string|null;
  alias: string|null;
  class_name: string;
  css: string;
  group_id: number|null;
  miniature_id: number|null;
  tags: string|null;
  row_order: number;

  /**
   * Заполнение данными
   *
   * @param data
   */
  constructor (data: ICssClassDTO) {
    this.id = data.id
    this.guid = data.guid
    this.name = data.name
    this.description = data.description
    this.alias = data.alias
    this.class_name = data.class_name
    this.css = data.css
    this.group_id = data.group_id
    this.miniature_id = data.miniature_id
    this.tags = data.tags
    this.row_order = data.row_order
  }

  /**
   * Создать пустую модель
   */
  static create (groupId: number|null = null) {
    return new CssClassDTO({
      name: '',
      description: '',
      alias: null,
      class_name: '',
      css: '',
      group_id: groupId,
      miniature_id: null,
      tags: null,
      row_order: 0
    })
  }
}
