<template>
  <div>
    <el-select
      v-model="selectedAddress"
      :style="`width: calc(100% - ${addButtonVisibility ? '40px' : '20px'})`"
      ref="field"
      filterable
      clearable
      remote
      value-key="key"
      :size="size"
      :loading="loading"
      :remote-method="search"
      :placeholder="placeholder"
      @focus="setDefaultAddress"
      @clear="clearSelectedAddress"
    >
      <i slot="prefix" class="el-icon-search" style="margin-left: 5px; font-weight: bold"></i>
      <el-option
        v-for="item in searchOptions"
        :key="item.key"
        :label="getAddressLabel(item)"
        :value="item"
        class="el-option-content">
        <div class="el-option-text" @click="changeSelected(item); addAddress(item)">
          {{ getAddressLabel(item) }}
        </div>
        <div class="el-option-buttons">
          <el-button type="text" size="small" class="el-option-button" @click="changeSelected(item, true)">
            <i class="el-icon-setting el-icon--left"></i>
            {{ $locale.address_service.button.specify }}
          </el-button>
        </div>
      </el-option>
    </el-select>
    <i
      class="el-icon-plus"
      style="margin-left: 5px; font-weight: bold; cursor:pointer; color: #409EFF;"
      v-show="addButtonVisibility"
      @click="addAddress(selectedAddress)"></i>
    <i
      :class="{'el-icon-arrow-down': !showAddressObjects, 'el-icon-arrow-up': showAddressObjects}"
      style="margin-left: 5px; font-weight: bold; cursor:pointer;"
      @click="showAddressObjects = !showAddressObjects"
    ></i>
    <address-objects
      :is-active.sync="showAddressObjects"
      :dropdown-width="dropdownWidth"
      :level="level"
      :required-level="requiredLevel"
      :selected-address="selectedAddress"
      @set-address="setSelectedAddress"
      style="margin-top: 5px"
    ></address-objects>
    <div
      v-for="(item, index) in selectedAddresses"
      :key="item.key"
      style="width: calc(100% - 20px)"
    >
      <slot name="address_label" :deleteAddress="deleteAddress" :index="index" :item="item" :getAddressLabel="getAddressLabel">
        <i
          :class="'el-icon-close'"
          style="font-size: 1.5em; vertical-align: sub; margin-left: 5px; cursor:pointer;"
          @click="deleteAddress(index)"
        ></i>
        <span style="margin-left: 5px;">{{ getAddressLabel(item) }}</span>
      </slot>
    </div>
  </div>
</template>

<script>
import AddressObjects from '@/core/infrastructure/components/AddressMultiField/AddressObjects'

export default {
  name: 'address_multi_field',
  components: { AddressObjects },

  props: {
    size: {
      type: String
    },
    level: {
      type: String,
      default: 'street'
    },
    placeholder: {
      type: String
    },
    searchLimit: {
      type: Number,
      default: 50
    },
    value: {
      type: Array
    },
    requiredLevel: {
      type: String
    },
    defaultAddress: {
      type: String
    },
    initialDropdownWidth: {
      type: Number
    }
  },

  data () {
    return {
      selectedAddresses: this.value || [],
      selectedAddress: {},
      searchOptions: [],
      showAddressObjects: false,
      dropdownWidth: 100,
      loading: false
    }
  },

  computed: {
    addButtonVisibility () {
      // return false
      return Object.keys(this.selectedAddress || {}).length !== 0
    }
  },

  watch: {
    value: {
      handler (newValue) {
        this.selectedAddresses = newValue;
        (newValue || []).forEach((item) => {
          const found = this.searchOptions.find((_) => _.addrobj_aoid === item.addrobj_aoid)
          if (found) {
            found.id = item.id
            found.address_id = item.id
          }
        })
      }
    },
    showAddressObjects (value) {
      if (value) {
        this.updateDropdownWidth()
      }
    }
  },

  mounted () {
    this.updateDropdownWidth()
  },

  methods: {
    updateDropdownWidth () {
      this.dropdownWidth = this.initialDropdownWidth || (this.$el.offsetWidth > 500 ? 500 : this.$el.offsetWidth - 20)
    },
    setDefaultAddress () {
      if (this.defaultAddress) {
        const inputEl = this.$refs.field.$el.children[0].children[0]
        inputEl.value = this.defaultAddress
        this.search(this.defaultAddress)
      }
    },
    async setSelectedAddress (address) {
      if (Object.keys(address || {}).length === 0) {
        return
      }

      let option = await this.loadAddress(address) || {}

      if (Object.keys(option).length > 0) {
        this.selectedAddress = option
      } else {
        this.selectedAddress = address
      }

      this.addAddress(this.selectedAddress)
    },
    async loadAddress (address) {
      if (Object.keys(address.data || {}).length === 0 || !address.type) {
        return
      }

      let url = null
      if (address.type === 'house') {
        if (address.data.house_houseid) {
          url = `${this.$config.api}/fiasaddressservice/addresses/houses?house_houseid=${address.data.house_houseid}`
        } else {
          url = `${this.$config.api}/fiasaddressservice/addresses/houses?addrobj_aoid=${address.data.previous}&house=${address.data.house}`
        }
      } else {
        url = `${this.$config.api}/fiasaddressservice/addresses/address_objects/${address.data.id}`
      }
      if (!url) {
        return false
      }
      const fiasData = await this.$http.get(url)
      const fiasAddress = fiasData.data
      if (!fiasAddress) {
        console.warn('address not found')
        return false
      }

      let option = fiasAddress
      /* Other fields */
      this.$set(option, 'flat', address.flat)
      // По умолчанию null, на сервере проверка дублей по NULL
      this.$set(option, 'description', address.description || null)

      // Для привязки существующего адреса по id (не address_id). Новый адрес если заполнен description.
      // - Может быть заполнен из-за выбора flat (см. address_id_flat в save в AddressObject.vue)
      if (address.id) {
        // ID на входе в функцию loadAddress есть, привязать существующий адрес по ID
        this.$set(option, 'id', address.id)
      } else if (option.address_id && !address.flat && !address.description) {
        // Привязать к существующему адресу только при не заполненом flat и description
        // ID на входе в функцию loadAddress нет и поском на сервере найден существующий адрес, привязать существующий адрес по ID
        this.$set(option, 'id', option.address_id)
      }
      // address_id от сервера привязанный к house, приравниваем к id, чтобы не сбивало с толку при отладке
      this.$set(option, 'address_id', option.id)

      // Для сохранения flat, обязателен address_level_id == 'flat'
      this.$set(option, 'address_level_id', option.flat ? 'flat' : option.address_level_id)

      // Ключ адреса для проверки дублей с учётом flat, description
      const key =
        `${option.subject_guid}_${option.municipal_area_guid}_${option.city_guid}_${option.locality_guid}_${option.planning_structure_guid}_${option.street_guid}` +
        `_${option.addrobj_aoid}_${option.house_houseid}_${option.address_level_id}_${address.flat}_${address.description}`
      this.$set(option, 'key', key)

      if (!this.searchOptions.find(item => item.key === option.key)) {
        // Адрес с ключем не найден, добавить адрес в список поиска
        this.searchOptions.push(option)
      }

      return option
    },
    async search (query) {
      if (!query) {
        this.searchOptions = []
        return false
      }
      this.loading = true
      query = encodeURIComponent(query)
      const fiasData = await this.$http.get(`${this.$config.api}/fiasaddressservice/addresses/address_objects/${this.level}/${query}?limit=${this.searchLimit}`)
      this.searchOptions = fiasData.data.map(fiasAddress => {
        // Ключ адреса для проверки дублей
        const key =
          `${fiasAddress.subject_guid}_${fiasAddress.municipal_area_guid}_${fiasAddress.city_guid}_${fiasAddress.locality_guid}_${fiasAddress.planning_structure_guid}_${fiasAddress.street_guid}` +
          `_${fiasAddress.addrobj_aoid}_${fiasAddress.address_id}_${fiasAddress.house_houseid}_${fiasAddress.address_level_id}`

        return Object.assign(fiasAddress, { key })
      })

      this.loading = false
    },
    addAddress (address) {
      if (Object.keys(address || {}).length === 0) {
        return
      }
      if (this.selectedAddresses.find(item => item.key === address.key)) {
        return
      }

      if (address.id) {
        let oldAddressIndex = this.selectedAddresses.findIndex(addressTemp => addressTemp.id === address.id)
        if (oldAddressIndex !== -1) {
          // Change exists address
          this.selectedAddresses[oldAddressIndex] = address
        } else {
          // Add not finded
          this.selectedAddresses.push(address)
        }
      } else {
        // Add new
        this.selectedAddresses.push(address)
      }

      this.$emit('input', this.selectedAddresses)

      this.clearSelectedAddress()
    },
    deleteAddress (addressIndex) {
      this.selectedAddresses.splice(addressIndex, 1)

      this.$emit('input', this.selectedAddresses)
    },
    changeSelected (address, isShowAddressObjects = false) {
      if (!address) {
        return
      }

      // address_id от сервера привязанный к house, приравниваем к id
      this.$set(address, 'id', address.address_id)
      /* Other fields */
      // По умолчанию null, на сервере проверка дублей по NULL
      this.$set(address, 'description', address.description || null)

      this.selectedAddress = address

      if (isShowAddressObjects) {
        // Показать модальное окно адреса
        this.showAddressObjects = true
      }
    },
    onChangeSearchSelect (address, isShowAddressObjects = false) {
      if (!address) {
        return
      }

      this.changeSelected(address, isShowAddressObjects)
    },
    clearSelectedAddress () {
      this.selectedAddress = {}
      setTimeout(() => { // Без задержки не стирает по кнопке "Уточнить" (specify) в option. Vue.js не сразу проставляет значение внутри фреймворка
        this.selectedAddress = {}
      })
    },
    getAddressLabel (address) {
      let addressLabel = address.name // Может содержать flat

      if (address.flat && !addressLabel.includes(', кв.') /* Проверить содержание flat */) {
        addressLabel += ', кв. ' + address.flat
      }

      if (address.description) {
        addressLabel += ', ' + address.description
      }

      return addressLabel
    }
  }
}
</script>

<style scoped>
.el-option-content {
  display: flex;
}

.el-option-text {
  float: left;
  width: 100%;
}

.el-option-buttons {
  float: right;
}

.el-option-button {
  width: 75px;
}
</style>
