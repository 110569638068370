import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { SourceDTO } from '@/services/MapEditor/domain/model/Source/Source'

export namespace SourceAPI {

    export class AddSource implements APIRequest<string> {
        response: string;
        path = '/mapeditor/sources';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.headers.location
        constructor(public params: SourceDTO) {}
    }

    export class UpdateSource implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: SourceDTO) {
            this.path = `/mapeditor/sources/${params.guid}`;
        }
    }

    export class DeleteSource implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.DELETE;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/mapeditor/sources/${guid}`;
        }
    }

    export class DeleteProjectSource implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.DELETE;
        parse = (data: AxiosResponse) => data.data
        constructor(id: number, projectGuid: string) {
            this.path = `/mapeditor/projects/${projectGuid}/sources/?id=${id}`;
        }
    }

    export class CountSources implements APIRequest<SourceDTO> {
        response: SourceDTO;
        path: string;
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: string[][]) {
            this.path = '/mapeditor/sources?*[func]=count'
        }
    }

    export class GetSources implements APIRequest<SourceDTO> {
        response: SourceDTO;
        path = '/mapeditor/sources';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: string[][]) {
            this.path += "?";
            if (params.hasOwnProperty("limit")) {
                this.path += `limit=${params['limit']}&`;
            }
            if (params.hasOwnProperty("offset")) {
                this.path += `offset=${params['offset']}`;
            }
        }
    }

    export class GetSourceByGuid implements APIRequest<SourceDTO> {
        response: SourceDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/mapeditor/sources/${guid}`;
        }
    }

    export class GetSourceProjects implements APIRequest<SourceDTO> {
        response: SourceDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/mapeditor/sources/${guid}/projects`;
        }
    }

    export class GetTypes implements APIRequest<string> {
        response: string;
        path = '/mapeditor/source_types';
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor() {}
    }

    export class GetTypeProperties implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(val: string) {
            this.path = `/mapeditor/source_types/${val}/properties`;
        }
    }

}