import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import TopicQueryRepositoryInterface from '@/services/Databus/domain/repository/TopicQueryRepositoryInterface'
import store from '@/store'
import { GetTopics, CountTopics, GetTopicByGuid } from '@/services/Databus/infrastructure/store/modules/Topic/action-types'

export default class TopicQueryRepository extends AbstractQueryRepository implements TopicQueryRepositoryInterface
{
    get(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new GetTopics(payload));
    }

    getCount(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new CountTopics(payload));
    }

    getByGuid(guid: string): Promise<any>
    {
        return store.dispatch(new GetTopicByGuid(guid));
    }
}