<template>
  <el-date-picker
    style="width: 100%;"
    v-bind:value="value"
    v-on:input="$emit('input', $event)"
    type="date"
    format="dd.MM.yyyy"
    value-format="yyyy-MM-dd"
    :placeholder="placeholder">
  </el-date-picker>
</template>

<script>
export default {
  name: 'DateInput',

  props: {
    value: String,

    placeholder: String
  }
}
</script>

<style type="text/css"></style>
