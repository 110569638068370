import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import StructureRepositoryInterface from '@/services/RegistryTable/domain/repository/StructureRepositoryInterface'
import StructureQuery from '@/services/RegistryTable/application/query/StructureQuery'

export default class StructureHandler implements QueryHandlerInterface {
  private repository: StructureRepositoryInterface;

  constructor (repository: StructureRepositoryInterface) {
    this.repository = repository
  }

  execute (query: StructureQuery): Promise<any> {
    return this.repository.getById(query.getRegistryId())
  }
}
