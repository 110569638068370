import { FluxStandardAction } from '@/core/domain/type/types'
import { PointStyleImageDTO } from '@/core/domain/model/modules/PointStyleImage'

export enum ActionTypes {
    ADD_POINT_STYLE_IMAGE = 'add_point_style_image',
    UPDATE_POINT_STYLE_IMAGE = 'update_point_style_image',
    DELETE_POINT_STYLE_IMAGE = 'delete_point_style_image',
    GET_POINT_STYLE_IMAGES = 'get_point_style_images',
    GET_POINT_STYLE_IMAGE_BY_GUID = 'get_point_style_image_by_guid'
}

export class AddPointStyleImage implements FluxStandardAction {
    type = 'PointStyleImage/' + ActionTypes.ADD_POINT_STYLE_IMAGE
    constructor(public payload: PointStyleImageDTO){}
}

export class UpdatePointStyleImage implements FluxStandardAction {
    type = 'PointStyleImage/' + ActionTypes.UPDATE_POINT_STYLE_IMAGE
    constructor(public payload: PointStyleImageDTO){}
}

export class DeletePointStyleImage implements FluxStandardAction {
    type = 'PointStyleImage/' + ActionTypes.DELETE_POINT_STYLE_IMAGE
    constructor(public guid: string){}
}

export class GetPointStyleImages implements FluxStandardAction {
    type = 'PointStyleImage/' + ActionTypes.GET_POINT_STYLE_IMAGES
    constructor(public payload: object){}
}

export class GetPointStyleImageByGuid implements FluxStandardAction {
    type = 'PointStyleImage/' + ActionTypes.GET_POINT_STYLE_IMAGE_BY_GUID
    constructor(public guid: string){}
}