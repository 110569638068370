import { MutationTree } from 'vuex'
import { ThemeState, initialState } from './state'
import { MutationTypes, ThemeCreatedEvent, ThemeUpdatedEvent, ThemeDeletedEvent } from './mutation-types'

export const mutations: MutationTree<ThemeState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false
    state.error = error
  },

  [MutationTypes.THEME_LOADING_EVENT]: (state) => {
    state.isLoading = true
  },

  [MutationTypes.THEME_LOADED_EVENT]: (state) => {
    state.isLoading = false
  },

  [MutationTypes.THEME_CREATED_EVENT]: (state, action: ThemeCreatedEvent) => {
    state.isLoading = false
    state.location = action.location
  },

  [MutationTypes.THEME_UPDATED_EVENT]: (state, action: ThemeUpdatedEvent) => {
    state.isLoading = false
  },

  [MutationTypes.THEME_DELETED_EVENT]: (state, action: ThemeDeletedEvent) => {
    state.isLoading = false
    state.deletedThemeGuid = action.guid
  }
}
