import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import ModelTreeElementQueryRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/ModelTreeElementQueryRepositoryInterface'
import store from '@/store'
import { GetModelTreeElementByGuid, GetModelTreeElements } from '@/services/DigitalTwinEditor/infrastructure/store/modules/ModelTreeElement/action-types'

export default class ModelTreeElementQueryRepository extends AbstractQueryRepository implements ModelTreeElementQueryRepositoryInterface
{
    getByGuid(guid: string): Promise<any>
    {
        return store.dispatch(new GetModelTreeElementByGuid(guid));
    }

    getChildNodes(parentId: Nullable<number>): Promise<any>
    {
        return store.dispatch(new GetModelTreeElements(parentId));
    }
}