import Vue from 'vue'
import AddressMultiField from '@/core/infrastructure/components/AddressMultiField'
import LEVELS from '@/core/infrastructure/components/AddressMultiField/levels.json'

export default Vue.extend({
  template: `
    <div class="ag-filter-wrapper ag-focus-managed">
    <div class="ag-filter-body-wrapper ag-set-filter-body-wrapper">

      <div class="ag-set-filter">
        <!--AG-INPUT-TEXT-FIELD-->
        <address-multi-field
          v-model="value"
          size="mini"
          level="house"
          placeholder="Поиск..."
          :initial-dropdown-width="400"
          :search-limit="10"
        >
          <template v-slot:address_label="{ deleteAddress, index, getAddressLabel, item }">
            <i
              :class="'el-icon-close'"
              style="font-size: 1em; margin-left: 5px; cursor:pointer;"
              @click="deleteAddress(index)"
            ></i>
            <span style="margin-left: 5px;">{{ getAddressLabel(item) }}</span>
          </template>
        </address-multi-field>
      </div>
    </div>
    <div class="ag-filter-apply-panel">
      <button type="button" class="ag-standard-button ag-filter-apply-panel-button" @click="apply">ОК</button>
      <button type="button" class="ag-standard-button ag-filter-apply-panel-button" @click="reset">Сбросить</button>
    </div>
    </div>`,
  components: {
    AddressMultiField
  },
  data () {
    return {
      value: []
    }
  },
  methods: {
    reset () {
      this.value = []
      this.params.filterChangedCallback()
    },
    apply () {
      this.params.filterChangedCallback()
    },
    selectAllOptions () {

    },
    async loadOptions () {

    },
    isFilterActive () {
      return this.value.length > 0
    },

    getModel () {
      return {
        filterType: 'address',
        type: this.isFilterActive() ? 'active' : 'non-active',
        value: this.value.map((item) => {
          const previousValue = this.getPreviousValue(item.address_level_id, item)
          switch (item.address_level_id) {
            case 'house':
              return {
                address_level_id: 'house',
                house: item.house,
                house_guid: item.house_houseid,
                previous_type: previousValue.type,
                previous_value: previousValue.value
              }
            case 'flat':
              return {
                address_level_id: 'flat',
                flat: item.flat,
                previous_type: previousValue.type,
                previous_value: previousValue.value
              }
            default:
              return {
                address_level_id: item.address_level_id,
                guid: item[`${item.address_level_id}_guid`]
              }
          }
        })
      }
    },
    getPreviousValue (type, address) {
      let level = LEVELS.find(item => item.name === type)
      let previousValue = {
        type: null,
        value: null
      }
      level.previous.forEach(item => {
        console.log(item, address)
        if (address[`${item}_guid`]) {
          previousValue.value = address[`${item}_guid`]
          previousValue.type = item
        }
      })

      return previousValue
    },
    setModel (model) {
      console.warn('setModel method in StringSetFilter is disabled', model)
    }
  }
})
