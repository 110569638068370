import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { RasterDTO } from '@/services/RasterLibrary/domain/model/Raster/Raster'

export namespace RasterAPI {

    export class GetRasters implements APIRequest<RasterDTO> {
        response: RasterDTO;
        path = '/rastersstorageservice/rasters';
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: string[][]) {
            if (params) {

                if (params.hasOwnProperty('name1')) {
                    //this.path += `?name=${encodeURIComponent(params['name'])}`;
                    this.path += `?name=${encodeURIComponent(params['name1'])}`;
                    delete params['name1'];
                    return;
                }


              if (params.hasOwnProperty('name')) {
                //this.path += `?name=${encodeURIComponent(params['name'])}`;
                this.path += `/name/${encodeURIComponent(params['name'])}`;
                delete params['name'];
              } else {
                if (params.hasOwnProperty('offset')) {
                  this.path += `/${params['offset']}`;
                }
                if (params.hasOwnProperty('limit')) {
                  this.path += `/${params['limit']}`;
                }
              }
            }
            delete params['offset'];
            delete params['limit'];
        }
    }

    export class CountRasters implements APIRequest<RasterDTO> {
        response: RasterDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/rastersstorageservice/rasters/count'
        }
    }

    export class GetRasterByGuid implements APIRequest<RasterDTO> {
        response: RasterDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/rastersstorageservice/rasters/${guid}`;
        }
    }

    export class AddRaster implements APIRequest<string> {
        response: string;
        path = '/rastersstorageservice/rasters';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.headers.location
        constructor(public params: FormData) {}
    }

    export class UpdateRaster implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: unknown) {
            this.path = `/rastersstorageservice/rasters/${params['guid']}`;
            //delete params['guid'];
        }
    }

    export class DeleteRaster implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.DELETE;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string, params: unknown) {
            //this.path = `/rastersstorageservice/rasters/${guid}`;
            this.path = `/rastersstorageservice/rasters`;
        }
    }

    /* export class DeleteRaster implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.DELETE;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: unknown) {
            this.path = '/rastersstorageservice/rasters';
            delete params['guids'];
        }
    } */

    export class GetRastersFolderTree implements APIRequest<unknown> {
        response: unknown;
        path = '/rastersstorageservice/rasters/folder-content';
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor() {}
    }

    export class AddRasterFromFolder implements APIRequest<string> {
        response: string;
        path = '/rastersstorageservice/rasters/server/';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: unknown) {
            this.path += params['type'];
        }
    }

    export class GetRastersPublishSettings implements APIRequest<unknown> {
        response: unknown;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/rastersstorageservice/rasters/publication/${guid}/default`;   
        }
    }

    export class GetPublishExtent implements APIRequest<unknown> {
        response: unknown;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: unknown) {
            //this.path = `/rastersstorageservice/publish/extent/${params['guid']}`;
            //delete params['guid'];
        }
    }

    export class PublishRaster implements APIRequest<string> {
        response: string;
        path = '/rastersstorageservice/rasters/publication';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.headers.location
        constructor(public params: unknown) {}
    }

}