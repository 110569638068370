import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    MODEL_INSTANCE_LOADING_EVENT = 'model_instance_loading_event',
    MODEL_INSTANCE_LOADED_EVENT = 'model_instance_loaded_event',
    MODEL_INSTANCE_CREATED_EVENT = 'model_instance_created_event',
    MODEL_INSTANCE_DELETED_EVENT = 'model_instance_deleted_event',
    MODEL_INSTANCE_UPDATED_EVENT = 'model_instance_updated_event'      
}

export class ModelInstanceLoadingEvent implements FluxStandardAction {
    type = MutationTypes.MODEL_INSTANCE_LOADING_EVENT
}

export class ModelInstanceLoadedEvent implements FluxStandardAction {
    type = MutationTypes.MODEL_INSTANCE_LOADED_EVENT
}

export class ModelInstanceCreatedEvent implements FluxStandardAction {
    type = MutationTypes.MODEL_INSTANCE_CREATED_EVENT
    constructor(public location: string){}
}

export class ModelInstanceDeletedEvent implements FluxStandardAction {
    type = MutationTypes.MODEL_INSTANCE_DELETED_EVENT
    constructor(public guid: string){}
}

export class ModelInstanceUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.MODEL_INSTANCE_UPDATED_EVENT
}