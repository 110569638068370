var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form',{attrs:{"label-width":"120px","label-position":"top","size":"mini"}},[_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$locale.interface_editor.component_editor.table.source_type))]),_c('editor-select',{attrs:{"value":_vm.value.type,"options":{
        multiple: false,
        options: [
          { id: 'extended_object', name: 'Расширенная таблица' },
          { id: 'query', name: 'Запросы' }
        ]
      }},on:{"change":function($event){return _vm.$set(_vm.value ,'type', $event)}}})],1),(_vm.value.type)?_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.entityIdPropertyLabel))]),_c('el-select',{attrs:{"value":_vm.value.entityId,"filterable":""},on:{"change":function($event){return _vm.$set(_vm.value ,'entityId', $event)}}},_vm._l((_vm.data),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.name,"value":item.id}})}),1)],1):_vm._e(),(_vm.value.type && _vm.value.type === 'query')?_c('el-form-item',[_c('editor-filters',{attrs:{"title":"Параметры запроса","value":_vm.value.queryParameters,"type":"parameter"},on:{"change":function($event){return _vm.$set(_vm.value ,'queryParameters', $event)}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }