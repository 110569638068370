import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import HistoryQueryRepositoryInterface from '@/services/History/domain/repository/HistoryQueryRepositoryInterface'
import RecordHistoryQuery from '@/services/History/application/query/RecordHistoryQuery'
import EventFactory from '@/services/History/domain/service/EventFactory'

export default class RecordHistoryHandler implements QueryHandlerInterface {
  private repository: HistoryQueryRepositoryInterface;

  constructor (repository: HistoryQueryRepositoryInterface) {
    this.repository = repository
  }

  async execute (query: RecordHistoryQuery): Promise<any> {
    let eventsData = await this.repository.getByRecord(query.getRegistryId(), query.getRecordId())
    return EventFactory.build(eventsData)
  }
}
