import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ProjectCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/ProjectCommandRepositoryInterface'
import ProjectUpdateCommand from '@/services/DigitalTwinEditor/application/command/ProjectUpdateCommand'
import { ProjectDTO } from '@/services/DigitalTwinEditor/domain/model/Project/Project'

export default class ProjectUpdateHandler implements CommandHandlerInterface
{
    private repo: ProjectCommandRepositoryInterface;

    constructor(repo: ProjectCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: ProjectUpdateCommand): Promise<void>
    {
        let item: ProjectDTO = {
            guid: command.getGuid(),
            name: command.getName(),
            alias: command.getAlias(),
            description: command.getDescription(),
            parent_id: command.getParentId()
        };
        return this.repo.update(item);
    }
}