import { FluxStandardAction, Nullable } from '@/core/domain/type/types'

export enum ActionTypes {
    GET_SERVICES = 'get_services'
}

export class GetServices implements FluxStandardAction {
    type = 'Service/' + ActionTypes.GET_SERVICES
    constructor(public payload: object){}
}

