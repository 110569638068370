import CommandBusHandlerLocator from './CommandBusHandlerLocator'
import CommandInterface from '../CommandInterface'
import HandlerNotFound from '../../exception/HandlerNotFound'

export default class CommandBus
{
    private locator: CommandBusHandlerLocator;

    constructor(locator: CommandBusHandlerLocator)
    {
        this.locator = locator;
    }

    execute(command: CommandInterface): Promise<void>
    {
        let handler = this.locator.getHandler(command);
        if (handler) {
            return handler.execute(command);
        } else {
            throw new HandlerNotFound(command.constructor.name);
        }
    }
}