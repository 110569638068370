import { MutationTree } from 'vuex'
import { GeoJsonState } from './state'
import { MutationTypes } from './mutation-types'

export const mutations: MutationTree<GeoJsonState> = {
  [MutationTypes.GEOJSON_LOADING_EVENT]: (state) => {
    state.isLoading = true;
  },
  [MutationTypes.GEOJSON_LOADED_EVENT]: (state) => {
    state.isLoading = false;
  }
}