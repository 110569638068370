export class CommandEventTypeDTO {
  id: string;
  name: string;
}

export class CommandEventTypePropertyDTO {
  id?: string|null
  name?: string|null
  type?: string|null
  default_value?: string|null
  primitive_type?: string|null

  constructor ({ id, name, type, default_value, primitive_type }) {
    this.id = id
    this.name = name
    this.type = type
    this.default_value = default_value
    this.primitive_type = primitive_type
  }
}

export enum ConditionTypes {
  state = 'state',
  procedure = 'procedure'
}

export interface ICommand {
  id?: number|null;
  guid?: string|null;
  name: string;
  description: string|null;
  target_state_id: number|null;
  plugin_id: string|null;
  event_id: string|null;
  is_plugin: boolean;
  is_complex: boolean;
  properties: object[] | null;
  before_command_id: number|null;
  after_command_id: number|null;
  cancel_command_id: number|null;
  command_states: number[];
  command_external_states: number[];
  eds_sign_command_fields: object[];
  command_constraints: number[];
  child_commands: ICommand[];
  alias: string|null;
  is_timer: boolean;
  period: string|null;
  logic_id?: number;
  parent_tree_element_id?: number|null;
  condition_type: ConditionTypes;
  condition_procedure_id: number|null;
  procedure_id: number|null;
}

export class CommandDTO implements ICommand {
  id?: number|null;
  guid?: string|null;
  name: string;
  description: string|null;
  target_state_id: number|null;
  plugin_id: string|null;
  event_id: string|null;
  is_plugin: boolean;
  is_complex: boolean;
  properties: object[] | null;
  before_command_id: number|null;
  after_command_id: number|null;
  cancel_command_id: number|null;
  command_states: number[];
  command_external_states: number[];
  eds_sign_command_fields: object[];
  command_constraints: number[];
  child_commands: CommandDTO[];
  alias: string|null;
  is_timer: boolean;
  period: string|null;
  logic_id?: number;
  parent_tree_element_id?: number|null;
  condition_type: ConditionTypes;
  condition_procedure_id: number|null;
  procedure_id: number|null;

  constructor (data: ICommand) {
    this.id = data.id
    this.guid = data.guid
    this.name = data.name
    this.description = data.description
    this.target_state_id = data.target_state_id
    this.plugin_id = data.plugin_id
    this.event_id = data.event_id
    this.is_plugin = data.is_plugin
    this.is_complex = data.is_complex
    this.properties = data.properties
    this.before_command_id = data.before_command_id
    this.after_command_id = data.after_command_id
    this.cancel_command_id = data.cancel_command_id
    this.command_states = data.command_states
    this.command_external_states = data.command_external_states
    this.eds_sign_command_fields = data.eds_sign_command_fields
    this.command_constraints = data.command_constraints
    if (data.child_commands && Array.isArray(data.child_commands)) {
      this.child_commands = data.child_commands.map(item => new CommandDTO(item))
    } else {
      this.child_commands = []
    }
    this.logic_id = data.logic_id
    this.alias = data.alias
    this.is_timer = data.is_timer
    this.period = data.period
    this.condition_type = data.condition_type
    this.condition_procedure_id = data.condition_procedure_id
    this.procedure_id = data.procedure_id
    this.parent_tree_element_id = data.parent_tree_element_id
  }

  clone () {
    return new CommandDTO(this)
  }

  static create (): CommandDTO {
    return new CommandDTO({
      id: null,
      guid: null,
      name: null,
      description: null,
      target_state_id: null,
      plugin_id: null,
      event_id: null,
      is_plugin: false,
      is_complex: false,
      properties: null,
      before_command_id: null,
      after_command_id: null,
      cancel_command_id: null,
      command_states: [],
      command_external_states: [],
      eds_sign_command_fields: [],
      command_constraints: [],
      child_commands: [],
      logic_id: null,
      alias: null,
      is_timer: false,
      period: null,
      condition_type: ConditionTypes.state,
      condition_procedure_id: null,
      procedure_id: null,
      parent_tree_element_id: null
    })
  }

  static createChildCommand (name: string, logicId: number): CommandDTO {
    return new CommandDTO({
      name: name,
      description: null,
      target_state_id: null,
      plugin_id: null,
      event_id: null,
      is_plugin: false,
      is_complex: false,
      properties: null,
      before_command_id: null,
      after_command_id: null,
      cancel_command_id: null,
      command_states: [],
      command_external_states: [],
      eds_sign_command_fields: [],
      command_constraints: [],
      child_commands: [],
      logic_id: logicId,
      alias: null,
      is_timer: false,
      period: null,
      condition_type: ConditionTypes.state,
      condition_procedure_id: null,
      procedure_id: null,
      parent_tree_element_id: null
    })
  }
}
