import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { OpenWorldDTO } from '@/services/DigitalTwinEditor/domain/model/OpenWorld/OpenWorld'
import { TextureDTO } from '@/services/DigitalTwinEditor/domain/model/Texture/Texture'

export namespace OpenWorldAPI {

    export class UpdateOpenWorld implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: OpenWorldDTO) {
            this.path = `/digitaltwineditor/open_worlds/${params.guid}`;
        }
    }

    export class GetOpenWorldByGuid implements APIRequest<OpenWorldDTO> {
        response: OpenWorldDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/digitaltwineditor/open_worlds/${guid}`;
        }
    }

    export class SetDefaultTextures implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: OpenWorldDTO) {
            this.path = `/digitaltwineditor/open_worlds/${params.guid}/textures`;
        }
    }

    export class SetTerrain implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: OpenWorldDTO) {
            this.path = `/digitaltwineditor/open_worlds/${params.guid}/terrain`;
        }
    }

    export class SetLandscape implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: OpenWorldDTO) {
            this.path = `/digitaltwineditor/open_worlds/${params.guid}/landscapes`;
        }
    }

    export class StopTiling implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: OpenWorldDTO) {
            this.path = `/digitaltwineditor/open_worlds/${params.guid}/stop_tiling`;
        }
    }

    export class CountLandscapeTextures implements APIRequest<TextureDTO> {
        response:  TextureDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor (payload: string[][]) {
          this.path = `/digitaltwineditor/open_worlds/${payload['open_world_guid']}/textures?*[func]=count`
        }
    }
  
    export class GetLandscapeTextures implements APIRequest<TextureDTO> {
        response: TextureDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor (payload: string[][]) {
          this.path = `/digitaltwineditor/open_worlds/${payload['open_world_guid']}/textures?` + (new URLSearchParams(payload).toString())
        }
    }

    export class GetOpenWorldGeojson implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor (payload: string[][]) {
          this.path = `/digitaltwineditor/open_worlds/${payload['guid']}/geojson?id=${payload['id']}`
        }
    }

    export class GetOpenWorldStyle implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor (payload: string[][]) {
          this.path = `/digitaltwineditor/open_worlds/${payload['guid']}/style?id=${payload['id']}`
        }
    }

    export class UpdateStyle implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: OpenWorldDTO) {
            this.path = `/digitaltwineditor/open_worlds/${params.guid}/style?id=${params.style_id}`
        }
    }
}