import Model from './Model'

export default class Child extends Model {
  resource () {
    return 'children'
  }

  name () {
    return Object.values(this).join('')
  }

  get xrefDisabled () {
    return false
    // return !['text_field', 'integer_field', 'date_field', 'string_field', 'float_field', 'time_field', 'datetime_field'].find((item) => item === this.entity_type_id)
  }
}
