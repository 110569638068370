import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import SourceQueryRepositoryInterface from '../../../domain/repository/SourceQueryRepositoryInterface'
import SourceTypesQuery from '../../query/SourceTypesQuery'

export default class SourceTypesHandler implements QueryHandlerInterface
{
    private repo: SourceQueryRepositoryInterface;

    constructor(repo: SourceQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: SourceTypesQuery): Promise<any>
    {
        return this.repo.getTypes();
    }
}