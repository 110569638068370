<template>
  <div>
    <el-form-item label="Выбрать запрос">
      <el-select
        style="width: 100%"
        size="medium"
        v-model="content.queryId"
        filterable
        clearable
        @clear="clearQueryId"
      >
        <el-option
          v-for="item in listQuery"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        <span style="float: left">{{ item.name }}</span>
        <span style="float: right; color: #8492a6; font-size: 13px">ID: {{ item.id }}</span>
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="Выбрать поле">
      <el-select
         value-key="name"
         v-model="content.selectQueryField"
        :disabled="_isDisabled"
        @change="selectQueryField"
      >
        <el-option
          v-for="item in fieldQuery"
          :key="item.id"
          :label="item.name"
          :value="item"
        >
        </el-option>
      </el-select>
    </el-form-item>
  </div>
</template>

<script>
// Описание. Компонент для отображения списка запросов и выбор поля.

// API
import Query from '@/components/BIEditor/Models/Query'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { APIDataSource } from '@/components/InterfaceEditor/components/basic/AnalyticTable/api/DataSource'

export default {
  name: 'query-sourrce',
  props: {
    model: {
      type: Object
    }
  },
  data () {
    return {
      content: this.model,
      listQuery: [],
      fieldQuery: []
    }
  },
  computed: {
    _isDisabled () {
      return !this.content?.queryId
    }
  },
  watch: {
    'content.queryId': {
      async handler (value) {
        if (value) {
          this.fieldQuery = await this.getQueryField()
          this.content.selectQueryField = {}
          this.content.value = ''
          this.$emit('change', this.content)
        }
      }
    }
  },
  methods: {
    clearQueryId () {
      this.content.selectQueryField = {}
      this.content.value = ''
      this.$emit('change', this.content)
    },
    async getListQuery () {
      let response = await new Query().$get()
      return response
    },
    async getQueryField () {
      let response = await APIClient.shared.request(new APIDataSource.QueryQuery(this.model.queryId))
      if (response) {
        // вернуть ключи объекта
        return Object.entries(response[0]).map(([key, value]) => ({ id: key, name: key }))
      }
      return []
    },
    selectQueryField (e) {
      this.content.selectQueryField = e
      // content.value нужен для сохранения старых атрибутов
      this.content.value = e.name
      this.$emit('change', this.content)
    }
  },
  async mounted () {
    this.listQuery = await this.getListQuery()
    if (this.model.queryId) {
      this.fieldQuery = await this.getQueryField()
    }
  }
}
</script>
