import { MutationTree } from 'vuex'
import { RouteState, initialState } from './state'
import { MutationTypes, RouteCreatedEvent, RouteUpdatedEvent, RouteDeletedEvent } from './mutation-types'

export const mutations: MutationTree<RouteState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false;
    state.error = error;    
  },
  [MutationTypes.ROUTE_LOADING_EVENT]: (state) => {
    state.isLoading = true;
  },
  [MutationTypes.ROUTE_LOADED_EVENT]: (state) => {
    state.isLoading = false;
  },
  [MutationTypes.ROUTE_CREATED_EVENT]: (state, action: RouteCreatedEvent) => {
    state.location = action.location;
    state.isLoading = false;
  },
  [MutationTypes.ROUTE_UPDATED_EVENT]: (state, action: RouteUpdatedEvent) => {
    state.isLoading = false;
  },
  [MutationTypes.ROUTE_DELETED_EVENT]: (state, action: RouteDeletedEvent) => {
    state.isLoading = false;
  }
}