import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { LogicGroupDTO } from '@/services/LogicEditor/domain/model/LogicGroup'

export namespace LogicGroupAPI {
  export class GetLogicGroupByGuid implements APIRequest<LogicGroupDTO> {
    response: LogicGroupDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => new LogicGroupDTO(data.data)
    constructor (guid: string) {
      this.path = `/v2/logiceditor/logic_groups/${guid}`
    }
  }

  export class CreateLogicGroup implements APIRequest<string> {
    response: string;
    path = '/v2/logiceditor/logic_groups';
    method = HTTPMethod.POST;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.headers.location
    constructor (public params: LogicGroupDTO) {}
  }

  export class UpdateLogicGroup implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.PUT;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: LogicGroupDTO) {
      this.path = `/v2/logiceditor/logic_groups/${params.guid}`
    }
  }

  export class DeleteLogicGroup implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.DELETE;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/logiceditor/logic_groups/${guid}`
    }
  }
}
