<template>
    <el-form label-width="120px" label-position="top" size="mini">
        <el-form-item :label="$locale.interface_editor.component_editor.table.source_type">
            <editor-select :value="value.type" @change="$set(value ,'type', $event)"
                           :options="{multiple: false, options: $locale.interface_editor.component_editor.editor_accordion_source.data_types}"></editor-select>
        </el-form-item>
        <el-form-item v-if="value.type === 'registry'" :label="$locale.bi_editor.form.message.object_id.required">
          <registry-select
            :label="$locale.interface_editor.component_editor.editor_open_card.select_registry"
            :value="value.entityId"
            @change="$set(value ,'entityId', $event)"
          ></registry-select>
            <!-- <editor-registry-select :value="value.entityId" @change="$set(value ,'entityId', $event)"></editor-registry-select> -->
        </el-form-item>
        <el-form-item :label="$locale.interface_editor.component_editor.editor_accordion_source.title_field"
                      v-if="value.type !== null">
            <el-input v-model="value.titleField"></el-input>
        </el-form-item>
      <el-form-item :label="$locale.interface_editor.component_editor.editor_accordion_source.content_field"
                    v-if="value.type !== null">
        <el-input v-model="value.contentField"></el-input>
      </el-form-item>
    </el-form>
</template>

<script>
import EditorSelect from './editor-select'
// import EditorRegistrySelect from './editor-registry-select'

import RegistrySelect from '@/components/Common/RegistrySelectTreeWithSearch.vue'

export default {
  name: 'editor-tasks-source',
  components: {
    EditorSelect,
    // EditorRegistrySelect,
    RegistrySelect
  },
  props: {
    value: {
      type: Object,
      default () {
        return {
          type: null,
          entityId: null,
          titleField: null,
          contentField: null
        }
      }
    }
  },
  watch: {
    value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    },
    'value.type': {
      handler () {
        this.value.titleField = null
        this.value.entityId = null
        this.value.contentField = null
      }
    }
  }
}
</script>

<style scoped>

</style>
