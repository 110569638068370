import { FluxStandardAction } from '@/core/domain/type/types'
import { ProcedureDTO } from '@/services/LogicEditor/domain/model/Procedure'

export enum ActionTypes {
  GET_PROCEDURES = 'get_procedures',
  GET_PROCEDURE_BY_GUID = 'get_procedure_by_guid',
  CREATE_PROCEDURE = 'create_procedure',
  UPDATE_PROCEDURE = 'update_procedure',
  DELETE_PROCEDURE = 'delete_procedure'
}

export class GetProcedures implements FluxStandardAction {
  type = 'Procedure/' + ActionTypes.GET_PROCEDURES
  constructor (payload: string[][] | object) {}
}

export class GetProcedureByGuid implements FluxStandardAction {
  type = 'Procedure/' + ActionTypes.GET_PROCEDURE_BY_GUID
  constructor (public guid: string) {}
}

export class CreateProcedure implements FluxStandardAction {
  type = 'Procedure/' + ActionTypes.CREATE_PROCEDURE
  constructor (public payload: ProcedureDTO) {}
}

export class UpdateProcedure implements FluxStandardAction {
  type = 'Procedure/' + ActionTypes.UPDATE_PROCEDURE
  constructor (public payload: ProcedureDTO) {}
}

export class DeleteProcedure implements FluxStandardAction {
  type = 'Procedure/' + ActionTypes.DELETE_PROCEDURE
  constructor (public guid: string) {}
}
