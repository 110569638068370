import Model from './Model'
import Record from './Record'
import Structure from './Structure'
import StoredFilter from './StoredFilter'

export default class Registry extends Model {
  resource () {
    return 'registry'
  }
  data () {
    return this.hasMany(Record)
  }
  structure () {
    return this.hasMany(Structure)
  }
  filters () {
    return this.hasMany(StoredFilter)
  }
}
