export default {
  data () {
    return {
      // Список выбора для editor-input-type
      regexTemplatesOptions: [
        {
          label: 'Email',
          value: 'email'
        },
        {
          label: 'IPv4',
          value: 'ipv4'
        },
        {
          label: 'IPv6',
          value: 'ipv6'
        }
      ],

      regexTemplates: {
        email: {
          // https://www.regular-expressions.info/email.html  https://regex101.com/r/8zDEPF/3
          value: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z0-9]{2,}$'
        },
        ipv4: {
          // https://www.regular-expressions.info/ip.html  https://regex101.com/r/crOGBi/3
          value: '^(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\\.' +
                '(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\\.' +
                '(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\\.' +
                '(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])$'
        },
        ipv6: {
          // https://regex101.com/r/mVg9ug/3/
          value: '^(?:^|(?<=\\s))(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))(?=\\s|$)$'
        }
      }
    }
  }
}
