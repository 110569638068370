
import Treeselect from '@bingosoftnn/vue-treeselect'
import TopicsQuery from '@/services/Databus/application/query/TopicsQuery'
import TasksQuery from '@/services/Databus/application/query/TasksQuery'
import RoutesQuery from '@/services/Databus/application/query/RoutesQuery'
import RoutesCountQuery from '@/services/Databus/application/query/RoutesCountQuery'
import CommandsQuery from '@/services/LogicEditor/application/query/CommandsQuery'
import Route, { RouteDTO } from '@/services/Databus/domain/model/Route/Route'
import RouteCreateCommand from '@/services/Databus/application/command/RouteCreateCommand'
import RouteUpdateCommand from '@/services/Databus/application/command/RouteUpdateCommand'
import RouteDeleteCommand from '@/services/Databus/application/command/RouteDeleteCommand'

export default {
  name: 'RouteSmevPanel',
  components: {
    Treeselect
  },
  data () {
    return {
      loading: false,
      topicType: 'smev_request',
      routesPageSize: 0,
      defaultRoutesPageSize: 100,
      routesCurrentPage: 0,
      routesPageLimit: 100,
      routesCount: 0,
      allRoutesCount: 0,
      routes: [],
      route: null,
      routeDto: null,
      isDuplicate: false,
      informationPanelHeight: 0,
      isCreateRouteWindowVisible: false,
      topics: [],
      tasks: [],
      commands: [],
      currentTopicId: null,
      currentTaskId: null,
      currentCommands: null,
      topicMap: {},
      taskMap: {},
      types: [{
        id: 'response',
        name: this.$locale.databus.route_smev_panel.response
      }, {
        id: 'status',
        name: this.$locale.databus.route_smev_panel.status
      }, {
        id: 'reject',
        name: this.$locale.databus.route_smev_panel.reject
      }, {
        id: 'error',
        name: this.$locale.databus.route_smev_panel.error
      }],
      createRouteRules: {
        type: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        topic: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        task_id: {
          required: false,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        }
      }
    }
  },
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  watch: {
    routeDto: {
      handler: async function (curDto) {
        if (curDto != null) {
          if (curDto.topic !== this.currentTopicId && typeof curDto.topic !== 'undefined') {
            this.topics = this.topicMap[curDto.topic] || await this.getQueryBus().execute(
              new TopicsQuery({ 'code': curDto.topic })
            )
            this.currentTopicId = curDto.topic
            this.topicMap[curDto.topic] = this.topics
            if (this.topics.length) {
              curDto.topic_name = this.topics[0].name
            }
          }
          if (curDto.task_id !== this.currentTaskId && typeof curDto.task_id !== 'undefined') {
            this.tasks = this.taskMap[curDto.task_id] || await this.getQueryBus().execute(
              new TasksQuery({ 'id': curDto.task_id })
            )
            this.currentTaskId = curDto.task_id
            this.taskMap[curDto.task_id] = this.tasks
            if (this.tasks.length) {
              curDto.task_name = this.tasks[0].name
            }
          }
        }
      },
      deep: true
    },
    routeCreated: function (location) {
      this.loadRoutes(() => {
        this.closeCreateRouteWindow()
      })
    },
    isRouteLoading: (state) => state,
    getSaveRouteError: function (err) {
      if (err.code == 'duplicate_key_violation_exception') {
        this.isDuplicate = true
        this.$message({
          message: this.$locale.databus.route_smev_panel.duplicate_route_code,
          type: 'warning'
        })
      }
    }
  },
  computed: {
    isRouteLoading () {
      return this.$store.getters['Route/isLoading']
    },
    routeCreated () {
      return this.$store.getters['Route/getLocation']
    },
    getSaveRouteError () {
      return this.$store.getters['Route/getError']
    }
  },
  methods: {
    loadRoutes (callback) {
      if (this.allRoutesCount == 0) {
        this.countAndLoadRoutes(callback)
      } else {
        this.loadAllRoutes(callback)
      }
    },
    async countAndLoadRoutes (callback) {
      await this.getQueryBus().execute(
        new RoutesCountQuery({
          topic_type: this.topicType
        })
      ).then(data => {
        this.routesCount = data[0].count
        this.loadAllRoutes(callback)
      })
    },
    async loadAllRoutes (callback) {
      await this.getQueryBus().execute(
        new RoutesQuery({
          limit: this.routesPageLimit,
          offset: this.routesCurrentPage,
          topic_type: this.topicType
        })
      ).then(data => {
        this.routesPageSize = this.routesPageLimit
        this.routes = data
        if (typeof callback === 'function') {
          callback()
        }
      })
    },
    createRoute () {
      this.routeDto = new RouteDTO({})
      this.routeDto.priority = 0
      this.isCreateRouteWindowVisible = true
    },
    updateRoute () {
      if (this.route !== null) {
        this.isCreateRouteWindowVisible = true
      } else {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        })
      }
    },
    saveRoute () {
      let form = this.$refs.route_smev_panel
      form.validate(valid => {
        if (valid) {
          let route = Route.create(this.routeDto)
          if (route.getId() == null) {
            this.getCommandBus().execute(
              new RouteCreateCommand(
                route.getTopic(),
                route.getTaskId(),
                route.getFileName(),
                route.getPriority(),
                route.getType(),
                route.getCommands(),
                route.getProperties()
              )
            )
          } else {
            this.getCommandBus().execute(
              new RouteUpdateCommand(
                route.getGuid(),
                route.getTopic(),
                route.getTaskId(),
                route.getFileName(),
                route.getPriority(),
                route.getType(),
                route.getCommands(),
                route.getProperties()
              )
            ).then(() => {
              if (this.isDuplicate) {
                this.isDuplicate = false
              } else {
                this.closeCreateRouteWindow()
              }
            })
          }
        }
      })
    },
    closeCreateRouteWindow () {
      this.isCreateRouteWindowVisible = false
    },
    changeRoute (routeDto) {
      if (routeDto !== null) {
        this.route = Route.create(routeDto)
        this.routeDto = routeDto
      } else {
        this.route = null
        this.routeDto = null
      }
    },
    deleteRoute () {
      if (this.route == null) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        })
      } else {
        this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
          confirmButtonText: this.$locale.main.button.delete,
          cancelButtonText: this.$locale.main.button.cancel,
          type: 'warning'
        }).then(async () => {
          this.getCommandBus().execute(
            new RouteDeleteCommand(
              this.route.getGuid()
            )
          ).then((response) => {
            this.route = null
            this.$refs.smev_response_routes_table.setCurrentRow(null)
            this.loadRoutes()
          })
        }).catch((error) => { console.log(error) })
      }
    },
    handleRoutesPageChange (val) {
      val--
      this.routesCurrentPage = (val * this.routesPageLimit)
      this.loadRoutes()
    },
    async loadTopics () {
      this.topics = await this.getQueryBus().execute(
        new TopicsQuery({
          type: this.topicType
        })
      )
    },
    async loadTasks () {
      this.tasks = await this.getQueryBus().execute(
        new TasksQuery()
      )
    },
    getCommandNameById (id) {
      let command = this.commands.find(item => item.id == id)
      if (typeof command === 'undefined') {
        return ''
      }
      return command.name
    },
    async loadCommands () {
      this.commands = await this.getQueryBus().execute(
        new CommandsQuery({
          fields: 'id,name',
          order: 'name:asc'
        })
      )
    }
  },
  mounted () {
    this.loadCommands()
    this.loadRoutes()
  }
}
