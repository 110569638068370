import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { StateDTO, StateOperationTypeDTO, StateFieldTypeDTO, ConditionFieldTypeDTO } from '@/services/LogicEditor/domain/model/State'

export namespace StateAPI {

  export class GetStateByGuid implements APIRequest<StateDTO> {
    response: StateDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => new StateDTO(data.data)
    constructor (guid: string) {
      this.path = `/v2/logiceditor/states/${guid}`
    }
  }

  export class GetStateOperationTypes implements APIRequest<StateOperationTypeDTO> {
    response: StateOperationTypeDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data.map(item => new StateOperationTypeDTO(item))
    constructor () {
      this.path = `/v2/logiceditor/state_operation_types`
    }
  }

  export class GetStateOperationTypeEntityTypes implements APIRequest<object> {
    response: object;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor () {
      this.path = `/v2/logiceditor/state_operation_type_entity_types`
    }
  }

  export class GetStateFieldTypes implements APIRequest<StateFieldTypeDTO> {
    response: StateFieldTypeDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data.map(item => new StateFieldTypeDTO(item))
    constructor () {
      this.path = `/v2/logiceditor/state_field_types`
    }
  }

  export class GetConditionFieldTypes implements APIRequest<ConditionFieldTypeDTO> {
    response: ConditionFieldTypeDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data.map(item => new ConditionFieldTypeDTO(item))
    constructor () {
      this.path = `/v2/logiceditor/condition_field_types`
    }
  }

  export class CreateState implements APIRequest<string> {
    response: string;
    path = '/v2/logiceditor/states';
    method = HTTPMethod.POST;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.headers.location
    constructor (public params: StateDTO) {}
  }

  export class UpdateState implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.PUT;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: StateDTO) {
      this.path = `/v2/logiceditor/states/${params.guid}`
    }
  }

  export class DeleteState implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.DELETE;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/logiceditor/states/${guid}`
    }
  }
}
