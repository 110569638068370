<template>
  <el-popover trigger="click" placement="top">
    <registry-select-tree
      v-model="localValue"
      type="registry"
      value-as="number"
      @input="$emit('change', localValue)"
    ></registry-select-tree>

    <template slot="reference">
      <el-button type="text" class="label">
        {{ label || $locale.main.button.edit }}
      </el-button>
      <el-tooltip v-if="tooltip" placement="top-start" :open-delay="400" style="margin-left: 5px;">
        <div slot="content" v-html="tooltip"></div>
        <i class="el-icon-question"></i>
      </el-tooltip>
    </template>
  </el-popover>
</template>

<script>
import RegistrySelectTree from '@/components/Common/RegistrySelectTree.vue'

export default {
  name: 'editor-registry-select',
  components: {
    RegistrySelectTree
  },
  props: {
    value: {
      type: [Object, Number, String]
    },
    label: {
      type: String
    },
    tooltip: {
      type: String
    }
  },
  data () {
    return {
      localValue: this.value
    }
  }
}
</script>

<style scoped>
  .label {
    font-size: 1em;
  }
</style>
