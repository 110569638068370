import QueryBusHandlerLocator from './QueryBusHandlerLocator'
import QueryInterface from '../QueryInterface'
import HandlerNotFound from '../../exception/HandlerNotFound'

export default class QueryBus {
    private locator: QueryBusHandlerLocator;

    constructor (locator: QueryBusHandlerLocator) {
      this.locator = locator
    }

    async execute (query: QueryInterface): Promise<any> {
      let handler = this.locator.getHandler(query)
      if (handler) {
        return await handler.execute(query)
      } else {
        throw new HandlerNotFound(query.constructor.name)
      }
    }
}
