import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import StructureRepositoryInterface from '@/services/RegistryTable/domain/repository/StructureRepositoryInterface'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { StructureAPI } from '@/services/RegistryTable/infrastructure/api/StructureAPI'

export default class StructureRepository extends AbstractQueryRepository implements StructureRepositoryInterface {
  getById (id: number): Promise<any> {
    return APIClient.shared.request(new StructureAPI.GetStructure(id))
  }
}
