<template>
  <div>
    <el-input placeholder="URL" style="width: 200px; margin-top: 5px;" size="small" v-model="value.urlImage">
      <template slot="append">
        <el-upload
          :headers="getHeaders()"
          class="upload-demo"
          ref="upload"
          :action="$config.api + '/registryservice/files/'"
          :on-change="numberFiles"
          :file-list="fileList"
          :http-request="save"
          :show-file-list="false">
            <el-button size="small"  icon="el-icon-download" type="primary" :title="this.$locale.interface_editor.toolbar.upload_image"></el-button>
        </el-upload>
      </template>
    </el-input>
    <el-tooltip class="item" effect="dark" placement="right">
      <div slot="content">
        {{ $locale.interface_editor.settings.card.size_image }}:<br/>
        100%<br/>
        100% 100%<br/>
        auto<br/>
        contain<br/>
        cover<br/>
      </div>
      <el-input :placeholder="$locale.interface_editor.settings.card.size_image" style="width: 200px;" size="small" v-model="value.fitImage"/>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: 'editor-background-card',
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      fileList: []
    }
  },
  methods: {
    getHeaders () {
      return {
        Authorization: localStorage.getItem('user-token')
      }
    },
    numberFiles (file, fileList) {
      if (file.raw.type !== 'image/jpeg' && file.raw.type !== 'image/png' && file.raw.type !== 'image/svg+xml') {
        this.$refs.upload.uploadFiles = []
        this.$message.error(this.$locale.access_editor.users_item.error_avatar)
        return false
      }
    },
    async save () {
      if (this.$refs.upload.uploadFiles.length) {
        let fileObj = this.$refs.upload.uploadFiles[0].raw
        const fd = new FormData()
        fd.append('file', fileObj)
        let res = await this.$http.post(this.$config.api + '/registryservice/files', fd)
        let res1 = await this.$http.get(`${this.$config.api}/registryservice/files/${res.data.id}`)
        this.value.urlImage = `/files/${this.getFilePath(res1.data)}`
      }
    }
  }

}
</script>

<style scoped>

</style>
