import QueryInterface from '@/core/application/query/QueryInterface'
import { Nullable } from '@/core/domain/type/types'

export default class LayerTreeElementsQuery implements QueryInterface
{
    private parentId: Nullable<number>;

    private projectId: number;

    constructor(parentId: Nullable<number>, projectId: number)
    {
        this.parentId = parentId;
        this.projectId = projectId;
    }

    getParentId(): Nullable<number>
    {
        return this.parentId;
    }

    getProjectId(): number
    {
        return this.projectId;
    }

    getClassName(): string
    {
        return 'LayerTreeElementsQuery';
    }
}