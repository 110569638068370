import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { AffineTransformationDTO } from '@/services/MapEditor/domain/model/Geometry/AffineTransformation'

export enum ActionTypes {
    ADD_AFFINE_TRANSFORMATION = 'add_affine_transformation',
    UPDATE_AFFINE_TRANSFORMATION = 'update_affine_transformation',
    DELETE_AFFINE_TRANSFORMATION = 'delete_affine_transformation',
    GET_AFFINE_TRANSFORMATION_BY_GUID = 'get_affine_transformation_by_guid'
}

export class AddAffineTransformation implements FluxStandardAction {
    type = 'AffineTransformation/' + ActionTypes.ADD_AFFINE_TRANSFORMATION
    constructor(public payload: AffineTransformationDTO){}
}

export class UpdateAffineTransformation implements FluxStandardAction {
    type = 'AffineTransformation/' + ActionTypes.UPDATE_AFFINE_TRANSFORMATION
    constructor(public payload: AffineTransformationDTO){}
}

export class DeleteAffineTransformation implements FluxStandardAction {
    type = 'AffineTransformation/' + ActionTypes.DELETE_AFFINE_TRANSFORMATION
    constructor(public guid: string){}
}

export class GetAffineTransformationByGuid implements FluxStandardAction {
    type = 'AffineTransformation/' + ActionTypes.GET_AFFINE_TRANSFORMATION_BY_GUID
    constructor(public guid: string){}
}