import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import ModelInstanceQueryRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/ModelInstanceQueryRepositoryInterface'
import store from '@/store'
import { GetModelInstanceByGuid } from '@/services/DigitalTwinEditor/infrastructure/store/modules/ModelInstance/action-types'

export default class ModelInstanceQueryRepository extends AbstractQueryRepository implements ModelInstanceQueryRepositoryInterface
{
    getByGuid(guid: string): Promise<any>
    {
        return store.dispatch(new GetModelInstanceByGuid(guid));
    }
}