import Model from './Model'
import Child from './Child'

export default class Type extends Model {
  resource () {
    return 'types'
  }

  name () {
    return Object.values(this).join('')
  }

  children () {
    return this.hasMany(Child)
  }
}
