<template>
  <div>
    <tree-select v-if="dataSource.type === 'registry'"
                 v-model="condition[property]"
                 placeholder="Выберите"
                 :data="listFields"
                 :props="fields"
                 :render-content="renderContent"
                 :render-option-label="renderOptionLabel"
    ></tree-select>
    <el-select v-else
               v-model="condition[property]"
               value-key="name">
      <el-option v-if="dataSource.type === 'query'"
                 v-for="(item, index) in listFields"
                 :key="index"
                 :label="item.name"
                 :value="item.id">
      </el-option>
      <el-option v-else
                 v-for="(item, index) in listFields"
                 :key="index"
                 :label="item.name"
                 :value="item.name">
      </el-option>
    </el-select>
  </div>
</template>

<script>
  import TreeSelect from '@/components/Common/TreeSelect.vue'
  export default {
    name: "select-field-condition",
    components: {
      TreeSelect
    },
    props: {
      value: {
        type: Object
      },
      property: {
        type: String
      },
      index: {
        type: Number
      },
      dataSource: {
        type: Object
      },
      condition: {
        type: Object
      }
    },
    data () {
      return {
        listFields: [],
        fields: {
          children: 'children',
          isLeaf: 'isLeaf',
          label: 'name'
        },
      }
    },
    async mounted() {
      this.listFields = await this.getFields(this.dataSource.entityId, this.dataSource.type)
    },
    watch: {
      'condition': {
        handler () {
          let conditions = this.value.listData.conditions
          conditions[this.index] = this.condition
          this.$set(this.value.listData, 'conditionMoving', conditions)
        }
      }
    },
    methods: {
      async getFields(objectId, type){
        if (objectId && type === 'registry') {
          let res = await this.$http
            .get(`${this.$config.api}/objecteditor/entities?parent_id=${objectId}&show_children=true`)
          return res.data.data
        } else if (objectId && type === 'extended_object') {
          let res = await this.$http
            .get(`${this.$config.api}/bieditor/extended_object_fields?extended_object_id=${objectId}`)
          return res.data
        } else if (objectId && type === 'query') {
          let res = await this.$http
            .get(`${this.$config.api}/bieditor/queries/fields/${objectId}`)
          return res.data
        }
        return []
      },
      renderOptionLabel (item) {
        return `${item.name} (id: ${item.id}, ${this.$t('object_editor.entity_types.' + item.entity_type_id)})`
      },
      renderContent (h, { node, data, store }) {
        return h('span', {
          class: 'custom-tree-node'
        }, [
          h(
            'span', {
              class: 'node-label'
            }, [
              h(
                'span',
                {
                  class: 'node-label__name'
                },
                node.label
              ),
              h(
                'span',
                {
                  class: 'node-label__info'
                },
                `${this.$t('object_editor.entity_types.' + data.entity_type_id)} id ${data.id}`
              )
            ])
        ])
      },
    }
  }
</script>
