<template>
  <el-form label-width="120px" label-position="top" size="mini">
    <el-collapse-item title="Источник данных">
      <source-data v-model="value.sourceData"></source-data>
      <div v-if="value.sourceData.entityId">
        <el-checkbox v-model="value.sourceData.customSorting">Произвольная сортировка</el-checkbox>
        <el-form-item label="Поле для сортировки внутри столбца">
          <el-tooltip v-if="value.sourceData.customSorting" placement="left-end" :open-delay="700" effect="dark">
            <div slot="content">
              <p>Поле должно быть дробным и иметь после запятой 3 знака</p>
            </div>
            <select-field v-model="value.sourceData" property="sorting"></select-field>
          </el-tooltip>
          <select-field v-else v-model="value.sourceData" property="sorting"></select-field>
        </el-form-item>
        <el-form-item label="Сортировать" v-if="value.sourceData.sorting">
          <el-radio-group v-if="!value.sourceData.customSorting" v-model="value.sourceData.sortingDirection">
            <el-radio-button label="ASC">По возрастанию</el-radio-button>
            <el-radio-button label="DESC">По убыванию</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </div>
      <el-form-item label="Лимит записей">
        <el-input type="number" size="small" v-model="value.sourceData.limit">
        </el-input>
      </el-form-item>
      <editor-filters v-model="value.sourceData.filters"></editor-filters>
    </el-collapse-item>
    <el-collapse-item title="Группировка столбцов" v-if="value.sourceData.entityId">
      <el-checkbox v-model="value.listData.isAdditionalSource">Дополнительный источник <br/>данных</el-checkbox>
      <div v-if="value.listData.isAdditionalSource">
        <source-data v-model="value.listData"/>
      </div>
      <div v-if="value.listData.isAdditionalSource === false">
        <el-form-item :label="$locale.interface_editor.component_editor.editor_tasks_source.group.field">
          <select-field v-model="value.sourceData" property="field"/>
        </el-form-item>
      </div>
      <div v-if="value.listData.isAdditionalSource && value.listData.entityId">
        <el-form-item>
          <el-tooltip placement="left-end" :open-delay="700" effect="dark">
            <div slot="content">
              <p>Выбрать этот параметр, если поле из основного источника явялется ссылкой на запись группировки столбцов</p>
            </div>
            <el-checkbox v-model="value.sourceData.fieldIsLink">
              Поле из основного источника<br> является ссылкой<br> на источник группировки
            </el-checkbox>
          </el-tooltip>
        </el-form-item>
        <el-form-item v-if="value.sourceData.fieldIsLink" label="Поле из основного источника">
          <select-field v-model="value.sourceData" property="field"/>
        </el-form-item>
        <el-form-item v-if="value.sourceData.fieldIsLink === false" label="Сопоставление полей">
          <el-button type="text" @click="dialogSettingsVisible = true">{{ $locale.main.button.edit }}</el-button>
          <el-dialog title="Сопоставление столбцов"
                     :modal="false"
                     :visible.sync="dialogSettingsVisible">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Поле из основного источника">
                  <select-field v-model="value.sourceData" property="field"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Поле из источника группировки">
                  <select-field v-model="value.listData" property="field"/>
                </el-form-item>
              </el-col>
            </el-row>
          </el-dialog>
        </el-form-item>
        <el-form-item>
          <el-tooltip placement="left-end" :open-delay="700" effect="dark">
            <div slot="content">
              <p>Выбрать этот параметр, если необходимо сразу отображать<br>
                все записи из источника группировки строк</p>
            </div>
            <el-checkbox v-model="value.listData.allLists">
              Отображать все столбцы
            </el-checkbox>
          </el-tooltip>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="value.listData.hasColumnEmptyTasks">
            Добавить столбец<br> с нераспределенными плитками<br> или пустой столбец
          </el-checkbox>
        </el-form-item>
        <el-form-item :label="$locale.interface_editor.component_editor.editor_tasks_source.condition_moving">
          <el-button type="text" @click="dialogSettingsConditionVisible = true">{{ $locale.main.button.edit }}</el-button>
          <el-dialog :title="$locale.interface_editor.component_editor.editor_tasks_source.condition_moving"
                     :modal="false"
                     :visible.sync="dialogSettingsConditionVisible">
              <condition-fields v-model="value"></condition-fields>
          </el-dialog>
        </el-form-item>
      </div>
      <el-form-item :label="$locale.interface_editor.component_editor.editor_tasks_source.additional_fields">
        <additional-fields v-model="value.listData"/>
      </el-form-item>
      <el-form-item label="Поле для сортировки">
        <el-checkbox v-if="value.listData.isAdditionalSource" v-model="value.listData.sortingByAdditionalField">
          Из дополнительных полей
        </el-checkbox>
        <el-input v-if="value.listData.sortingByAdditionalField || !value.listData.isAdditionalSource" placeholder="Поле строкой" v-model="value.listData.sorting"/>
        <select-field v-else-if="this.value.listData.isAdditionalSource" v-model="value.listData" property="sorting"/>
      </el-form-item>
      <el-form-item label="Сортировать" v-if="value.listData.sorting || !value.listData.isAdditionalSource">
        <el-radio-group v-model="value.listData.sortingDirection">
          <el-radio-button label="ASC">По возрастанию</el-radio-button>
          <el-radio-button label="DESC"> По убыванию</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <editor-filters v-model="value.listData.filters"></editor-filters>
    </el-collapse-item>
  </el-form>
</template>

<script>
import AdditionalFields from "./Tasks/AdditionalFields";
import SourceData from './Tasks/SourceData'
import EditorSelect from './editor-select'
import EditorRegistrySelect from './editor-registry-select'
import RegistrySelectTree from '@/components/Common/RegistrySelectTree.vue'
import TreeSelect from '@/components/Common/TreeSelect.vue'
import SelectField from "./Tasks/SelectField"
import EditorNumber from './editor-number'
import FilterBuilder, { EComponentTypes } from '../utils'
import EditorFilters from "./editor-filters";
import ConditionFields from "./Tasks/ConditionFields";

export default {
  name: 'editor-tasks-source',
  components: {
    ConditionFields,
    EditorFilters,
    FilterBuilder,
    EditorSelect,
    EditorRegistrySelect,
    RegistrySelectTree,
    TreeSelect,
    SourceData,
    SelectField,
    AdditionalFields,
    EditorNumber
  },
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      dialogSettingsVisible: false,
      dialogSettingsConditionVisible: false
    }
  },
  watch: {
    'value.sourceData.customSorting': {
      handler () {
        this.value.listData.sortingDirection = "ASC"
      }
    },
    'value.listData.isAdditionalSource': {
      handler () {
        this.value.listData.sorting = null
      }
    },
    'value.listData.fieldIsLink': {
      handler () {
        this.value.listData.field = null
      }
    },
    'value.listData.sortingByAdditionalField': {
      handler () {
        this.value.listData.sorting = null
      }
    },
    value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    },
  },
  mounted () {
    this.value = this.updateParams(this.value)
  },
  methods: {
    updateParams (source) {
      if (source.sourceData === undefined && source.listData === undefined) {
        let newSource = {}
        newSource.listData = {}
        newSource.listData.additionalFields = source.additionalFields
        newSource.listData.isAdditionalSource = false
        newSource.listData.filters = []
        newSource.sourceData = {}
        newSource.sourceData.entityId = source.entityId
        newSource.sourceData.type = source.type
        newSource.sourceData.field = parseInt(source.group_list_field.match(/\d+/))
        newSource.sourceData.sortingDirection = 'ASC'
        newSource.sourceData.filters = []
        newSource.sourceData.limit = this.$attrs.limit
        return newSource
      }
      return source
    },
    open () {
      this.$alert('This is a message', 'Title', {
        confirmButtonText: 'OK',
        callback: action => {
          this.$message({
            type: 'info',
            message: `action: ${ action }`
          });
        }
      });
    },
    async getFields(objectId){
      if(!objectId){
        return []
      }else{
        let res = await this.$http
          .get(`${this.$config.api}/objecteditor/entities?parent_id=${objectId}&show_children=true`)
        return res.data.data
      }
    }
  }
}
</script>
