import { FluxStandardAction } from '@/core/domain/type/types'
import { ApprovalStageDTO } from '@/services/LogicEditor/domain/model/ApprovalStage'

export enum ActionTypes {
  GET_APPROVAL_STAGE_BY_GUID = 'get_approval_stage_by_guid',
  CREATE_APPROVAL_STAGE = 'create_approval_stage',
  UPDATE_APPROVAL_STAGE = 'update_approval_stage',
  DELETE_APPROVAL_STAGE = 'delete_approval_stage'
}

export class GetApprovalStageByGuid implements FluxStandardAction {
  type = 'ApprovalStage/' + ActionTypes.GET_APPROVAL_STAGE_BY_GUID
  constructor (public guid: string) {}
}

export class CreateApprovalStage implements FluxStandardAction {
  type = 'ApprovalStage/' + ActionTypes.CREATE_APPROVAL_STAGE
  constructor (public payload: ApprovalStageDTO) {}
}

export class UpdateApprovalStage implements FluxStandardAction {
  type = 'ApprovalStage/' + ActionTypes.UPDATE_APPROVAL_STAGE
  constructor (public payload: ApprovalStageDTO) {}
}

export class DeleteApprovalStage implements FluxStandardAction {
  type = 'ApprovalStage/' + ActionTypes.DELETE_APPROVAL_STAGE
  constructor (public guid: string) {}
}
