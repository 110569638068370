import { MutationTree } from 'vuex'
import { CoordinateSystemState, initialState } from './state'
import { MutationTypes, CoordinateSystemCreatedEvent, CoordinateSystemUpdatedEvent, CoordinateSystemDeletedEvent } from './mutation-types'

export const mutations: MutationTree<CoordinateSystemState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false;
    state.error = error;    
  },
  [MutationTypes.COORDINATE_SYSTEM_LOADING_EVENT]: (state) => {
    state.isLoading = true;
  },
  [MutationTypes.COORDINATE_SYSTEM_LOADED_EVENT]: (state) => {
    state.isLoading = false;
  },
  [MutationTypes.COORDINATE_SYSTEM_CREATED_EVENT]: (state, action: CoordinateSystemCreatedEvent) => {
    state.location = action.location;
    state.isLoading = false;
  },
  [MutationTypes.COORDINATE_SYSTEM_UPDATED_EVENT]: (state, action: CoordinateSystemUpdatedEvent) => {
    state.isLoading = false;
  },
  [MutationTypes.COORDINATE_SYSTEM_DELETED_EVENT]: (state, action: CoordinateSystemDeletedEvent) => {
    state.isLoading = false;
  }
}