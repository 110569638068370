export default class RegionUpdateCommand {
  private readonly code: string;
  private readonly isUpdatable: boolean;
  private readonly hasHouses: boolean;
  private readonly hasAddressObjects: boolean;

  constructor (code: string, isUpdatable: boolean, hasHouses: boolean, hasAddressObjects: boolean) {
    this.code = code
    this.isUpdatable = isUpdatable
    this.hasHouses = hasHouses
    this.hasAddressObjects = hasAddressObjects
  }

  getClassName (): string {
    return 'RegionUpdateCommand'
  }

  getCode () {
    return this.code
  }

  getIsUpdatable () {
    return this.isUpdatable
  }

  getHasHouses () {
    return this.hasHouses
  }

  getHasAddressObjects () {
    return this.hasAddressObjects
  }
}
