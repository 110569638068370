<template>
  <el-dialog class="import-log" width="70%" title="Лог импорта" :visible.sync="show" :before-close="close" :border="true" append-to-body>
    <template v-slot:title>
      <div style="display: flex;align-items: center;">
        <el-button
          v-if="detail.show"
          type="default"
          size="small"
          @click="detail.show = false"
          icon="el-icon-arrow-left"
          style="margin-right: 20px;"
        ></el-button>

        <h2 style="margin: 0;">Логи импорта</h2>
      </div>
    </template>

    <div v-show="!detail.show">
      <el-table border v-loading="isLoading" :height="600" :data="grid.data">
        <el-table-column property="has_errors" :label="$t('etl_editor.log.table.has_errors')" width="110">
          <template slot-scope="scope">
            <template v-if="!scope.row.has_errors">
              {{ $t('etl_editor.log.table.error_no') }}
            </template>

            <template v-else>
              <el-button
                size="mini"
                @click="loadDetail(scope.$index, scope.row)"
              >
                Детали
              </el-button>
            </template>
          </template>
        </el-table-column>

        <el-table-column property="task" :label="$t('etl_editor.log.table.task')"></el-table-column>

        <el-table-column property="user" :label="$t('etl_editor.log.table.user')"></el-table-column>

        <el-table-column property="load_date" :label="$t('etl_editor.log.table.load_date')" width="160"></el-table-column>

        <el-table-column property="file_name" :label="$t('etl_editor.log.table.file_name')"></el-table-column>

        <el-table-column property="inserted" :label="$t('etl_editor.log.table.inserted')" width="100"></el-table-column>

        <el-table-column property="updated" :label="$t('etl_editor.log.table.updated')" width="100"></el-table-column>

        <el-table-column property="not_loaded" :label="$t('etl_editor.log.table.not_loaded')" width="100"></el-table-column>
      </el-table>

      <el-pagination
        @current-change="pageChange"
        :page-size="grid.pagination.limit"
        layout="total, prev, pager, next"
        style="background-color: #fff;"
        :total="grid.count"
      ></el-pagination>
    </div>

    <div v-show="detail.show">
      <el-table border v-loading="isLoading" :height="600" :data="detail.data">
        <el-table-column property="row_number" :label="$t('etl_editor.log.detail.row_number')"></el-table-column>
        <el-table-column property="incorrect_fields" :label="$t('etl_editor.log.detail.incorrect_fields')"></el-table-column>
        <el-table-column property="required_fields" :label="$t('etl_editor.log.detail.required_fields')"></el-table-column>
        <el-table-column property="exception" :label="$t('etl_editor.log.detail.exception')"></el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>

<script>
import Template from '../EtlEditor/Properties/Template'
export default {
  name: 'ImportLog',
  components: { Template },
  props: {
    show: {
      type: Boolean,
      default: false
    },

    objectId: {
      type: Number,
      default: null
    },

    close: {
      type: Function,
      default: function () {
      }
    }
  },

  data () {
    return {
      grid: {
        count: 0,
        data: [],
        pagination: {
          current: 0,
          limit: 30
        }
      },
      detail: {
        id: null,
        data: [],
        show: false
      },
      isLoading: false,
      error: null
    }
  },

  methods: {
    load () {
      this.useFetch(async () => {
        const resCount = await this.$http.get(`${this.$config.api}/etleditor/import/log?*[func]=count&object_id=${this.objectId}`)

        this.grid.count = resCount.data.length ? resCount.data[0].count : 0

        const resTable = await this.$http
          .get(`${this.$config.api}/etleditor/import/log?object_id=${this.objectId}&offset=${this.grid.pagination.current}&limit=${this.grid.pagination.limit}&order=load_date:desc`)

        this.grid.data = resTable.data || []
      })
    },

    loadDetail (rowIndex, row) {
      this.detail.show = true

      if (this.detail.id !== row.id) {
        this.detail.data = []

        this.useFetch(async () => {
          const res = await this.$http
            .get(`${this.$config.api}/etleditor/import/log/rows/${row.id}`)

          this.detail.data = res.data || []
          this.detail.id = row.id
        })
      }
    },

    async useFetch (callback) {
      try {
        this.isLoading = true

        await callback()
      } catch (e) {
        console.error(e)

        this.error = e
      } finally {
        this.isLoading = false
      }
    },

    pageChange (val) {
      val--
      this.grid.pagination.current = (val * this.grid.pagination.limit)

      this.load()
    }
  }
}
</script>

<style scoped>
  .import-log .el-dialog__body {
    padding: 0;
  }

  .import-log .el-dialog__header {
    padding: 18px;
  }

  .detail-box {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid rgb(235, 238, 245);
  }

  .detail-box p {
    margin: 5px 0;
  }
</style>
