export interface StateState {
  isLoading: boolean
  location: string
  deletedStateGuid: string
  error: object
}

export const initialState = (): StateState => {
  return {
    isLoading: false,
    location: null,
    deletedStateGuid: null,
    error: null
  }
}
