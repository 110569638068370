import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    ENTITIES_LOADING_EVENT = 'entities_loading_event',
    ENTITIES_LOADED_EVENT = 'entities_loaded_event' 
}

export class EntitiesLoadingEvent implements FluxStandardAction {
    type = MutationTypes.ENTITIES_LOADING_EVENT
}

export class EntitiesLoadedEvent implements FluxStandardAction {
    type = MutationTypes.ENTITIES_LOADED_EVENT
}