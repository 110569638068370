import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { LayerDTO } from '@/services/DigitalTwinEditor/domain/model/Layer/Layer'

export namespace LayerAPI {

    export class UpdateLayer implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: LayerDTO) {
            this.path = `/digitaltwineditor/layers/${params.guid}`;
        }
    }

    export class GetLayerByGuid implements APIRequest<LayerDTO> {
        response: LayerDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/digitaltwineditor/layers/${guid}`;
        }
    }
}