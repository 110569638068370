<template>
  <div>
    <el-dropdown trigger="click" class="dropdown-button" @command="handleDropdown" size="mini">
      <span class="el-dropdown-link">
        Экспорт/импорт настроек <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="export">
          <i style="font-size: 16px;" class="el-icon-download"></i>
          экспорт
        </el-dropdown-item>
        <el-dropdown-item command="import">
          <i style="font-size: 16px;" class="el-icon-upload2"></i>
          импорт
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <el-dialog
      title="Экспорт настроек слоев"
      :modal="false"
      :visible.sync="showExportWindow"
      class="window">
      <el-tree
        ref="tree"
        node-key="guid"
        :props="{ label:'name', children: 'children' }"
        :data="layersTree"
        :expand-on-click-node="false"
        show-checkbox
      ></el-tree>
      <br>
      <el-button @click="exportSettings">Экспортировать</el-button>
    </el-dialog>
    <el-dialog
      title="Импорт настроек слоев"
      :modal="false"
      :visible.sync="showImportWindow"
      class="window">
      <file-loader @load="importJSONString = $event"></file-loader>
      <el-checkbox v-model="importCreateNewObjects" label="Пересоздать guid у импортируемых слоев и групп"></el-checkbox>
      <br>
      <el-button @click="importSettings">Загрузить</el-button>
    </el-dialog>
  </div>
</template>

<script>
  import FileLoader from './FileLoader'

  export default {
    name: 'SettingsManager',
    components: { FileLoader },
    props: {
      layers: {
        type: Array
      }
    },
    data: function () {
      return {
        layersTree: this.layers,
        showExportWindow: false,
        showImportWindow: false,
        importJSONString: null,
        importCreateNewObjects: false
      }
    },
    methods: {
      handleDropdown (type) {
        if (type === 'export') {
          this.showExportWindow = true
        }
        if (type === 'import') {
          this.showImportWindow = true
        }
      },
      importSettings () {
        let importJSON = JSON.parse(this.importJSONString)
        if (this.importCreateNewObjects) {
          importJSON = this.regenerateGuidesRecursively(importJSON)
        }
        this.$emit('import-settings', importJSON)
      },
      regenerateGuidesRecursively (layers) {
        for (let i = 0; i < layers.length; i++) {
          layers[i].guid = this.generateGuid()
          if (layers[i].type === 'group') {
            layers.children = this.regenerateGuidesRecursively(layers[i].children)
          }
        }
        return layers
      },
      exportSettings () {
        // есть список гуидов выбранных слоев и групп
        const checkedNodes = this.$refs['tree'].getCheckedNodes(false, true).map(x => x.guid)
        // нужно сформировать новое дерево для экспорта
        let exportData = this.generateExportArray(this.layersTree, checkedNodes)
        // выдаем файл
        const buffer = JSON.stringify(exportData)
        let blob = new Blob([buffer], { type: 'text/plain' })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = this.generateGuid() + '.json'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        // закрываем окно
        this.showExportWindow = false
      },
      generateExportArray(source, guides) {
        let result = []
        for (let i = 0; i < source.length; i++) {
          if (guides.includes(source[i].guid)) {
            let newItem = JSON.parse(JSON.stringify(source[i]))
            if (source[i].type === 'group') {
              newItem.children = this.generateExportArray(newItem.children, guides)
            }
            result.push(newItem)
          }
        }
        return result
      }
    }
  }
</script>

<style scoped>
  .dropdown-button {
    cursor:pointer;
    color: #409EFF;
    font-size: 12px;
    margin-left: 15px;
  }
</style>
