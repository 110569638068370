import { MutationTree } from 'vuex'
import { TemplateState, initialState } from './state'
import { MutationTypes, TemplateCreatedEvent, TemplateUpdatedEvent, TemplateDeletedEvent } from './mutation-types'

export const mutations: MutationTree<TemplateState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false
    state.error = error
  },
  [MutationTypes.TEMPLATE_LOADING_EVENT]: (state) => {
    state.isLoading = true
  },
  [MutationTypes.TEMPLATE_CREATED_EVENT]: (state, action: TemplateCreatedEvent) => {
    state.isLoading = false
    state.location = action.location
  },
  [MutationTypes.TEMPLATE_UPDATED_EVENT]: (state, action: TemplateUpdatedEvent) => {
    state.isLoading = false
  },
  [MutationTypes.TEMPLATE_DELETED_EVENT]: (state, action: TemplateDeletedEvent) => {
    state.isLoading = false
    state.deletedTemplateGuid = action.guid
  }
}
