import { AxiosResponse } from 'axios'
import { Nullable } from '@/core/domain/type/types'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { EntityDTO } from '@/services/MapEditor/domain/model/Entity/Entity'

export namespace EntityAPI {

    export class GetEntities implements APIRequest<EntityDTO> {
        response: EntityDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/objecteditor/entities?' +  (new URLSearchParams(payload).toString())
        }
    }

    export class GetEntityById implements APIRequest<EntityDTO> {
        response: EntityDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(id: number) {
            this.path = `/objecteditor/entities/${id}`;
        }
    }

}