import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'

export namespace MapEditorAPI {
  export class GetCoordinateSystemsByUserId implements APIRequest<string> {
    response: string;
    path = '/mapeditor/coordinate_systems_user/';
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data;
    hideNotification = true;

    constructor (userId: number) {
      this.path += userId
    }
  }

  export class GetCoordinateSystemBySrid implements APIRequest<string> {
    response: string;
    path = '/mapeditor/coordinate_systems/';
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data;
    hideNotification = true;

    constructor (srid: number) {
      this.path += srid
    }
  }

  export class GetStyleTemplateGroups implements APIRequest<string> {
    response: string;
    path = '/mapeditor/style_templates_group';
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data;
    hideNotification = true;

    constructor () {
    }
  }

  export class GetStyleTemplateGroupByGuid implements APIRequest<string> {
    response: string;
    path = '/mapeditor/style_templates_group/';
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data;
    hideNotification = true;

    constructor (guid: string) {
      this.path += guid
    }
  }

  export class GetStyleTemplatesByGroupGuid implements APIRequest<string> {
    response: string;
    path = '/mapeditor/style_templates_group/';
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data;
    hideNotification = true;

    constructor (guid: string) {
      this.path += guid + '/templates'
    }
  }

  export class GetStyleTemplates implements APIRequest<string> {
    response: string;
    path = '/mapeditor/style_templates';
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data;
    hideNotification = true;

    constructor () {
    }
  }

  export class GetStyleTemplateByGuid implements APIRequest<string> {
    response: string;
    path = '/mapeditor/style_templates/';
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data;
    hideNotification = true;

    constructor (guid: string) {
      this.path += guid
    }
  }

  export class PutStyleTemplateGroup implements APIRequest<string> {
    response: string;
    path = '/mapeditor/style_templates_group/';
    method = HTTPMethod.PUT;
    parse = (data: AxiosResponse) => data.data;
    hideNotification = false;

    constructor (guid:string, public params = {}) {
      this.path += guid
    }
  }

  export class AddStyleTemplateGroup implements APIRequest<string> {
    response: string;
    path = '/mapeditor/style_templates_group/';
    method = HTTPMethod.POST;
    parse = (data: AxiosResponse) => data.data;
    hideNotification = false;

    constructor (public params = {}) {
    }
  }

  export class DeleteStyleTemplateGroup implements APIRequest<string> {
    response: string;
    path = '/mapeditor/style_templates_group/';
    method = HTTPMethod.DELETE;
    parse = (data: AxiosResponse) => data.data;
    hideNotification = false;

    constructor (guid:string) {
      this.path += guid
    }
  }

  export class UpdateStyleTemplateGroup implements APIRequest<string> {
    response: string;
    path = '/mapeditor/style_templates/';
    method = HTTPMethod.PUT;
    parse = (data: AxiosResponse) => data.data;
    hideNotification = false;

    constructor (guid:string, public params = {}) {
      this.path += guid
    }
  }

  export class DeleteStyleTemplate implements APIRequest<string> {
    response: string;
    path = '/mapeditor/style_templates/';
    method = HTTPMethod.DELETE;
    parse = (data: AxiosResponse) => data.data;
    hideNotification = false;

    constructor (guid:string) {
      this.path += guid
    }
  }

  export class AddStyleTemplate implements APIRequest<string> {
    response: string;
    path = '/mapeditor/style_templates';
    method = HTTPMethod.POST;
    parse = (data: AxiosResponse) => data.data;
    hideNotification = false;

    constructor (public params = {}) {
    }
  }

}
