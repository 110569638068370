<template>
  <div class="checkbox-container">
    <el-checkbox v-model="localValue" @change="$emit('change', localValue)"></el-checkbox>
    <span class="property_label" style="display: inline-block;">{{ $t(`interface_editor.${label}`) }}</span>
    <el-tooltip v-if="options && options.tooltip && options.tooltip.show" placement="top-start" :open-delay="300" effect="dark" :content="$t(`interface_editor.${options.tooltip.content}`)">
      <div slot="content" v-html="$t(`interface_editor.${options.tooltip.content}`)"></div>
      <i class="el-icon-question"></i>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: 'editor-checkbox',
  props: {
    value: Boolean,
    options: Object,
    label: String
  },
  data () {
    return {
      localValue: this.value
    }
  },
  watch: {
    value () {
      this.localValue = this.value
    }
  }
}
</script>

<style scoped>
.checkbox-container {
  display: flex;
}
</style>
