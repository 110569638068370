import { Nullable } from '@/core/domain/type/types'

export default class SourceCreateCommand
{
    private name: string;
    private type: string;
    private properties: object;
    private projectId: number;

    constructor(name: string, type: string, properties: object, projectId: number)
    {
        this.name = name;
        this.type = type;
        this.properties = properties;
        this.projectId = projectId;
    }

    getClassName(): string
    {
        return 'SourceCreateCommand';
    }

    getName(): string
    {
        return this.name;
    }

    getType(): string
    {
        return this.type;
    }

    getProperties(): object
    {
        return this.properties;
    }

    getProjectId(): number
    {
        return this.projectId;
    }
}