import ApprovalQueryRepositoryInterface from '@/services/LogicEditor/domain/repository/ApprovalQueryRepositoryInterface'
import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import ApprovalStageTypesQuery from '@/services/LogicEditor/application/query/ApprovalStageTypesQuery'

export default class ApprovalStageTypesHandler implements QueryHandlerInterface {
  private repository: ApprovalQueryRepositoryInterface

  constructor (repository: ApprovalQueryRepositoryInterface) {
    this.repository = repository
  }

  execute (query: ApprovalStageTypesQuery): Promise<any> {
    return this.repository.getApprovalStageTypes()
  }
}
