import RouteCommandRepositoryInterface from '@/services/Databus/domain/repository/RouteCommandRepositoryInterface'
import { RouteDTO } from '@/services/Databus/domain/model/Route/Route'
import store from '@/store'
import { AddRoute, UpdateRoute, DeleteRoute } from '@/services/Databus/infrastructure/store/modules/Route/action-types'

export default class RouteCommandRepository implements RouteCommandRepositoryInterface
{
    insert(item: RouteDTO): Promise<void>
    {
        return store.dispatch(new AddRoute(item));
    }

    update(item: RouteDTO): Promise<void>
    {
        return store.dispatch(new UpdateRoute(item));
    }

    delete(guid: string): Promise<void>
    {
        return store.dispatch(new DeleteRoute(guid));
    }
}
