import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    OPEN_WORLD_LOADING_EVENT = 'open_world_loading_event',
    OPEN_WORLD_LOADED_EVENT = 'open_world_loaded_event',
    OPEN_WORLD_UPDATED_EVENT = 'open_world_updated_event'
}

export class OpenWorldLoadingEvent implements FluxStandardAction {
    type = MutationTypes.OPEN_WORLD_LOADING_EVENT
}

export class OpenWorldLoadedEvent implements FluxStandardAction {
    type = MutationTypes.OPEN_WORLD_LOADED_EVENT
}

export class OpenWorldUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.OPEN_WORLD_UPDATED_EVENT
}
