import { MutationTree } from 'vuex'
import { ModelFileState } from './state'
import { MutationTypes, ModelFileCreatedEvent, ModelFileDeletedEvent, ModelFileUpdatedEvent } from './mutation-types'

export const mutations: MutationTree<ModelFileState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false;
    state.error = error;    
  },
  [MutationTypes.MODEL_FILE_LOADING_EVENT]: (state) => {
    state.isLoading = true;
  },
  [MutationTypes.MODEL_FILE_CREATED_EVENT]: (state, action: ModelFileCreatedEvent) => {
    state.location = action.location;
    state.isLoading = false;
  },
  [MutationTypes.MODEL_FILE_DELETED_EVENT]: (state, action: ModelFileDeletedEvent) => {
    state.isLoading = false;
    state.deletedElementGuid = action.guid;
  },
  [MutationTypes.MODEL_FILE_UPDATED_EVENT]: (state, action: ModelFileUpdatedEvent) => {
    state.isLoading = false;
  }
}