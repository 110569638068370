import { ActionTree } from 'vuex'
import { TemplateState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, TemplateLoadingEvent, TemplateCreatedEvent, TemplateUpdatedEvent, TemplateDeletedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { TemplateAPI } from '@/services/EtlEditor/infrastructure/api/TemplateAPI'

export const actions: ActionTree<TemplateState, any> = {
  [ActionTypes.GET_TEMPLATE_BY_GUID]: async ({ commit, dispatch }, data) => {
    try {
      return await APIClient.shared.request(new TemplateAPI.GetTemplateByGuid(data.guid))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.CREATE_TEMPLATE]: async ({ commit, dispatch }, data) => {
    try {
      commit(new TemplateLoadingEvent())
      let location = await APIClient.shared.request(new TemplateAPI.CreateTemplate(data.payload))
      commit(new TemplateCreatedEvent(location))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.UPDATE_TEMPLATE]: async ({ commit, dispatch }, data) => {
    try {
      commit(new TemplateLoadingEvent())
      let location = await APIClient.shared.request(new TemplateAPI.UpdateTemplate(data.payload))
      commit(new TemplateUpdatedEvent(location))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.DELETE_TEMPLATE]: async ({ commit, dispatch }, data) => {
    try {
      await APIClient.shared.request(new TemplateAPI.DeleteTemplate(data.guid))
      commit(new TemplateDeletedEvent(data.guid))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  }
}
