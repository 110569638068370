import Vue from 'vue'
import '../Filters/filterStyle.css'

export default Vue.extend({
  name: 'booleanField',
  template: `
    <div class="booleanfield-ag-grid">
      <el-checkbox :value="this.params.value" @click.native="onClick"></el-checkbox>
    </div>
  `,
  methods: {
    onClick (event) {
      event.preventDefault()
    }
  },
  mounted () {
  }
})
