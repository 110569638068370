import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import LayerTreeElementQueryRepositoryInterface from '../../../domain/repository/LayerTreeElementQueryRepositoryInterface'
import LayerTreeElementsQuery from '../../query/LayerTreeElementsQuery'

export default class LayerTreeElementsHandler implements QueryHandlerInterface
{
    private repo: LayerTreeElementQueryRepositoryInterface;

    constructor(repo: LayerTreeElementQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: LayerTreeElementsQuery): Promise<any>
    {
        return this.repo.getChildNodes(query.getParentId(), query.getProjectId());
    }
}