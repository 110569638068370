import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview'
import { CLOSE_ON_ESCAPE, CLOSE_ON_CLICK } from './config'

export default class FullScreen extends Plugin {
  static get pluginName () {
    return 'FullScreen'
  }

  constructor (editor) {
    super(editor)
  }

  init () {
    const editor = this.editor
    const t = editor.t
    editor.ui.componentFactory.add('fullscreen', () => {
      const button = new ButtonView()
      button.set({
        label: t('На весь экран'),
        icon: `<svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M3 3H9V5H6.46173L11.3047 9.84298L9.8905 11.2572L5 6.3667V9H3V3Z" fill="black"/>
  <path d="M3 21H9V19H6.3764L11.3046 14.0718L9.89038 12.6576L5 17.548V15H3V21Z" fill="black"/>
  <path d="M15 21H21V15H19V17.5244L14.1332 12.6576L12.719 14.0718L17.6472 19H15V21Z" fill="black"/>
  <path d="M21 3H15V5H17.5619L12.7189 9.84301L14.1331 11.2572L19 6.39032V9H21V3Z" fill="black"/>
</svg>`,
        tooltip: true
      })

      button.on('execute', () => {
        editor.fire('fullscreen')
      })

      return button
    })
  }
}
