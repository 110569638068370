
import Vue from 'vue'
import TreeSelect from '@/components/Common/TreeSelect.vue'
import CurrentTableField from '@/core/infrastructure/components/FilterType/CurrentTableField.vue'
import ExternalTableField from '@/core/infrastructure/components/FilterType/ExternalTableField.vue'
import Parameter from '@/core/infrastructure/components/FilterType/Parameter.vue'
import Sql from '@/core/infrastructure/components/FilterType/Sql.vue'
import UsersTable from '@/core/infrastructure/components/FilterType/UsersTable.vue'

export type ParameterTypes = 'current_table_field' | 'external_table_field' | 'current_user' | 'current_datetime' | 'constant' | 'parameter' | 'user_table' | 'sql' | string

export type ParameterValue = {
  field_id?: number;
  registry_id?: number;
  xref_field_id?: number;
  constant?: string|null;
  parameter?: string|null;
  sql?: string|null;
  is_array?: boolean;
} | null;

export interface IParameter {
  name: string;
  type: ParameterTypes;
  value: ParameterValue;
  is_correlation_key: boolean;
}

export default Vue.extend({
  name: 'CalculatedParameters',

  components: {
    TreeSelect,

    CurrentTableField,
    ExternalTableField,
    Parameter,
    Sql,
    UsersTable
  },

  props: {
    value: {
      type: Array,
      default () {
        return []
      }
    },

    prop: String,

    label: String,

    objectId: Number,

    correlateKeys: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    value: {
      handler: function (value: IParameter[]) {
        this.localValue = value
      },
      deep: true
    },

    localValue: {
      handler: function (value: IParameter[]) {
        this.$emit('input', value)
      },
      deep: true
    }
  },

  computed: {
    parameterTypes () {
      return [
        { value: 'constant', label: this.$t('logic_editor_v2.state_field_types.constant') },
        { value: 'current_table_field', label: this.$t('logic_editor_v2.state_field_types.current_table_field') },
        { value: 'external_table_field', label: this.$t('logic_editor_v2.state_field_types.external_table_field') },
        { value: 'current_user', label: this.$t('logic_editor_v2.state_field_types.current_user') },
        { value: 'current_datetime', label: this.$t('logic_editor_v2.state_field_types.current_datetime') },
        { value: 'parameter', label: this.$t('logic_editor_v2.state_field_types.parameter') },
        { value: 'sql', label: this.$t('logic_editor_v2.state_field_types.sql') },
        { value: 'users_table', label: this.$t('logic_editor_v2.state_field_types.users_table') }
      ]
    },

    rules () {
      return {
        name: [],
        'value.constant': [],
        'value.field_id': [],
        'value.parameter': [],
        'value.sql': []
      }
    }
  },

  data () {
    return {
      localValue: [],

      currentFields: [],

      treeProps: {
        children: 'children',
        isLeaf: 'isLeaf',
        label: 'name',
        disabled: (data, node) => {
          return [
            'boolean_field', 'xref_field', 'xref_multi_field',
            'string_field', 'integer_field', 'float_field',
            'date_field', 'time_field', 'datetime_field',
            'text_field', 'json_field', 'address_field',
            'gar_address_field', 'address_multi_field', 'xml_field',
            'geometry_field'
          ].indexOf(data.entity_type_id) === -1
        }
      }
    }
  },

  async mounted () {
    if (!this.currentFields.length) {
      await this.$http.get(`${this.$config.api}/objecteditor/entities?parent_id=${this.objectId}&show_children=true`)
        .then(response => {
          this.currentFields = response.data.data
        })
        .catch(error => console.error(error))
    }

    if (Array.isArray(this.value)) {
      this.localValue = this.value
    }
  },

  methods: {
    validate () {
      if (this.$refs.forms) {
        for (const form of this.$refs.forms) {
          if (!form.validate()) {
            return false
          }
        }
      }

      return true
    },

    addParameter () {
      this.localValue.push({
        is_correlation_key: false,
        name: 'attr_N_',
        type: 'constant',
        value: {
          field_id: null,
          registry_id: null,
          xref_field_id: null,
          constant: null,
          parameter: null,
          sql: null,
          is_array: false
        }
      })
    },

    removeParameter (index: number) {
      this.localValue.splice(index, 1)
    },

    selectType (index: number, type: string) {
      this.localValue[index].value = {
        field_id: null,
        registry_id: null,
        xref_field_id: null,
        constant: null,
        parameter: null,
        sql: null,
        is_array: false
      }
    },

    renderContent (h, { node, data, store }) {
      return h('span', {
        class: 'custom-tree-node'
      }, [
        h(
          'span', {
            class: 'node-label'
          }, [
            h(
              'span',
              {
                class: 'node-label__name'
              },
              node.label
            ),
            h(
              'span',
              {
                class: 'node-label__info'
              },
              `${this.$t('object_editor.entity_types.' + data.entity_type_id)} id ${data.id}`
            )
          ])
      ])
    },

    renderOptionLabel (item) {
      return `${item.name} (id: ${item.id}, ${this.$t('object_editor.entity_types.' + item.entity_type_id)})`
    }
  }
})
