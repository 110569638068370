import { MutationTree } from 'vuex'
import { LogicGroupState, initialState } from './state'
import { MutationTypes, LogicGroupCreatedEvent, LogicGroupUpdatedEvent, LogicGroupDeletedEvent } from './mutation-types'

export const mutations: MutationTree<LogicGroupState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false
    state.error = error
  },
  [MutationTypes.LOGIC_GROUP_LOADING_EVENT]: (state) => {
    state.isLoading = true
  },
  [MutationTypes.LOGIC_GROUP_LOADED_EVENT]: (state) => {
    state.isLoading = false
  },
  [MutationTypes.LOGIC_GROUP_CREATED_EVENT]: (state, action: LogicGroupCreatedEvent) => {
    state.isLoading = false
    state.location = action.location
  },
  [MutationTypes.LOGIC_GROUP_UPDATED_EVENT]: (state, action: LogicGroupUpdatedEvent) => {
    state.isLoading = false
  },
  [MutationTypes.LOGIC_GROUP_DELETED_EVENT]: (state, action: LogicGroupDeletedEvent) => {
    state.isLoading = false
    state.deletedLogicGroupGuid = action.guid
  }
}
