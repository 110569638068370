<template>
  <div>
    <el-button type="text" @click="dialogSettingsVisible = true">{{ $locale.main.button.edit }}</el-button>
    <el-dialog
      :title="$locale.interface_editor.component_editor.editor_color_donutchart.edit_color"
      :modal="false"
      :width="'25%'"
      :visible.sync="dialogSettingsVisible">
      <el-button class="button_block" size="small" @click="addColor">{{$locale.interface_editor.component_editor.editor_color_donutchart.add_color}}</el-button>
      <div v-for="(item, index) in colors" :key="index" class="main_block">
        <div class="block" >
          <el-color-picker class="color_picker" v-model="item.color"></el-color-picker>
          <el-input type="text" size="small" v-model="item.color" placeholder="#19D29A"></el-input>
          <el-button @click="deleteColor(index)" class="color_picker__remove" icon="el-icon-close" size="medium" circle></el-button>
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'editor-color-donutchart',
  props: {
    value: {
      type: Array,
      default () { return [] }
    }
  },
  data () {
    return {
      colors: [],
      dialogSettingsVisible: false
    }
  },
  methods: {
    addColor () {
      this.colors.push({ color: '' })
    },
    deleteColor (index) {
      this.colors.splice(index, 1)
    }
  },
  computed: {
    colorEdit () {
      return this.colors.map(({ color }) => color)
    }
  },
  watch: {
    dialogSettingsVisible: {
      handler (value) {
        if (!value) {
          this.$emit('change', this.colorEdit)
        }
      }
    }
  },
  mounted () {
    if (this.value.length) {
      this.colors = this.value.map(el => ({ color: el }))
    }
  }
}
</script>

<style scoped>
.block {
  display: inline-flex;
  align-items: center;
  height: 40px;
}
.color_picker {
  margin-right: 10px;
}
.button_block {
  display: block;
  margin-bottom: 10px;
}
.color_picker__remove {
  margin-left: 10px;
  border: none;
  display: none;
}
.main_block:hover .color_picker__remove {
  display: block;
}
</style>
