import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    SNAPSHOT_LOADING_EVENT = 'snapshot_loading_event',
    SNAPSHOT_LOADED_EVENT = 'snapshot_loaded_event'
}

export class SnapshotLoadingEvent implements FluxStandardAction {
    type = MutationTypes.SNAPSHOT_LOADING_EVENT
}

export class SnapshotLoadedEvent implements FluxStandardAction {
    type = MutationTypes.SNAPSHOT_LOADED_EVENT
}