import { ActionTree } from 'vuex'
import { ModelTreeElementState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, ModelTreeElementCreatedEvent, ModelTreeElementLoadingEvent, ModelTreeElementDeletedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { ModelTreeElementAPI } from '@/services/DigitalTwinEditor/infrastructure/api/ModelTreeElementAPI'

export const actions: ActionTree<ModelTreeElementState, any> = {
    [ActionTypes.ADD_MODEL_TREE_MODEL_ELEMENT]: async ({ commit, dispatch }, data) => {
        try {
            commit(new ModelTreeElementLoadingEvent());
            let location = await APIClient.shared.request(new ModelTreeElementAPI.AddModelTreeModelElement(data.payload));
            commit(new ModelTreeElementCreatedEvent(location));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.ADD_MODEL_TREE_GROUP_ELEMENT]: async ({ commit, dispatch }, data) => {
        try {
            commit(new ModelTreeElementLoadingEvent());
            let location = await APIClient.shared.request(new ModelTreeElementAPI.AddModelTreeGroupElement(data.payload));
            commit(new ModelTreeElementCreatedEvent(location));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.DELETE_MODEL_TREE_ELEMENT]: async ({ commit, dispatch }, data) => {
        try {
            await APIClient.shared.request(new ModelTreeElementAPI.DeleteModelTreeElement(data.guid));
            commit(new ModelTreeElementDeletedEvent(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }         
    },
    [ActionTypes.GET_MODEL_TREE_ELEMENT_BY_GUID]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new ModelTreeElementAPI.GetModelTreeElementByGuid(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    },
    [ActionTypes.GET_MODEL_TREE_ELEMENTS]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new ModelTreeElementAPI.GetModelTreeElements(data.parentId));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    }
}
