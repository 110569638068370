import { ActionTree } from 'vuex'
import { LayerState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, LayerUpdatedEvent, LayerLoadingEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { LayerAPI } from '@/services/DigitalTwinEditor/infrastructure/api/LayerAPI'

export const actions: ActionTree<LayerState, any> = {
    [ActionTypes.UPDATE_LAYER]: async ({ commit, dispatch }, data) => {
        try {
            commit(new LayerLoadingEvent());
            let location = await APIClient.shared.request(new LayerAPI.UpdateLayer(data.payload));
            commit(new LayerUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.GET_LAYER_BY_GUID]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new LayerAPI.GetLayerByGuid(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    }
}
