import AbstractEntitiesTreeQuery from './AbstractEntitiesTreeQuery'
import QueryHandlerInterface from './QueryHandlerInterface'
import AbstractQueryRepository from '../../domain/repository/AbstractQueryRepository'

export default abstract class AbstractEntitiesTreeQueryHandler implements QueryHandlerInterface
{
    private repo: AbstractQueryRepository;

    constructor(repo: AbstractQueryRepository)
    {
        this.repo = repo;
    }

    execute(query: AbstractEntitiesTreeQuery): Promise<any>
    {
        return this.repo.getChildNodes(query.getParentId());
    }
}