import Model from './Model'
import ExtractorTypeProperty from './ExtractorTypeProperty'

export default class ExtractorType extends Model {
  resource () {
    return 'extractor_types'
  }

  properties () {
    return this.hasMany(ExtractorTypeProperty)
  }
}
