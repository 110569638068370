<template>
  <el-form-item :label="title" :label-width="titleWidth">
    <el-select v-bind:value="value" v-on:input="$emit('input', $event)" :placeholder="title" size="mini" clearable>
      <el-option
        v-for="item in items"
        :value="item.id"
        :key="item.id"
        :label="item.name">
      </el-option>
    </el-select>
  </el-form-item>
</template>

<script>
export default {
  name: 'Template',

  props: ['value', 'size', 'title', 'titleWidth'],

  data () {
    return {
      items: []
    }
  },

  async mounted () {
    await this.$http.get(`${this.$config.api}/etleditor/templates`).then((response) => {
      this.items.push(...response.data)
    })
  }
}
</script>

<style type="text/css">

</style>
