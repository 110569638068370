import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import LandscapeTexturesQueryRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/LandscapeTexturesQueryRepositoryInterface'
import store from '@/store'
import { GetLandscapeTextures, CountLandscapeTextures } from '@/services/DigitalTwinEditor/infrastructure/store/modules/OpenWorld/action-types'

export default class LandscapeTexturesQueryRepository extends AbstractQueryRepository implements LandscapeTexturesQueryRepositoryInterface {
  get (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetLandscapeTextures(payload))
  }

  getCount (payload: string[][] | object): Promise<any> {
    return store.dispatch(new CountLandscapeTextures(payload))
  }
}
