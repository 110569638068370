import { FluxStandardAction } from '@/core/domain/type/types'
import { TemplateDTO } from '@/services/EtlEditor/domain/model/Template'

export enum ActionTypes {
  GET_TEMPLATE_BY_GUID = 'get_template_by_id',
  CREATE_TEMPLATE = 'create_template',
  UPDATE_TEMPLATE = 'update_template',
  DELETE_TEMPLATE = 'delete_template'
}

export class GetTemplateByGuid implements FluxStandardAction {
  type = 'Template/' + ActionTypes.GET_TEMPLATE_BY_GUID
  constructor (public guid: string) {}
}

export class CreateTemplate implements FluxStandardAction {
  type = 'Template/' + ActionTypes.CREATE_TEMPLATE
  constructor (public payload: TemplateDTO) {}
}

export class UpdateTemplate implements FluxStandardAction {
  type = 'Template/' + ActionTypes.UPDATE_TEMPLATE
  constructor (public payload: TemplateDTO) {}
}

export class DeleteTemplate implements FluxStandardAction {
  type = 'Template/' + ActionTypes.DELETE_TEMPLATE
  constructor (public guid: string) {}
}
