import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { GroupDTO } from '@/services/CssEditor/domain/model/Group'

export namespace GroupAPI {
  export class GetGroup implements APIRequest<GroupDTO[]> {
    response: GroupDTO[];
    path: string;
    method = HTTPMethod.GET;
    parse = (res: AxiosResponse) => res.data.map(item => new GroupDTO(item))
    constructor (payload?: string[][]) {
      this.path = '/csseditor/groups'

      if (payload) {
        this.path += `?${new URLSearchParams(payload).toString()}`
      }
    }
  }

  export class GetGroupByGuid implements APIRequest<GroupDTO> {
    response: GroupDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => new GroupDTO(data.data)
    constructor (guid: string) {
      this.path = `/csseditor/groups/${guid}`
    }
  }

  export class CreateGroup implements APIRequest<string> {
    response: string;
    path = '/csseditor/groups';
    method = HTTPMethod.POST;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.headers.location
    constructor (public params: GroupDTO) {}
  }

  export class UpdateGroup implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.PUT;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: GroupDTO) {
      this.path = `/csseditor/groups/${params.guid}`
    }
  }

  export class DeleteGroup implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.DELETE;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/csseditor/groups/${guid}`
    }
  }
}
