export interface EntityState {
  isLoading: boolean;
  error: object;
}

export const initialState = (): EntityState => {
  return {
    isLoading: false,  
    error: null
  }
}