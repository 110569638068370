import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { LayerDTO } from '@/services/MapEditor/domain/model/Layer/Layer'

export namespace LayerAPI {

    export class AddLayer implements APIRequest<string> {
        response: string;
        path = '/mapeditor/layer_tree_elements/layers';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.headers.location
        constructor(public params: LayerDTO) {}
    }

    export class UpdateLayer implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: LayerDTO) {
            this.path = `/mapeditor/layers/${params.guid}`;
        }
    }

    export class GetLayerByGuid implements APIRequest<LayerDTO> {
        response: LayerDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/mapeditor/layers/${guid}`;
        }
    }

    export class GetLayers implements APIRequest<LayerDTO> {
        response: LayerDTO;
        path = '/mapeditor/layers';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: string[][]) {}
    }

    export class DeleteProjectLayer implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.DELETE;
        parse = (data: AxiosResponse) => data.data
        constructor(id: number, projectGuid: string) {
            this.path = `/mapeditor/projects/${projectGuid}/layers/?id=${id}`;
        }
    }

}