import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';

export namespace FeatureAPI {

    export class UpdateFeature implements APIRequest<string> {
        response: string;
        path = '/mapeditor/features';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: { [key: string]: string; }) {}
    }
}