import { MutationTree } from 'vuex'
import { TopicState, initialState } from './state'
import { MutationTypes, TopicCreatedEvent, TopicUpdatedEvent, TopicDeletedEvent } from './mutation-types'

export const mutations: MutationTree<TopicState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false;
    state.error = error;    
  },
  [MutationTypes.TOPIC_LOADING_EVENT]: (state) => {
    state.isLoading = true;
  },
  [MutationTypes.TOPIC_LOADED_EVENT]: (state) => {
    state.isLoading = false;
  },
  [MutationTypes.TOPIC_CREATED_EVENT]: (state, action: TopicCreatedEvent) => {
    state.location = action.location;
    state.isLoading = false;
  },
  [MutationTypes.TOPIC_UPDATED_EVENT]: (state, action: TopicUpdatedEvent) => {
    state.isLoading = false;
  },
  [MutationTypes.TOPIC_DELETED_EVENT]: (state, action: TopicDeletedEvent) => {
    state.isLoading = false;
  }
}