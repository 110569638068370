
import Treeselect from '@bingosoftnn/vue-treeselect'

export default {
  name: 'kafkaMessagePanel',
  components: {
    Treeselect
  },
  data () {
    return {
      kafkaMessage: {
        ktopic: 'postgres.core.message_metadata',
        kmax: 100,
        ktimeout: 2000,
        content: null
      },
      topics: [{
        id: "postgres.core.message_metadata",
        name: "postgres.core.message_metadata"
      },{
        id: "postgres.core.message_content",
        name: "postgres.core.message_content"
      }],
      kafkaMessageRules: {
        ktopic: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        kmax: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        ktimeout: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        }
      },
    }
  },
  inject: ['getQueryBus', 'getCommandBus'],
  methods: {
    async viewKafkaMessage() {
      let response = await this.$http.get(`${this.$config.api}/databusservice/utilities/kafka?ktopic=${this.kafkaMessage.ktopic}&kmax=${this.kafkaMessage.kmax}&ktimeot=${this.kafkaMessage.ktimeout}`);
      if (response.data.status === true) {
        this.kafkaMessage.content = response.data.content;
      }
      else {
        this.$message({
          message: response.data.error,
          type: 'warning'
        });
      }
    }
  },
}
