<template>
  <div>
    <el-form>
      <el-form-item label="Ширина компонента в стандартных кнопках(45px)">
        <el-input-number v-model="value.width"></el-input-number>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  export default {
    name: 'index.vue',
    props: {
      value: {
        type: Object
      }
    }
  }
</script>

<style scoped>

</style>
