import { AxiosResponse } from 'axios'
import { Nullable } from '@/core/domain/type/types'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { LayerTreeElementDTO } from '@/services/MapEditor/domain/model/Layer/LayerTreeElement'

export namespace LayerTreeElementAPI {

    export class AddLayerTreeLayerElement implements APIRequest<string> {
        response: string;
        path = '/mapeditor/layer_tree_elements/layers';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.headers.location
        constructor(public params: LayerTreeElementDTO) {}
    }

    export class PutLayerTreeLayerElement implements APIRequest<string> {
        response: string;
        path = '/mapeditor/layer_tree_elements/layers';
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.headers.location
        constructor(public params: LayerTreeElementDTO) {}
    }

    export class AddLayerTreeGroupElement implements APIRequest<string> {
        response: string;
        path = '/mapeditor/layer_tree_elements/groups';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.headers.location
        constructor(public params: LayerTreeElementDTO) {}
    }

    export class DeleteLayerTreeElement implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.DELETE;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string, public params: string[][]) {
            this.path = `/mapeditor/layer_tree_elements/${guid}`;
        }
    }

    export class GetLayerTreeElementByGuid implements APIRequest<LayerTreeElementDTO> {
        response: LayerTreeElementDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/mapeditor/layer_tree_elements/${guid}`;
        }
    }

    export class GetLayerTreeElements implements APIRequest<LayerTreeElementDTO> {
        response: LayerTreeElementDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(parentId: Nullable<number>, projectId: Nullable<number>) {
            if (parentId !== null) {
                this.path = `/mapeditor/layer_tree_elements/?parent_id=${parentId}&project_id=${projectId}`;
            } else {
                this.path = `/mapeditor/layer_tree_elements/?project_id=${projectId}`;
            }
        }
    }

    export class UpdateParent implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: LayerTreeElementDTO) {
            this.path = `/mapeditor/layer_tree_elements/${params.parent_id}/child`;
        }
    }

}