import { ActionTree } from 'vuex'
import { TopicState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, TopicLoadingEvent, TopicLoadedEvent, TopicCreatedEvent, TopicUpdatedEvent, TopicDeletedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { TopicAPI } from '@/services/Databus/infrastructure/api/TopicAPI'

export const actions: ActionTree<TopicState, any> = {
    [ActionTypes.ADD_TOPIC]: async ({ commit, dispatch }, data) => {
        try {
            commit(new TopicLoadingEvent());
            let location = await APIClient.shared.request(new TopicAPI.AddTopic(data.payload));
            commit(new TopicCreatedEvent(location));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.UPDATE_TOPIC]: async ({ commit, dispatch }, data) => {
        try {
            commit(new TopicLoadingEvent());
            let location = await APIClient.shared.request(new TopicAPI.UpdateTopic(data.payload));
            commit(new TopicUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.DELETE_TOPIC]: async ({ commit, dispatch }, data) => {
        try {
            await APIClient.shared.request(new TopicAPI.DeleteTopic(data.guid));
            commit(new TopicDeletedEvent(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }         
    },
    [ActionTypes.GET_TOPICS]: async ({ commit, dispatch }, data) => {
        try {
            commit(new TopicLoadingEvent());
            let res = await APIClient.shared.request(new TopicAPI.GetTopics(data.payload));
            commit(new TopicLoadedEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.COUNT_TOPICS]: async ({ commit, dispatch }, data) => {
        try {
            commit(new TopicLoadingEvent());
            let res = await APIClient.shared.request(new TopicAPI.CountTopics(data.payload));
            commit(new TopicLoadedEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.GET_TOPIC_BY_GUID]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new TopicAPI.GetTopicByGuid(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    }
}
