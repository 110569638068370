import { CryptoPro } from 'ruscryptojs'
import CertificateModel from './CertificateModel'

export default class CriptoHandler {
    provider = null;
    certificates = [];
    setProvider () {
      return new Promise(async (resolve, reject) => {
        try {
          this.provider = new CryptoPro()
          this.provider.init()
          return resolve()
        } catch (e) {
          return reject({
            'message': 'Ошибка! Не установлен плагин для работы с ЭП',
            'error': e
          })
        }
      })
    }
    getCertificates () {
      return new Promise(async (resolve, reject) => {
        try {
          let certificates = await this.provider.listCertificates()
            .then((result) => {
              return result
            })
            .catch((error) => {
              throw { 'message': 'Для подписания необходим доступ к плагину криптопровайдера', error }
            })
          if (certificates.length === 0) {
            throw { 'message': 'У Вас не установлены сертификаты' }
          }
          certificates = await this.getFullInformationAboutCertificates(certificates)
          certificates = this.filterCertificates(certificates)
          if (certificates.length === 0) {
            throw { 'message': 'Все сертификаты устарели' }
          }
          resolve(certificates)
        } catch (e) {
          return reject({
            'message': e.message,
            'error': e.error
          })
        }
      })
    }
    getFullInformationAboutCertificates (certificates) {
      return new Promise(async (resolve) => {
        for (var i = 0; i < certificates.length; i++) {
          let certificate = new CertificateModel(await this.provider.certificateInfo(
            certificates[i].id
          ))
          certificates[i] = certificate
        }
        resolve(certificates)
      })
    }

    filterCertificates (certificates) {
      return certificates.filter(certificate => {
        return certificate.IsValid === true
      })
    }

    async signHashRaw(hash, certificate) {
      return this.provider.signHashRaw(hash, certificate.Thumbprint, null);
    }

    async signHash (hash, certificate) {
      return this.provider.signHash(hash, certificate.Thumbprint,null);
    }

    async createCoSignHash (hash, contentSigFile, certificate) {
      return this.provider.createCoSignHash(hash, contentSigFile, certificate.Thumbprint)
    }

    async signData (hash, certificate) {
      return this.provider.signHash(hash, certificate.Thumbprint)
    }

    async getCertificateBase64 (thumbprint) {
      return this.provider.readCertificate(thumbprint)
    }
}
