import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { ExtractorDTO, ExtractorTypeDTO, ExtractorTypePropertyDTO } from '@/services/EtlEditor/domain/model/Extractor'

export namespace ExtractorAPI {
  export class GetExtractors implements APIRequest<ExtractorDTO> {
    response: ExtractorDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (payload: string[][]) {
      this.path = `/v2/etleditor/extractors?${new URLSearchParams(payload).toString()}`
    }
  }

  export class GetExtractorByGuid implements APIRequest<ExtractorDTO> {
    response: ExtractorDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/etleditor/extractors/${guid}`
    }
  }

  export class GetExtractorTypes implements APIRequest<ExtractorTypeDTO> {
    response: ExtractorTypeDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor () {
      this.path = `/v2/etleditor/extractor_types`
    }
  }

  export class GetExtractorTypeProperties implements APIRequest<ExtractorTypePropertyDTO> {
    response: ExtractorTypePropertyDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (extractorTypeId: string) {
      this.path = `/v2/etleditor/extractor_types/${extractorTypeId}/properties`
    }
  }

  export class CreateExtractor implements APIRequest<string> {
    response: string;
    path = '/v2/etleditor/extractors';
    method = HTTPMethod.POST;
    parse = (data: AxiosResponse) => data.headers.location
    constructor (public params: ExtractorDTO) {}
  }

  export class UpdateExtractor implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.PUT;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: ExtractorDTO) {
      this.path = `/v2/etleditor/extractors/${params.guid}`
    }
  }

  export class DeleteExtractor implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.DELETE;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/etleditor/extractors/${guid}`
    }
  }
}
