<template>
  <div class="regions">
    <div class="header">
      <i class="el-icon-refresh-right button" @click="load"></i>
      <el-input
        v-model="search"
        style="width: 20%; float: right"
        :placeholder="$locale.main.fields.search"/>
      <el-button type="text" style="margin-right: 20px;float: right" @click="updateFromFIAS">{{ $locale.address_service.regions.update_from_fias }}</el-button>
    </div>
    <el-table
      :header-cell-style="headerCellStyle"
      :cell-style="cellStyle"
      :default-sort="{prop: 'has_address_objects', order: 'descending'}"
      :data="regions.filter(item => !search || (item.name.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())))"
      v-loading="$store.getters['Region/isLoading']"
      style="width: 100%; border: none"
      class="custom_scrollbar table"
      height="calc(100% - 50px)"
    >
      <el-table-column
        width="70"
        prop="code"
        label="">
        <template slot-scope="scope">
          <i
            v-show="!scope.row.edit"
            style="cursor: pointer"
            class="el-icon-edit"
            @click="$set(scope.row, 'edit', true)"
          ></i>
          <i
            v-show="scope.row.edit"
            style="cursor: pointer"
            class="el-icon-check"
            @click="updateRegion(scope.row)"
          ></i>
        </template>
      </el-table-column>
      <el-table-column
        width="70"
        prop="code"
        sortable
        :label="$locale.address_service.regions.columns.code">
        <template slot-scope="scope">
          <span style="font-weight: bold">{{ scope.row.code }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        sortable
        :label="$locale.address_service.regions.columns.name">
      </el-table-column>
      <el-table-column :label="$locale.address_service.regions.columns.address_objects" prop="has_address_objects"
                       sortable>
        <template slot-scope="scope">
          <template v-if="!scope.row.edit">
            <div :class="{'status': true, 'has':scope.row.has_address_objects}">
              {{ $locale.address_service.regions.columns[scope.row.has_address_objects ? 'has' : 'has_not'] }}
            </div>
            <div class="version" v-show="scope.row.has_address_objects">
              {{ $locale.address_service.regions.columns.version }}: {{ scope.row.address_objects_version }}
            </div>
          </template>
          <el-switch
            v-model="scope.row.has_address_objects"
            v-if="scope.row.edit"
            active-color="#3DB650">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column :label="$locale.address_service.regions.columns.houses" prop="has_houses" sortable>
        <template slot-scope="scope">
          <template v-if="!scope.row.edit">
            <div :class="{'status': true, 'has':scope.row.has_houses}">
              {{ $locale.address_service.regions.columns[scope.row.has_houses ? 'has' : 'has_not'] }}
            </div>
            <div class="version" v-show="scope.row.has_houses">{{ $locale.address_service.regions.columns.version }}:
              {{ scope.row.houses_version }}
            </div>
          </template>
          <el-switch
            v-model="scope.row.has_houses"
            v-if="scope.row.edit"
            active-color="#3DB650">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column
        prop="is_updatable"
        sortable
        :label="$locale.address_service.regions.columns.is_updatable">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.is_updatable"
            :disabled="!scope.row.edit"
            active-color="#3DB650">
          </el-switch>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import RegionsQuery from '@/services/AddressService/application/query/RegionsQuery'
import RegionUpdateCommand from '@/services/AddressService/application/command/RegionUpdateCommand'
import UpdateFromFIASCommand from '@/services/AddressService/application/command/UpdateFromFIASCommand'

export default {
  name: 'regions',
  inject: ['getQueryBus', 'getCommandBus'],
  data () {
    return {
      isActive: true,
      search: null,
      regions: [],
      cellStyle: {
        fontSize: '15px',
        color: '#2D3239'
      },
      headerCellStyle: {
        color: '#6E757C',
        fontSize: '13px',
        border: 'none',
        background: '#EFF0F0'
      }
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    updateFromFIAS () {
      this.$confirm(this.$locale.address_service.regions.update_from_fias_confirm_text, this.$locale.main.message.confirm, {
        type: 'warning'
      }).then(() => {
        this.getCommandBus().execute(
          new UpdateFromFIASCommand()
        )
      })
    },
    updateRegion (region) {
      this.getCommandBus().execute(
        new RegionUpdateCommand(
          region.code,
          region.is_updatable,
          region.has_houses,
          region.has_address_objects
        )
      ).finally(() => {
        this.$set(region, 'edit', false)
        this.load()
      })
    },
    async load () {
      this.regions = await this.getQueryBus().execute(
        new RegionsQuery()
      )
    }
  }
}
</script>

<style scoped src="./Regions.css">

</style>
