import { FluxStandardAction } from '@/core/domain/type/types'

export enum ActionTypes {
    GET_BY_RECORD = 'get_by_record',
}

export class GetByRecord implements FluxStandardAction {
    type = 'History/' + ActionTypes.GET_BY_RECORD
    constructor (public registryId: number, public recordId: number) {}
}
