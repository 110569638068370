import { Nullable } from '@/core/domain/type/types'

export default class ProjectUpdateCommand
{
    private guid: string;
    private name: string;
    private description: Nullable<string>;

    constructor(guid: string, name: string, description: Nullable<string>)
    {
        this.guid = guid;
        this.name = name;
        this.description = description;
    }

    getGuid(): string
    {
        return this.guid;
    }

    getName(): string
    {
        return this.name;
    }

    getDescription(): Nullable<string>
    {
        return this.description;
    }

    getClassName(): string
    {
        return 'ProjectUpdateCommand';
    }    
}