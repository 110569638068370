import { Nullable } from '@/core/domain/type/types'

export default class AffineTransformationCreateCommand
{
    private name: string;
    private description: string;    
    private x0: number;
    private y0: number;
    private z0: Nullable<number>;
    private a: number;
    private b: number;
    private c: number;
    private d: number;
    private e: number;
    private f: number;
    private g: Nullable<number>;
    private h: Nullable<number>;
    private i: Nullable<number>;
    private projectId: number;

    constructor(
        name: string,
        description: string,
        x0: number,
        y0: number,
        z0: Nullable<number>,
        a: number,
        b: number,
        c: number,
        d: number,
        e: number,
        f: number,
        g: Nullable<number>,
        h: Nullable<number>,
        i: Nullable<number>,
        projectId: number
    ) {
        this.name = name;
        this.description = description;
        this.x0 = x0;
        this.y0 = y0;
        this.z0 = z0;
        this.a = a;
        this.b = b;
        this.c = c;
        this.d = d;
        this.e = e;
        this.f = f;
        this.g = g;
        this.h = h;
        this.i = i;
        this.projectId = projectId;
    }

    getClassName(): string
    {
        return 'AffineTransformationCreateCommand';
    }

    getName(): string
    {
        return this.name;
    }

    getDescription(): string
    {
        return this.description;
    }

    getX0(): number
    {
        return this.x0;
    }

    getY0(): number
    {
        return this.y0;
    }

    getZ0(): Nullable<number>
    {
        return this.z0;
    }

    getA(): number
    {
        return this.a;
    }

    getB(): number
    {
        return this.b;
    }

    getC(): number
    {
        return this.c;
    }

    getD(): number
    {
        return this.d;
    }

    getE(): number
    {
        return this.e;
    }

    getF(): number
    {
        return this.f;
    }

    getG(): Nullable<number>
    {
        return this.g;
    }

    getH(): Nullable<number>
    {
        return this.h;
    }

    getI(): Nullable<number>
    {
        return this.i;
    }

    getProjectId(): number
    {
        return this.projectId;
    }
}