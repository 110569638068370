<template>
  <div :style="css">
      <el-checkbox :value="value" @click.native="onClick"></el-checkbox>
  </div>
</template>

<script>
export default {
  name: 'boolean_field',
  props: {
    value: Boolean,
    css: String
  },
  methods: {
    onClick (event) {
      event.preventDefault()
    }
  }
}
</script>

<style scoped>

</style>
