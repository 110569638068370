export interface EventState {
    isLoading: boolean;
    error: object;
  }

  export const initialState = (): EventState => {
    return {
      isLoading: false,
      error: null
    }
  }
