<template>
  <div>
    <el-button type="text" @click="dialogSettingsVisible = true">{{
        $locale.interface_editor.show_button.title
      }}
    </el-button>
    <el-dialog
      :title="$locale.interface_editor.show_button.title"
      :modal="false"
      :visible.sync="dialogSettingsVisible"
    >
      <div v-for="(value, name) in buttonVisibleSettings" :key="name">
        <i :class="iconBtn[name]"></i> {{ $locale.interface_editor.show_button.name_btn[name] }}
        <editorConditions v-model="buttonVisibleSettings[name].isHidden"/>
        <el-divider></el-divider>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import EditorConditions from '@/components/InterfaceEditor/components/editor/editor-conditions.vue'

export default {
  name: 'ShowButtonAgGrid',
  components: { EditorConditions },
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      dialogSettingsVisible: false,
      buttonVisibleSettings: {},
      buttonVisibilityDefault: {
        update: {
          isHidden: {
            type: 'never',
            condition_type: 'and',
            conditions: []
          }
        },
        add: {
          isHidden: {
            type: 'never',
            condition_type: 'and',
            conditions: []
          }
        },
        add_existing: {
          isHidden: {
            type: 'never',
            condition_type: 'and',
            conditions: []
          }
        },
        edit: {
          isHidden: {
            type: 'never',
            condition_type: 'and',
            conditions: []
          }
        },
        delete: {
          isHidden: {
            type: 'never',
            condition_type: 'and',
            conditions: []
          }
        },
        search: {
          isHidden: {
            type: 'never',
            condition_type: 'and',
            conditions: []
          }
        },
        export: {
          isHidden: {
            type: 'never',
            condition_type: 'and',
            conditions: []
          }
        },
        clearFilters: {
          isHidden: {
            type: 'never',
            condition_type: 'and',
            conditions: []
          }
        }
      },
      defaultSetting: {
        update: false,
        add: false,
        add_existing: false,
        edit: false,
        delete: false,
        search: false,
        export: false,
        clearFilters: false
      },
      iconBtn: {
        update: 'el-icon-refresh-right',
        add: 'el-icon-plus',
        add_existing: 'el-icon-finished',
        edit: 'el-icon-edit',
        delete: 'el-icon-delete',
        search: 'el-icon-search',
        export: 'el-icon-upload2',
        clearFilters: 'el-icon-s-operation'
      }
    }
  },
  watch: {
    dialogSettingsVisible: {
      handler (val) {
        if (!val) this.$emit('change', this.buttonVisibleSettings)
      }
    }
  },

  mounted () {
    // this.value ? this.buttonVisibleSettings = { ...this.defaultSetting, ...this.value } : this.buttonVisibleSettings = this.defaultSetting
    this.value ? this.buttonVisibleSettings = { ...this.buttonVisibilityDefault, ...this.value } : this.buttonVisibleSettings = this.buttonVisibilityDefault
  }
}
</script>

<style lang="scss" scoped>

</style>
