export default {
  data () {
    return {
      observer: undefined
    }
  },
  beforeDestroy () {
    if (this.observer) {
      this.observer.unobserve(this.$el)
    }
  },
  methods: {
    onVisibilityChange (action) {
      let me = this
      let respondToVisibility = function (element, callback) {
        let options = {
          root: document.documentElement
        }

        me.observer = new IntersectionObserver((entries, observer) => {
          entries.forEach(entry => {
            callback(entry.intersectionRatio > 0)
          })
        }, options)

        me.observer.observe(element)
      }
      respondToVisibility(this.$el, (visible) => {
        if (visible) {
          action()
        }
      })
    }
  }
}
