import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ProjectGroupCommandRepositoryInterface from '@/services/MapEditor/domain/repository/ProjectGroupCommandRepositoryInterface'
import ProjectGroupUpdateCommand from '@/services/MapEditor/application/command/ProjectGroupUpdateCommand'
import { ProjectGroupDTO } from '@/services/MapEditor/domain/model/Project/ProjectGroup'

export default class ProjectGroupUpdateHandler implements CommandHandlerInterface
{
    private repo: ProjectGroupCommandRepositoryInterface;

    constructor(repo: ProjectGroupCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: ProjectGroupUpdateCommand): Promise<void>
    {
        let item: ProjectGroupDTO = {
            guid: command.getGuid(),
            name: command.getName()
        };
        return this.repo.update(item);
    }
}