enum TreeElementType
{
    MODEL = 'model',
    MODEL_GROUP = 'model_group',
    LAYER = 'layer',
    OPEN_WORLD = 'open_world',
    PROJECT = 'project',
    PROJECT_GROUP = 'project_group',
};

export default TreeElementType;