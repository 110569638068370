import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import PackageQueryRepositoryInterface from '../../../domain/repository/PackageQueryRepositoryInterface'
import PackagesCountQuery from '../../query/PackagesCountQuery'

export default class PackagesCountHandler implements QueryHandlerInterface
{
    private repo: PackageQueryRepositoryInterface;

    constructor(repo: PackageQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: PackagesCountQuery): Promise<any>
    {
        return this.repo.getCount(query.getPayload());
    }
}