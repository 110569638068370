<template>
  <div>
    <el-form-item>
      <el-col :span="24">
        <span style="margin-right: 10px">Раскрасить по уникальным значениям</span>
        <el-checkbox v-model="checked" @change="checkboxChange"></el-checkbox>
      </el-col>
      <template v-if="value.properties.paint.type === 'unique_value'">
        <template v-if="value.properties.source.type === 'WFS'">
          <el-col :span="24">
            <span style="margin-right: 10px">Поле</span>
            <el-select v-model="value.properties.paint.field">
              <el-option
                v-for="item in fields"
                :key="item.id"
                :label="item.label"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-col>
        </template>
        <template v-if="value.properties.source.type === 'Registry'">
          <el-col :span="24">
            <span style="margin-right: 10px">Поле</span>
            <select-tree
              :clearable="true"
              ref="entity_tree"
              :dataTable="fields"
              class="custom_scrollbar"
              :appendToBody='false'
              v-model="value.properties.paint.field"/>
          </el-col>
        </template>
        <el-col :span="24">
          <span style="margin-right: 10px">Цвет</span>
          <el-color-picker v-model="value.properties.paint.startColor"></el-color-picker>
        </el-col>
        <el-col :span="24">
          <span style="margin-right: 10px">Шаг цвета</span>
          <el-input-number v-model="value.properties.paint.incrementColor"></el-input-number>
        </el-col>
      </template>
    </el-form-item>
  </div>
</template>

<script>
import Registry from '@/components/Models/EntityModel'
import SelectTree from '@/core/infrastructure/components/TreeSelect'

const PAINT_TYPE = 'unique_value'
export default {
  name: 'PaintByUniqueValues',
  components: { SelectTree },
  props: {
    value: {
      type: Object
    }
  },
  watch: {
    value: {
      deep: true,
      handler: function (newValue, oldValue) {
        //if (newValue.properties.source.'WFS' !== newValue.oldValue)
        this.loadFields(newValue.properties)
      }
    }
  },
  data () {
    return {
      fields: [],
      selectedField: null,
      checked: this.value.properties.paint !== undefined ? this.value.properties.paint.type === PAINT_TYPE : false
    }
  },
  async mounted () {
    if (!this.value.properties.paint) {
      this.$set(this.value.properties, 'paint', {
        type: null,
        field: null,
        startColor: null
      })
    }
    this.loadFields(this.value.properties)
  },
  methods: {
    checkboxChange (value) {
      this.$set(this.value.properties.paint, 'type', value ? PAINT_TYPE : null)
      if (!value) {
        this.$set(this.value.properties.paint, 'field', null)
        this.$set(this.value.properties.paint, 'startColor', null)
        this.$set(this.value.properties.paint, 'incrementColor', null)
      }
    },
    async loadFields (properties) {
      if (properties.source.type === 'WFS') {
        this.fields = properties.interactive.standardCard.fields
      } else if (properties.source.type === 'Registry') {
        this.fields = await Registry.params({
          parent_id: properties.source.entityId,
          show_children: true,
          properties: { is_visible_in_grid: true } }).$get()
      }
    },
  }
}
</script>

<style scoped>

</style>
