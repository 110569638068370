import { ActionTree } from 'vuex'
import { ModelInstanceState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, ModelInstanceCreatedEvent, ModelInstanceLoadingEvent, ModelInstanceLoadedEvent, ModelInstanceDeletedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { ModelInstanceAPI } from '@/services/DigitalTwinEditor/infrastructure/api/ModelInstanceAPI'

export const actions: ActionTree<ModelInstanceState, any> = {
    [ActionTypes.ADD_MODEL_INSTANCE]: async ({ commit, dispatch }, data) => {
        try {
            commit(new ModelInstanceLoadingEvent());
            let location = await APIClient.shared.request(new ModelInstanceAPI.AddModelInstance(data.payload));
            commit(new ModelInstanceCreatedEvent(location));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.UPDATE_MODEL_INSTANCE]: async ({ commit, dispatch }, data) => {
        try {
            commit(new ModelInstanceLoadingEvent());
            await APIClient.shared.request(new ModelInstanceAPI.UpdateModelInstance(data.payload));
            commit(new ModelInstanceLoadedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.DELETE_MODEL_INSTANCE]: async ({ commit, dispatch }, data) => {
        try {
            await APIClient.shared.request(new ModelInstanceAPI.DeleteModelInstance(data.guid));
            commit(new ModelInstanceDeletedEvent(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }         
    },
    [ActionTypes.GET_MODEL_INSTANCE_BY_GUID]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new ModelInstanceAPI.GetModelInstanceByGuid(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    }
}
