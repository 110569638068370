
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { APIDataSource } from '@/components/InterfaceEditor/components/basic/AnalyticTable/api/DataSource.ts'

export default {
  name: 'statusBar',
  template: `
    <div class="ag-status-name-value" v-if="visible">
      <div style="display: inline-block">Количество записей:&nbsp;
        <el-button
        style="padding:0"
        v-show="showBtn"
        type="text"
        size="small"
        v-on:click="onClick"
        :loading="loading"
        plain>?</el-button>
      </div>
      <span v-show="!loading" class="ag-status-name-value-value">{{count}}</span>
      <el-button :loading="loading" style="padding:0" v-if="count !== null" v-on:click="onClick" type="text">Пересчитать</el-button>
    </div>
  `,
  data: function () {
    return {
      visible: false,
      loading: false,
      showBtn: true,
      count: null,
      entityId: null
    }
  },
  beforeMount () {},
  mounted () { },
  methods: {
    async getNumberRecords () {
      const dataSourceService = this.$parent.$parent.dataSourceService
      // Фильтры от полей (настраиваются в редакторе интерфейсов)
      // const fieldFilter = dataSourceService.getExternalFilters() || []
      // Фильтр ag-grid (панель справа)
      // this.params.context.componentParent.getFilterModel()
      // let { and } = dataSourceService.getLastPayload()
      let lastPayload = dataSourceService?.lastPayload?.where?.and || []
      if (['extended_object', 'query'].includes(dataSourceService.getType())) {
        this.entityId = dataSourceService.entityId
      }
      const requestTypes = {
        extended_object: APIDataSource.ExtendedObjectQuery,
        query: APIDataSource.QueryQuery
      }
      if (requestTypes[dataSourceService.getType()] && this.entityId) {
        try {
          let currentParams = {}
          if (lastPayload.length) {
            currentParams = { '*': { func: 'count' }, 'where': { 'and': [...lastPayload] } }
          } else {
            currentParams = { '*': { func: 'count' } }
          }
          let response = await APIClient.shared.request(new requestTypes[dataSourceService.getType()](
            this.entityId, currentParams
          ))

          return response[0].count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        } catch (error) {
          return 0
        }
      }
    },
    async onClick () {
      this.loading = true
      this.count = await this.getNumberRecords()
      this.loading = false
      this.showBtn = false
    },
    setVisible (visible) {
      this.visible = visible
    },
    isVisible () {
      return this.visible
    }
  }
}
