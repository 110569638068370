import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import MethodNotImplemented from '@/core/domain//exception/MethodNotImplemented'
import PackageQueryRepositoryInterface from '@/services/Databus/domain/repository/PackageQueryRepositoryInterface'
import store from '@/store'
import { GetPackages, CountPackages} from '@/services/Databus/infrastructure/store/modules/Package/action-types'

export default class PackageQueryRepository extends AbstractQueryRepository implements PackageQueryRepositoryInterface
{
    get(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new GetPackages(payload));
    }

    getCount(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new CountPackages(payload));
    }
}