import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { LayerDTO } from '@/services/MapEditor/domain/model/Layer/Layer'

export enum ActionTypes {
    ADD_LAYER = 'add_layer',
    UPDATE_LAYER = 'update_layer',
    GET_LAYER_BY_GUID = 'get_layer_by_guid',
    GET_LAYERS = 'get_layers',
    DELETE_PROJECT_LAYER = 'delete_project_layer',
}

export class AddLayer implements FluxStandardAction {
    type = 'Layer/' + ActionTypes.ADD_LAYER
    constructor(public payload: LayerDTO){}
}

export class UpdateLayer implements FluxStandardAction {
    type = 'Layer/' + ActionTypes.UPDATE_LAYER
    constructor(public payload: LayerDTO){}
}

export class GetLayerByGuid implements FluxStandardAction {
    type = 'Layer/' + ActionTypes.GET_LAYER_BY_GUID
    constructor(public guid: string){}
}

export class GetLayers implements FluxStandardAction {
    type = 'Layer/' + ActionTypes.GET_LAYERS
    constructor(public payload: string[][]){}
}

export class DeleteProjectLayer implements FluxStandardAction {
    type = 'Layer/' + ActionTypes.DELETE_PROJECT_LAYER
    constructor(public id: number, public projectGuid: string){}
}