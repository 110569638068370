import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    FORMULA_LOADING_EVENT = 'formula_loading_event',
    FORMULA_LOADED_EVENT = 'formula_loaded_event',
    FORMULA_CREATED_EVENT = 'formula_created_event',
    FORMULA_UPDATED_EVENT = 'formula_updated_event',
    FORMULA_DELETED_EVENT = 'formula_deleted_event'
}

export class FormulaLoadingEvent implements FluxStandardAction {
  type = MutationTypes.FORMULA_LOADING_EVENT
}

export class FormulaLoadedEvent implements FluxStandardAction {
  type = MutationTypes.FORMULA_LOADED_EVENT
}

export class FormulaCreatedEvent implements FluxStandardAction {
  type = MutationTypes.FORMULA_CREATED_EVENT
  constructor (public location: string) {}
}

export class FormulaUpdatedEvent implements FluxStandardAction {
  type = MutationTypes.FORMULA_UPDATED_EVENT
  constructor (public location: string) {}
}

export class FormulaDeletedEvent implements FluxStandardAction {
  type = MutationTypes.FORMULA_DELETED_EVENT
  constructor (public guid: string) {}
}
