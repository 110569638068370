<template>
  <div v-if="type === 'percentage'" class="percentage-settings">
    <el-button class="el-icon-circle-plus-outline" @click="addPercentageOption()" style="margin: 8px">Добавить
    </el-button>
    <el-row v-for="item in percentageOptions" :key="item.value" :label="item.label" :value="item.value" :gutter="10">
      <el-col :span="8">
        <div>
          <el-form-item label="Псевдоним">
            <el-input v-model="item.name"></el-input>
          </el-form-item>
        </div>
      </el-col>
      <el-col :span="8">
        <div>
          <el-form-item label="Атрибут, содержащий значение">
            <el-input v-model="item.attribute"></el-input>
          </el-form-item>
        </div>
      </el-col>
      <el-col :span="4">
        <div>
          <el-form-item label="Цвет">
            <el-color-picker v-model="item.color"></el-color-picker>
          </el-form-item>
        </div>
      </el-col>
      <el-col :span="4">
        <div>
          <el-form-item>
            <br>
            <el-button class="el-icon-delete" @click="removePercentageOption(item.value)"></el-button>
          </el-form-item>
        </div>
      </el-col>
    </el-row>

  </div>
  <div v-else>
    <el-form-item v-if="!['area', 'line'].includes(type)">
      <el-checkbox :value="activeNode.label" @change="$set(activeNode, 'label', $event)">Лейбл</el-checkbox>
    </el-form-item>
    <el-form-item>
      <el-checkbox :value="activeNode.tooltip" @change="$set(activeNode, 'tooltip', $event)">Подсказки</el-checkbox>
    </el-form-item>
    <el-form-item v-if="type !== 'line'" label="Цвет">
      <el-input :value="activeNode.fill" @input="$set(activeNode, 'fill', $event)"></el-input>
    </el-form-item>
    <el-form-item v-else label="Цвет" style="width: 50%">
      <el-input :value="activeNode.lineColor" @input="$set(activeNode, 'lineColor', $event)"></el-input>
    </el-form-item>
  </div>
</template>

<script>

export default {
  name: 'configurator-sparkline',
  props: {
    activeNode: {
      type: Object,
      default () {
        return {
          label: '',
          tooltip: '',
          fill: '',
          lineColor: '',
          percentageOptions: []
        }
      }
    },
    type: {
      type: String
    }
  },
  methods: {
    addPercentageOption () {
      let color = ''
      let randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16)
      switch (this.percentageOptions.length) {
        case 0:
          color = '#67cf70'
          break
        case 1:
          color = '#fffa6e'
          break
        case 2:
          color = '#ff5454'
          break
        default:
          color = randomColor
          break
      }
      this.percentageOptions.push({
        value: this.generateGuid(),
        label: 'label',
        color: color,
        attribute: '',
        name: ''
      })
    },

    removePercentageOption (id) {
      this.percentageOptions = this.percentageOptions.filter((el) => el.value !== id)
    }
  },
  data () {
    return {
      percentageOptions: this.activeNode.percentageOptions || [{
        value: this.generateGuid(),
        label: 'label',
        color: '#67cf70',
        attribute: '',
        name: ''
      }]
    }
  },
  mounted () {
    console.log(this.activeNode)
  },
  watch: {
    percentageOptions: {
      handler (newOption, prevOption) {
        this.$set(this.activeNode, 'percentageOptions', newOption)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.percentage-settings {
  padding: 10px;
  overflow-y: auto;
  height: calc(100% - 70px);
}
</style>
