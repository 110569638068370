import { Nullable } from '@/core/domain/type/types'

export default class ModelUpdateCommand
{
    private guid: string;
    private name: string;
    private description: Nullable<string>;
    private alias: Nullable<string>;
    private coordinateSystem: Nullable<number>;
    private position: Array<number>;
    private rotation: Array<number>;
    private files: Array<object>;
    private parentId: Nullable<number>;

    constructor(guid: string, name: string, description: Nullable<string>, alias: Nullable<string>, coordinateSystem: Nullable<number>, position: Array<number>, rotation: Array<number>, files: Array<object>, parentId: Nullable<number>)
    {
        this.guid = guid;
        this.name = name;
        this.description = description;
        this.alias = alias;
        this.coordinateSystem = coordinateSystem;
        this.position = position;
        this.rotation = rotation;
        this.files = files;
        this.parentId = parentId;
    }

    getClassName(): string
    {
        return 'ModelUpdateCommand';
    }

    getGuid(): string
    {
        return this.guid;
    }

    getName(): string
    {
        return this.name;
    }

    getDescription(): Nullable<string>
    {
        return this.description;
    }

    getCoordinateSystem(): Nullable<number>
    {
        return this.coordinateSystem;
    }

    getAlias(): Nullable<string>
    {
        return this.alias;
    }

    getPosition(): Array<number>
    {
        return this.position;
    }

    getRotation(): Array<number>
    {
        return this.rotation;
    }

    getFiles(): Array<object>
    {
        return this.files;
    }

    getParentId(): Nullable<number>
    {
        return this.parentId;
    }
}