import QueryInterface from '@/core/application/query/QueryInterface'

export default class RecordHistoryQuery implements QueryInterface {
  private readonly registryId: number
  private readonly recordId: number

  constructor (registryId: number, recordId: number) {
    this.registryId = registryId
    this.recordId = recordId
  }

  public getRegistryId ():number {
    return this.registryId
  }

  public getRecordId ():number {
    return this.recordId
  }

  getClassName (): string {
    return 'RecordHistoryQuery'
  }
}
