<template>
  <div class="editor-document">
    <el-form-item>
      <span class="property_label">Ссылка</span>
      <el-select v-model="autofillParams.xref" placeholder="Простая ссылка" @change="onChange">
        <el-option
          v-for="(item, index) in xrefsOnCard"
          :key="index"
          :label="`${item.properties.label} (${item.properties.name})`"
          :value="item.guid"
        ></el-option>
      </el-select>
    </el-form-item>
  </div>
</template>

<script>
import modelMixin from '@/components/InterfaceEditor/components/ModelMixin'
import { deepClone } from '@/components/InterfaceEditor/components/utils'

const defaultModel = () => deepClone({
  // Определяет включено ли поведение
  is_active: false,

  // Мэппинг полей реестра с атрибутами карточки
  fields: []
})
export default {
  name: 'editor-autofill-fields',
  mixins: [ modelMixin ],
  inject: {
    getComponents: {
      default: () => () => null
    }
  },
  props: {
    value: {
      type: Object,
      default () {
        return defaultModel()
      }
    }
  },
  data () {
    return {
      xref: '',
      autofillParams: this.value
    }
  },
  computed: {
    components () {
      if (this.getComponents) {
        return this.getComponents()
      }

      return []
    },
    xrefsOnCard () {
      return this.components.filter((el) => {
        return el.initialType === 'xref_field'
      })
    }
  },
  mounted () {
    if (!this.autofillParams.xref && this.xrefsOnCard.length) {
      this.$set(this.autofillParams, 'xref', this.xrefsOnCard[0].guid)
    }
  },
  methods: {
    onChange (value) {
      this.$emit('change', value)
    }
  }
}
</script>

<style>

</style>
