import { MutationTree } from 'vuex'
import { CssClassState, initialState } from './state'
import { MutationTypes, CssClassCreatedEvent, CssClassUpdatedEvent, CssClassDeletedEvent } from './mutation-types'

export const mutations: MutationTree<CssClassState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false
    state.error = error
  },

  [MutationTypes.CSS_CLASS_LOADING_EVENT]: (state) => {
    state.isLoading = true
  },

  [MutationTypes.CSS_CLASS_LOADED_EVENT]: (state) => {
    state.isLoading = false
  },

  [MutationTypes.CSS_CLASS_CREATED_EVENT]: (state, action: CssClassCreatedEvent) => {
    state.isLoading = false
    state.location = action.location
  },

  [MutationTypes.CSS_CLASS_UPDATED_EVENT]: (state, action: CssClassUpdatedEvent) => {
    state.isLoading = false
  },

  [MutationTypes.CSS_CLASS_DELETED_EVENT]: (state, action: CssClassDeletedEvent) => {
    state.isLoading = false
    state.deletedCssClassGuid = action.guid
  }
}
