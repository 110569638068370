<template>
  <el-input
    v-model="localModel"
    v-on:input="$emit('input', localModel || null)"
    type="number"
  >
  </el-input>
</template>

<script>
export default {
  name: 'input_integer_field',
  props: ['value'],
  data () {
    return {
      localModel: this.value
    }
  }
}
</script>
