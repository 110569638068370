<template>
  <div class="string-input">
    <el-input
      v-model="inputValue"
      @change="emitChange"
      size="small"
    ></el-input>
  </div>
</template>

<script>
export default {
  name: 'StringInput',
  props: {
    value: String,
    default: null
  },
  data () {
    return {
      inputValue: this.value
    }
  },
  methods: {
    emitChange(val) {
      this.$nextTick(() => {
        this.$emit('input', val, this.inputValue);
      });
    }
  }
}
</script>
