<template>
  <el-color-picker
    v-model="localValue"
    show-alpha
    size="mini"
    @change="change"
    @active-change="change"
  ></el-color-picker>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'editor-color-picker',

  props: {
    value: String
  },

  data () {
    return {
      localValue: this.value || '#FFFFFF'
    }
  },

  methods: {
    change (value) {
      this.$emit('change', value)
    }
  }
})
</script>

<style scoped>

</style>
