<template>
  <el-date-picker
    v-model="localModel"
    v-on:input="$emit('input', localModel)"
    type="month"
    :name="name"
    value-format="yyyy-MM-dd"
    :picker-options="{ firstDayOfWeek: 1 }"
    format="MMM.yyyy">
  </el-date-picker>
</template>

<script>
export default {
  name: 'input_month_field',
  props: ['name', 'value'],
  data () {
    return {
      localModel: this.value
    }
  }
}
</script>
