<template>
  <div class="main">
    <toolbar></toolbar>
    <div class="viewer">
      <div :id="`viewer_${guid}`" class="bpmn"></div>
      <properties-panel
        :active-element="activeElement"
      />
    </div>
  </div>
</template>

<script>
import Modeler from 'bpmn-js/lib/Modeler'
import 'bpmn-js/dist/assets/diagram-js.css'
import 'bpmn-js/dist/assets/bpmn-js.css'
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn.css'
import Vue from 'vue'
import Toolbar from '@/services/BPMNEditor/infrastructure/components/Toolbar/index.vue'
import BPMNService from '@/services/BPMNEditor/infrastructure/service/BPMNService'
import PaletteModule from '@/services/BPMNEditor/infrastructure/modules/PalleteProvider'

import Extensions from '@/services/BPMNEditor/infrastructure/extensions/Extensions.json'
import ExportImportService from '@/services/BPMNEditor/infrastructure/service/ExportImportService'
import PropertiesPanel from '@/services/BPMNEditor/infrastructure/components/PropertiesPanel/index.vue'
import CustomRenderer from '@/services/BPMNEditor/infrastructure/modules/CustomRenderer'

const DEFAULT_XML = `<?xml version="1.0" encoding="UTF-8"?>
<bpmn2:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" xsi:schemaLocation="http://www.omg.org/spec/BPMN/20100524/MODEL BPMN20.xsd" id="sample-diagram" targetNamespace="http://bpmn.io/schema/bpmn">
  <bpmn2:process id="process_{{PROCESS_ID}}" isExecutable="true">
  </bpmn2:process>
  <bpmndi:BPMNDiagram id="BPMNDiagram_1">
    <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="process_{{PROCESS_ID}}">
    </bpmndi:BPMNPlane>
  </bpmndi:BPMNDiagram>
</bpmn2:definitions>
`

export default {
  name: 'BPMNEditor',
  components: { PropertiesPanel, Toolbar },
  props: {
    schema: String,
    saveCallback: Function,
    processGuid: String
  },
  provide () {
    return {
      getEventBus: this.getEventBus,
      getModeler: this.getModeler
    }
  },
  beforeDestroy () {
    this.eventBus.$destroy()
  },
  data () {
    return {
      modeler: undefined,
      guid: this.generateGuid(),
      eventBus: new Vue(),
      activeElement: null
    }
  },
  mounted () {
    this.modeler = new Modeler({
      container: `#viewer_${this.guid}`,
      moddleExtensions: {
        extensions: Extensions
      },
      additionalModules: [
        PaletteModule,
        CustomRenderer
      ]
    })
    this.modeler.importXML(this.schema).catch((error) => {
      console.log(error)
      if ((this.schema || '').trim().length === 0) {
        this.$message.warning('Некорректная схема, заменена на пустую')
      }
      console.log(DEFAULT_XML.replaceAll('{{PROCESS_ID}}', this.processGuid))
      this.modeler.importXML(DEFAULT_XML.replaceAll('{{PROCESS_ID}}', this.processGuid))
    }).finally(() => {
      const root = this.modeler.get('canvas').getRootElement()
      if (root) {
        this.activeElement = root
      }
      console.log(this.modeler)
      this.modeler.get('canvas').zoom('fit-viewport')
    })
    this.BPMNService = new BPMNService(this.modeler)
    this.registerEvents()
  },
  methods: {
    getModeler () {
      return this.modeler
    },
    getEventBus () {
      return this.eventBus
    },
    save () {
      const me = this
      if (typeof this.saveCallback === 'function') {
        this.modeler.saveXML({ format: true }).then((xml) => {
          me.saveCallback(xml)
        })
      }
    },
    registerEvents () {
      this.eventBus.$on('save', this.save)
      this.eventBus.$on('export', () => ExportImportService.export(this.modeler))
      this.eventBus.$on('import', () => ExportImportService.import(this.modeler))
      this.modeler.get('eventBus').on('add', (event, data) => this.BPMNService.add(data))
      this.modeler.on('element.click', (event) => {
        this.activeElement = event.element
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "./main.scss";
</style>
<style>
.bjs-powered-by {
  display: none !important;
}
</style>
