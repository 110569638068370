import { ActionTree } from 'vuex'
import { ActivityState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, ActivityLoadingEvent, ActivityLoadedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { ActivityAPI } from '@/services/ActivityService/infrastructure/api/ActivityAPI'

export const actions: ActionTree<ActivityState, any> = {
  [ActionTypes.GET_ACTIVITIES]: async ({ commit, dispatch }, data) => {
    try {
      commit(new ActivityLoadingEvent())
      let res = await APIClient.shared.request(new ActivityAPI.GetActivities(data.payload))
      commit(new ActivityLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  }
}
