export default {
  created () {
    this.$http.interceptors.response.use((response) => {
      if (response.config.method !== 'get' && !response.config.hideNotification) {
        this.$notify({
          title: this.$locale.main.message.success,
          message: this.$locale.main.message.saved,
          type: 'success',
          duration: 2000
        })
      }
      return response
    }, (error) => {
      let me = this
      return new Promise(function (resolve, reject) {
        if (error.config && !error.config.__isRetryRequest) {
          switch (error.response.status) {
            case 401:
              me.$store.dispatch('Authorization/logout')
              break
            case 400:
              if (!error.response.config.hideNotification && !me.hiddenErrors.includes(error.response.data.error)) {
                if (
                  typeof error.response.data.code !== 'undefined' &&
                  error.response.data.code !== 0 &&
                  error.response.data.type === 'client'
                ) {
                  let title = me.$locale.errors[error.response.data.code] || error.response.data.code
                  me.$confirm(error.response.data.message, title, {
                    type: 'error',
                    showCancelButton: false
                  })
                } else {
                  let message = me.$locale.main.message.not_saved
                  if (error.response.data.notify) {
                    message = error.response.data.message || message
                  }
                  me.$notify.error({
                    title: me.$locale.main.message.error,
                    message: message,
                    duration: 2000
                  })
                }
              }
              break
            case 500:
              me.$message({
                showClose: true,
                message: `Произошла внутренняя ошибка сервера. Пожалуйста, попробуйте позже. Код ошибки: 500 (Internal Server Error)`,
                type: 'error',
                duration: 5000
              })
              break
            case 502:
              me.$message({
                showClose: true,
                message: `Произошла ошибка сервера. Пожалуйста, попробуйте позже. Код ошибки: 502 (Bad Gateway)`,
                type: 'error',
                duration: 5000
              })
              break
            case 504:
              me.$message({
                showClose: true,
                message: `Произошла ошибка сервера. Пожалуйста, попробуйте позже. Код ошибки: 504 (Gateway Timeout)`,
                type: 'error',
                duration: 5000
              })
              break
            default:
              break
          }
        }
        throw error
      })
    })
  }
}
