<template>
  <el-form-item :label="title" :label-width="titleWidth">
    <el-input-number v-bind:value="value" v-on:input="$emit('input', $event)" :placeholder="title" size="mini" controls-position="right"></el-input-number>
  </el-form-item>
</template>

<script>
export default {
  name: 'Integer',
  props: ['value', 'size', 'title', 'titleWidth']
}
</script>

<style type="text/css">

</style>
