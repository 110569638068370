import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { RowFormulaDTO } from '@/services/LogicEditor/domain/model/RowFormula'

export namespace RowFormulaAPI {
  export class GetRowFormulaByGuid implements APIRequest<RowFormulaDTO> {
    response: RowFormulaDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => new RowFormulaDTO(data.data)
    constructor (guid: string) {
      this.path = `/v2/logiceditor/row_formulas/${guid}`
    }
  }

  export class CreateRowFormula implements APIRequest<string> {
    response: string;
    path = '/v2/logiceditor/row_formulas';
    method = HTTPMethod.POST;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.headers.location
    constructor (public params: RowFormulaDTO) {}
  }

  export class UpdateRowFormula implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.PUT;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: RowFormulaDTO) {
      this.path = `/v2/logiceditor/row_formulas/${params.guid}`
    }
  }

  export class DeleteRowFormula implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.DELETE;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/logiceditor/row_formulas/${guid}`
    }
  }
}
