<template>
  <el-form-item :label="title" :label-width="titleWidth">
    <el-input v-bind:value="value" v-on:input="$emit('input', $event || null)" :placeholder="title" :size="size"></el-input>
  </el-form-item>
</template>

<script>
export default {
  name: 'Varchar',
  props: ['value', 'size', 'title', 'titleWidth']
}
</script>

<style type="text/css">

</style>
