import { ActionTree } from 'vuex'
import { ExtractorState, initialState } from '@/services/EtlEditor/infrastructure/store/modules/Extractor/state'
import { ActionTypes } from './action-types'
import { MutationTypes, ExtractorUpdatedEvent, ExtractorCreatedEvent, ExtractorLoadingEvent, ExtractorDeletedEvent, ExtractorLoadedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { ExtractorAPI } from '@/services/EtlEditor/infrastructure/api/ExtractorAPI'

export const actions: ActionTree<ExtractorState, any> = {
  [ActionTypes.GET_EXTRACTOR]: async ({ commit, dispatch }, data) => {
    commit(new ExtractorLoadingEvent())

    return APIClient.shared.request(new ExtractorAPI.GetExtractors(data.payload))
      .then((response) => {
        commit(new ExtractorLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.GET_EXTRACTOR_BY_GUID]: async ({ commit, dispatch }, data) => {
    commit(new ExtractorLoadingEvent())

    return APIClient.shared.request(new ExtractorAPI.GetExtractorByGuid(data.guid))
      .then((response) => {
        commit(new ExtractorLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.GET_EXTRACTOR_TYPES]: async ({ commit, dispatch }, data) => {
    commit(new ExtractorLoadingEvent())

    return APIClient.shared.request(new ExtractorAPI.GetExtractorTypes())
      .then((response) => {
        commit(new ExtractorLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.GET_EXTRACTOR_TYPE_PROPERTIES]: async ({ commit, dispatch }, data) => {
    commit(new ExtractorLoadingEvent())

    return APIClient.shared.request(new ExtractorAPI.GetExtractorTypeProperties(data.extractorTypeId))
      .then((response) => {
        commit(new ExtractorLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.CREATE_EXTRACTOR]: async ({ commit, dispatch }, data) => {
    commit(new ExtractorLoadingEvent())

    return APIClient.shared.request(new ExtractorAPI.GetExtractorTypeProperties(data.extractorTypeId))
      .then((location) => {
        commit(new ExtractorCreatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.UPDATE_EXTRACTOR]: async ({ commit, dispatch }, data) => {
    commit(new ExtractorLoadingEvent())

    return APIClient.shared.request(new ExtractorAPI.UpdateExtractor(data.payload))
      .then((location) => {
        commit(new ExtractorUpdatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.DELETE_EXTRACTOR]: async ({ commit, dispatch }, data) => {
    commit(new ExtractorLoadingEvent())

    return APIClient.shared.request(new ExtractorAPI.DeleteExtractor(data.guid))
      .then(() => {
        commit(new ExtractorDeletedEvent(data.guid))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  }
}
