import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    PROJECT_LOADING_EVENT = 'project_loading_event',
    PROJECT_LOADED_EVENT = 'project_loaded_event',
    PROJECT_UPDATED_EVENT = 'project_updated_event'
}

export class ProjectLoadingEvent implements FluxStandardAction {
    type = MutationTypes.PROJECT_LOADING_EVENT
}

export class ProjectLoadedEvent implements FluxStandardAction {
    type = MutationTypes.PROJECT_LOADED_EVENT
}

export class ProjectUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.PROJECT_UPDATED_EVENT
}
