import { MutationTree } from 'vuex'
import { RuleTreeElementState, initialState } from './state'
import { MutationTypes } from './mutation-types'

export const mutations: MutationTree<RuleTreeElementState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false
    state.error = error
  },
  [MutationTypes.RULE_TREE_ELEMENT_LOADING_EVENT]: (state) => {
    state.isLoading = true
  },
  [MutationTypes.RULE_TREE_ELEMENT_LOADED_EVENT]: (state) => {
    state.isLoading = false
  }
}
