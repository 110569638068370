<template>
    <div style="height: 100%;">
      <!-- customModal ProgressBar -->
      <div v-if="isShowProgressBar" class="customModal" :style="{ zIndex }">
        <div class="customModal-content">
          <el-progress type="dashboard" :percentage="uploadPercentage" :color="colors"></el-progress>
        </div>
      </div>
        <el-dialog width="30%" :title="$locale.registry.approvals.added_approve"
                   :visible.sync="approvalAddUserDialogVisible">
            <el-select filterable style="width: 100%" v-model="approvalAddUserModel">
                <el-option
                        v-for="item in approvalUsers"
                        :key="item.value"
                        :label="item.full_name"
                        :value="item.id">
                </el-option>
            </el-select>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="addApprovalUser">{{ $locale.main.button.ok }}</el-button>
            </span>
        </el-dialog>
        <!-- кнопка согласования -->
        <el-dropdown trigger="click" @command="handleOperation"
                     class="approvalsButton"
                     style="position: absolute; right: 16px; margin-top: 40px; z-index: 666;"
                     v-if="operations.filter(item => item.visible).length > 0">
          <span class="el-dropdown-link">
              <el-button type="primary">
                  {{$locale.registry.approvals.title}}
              <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
          </span>
          <el-dropdown-menu slot="dropdown">
<!--              <el-dropdown-item
                      :command="{type: 'add_user', stage_id: operations.filter(item => item.visible)[0].stage_id}">
                  {{$locale.registry.approvals.add_approve}}
              </el-dropdown-item>
              <el-divider></el-divider>-->
              <el-dropdown-item :command="operation"
                                v-for="(operation, index) in operations.filter(item => item.visible)" :key="index">
                  {{ operation.name }}
              </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!--<div style="height: calc(100% - 46px);">-->
        <el-form
          ref="validateForm"
          v-loading="loading"
          :element-loading-text="$locale.main.message.loading"
          :model="data"
          :style="`height: calc(100% ${readonly ? '' : ' - 46px'});`"
          @submit.native.prevent
        >
          <InterfaceViewer
            v-if="version === 1"
            :registry-record-id="activeRecordId"
            :registry-id="registryId"
            :model="data"
            ref="interface_viewer"
            :registryCard="{cardId, registryId, recordId}"
          ></InterfaceViewer>
          <InterfaceViewerV2
            v-else-if="version === 2"
            :model="data"
            :invalid-fields="invalidFields"
            :openCardInWindow ="openCardInWindow"
            :blocked-tab-aliases="blockingAliases"
            type="dashboard"
            ref="interface_viewer_v2"
            :saveCardNow="saveCardNow"
            :registryCard="{cardId, registryId, recordId}">
          </InterfaceViewerV2>
        </el-form>
        <!--</div>-->
        <el-row style="padding-right: 50px; position: fixed; bottom: 5px; right: 0; z-index: 666"
        :style="{ position: activePositionStyleForBtn }" v-show="!loading">
            <!--<el-col :span="12">
              <el-dropdown @command="handleApproval" trigger="click" v-if="!!approvalStage.id">
                <el-button size="medium" style="margin: 7px 0 0 10px; background-color: #1E33AF; color: white">{{
                  $locale.registry.approvals.title
                  }}<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="approve" icon="el-icon-plus">{{ approvalStage.approve_text ||
                    $locale.registry.approvals.default_approve }}
                  </el-dropdown-item>
                  <el-dropdown-item command="cancel" icon="el-icon-circle-plus">{{ approvalStage.cancel_text ||
                    $locale.registry.approvals.default_cancel }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <span v-else style="opacity: 0">bug fix</span>
            </el-col>-->
            <el-col style="text-align: right; margin-top: 7px;"  v-if="showBlockButtons">
                <el-button style="z-index: 666; position: relative;" type="primary" plain @click="saveRecord()" v-show="!readonly" v-if="!isBlocked && !buttonSave.btnSaveHidden" :disabled="loading"
                           size="medium">{{ this.buttonSave.text || $locale.main.button.save }}
                </el-button>
                <el-button v-if="!buttonClose.btnCloseHidden" size="medium"
                  v-show="showCloseCardBtn"
                style="z-index: 666; position: relative;" type="primary" plain
                @click="$emit('cancelChanges', { confirmCloseCard: buttonClose.confirmCloseCard, confirmCloseCardText: buttonClose.confirmCloseCardText })">
                {{ $locale.main.button.close }}
                </el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import InterfaceViewer from '@/components/InterfaceViewer/index.vue'
import InterfaceViewerV2 from '@/services/InterfaceViewer/index.vue'
import Card from './Models/Card'
import Registry from './Models/Registry'
import Record from './Models/Record'
import { eventBus } from '@/eventBus'
import CommandExecutor from '@/core/infrastructure/service/CommandExecutor'

import { PopupManager } from 'element-ui/src/utils/popup'

// API
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { UserAPI } from '@/services/AccessEditor/infrastructure/api/UserAPI'
import EventFactory from '@/core/infrastructure/service/EventFactory'

// helpes
import { isNumeric } from '@/helpers/index'
import Vue from 'vue'
import { cloneObject, getDefaultValues } from '@/components/RegistryCard/utils'
export default {
  props: {
    'card-id': {},
    'registry-id': {},
    'record-id': {},
    'initial-data': {},
    'readonly': {},
    'quick': {},
    'addMainTabInWindow': {},
    'showBlockBtn': {
      type: Boolean,
      default: true
    },
    showCloseCardBtn: {
      type: Boolean,
      default: true
    },
    openCardInWindow: {
      type: Boolean,
      default: false
    },
    context: null
  },
  name: 'RegistryCard',
  components: {
    InterfaceViewer,
    InterfaceViewerV2
  },
  data () {
    return {
      saveCardNow: false,
      version: null,
      listeners: {},
      loading: true,
      error: false,
      structure: {},
      operations: [],
      approvalAddUserDialogVisible: false,
      approvalAddUserModel: null,
      approvalUsers: [],
      name: null,
      registry: null,
      data: {},
      recordData: {},
      isBlocked: this.readonly,
      blockingFields: [],
      blockingAliases: [],
      showingFields: [],
      hiddenFields: [],
      activeStageId: null,
      activeRecordId: this.recordId,
      defaultState: null,
      invalidFields: [],
      recalculateFields: [
        'registry/address_field',
        'registry/gar_address_field',
        'registry/string_field',
        'registry/text_field',
        'registry/integer_field',
        'registry/float_field',
        'registry/xref_field',
        'registry/xref_multi_field',
        'registry/date_field',
        'registry/datetime_field',
        'registry/boolean_field',
        'registry/address_multi_field',
        'basic/a-file'
      ],
      excludingAttributes: [
        'create_date',
        'create_user_id',
        'delete_date',
        'delete_user_id',
        'update_date',
        'update_user_id'
      ],
      fileAttributes: [],
      excludingComponents: ['registry/file_field', 'registry/xref_outer_field'],
      showBlockButtons: this.showBlockBtn,
      buttonSave: {
        text: this.$locale.main.button.save,
        closeCardOnEdit: false,
        closeCardOnAdd: false,
        btnSaveHidden: false
      },
      buttonClose: {
        btnCloseHidden: false,
        confirmCloseCard: false,
        confirmCloseCardText: ''
      },
      activePositionStyleForBtn: 'fixed',
      // progressBar
      uploadPercentage: 0,
      isShowProgressBar: false,
      colors: [
        { color: '#f56c6c', percentage: 20 },
        { color: '#e6a23c', percentage: 40 },
        { color: '#5cb87a', percentage: 60 },
        { color: '#1989fa', percentage: 80 },
        { color: '#6f7ad3', percentage: 100 }
      ],
      zIndex: 0,
      eventBus: new Vue()
    }
  },
  provide () {
    return {
      getRegistryRecordId: this.getRegistryRecordId,
      getRegistryId: this.getRegistryId,
      getCard: this.getCard,
      getRawData: this.getRecordData,
      addMainTabInWindow: this.addMainTabInWindow,
      getInterfaceEditorVersion: this.getVersion,
      getEventBus: this.getEventBus
    }
  },
  computed: {
    preparedData () {
      let data = { card_id: this.cardId }
      Object.keys(this.data).forEach((field) => {
        if (!this.excludingAttributes.includes(field) && !this.fileAttributes.includes(field)) {
          data[field] = this.data[field]
        }
      })

      return data
    },
    cardIdToNumber () {
      if (isNumeric(this.cardId)) {
        return parseFloat(this.cardId)
      }
      return null
    }
  },
  watch: {
    showBlockBtn: {
      handler: function (newValue) {
        this.showBlockButtons = newValue
      }
    }
  },
  async mounted () {
    // console.log('card mounted event')
    if (!this.cardId || !this.registryId) {
      this.error = true
      return false
    }
    // this.$store.commit('setRegistryId', this.registryId)
    this.registry = new Registry({ id: this.registryId })
    this.loading = true
    let card = await Card.find(this.cardId)
    if (card) {
      this.name = card.name
      this.structure = JSON.parse(card.structure)
      this.operations = JSON.parse(card.structure).operations || []
      this.defaultState = JSON.parse(card.structure).defaultState
      this.listeners = JSON.parse(card.structure).listeners || {}
      this.buttonClose.btnCloseHidden = JSON.parse(card.structure).btnCloseHidden || false
      this.buttonClose.confirmCloseCard = JSON.parse(card.structure).confirmCloseCard || false
      this.buttonClose.confirmCloseCardText = JSON.parse(card.structure).confirmCloseCardText || this.$locale.interface_editor.main.confirm_close_card_text
      this.buttonSave.text = JSON.parse(card.structure).buttonSave?.text || this.$locale.main.button.save
      this.buttonSave.btnSaveHidden = JSON.parse(card.structure).buttonSave?.btnSaveHidden || false
      this.buttonSave.closeCardOnEdit = JSON.parse(card.structure).buttonSave?.closeCardOnEdit || false
      this.buttonSave.closeCardOnAdd = JSON.parse(card.structure).buttonSave?.closeCardOnAdd || false
      if (this.activeRecordId) {
        let activeRecordId = JSON.parse(this.activeRecordId)
        if (activeRecordId.length > 0) {
          activeRecordId = activeRecordId[0].id
        } else {
          activeRecordId = this.activeRecordId
        }
        await this.loadData(activeRecordId)
      } else {
        if (this.defaultState) {
          await this.loadDefaultState()
        }
        let data = await this.getDefaultConstraints()
        this.blockingFields = this.getBlockedFields(data.disable_constraints)
        this.blockingAliases = this.getBlockedAliases(data.disable_constraints)
        this.showingFields = this.getShowingFields(data.view_constraints)
        this.hiddenFields = this.getHiddenFields(data.view_constraints)
      }
      this.$emit('change-name', this.getCardName({
        titleForAdd: JSON.parse(card.structure).titleForAdd,
        titleForEdit: JSON.parse(card.structure).titleForEdit,
        name: card.name
      }, this.recordId))
      if (this.structure) {
        this.getComponentsArray().forEach((item, index, array) => {
          const initialType =
            typeof item.group !== 'undefined'
              ? `${item.group}/${item.initialType}` : item.initialType
          if (!this.data[item.properties.name] && /attr_[0-9]+_/i.test(item.properties.name)) {
            if (!(item.properties.name in this.initialData) && !(item.properties.name in this.recordData) && initialType !== 'registry/xref_outer_field') {
              // console.warn(`Не найдены данные в записи по компоненту с аттрибутом = ${item.properties.name}`)
            }
            this.$set(this.data, item.properties.name, this.initialData[item.properties.name] || this.recordData[item.properties.name])
            if (initialType === 'registry/xref_multi_field' && item.properties.name) {
              // мн. адрес внезапно считается мн. ссылкой
              // value[0] > 0 - проверка на адрес
              let value = this.initialData[item.properties.name] || this.recordData[item.properties.name]
              if (typeof value !== 'undefined') {
                try {
                  value = JSON.parse(value)
                } catch (e) {
                // значение по умолчанию в мн. ссылке переданное при открытие карточки
                  value = value + ''
                  value = value.split(',').map(item => +item)
                  if (Array.isArray(value) && value[0] > 0) {
                    console.log('значение множественной ссылки', value)
                    this.$set(this.data, item.properties.name, value)
                  }
                }
              }
            }
            if (initialType === 'registry/address_field' && item.properties.name) {
              let value = this.initialData[item.properties.name] || this.recordData[item.properties.name]
              try {
                value = JSON.parse(value)
              } catch (e) {

              }

              if (Array.isArray(value)) {
                value = value[0] ?? null
              }
              this.$set(this.data, item.properties.name, value)
            }

            if (initialType === 'registry/gar_address_field' && item.properties.name) {
              let value = this.initialData[item.properties.name] || this.recordData[item.properties.name]
              try {
                value = JSON.parse(value)
              } catch (e) {

              }

              if (Array.isArray(value)) {
                value = value[0] ?? null
              }
              this.$set(this.data, item.properties.name, value)
            }

            if (this.excludingComponents.includes(initialType)) {
              this.excludingAttributes.push(item.properties.name)
            }
            if (initialType === 'basic/a-file' && item.properties.name) {
              this.fileAttributes.push(item.properties.name)
            }
          }
          let alwaysActive = false
          if (item.properties.alwaysActive) {
            alwaysActive = item.properties.alwaysActive
          }
          if (!alwaysActive) {
            if (this.isBlocked ||
                (this.blockingFields.map(item => `attr_${item}_`).includes(item.properties.name) &&
                /attr_[0-9]+_/i.test(item.properties.name))) {
              this.$set(item.properties, 'readonly', true)
            }
            if (this.isBlocked ||
              (this.blockingAliases.includes(item.properties.editorAlias))) {
              this.$set(item.properties, 'readonly', true)
            }
          }
          if (this.showingFields.map(item => `attr_${item}_`).includes(item.properties.name) &&
              /attr_[0-9]+_/i.test(item.properties.name)) {
            item.properties.hiddenCondition = { type: 'never' }
          }
          if (this.hiddenFields.map(item => `attr_${item}_`).includes(item.properties.name) &&
                /attr_[0-9]+_/i.test(item.properties.name)) {
            item.properties.hiddenCondition = { type: 'always' }
          }
        })
        if (typeof this.structure.version !== 'undefined') {
          this.version = this.structure.version
        } else {
          this.version = 1
        }
        this.$nextTick(() => {
          this.loadInterface(this.structure)
        })
      }

      await this.loadDefaultValues()
    } else {
      this.error = true
    }
    this.loading = false
    this.$nextTick(() => {
      if (this.$el.offsetParent?.className === 'el-message-box__container') {
        this.activePositionStyleForBtn = 'sticky'
      }
    })
  },
  methods: {
    async loadDefaultValues () {
      const defaultValues = await getDefaultValues(this.structure, this)

      const defaultData = {}

      console.group()

      for (const key of Object.keys(defaultValues)) {
        if (typeof this.data[key] === 'undefined') {
          defaultData[key] = defaultValues[key]

          console.log(key, defaultData[key])
        }
      }

      console.log(this.data)

      console.groupEnd()

      this.data = Object.assign(
        cloneObject(this.data || {}),
        cloneObject(defaultData)
      )
    },
    getModel () {
      return this.data
    },
    getRegistryId () {
      return this.registryId
    },
    getRegistryRecordId () {
      return this.activeRecordId
    },
    getComponentsArray () {
      if (!this.structure.components) {
        return []
      }
      if (Array.isArray(this.structure.components)) {
        return this.structure.components
      } else if (typeof this.structure.components === 'object') {
        let components = []
        for (let key in this.structure.components) {
          if (this.structure.components.hasOwnProperty(key)) {
            components.push(this.structure.components[key])
          }
        }
        return components
      }
    },
    loadInterface (data) {
      if (this.version === 1) {
        this.$refs.interface_viewer?.loadState(data)
      } else if (this.version === 2) {
        this.$refs.interface_viewer_v2?.loadInterface(data)
      }
    },
    async getDefaultConstraints () {
      let data = await this.$http.get(`${this.$config.api}/registryservice/registry/${this.registryId}/constraints`)
      return data.data
    },
    getRecordData () {
      return this.recordData
    },
    getCardName (settings, recordId) {
      if (recordId) {
        return settings.titleForEdit ? this.calculateCardName(settings.titleForEdit) : settings.name
      }
      return settings.titleForAdd ? this.calculateCardName(settings.titleForAdd) : settings.name
    },
    calculateCardName (name) {
      let attributes = name.match(/\{{(.*?)\}}/g) || []
      let me = this
      attributes.forEach((attribute) => {
        attribute = attribute.replace('{{', '').replace('}}', '')
        let value = me.recordData[attribute]
        try {
          value = JSON.parse(value)
        } catch (e) {

        }
        if (value instanceof Array) {
          value = value.map(item => item.name).join(',')
        }
        name = name.replace(`{{${attribute}}}`, value || '')
      })

      return name
    },
    getCard () {
      return this
    },
    setLoading (value = true) {
      this.loading = value
    },
    async addApprovalUser () {
      if (this.approvalAddUserModel && this.activeStageId && this.activeRecordId) {
        this.approvalAddUserDialogVisible = false
        this.$http.post(`${this.$config.api}/registryservice/registry/stages/${this.activeStageId}/users`, {
          user_id: this.approvalAddUserModel,
          record_id: this.activeRecordId
        })
      } else {
        this.$notify.error({
          title: this.$locale.main.message.error,
          message: 'Не выбран согласующий'
        })
      }
    },
    async loadDefaultState () {
      let data = await this.$http.get(`${this.$config.api}/registryservice/registry/${this.registryId}/state/${this.defaultState}`)
      this.data = data.data
    },
    getIsBlocked (constraints = []) {
      return constraints.filter((item) => {
        return ((item.cards || []).length === 0 || (item.cards || []).includes(this.cardIdToNumber)) && item.full
      }).length > 0
    },
    getBlockedFields (constraints = []) {
      let fields = []
      let data = constraints.filter((item) => {
        return ((item.cards || []).length === 0 || (item.cards || []).includes(this.cardIdToNumber)) && !item.full
      })
      data.forEach((item) => {
        fields.push(...item.fields)
      })
      return fields
    },
    getBlockedAliases (constraints = []) {
      let aliases = []
      let data = constraints.filter((item) => {
        return ((item.cards || []).length === 0 || (item.cards || []).includes(this.cardIdToNumber)) && !item.full
      })
      data.forEach((item) => {
        aliases.push(...(item.aliases || []))
      })
      return aliases
    },
    getShowingFields (constraints = []) {
      let fields = []
      let data = constraints.filter((item) => {
        return ((item.cards || []).length === 0 || (item.cards || []).includes(this.cardIdToNumber)) && !item.full
      })
      data.forEach((item) => {
        fields.push(...item.fields.filter((field) => field.type === 'view_field').map((field) => field.field))
      })
      return fields
    },
    getHiddenFields (constraints = []) {
      let fields = []
      let data = constraints.filter((item) => {
        return ((item.cards || []).length === 0 || (item.cards || []).includes(this.cardIdToNumber)) && !item.full
      })
      data.forEach((item) => {
        fields.push(...item.fields.filter((field) => field.type === 'hide_field').map((field) => field.field))
      })
      return fields
    },
    async handleOperation (operation) {
      if (operation.type === 'approval') {
        this.handleApproval(operation.approval_type, operation.stage_id)
      } else if (operation.type === 'add_user' && operation.stage_id) {
        await this.loadUsers()
        this.activeStageId = operation.stage_id
        this.approvalAddUserDialogVisible = true
      }
      this.loading = false
    },
    async loadUsers () {
      if (this.approvalUsers.length === 0) {
        // let users = await this.$http.get(`${this.$config.api}/accesseditor/users`)
        let users = []
        try {
          users = await APIClient.shared.request(new UserAPI.GetUsers([]))
        } catch (error) {
          console.log({ error })
        }
        this.approvalUsers = users.map((item) => {
          item.full_name = `${item.surname} ${item.name} ${item.midname}`
          return item
        })
      }
    },
    handleApproval (type, stageId) {
      this.$prompt(this.$locale.registry.approvals.comment, this.$locale.registry.approvals.title, {
        confirmButtonText: this.$locale.main.button.ok,
        cancelButtonText: this.$locale.main.button.cancel,
        inputErrorMessage: this.$locale.main.message.error
      }).then(({ value }) => {
        this.loading = true
        this.$http.post(`${this.$config.api}/registryservice/registry/${this.registryId}/records/${this.activeRecordId}/${type}`, {
          stage_id: stageId,
          comment: value
        }).then(() => {
          this.$emit('cancelChanges')
        }).catch((error) => {
          if (
            error.response?.data?.error === 'need_to_sign_record_before_approve_exception' &&
            error.response?.data?.params?.command_id
          ) {
            let me = this
            CommandExecutor.execute(this, error.response.data.params.command_id, false, () => {
              me.loading = true
              me.$http.post(`${me.$config.api}/registryservice/registry/${me.registryId}/records/${me.activeRecordId}/${type}`, {
                stage_id: stageId,
                comment: value,
                skip_eds_sign: true
              }).then(() => {
                me.$emit('recordEdited', me.activeRecordId)
                me.$emit('cancelChanges')
              }).finally(() => {
                me.loading = false
              })
            })
          }
          if (error.response?.data?.error === 'constraint_verification_failed') {
            let message = '<ul>'

            error.response.data.params.errors.forEach((item) => {
              const required = '' // item.is_allow_saving ? '(необязательно)' : '(обязательно)'
              message += `<li>${item.message} <strong>${required}</strong></li>`
            })

            message += '</ul>'

            this.$alert(`Необходимо исправить ошибки!${message}`, this.$locale.main.message.attention, {
              confirmButtonText: this.$locale.main.button.ok,
              type: 'error',
              dangerouslyUseHTMLString: true,
              lockScroll: true,
              callback: (action) => {
              }
            })
          }
        }).finally(() => {
          this.loading = false
        })
      }).finally(() => {
        this.loading = false
      })
    },

    async loadData (recordId) {
      if (!recordId) {
        return {}
      }
      this.loading = true
      let { data } = await this
        .$http
        .get(`${this.$config.api}/registryservice/registry/${this.registryId}/card/${recordId}?card_id=${this.cardId}`)
      // let data = await this.registry.data().find(recordId)
      this.$set(this, 'recordData', data)
      this.$set(this.data, 'id', recordId)
      this.$set(this.data, 'guid', data.guid)
      this.prepareApprovalStages(data.approval_stages)
      this.isBlocked = this.isBlocked || this.getIsBlocked(data.disable_constraints)
      if (!this.isBlocked) {
        this.blockingFields = this.getBlockedFields(data.disable_constraints)
        this.blockingAliases = this.getBlockedAliases(data.disable_constraints)
      }
      this.showingFields = this.getShowingFields(data.view_constraints)
      this.hiddenFields = this.getHiddenFields(data.view_constraints)
      this.loading = false

      return cloneObject(data)
    },
    saveRecord (parameters = {}, showNotificationMessages = true) {
      this.loading = true
      this.saveCardNow = true

      return new Promise((resolve, reject) => {
        this.$refs.validateForm.validate(async (valid, invalidFields) => {
          if (valid || Object.values(invalidFields).filter(el => !el[0]?.skip).length === 0) {
            this.invalidFields = []
            if (!this.quick) {
              let recordData = JSON.parse(JSON.stringify(this.preparedData))

              if (parameters.force_record_id) {
                recordData.id = parameters.force_record_id
              }
              const event = recordData.id ? 'recordEdited' : 'recordAdded'
              if (parameters.force) {
                recordData.force = parameters.force
              }
              if (parameters.commandId) {
                recordData.command_id = parameters.commandId
              }
              if (parameters.check_conditions === false) {
                // на беке по умолчанию TRUE
                recordData.check_conditions = parameters.check_conditions
              }

              let formData = new FormData()

              for (let key in recordData) {
                formData.append(key, JSON.stringify(recordData[key]))
              }

              this.fileAttributes.forEach((item) => {
                if (typeof this.data[item] === 'string') {
                  return
                }
                (this.data[item] || []).forEach((file, index) => {
                  formData.append(`${item}[${index}]`, file.raw)
                })
              })

              let answer
              try {
                let requestFunction = recordData.id ? this.$http.put : this.$http.post
                answer = await requestFunction(`${this.$config.api}/registryservice/registry/${this.registryId}/records${recordData.id ? `/${recordData.id}` : ''}`,
                  formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: function (progressEvent) {
                      this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                      this.loading = false
                      this.isShowProgressBar = true
                      this.zIndex = PopupManager.nextZIndex()
                      if (this.uploadPercentage === 100) {
                        this.loading = true
                        this.isShowProgressBar = false
                      }
                    }.bind(this)
                    // hideNotification: showNotificationMessages
                  }
                )
              } catch (e) {
                console.log('saveRecord', e.response.data)
                this.isShowProgressBar = false
                if (e.response.data.error === 'record_constraint_forbidden') {
                  this.$message({
                    showClose: true,
                    dangerouslyUseHTMLString: true,
                    message: e.response.data.message.replace('\r\n', '<br>'),
                    type: 'error'
                  })
                }
                if (e.response.data.error === 'record_constraint_notify') {
                  let asyncConfirm = async () => {
                    return new Promise((resolve, reject) => {
                      this.$confirm(e.response.data.message, this.$locale.main.message.attention, {
                        confirmButtonText: this.$locale.main.button.ok,
                        cancelButtonText: this.$locale.main.button.cancel,
                        type: 'warning'
                      }).then(() => {
                        resolve()
                      }).catch(() => {
                        reject(new Error('cancel confirm'))
                      })
                    })
                  }

                  try {
                    await asyncConfirm()
                    this.saveRecord(Object.assign(parameters, { force: true }))
                    resolve()
                    return
                  } catch (error) {
                    reject(new Error('Отмена действия'))
                    return
                  }
                }
                if (e.response.data.error === 'constraint_verification_failed') {
                  let message = '<ul>'

                  e.response.data.params.errors.forEach((item) => {
                    const required = '' // item.is_allow_saving ? '(необязаьельно)' : '(обязательно)'
                    message += `<li>${item.message} <strong>${required}</strong></li>`
                  })

                  message += '</ul>'

                  if (e.response.data.params.is_allow_saving) {
                    await this.$confirm(`Вы действительно хотите сохранить? ${message}`, this.$locale.main.message.attention, {
                      confirmButtonText: this.$locale.main.button.ok,
                      cancelButtonText: this.$locale.main.button.cancel,
                      dangerouslyUseHTMLString: true,
                      lockScroll: true,
                      type: 'warning'
                    }).then(async () => {
                      await this.saveRecord(Object.assign(parameters, { check_conditions: false }))
                      resolve()
                    }).catch(() => reject())
                  } else {
                    this.$alert(`Необходимо исправить ошибки!${message}`, this.$locale.main.message.attention, {
                      confirmButtonText: this.$locale.main.button.ok,
                      type: 'error',
                      dangerouslyUseHTMLString: true,
                      lockScroll: true,
                      callback: (action) => {}
                    })
                  }
                }
                reject(e.response.data)
                return false
              }
              this.activeRecordId = recordData.id || answer.data.id
              if (this.listeners.update_event && event === 'recordEdited') {
                import(`@/plugins/${this.$config.project}/${this.listeners.update_event}`)
                  .then((Plugin) => {
                    let instance = new Plugin.default(this)
                    instance.execute()
                  }).catch(() => {
                    console.log(`Plugin: ${this.listeners.update_event} not found (listeners.update_event)`)
                  })
              }
              this.$emit(event, this.activeRecordId)

              const data = await this.loadData(this.activeRecordId)
              this.recalculateData(data)
              this.saveCardNow = true
              this.getEventBus().$emit('registry-card-saved')
              if (this.context && typeof this.context.getEventBus === 'function') {
                this.context.getEventBus().$emit('registry-card-saved')
              }
              if (event === 'recordEdited' && this.buttonSave.closeCardOnEdit) {
                this.$emit('cancelChanges')
              }
              if (event === 'recordAdded' && this.buttonSave.closeCardOnAdd) {
                this.$emit('cancelChanges')
              }
              resolve()
            } else {
              this.$emit('quick-add', this.data)
            }
          } else {
            this.invalidFields = Object.keys(invalidFields)
            console.warn('Невалидные поля', this.invalidFields)
            this.$message.error('Заполните обязательные поля')
            reject('Не заполнены обязательные поля')
            return false
          }
        })
      }).finally(() => {
        this.loading = false
        this.saveCardNow = false
        this.isShowProgressBar = false
        EventFactory.send(
          this,
          {
            eventType: this.recordId ? 'save_card' : 'new_save_card',
            entityId: this.cardId,
            entityType: 'card',
            entityName: this.name,
            recordId: this.activeRecordId,
            objectId: this.registryId
          }
        )
      })
    },
    recalculateData (data) {
      this.getComponentsArray().forEach((item) => {
        const initialType =
          typeof item.group !== 'undefined'
            ? `${item.group}/${item.initialType}` : item.initialType
        if (/attr_[0-9]+_/i.test(item.properties.name) && this.recalculateFields.includes(initialType)) {
          let value = data[item.properties.name]
          let parsed
          switch (initialType) {
            case 'registry/xref_field':
              if (!value) {
                value = null
                break
              }
              parsed = null
              try {
                parsed = JSON.parse(value)
              } catch (e) {
              }
              if (parsed instanceof Array && parsed.length > 0) {
                value = parsed[0].id
              } else if (typeof parsed === 'number') {
                value = parsed
              } else {
                value = null
              }
              break
            case 'registry/xref_multi_field':
              if (!value) {
                value = []
                break
              }
              parsed = []
              try {
                parsed = JSON.parse(value)
              } catch (e) {
              }
              if (parsed instanceof Array && parsed.length > 0) {
                value = parsed.map((item) => item.id)
              } else if (typeof parsed === 'number') {
                value = [parsed]
              } else {
                value = []
              }
              break
            default:
              break
          }
          this.$set(this.data, item.properties.name, value)
        }
        if (
          this.isBlocked ||
              (this.blockingFields.map(item => `attr_${item}_`).includes(item.properties.name) &&
                  /attr_[0-9]+_/i.test(item.properties.name))
        ) {
          this.$set(item.properties, 'isReadonly', { type: 'always' })
        }
        if (
          this.isBlocked ||
          (this.blockingAliases.includes(item.properties.editorAlias))
        ) {
          this.$set(item.properties, 'readonly', true)
        }
        if (this.showingFields.map(item => `attr_${item}_`).includes(item.properties.name) &&
              /attr_[0-9]+_/i.test(item.properties.name)) {
          this.$set(item.properties, 'hiddenCondition', { type: 'never' })
        }
        if (this.hiddenFields.map(item => `attr_${item}_`).includes(item.properties.name) &&
              /attr_[0-9]+_/i.test(item.properties.name)) {
          this.$set(item.properties, 'hiddenCondition', { type: 'always' })
        }
      })
    },
    prepareApprovalStages (stages) {
      this.operations = []
      if (!stages) {
        return false
      }
      stages.forEach((stage) => {
        if (stage.approve_text) {
          this.operations.push({
            visible: true,
            name: stage.approve_text || 'Согласовать',
            approval_type: 'approve',
            stage_id: stage.id,
            type: 'approval'
          })
        }
        if (stage.cancel_text) {
          this.operations.push({
            visible: true,
            name: stage.cancel_text || 'Вернуть на доработку',
            approval_type: 'cancel',
            stage_id: stage.id,
            type: 'approval'
          })
        }

        /* if (stage.approve_button_id) {
          const operation = this.operations.find((item) => item.id === stage.approve_button_id)
          if (operation) {
            operation.name = stage.approve_text || operation.name
            operation.approval_type = 'approve'
            operation.stage_id = stage.id
            operation.visible = true
          }
        }
        if (stage.approve_button_id) {
          const operation = this.operations.find((item) => item.id === stage.cancel_button_id)
          if (operation) {
            operation.name = stage.cancel_text || operation.name
            operation.approval_type = 'cancel'
            operation.stage_id = stage.id
            operation.visible = true
          }
        } */
      })
    },
    getVersion () {
      return this.version
    },
    hideButtonsBlock () {
      this.changeCardButtonsVisibility(false)
    },
    showButtonsBlock () {
      this.changeCardButtonsVisibility(true)
    },
    changeCardButtonsVisibility (val) {
      this.$set(this, 'showBlockButtons', val)
    },
    getEventBus () {
      return this.eventBus
    }
  }
}
</script>

<style scoped>
.customModal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0,0,0,0.4); */
}

.customModal-content {
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
</style>
