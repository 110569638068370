export default class SetLandscapeCommand
{
    private guid: string;
    private minZoom: number = 3;
    private maxZoom: number = 5;
    private numThreads: number = 1;
    private forceTiling: boolean = false;
    private applyNewStyles: boolean = false;

    constructor(guid: string, minZoom: number, maxZoom: number, numThreads: number, forceTiling: boolean, applyNewStyles: boolean)
    {
        this.guid = guid;
        if (maxZoom < minZoom) {
            throw new Error('Maximin zoom must be greater than minimum zoom');
        }
        this.minZoom = minZoom;
        this.maxZoom = maxZoom;
        this.numThreads = numThreads;
        this.forceTiling = forceTiling;
        this.applyNewStyles = applyNewStyles;
    }

    getGuid(): string
    {
        return this.guid;
    }

    getMinZoom(): number
    {
        return this.minZoom;
    }

    getMaxZoom(): number
    {
        return this.maxZoom;
    }
    
    getNumThreads(): number
    {
        return this.numThreads;
    }

    getForceTiling(): boolean
    {
        return this.forceTiling;
    }

    getApplyNewStyles(): boolean
    {
        return this.applyNewStyles;
    }

    getClassName(): string
    {
        return 'SetLandscapeCommand';
    }
}