import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ElRuLocale from 'element-ui/lib/locale/lang/ru-RU'
import ElEnLocale from 'element-ui/lib/locale/lang/en'
import ElIdLocale from 'element-ui/lib/locale/lang/id'
import locales from '@/locale/ru.json'
import localesEn from '@/locale/en.json'
import localesId from '@/locale/id.json'

Vue.use(VueI18n)

const messages = {
  ru: {
    ...locales,
    el: ElRuLocale.el
  },
  en: {
    ...localesEn,
    el: ElEnLocale.el
  },
  id: {
    ...localesId,
    el: ElIdLocale.el
  }
}

const i18n = new VueI18n({
  locale: 'ru',
  fallbackLocale: 'ru',
  messages
})

export default i18n
