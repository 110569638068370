import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    TASK_LOADING_EVENT = 'task_loading_event',
    TASK_CREATED_EVENT = 'task_created_event',
    TASK_UPDATED_EVENT = 'task_updated_event',
    TASK_DELETED_EVENT = 'task_deleted_event'
}

export class TaskLoadingEvent implements FluxStandardAction {
  type = MutationTypes.TASK_LOADING_EVENT
}

export class TaskCreatedEvent implements FluxStandardAction {
  type = MutationTypes.TASK_CREATED_EVENT
  constructor (public location: string) {}
}

export class TaskUpdatedEvent implements FluxStandardAction {
  type = MutationTypes.TASK_UPDATED_EVENT
  constructor (public location: string) {}
}

export class TaskDeletedEvent implements FluxStandardAction {
  type = MutationTypes.TASK_DELETED_EVENT
  constructor (public guid: string) {}
}
