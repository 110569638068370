import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    RECORD_HISTORY_LOADED_EVENT = 'entities_loaded_event',
    RECORD_HISTORY_LOADING_EVENT = 'entities_loading_event',
}

export class RecordHistoryLoadedEvent implements FluxStandardAction {
    type = MutationTypes.RECORD_HISTORY_LOADED_EVENT
}

export class RecordHistoryLoadingEvent implements FluxStandardAction {
    type = MutationTypes.RECORD_HISTORY_LOADING_EVENT
}
