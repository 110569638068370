import { ApprovalStageDTO, IApprovalStage } from '@/services/LogicEditor/domain/model/ApprovalStage'

export interface IApproval {
  id?: number|null;
  guid?: string|null;
  name: string
  description: string|null;
  approval_states: number[];
  approval_stages: IApprovalStage[];
  alias: string|null;
  logic_id?: number
  parent_tree_element_id?: number|null;
  is_enabled?: boolean
}

export class ApprovalDTO {
  id?: number|null;
  guid?: string|null;
  name: string
  description: string|null;
  approval_states: number[];
  approval_stages: ApprovalStageDTO[];
  alias: string|null;
  logic_id?: number
  parent_tree_element_id?: number|null;
  is_enabled?: boolean

  constructor (data: IApproval) {
    this.id = data.id
    this.guid = data.guid
    this.name = data.name
    this.description = data.description
    this.approval_states = data.approval_states
    this.alias = data.alias
    this.logic_id = data.logic_id
    this.parent_tree_element_id = data.parent_tree_element_id
    this.is_enabled = data.is_enabled

    // subordinate entities
    if (data.approval_stages && Array.isArray(data.approval_stages)) {
      this.approval_stages = data.approval_stages.map(item => new ApprovalStageDTO(item))
    } else {
      this.approval_stages = []
    }
  }

  static create (): ApprovalDTO {
    return new ApprovalDTO({
      id: null,
      guid: null,
      name: null,
      description: null,
      approval_states: [],
      approval_stages: [],
      logic_id: null,
      alias: null,
      parent_tree_element_id: null,
      is_enabled: true
    })
  }
}
