import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class StrokeStyleDTO {
    id?: Nullable<number>;
    guid?: Nullable<string>;
    name?: Nullable<string>;
    description?: Nullable<string>;
    pattern?: Array<string>;

    constructor ({ id, guid, name, description, pattern }: {id?: Nullable<number>, guid?: Nullable<string>, name?: Nullable<string>, description?: Nullable<string>, pattern?: Array<string>}) {
      this.id = id
      this.guid = guid
      this.name = name
      this.description = description
      this.pattern = pattern
    }
}

export default class StrokeStyle extends AbstractBaseEntity {
    private id?: Nullable<number>;
    private guid?: Nullable<string>;
    private name?: Nullable<string>;
    private description?: Nullable<string>;
    private pattern?: Array<string>;

    constructor (id: Nullable<number>, guid: Nullable<string>, name: Nullable<string>, description: Nullable<string>, pattern?: Array<string>) {
      super()
      this.id = id
      this.guid = guid
      this.name = name
      this.description = description
      this.pattern = pattern
    }

    static create (dto: StrokeStyleDTO): StrokeStyle {
      return new StrokeStyle(dto.id, dto.guid, dto.name, dto.description, dto.pattern)
    }

    setId (id: number): void {
      this.id = id
    }

    getId (): number {
      return this.id
    }

    setGuid (guid: string): void {
      this.guid = guid
    }

    getGuid (): string {
      return this.guid
    }

    setName (name: string): void {
      this.name = name
    }

    getName (): string {
      return this.name
    }

    setDescription (description: Nullable<string>): void {
      this.description = description
    }

    getDescription (): Nullable<string> {
      return this.description
    }

    setPattern (pattern: Array<string>): void {
      this.pattern = pattern
    }

    getPattern (): Array<string> {
      return this.pattern
    }
}
