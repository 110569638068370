import QueryInterface from '@/core/application/query/QueryInterface'
import { Nullable } from '@/core/domain/type/types'

export default class ProjectTreeElementsQuery implements QueryInterface
{
    private parentId: Nullable<number>;

    constructor(parentId: Nullable<number>)
    {
        this.parentId = parentId;
    }

    getParentId(): Nullable<number>
    {
        return this.parentId;
    }

    getClassName(): string
    {
        return 'DigitalTwinProjectTreeElementsQuery';
    }
}