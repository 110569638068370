import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    TASK_REGISTRY_ENTITIES_LOADING_EVENT = 'task_registry_entities_loading_event',
    TASK_REGISTRY_ENTITIES_LOADED_EVENT = 'task_registry_entities_loaded_event',
    TASK_REGISTRY_ENTITIES_UPDATED_EVENT = 'task_registry_entities_updated_event',
}

export class TaskRegistryEntitiesLoadingEvent implements FluxStandardAction {
  type = MutationTypes.TASK_REGISTRY_ENTITIES_LOADING_EVENT
}

export class TaskRegistryEntitiesLoadedEvent implements FluxStandardAction {
  type = MutationTypes.TASK_REGISTRY_ENTITIES_LOADED_EVENT
}

export class TaskRegistryEntitiesUpdatedEvent implements FluxStandardAction {
  type = MutationTypes.TASK_REGISTRY_ENTITIES_UPDATED_EVENT
}
