import { MutationTree } from 'vuex'
import { FormulaState, initialState } from './state'
import { MutationTypes, FormulaCreatedEvent, FormulaUpdatedEvent, FormulaDeletedEvent } from './mutation-types'

export const mutations: MutationTree<FormulaState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false
    state.error = error
  },
  [MutationTypes.FORMULA_LOADING_EVENT]: (state) => {
    state.isLoading = true
  },
  [MutationTypes.FORMULA_LOADED_EVENT]: (state) => {
    state.isLoading = false
  },
  [MutationTypes.FORMULA_CREATED_EVENT]: (state, action: FormulaCreatedEvent) => {
    state.isLoading = false
    state.location = action.location
  },
  [MutationTypes.FORMULA_UPDATED_EVENT]: (state, action: FormulaUpdatedEvent) => {
    state.isLoading = false
  },
  [MutationTypes.FORMULA_DELETED_EVENT]: (state, action: FormulaDeletedEvent) => {
    state.isLoading = false
    state.deletedFormulaGuid = action.guid
  }
}
