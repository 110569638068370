import { AxiosResponse } from 'axios'
import { Nullable } from '@/core/domain/type/types'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { ProjectGroupDTO } from '@/services/MapEditor/domain/model/Project/ProjectGroup'

export namespace ProjectGroupAPI {

    export class UpdateProjectGroup implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: ProjectGroupDTO) {
            this.path = `/mapeditor/project_groups/${params.guid}`;
        }
    }

}