import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    SOURCE_LOADING_EVENT = 'source_loading_event',
    SOURCE_LOADED_EVENT = 'source_loaded_event',
    SOURCE_CREATED_EVENT = 'source_created_event',
    SOURCE_UPDATED_EVENT = 'source_updated_event',
    SOURCE_DELETED_EVENT = 'source_deleted_event' 
}

export class SourceLoadingEvent implements FluxStandardAction {
    type = MutationTypes.SOURCE_LOADING_EVENT
}

export class SourceLoadedEvent implements FluxStandardAction {
    type = MutationTypes.SOURCE_LOADED_EVENT
}

export class SourceCreatedEvent implements FluxStandardAction {
    type = MutationTypes.SOURCE_CREATED_EVENT
    constructor(public location: string){}
}

export class SourceUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.SOURCE_UPDATED_EVENT
}

export class SourceDeletedEvent implements FluxStandardAction {
    type = MutationTypes.SOURCE_DELETED_EVENT
    constructor(public guid: string){}
}