export interface IGroupDTO {
  id?: number;
  guid?: string;
  name: string;
  description: string|null;
  alias: string|null;
  row_order: number;
  parent_id: number|null;
}

export class GroupDTO {
  id?: number;
  guid?: string;
  name: string;
  description: string|null;
  alias: string|null;
  row_order: number;
  parent_id: number|null;

  /**
   * Заполнение данными
   *
   * @param data
   */
  constructor (data: IGroupDTO) {
    this.id = data.id
    this.guid = data.guid
    this.name = data.name
    this.description = data.description
    this.alias = data.alias
    this.parent_id = data.parent_id
    this.row_order = data.row_order
  }

  /**
   * Создать пустую модель
   */
  static create (parentId: number|null = null) {
    return new GroupDTO({
      name: '',
      description: '',
      alias: null,
      parent_id: parentId,
      row_order: 0
    })
  }
}
