<template>
  <div :style="css || 'text-align: center'" v-if="value" :class="{'hover_effect': clickTable}">
    {{ value }}
  </div>
</template>

<script>
import clickTableMixin from './click_table_mixin'
export default {
  mixins: [clickTableMixin],
  props: {
    value: String,
    css: String
  },
  name: 'time_field'
}
</script>

<style scoped>
  .hover_effect:hover {
      cursor: pointer;
      text-decoration: underline
    }
</style>
