import { ActionTree } from 'vuex'
import { ThemeState } from '@/services/CssEditor/infrastructure/store/modules/Theme/state'
import { ActionTypes } from './action-types'
import { MutationTypes, ThemeUpdatedEvent, ThemeCreatedEvent, ThemeLoadingEvent, ThemeDeletedEvent, ThemeLoadedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { ThemeAPI } from '@/services/CssEditor/infrastructure/api/ThemeAPI'

export const actions: ActionTree<ThemeState, any> = {
  [ActionTypes.GET_THEME]: async ({ commit }, data) => {
    commit(new ThemeLoadingEvent())

    return APIClient.shared.request(new ThemeAPI.GetTheme(data.payload))
      .then((response) => {
        commit(new ThemeLoadedEvent())

        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },

  [ActionTypes.GET_THEME_BY_GUID]: async ({ commit }, data) => {
    commit(new ThemeLoadingEvent())

    return APIClient.shared.request(new ThemeAPI.GetThemeByGuid(data.guid))
      .then((response) => {
        commit(new ThemeLoadedEvent())

        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },

  [ActionTypes.CREATE_THEME]: async ({ commit }, data) => {
    commit(new ThemeLoadingEvent())

    return APIClient.shared.request(new ThemeAPI.CreateTheme(data.payload))
      .then((location: string) => {
        commit(new ThemeCreatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },

  [ActionTypes.UPDATE_THEME]: async ({ commit }, data) => {
    commit(new ThemeLoadingEvent())

    return APIClient.shared.request(new ThemeAPI.UpdateTheme(data.payload))
      .then((location: string) => {
        commit(new ThemeUpdatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },

  [ActionTypes.DELETE_THEME]: async ({ commit }, data) => {
    commit(new ThemeLoadingEvent())

    return APIClient.shared.request(new ThemeAPI.DeleteTheme(data.guid))
      .then(() => {
        commit(new ThemeDeletedEvent(data.guid))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  }
}
