import { FluxStandardAction } from '@/core/domain/type/types'

export enum ActionTypes {
  GET_TELEGRAM_CHATS = 'get_telegram_chats',
}

export class GetTelegramChat implements FluxStandardAction {
  type = 'TelegramChat/' + ActionTypes.GET_TELEGRAM_CHATS
  payload = null
  constructor (payload: string[][]) {
    this.payload = payload
  }
}
