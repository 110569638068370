import { FluxStandardAction } from '@/core/domain/type/types'
import NotificationDTO from '@/services/NotificationEditor/domain/model/NotificationDTO'

export enum ActionTypes {
  GET_NOTIFICATION = 'get_notification',
  GET_NOTIFICATION_BY_GUID = 'get_notification_by_id',
  CREATE_NOTIFICATION = 'create_notification',
  UPDATE_NOTIFICATION = 'update_notification',
  DELETE_NOTIFICATION = 'delete_notification'
}

export class GetNotification implements FluxStandardAction {
  type = 'Notification/' + ActionTypes.GET_NOTIFICATION
  constructor (public payload: object) {}
}

export class GetNotificationById implements FluxStandardAction {
  type = 'Notification/' + ActionTypes.GET_NOTIFICATION_BY_GUID
  constructor (public id: number) {}
}

export class CreateNotification implements FluxStandardAction {
  type = 'Notification/' + ActionTypes.CREATE_NOTIFICATION
  constructor (public payload: NotificationDTO) {}
}

export class UpdateNotification implements FluxStandardAction {
  type = 'Notification/' + ActionTypes.UPDATE_NOTIFICATION
  constructor (public payload: NotificationDTO) {}
}

export class DeleteNotification implements FluxStandardAction {
  type = 'Notification/' + ActionTypes.DELETE_NOTIFICATION
  constructor (public id: number) {}
}
