import { ActionTree } from 'vuex'
import { TaskRegistryEntitiesState, initialState } from './state'
import { ActionTypes } from './action-types'
import {
  MutationTypes,
  TaskRegistryEntitiesLoadingEvent,
  TaskRegistryEntitiesLoadedEvent,
  TaskRegistryEntitiesUpdatedEvent
} from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { TaskRegistryEntityAPI } from '@/services/TaskEditor/infrastructure/api/TaskRegistryEntityAPI'

export const actions: ActionTree<TaskRegistryEntitiesState, any> = {
  [ActionTypes.GET_TASK_REGISTRY_ENTITIES]: async ({ commit, dispatch }, data) => {
    commit(new TaskRegistryEntitiesLoadingEvent())

    return APIClient.shared.request(new TaskRegistryEntityAPI.GetTaskRegistryEntities())
      .then((response) => {
        commit(new TaskRegistryEntitiesLoadedEvent())

        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.GET_TASK_REGISTRY_ENTITY_BY_PROPERTY]: async ({ commit, dispatch }, data) => {
    commit(new TaskRegistryEntitiesLoadingEvent())

    return APIClient.shared.request(new TaskRegistryEntityAPI.GetTaskRegistryEntityByProperty(data.property))
      .then((response) => {
        commit(new TaskRegistryEntitiesLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.UPDATE_TASK_REGISTRY_ENTITIES]: async ({ commit, dispatch }, data) => {
    commit(new TaskRegistryEntitiesLoadingEvent())

    return APIClient.shared.request(new TaskRegistryEntityAPI.UpdateTaskRegistryEntities(data.payload))
      .then(() => {
        commit(new TaskRegistryEntitiesUpdatedEvent())
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  }
}
