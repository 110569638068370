import { MutationTree } from 'vuex'
import { TransformerState, initialState } from './state'
import { MutationTypes, TransformerCreatedEvent, TransformerUpdatedEvent, TransformerDeletedEvent } from './mutation-types'

export const mutations: MutationTree<TransformerState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false
    state.error = error
  },
  [MutationTypes.TRANSFORMER_LOADING_EVENT]: (state) => {
    state.isLoading = true
  },
  [MutationTypes.TRANSFORMER_LOADED_EVENT]: (state) => {
    state.isLoading = false
  },
  [MutationTypes.TRANSFORMER_CREATED_EVENT]: (state, action: TransformerCreatedEvent) => {
    state.isLoading = false
    state.location = action.location
  },
  [MutationTypes.TRANSFORMER_UPDATED_EVENT]: (state, action: TransformerUpdatedEvent) => {
    state.isLoading = false
  },
  [MutationTypes.TRANSFORMER_DELETED_EVENT]: (state, action: TransformerDeletedEvent) => {
    state.isLoading = false
    state.deletedTransformerGuid = action.guid
  }
}
