import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
  ERROR = 'error',
  APPROVAL_STAGE_LOADING_EVENT = 'logic_loading_event',
  APPROVAL_STAGE_LOADED_EVENT = 'logic_loaded_event',
  APPROVAL_STAGE_CREATED_EVENT = 'logic_created_event',
  APPROVAL_STAGE_UPDATED_EVENT = 'logic_updated_event',
  APPROVAL_STAGE_DELETED_EVENT = 'logic_deleted_event',
}

export class ApprovalStageLoadingEvent implements FluxStandardAction {
  type = MutationTypes.APPROVAL_STAGE_LOADING_EVENT
}

export class ApprovalStageLoadedEvent implements FluxStandardAction {
  type = MutationTypes.APPROVAL_STAGE_LOADED_EVENT
}

export class ApprovalStageCreatedEvent implements FluxStandardAction {
  type = MutationTypes.APPROVAL_STAGE_CREATED_EVENT
  constructor (public location: string) {}
}

export class ApprovalStageUpdatedEvent implements FluxStandardAction {
  type = MutationTypes.APPROVAL_STAGE_UPDATED_EVENT
  constructor (public location: string) {}
}

export class ApprovalStageDeletedEvent implements FluxStandardAction {
  type = MutationTypes.APPROVAL_STAGE_DELETED_EVENT
  constructor (public guid: string) {}
}
