
import { VueContext } from 'vue-context'
import Treeselect from '@bingosoftnn/vue-treeselect'
import AttachTemplateToProjectCommand from '@/services/DigitalTwinEditor/application/command/AttachTemplateToProjectCommand'
import DetachTemplateFromProjectCommand from '@/services/DigitalTwinEditor/application/command/DetachTemplateFromProjectCommand'
import TemplatesQuery from '@/services/DigitalTwinEditor/application/query/TemplatesQuery'
import TemplatesCountQuery from '@/services/DigitalTwinEditor/application/query/TemplatesCountQuery'
import ProjectBuildsQuery from '@/services/DigitalTwinEditor/application/query/ProjectBuildsQuery'
import ProjectBuildsCountQuery from '@/services/DigitalTwinEditor/application/query/ProjectBuildsCountQuery'

export default {
  name: 'ProjectForm',
  components: {
    VueContext,
    Treeselect
  },
  props: ['value'],
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  computed: {
    dto: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  data () {
    return {
      element_type: "layer",
      formRules: {
        name: {
          required: true,
          message: this.$locale.digitaltwin_editor.errors.name_required,
          trigger: 'input'
        }
      },
      showTemplates: false,
      templates: [],
      loading: false,
      selectedTemplate: null,
      builds: [],
      projectBuildsPageSize: 0,
      projectBuildsCurrentPage: 0,
      projectBuildsPageLimit: 100,
      projectBuildsCount: 0
    }
  },
  methods: {
    addTemplate() {
      this.showTemplates = true;
      this.loadTemplates();
    },
    closeTemplates() {      
      this.clearTemplates();
    },
    clearTemplates() {
      this.showTemplates = false;
      this.templates = [];
    },
    async loadTemplates() {
      await this.getQueryBus().execute(
        new TemplatesQuery({})
      ).then(data => {
        this.templates = data;
      })
    },
    removeTemplate(scope) {
      this.getCommandBus().execute(
        new DetachTemplateFromProjectCommand(
          this.dto.guid,
          scope.row.id
        )
      ).then((response) => {
        for (let i = 0; i < this.dto.templates.length; i += 1) {
          if (scope.row.id == this.dto.templates[i].id) {
            this.dto.templates.splice(i, 1);
            break;
          }
        }
        this.selectedTemplate = null;
      });
    },
    saveTemplate() {
      this.clearTemplates();
      let exists = false;
      for (let i = 0; i < this.dto.templates.length; i += 1) {
        if (this.selectedTemplate.id == this.dto.templates[i].id) {
          exists = true;
          break;
        }
      }
      if (!exists) {
        this.getCommandBus().execute(
          new AttachTemplateToProjectCommand(
            this.dto.guid,
            this.selectedTemplate.id
          )
        ).then((response) => {
          this.dto.templates.push(this.selectedTemplate);
          this.selectedTemplate = null;
        });
      }
    },
    selectTemplate(rec) {
      this.selectedTemplate = rec;
    },
    loadProjectBuilds (callback) {
      if (this.projectBuildsCount === 0) {
        this.countAndLoadProjectBuilds(callback)
      } else {
        this.loadPageProjectBuilds(callback)
      }
    },
    async countAndLoadProjectBuilds (callback) {
      await this.getQueryBus().execute(
        new ProjectBuildsCountQuery(this.prepareProjectBuildsUrlQueryParams(true))
      ).then(data => {
        console.log(data);
        this.projectBuildsCount = data[0].count
        this.loadPageProjectBuilds(callback)
      });
    },
    async loadPageProjectBuilds (callback) {
      await this.getQueryBus().execute(
        new ProjectBuildsQuery(this.prepareProjectBuildsUrlQueryParams())
      ).then(data => {
        this.projectBuildsPageSize = this.projectBuildsPageLimit
        this.builds = data
        if (typeof callback === 'function') {
          callback()
        }
      })
    },
    handleProjectBuildsPageChange (val) {
      val--;
      this.projectBuildsCurrentPage = (val * this.projectBuildsPageLimit)
      this.loadProjectBuilds()
    },
    prepareProjectBuildsUrlQueryParams (forCount = false) {
      let params = {}
      params['project_guid'] = this.dto.guid;
      if (!forCount) {
        params['limit'] = this.projectBuildsPageLimit
        params['offset'] = this.projectBuildsCurrentPage
      }
      return params
    },
    editTemplate(scope) {
      
    }
  },
  mounted () {
    if (typeof this.dto.id !== "undefined") {
      this.loadProjectBuilds();
    }
  }
}

