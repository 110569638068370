import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import OpenWorldCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/OpenWorldCommandRepositoryInterface'
import StopTilingCommand from '@/services/DigitalTwinEditor/application/command/StopTilingCommand'
import { OpenWorldDTO } from '@/services/DigitalTwinEditor/domain/model/OpenWorld/OpenWorld'

export default class StopTilingHandler implements CommandHandlerInterface
{
    private repo: OpenWorldCommandRepositoryInterface;

    constructor(repo: OpenWorldCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: StopTilingCommand): Promise<void>
    {
        let item: OpenWorldDTO = {
            guid: command.getGuid(),
            command: command.getCommand()
        };
        return this.repo.stopTiling(item);
    }
}