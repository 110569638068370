import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    ROUTE_LOADING_EVENT = 'route_loading_event',
    ROUTE_LOADED_EVENT = 'route_loaded_event',
    ROUTE_CREATED_EVENT = 'route_created_event',
    ROUTE_UPDATED_EVENT = 'route_updated_event',
    ROUTE_DELETED_EVENT = 'route_deleted_event' 
}

export class RouteLoadingEvent implements FluxStandardAction {
    type = MutationTypes.ROUTE_LOADING_EVENT
}

export class RouteLoadedEvent implements FluxStandardAction {
    type = MutationTypes.ROUTE_LOADED_EVENT
}

export class RouteCreatedEvent implements FluxStandardAction {
    type = MutationTypes.ROUTE_CREATED_EVENT
    constructor(public location: string){}
}

export class RouteUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.ROUTE_UPDATED_EVENT
}

export class RouteDeletedEvent implements FluxStandardAction {
    type = MutationTypes.ROUTE_DELETED_EVENT
    constructor(public guid: string){}
}