<template>
  <el-container style="height: 100%;">
    <el-header class="panel-header" height="auto">
      <h2>{{ addExistingTitle }}</h2>
      <div style="margin-right: 5%; display: inline-block">
        <el-input size="small" type="text" v-model='searchModel' placeholder="Поиск..."/>
      </div>
    </el-header>
    <RegistryTable
      ref="registry"
      :registryId="registryId"
      :custom-columns="columns.length ? columns : grid.columns"
      :external-filters="externalFilters"
      :is-custom-columns-sort="true"
      :is-custom-columns-width="true"
      :check-box-selection="true"
      :is-show-toolbar="false"
      :closeToolPanel="true"
    ></RegistryTable>

    <el-row style="padding-right: 50px; padding-bottom: 5px">
      <el-col style="text-align: right; margin-top: 7px">
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-success"
          plain
          @click="addLinks()"
        >{{ $locale.registry.registry_add_existing.button.add_selected }}</el-button>
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-close"
          plain
          @click="modal.close()"
        >{{ $locale.main.button.close }}</el-button>
      </el-col>
    </el-row>
  </el-container>
</template>

<script>
import RegistryTable from '@/services/RegistryTable/index.vue'
import FilterBuilder, { EComponentTypes } from '@/components/InterfaceEditor/components/utils'

export default {
  name: 'RegistryAddExisting',
  components: {
    RegistryTable
  },
  props: {
    registryId: Number, // ID целевого реестра
    outerXref: Object, // Вн. ссылка
    xrefId: String, // Мн. ссылка
    grid: Object, // Грид целевого реестра
    modal: Object, // Модальное окно

    // столбцы от редактора столбцов
    columns: {
      type: Array,
      description: 'columns',
      default: () => [],
      editor: 'TableColumns',
      options: {
        sourceType: 'registry'
      }
    }
  },
  data () {
    return {
      selectedRows: [],
      searchModel: null
    }
  },
  computed: {
    externalFilters () {
      const builder = new FilterBuilder(
        this.grid.tableAddExisting.filters,
        this.grid.getModel() || this.grid._getModel(),
        this.$store,
        EComponentTypes.registry
      )

      const filters = builder.buildAsApiQl()

      let attrs = this.grid.tableAddExisting.searchByAttr
      if (this.searchModel && this.grid.tableAddExisting.searchByAttr) {
        let obj = attrs.split(',').reduce((acc, item) => {
          acc.or.push({ like: { [`${item.trim()}`]: `%${this.searchModel}%` } })
          return acc
        }, { or: [] })
        filters.push(obj)
      }

      return filters
    },
    addExistingTitle () {
      return this.isMultiXref ? this.$locale.registry.registry_add_existing.title_multi : this.$locale.registry.registry_add_existing.title
    },
    xrefIds () {
      let xrefIds = this.getModel()[this.xrefId]

      if (!xrefIds) {
        return []
      }

      if (typeof xrefIds === 'number' || Number(xrefIds) == xrefIds) {
        return [Number(xrefIds)]
      }

      if (typeof xrefIds === 'string') {
        try {
          xrefIds = JSON.parse(xrefIds)
        } catch (error) {
          console.error(error)
        }
      }

      if (Array.isArray(xrefIds)) {
        return xrefIds.map(xrefId => Number(xrefId.id || xrefId))
      }

      return []
    }
  },
  methods: {
    async addLinks () {
      if (this.outerXref) {
        await this.addOuterLinks()
        if (this.grid.$refs.tableBody) {
          this.grid.$refs.tableBody.$refs.grid.load()
        } else {
          // Обновить грид
          this.grid.loadData()
        }
      } else {
        await this.addMultiLinks()
      }

      // Закрыть модальное окно
      this.modal.close()
    },
    // Добавить связи записей целевого реестра с записью для привязки вн. ссылки
    async addOuterLinks () {
      // ID записи для привязки
      const newRecordId = this.outerXref.value
      // ID атрибута для привязки (пр./мн. ссылка)
      const xrefFieldAttrId = this.outerXref.id
      // Атрибут привязки является множественным
      const isMultiXref = this.outerXref.isMulti
      // ID целевого реестра
      const targetRegistryId = this.registryId
      // ID записей в мн. ссылку которых надо добавить ID записи для привязки
      const targetRecords = this.$refs.registry.selectedRows

      if (!newRecordId || !targetRegistryId || !targetRecords || !targetRecords.length || !xrefFieldAttrId) {
        return
      }

      let addLinksRequestsPromises = []
      targetRecords.forEach(targetRecord => {
        // Подготовить тело для запроса
        const data = this.prepareData([newRecordId], targetRecord, xrefFieldAttrId, isMultiXref)

        // Заполнить мн./пр. ссылку
        addLinksRequestsPromises.push(this.$http.put(`${this.$config.api}/registryservice/registry/${targetRegistryId}/records/${targetRecord.id}`, data))
      })

      await Promise.all(addLinksRequestsPromises)
    },
    /**
     * Подготовить тело для запроса
     * - Конвертация значений мн. ссылки от сервера вида {1,2,3} в [1,2,3]
     * - Для мн. ссылки соединение прошлых и новых значений
     *
     * @param {object} newRecordIds - ID новых записей
     * @param {object} record - Запись в которой содержиться xrefFieldAttrId
     * @param {number} xrefFieldAttrId - ID атрибута пр./мн. ссылки для вн. ссылки
     * @param {boolean} isMultiXref - Атрибут к которому привязана вн. ссылка является множественным
     * @param {boolean} isUnique - Убрать дубли ID (newRecordId может совпадать с record.attr_{xrefFieldAttrId}_)
     * @returns {object} - {
     *  id: record.id, // Необходимо дублировать в теле запроса (19.04.2021 в id записи в конце URL обязателен, но не используется)
     *  [`attr_${xrefFieldAttrId}_`]: {number|object} - Простая ссылка = Number, Мн. ссылка = Array
     * }
     */
    prepareData (newRecordIds, record, xrefFieldAttrId, isMultiXref, isUnique = true) {
      const data = {
        id: record.id
      }
      data[`attr_${xrefFieldAttrId}_`] = this.prepareXrefIds(newRecordIds, record, xrefFieldAttrId, isMultiXref, isUnique)
      return data
    },
    /**
     * - Простая ссылка вернет newRecordId
     * - Множественная ссылка соединит массив из прошлых значения record.attr_{xrefFieldAttrId}_ и newRecordId
     *
     * @param {object} newRecordIds - ID новых записей
     * @param {object} record - Запись в которой содержиться xrefFieldAttrId
     * @param {number} xrefFieldAttrId - ID атрибута пр./мн. ссылки для вн. ссылки
     * @param {boolean} isMultiXref - Атрибут к которому привязана вн. ссылка является множественным
     * @param {boolean} isUnique - Убрать дубли ID (newRecordId может совпадать с record.attr_{xrefFieldAttrId}_)
     * @returns {number|object} - Простая ссылка = Number, Мн. ссылка = Array
     */
    prepareXrefIds (newRecordIds, record, xrefFieldAttrId, isMultiXref, isUnique = true) {
      // Простая ссылка, перезаписать ID
      let xrefIds = newRecordIds
      if (isMultiXref) {
        // Для мн. ссылки оставить прошлые ID, добавив новый ID
        const olsXrefIds = this.parseXrefIds(record[`attr_${xrefFieldAttrId}_id`])
        xrefIds = [...olsXrefIds, ...xrefIds]
      }

      if (isUnique && isMultiXref) {
        // Убрать дубли
        xrefIds = [...new Set(xrefIds)]
      }

      return isMultiXref ? xrefIds : xrefIds[0]
    },
    /**
     * Конвертировать значения от сервера мн. ссылки
     *
     * @param {string} xrefIdsString - xrefIdsString вида {1,2,3}
     * @returns {object} - Массив значений вида [1,2,3]
     */
    parseXrefIds (xrefIdsString) {
      let xrefIds = []
      if (xrefIdsString) {
        xrefIds = xrefIdsString.replace('{', '').replace('}', '').split(',')
        /**
         * Убрать falsy значения так как:
         * - ''.split(',') == Array [ "" ]
         * - ''.split(',').map(Number) == Array [ 0 ]
         */
        xrefIds = xrefIds.filter(recordId => recordId)
        xrefIds = xrefIds.map(Number)
      }

      return xrefIds
    },
    addMultiLinks () {
      const xrefAttrKey = this.xrefId
      // Записи мн. ссылки в карточке
      let modelXref = this.grid.getModel()[xrefAttrKey] || []
      // ID записей которые надо добавить в мн. ссылку
      const targetRecords = this.$refs.registry.selectedRows

      if (typeof modelXref === 'string') {
        try {
          modelXref = JSON.parse(modelXref)
        } catch (error) {
          console.error(error)
        }
      }

      if (Array.isArray(modelXref)) {
        modelXref = modelXref.map(xrefId => Number(xrefId.id || xrefId))
      }

      // Объединить ID записей
      let newXrefIds = [...modelXref, ...targetRecords.map(targetRecord => targetRecord.id)]
      // Убрать дубли
      newXrefIds = [...new Set(newXrefIds)]

      /* Изменить model (не перетирая массив, чтобы не сбилась ссылка) */
      // Очистить массив
      this.$set(this.grid.getModel(), xrefAttrKey, newXrefIds)
      // modelXref.splice(0, modelXref.length)
      // modelXref.push(...newXrefIds)
    }
  }
}
</script>

<style scoped>
  .el-header.panel-header {
    float: right;
    padding: 10px 20px 10px 20px;
    border-bottom: 1px solid #efefef;
    font-size: 18px;
    font-weight: 700;
    color: #303030;
  }

  .el-header.panel-header h2 {
    float: left;
    margin: 0;
  }
  .el-header.panel-header div {
    float: right;
    margin: 0;
  }
</style>
