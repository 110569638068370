import { MutationTree } from 'vuex'
import { RelatedObjectState, initialState } from './state'
import { MutationTypes, RelatedObjectCreatedEvent, RelatedObjectUpdatedEvent, RelatedObjectDeletedEvent } from './mutation-types'

export const mutations: MutationTree<RelatedObjectState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false
    state.error = error
  },
  [MutationTypes.RELATED_OBJECT_LOADING_EVENT]: (state) => {
    state.isLoading = true
  },
  [MutationTypes.RELATED_OBJECT_LOADED_EVENT]: (state) => {
    state.isLoading = false
  },
  [MutationTypes.RELATED_OBJECT_CREATED_EVENT]: (state, action: RelatedObjectCreatedEvent) => {
    state.isLoading = false
    state.location = action.location
  },
  [MutationTypes.RELATED_OBJECT_UPDATED_EVENT]: (state, action: RelatedObjectUpdatedEvent) => {
    state.isLoading = false
  },
  [MutationTypes.RELATED_OBJECT_DELETED_EVENT]: (state, action: RelatedObjectDeletedEvent) => {
    state.isLoading = false
    state.deletedRelatedObjectGuid = action.guid
  }
}
