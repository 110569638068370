import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { ProjectDTO, ProjectBuildDTO } from '@/services/DigitalTwinEditor/domain/model/Project/Project'
import { TemplateDTO } from '@/services/DigitalTwinEditor/domain/model/Project/Template'

export namespace ProjectAPI {

    export class UpdateProject implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: ProjectDTO) {
            this.path = `/digitaltwineditor/projects/${params.guid}`;
        }
    }

    export class GetProjectByGuid implements APIRequest<ProjectDTO> {
        response: ProjectDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/digitaltwineditor/projects/${guid}`;
        }
    }

    export class BuildProject implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: ProjectDTO) {
            this.path = `/digitaltwineditor/projects/${params.guid}/build`;
        }
    }

    export class DownloadLastBuild implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        blob = true;
        fileName: string;
        constructor(public dto: ProjectBuildDTO) {
            this.fileName = dto.name;
            this.path = `/digitaltwineditor/projects/${dto.guid}/build?year=${dto.year}&month=${dto.month}&extension=${dto.extension}`;
        }
    }

    export class DownloadPrj implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        blob = true;
        fileName: string;
        constructor(public dto: ProjectDTO) {
            this.fileName = dto.name;
            this.path = `/digitaltwineditor/projects/${dto.guid}/prj`;
        }
    }

    export class AttachTemplate implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: TemplateDTO) {
            this.path = `/digitaltwineditor/projects/${params.project_guid}/templates`;
        }
    }

    export class DetachTemplate implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.DELETE;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: TemplateDTO) {
            this.path = `/digitaltwineditor/projects/${params.project_guid}/templates`;
        }
    }

    export class CountProjectBuilds implements APIRequest<ProjectBuildDTO> {
        response: ProjectBuildDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor (payload: string[][]) {
          this.path = `/digitaltwineditor/projects/${payload['project_guid']}/builds?*[func]=count`
        }
    }
  
    export class GetProjectBuilds implements APIRequest<ProjectBuildDTO> {
        response: ProjectBuildDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor (payload: string[][]) {
          this.path = `/digitaltwineditor/projects/${payload['project_guid']}/builds?` + (new URLSearchParams(payload).toString())
        }
    }
}