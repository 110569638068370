import ModelTreeElementCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/ModelTreeElementCommandRepositoryInterface'
import { ModelTreeElementDTO } from '@/services/DigitalTwinEditor/domain/model/Model/ModelTreeElement'
import store from '@/store'
import { AddModelTreeModelElement, AddModelTreeGroupElement, DeleteModelTreeElement } from '@/services/DigitalTwinEditor/infrastructure/store/modules/ModelTreeElement/action-types'

export default class ModelTreeElementCommandRepository implements ModelTreeElementCommandRepositoryInterface
{
    insertModel(item: ModelTreeElementDTO): Promise<void>
    {
        return store.dispatch(new AddModelTreeModelElement(item));
    }

    insertGroup(item: ModelTreeElementDTO): Promise<void>
    {
        return store.dispatch(new AddModelTreeGroupElement(item));
    }

    delete(guid: string): Promise<void>
    {
        return store.dispatch(new DeleteModelTreeElement(guid));
    }
}
