<template>
  <div>
    <el-button type="text" @click="dialogSettingsVisible = true">{{ $locale.main.button.edit }}</el-button>
    <el-dialog
        :title="$locale.interface_editor.component_editor.editor_legend.edit_legend"
        :modal="false"
        :width="'25%'"
        :visible.sync="dialogSettingsVisible">
      <el-form label-position="top" :model="model">
        <el-form-item :label="$locale.interface_editor.component_editor.editor_legend.pos_legend">
          <el-select v-model="model.position" size="small">
            <el-option label="Слева" value="left"></el-option>
            <el-option label="Справа" value="right"></el-option>
            <el-option label="Сверху" value="top"></el-option>
            <el-option label="Снизу" value="bottom"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$locale.interface_editor.component_editor.editor_legend.font_size">
          <el-input type="text" size="small" style="width: 185px; margin-bottom: 10px" v-model="model.fontSize" placeholder="12px"></el-input>
        </el-form-item>
        <el-form-item :label="$locale.interface_editor.component_editor.editor_legend.color_legend" >
          <div class="block">
            <el-color-picker class="color_picker" size="mini" v-model="model.labels.colors"></el-color-picker>
            <el-input style="width: 147px" type="text" size="small" v-model="model.labels.colors" placeholder="#19D29A"></el-input>
          </div>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="model.show">{{$locale.interface_editor.component_editor.editor_legend.show_legend}}</el-checkbox>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'editor-legend-chart',
  props: {
    value: {
      type: Object,
      default () {
        return {
          position: 'right',
          show: true,
          fontSize: '14px',
          labels: {
            colors: '#000'
          }
        }
      }
    }
  },
  data () {
    return {
      dialogSettingsVisible: false,
      model: {
        position: 'right',
        show: true,
        fontSize: '14px',
        labels: {
          colors: '#000'
        }
      }
    }
  },
  watch: {
    dialogSettingsVisible: {
      handler (value) {
        if (!value) {
          this.$emit('change', this.model)
        }
      }
    }
  },
  mounted () {
    this.model = { ...this.model, ...this.value }
    // this.model = Object.assign({}, this.value, this.model)
  }
}
</script>

<style scoped>
.block {
  display: inline-flex;
  align-items: center;
  height: 40px;
}
.color_picker {
  margin-right: 10px;
}
</style>
