import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { LayerDTO } from '@/services/MapEditor/domain/model/Layer/Layer'

export enum ActionTypes {
    GET_LAYER_GEOJSON = 'get_layer_geojson',
}

export class GetLayerGeoJson implements FluxStandardAction {
    type = 'GeoJson/' + ActionTypes.GET_LAYER_GEOJSON
    constructor(public layerId: number, public filter: string[][] | object | null){}
}