import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { TransformerDTO, TransformerTypeDTO, TransformerTypePropertyDTO } from '@/services/EtlEditor/domain/model/Transformer'

export namespace TransformerAPI {
  export class GetTransformer implements APIRequest<TransformerDTO> {
    response: TransformerDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (payload: string[][]) {
      this.path = `/v2/etleditor/transformers?${new URLSearchParams(payload).toString()}`
    }
  }

  export class GetTransformerByGuid implements APIRequest<TransformerDTO> {
    response: TransformerDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/etleditor/transformers/${guid}`
    }
  }

  export class GetTransformerTypes implements APIRequest<TransformerTypeDTO> {
    response: TransformerTypeDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor () {
      this.path = `/v2/etleditor/transformer_types`
    }
  }

  export class GetTransformerTypeProperties implements APIRequest<TransformerTypePropertyDTO> {
    response: TransformerTypePropertyDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (transformerTypeId: string) {
      this.path = `/v2/etleditor/transformer_types/${transformerTypeId}/properties`
    }
  }

  export class CreateTransformer implements APIRequest<string> {
    response: string;
    path = '/v2/etleditor/transformers';
    method = HTTPMethod.POST;
    parse = (data: AxiosResponse) => data.headers.location
    constructor (public params: TransformerDTO) {}
  }

  export class UpdateTransformer implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.PUT;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: TransformerDTO) {
      this.path = `/v2/etleditor/transformers/${params.guid}`
    }
  }

  export class DeleteTransformer implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.DELETE;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/etleditor/transformers/${guid}`
    }
  }
}
