import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    LISTENER_LOADING_EVENT = 'listener_loading_event',
    LISTENER_LOADED_EVENT = 'listener_loaded_event',
    LISTENER_CREATED_EVENT = 'listener_created_event',
    LISTENER_UPDATED_EVENT = 'listener_updated_event',
    LISTENER_DELETED_EVENT = 'listener_deleted_event'
}

export class ListenerLoadingEvent implements FluxStandardAction {
  type = MutationTypes.LISTENER_LOADING_EVENT
}

export class ListenerLoadedEvent implements FluxStandardAction {
  type = MutationTypes.LISTENER_LOADED_EVENT
}

export class ListenerCreatedEvent implements FluxStandardAction {
  type = MutationTypes.LISTENER_CREATED_EVENT
  constructor (public location: string) {}
}

export class ListenerUpdatedEvent implements FluxStandardAction {
  type = MutationTypes.LISTENER_UPDATED_EVENT
  constructor (public location: string) {}
}

export class ListenerDeletedEvent implements FluxStandardAction {
  type = MutationTypes.LISTENER_DELETED_EVENT
  constructor (public guid: string) {}
}
