import { ActionTree } from 'vuex'
import { PackageState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, PackageLoadingEvent, PackageLoadedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { PackageAPI } from '@/services/Databus/infrastructure/api/PackageAPI'

export const actions: ActionTree<PackageState, any> = {
    [ActionTypes.GET_PACKAGES]: async ({ commit, dispatch }, data) => {
        try {
            commit(new PackageLoadingEvent());
            let res = await APIClient.shared.request(new PackageAPI.GetPackages(data.payload));
            commit(new PackageLoadedEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.COUNT_PACKAGES]: async ({ commit, dispatch }, data) => {
        try {
            commit(new PackageLoadingEvent());
            let res = await APIClient.shared.request(new PackageAPI.CountPackages(data.payload));
            commit(new PackageLoadedEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.DOWNLOAD_PACKAGE]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new PackageAPI.DownloadPackage(data.topic, data.guid, data.fileName));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    }
}
