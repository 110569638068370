export default class EventFactory {
  private static queries = [];
  private static socket: WebSocket = null;
  private static reconnectCount = 10;
  private static normalClosureCode = 1000;

  public static async send (
    context: { $eventSocket: WebSocket },
    options: {
      eventType: string,
      entityId?: number | string,
      entityType?: string,
      entityName?: string,
      recordId?: number,
      objectId?: number,
      buttonName?: string,
      registryName?: string,
      authorizationType?: string
    }) {
    const message = {
      'browser': this.checkBrowser(),
      'event_type': options.eventType,
      'entity_id': options.entityId,
      'entity_type': options.entityType,
      'entity_name': options.entityName,
      'record_id': options.recordId,
      'object_id': options.objectId,
      'button_name': options.buttonName,
      'authorization_type': options.authorizationType
    }
    this.queries.push(message)

    if (!this.socket) {
      this.connect()
      return false
    }

    this.runQueue()
  }

  private static sendToSocket (message: object) {
    this.socket.send(JSON.stringify(message))
  }

  public static connect (): boolean {
    if (this.socket || this.socket?.readyState === 1) {
      return false
    }
    const protocol = (window.location.protocol === 'https:') ? 'wss' : 'ws'
    const token = localStorage.getItem('user-token')
    if (!token) {
      return false
    }

    this.socket = new WebSocket(`${protocol}://${config.api.substring(7)}/eventservice/socket`, token)
    let me = this
    this.socket.addEventListener('open', () => {
      me.runQueue()
    })
    setTimeout(() => {
      if ((!me.socket || me.socket?.readyState !== 1) && me.reconnectCount > 0) {
        if (me.socket) me.socket.close()
        me.socket = null
        me.connect()
        me.reconnectCount -= 1
      }
    }, 5000)
    return true
  }

  public static runQueue () {
    if (!this.socket || this.socket?.readyState !== 1) {
      return false
    }
    this.queries.forEach((query, index) => {
      this.sendToSocket(query)
      this.queries.splice(index, 1)
    })
  }

  private static checkBrowser () {
    if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
      return ('Opera')
    } else if (navigator.userAgent.indexOf('Edg') !== -1) {
      return ('Edge')
    } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
      return ('Chrome')
    } else if (navigator.userAgent.indexOf('Safari') !== -1) {
      return ('Safari')
    } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
      return ('Firefox')
    } else if ((navigator.userAgent.indexOf('MSIE') !== -1)) {
      return ('IE')
    } else {
      return ('Неизвестно')
    }
  }

  public static closeConnection (reason: string, code: number = this.normalClosureCode) {
    this.socket.close(code, reason)
    this.reconnectCount = 10
    this.socket = null
  }
}
