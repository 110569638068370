<template>
    <el-select size="small" v-model="data.value" placeholder="Select" @change="$emit('input', `eq,${data.value}`)">
        <el-option
                v-for="item in types"
                :key="item.type"
                :label="item.name"
                :value="item.type">
        </el-option>
    </el-select>
</template>

<script>
import mixin from './mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      data: {
        value: null
      },
      types: this.$locale.registry.filter.fields.boolean_field.types
    }
  },
  methods: {
    render () {
      if (this.value) {
        let properties = this.value.split(',')
        properties.shift()
        this.data.value = properties.join(',')
      }
    }
  }
}
</script>

<style scoped>

</style>
