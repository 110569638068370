import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { SnapshotDTO } from '@/services/SyncService/domain/model/Snapshot/Snapshot'

export namespace SnapshotAPI {

    export class CountSnapshots implements APIRequest<SnapshotDTO> {
      response: SnapshotDTO
      path: string
      method = HTTPMethod.GET
      parse = (data: AxiosResponse) => data.data
      constructor (payload: string[][]) {
        this.path = '/syncservice/snapshots?func=count'
      }
    }

    export class GetSnapshots implements APIRequest<SnapshotDTO> {
      response: SnapshotDTO
      path: string
      method = HTTPMethod.GET
      parse = (data: AxiosResponse) => data.data
      constructor (payload: string[][]) {
        this.path = '/syncservice/snapshots?' + (new URLSearchParams(payload).toString())
      }
    }

  export class FilterSnapshots implements APIRequest<SnapshotDTO> {
    response: SnapshotDTO
    path: string
    method = HTTPMethod.GET
    parse = (data: AxiosResponse) => data.data
    constructor (payload: string[][]) {
      this.path = '/syncservice/snapshots_filter?' + (new URLSearchParams(payload).toString())
    }
  }

  export class GetSnapshotsSchemaFilterValues implements APIRequest<SnapshotDTO> {
    response: SnapshotDTO
    path: string
    method = HTTPMethod.GET
    parse = (data: AxiosResponse) => data.data
    constructor () {
      this.path = '/syncservice/filters/schemas'
    }
  }

  export class GetSnapshotsTablesBySchema implements APIRequest<SnapshotDTO> {
    response: SnapshotDTO
    path: string
    method = HTTPMethod.GET
    parse = (data: AxiosResponse) => data.data
    constructor (payload: string[][]) {
      this.path = '/syncservice/filters/tables?' + (new URLSearchParams(payload).toString())
    }
  }
}
