import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    TEMPLATE_LOADING_EVENT = 'template_loading_event',
    TEMPLATE_LOADED_EVENT = 'template_loaded_event'
}

export class TemplateLoadingEvent implements FluxStandardAction {
    type = MutationTypes.TEMPLATE_LOADING_EVENT
}

export class TemplateLoadedEvent implements FluxStandardAction {
    type = MutationTypes.TEMPLATE_LOADED_EVENT
}
