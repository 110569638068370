import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { DagDTO } from '@/services/Databus/domain/model/Dag/Dag'

export namespace DagAPI {

    export class GetDags implements APIRequest<DagDTO> {
        response: DagDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/databusservice/dags'
        }
    }

    export class RunDag implements APIRequest<string> {
        response: string;
        path = '/databusservice/dags';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.headers.location
        constructor(public params: DagDTO) {}
    }
}