import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    LOGIC_GROUP_LOADING_EVENT = 'logic_group_loading_event',
    LOGIC_GROUP_LOADED_EVENT = 'logic_group_loaded_event',
    LOGIC_GROUP_CREATED_EVENT = 'logic_group_created_event',
    LOGIC_GROUP_UPDATED_EVENT = 'logic_group_updated_event',
    LOGIC_GROUP_DELETED_EVENT = 'logic_group_deleted_event'
}

export class LogicGroupLoadingEvent implements FluxStandardAction {
  type = MutationTypes.LOGIC_GROUP_LOADING_EVENT
}

export class LogicGroupLoadedEvent implements FluxStandardAction {
  type = MutationTypes.LOGIC_GROUP_LOADED_EVENT
}

export class LogicGroupCreatedEvent implements FluxStandardAction {
  type = MutationTypes.LOGIC_GROUP_CREATED_EVENT
  constructor (public location: string) {}
}

export class LogicGroupUpdatedEvent implements FluxStandardAction {
  type = MutationTypes.LOGIC_GROUP_UPDATED_EVENT
  constructor (public location: string) {}
}

export class LogicGroupDeletedEvent implements FluxStandardAction {
  type = MutationTypes.LOGIC_GROUP_DELETED_EVENT
  constructor (public guid: string) {}
}
