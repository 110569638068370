export default class PackageFileDownloadCommand
{
    private topic: string;
    private guid: string;
    private fileName: string;

    constructor(topic: string, guid: string, fileName: string)
    {
        this.topic = topic;
        this.guid = guid;
        this.fileName = fileName;
    }

    getTopic(): string
    {
        return this.topic;
    }

    getGuid(): string
    {
        return this.guid;
    }

    getFileName(): string
    {
        return this.fileName;
    }

    getClassName(): string
    {
        return 'PackageFileDownloadCommand';
    }
}
