
import { VueContext } from 'vue-context'
import { Loading } from 'element-ui'
import EditorTree from '@/core/infrastructure/components/EditorTree.vue'
import DownloadLastBuildCommand from '@/services/DigitalTwinEditor/application/command/DownloadLastBuildCommand'
import DownloadPrjCommand from '@/services/DigitalTwinEditor/application/command/DownloadPrjCommand'
import ProjectTreeElementDeleteCommand from '@/services/DigitalTwinEditor/application/command/ProjectTreeElementDeleteCommand'
import ProjectTreeElementGroupCreateCommand from '@/services/DigitalTwinEditor/application/command/ProjectTreeElementGroupCreateCommand'
import ProjectTreeElementLayerCreateCommand from '@/services/DigitalTwinEditor/application/command/ProjectTreeElementLayerCreateCommand'
import ProjectTreeElementModelCreateCommand from '@/services/DigitalTwinEditor/application/command/ProjectTreeElementModelCreateCommand'
import ProjectTreeElementOpenWorldCreateCommand from '@/services/DigitalTwinEditor/application/command/ProjectTreeElementOpenWorldCreateCommand'
import ProjectTreeElementProjectCreateCommand from '@/services/DigitalTwinEditor/application/command/ProjectTreeElementProjectCreateCommand'
import ProjectBuildCommand from '@/services/DigitalTwinEditor/application/command/ProjectBuildCommand'
import LayerByGuidQuery from '@/services/DigitalTwinEditor/application/query/LayerByGuidQuery'
import ModelByGuidQuery from '@/services/DigitalTwinEditor/application/query/ModelByGuidQuery'
import OpenWorldByGuidQuery from '@/services/DigitalTwinEditor/application/query/OpenWorldByGuidQuery'
import ProjectByGuidQuery from '@/services/DigitalTwinEditor/application/query/ProjectByGuidQuery'
import ProjectGroupByGuidQuery from '@/services/DigitalTwinEditor/application/query/ProjectGroupByGuidQuery'
import ProjectTreeElementByGuidQuery from '@/services/DigitalTwinEditor/application/query/ProjectTreeElementByGuidQuery'
import ProjectTreeElementsQuery from '@/services/DigitalTwinEditor/application/query/ProjectTreeElementsQuery'
import ModelTreeElementsQuery from '@/services/DigitalTwinEditor/application/query/ModelTreeElementsQuery'
import TreeElementType from '@/services/DigitalTwinEditor/domain/model/TreeElementType'
import ProjectTreeElement, { ProjectTreeElementDTO } from '@/services/DigitalTwinEditor/domain/model/Project/ProjectTreeElement'
import ModelTreeElement, { ModelTreeElementDTO } from '@/services/DigitalTwinEditor/domain/model/Model/ModelTreeElement'
import { LayerDTO } from '@/services/DigitalTwinEditor/domain/model/Layer/Layer'
import { ModelDTO } from '@/services/DigitalTwinEditor/domain/model/Model/Model'
import { OpenWorldDTO } from '@/services/DigitalTwinEditor/domain/model/OpenWorld/OpenWorld'
import { ProjectDTO } from '@/services/DigitalTwinEditor/domain/model/Project/Project'
import { ProjectGroupDTO } from '@/services/DigitalTwinEditor/domain/model/Project/ProjectGroup'
import GroupForm from './GroupForm.vue'
import LayerForm from './LayerForm.vue'
import OpenWorldForm from './OpenWorldForm.vue'
import ProjectForm from './ProjectForm.vue'

export default {
  name: 'MainTree',
  components: {
    EditorTree,
    VueContext,
    GroupForm,
    LayerForm,
    OpenWorldForm,
    ProjectForm
  },
  props: [],
  data () {
    return {
      treeNodeProps: {
        isLeaf: 'is_leaf',
        label: 'name',
        children: 'child'
      },
      elementBuilder: ProjectTreeElement,
      modelElementBuilder: ModelTreeElement,
      selectedProjectTreeElement: null,
      projectTreeElementDto: {},
      loadedProjects: {},
      allProjects: [],
      loadResolve: null,
      showCard: false,
      command: null,
      showModelSelectionCard: false,
      selectedProjectDtoFromContextMenu: null,
      showTemplates: false,
      templates: [],
      selectedTemplates: []
    }
  },  
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus', 'getTreeIcon', 'expandTreeNode'],
  provide() {
    return {
    }
  },
  watch: {   
    isProjectGroupLoading: (state) => state,
    projectGroupCreated: function (location) {
      this.getQueryBus().execute(
        new ProjectTreeElementByGuidQuery(
          location.replace('/project_tree_elements/', '')
        )
      ).then((elementDto) => {
        this.$refs.projectTree.addEditorElement(elementDto);
      });
    },
    projectTreeElementCreatedEvent: function (location) {
      this.getQueryBus().execute(
        new ProjectTreeElementByGuidQuery(
          location.replace('/project_tree_elements/', '')
        )
      ).then((elementDto) => {
        this.$refs.projectTree.addEditorElement(elementDto);
        this.showEditPanel(elementDto);
      });
    }
  },
  computed: {
    console: () => console,
    isProjectGroupLoading() {
      return this.$store.getters['DigitalTwinProjectGroup/isLoading'];     
    },
    projectGroupCreated() {
      return this.$store.getters['DigitalTwinProjectGroup/getLocation'];
    },
    projectTreeElementCreatedEvent() {
      return this.$store.getters['DigitalTwinProjectTreeElement/getElementLocation'];
    }
  },
  methods: {
    debug (message) {
    },    
    addProjectTreeElement(command) {
      let attachModel = false;
      let hasException = false;
      if (command == 'add_project') {
        if (this.selectedProjectTreeElement != null && this.selectedProjectTreeElement.element_type != "project_group") {
          hasException = true;          
        } else {
          this.projectTreeElementDto = new ProjectDTO({});
        }
      } else if (command == 'add_group') {
        this.projectTreeElementDto = new ProjectGroupDTO({});
      } else if (command == 'add_layer') {
        if (this.selectedProjectTreeElement == null || (this.selectedProjectTreeElement.element_type != "project" &&  this.selectedProjectTreeElement.element_type != "open_world") ) {
          hasException = true;
        } else {
          this.projectTreeElementDto = new LayerDTO({});
        }
      } else if (command == 'add_openworld') {
        if (this.selectedProjectTreeElement == null || this.selectedProjectTreeElement.element_type != "project") {
          hasException = true;          
        } else {
          this.projectTreeElementDto = new OpenWorldDTO({});
        }
      } else if (command == 'add_model') {
        attachModel = true;
        if (this.selectedProjectTreeElement == null || (this.selectedProjectTreeElement.element_type != "project" && this.selectedProjectTreeElement.element_type != "layer" && this.selectedProjectTreeElement.element_type != "open_world") ) {
          hasException = true;
        } else {
          this.showModelSelectionCard = true;
          this.command = command;
        }
      }
      if (!attachModel) {
        if (!hasException) {
          this.showCard = true;
          this.command = command;
        } else {
          this.$message({
            message: this.$locale.digitaltwin_editor.project_panel.invalid_parent_type,
            type: 'warning'
          });
        }
      }
    },  
    editProjectTreeElement() {
      if (this.selectedProjectTreeElement == null) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      } else {
        this.showEditPanel(this.selectedProjectTreeElement);
      } 
    },
    removeProjectTreeElement() {
      if (this.selectedProjectTreeElement == null) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      } else {
        this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
          confirmButtonText: this.$locale.main.button.delete,
          cancelButtonText: this.$locale.main.button.cancel,
          type: 'warning'
        }).then(async () => {
          this.getCommandBus().execute(
            new ProjectTreeElementDeleteCommand(
              this.selectedProjectTreeElement.guid
            )
          ).then((response) => {
            this.getEventBus().$emit('removeTreeElementByGuid', this.selectedProjectTreeElement.element_guid);
            this.$refs.projectTree.remove(this.selectedProjectTreeElement);               
            this.selectedProjectTreeElement = null;     
          });
        }).catch((error) => { console.log(error); })
      }        
    },
    async loadProjectTree(node, resolve) {
      this.loadResolve = resolve;
      this.getQueryBus().execute(
        new ProjectTreeElementsQuery(
          ( node == null || node.level === 0 ) ? null : node.data.id
        )
      ).then((elements) => {
        resolve(elements);
      });
    },    
    selectProjectTreeNode(elementDto) {
      console.log(elementDto, elementDto.parent);
      this.selectedProjectTreeElement = elementDto;
      this.showEditPanel(elementDto);      
    },
    showEditPanel(elementDto) {
      let query;
      if (elementDto.element_type == 'project_group') {
        query = new ProjectGroupByGuidQuery(
          elementDto.element_guid
        )
      } else if (elementDto.element_type == 'layer') {
        query = new LayerByGuidQuery(
          elementDto.element_guid
        )
      } else if (elementDto.element_type == 'project') {
        query = new ProjectByGuidQuery(
          elementDto.element_guid
        )
      } else if (elementDto.element_type == 'open_world') {
        query = new OpenWorldByGuidQuery(
          elementDto.element_guid
        )
      } else if (elementDto.element_type == 'model') {
        query = new ModelByGuidQuery(
          elementDto.element_guid
        )
      }
      this.getQueryBus().execute(
        query 
      ).then((resultDto) => {
        resultDto.tree_element_id = elementDto.id;
        resultDto.parent_id = elementDto.parent_id;
        resultDto.element_type = elementDto.element_type;
        this.getEventBus().$emit('openEditForm', resultDto, elementDto);
      });
    },
    allowDrop(draggingNode, dropNode, type) {
      if (type == "inner") return !dropNode.data.is_leaf;
      return true;
    },
    allowDrag() {
      return this.allowNodeDrag;
    },
    nodeDrop (dragNode, dropNode, type) {
    },
    saveTreeElement () {
      let form;
      form = this.$refs.formParent.$refs.formChild;
      if (typeof form !== "undefined") {
        form.validate((valid, invalidFields) => {
          if (valid) {
            let command;
            let parentId = this.selectedProjectTreeElement !== null ? this.selectedProjectTreeElement.id : null;
            if (this.command == 'add_group') {
              command = new ProjectTreeElementGroupCreateCommand(                
                this.projectTreeElementDto.name,
                this.projectTreeElementDto.alias,
                parentId
              )
            } else if (this.command == 'add_layer') {
              command = new ProjectTreeElementLayerCreateCommand(                
                this.projectTreeElementDto.name,
                this.projectTreeElementDto.description,
                this.projectTreeElementDto.alias,
                parentId
              )
            } else if (this.command == 'add_project') {
              command = new ProjectTreeElementProjectCreateCommand(                
                this.projectTreeElementDto.name,
                this.projectTreeElementDto.description,
                this.projectTreeElementDto.alias,
                parentId
              )
            } else if (this.command == 'add_openworld') {
              command = new ProjectTreeElementOpenWorldCreateCommand(                
                this.projectTreeElementDto.name,
                this.projectTreeElementDto.description,
                this.projectTreeElementDto.alias,
                this.projectTreeElementDto.heightmap,
                this.projectTreeElementDto.coordinate_system_id,
                this.projectTreeElementDto.start_point,
                this.projectTreeElementDto.is_auto_borders,
                this.projectTreeElementDto.borders_bbox,
                this.projectTreeElementDto.radius_of_buffer,
                parentId
              )
            }
            this.getCommandBus().execute(command).then((response) => {
              this.showCard = false;
              this.projectTreeElementDto = {};
            });
          }
        });
      }
    },
    async loadModelTree(node, resolve) {
      this.getQueryBus().execute(
        new ModelTreeElementsQuery(
          ( node == null || node.level === 0 ) ? null : node.data.id
        )
      ).then((elements) => {
        resolve(elements);
      });
    },
    selectModelTreeNode(elementDto) {
      this.selectedModelTreeElement = elementDto;  
    },
    selectModel() {
      if (this.selectedModelTreeElement.element_type == "model") {
        let command = new ProjectTreeElementModelCreateCommand(                
          this.selectedModelTreeElement.element_id,
          this.selectedProjectTreeElement.id
        );
        this.getCommandBus().execute(command).then((response) => {
          this.showModelSelectionCard = false;
          this.selectedModelTreeElement = null;
        });
      } else {
        this.$message({
          message: this.$locale.digitaltwin_editor.project_panel.select_model,
          type: 'warning'
        });
      }
    },
    projectsContextMenu (event, data) {
      if (data.element_type == 'project') {
        this.selectedProjectDtoFromContextMenu = data;
        this.$refs.projects_context_menu.open(event, data);
      }
    },
    buildProject () {
      if (this.selectedProjectDtoFromContextMenu !== null) {
        let query = new ProjectByGuidQuery(
          this.selectedProjectDtoFromContextMenu.element_guid
        );
        this.getQueryBus().execute(
          query 
        ).then((resultDto) => {
          this.templates = resultDto.templates;
          this.showTemplates = true;
        });
      }
    },
    closeTemplates () {
      this.templates = [];
      this.showTemplates = false;
    },
    saveTemplate () {
      if (this.selectedTemplates.length == 0) {
        this.closeTemplates();
      } else if (this.selectedTemplates.length == 1) {
        let templateId = this.selectedTemplates[0].id;
        let command = new ProjectBuildCommand(                
          this.selectedProjectDtoFromContextMenu.element_guid,
          templateId
        )
        this.getCommandBus().execute(command).then((response) => {
          this.selectedProjectDtoFromContextMenu = null;
          this.closeTemplates();
          this.$message({
            showClose: true,
            message: this.$locale.digitaltwin_editor.project_panel.project_build_initiated,
            type: 'success'
          });
        });
      } else {
        this.$message({
          showClose: true,
          message: this.$locale.digitaltwin_editor.project_panel.select_one_template,
          type: 'success'
        });
      }
    },
    selectTemplate (records) {
      this.selectedTemplates = records;      
    },
    downloadLastBuild () {
      if (this.selectedProjectDtoFromContextMenu !== null) {
        let query = new ProjectByGuidQuery(
          this.selectedProjectDtoFromContextMenu.element_guid
        )     
        this.getQueryBus().execute(
          query 
        ).then((resultDto) => {
          if (typeof resultDto.last_build !== "undefined" && resultDto.last_build != null) {
            let command = new DownloadLastBuildCommand(                
              resultDto.guid,
              resultDto.year,
              resultDto.month,
              resultDto.extension
            )
            this.getCommandBus().execute(command).then((response) => {
              this.selectedProjectDtoFromContextMenu = null;
            });
          } else {
            this.$message({
              message: this.$locale.digitaltwin_editor.project_panel.build_missing,
              type: 'warning'
            });
          }
        });
      }
    },
    downloadPrj () {
      if (this.selectedProjectDtoFromContextMenu !== null) {
        let command = new DownloadPrjCommand(                
          this.selectedProjectDtoFromContextMenu.element_guid,
          this.selectedProjectDtoFromContextMenu.name
        )
        this.getCommandBus().execute(command).then((response) => {
          this.selectedProjectDtoFromContextMenu = null;
        });
      }
    },
    saveProjectTreeElement (elementDto) {
      console.log("Saved ", elementDto);
      //this.$refs.projectTree.remove(this.selectedProjectTreeElement); 
    }
  },
  mounted () {
    this.getEventBus().$on('saveProjectTreeElement', this.saveProjectTreeElement);
  }
}
