import { ActionTree } from 'vuex'
import { TreeElementState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, TreeElementLoadingEvent, TreeElementLoadedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { TreeElementAPI } from '@/services/EtlEditor/infrastructure/api/TreeElementAPI'

export const actions: ActionTree<TreeElementState, any> = {
  [ActionTypes.GET_TREE_ELEMENTS]: async ({ commit, dispatch }, data) => {
    try {
      commit(new TreeElementLoadingEvent())
      let response = await APIClient.shared.request(new TreeElementAPI.GetTreeElements(data.payload))
      commit(new TreeElementLoadedEvent())
      return response
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_TREE_ELEMENTS_TREE]: async ({ commit, dispatch }, data) => {
    try {
      return await APIClient.shared.request(new TreeElementAPI.GetTreeElementsTree(data.payload))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_TREE_ELEMENT_BY_GUID]: async ({ commit, dispatch }, data) => {
    try {
      return await APIClient.shared.request(new TreeElementAPI.GetTreeElementByGuid(data.guid))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_TREE_ELEMENT_BY_ELEMENT_GUID]: async ({ commit, dispatch }, data) => {
    try {
      return await APIClient.shared.request(new TreeElementAPI.GetTreeElementByElementGuid(data.elementGuid))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  }
}
