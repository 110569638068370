<template>
  <div class="addresses">
    <div class="header">
      <i class="el-icon-refresh-right button" @click="load"></i>
      <el-input
        v-model="search"
        style="width: 20%; float: right"
        :placeholder="$locale.main.fields.search"/>
    </div>
    <el-table
      :header-cell-style="headerCellStyle"
      :cell-style="cellStyle"
      :data="addresses.filter(item => !search || (item.address.toLowerCase().includes(search.toLowerCase()) || item.id.toLowerCase().includes(search.toLowerCase())))"
      v-loading="loading"
      style="width: 100%; border: none"
      class="custom_scrollbar table"
      height="calc(100% - 75px)"
    >
      <el-table-column
        width="70"
        prop="id"
        sortable
        :label="$locale.address_service.addresses.columns.id">
        <template slot-scope="scope">
          <span style="font-weight: bold">{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="address"
        sortable
        :label="$locale.address_service.addresses.columns.address">
      </el-table-column>
    </el-table>
    <el-pagination
      :page-size="limit"
      :total="total"
      layout="prev, pager, next"
      @current-change="pageChange">
    </el-pagination>
  </div>
</template>

<script>
import AddressesQuery from '@/services/AddressService/application/query/AddressesQuery'

export default {
  name: 'addresses',
  inject: ['getQueryBus', 'getCommandBus'],
  data () {
    return {
      isActive: true,
      search: null,
      addresses: [],
      loading: false,
      limit: 50,
      total: 0,
      page: 1,
      cellStyle: {
        fontSize: '15px',
        color: '#2D3239'
      },
      headerCellStyle: {
        color: '#6E757C',
        fontSize: '13px',
        border: 'none',
        background: '#EFF0F0'
      }
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    pageChange (value) {
      this.page = value
      this.load()
    },
    async load () {
      this.loading = true

      let total = await this.getQueryBus().execute(
        new AddressesQuery({
          '*[func]': 'count'
        })
      )
      this.total = total[0].count

      this.addresses = await this.getQueryBus().execute(
        new AddressesQuery({
          limit: this.limit,
          offset: (this.page - 1) * this.limit
        })
      )

      this.loading = false
    }
  }
}
</script>

<style scoped src="./Addresses.css">

</style>
