import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { TaskDTO } from '@/services/DigitalTwinEditor/domain/model/Task/Task'

export namespace TaskAPI {

    export class CountTasks implements APIRequest<TaskDTO> {
        response: TaskDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor (payload: string[][]) {
          this.path = '/digitaltwineditor/tasks?*[func]=count&' + (new URLSearchParams(payload).toString())
        }
    }
  
    export class GetTasks implements APIRequest<TaskDTO> {
        response: TaskDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor (payload: string[][]) {
          this.path = '/digitaltwineditor/tasks?' + (new URLSearchParams(payload).toString())
        }
    }
}