import { ActionTree } from 'vuex'
import { RecordHistoryState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, RecordHistoryLoadingEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { HistoryAPI } from '@/services/History/infrastructure/api/HistoryAPI'

export const actions: ActionTree<RecordHistoryState, any> = {
  [ActionTypes.GET_BY_RECORD]: async ({ commit, dispatch }, data) => {
    try {
      commit(new RecordHistoryLoadingEvent())
      let response = await APIClient.shared.request(new HistoryAPI.GetByRecord(data.registryId, data.recordId))
      commit(new RecordHistoryLoadingEvent())
      return response
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  }
}
