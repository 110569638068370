import { ActionTree } from 'vuex'
import { ReleaseState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, ReleaseLoadingEvent, ReleaseLoadedEvent, ReleaseCreatedEvent, ReleaseUpdatedEvent, ReleaseDeletedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { ReleaseAPI } from '@/services/ReleaseEditor/infrastructure/api/ReleaseAPI'

export const actions: ActionTree<ReleaseState, any> = {
    [ActionTypes.ADD_RELEASE]: async ({ commit, dispatch }, data) => {
        try {
            commit(new ReleaseLoadingEvent());
            let location = await APIClient.shared.request(new ReleaseAPI.AddRelease(data.payload));
            commit(new ReleaseCreatedEvent(location));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.UPDATE_RELEASE]: async ({ commit, dispatch }, data) => {
        try {
            commit(new ReleaseLoadingEvent());
            let location = await APIClient.shared.request(new ReleaseAPI.UpdateRelease(data.payload));
            commit(new ReleaseUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.DELETE_RELEASE]: async ({ commit, dispatch }, data) => {
        try {
            await APIClient.shared.request(new ReleaseAPI.DeleteRelease(data.guid));
            commit(new ReleaseDeletedEvent(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }         
    },
    [ActionTypes.GET_RELEASES]: async ({ commit, dispatch }, data) => {
        try {
            commit(new ReleaseLoadingEvent());
            let res = await APIClient.shared.request(new ReleaseAPI.GetReleases(data.payload));
            commit(new ReleaseLoadedEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.COUNT_RELEASES]: async ({ commit, dispatch }, data) => {
        try {
            commit(new ReleaseLoadingEvent());
            let res = await APIClient.shared.request(new ReleaseAPI.CountReleases(data.payload));
            commit(new ReleaseLoadedEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.GET_RELEASE_BY_GUID]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new ReleaseAPI.GetReleaseByGuid(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    }
}
