import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ProjectCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/ProjectCommandRepositoryInterface'
import DownloadPrjCommand from '@/services/DigitalTwinEditor/application/command/DownloadPrjCommand'
import { ProjectDTO } from '@/services/DigitalTwinEditor/domain/model/Project/Project'

export default class DownloadPrjHandler implements CommandHandlerInterface
{
    private repo: ProjectCommandRepositoryInterface;

    constructor(repo: ProjectCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: DownloadPrjCommand): Promise<void>
    {
        let item: ProjectDTO = {
            guid: command.getGuid(),
            name: command.getName()
        };
        return this.repo.downloadPrj(item);
    }
}