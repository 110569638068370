import { MutationTree } from 'vuex'
import { BindServiceState, initialState } from './state'
import { MutationTypes, BindServiceCreatedEvent, BindServiceDeletedEvent } from './mutation-types'

export const mutations: MutationTree<BindServiceState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false;
    state.error = error;    
  },
  [MutationTypes.BIND_SERVICE_LOADING_EVENT]: (state) => {
    state.isLoading = true;
  },
  [MutationTypes.BIND_SERVICE_LOADED_EVENT]: (state) => {
    state.isLoading = false;
  },
  [MutationTypes.BIND_SERVICE_CREATED_EVENT]: (state, action: BindServiceCreatedEvent) => {
    state.location = action.location;
    state.isLoading = false;
  },
  [MutationTypes.BIND_SERVICE_DELETED_EVENT]: (state, action: BindServiceDeletedEvent) => {
    state.isLoading = false;
  }
}