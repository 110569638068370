import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
  ERROR = 'error',
  APPROVAL_LOADING_EVENT = 'logic_loading_event',
  APPROVAL_LOADED_EVENT = 'logic_loaded_event',
  APPROVAL_CREATED_EVENT = 'logic_created_event',
  APPROVAL_UPDATED_EVENT = 'logic_updated_event',
  APPROVAL_DELETED_EVENT = 'logic_deleted_event',

  APPROVAL_STAGE_TYPE_LOADING_EVENT = 'approval_stage_type_loading_event',
  APPROVAL_STAGE_TYPE_LOADED_EVENT = 'approval_stage_type_loaded_event',

  APPROVAL_STAGE_LOADING_EVENT = 'approval_stage_loading_event',
  APPROVAL_STAGE_LOADED_EVENT = 'approval_stage_loaded_event'
}

export class ApprovalLoadingEvent implements FluxStandardAction {
  type = MutationTypes.APPROVAL_LOADING_EVENT
}

export class ApprovalLoadedEvent implements FluxStandardAction {
  type = MutationTypes.APPROVAL_LOADED_EVENT
}

export class ApprovalCreatedEvent implements FluxStandardAction {
  type = MutationTypes.APPROVAL_CREATED_EVENT
  constructor (public location: string) {}
}

export class ApprovalUpdatedEvent implements FluxStandardAction {
  type = MutationTypes.APPROVAL_UPDATED_EVENT
  constructor (public location: string) {}
}

export class ApprovalDeletedEvent implements FluxStandardAction {
  type = MutationTypes.APPROVAL_DELETED_EVENT
  constructor (public guid: string) {}
}

export class ApprovalStageTypeLoadingEvent implements FluxStandardAction {
  type = MutationTypes.APPROVAL_STAGE_TYPE_LOADING_EVENT
}

export class ApprovalStageTypeLoadedEvent implements FluxStandardAction {
  type = MutationTypes.APPROVAL_STAGE_TYPE_LOADED_EVENT
}

export class ApprovalStageLoadingEvent implements FluxStandardAction {
  type = MutationTypes.APPROVAL_STAGE_LOADING_EVENT
}

export class ApprovalStageLoadedEvent implements FluxStandardAction {
  type = MutationTypes.APPROVAL_STAGE_LOADED_EVENT
}
