var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form',{staticClass:"mini-form",attrs:{"label-width":"120px","label-position":"top","size":"mini"}},[_c('el-select',{attrs:{"filterable":"","clearable":""},on:{"clear":function($event){_vm.localModel.cardId = null, _vm.$emit('change', _vm.localModel)},"change":function($event){return _vm.$emit('change', _vm.localModel)}},model:{value:(_vm.localModel.cardId),callback:function ($$v) {_vm.$set(_vm.localModel, "cardId", $$v)},expression:"localModel.cardId"}},_vm._l((_vm.cards),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.name,"value":item.id}})}),1),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.localModel.cardId),expression:"localModel.cardId"}],staticStyle:{"display":"flex"},attrs:{"label":_vm.$locale.interface_editor.component_editor.button_action.open_in_window}},[_c('el-checkbox',{staticStyle:{"margin-left":"10px"},model:{value:(_vm.localModel.isWindow),callback:function ($$v) {_vm.$set(_vm.localModel, "isWindow", $$v)},expression:"localModel.isWindow"}})],1),(_vm.localModel.isWindow)?_c('el-form-item',{attrs:{"label":this.$locale.interface_editor.component_editor.button_action.window_title}},[_c('el-input',{model:{value:(_vm.localModel.windowTitle),callback:function ($$v) {_vm.$set(_vm.localModel, "windowTitle", $$v)},expression:"localModel.windowTitle"}})],1):_vm._e(),(_vm.localModel.isWindow)?_c('el-form-item',{attrs:{"label":this.$locale.interface_editor.component_editor.button_action.window_width}},[_c('editor-select',{attrs:{"value":_vm.localModel.windowWidth,"options":{
              multiple: false,
              options: [
                { id: '25', name:'25%' },
                { id: '50', name:'50%' },
                { id: '75', name:'75%' },
                { id: '100', name:'100%' },
              ]
            }},on:{"change":function($event){return _vm.$set(_vm.localModel ,'windowWidth', $event)}}})],1):_vm._e(),(_vm.localModel.isWindow)?_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$locale.interface_editor.component_editor.button_action.window_height)+" (ex: 400px)")]),_c('el-input',{attrs:{"value":_vm.localModel.windowHeight},on:{"input":function($event){return _vm.$set(_vm.localModel ,'windowHeight', $event)}}})],1):_vm._e(),_c('el-form-item',[_c('span',{staticClass:"property_label",staticStyle:{"display":"inline-block"}},[_vm._v(_vm._s(_vm.$locale.interface_editor.component_editor.editor_open_card.container))]),_c('el-tooltip',{attrs:{"placement":"top-start","open-delay":400}},[_c('div',{attrs:{"slot":"content"},domProps:{"innerHTML":_vm._s(_vm.$locale.interface_editor.component_editor.editor_open_card.tooltip.container)},slot:"content"}),_c('i',{staticClass:"el-icon-question"})]),_c('el-select',{attrs:{"clearable":""},model:{value:(_vm.localModel.containerAlias),callback:function ($$v) {_vm.$set(_vm.localModel, "containerAlias", $$v)},expression:"localModel.containerAlias"}},_vm._l((_vm.containers),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.alias,"value":item.alias}})}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }