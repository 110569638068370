import Model from './Model'
import Child from './Child'
import Root from './Root'
import Expanded from './Expanded'

export default class Entity extends Model {
  resource () {
    return 'entities'
  }

  children () {
    return this.hasMany(Child)
  }

  root () {
    return this.hasMany(Root)
  }

  expanded () {
    return this.hasMany(Expanded)
  }

  get child () {
    return []
  }

  get xrefDisabled () {
    return true
  }
}
