import QueryInterface from './QueryInterface'

export default abstract class AbstractByIdQuery implements QueryInterface
{
    private id: number;

    constructor(id: number)
    {
        this.id = id;
    }

    getId(): number
    {
        return this.id;
    }

    abstract getClassName(): string;
}