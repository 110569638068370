<template>
    <el-form label-width="120px" label-position="top" size="mini">
        <el-form-item :label="$locale.interface_editor.component_editor.editor_tasks_groups.title">
            <el-button @click="addGroup">{{ $locale.main.button.add }}</el-button>
            <el-row v-for="(group, index) in value" :key="index">
                <el-col :span="2">{{ index + 1 }}.</el-col>
                <el-col :span="16"><el-input v-model="group.name"/></el-col>
                <el-col :span="6"><el-button @click="removeGroup(index)"
                                              size="mini"
                                              type="danger"
                                              :title="$locale.main.button.delete">
                    <i class="el-icon-delete"></i>
                </el-button></el-col>
            </el-row>
        </el-form-item>
    </el-form>
</template>

<script>
export default {
  name: 'editor-tasks-groups',
  props: {
    value: {
      type: Array,
      default () {
        return []
      }
    }
  },
  watch: {
    value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    }
  },
  methods: {
    addGroup () {
      this.value.push({
        name: null
      })
    },
    removeGroup (index) {
      this.value.splice(index, 1)
    }
  }
}
</script>

<style scoped>

</style>
