import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { BindServiceDTO } from '@/services/ReleaseEditor/domain/model/BindService/BindService'

export enum ActionTypes {
    ADD_BIND_SERVICE = 'add_bind_service',
    DELETE_BIND_SERVICE = 'delete_bind_service',
    GET_BIND_SERVICES = 'get_bind_services'
}

export class AddBindService implements FluxStandardAction {
    type = 'BindService/' + ActionTypes.ADD_BIND_SERVICE
    constructor(public payload: BindServiceDTO){}
}

export class DeleteBindService implements FluxStandardAction {
    type = 'BindService/' + ActionTypes.DELETE_BIND_SERVICE
    constructor(public guid: string, public payload: string[][]){}
}

export class GetBindServices implements FluxStandardAction {
    type = 'BindService/' + ActionTypes.GET_BIND_SERVICES
    constructor(public payload: object){}
}

