<template>
  <div>
    <h1 v-if="busRoleId !== null">{{nameRole}}</h1>
      <el-alert
        v-show="busRoleId === null"
        :title="$locale.access_editor.right_object.role_not_found"
        type="error"
        show-icon>
      </el-alert>
    <el-table class="custom_scrollbar"  height="calc(100vh - 228px)" v-show="busRoleId !== null" :data="treeRightUser" style="width: 100%;margin-bottom: 20px;" row-key="id" border>
      <!-- <el-table
      class="custom_scrollbar"
      height="calc(100vh - 228px)"
      v-show="busRoleId !== null"
      :data="treeRightUser"
      lazy
      style="width: 100%;margin-bottom: 20px;"
      row-key="id"
      border
      :load="loadNode"
      :tree-props="{isLeaf: 'leaf', label: 'name', children: 'child'}"
      > -->
      <el-table-column prop="name" :label="$locale.access_editor.right_object.list"></el-table-column>
      <el-table-column prop="is_addable" :label="$locale.access_editor.roles_table.add" width="180">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.is_addable !== null"
            size="mini"
            v-model="scope.row.is_addable"
            @click="scope.row.is_addable = !scope.row.is_addable"
            :disabled="activeItem !== scope.row.id"
            :type="scope.row.is_addable ? 'success' : 'danger'"
          >{{scope.row.is_addable ? $locale.access_editor.yes : $locale.access_editor.no}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="is_deletable" :label="$locale.access_editor.roles_table.delete" width="180">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.is_deletable !== null"
            size="mini"
            v-model="scope.row.is_deletable"
            @click="scope.row.is_deletable = !scope.row.is_deletable"
            :disabled="activeItem !== scope.row.id"
            :type="scope.row.is_deletable ? 'success' : 'danger'"
          >{{scope.row.is_deletable ? $locale.access_editor.yes : $locale.access_editor.no}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="is_editable" :label="$locale.access_editor.roles_table.edit" width="180">
        <template slot-scope="scope">
          <el-button
            size="mini"
            v-model="scope.row.is_editable"
            @click="scope.row.is_editable = !scope.row.is_editable"
            v-show="scope.row.isShowEditable"
            :disabled="activeItem !== scope.row.id"
            :type="scope.row.is_editable ? 'success' : 'danger'"
          >{{scope.row.is_editable ? $locale.access_editor.yes : $locale.access_editor.no}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="is_readable" :label="$locale.access_editor.roles_table.read" width="180">
        <template slot-scope="scope">
          <el-button
            size="mini"
            v-model="scope.row.is_readable"
            @click="scope.row.is_readable = !scope.row.is_readable"
            v-show="scope.row.isShowReadable"
            :disabled="activeItem !== scope.row.id"
            :type="scope.row.is_readable ? 'success' : 'danger'"
          >{{scope.row.is_readable ? $locale.access_editor.yes : $locale.access_editor.no}}</el-button>
        </template>
      </el-table-column>
      <el-table-column width="180" :label="$locale.access_editor.roles_table.action">
        <template slot-scope="scope">
          <el-tooltip
            v-if="activeItem === scope.row.id"
            class="item"
            effect="dark"
            :content="$locale.main.button.save"
            placement="bottom"
          >
            <el-button
              @click="editRow(scope.row)"
              type="success"
              icon="el-icon-check"
              circle
              size="mini"
            ></el-button>
          </el-tooltip>
          <el-tooltip v-else
            class="item" effect="dark"
            :content="$locale.main.button.edit"
            placement="bottom">
            <el-button
              :disabled="disabled"
              @click="editRow(scope.row)"
              icon="el-icon-edit"
              circle
              size="mini"
            ></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import RightObject from '../Models/RightObject'
import { eventBus } from '@/eventBus'
export default {
  name: 'RightObject',
  watch: {
    // id роли из компонента roles
    async busRoleId (val) {
      if (val != null) {
        let right = new RightObject({ id: val })
        const result = await RightObject.custom(right).get()
        this.treeRightUser = result
        this.deepSearch(this.treeRightUser)
      } else {
        this.treeRightUser = []
      }
    },
    role (val) {
      this.busRoleId = val.id
    },
    treeRightUser: {
      handler: function (val, oldVal) {
        this.deepSearch(val)
      }
      // deep: true
    }
  },
  computed: {
    disabled: function () {
      return this.activeItem !== null
    }
  },
  data () {
    return {
      // выбранная строка
      nameRole: null,
      activeItem: null,
      treeRightUser: [],
      busRoleId: null
    }
  },
  methods: {
    editRow (item) {
      console.log(item)
      if (!this.activeItem) {
        this.activeItem = item.id
      } else {
        let params = {
          entity_id: item.id,
          role_id: this.busRoleId,
          is_addable: item.is_addable,
          is_deletable: item.is_deletable,
          is_editable: item.is_editable,
          is_readable: item.is_readable
        }
        this.$http.put(`${this.$config.api}/accesseditor/rights`, params)
        this.activeItem = null
      }
    },
    // async loadNode (node, resolve) {
    //   console.log(node)
    //   if (node.level === 0) {
    //     resolve(await this.loadEntities())
    //   } else {
    //     resolve(await this.loadEntities(node.data.id))
    //   }
    // },
    // async loadEntities (entityId = null) {
    //   let data = []
    //   if (!entityId) {
    //     // data = await new Entity().params({ root: true }).$get()
    //   } else {
    //     // data = await new Entity({ id: entityId }).children().$get()
    //   }

    //   return data
    // },
    deepSearch (item) {
      item.forEach(el => {
        this.$set(el, 'isShowReadable', true)
        this.$set(el, 'isShowEditable', true)
        if (el.children && el.is_readable) {
          this.addItemInTree(el, 'isShowReadable', true)
        }
        if (el.children && !el.is_readable) {
          this.addItemInTree(el, 'isShowReadable', false)
        }
        if (el.children && el.is_editable) {
          this.addItemInTree(el, 'isShowEditable', true)
        }
        if (el.children && !el.is_editable) {
          this.addItemInTree(el, 'isShowEditable', false)
        }
        if (el.children instanceof Array) this.deepSearch(el.children)
      })
    },
    addItemInTree (el, item, bln) {
      el.children.forEach(elem => {
        this.$set(elem, item, bln)
      })
    }
  },
  mounted () {
    eventBus.$on('roleId', data => {
      this.busRoleId = data.id
      this.nameRole = data.name
    })
  }
}
</script>
