<template>
  <el-form size="mini" style="padding: 10px">
    <editor-open-card
      :isButton="false"
      :sourceId="registryId"
      :sourceType="'registry'"
      :isHeaders="true"
      :value="value.card"
      :registry-attrs="registryAttrs"
    ></editor-open-card>
  </el-form>
</template>

<script>
import EditorOpenCard from '@/components/InterfaceEditor/components/editor/editor-open-card'
import Registry from '@/components/Models/EntityModel'

export default {
  name: 'Interactive',
  components: { EditorOpenCard },
  props: {
    entityId: Number,
    value: Object
  },
  watch: {
    'value.card.card.registryId': {
      handler (value) {
        this.getRegistryAttrs(value)
      }
    }
  },
  data () {
    return {
      registryId: this.entityId,
      registryAttrs: []
    }
  },
  created () {
    if (Object.keys(this.value.card.card).length === 0) {
      this.$set(this.value.card, 'card', JSON.parse(JSON.stringify(this.value.card)))
    }
    this.$nextTick(() => {
      this.$set(this.value.card, 'type', 'open_card')
    }, this)
  },
  mounted () {
    if (this.value.card.card.registryId) {
      this.getRegistryAttrs(this.value.card.card.registryId)
    }
  },
  methods: {
    async getRegistryAttrs (registryId) {
      if (!registryId) {
        return
      }
      //this.registryAttrs = await this.proceedObjectItems(registryId)
      let response = await Registry.params({ parent_id: registryId, show_children: true }).$get()
      this.registryAttrs = await this.getRegistryAttrsRecursive(response)
    },
    async getRegistryAttrsRecursive (entities) {
      let data = []
      let me = this

      entities.map(async function (entity) {
        if (entity.has_children && entity.entity_type_id === 'field_group') {
          let temp = await me.getRegistryAttrsRecursive(entity.children)
          temp.map(function (item) {
            data.push(item)
          })
        } else {
          let attr = `attr_${entity.id}_`
          data.push({
            id: attr,
            name: attr
          })
        }
      })

      data.filter(item => {
        return item !== undefined
      })

      return data
    }
  }
}
</script>

<style scoped>

</style>
