<template>
  <div class="input-offset">
    <div style="float: left">
      <span>X&nbsp;</span>
      <el-input-number
        v-model="xOffset"
        @change="emitChangeX"
        :step="step"
        :disabled="disabled"
        :min="xmin"
        :max="xmax"
        :size="inputSize"
      ></el-input-number>
    </div>
    <div class="second-input" style="float: left; margin-left: 10px;">
      <span>Y&nbsp;</span>
      <el-input-number
        v-model="yOffset"
        @change="emitChangeY"
        :step="step"
        :disabled="disabled"
        :min="ymin"
        :max="ymax"
        :size="inputSize"      
      ></el-input-number>
    </div>
  </div>
</template>

<script type="text/babel">
  import ElInputNumber from 'element-ui/packages/input-number';

  export default {
    name: 'InputOffset',

    inject: {
      elForm: {
        default: ''
      }
    },

    props: {
      isVertical: {
        type: Boolean,
        default: false
      },
      xmin: {
        type: Number,
        default: -Infinity
      },
      xmax: {
        type: Number,
        default: Infinity
      },
      ymin: {
        type: Number,
        default: -Infinity
      },
      ymax: {
        type: Number,
        default: Infinity
      },
      step: {
        type: Number,
        default: 1
      },
      disabled: {
        type: Boolean,
        default: false
      },
      inputSize: {
        type: String,
        default: 'small'
      }
    },

    components: {
      ElInputNumber
    },

    data() {
      return {
        xOffset: 0,
        yOffset: 0
      };
    },

    methods: {
      emitChangeX(val) {
        this.$nextTick(() => {
          this.$emit('change', val, this.yOffset);
        });
      },
      emitChangeY(val) {
        this.$nextTick(() => {
          this.$emit('change', this.xOffset, val);
        });
      }
    },

    mounted() {
      this.$nextTick(() => {
        this.$emit('init', (value) => {
          this.xOffset = value[0];
          this.yOffset = value[1];
        });
      });
    }
  };
</script>
