import { render, staticRenderFns } from "./editor-accordion-source.vue?vue&type=template&id=29794e56&scoped=true&"
import script from "./editor-accordion-source.vue?vue&type=script&lang=js&"
export * from "./editor-accordion-source.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29794e56",
  null
  
)

export default component.exports