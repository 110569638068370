import { FluxStandardAction, Nullable } from '@/core/domain/type/types'

export enum ActionTypes {
    GET_DEPLOYMENTS = 'get_deployments'
}

export class GetDeployments implements FluxStandardAction {
    type = 'Deployment/' + ActionTypes.GET_DEPLOYMENTS
    constructor(public payload: object){}
}

