<template>
  <div>
    <el-button type="text" @click="dialogSettingsVisible = true">{{ $locale.main.button.edit }}</el-button>

    <el-dialog :title="$locale.interface_editor.registry_headers.title" :modal="false"
               :visible.sync="dialogSettingsVisible"
               class="configurator-window">
      <configurator
        v-if="sourceType && sourceId"
        :source-id="sourceId"
        :source-type="sourceType"
        :available-columns="availableColumns"
        :value.sync="localValue"
      ></configurator>
    </el-dialog>
  </div>
</template>

<script>
import Configurator from '@/components/InterfaceEditor/components/editor/Columns/Configurator'
import ColumnsRepository from '@/components/InterfaceEditor/components/editor/Columns/repository/ColumnsRepository'
import { ColumnsFactory as RegistryColumnsFactory } from '@/services/RegistryTable/domain/service/ColumnsFactory'
import { ExtendedObjectColumnsFactory } from '@/components/InterfaceEditor/components/editor/Columns/service/ExtendedObjectColumnsFactory'

export default {
  name: 'columns',
  components: { Configurator },
  props: ['other-properties', 'value', 'options'],
  data () {
    return {
      sourceType: null,
      dialogSettingsVisible: false,
      sourceId: null,
      columnsRepository: null,
      availableColumns: [],
      localValue: this.value
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    key () {
      let key = 'none'
      if (this.options) {
        if (this.options.sourceType === 'registry') {
          key = `registry_${this.otherProperties.registryId}`
        } else if (this.options.sourceType === 'external') {
          key = `${(this.otherProperties.source || {}).type}_${(this.otherProperties.source || {}).entityId}`
        }
      }
      return key
    }
  },
  methods: {
    init () {
      if (this.options) {
        if (this.options.sourceType === 'registry') {
          this.sourceType = 'registry'
          this.sourceId = this.otherProperties.registryId
        } else if (this.options.sourceType === 'external') {
          this.sourceType = (this.otherProperties.source || {}).type
          this.sourceId = (this.otherProperties.source || {}).entityId
        }
      }
      if (this.sourceType && this.sourceId) {
        this.columnsRepository = new ColumnsRepository(this.sourceType, this.sourceId)
        this.load()
      }
    },
    async load () {
      let columns = await this.columnsRepository.get()
      if (columns) {
        switch (this.sourceType) {
          case 'registry':
            this.availableColumns = RegistryColumnsFactory.build(columns)
            break
          case 'extended_object':
            this.availableColumns = ExtendedObjectColumnsFactory.build(columns)
            break
        }
      }
    }
  },
  watch: {
    key () {
      this.init()
    },
    localValue: {
      deep: true,
      handler (value) {
        this.$emit('change', value)
      }
    },
    dialogSettingsVisible: {
      handler (value) {
        this.$emit('open-modal-window-by-configurator', value)
      }
    }
  }
}
</script>

<style scoped>
.configurator-window > /deep/ .el-dialog {
  width: 60%;
}
</style>
