
import Treeselect from '@bingosoftnn/vue-treeselect'

export default {
  name: 'smevMessagePanel',
  components: {
    Treeselect
  },
  data () {
    return {
      smevMessage: {
        message_id: null,
        type: null,
        content: null
      },
      types: [{
        id: "SendRequestRequest",
        name: "SendRequestRequest"
      },{
        id: "SendRequestResponse",
        name: "SendRequestResponse"
      },{
        id: "GetResponseRequest",
        name: "GetResponseRequest"
      },{
        id: "GetResponseResponse",
        name: "GetResponseResponse"
      }],
      smevMessageRules: {
        message_id: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        type: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        }
      },
    }
  },
  inject: ['getQueryBus', 'getCommandBus'],
  methods: {
    async viewSmevMessage() {
      let filename = '{' + this.smevMessage.message_id + '}-{' + this.smevMessage.type + '}-{SUCCESS}';
      let response = await this.$http.get(`${this.$config.api}/databusservice/utilities/smev/message/${filename}`);
      if (response.data.status == 'ok') {
        this.smevMessage.content = response.data.content;
      }
      else {
        this.$message({
          message: this.$locale.databus.entities.smev_message + " " + filename + " " + this.$locale.databus.log_panel.not_found,
          type: 'warning'
        });
      }
    },
    async downloadSmevMessage() {
      let filename = '{' + this.smevMessage.message_id + '}-{' + this.smevMessage.type + '}-{SUCCESS}';
      let response = await this.$http.get(`${this.$config.api}/databusservice/utilities/smev/message/${filename}`);
      if (response.data.status == 'ok') {
        const url = window.URL.createObjectURL(new Blob([response.data.content]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      }
      else {
        this.$message({
          message: this.$locale.databus.entities.smev_message + " " + filename + " " + this.$locale.databus.log_panel.not_found,
          type: 'warning'
        });
      }
    },
    downloadButtonLocker() {
      if (this.smevMessage === null || typeof this.smevMessage === 'undefined') {
        return true
      }
      if (this.smevMessage.message_id === null || typeof this.smevMessage.message_id === 'undefined' || this.smevMessage.message_id == '') {
        return true
      }
      if (this.smevMessage.type === null || typeof this.smevMessage.type === 'undefined') {
        return true
      }

      return false;
    }
  },
}
