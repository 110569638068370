import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { LayerTreeElementDTO } from '@/services/MapEditor/domain/model/Layer/LayerTreeElement'

export enum ActionTypes {
    ADD_LAYER_TREE_LAYER_ELEMENT = 'add_layer_tree_layer_element',
    PUT_LAYER_TREE_LAYER_ELEMENT = 'put_layer_tree_layer_element',
    ADD_LAYER_TREE_GROUP_ELEMENT = 'add_layer_tree_group_element',
    DELETE_LAYER_TREE_ELEMENT = 'delete_layer_tree_element',
    GET_LAYER_TREE_ELEMENT_BY_GUID = 'get_layer_tree_element_by_guid',
    GET_LAYER_TREE_ELEMENTS = 'get_layer_tree_elements',
    UPDATE_PARENT = 'update_parent',
}

export class AddLayerTreeLayerElement implements FluxStandardAction {
    type = 'LayerTreeElement/' + ActionTypes.ADD_LAYER_TREE_LAYER_ELEMENT
    constructor(public payload: LayerTreeElementDTO){}
}

export class PutLayerTreeLayerElement implements FluxStandardAction {
    type = 'LayerTreeElement/' + ActionTypes.PUT_LAYER_TREE_LAYER_ELEMENT
    constructor(public payload: LayerTreeElementDTO){}
}

export class AddLayerTreeGroupElement implements FluxStandardAction {
    type = 'LayerTreeElement/' + ActionTypes.ADD_LAYER_TREE_GROUP_ELEMENT
    constructor(public payload: LayerTreeElementDTO){}
}

export class DeleteLayerTreeElement implements FluxStandardAction {
    type = 'LayerTreeElement/' + ActionTypes.DELETE_LAYER_TREE_ELEMENT
    constructor(public guid: string, public payload: string[][]){}
}

export class GetLayerTreeElementByGuid implements FluxStandardAction {
    type = 'LayerTreeElement/' + ActionTypes.GET_LAYER_TREE_ELEMENT_BY_GUID
    constructor(public guid: string){}
}

export class GetLayerTreeElements implements FluxStandardAction {
    type = 'LayerTreeElement/' + ActionTypes.GET_LAYER_TREE_ELEMENTS
    constructor(public parentId: Nullable<number>, public projectId: Nullable<number>){}
}

export class UpdateParent implements FluxStandardAction {
    type = 'LayerTreeElement/' + ActionTypes.UPDATE_PARENT
    constructor(public payload: LayerTreeElementDTO){}
}