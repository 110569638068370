import AbstractByGuidQuery from './AbstractByGuidQuery'
import QueryHandlerInterface from './QueryHandlerInterface'
import AbstractQueryRepository from '../../domain/repository/AbstractQueryRepository'
import AbstractBaseEntity from '../../domain/model/AbstractBaseEntity'

export default abstract class AbstractByGuidQueryHandler implements QueryHandlerInterface
{
    private repo: AbstractQueryRepository;

    constructor(repo: AbstractQueryRepository)
    {
        this.repo = repo;
    }

    execute(query: AbstractByGuidQuery): Promise<any>
    {
        return this.repo.getByGuid(query.getGuid());
    }
}