import Model from './Model'
import LoaderTypeProperty from './LoaderTypeProperty'

export default class LoaderType extends Model {
  resource () {
    return 'loader_types'
  }

  properties () {
    return this.hasMany(LoaderTypeProperty)
  }
}
