import { FluxStandardAction, Nullable } from '@/core/domain/type/types'

export enum ActionTypes {
  GET_EDITOR_ENTITIES = 'editor_entities',
  EXPORT = 'export'
}

export class GetEditorEntities implements FluxStandardAction {
  type = 'ConfigurationElement/' + ActionTypes.GET_EDITOR_ENTITIES
  constructor (public payload: object) {}
}

export class ConfigurationElementsExport implements FluxStandardAction {
  type = 'ConfigurationElement/' + ActionTypes.EXPORT
  constructor (public payload: object) {}
}
