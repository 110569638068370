<template>
  <div :class="mainClass">
    <div class="panel-heading">
      <span class="panel-title">
        <slot name="header">
          {{ header }}
        </slot>
      </span>
    </div>
    <div class="panel-body">
      <slot></slot>
    </div>
    <div class="panel-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Panel',

  props: {
    header: {
      type: String,
      default: null
    },
    class: {
      type: String,
      default: null
    }
  },

  computed: {
    mainClass () {
      return !this.class ? `panel panel-default` : `panel ${this.class}`
    }
  },

  data () {
    return {
    }
  }
}
</script>

<style type="text/css">
  .panel-default {
    border-color: #ddd;
  }
  .panel-success {
    border-color: #d6e9c6;
  }
  .panel-info {
    border-color: #bce8f1;
  }
  .panel-warning {
    border-color: #faebcc;
  }
  .panel-danger {
    border-color: #ebccd1;
  }
  .panel {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
  }
  .panel-default>.panel-heading {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
  }
  .panel-heading {
    font-size: 16px;
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
  }
  .panel-heading .panel-title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    color: #616161;
  }
  .panel-body {
    /*padding: 15px;*/
  }
  .panel-footer {
    padding: 10px 15px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
  }
</style>
