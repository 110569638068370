import { AffineTransformationDTO } from '@/services/MapEditor/domain/model/Geometry/AffineTransformation'

export interface AffineTransformationState {
  isLoading: boolean;
  location: string;
  error: object
}

export const initialState = (): AffineTransformationState => {
  return {
    isLoading: false,
    location: null,
    error: null
  }
}