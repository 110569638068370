<template>
  <el-form-item :label="label">
    <el-tree
      style="height: 200px; overflow-y:scroll"
      ref="tree"
      lazy
      :props="props"
      :load="loadNode"
      show-checkbox
      @check-change="checkNode"
      :default-checked-keys="[localValue]"
      :default-expanded-keys="expanded"
      check-strictly
      node-key="id"
      :expand-on-click-node="false">
      <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>{{ node.label }} (id: {{data.id}}, <b>{{ $locale.object_editor.entity_types[data.entity_type_id] || data.entity_type_id }}</b>)</span>
      </span>
    </el-tree>
  </el-form-item>
</template>

<script>
import Entity from '../Models/Entity'

export default {
  name: 'field',

  props: ['value', 'label'],

  data () {
    return {
      expanded: [],
      localValue: parseInt(this.value),
      props: {
        isLeaf: 'leaf',
        label: 'name',
        disabled: 'xrefDisabled'
      }
    }
  },

  async mounted () {
    this.onMounted()
  },

  watch: {
    value (val) {
      this.localValue = parseInt(val)
      this.onMounted()
    }
  },

  methods: {
    async onMounted () {
      this.$refs.tree.setCheckedKeys([])
      if (this.localValue) {
        let expanded = await new Entity({ id: this.localValue }).expanded().$first()
        this.expanded = this.getExpanded(expanded)
      }
    },

    checkNode (node, checked) {
      if (checked) {
        this.$refs.tree.setCheckedKeys([node.id])
        this.$emit('input', parseInt(node.id))
      }
    },

    getExpanded (object) {
      let array = []
      if (object && object.parent) {
        array.push(object.parent.id)
        array.push(...this.getExpanded(object.parent))
      }
      return array
    },

    async loadNode (node, resolve) {
      if (node.level === 0) {
        resolve(await this.loadEntities())
      } else {
        resolve(await this.loadEntities(node.data.id))
      }
    },

    async loadEntities (entityId = null) {
      let data = []
      if (!entityId) {
        data = await new Entity().params({ root: true }).$get()
      } else {
        data = await new Entity({ id: entityId }).children().$get()
      }

      return data
    }
  }
}
</script>

<style scoped>

</style>
