import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    REGIONS_LOADING_EVENT = 'addresses_loading_event',
    REGIONS_LOADED_EVENT = 'addresses_loaded_event'
}

export class RegionsLoadingEvent implements FluxStandardAction {
    type = MutationTypes.REGIONS_LOADING_EVENT
}

export class RegionsLoadedEvent implements FluxStandardAction {
    type = MutationTypes.REGIONS_LOADED_EVENT
}
