export interface RoleState {
    isLoading: boolean;
    location: string;
    error: object;
  }

export const initialState = (): RoleState => {
  return {
    isLoading: false,
    location: null,
    error: null
  }
}
