import { MutationTree } from 'vuex'
import { NotificationState } from './state'
import { MutationTypes, NotificationCreatedEvent, NotificationUpdatedEvent, NotificationDeletedEvent } from './mutation-types'

export const mutations: MutationTree<NotificationState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false
    state.error = error
  },
  [MutationTypes.NOTIFICATION_LOADING_EVENT]: (state) => {
    state.isLoading = true
  },
  [MutationTypes.NOTIFICATION_LOADED_EVENT]: (state) => {
    state.isLoading = false
  },
  [MutationTypes.NOTIFICATION_CREATED_EVENT]: (state, action: NotificationCreatedEvent) => {
    state.isLoading = false
    state.location = action.location
  },
  [MutationTypes.NOTIFICATION_UPDATED_EVENT]: (state, action: NotificationUpdatedEvent) => {
    state.isLoading = false
    state.location = action.location
  },
  [MutationTypes.NOTIFICATION_DELETED_EVENT]: (state, action: NotificationDeletedEvent) => {
    state.isLoading = false
    state.deletedNotificationId = action.id
  }
}
