import { ActionTree } from 'vuex'
import { ProjectState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, ProjectUpdatedEvent, ProjectLoadingEvent, ProjectLoadedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { ProjectAPI } from '@/services/DigitalTwinEditor/infrastructure/api/ProjectAPI'

export const actions: ActionTree<ProjectState, any> = {
    [ActionTypes.UPDATE_PROJECT]: async ({ commit, dispatch }, data) => {
        try {
            commit(new ProjectLoadingEvent());
            let location = await APIClient.shared.request(new ProjectAPI.UpdateProject(data.payload));
            commit(new ProjectUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.GET_PROJECT_BY_GUID]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new ProjectAPI.GetProjectByGuid(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    },
    [ActionTypes.BUILD_PROJECT]: async ({ commit, dispatch }, data) => {
        try {
            commit(new ProjectLoadingEvent());
            let location = await APIClient.shared.request(new ProjectAPI.BuildProject(data.payload));
            commit(new ProjectUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.DOWNLOAD_LAST_BUILD]: async ({ commit, dispatch }, data) => {
        try {
            commit(new ProjectLoadingEvent());
            let location = await APIClient.shared.request(new ProjectAPI.DownloadLastBuild(data.payload));
            commit(new ProjectUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.DOWNLOAD_PRJ]: async ({ commit, dispatch }, data) => {
        try {
            commit(new ProjectLoadingEvent());
            let location = await APIClient.shared.request(new ProjectAPI.DownloadPrj(data.payload));
            commit(new ProjectUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.ATTACH_TEMPLATE]: async ({ commit, dispatch }, data) => {
        try {
            commit(new ProjectLoadingEvent());
            await APIClient.shared.request(new ProjectAPI.AttachTemplate(data.payload));
            commit(new ProjectUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.DETACH_TEMPLATE]: async ({ commit, dispatch }, data) => {
        try {
            await APIClient.shared.request(new ProjectAPI.DetachTemplate(data.payload));
            commit(new ProjectUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }         
    },
    [ActionTypes.GET_PROJECT_BUILDS]: async ({ commit, dispatch }, data) => {
        try {
            commit(new ProjectLoadingEvent())
            let res = await APIClient.shared.request(new ProjectAPI.GetProjectBuilds(data.payload))
            commit(new ProjectLoadedEvent())
            return res
        } catch (error) {
            commit(MutationTypes.ERROR, error)
        }
    },
    [ActionTypes.COUNT_PROJECT_BUILDS]: async ({ commit, dispatch }, data) => {
        try {
            commit(new ProjectLoadingEvent())
            let res = await APIClient.shared.request(new ProjectAPI.CountProjectBuilds(data.payload))
            commit(new ProjectLoadedEvent())
            return res
        } catch (error) {
            commit(MutationTypes.ERROR, error)
        }
    }
}
