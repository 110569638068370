import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { ActivityDTO } from '@/services/ActivityService/domain/model/Activity/Activity'

export namespace ActivityAPI {
    export class GetActivities implements APIRequest<ActivityDTO> {
        response: ActivityDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor (payload: string[][]) {
          this.path = '/activityservice/activities?' + (new URLSearchParams(payload).toString())
        }
    }
}
