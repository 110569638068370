import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    LOADER_LOADING_EVENT = 'loader_loading_event',
    LOADER_LOADED_EVENT = 'loader_loaded_event',
    LOADER_CREATED_EVENT = 'loader_created_event',
    LOADER_UPDATED_EVENT = 'loader_updated_event',
    LOADER_DELETED_EVENT = 'loader_deleted_event'
}

export class LoaderLoadingEvent implements FluxStandardAction {
  type = MutationTypes.LOADER_LOADING_EVENT
}

export class LoaderLoadedEvent implements FluxStandardAction {
  type = MutationTypes.LOADER_LOADED_EVENT
}

export class LoaderCreatedEvent implements FluxStandardAction {
  type = MutationTypes.LOADER_CREATED_EVENT
  constructor (public location: string) {}
}

export class LoaderUpdatedEvent implements FluxStandardAction {
  type = MutationTypes.LOADER_UPDATED_EVENT
  constructor (public location: string) {}
}

export class LoaderDeletedEvent implements FluxStandardAction {
  type = MutationTypes.LOADER_DELETED_EVENT
  constructor (public guid: string) {}
}
