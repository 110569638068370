import {Model as BaseModel} from 'vue-api-query'

export default class Model extends BaseModel {
  #defaultProperty = {};

  constructor (...attributes) {
    super(...attributes);

    if (attributes.length) {
      Object.assign(this.#defaultProperty, ...attributes)
    }
  }

  // define a base url for a REST API
  baseURL () {
    return `${config.api}/etleditor`
  }

  // implement a default request method
  request (config) {
    // config.hideNotification = true
    return this.$http.request(config)
  }

  async save () {
    let result = await super.save();

    this.#defaultProperty = {};
    Object.assign(this.#defaultProperty, result);

    return result
  }

  parameterNames () {
    return {
      include: 'include',
      filter: 'filter',
      sort: 'order',
      fields: 'fields',
      append: 'append',
      page: 'page',
      limit: 'limit'
    }
  }

  isEdit () {
    for (let [key, value] of Object.entries(this.#defaultProperty)) {
      if (this.#defaultProperty[key] !== this[key]) {
        return true
      }
    }

    return false
  }
}
