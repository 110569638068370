<template>
  <div class="source-form custom_scrollbar">
    <el-form :rules="formRules" :ref="formId" v-if="dto" class="source-form-inner-body" :model="dto">
      <el-form-item prop="name" :label="$locale.main.fields.name">
        <el-input v-model="dto.name" autocomplete="off"></el-input>        
      </el-form-item>
      <el-form-item prop="type" :label="$locale.main.fields.type" style="width:206px">
        <treeselect
          v-model="dto.type"
          :placeholder="$locale.main.fields.select"
          :normalizer="typesNormalizer"
          :options="types"
          :clear-value-text="$locale.main.message.clear"
          :loading-text="$locale.main.message.loading"
          :disable-immediate-search="true"
          :async="true"
          :cache-options="false"
          :append-to-body="true"
          :load-options="loadTypes"
          :clearable="false"
          :delete-removes="false"
          :backspace-removes="false"
          @open="loadTypes"
        ></treeselect>
      </el-form-item>
      <PropertySet v-if="properties.length" ref="propertySet" :items="properties" :dto="dto"/>
    </el-form>
  </div>
</template>

<script type="ts">
import Treeselect from '@bingosoftnn/vue-treeselect'
import Source, { SourceDTO } from '@/services/MapEditor/domain/model/Source/Source'
import SourceCreateCommand from '@/services/MapEditor/application/command/SourceCreateCommand'
import SourceUpdateCommand from '@/services/MapEditor/application/command/SourceUpdateCommand'
import SourceByGuidQuery from '@/services/MapEditor/application/query/SourceByGuidQuery'
import SourceTypesQuery from '@/services/MapEditor/application/query/SourceTypesQuery'
import SourceTypePropertiesQuery from '@/services/MapEditor/application/query/SourceTypePropertiesQuery'
import PropertySet from '@/services/MapEditor/infrastructure/components/PropertySet/index.vue'

export default {
  name: 'SourceForm',
  props: ['formId', 'parentFormId', 'projectId', 'projectGuid', 'source', 'submitCallback', 'sourceCreatedCallback', 'sourceUpdatedCallback'],
  inject: ['getEventBus', 'getCommandBus', 'getQueryBus'],
  components: {
    Treeselect,
    PropertySet
  },
  watch: {
    dto: {
      handler: async function(curDto) {
        if (curDto.type != this.sourceType) {
          if (this.types == null) {
            await this.loadTypes();
          }
          this.sourceType = curDto.type;
          if (typeof curDto.type !== 'undefined') {
            this.properties = await this.getQueryBus().execute(
              new SourceTypePropertiesQuery(curDto.type)
            );          
          } else {
            this.properties = [];
          }
        }
      },
      deep: true
    },
    sourceCreated: function (location) {
      this.getQueryBus().execute(
        new SourceByGuidQuery(
          location.replace('/sources/', '')
        )
      ).then((sourceDto) => {
        let source = Source.create(sourceDto);
        if (typeof this.sourceCreatedCallback == 'function') {
          this.sourceCreatedCallback(source);
        }
        this.getEventBus().$emit('sourceCreated', source, this.parentFormId);        
      }); 
    } 
  },
  data() {
    return {
      types: null,
      dto: null,
      sourceType: null,
      properties: [],
      defaultFormRules: {
        name: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        type: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'blur'
        }
      },
      formRules: {
        name: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        type: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'blur'
        }
      }     
    }
  },
  computed: {
    sourceCreated() {
      return this.$store.getters['Source/getLocation'];
    }
  },
  methods: {
    async loadTypes() {
      this.types = await this.getQueryBus().execute(
        new SourceTypesQuery()
      );
    },
    typesNormalizer(node) {
      return {
        id: node.id,
        label: this.$locale.map_editor.source_types[node.name]
      };
    },
    updateRules() {
      let rules = this.defaultFormRules;
      this.properties.forEach((prop) => {
        rules[prop.id] = {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: this.triggerType(prop.type)
        }
      });
      this.formRules = rules;
    },
    triggerType(name) {
      switch(name) {
        case 'geometry_object':
        case 'geometry_field':
          return 'blur';
        default:
          return 'change'; 
      }
    },
    async submit(callback) {
      let form = this.$refs[this.formId];
      form.validate(valid => {
        if (valid) {
          if (typeof this.source !== 'undefined') {
            this.source.setName(this.dto.name);
            this.source.setType(this.dto.type);
            this.source.setProperties(this.dto.properties);

            this.getCommandBus().execute(
              new SourceUpdateCommand(
                this.source.getGuid(),
                this.source.getName(),
                this.source.getType(),
                this.source.getProperties()
              )
            ).then((response) => {
              if (typeof callback == 'function') {
                callback();
              }
              if (typeof this.sourceUpdatedCallback == 'function') {
                this.sourceUpdatedCallback(this.source);
              }
              this.getEventBus().$emit('sourceUpdated', this.source);
            });
          } else {
            let source = Source.create(this.dto);
            this.getCommandBus().execute(
              new SourceCreateCommand(
                source.getName(),
                source.getType(),
                source.getProperties(),
                this.projectId
              )
            ).then((response) => {
              if (typeof callback == 'function') {
                callback(response);
              }              
            });
          }
        }
      });
    },
    propertiesMounted(properties) {
      if (typeof this.source !== 'undefined' && this.$refs.propertySet == properties) {
        properties.setValues(this.source.getProperties());
      }
    }
  },
  mounted() {
    if (typeof this.source !== 'undefined') {
      this.dto = new SourceDTO ({
        id: this.source.getId(),
        guid: this.source.getGuid(),
        name: this.source.getName(),
        type: this.source.getType(),
        properties: this.source.getProperties()
      });
    } else {      
      this.dto = new SourceDTO({});
    }
    this.getEventBus().$on('propertiesMounted', this.propertiesMounted);
  }
}
</script>