export interface SnapshotState {
    isLoading: boolean;
    error: object;
  }
  
  export const initialState = (): SnapshotState => {
    return {
      isLoading: false,
      error: null
    }
  }