import { MutationTree } from 'vuex'
import { RasterState } from './state'
import { MutationTypes, RasterCreatedEvent, RasterUpdatedEvent, RasterDeletedEvent } from './mutation-types'

export const mutations: MutationTree<RasterState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false;
    state.error = error;    
  },
  [MutationTypes.RASTER_LOADING_EVENT]: (state) => {
    state.isLoading = true;
  },
  [MutationTypes.RASTER_LOADED_EVENT]: (state) => {
    state.isLoading = false;
  },
  [MutationTypes.RASTER_CREATED_EVENT]: (state, action: RasterCreatedEvent) => {
    state.location = action.location;
    state.isLoading = false;
  },
  [MutationTypes.RASTER_UPDATED_EVENT]: (state, action: RasterUpdatedEvent) => {
    state.isLoading = false;
  },
  [MutationTypes.RASTER_DELETED_EVENT]: (state, action: RasterDeletedEvent) => {
    state.isLoading = false;
  }
}