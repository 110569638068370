import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class FormulaDTO {
  id?: Nullable<number>
  guid?: Nullable<string>
  name: Nullable<string>
  description: Nullable<string>
  formula_type_id: string
  sql_statements: Nullable<object>
  dsl_statement: Nullable<string>
  entity_id: Nullable<number>
  role_id: Nullable<number>
  is_dsl: boolean
  is_stored: boolean
  is_basic: boolean
  alias: Nullable<string>
  logic_id?: number
  parent_tree_element_id?: Nullable<number>

  constructor ({ id, guid, name, description, formula_type_id, sql_statements, dsl_statement, entity_id, role_id, is_dsl, is_stored, is_basic, alias, logic_id, parent_tree_element_id }) {
    this.id = id
    this.guid = guid
    this.name = name
    this.description = description
    this.formula_type_id = formula_type_id
    this.sql_statements = sql_statements
    this.dsl_statement = dsl_statement
    this.entity_id = entity_id
    this.role_id = role_id
    this.is_dsl = is_dsl
    this.is_stored = is_stored
    this.is_basic = is_basic
    this.alias = alias
    this.logic_id = logic_id
    this.parent_tree_element_id = parent_tree_element_id
  }

  static create (formulaTypeId: string = 'column_formula', entityId: number|null = null): FormulaDTO {
    return new FormulaDTO({
      id: null,
      guid: null,
      name: null,
      description: null,
      entity_id: entityId,
      is_dsl: false,
      sql_statements: {
        select: null,
        join: null,
        group_by: null,
        join_field_ids: []
      },
      dsl_statement: null,
      is_stored: false,
      is_basic: false,
      formula_type_id: formulaTypeId,
      role_id: null,
      alias: null,
      logic_id: null,
      parent_tree_element_id: null
    })
  }
}

export default class Formula extends AbstractBaseEntity {
  private id?: Nullable<number>
  private guid?: Nullable<string>
  private name: string
  private description: Nullable<string>
  private formulaTypeId: string
  private roleId: Nullable<number>
  private entityId: Nullable<number>
  private sqlStatements: Nullable<object>
  private dslStatement: Nullable<string>
  private isDsl: boolean
  private isStored: boolean
  private isBasic: boolean
  private alias: Nullable<string>
  private logicId?: number
  private parentTreeElementId?: Nullable<number>

  constructor (
    name: string,
    description: Nullable<string>,
    formulaTypeId: string,
    isDsl: boolean,
    isStored: boolean,
    isBasic: boolean,
    roleId: Nullable<number>,
    entityId: Nullable<number>,
    sqlStatements: Nullable<object>,
    dslStatement: Nullable<string>,
    logicId: number,
    alias: Nullable<string>,
    parentTreeElementId?: Nullable<number>,
    id?: Nullable<number>,
    guid?: Nullable<string>
  ) {
    super()
    this.id = id
    this.guid = guid
    this.name = name
    this.description = description
    this.formulaTypeId = formulaTypeId
    this.roleId = roleId
    this.entityId = entityId
    this.sqlStatements = sqlStatements
    this.dslStatement = dslStatement
    this.isDsl = isDsl
    this.isStored = isStored
    this.isBasic = isBasic
    this.logicId = logicId
    this.alias = alias
    this.parentTreeElementId = parentTreeElementId
  }

  static create (dto: FormulaDTO): Formula {
    return new Formula(
      dto.name,
      dto.description,
      dto.formula_type_id,
      dto.is_dsl,
      dto.is_stored,
      dto.is_basic,
      dto.role_id,
      dto.entity_id,
      dto.sql_statements,
      dto.dsl_statement,
      dto.logic_id,
      dto.alias,
      dto.parent_tree_element_id,
      dto.id,
      dto.guid
    )
  }

  setId (id: number): void {
    this.id = id
  }

  getId (): number {
    return this.id
  }

  setGuid (guid: string): void {
    this.guid = guid
  }

  getGuid (): string {
    return this.guid
  }

  getName (): string {
    return this.name
  }

  setName (name: string): void {
    this.name = name
  }

  getDescription (): string {
    return this.description
  }

  setDescription (description: string): void {
    this.description = description
  }

  getFormulaTypeId (): string {
    return this.formulaTypeId
  }

  setFormulaTypeId (formulaTypeId: string): void {
    this.formulaTypeId = formulaTypeId
  }

  getRoleId (): Nullable<number> {
    return this.roleId
  }

  setRoleId (roleId: Nullable<number>): void {
    this.roleId = roleId
  }

  getEntityId (): Nullable<number> {
    return this.entityId
  }

  setEntityId (entityId: Nullable<number>): void {
    this.entityId = entityId
  }

  getSqlStatements (): Nullable<object> {
    return this.sqlStatements
  }

  setSqlStatements (sqlStatements: Nullable<object>): void {
    this.sqlStatements = sqlStatements
  }

  getDslStatement (): Nullable<string> {
    return this.dslStatement
  }

  setDslStatement (dslStatement: Nullable<string>): void {
    this.dslStatement = dslStatement
  }

  getIsDsl (): boolean {
    return this.isDsl
  }

  setIsDsl (isDsl: boolean): void {
    this.isDsl = isDsl
  }

  getIsStored (): boolean {
    return this.isStored
  }

  setIsStored (isStored: boolean): void {
    this.isStored = isStored
  }

  getIsBasic (): boolean {
    return this.isBasic
  }

  setIsBasic (isBasic: boolean): void {
    this.isBasic = isBasic
  }

  getAlias (): Nullable<string> {
    return this.alias
  }

  setAlias (alias: Nullable<string>): void {
    this.alias = alias
  }

  getLogicId (): number {
    return this.logicId
  }

  setLogicId (logicId: number): void {
    this.logicId = logicId
  }

  getParentTreeElementId (): Nullable<number> {
    return this.parentTreeElementId
  }

  setParentTreeElementId (parentTreeElementId?: Nullable<number>): void {
    this.parentTreeElementId = parentTreeElementId
  }
}
