import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class LogicTreeElementDTO {
  id?: Nullable<number>
  guid?: Nullable<string>
  name?: Nullable<string>
  parent_id?: Nullable<number>
  formula_id?: Nullable<number>
  row_formula_id?: Nullable<number>
  state_id?: Nullable<number>
  listener_id?: Nullable<number>
  command_id?: Nullable<number>
  constraint_id?: Nullable<number>
  approval_id?: Nullable<number>
  related_object_id?: Nullable<number>
  group_id?: Nullable<number>
  logic_id?: Nullable<number>
  element_type?: Nullable<string>
  element_guid?: Nullable<string>
  row_order?: Nullable<number>
  is_leaf?: Nullable<boolean>
  is_search?: boolean
  children?: Nullable<LogicTreeElementDTO[]>
  entity_id?: Nullable<number>
  entity_name?: Nullable<string>
  description?: Nullable<string>
  element_alias?: Nullable<string>
  element_id?: Nullable<number>
  logic_alias?: Nullable<string>

  constructor ({ id, guid, name, parent_id, element_alias, element_id, logic_alias, is_search, formula_id, row_formula_id, state_id, entity_id, entity_name, description, listener_id, command_id, constraint_id, approval_id, related_object_id, group_id, logic_id, element_type, element_guid, row_order, is_leaf, children }) {
    this.id = id
    this.guid = guid
    this.name = name
    this.parent_id = parent_id
    this.formula_id = formula_id
    this.row_formula_id = row_formula_id
    this.state_id = state_id
    this.listener_id = listener_id
    this.command_id = command_id
    this.constraint_id = constraint_id
    this.approval_id = approval_id
    this.related_object_id = related_object_id
    this.group_id = group_id
    this.logic_id = logic_id
    this.element_type = element_type
    this.element_guid = element_guid
    this.row_order = row_order
    this.is_leaf = is_leaf
    this.is_search = is_search
    if (children && Array.isArray(children)) {
      this.children = children.map(child => new LogicTreeElementDTO(child))
    } else {
      this.children = []
    }
    this.entity_id = entity_id
    this.entity_name = entity_name
    this.description = description
    this.element_alias = element_alias
    this.element_id = element_id
    this.logic_alias = logic_alias
  }
}

export default class LogicTreeElement extends AbstractBaseEntity {
  private id?: Nullable<number>
  private guid?: Nullable<string>
  private name: string
  private parentId: Nullable<number>
  private formulaId: Nullable<number>
  private rowFormulaId: Nullable<number>
  private stateId: Nullable<number>
  private listenerId: Nullable<number>
  private commandId: Nullable<number>
  private constraintId: Nullable<number>
  private approvalId: Nullable<number>
  private relatedObjectId: Nullable<number>
  private groupId: Nullable<number>
  private logicId: number
  private elementType: string
  private elementGuid: string
  private rowOrder: number
  private isLeaf: boolean

  constructor (
    name: string,
    logicId: number,
    elementType: string,
    elementGuid: string,
    rowOrder: number,
    isLeaf: boolean,
    id?: Nullable<number>,
    guid?: Nullable<string>,
    parentId?: Nullable<number>,
    formulaId?: Nullable<number>,
    rowFormulaId?: Nullable<number>,
    stateId?: Nullable<number>,
    listenerId?: Nullable<number>,
    commandId?: Nullable<number>,
    constraintId?: Nullable<number>,
    approvalId?: Nullable<number>,
    relatedObjectId?: Nullable<number>,
    groupId?: Nullable<number>
  ) {
    super()
    this.id = id
    this.guid = guid
    this.name = name
    this.parentId = parentId
    this.formulaId = formulaId
    this.rowFormulaId = rowFormulaId
    this.stateId = stateId
    this.listenerId = listenerId
    this.commandId = commandId
    this.constraintId = constraintId
    this.approvalId = approvalId
    this.relatedObjectId = relatedObjectId
    this.groupId = groupId
    this.elementType = elementType
    this.elementGuid = elementGuid
    this.rowOrder = rowOrder
    this.isLeaf = isLeaf
  }

  static create (dto: LogicTreeElementDTO): LogicTreeElement {
    return new LogicTreeElement(
      dto.name,
      dto.logic_id,
      dto.element_type,
      dto.element_guid,
      dto.row_order,
      dto.is_leaf,
      dto.id,
      dto.guid,
      dto.parent_id,
      dto.formula_id,
      dto.row_formula_id,
      dto.state_id,
      dto.listener_id,
      dto.command_id,
      dto.constraint_id,
      dto.approval_id,
      dto.related_object_id,
      dto.group_id
    )
  }

  setId (id: number): void {
    this.id = id
  }

  getId () {
    return this.id
  }

  setGuid (guid: string): void {
    this.guid = guid
  }

  getGuid (): string {
    return this.guid
  }

  setName (name: string): void {
    this.name = name
  }

  getName (): string {
    return this.name
  }

  setParentId (parentId: Nullable<number>): void {
    this.parentId = parentId
  }

  getParentId (): Nullable<number> {
    return this.parentId
  }

  setFormulaId (formulaId: Nullable<number>): void {
    this.formulaId = formulaId
  }

  getFormulaId (): Nullable<number> {
    return this.formulaId
  }

  setRowFormulaId (rowFormulaId: Nullable<number>): void {
    this.rowFormulaId = rowFormulaId
  }

  getRowFormulaId (): Nullable<number> {
    return this.rowFormulaId
  }

  setStateId (stateId: Nullable<number>): void {
    this.stateId = stateId
  }

  getStateId (): Nullable<number> {
    return this.stateId
  }

  setListenerId (listenerId: Nullable<number>): void {
    this.listenerId = listenerId
  }

  getListenerId (): Nullable<number> {
    return this.listenerId
  }

  setCommandId (commandId: Nullable<number>): void {
    this.commandId = commandId
  }

  getCommandId (): Nullable<number> {
    return this.commandId
  }

  setConstraintId (constraintId: Nullable<number>): void {
    this.constraintId = constraintId
  }

  getConstraintId (): Nullable<number> {
    return this.constraintId
  }

  setApprovalId (approvalId: Nullable<number>): void {
    this.approvalId = approvalId
  }

  getApprovalId (): Nullable<number> {
    return this.approvalId
  }

  setRelatedObjectId (relatedObjectId: Nullable<number>): void {
    this.relatedObjectId = relatedObjectId
  }

  getRelatedObjectId (): Nullable<number> {
    return this.relatedObjectId
  }

  setGroupId (groupId: Nullable<number>): void {
    this.groupId = groupId
  }

  getGroupId (): Nullable<number> {
    return this.groupId
  }

  setLogicId (logicId: number): void {
    this.logicId = logicId
  }

  getLogicId (): number {
    return this.logicId
  }

  setElementType (elementType: string): void {
    this.elementType = elementType
  }

  getElementType (): string {
    return this.elementType
  }

  setElementGuid (elementGuid: string): void {
    this.elementGuid = elementGuid
  }

  getElementGuid (): string {
    return this.elementGuid
  }

  setRowOrder (rowOrder: number): void {
    this.rowOrder = rowOrder
  }

  getRowOrder (): number {
    return this.rowOrder
  }

  setIsLeaf (isLeaf: boolean): void {
    this.isLeaf = isLeaf
  }

  getIsLeaf (): boolean {
    return this.isLeaf
  }
}
