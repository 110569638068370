<template>
  <el-checkbox v-model="localModel"
               v-on:input="$emit('input', localModel)"></el-checkbox>
</template>

<script>
export default {
  name: 'input_boolean_field',
  props: ['value'],
  data () {
    return {
      localModel: this.value
    }
  }
}
</script>
