import { ActionTree } from 'vuex'
import { CssClassState } from '@/services/CssEditor/infrastructure/store/modules/CssClass/state'
import { ActionTypes } from './action-types'
import { MutationTypes, CssClassUpdatedEvent, CssClassCreatedEvent, CssClassLoadingEvent, CssClassDeletedEvent, CssClassLoadedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { CssClassAPI } from '@/services/CssEditor/infrastructure/api/CssClassAPI'

export const actions: ActionTree<CssClassState, any> = {
  [ActionTypes.GET_CSS_CLASS]: async ({ commit }, data) => {
    commit(new CssClassLoadingEvent())

    return APIClient.shared.request(new CssClassAPI.GetCssClass(data.payload))
      .then((response) => {
        commit(new CssClassLoadedEvent())

        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },

  [ActionTypes.GET_CSS_CLASS_TREE]: async ({ commit }, data) => {
    commit(new CssClassLoadingEvent())

    return APIClient.shared.request(new CssClassAPI.GetCssClassTree(data.payload))
      .then((response) => {
        commit(new CssClassLoadedEvent())

        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },

  [ActionTypes.GET_CSS_CLASS_SEARCH]: async ({ commit }, data) => {
    commit(new CssClassLoadingEvent())

    return APIClient.shared.request(new CssClassAPI.GetCssClassSearch(data.payload))
      .then((response) => {
        commit(new CssClassLoadedEvent())

        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },

  [ActionTypes.GET_CSS_CLASS_TREE_BY_GUID]: async ({ commit }, data) => {
    commit(new CssClassLoadingEvent())

    return APIClient.shared.request(new CssClassAPI.GetCssClassTreeByGuid(data.guid))
      .then((response) => {
        commit(new CssClassLoadedEvent())

        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },

  [ActionTypes.GET_CSS_CLASS_BY_GUID]: async ({ commit }, data) => {
    commit(new CssClassLoadingEvent())

    return APIClient.shared.request(new CssClassAPI.GetCssClassByGuid(data.guid))
      .then((response) => {
        commit(new CssClassLoadedEvent())

        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },

  [ActionTypes.CREATE_CSS_CLASS]: async ({ commit }, data) => {
    commit(new CssClassLoadingEvent())

    return APIClient.shared.request(new CssClassAPI.CreateCssClass(data.payload))
      .then((location: string) => {
        commit(new CssClassCreatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },

  [ActionTypes.UPDATE_CSS_CLASS]: async ({ commit }, data) => {
    commit(new CssClassLoadingEvent())

    return APIClient.shared.request(new CssClassAPI.UpdateCssClass(data.payload))
      .then((location: string) => {
        commit(new CssClassUpdatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },

  [ActionTypes.DELETE_CSS_CLASS]: async ({ commit }, data) => {
    commit(new CssClassLoadingEvent())

    return APIClient.shared.request(new CssClassAPI.DeleteCssClass(data.guid))
      .then(() => {
        commit(new CssClassDeletedEvent(data.guid))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  }
}
