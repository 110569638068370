import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    RULE_LOADING_EVENT = 'rule_loading_event',
    RULE_LOADED_EVENT = 'rule_loaded_event',
    RULE_CREATED_EVENT = 'rule_created_event',
    RULE_UPDATED_EVENT = 'rule_updated_event',
    RULE_DELETED_EVENT = 'rule_deleted_event'
}

export class RuleLoadingEvent implements FluxStandardAction {
  type = MutationTypes.RULE_LOADING_EVENT
}

export class RuleLoadedEvent implements FluxStandardAction {
  type = MutationTypes.RULE_LOADED_EVENT
}

export class RuleCreatedEvent implements FluxStandardAction {
  type = MutationTypes.RULE_CREATED_EVENT
  constructor (public location: string) {}
}

export class RuleUpdatedEvent implements FluxStandardAction {
  type = MutationTypes.RULE_UPDATED_EVENT
  constructor (public location: string) {}
}

export class RuleDeletedEvent implements FluxStandardAction {
  type = MutationTypes.RULE_DELETED_EVENT
  constructor (public guid: string) {}
}
