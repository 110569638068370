import { ActionTree } from 'vuex'
import { RoleState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, RoleLoadingEvent, RoleLoadedEvent, RoleCreatedEvent, RoleUpdatedEvent, RoleDeletedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { RoleAPI } from '@/services/AccessEditor/infrastructure/api/RoleAPI'

export const actions: ActionTree<RoleState, any> = {
  [ActionTypes.ADD_ROLE]: async ({ commit, dispatch }, data) => {
    try {
      commit(new RoleLoadingEvent())
      let location = await APIClient.shared.request(new RoleAPI.AddRole(data.payload))
      commit(new RoleCreatedEvent(location))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.UPDATE_ROLE]: async ({ commit, dispatch }, data) => {
    try {
      commit(new RoleLoadingEvent())
      let location = await APIClient.shared.request(new RoleAPI.UpdateRole(data.payload))
      commit(new RoleUpdatedEvent())
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.DELETE_ROLE]: async ({ commit, dispatch }, data) => {
    try {
      await APIClient.shared.request(new RoleAPI.DeleteRole(data.guid))
      commit(new RoleDeletedEvent(data.guid))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_ROLES]: async ({ commit, dispatch }, data) => {
    try {
      commit(new RoleLoadingEvent())
      let res = await APIClient.shared.request(new RoleAPI.GetRoles(data.payload))
      commit(new RoleLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.COUNT_ROLES]: async ({ commit, dispatch }, data) => {
    try {
      commit(new RoleLoadingEvent())
      let res = await APIClient.shared.request(new RoleAPI.CountRoles(data.payload))
      commit(new RoleLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_ROLE_BY_GUID]: async ({ commit, dispatch }, data) => {
    try {
      return await APIClient.shared.request(new RoleAPI.GetRoleByGuid(data.guid))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_TOPIC_RIGHTS]: async ({ commit, dispatch }, data) => {
    try {
      commit(new RoleLoadingEvent())
      let res = await APIClient.shared.request(new RoleAPI.GetTopicRights(data.payload.role_guid, data.payload))
      commit(new RoleLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.COUNT_TOPIC_RIGHTS]: async ({ commit, dispatch }, data) => {
    try {
      commit(new RoleLoadingEvent())
      let res = await APIClient.shared.request(new RoleAPI.CountTopicRights(data.payload.role_guid, data.payload))
      commit(new RoleLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.UPDATE_TOPIC_RIGHT]: async ({ commit, dispatch }, data) => {
    try {
      commit(new RoleLoadingEvent())
      let location = await APIClient.shared.request(new RoleAPI.UpdateTopicRight(data.payload.role_guid, data.payload))
      commit(new RoleUpdatedEvent())
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_ROOT_MENU]: async ({ commit, dispatch }, data) => {
    try {
      commit(new RoleLoadingEvent())
      let res = await APIClient.shared.request(new RoleAPI.GetRootMenu())
      commit(new RoleLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_DASHBOARDS]: async ({ commit, dispatch }, data) => {
    try {
      commit(new RoleLoadingEvent())
      let res = await APIClient.shared.request(new RoleAPI.GetDashboards(data.payload))
      commit(new RoleLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_REGISTRY_RIGHTS]: async ({ commit, dispatch }, data) => {
    try {
      commit(new RoleLoadingEvent())
      let res = await APIClient.shared.request(new RoleAPI.GetRegistryRights(data.payload.guid, data.payload))
      commit(new RoleLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.UPDATE_REGISTRY_RIGHTS]: async ({ commit, dispatch }, data) => {
    try {
      commit(new RoleLoadingEvent())
      let location = await APIClient.shared.request(new RoleAPI.UpdateRegistryRights(data.payload.role_guid, data.payload))
      commit(new RoleUpdatedEvent())
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_ROLE_PROFILE]: async ({ commit, dispatch }, data) => {
    try {
      let res = await APIClient.shared.request(new RoleAPI.GetProfile(data.roleId))
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.UPDATE_ROLE_PROFILE]: async ({ commit, dispatch }, data) => {
    try {
      commit(new RoleLoadingEvent())
      let location = await APIClient.shared.request(new RoleAPI.UpdateRoleProfileRight(data.payload.role_guid, data.payload))
      commit(new RoleUpdatedEvent())
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  }
}
