import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { SettingDTO } from '@/services/Databus/domain/model/Setting/Setting'

export namespace SettingAPI {

    export class AddSetting implements APIRequest<string> {
        response: string;
        path = '/databusservice/settings';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.headers.location
        constructor(public params: SettingDTO) {}
    }

    export class UpdateSetting implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: SettingDTO) {
            this.path = `/databusservice/settings/${params.id}`;
        }
    }

    export class DeleteSetting implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.DELETE;
        parse = (data: AxiosResponse) => data.data
        constructor(id: number) {
            this.path = `/databusservice/settings/${id}`;
        }
    }

    export class CountSettings implements APIRequest<SettingDTO> {
        response: SettingDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/databusservice/settings?*[func]=count'
        }
    }

    export class GetSettings implements APIRequest<SettingDTO> {
        response: SettingDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/databusservice/settings?' + (new URLSearchParams(payload).toString())
        }
    }

}