import Event from '@/services/History/domain/model/Event'

export default class DatesFactory {
  public static build (events: Event[]): object {
    let result = {}

    events.forEach((item) => {
      let key = `${item.date.getFullYear()}-${item.date.getMonth()}`
      if (!result[key]) {
        result[key] = []
      }
      result[key].push(item.date)
    })

    return result
  }
}
