import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { TaskDTO, TaskObjectDTO } from '@/services/EtlEditor/domain/model/Task'

export namespace TaskAPI {
  export class GetTaskByGuid implements APIRequest<TaskDTO> {
    response: TaskDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/etleditor/tasks/${guid}`
    }
  }

  export class CreateTask implements APIRequest<string> {
    response: string;
    path = '/v2/etleditor/tasks';
    method = HTTPMethod.POST;
    parse = (data: AxiosResponse) => data.headers.location
    constructor (public params: TaskDTO) {}
  }

  export class UpdateTask implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.PUT;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: TaskDTO) {
      this.path = `/v2/etleditor/tasks/${params.guid}`
    }
  }

  export class DeleteTask implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.DELETE;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/etleditor/tasks/${guid}`
    }
  }

  export class GetTaskObjects implements APIRequest<TaskObjectDTO> {
    response: TaskObjectDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (payload: string[][]) {
      this.path = `/v2/etleditor/task_objects?${new URLSearchParams(payload).toString()}`
    }
  }

  export class GetTaskObjectByTaskId implements APIRequest<TaskObjectDTO> {
    response: TaskObjectDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (taskId: number) {
      this.path = `/v2/etleditor/task_objects/${taskId}`
    }
  }
}
