<template>
    <el-row>
        <el-row>
            <el-col :span="12">
                <span class="registry_fields_label" style="display: inline-block;">{{$locale.interface_editor.component_editor.absolute_position_interface_designer.component_properties.interactive}}</span>
            </el-col>
            <el-col :span="12">
                <el-select v-model="value.type">
                    <el-option
                            v-for="(item, index) in $locale.interface_editor.component_editor.absolute_position_interface_designer.component_properties.interactive_types"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                    ></el-option>
                </el-select>
            </el-col>
        </el-row>
        <el-row v-if="value.type === 'card'">
            <span class="registry_fields_label" style="display: inline-block;">{{$locale.interface_editor.component_editor.absolute_position_interface_designer.component_properties.object_field}}</span>
            <el-input v-model="value.properties.objectField"></el-input>
            <span class="registry_fields_label" style="display: inline-block;">{{$locale.interface_editor.component_editor.absolute_position_interface_designer.component_properties.id_field}}</span>
            <el-input v-model="value.properties.idField"></el-input>
        </el-row>
        <el-row v-else-if="value.type === 'plugin'">
            <span class="registry_fields_label" style="display: inline-block;">{{$locale.interface_editor.component_editor.table.plugin}}</span>
            <editor-plugin
                    :without-postfix="true"
                    :value="value.properties.plugin"
                    @change="$set(value.properties, 'plugin', $event)"
            ></editor-plugin>
        </el-row>
    </el-row>
</template>

<script>
import EditorPlugin from '../../../../editor/editor-plugins'
export default {
  name: 'interactive-editor',
  components: {
    EditorPlugin
  },
  props: {
    value: {
      type: Object,
      default () {
        return {
          type: null,
          properties: {}
        }
      }
    }
  },
  watch: {
    value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    },
    'value.type': {
      handler (type) {
        switch (type) {
          case 'card':
            this.$set(this.value, 'properties', {
              objectField: null,
              idField: null
            })
            break
          case 'plugin':
            this.$set(this.value, 'properties', {
              plugin: null
            })
            break
          default:
            this.$set(this.value, 'properties', {})
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
