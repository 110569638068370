export default class StopTilingCommand
{
    private guid: string;
    private command: string;

    constructor(guid: string, command: string)
    {
        this.guid = guid;
        this.command = command;
    }

    getGuid(): string
    {
        return this.guid;
    }

    getCommand(): string
    {
        return this.command;
    }

    getClassName(): string
    {
        return 'StopTilingCommand';
    }
}