<template>
  <el-time-select
    :picker-options="{
      start: '00:00',
      step: '00:15',
      end: '23:45'
    }"
    v-model="localModel"
    v-on:input="$emit('input', localModel)"
  >
  </el-time-select>
</template>

<script>
export default {
  name: 'input_time_field',
  props: ['value', 'name'],
  data () {
    return {
      localModel: this.value
    }
  }
}
</script>
