import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    GROUP_LOADING_EVENT = 'group_loading_event',
    GROUP_LOADED_EVENT = 'group_loaded_event',
    GROUP_CREATED_EVENT = 'group_created_event',
    GROUP_UPDATED_EVENT = 'group_updated_event',
    GROUP_DELETED_EVENT = 'group_deleted_event'
}

export class GroupLoadingEvent implements FluxStandardAction {
  type = MutationTypes.GROUP_LOADING_EVENT
}

export class GroupLoadedEvent implements FluxStandardAction {
  type = MutationTypes.GROUP_LOADED_EVENT
}

export class GroupCreatedEvent implements FluxStandardAction {
  type = MutationTypes.GROUP_CREATED_EVENT
  constructor (public location: string) {}
}

export class GroupUpdatedEvent implements FluxStandardAction {
  type = MutationTypes.GROUP_UPDATED_EVENT
  constructor (public location: string) {}
}

export class GroupDeletedEvent implements FluxStandardAction {
  type = MutationTypes.GROUP_DELETED_EVENT
  constructor (public id: number) {}
}
