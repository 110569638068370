import { FluxStandardAction } from '@/core/domain/type/types'

export enum ActionTypes {
  GET_SOURCE = 'get_source',
  GET_SOURCE_BY_GUID = 'get_source_by_guid',
  GET_SOURCE_BY_ID_AND_TYPE = 'get_source_by_id_and_type'
}

export class GetSource implements FluxStandardAction {
  type = 'NotificationTreeElement/' + ActionTypes.GET_SOURCE
  constructor (public payload: object) {}
}

export class GetSourceByGuid implements FluxStandardAction {
  type = 'NotificationTreeElement/' + ActionTypes.GET_SOURCE_BY_GUID
  constructor (public guid: string) {}
}

export class GetSourceByIdAndType implements FluxStandardAction {
  type = 'NotificationTreeElement/' + ActionTypes.GET_SOURCE_BY_ID_AND_TYPE
  constructor (public id: number, public elementType: string) {}
}
