import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import SourceDTO from '@/services/NotificationEditor/domain/model/SourceDTO'

export namespace SourceAPI {
  export class GetSource implements APIRequest<SourceDTO> {
    response: SourceDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (payload: string[][]) {
      this.path = `/notificationeditor/sources?${new URLSearchParams(payload).toString()}`
    }
  }

  export class GetSourceByGuid implements APIRequest<SourceDTO> {
    response: SourceDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/notificationeditor/sources/${guid}`
    }
  }

  export class GetSourceByIdAndType implements APIRequest<SourceDTO> {
    response: SourceDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (id: number, type: string) {
      this.path = `/notificationeditor/sources/${id}/${type}`
    }
  }
}
