import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    FEATURE_LOADING_EVENT = 'feature_loading_event',
    FEATURE_LOADED_EVENT = 'feature_loaded_event',
    FEATURE_UPDATED_EVENT = 'feature_updated_event'
}

export class FeatureLoadingEvent implements FluxStandardAction {
    type = MutationTypes.FEATURE_LOADING_EVENT
}

export class FeatureLoadedEvent implements FluxStandardAction {
    type = MutationTypes.FEATURE_LOADED_EVENT
}

export class FeatureUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.FEATURE_UPDATED_EVENT
}