import { ActionTree } from 'vuex'
import { ProcedureState, initialState } from './state'
import { ActionTypes } from './action-types'
import {
  MutationTypes,
  ProcedureUpdatedEvent,
  ProcedureCreatedEvent,
  ProcedureLoadingEvent,
  ProcedureDeletedEvent,
  ProcedureLoadedEvent
} from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { ProcedureAPI } from '@/services/LogicEditor/infrastructure/api/ProcedureAPI'

export const actions: ActionTree<ProcedureState, any> = {
  [ActionTypes.GET_PROCEDURES]: async ({ commit, dispatch }, payload: string[][] | object) => {
    commit(new ProcedureLoadingEvent())

    return APIClient.shared.request(new ProcedureAPI.GetProcedures(payload))
      .then((response) => {
        commit(new ProcedureLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },

  [ActionTypes.GET_PROCEDURE_BY_GUID]: async ({ commit, dispatch }, data) => {
    commit(new ProcedureLoadingEvent())

    return APIClient.shared.request(new ProcedureAPI.GetProcedureByGuid(data.guid))
      .then((response) => {
        commit(new ProcedureLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },

  [ActionTypes.CREATE_PROCEDURE]: async ({ commit, dispatch }, data) => {
    commit(new ProcedureLoadingEvent())

    return APIClient.shared.request(new ProcedureAPI.CreateProcedure(data.payload))
      .then((location: string) => {
        commit(new ProcedureCreatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },

  [ActionTypes.UPDATE_PROCEDURE]: async ({ commit, dispatch }, data) => {
    commit(new ProcedureLoadingEvent())

    return APIClient.shared.request(new ProcedureAPI.UpdateProcedure(data.payload))
      .then((location: string) => {
        commit(new ProcedureUpdatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },

  [ActionTypes.DELETE_PROCEDURE]: async ({ commit, dispatch }, data) => {
    commit(new ProcedureLoadingEvent())

    return APIClient.shared.request(new ProcedureAPI.DeleteProcedure(data.guid))
      .then(() => {
        commit(new ProcedureDeletedEvent(data.guid))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  }
}
