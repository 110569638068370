import AbstractEntitiesQuery from '@/core/application/query/AbstractEntitiesQuery'

export default class DataQuery extends AbstractEntitiesQuery {
  private readonly registryId: number;

  constructor (registryId: number, payload?: string[][] | object) {
    super(payload)
    this.registryId = registryId
  }

  getRegistryId (): number {
    return this.registryId
  }

  getClassName (): string {
    return 'DataQuery'
  }
}
