export interface LoaderState {
  isLoading: boolean
  location: string
  deletedLoaderGuid: string
  error: object
}

export const initialState = (): LoaderState => {
  return {
    isLoading: false,
    location: null,
    deletedLoaderGuid: null,
    error: null
  }
}
