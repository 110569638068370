import { MutationTree } from 'vuex'
import { ModelState } from './state'
import { MutationTypes, ModelUpdatedEvent } from './mutation-types'

export const mutations: MutationTree<ModelState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false;
    state.error = error;    
  },
  [MutationTypes.MODEL_LOADING_EVENT]: (state) => {
    state.isLoading = true;
  },
  [MutationTypes.MODEL_LOADED_EVENT]: (state) => {
    state.isLoading = false;
  },
  [MutationTypes.MODEL_UPDATED_EVENT]: (state, action: ModelUpdatedEvent) => {
    state.isLoading = false;
  }
}