import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { LayerGroupDTO } from '@/services/MapEditor/domain/model/Layer/LayerGroup'

export namespace LayerGroupAPI {
    export class AddLayerGroup implements APIRequest<string> {
        response: string;
        path = '/mapeditor/layer_tree_elements/groups';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.headers.location
        constructor(public params: LayerGroupDTO) {}
    }

    export class UpdateLayerGroup implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: LayerGroupDTO) {
            this.path = `/mapeditor/layer_groups/${params.guid}`;
        }
    }
}