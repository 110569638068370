import ProjectTreeElementType from './ProjectTreeElementType'
import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'
import NameTooLong from '@/core/domain/exception/NameTooLong'
import NameMustNotBeEmpty from '@/core/domain/exception/NameMustNotBeEmpty'

export class ProjectGroupDTO
{
    id?: Nullable<number>;
    guid?: Nullable<string>;
    name: string;
}

export default class ProjectGroup extends AbstractBaseEntity
{
    private id: Nullable<number>;
    private guid: Nullable<string>;
    private name: string;

    constructor(id: Nullable<number>, guid: Nullable<string>, name: string)
    {
        super();
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.assertInvariants();
    }

    static create(dto: ProjectGroupDTO): ProjectGroup
    {
        return new ProjectGroup(dto.id, dto.guid, dto.name);
    }

    setId(id: number): void
    {
        this.id = id;
    }

    getId(): number
    {
        return this.id;
    }

    setGuid(guid: string): void
    {
        this.guid = guid;
    }

    getGuid(): string
    {
        return this.guid;
    }

    setName(name: string): void
    {
        if (name.length > 255) {
            throw new NameTooLong();
        }
        this.name = name;
    }

    getName(): string
    {
        return this.name;
    }

    assertInvariants(): void
    {
        if (this.name.length == 0) {
            throw new NameMustNotBeEmpty();
        }

        if (this.name.length > 255) {
            throw new NameTooLong();
        }
    }

    getType(): ProjectTreeElementType
    {
        return ProjectTreeElementType.GROUP;
    }
}