import { ActionTree } from 'vuex'
import { LayerGroupState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, LayerGroupCreatedEvent, LayerGroupUpdatedEvent, LayerGroupLoadingEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { LayerGroupAPI } from '@/services/MapEditor/infrastructure/api/LayerGroupAPI'

export const actions: ActionTree<LayerGroupState, any> = {
    [ActionTypes.ADD_LAYER_GROUP]: async ({ commit, dispatch }, data) => {
        try {
            commit(new LayerGroupLoadingEvent());
            let location = await APIClient.shared.request(new LayerGroupAPI.AddLayerGroup(data.payload));
            commit(new LayerGroupCreatedEvent(location));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.UPDATE_LAYER_GROUP]: async ({ commit, dispatch }, data) => {
        try {
            commit(new LayerGroupLoadingEvent());
            let location = await APIClient.shared.request(new LayerGroupAPI.UpdateLayerGroup(data.payload));
            commit(new LayerGroupUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    }
}
