import { MutationTree } from 'vuex'
import { DeploymentState, initialState } from './state'
import { MutationTypes } from './mutation-types'

export const mutations: MutationTree<DeploymentState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false;
    state.error = error;    
  },
  [MutationTypes.DEPLOYMENT_LOADING_EVENT]: (state) => {
    state.isLoading = true;
  },
  [MutationTypes.DEPLOYMENT_LOADED_EVENT]: (state) => {
    state.isLoading = false;
  }
}