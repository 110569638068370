import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    SCHEMA_LOADING_EVENT = 'schema_loading_event',
    SCHEMA_LOADED_EVENT = 'schema_loaded_event'
}

export class SchemaLoadingEvent implements FluxStandardAction {
    type = MutationTypes.SCHEMA_LOADING_EVENT
}

export class SchemaLoadedEvent implements FluxStandardAction {
    type = MutationTypes.SCHEMA_LOADED_EVENT
}
