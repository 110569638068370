<template>
  <el-container direction="vertical" class="address-service">
    <div class="header-container">
      <div class="title">{{ $locale.address_service.title }}</div>
    </div>
    <div class="main-container">
      <el-tabs v-model="activeTab" style="width: 100%;" class="tabs">
        <el-tab-pane style="height: 100%;" :lazy="true" :label="$locale.address_service.tabs.isset_addresses" name="isset_addresses">
          <addresses></addresses>
        </el-tab-pane>
        <el-tab-pane style="height: 100%;" :lazy="true" :label="$locale.address_service.tabs.regions" name="regions">
          <regions></regions>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-container>
</template>

<script>
import QueryBus from '@/core/application/query/service/QueryBus'
import QueryBusHandlerLocator from '@/core/application/query/service/QueryBusHandlerLocator'
import AddressQueryRepository from '@/services/AddressService/infrastructure/domain/AddressQueryRepository'
import AddressesHandler from '@/services/AddressService/application/handler/query/AddressesHandler'
import Regions from '@/services/AddressService/infrastructure/components/Regions/index'
import Addresses from '@/services/AddressService/infrastructure/components/Addresses/index'
import RegionsHandler from '@/services/AddressService/application/handler/query/RegionsHandler'
import RegionQueryRepository from '@/services/AddressService/infrastructure/domain/RegionQueryRepository'
import CommandBus from '@/core/application/command/service/CommandBus'
import CommandBusHandlerLocator from '@/core/application/command/service/CommandBusHandlerLocator'
import RegionUpdateHandler from '@/services/AddressService/application/handler/command/RegionUpdateHandler'
import RegionCommandRepository from '@/services/AddressService/infrastructure/domain/RegionCommandRepository'
import UpdateFromFIASHandler from '@/services/AddressService/application/handler/command/UpdateFromFIASHandler'

export default {
  name: 'address-service',
  components: { Regions, Addresses },
  data () {
    return {
      activeTab: 'isset_addresses',
      commandBus: new CommandBus(
        new CommandBusHandlerLocator({
          'RegionUpdateCommand': new RegionUpdateHandler(
            new RegionCommandRepository()
          ),
          'UpdateFromFIASCommand': new UpdateFromFIASHandler(
            new RegionCommandRepository()
          )
        }
        )
      ),
      queryBus: new QueryBus(
        new QueryBusHandlerLocator({
          'AddressesQuery': new AddressesHandler(
            new AddressQueryRepository()
          ),
          'RegionsQuery': new RegionsHandler(
            new RegionQueryRepository()
          ) }
        )
      )
    }
  },
  provide () {
    return {
      getQueryBus: this.getQueryBus,
      getCommandBus: this.getCommandBus
    }
  },
  methods: {
    getQueryBus () {
      return this.queryBus
    },
    getCommandBus () {
      return this.commandBus
    }
  }
}
</script>

<style scoped src="./AddressService.css">

</style>
