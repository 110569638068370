import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { TextureDTO } from '@/services/DigitalTwinEditor/domain/model/Texture/Texture'

export namespace TextureAPI {

    export class CountTextures implements APIRequest<TextureDTO> {
        response: TextureDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor (payload: string[][]) {
          this.path = '/digitaltwineditor/textures?*[func]=count&' + (new URLSearchParams(payload).toString())
        }
    }
  
    export class GetTextures implements APIRequest<TextureDTO> {
        response: TextureDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor (payload: string[][]) {
          this.path = '/digitaltwineditor/textures?' + (new URLSearchParams(payload).toString())
        }
    }
}