<template>
  <el-form label-width="120px" label-position="top" size="mini" class="editor_button">
    <el-form-item>
      <el-checkbox v-model="localValue.isManualSigning"/>
      <span class="property_label" style="display: inline-block">{{ $t('interface_editor.a_file.manual_signing') }}</span>
    </el-form-item>
    <el-form-item v-if="localValue.isManualSigning">
      <span class="property_label" style="display: inline-block">{{ $t('interface_editor.a_file.execute_command_after_sign') }}</span>
      <el-select
        :value="localValue.commandId"
        value-key="id"
        filterable
        @change="$set(localValue ,'commandId', $event )"
      >
        <el-option
          v-for="(item, index) in commands"
          :key="index"
          :label="item.name"
          :value="item.id"
        >
          <span style="float: left">{{ item.name + ` (id: ${item.id})` }}</span>
        </el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'editor-file',
  inject: {
    getRegistryId: {
      default: () => null
    }
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          isManualSigning: false,
          commandId: null
        }
      }
    }
  },
  watch: {
    localValue: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    }
  },
  data () {
    return {
      localValue: this.value,
      commands: []
    }
  },
  mounted () {
    if (this.getRegistryId()) {
      this.$http.get(`${this.$config.api}/v2/logiceditor/logic?entity_id=${this.getRegistryId()}`)
        .then((response) => {
          if (response.data[0]) {
            this.$http.get(`${this.$config.api}/v2/logiceditor/commands?logic_id=${response.data[0].id}`)
              .then((res) => {
                this.commands = res.data
              })
          }
        })
    } else {
      this.$http.get(`${this.$config.api}/v2/logiceditor/commands`)
        .then((res) => {
          this.commands = res.data
        })
    }
  }
}
</script>

<style scoped>

</style>
