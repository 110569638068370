import { FluxStandardAction, Nullable } from '@/core/domain/type/types'

export enum ActionTypes {
    GET_TEXTURES = 'get_textures',
    COUNT_TEXTURES = 'count_textures'
}

export class GetTextures implements FluxStandardAction {
    type = 'Texture/' + ActionTypes.GET_TEXTURES
    constructor (public payload: object) {}
}

export class CountTextures implements FluxStandardAction {
    type = 'Texture/' + ActionTypes.COUNT_TEXTURES
    constructor (public payload: object) {}
}
