<template>
  <el-input
    v-model="localModel"
    ref="input_string_field"
    v-on:input="$emit('input', localModel)"
    :name="name"
  >
  </el-input>
</template>

<script>
export default {
  name: 'input_string_field',
  props: ['value', 'name'],
  data () {
    return {
      localModel: this.value
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.input_string_field.focus()
    })
  }
}
</script>
