  <template>
  <!-- <div style="height: calc(100% - 100px)"> -->
    <div style="height: 100%;">
    <div style="padding: 0px 0px 10px 10px;">
      <el-popover placement="bottom-left" width="350" trigger="click" v-model="dialogFormVisible">
        <el-form :model="form" size="small" labelPosition="right">
          <el-form-item style="width: 100%" :label="$locale.access_editor.name">
            <el-input v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item class="roles_width">
            <el-checkbox
              :label="$locale.access_editor.roles_table.read"
              v-model="form.is_readable_by_default"
              name="type"
              size="medium"
            ></el-checkbox>
            <el-checkbox
              class="roles_margin"
              :label="$locale.access_editor.roles_table.add"
              v-model="form.is_addable_by_default"
              name="type"
              size="medium"
            ></el-checkbox>
          </el-form-item>
          <el-form-item class="roles_width">
            <el-checkbox
              :label="$locale.access_editor.roles_table.edit"
              v-model="form.is_editable_by_default"
              name="type"
              size="medium"
            ></el-checkbox>
            <el-checkbox
              class="roles_margin2"
              :label="$locale.access_editor.roles_table.delete"
              v-model="form.is_deletable_by_default"
              name="type"
              size="medium"
            ></el-checkbox>
          </el-form-item>
          <el-form-item class="roles_width2">
            <el-select class="roles_widthSelect" clearable v-model="form.root_menu_id" :placeholder="$locale.access_editor.roles_table.root_menu_id" size="mini">
              <el-option
                v-for="item in listMenus"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <el-checkbox
              class="roles_margin2"
              :label="$locale.access_editor.roles_table.is_menu_visible_default"
              v-model="form.is_menu_visible_by_default "
              name="type"
              size="medium"
            ></el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-select
              :placeholder="$locale.access_editor.roles_table.default_dashboard"
              class="roles_widthSelect"
              value-key="id"
              collapse-tags
              multiple
              filterable
              v-model="form.dashboards"
              size="mini">
                <el-option
                  v-for="item in dashboards"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
            </el-select>
          </el-form-item>
          <div class="roles_group__btn">
            <el-button @click="close">{{$locale.main.button.cancel}}</el-button>
            <el-button
              type="primary"
              :disabled="form.name===''"
              @click="save"
            >{{$locale.main.button.save}}</el-button>
          </div>
        </el-form>
        <el-tooltip
          slot="reference"
          class="item"
          effect="dark"
          :content="$locale.access_editor.add_role"
          placement="bottom"
        >
          <el-button style="display: block" size="small" icon="el-icon-plus" circle></el-button>
        </el-tooltip>
      </el-popover>
    </div>
    <el-table
      :data="listRoles"
      style="width: 100%"
      class="custom_scrollbar"
      v-loading="loading"
      border
      height="calc(100vh - 228px)"
      highlight-current-row
    >
      <el-table-column width="120" :label="$locale.access_editor.roles_table.choose_role">
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.isEnabled"  @change="handleCheckAllChange(scope.row)"></el-checkbox>
          <!-- <el-button @click="handleCurrentChange(scope.row.id)" size="mini">Select</el-button> -->
        </template>
      </el-table-column>
      <el-table-column prop="name" :label="$locale.access_editor.roles_table.name_role" width="180"></el-table-column>

      <el-table-column
        prop="is_readable_by_default"
        :label="$locale.access_editor.roles_table.read"
        width="180"
      >
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.is_readable_by_default ? 'success' : 'danger'"
          >{{scope.row.is_readable_by_default ? $locale.access_editor.yes : $locale.access_editor.no}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="is_addable_by_default"
        :label="$locale.access_editor.roles_table.add"
        width="180"
      >
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.is_addable_by_default ? 'success' : 'danger'"
          >{{scope.row.is_addable_by_default ? $locale.access_editor.yes : $locale.access_editor.no}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="is_editable_by_default"
        :label="$locale.access_editor.roles_table.edit"
        width="180"
      >
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.is_editable_by_default ? 'success' : 'danger'"
          >{{scope.row.is_editable_by_default ? $locale.access_editor.yes : $locale.access_editor.no}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="is_deletable_by_default"
        :label="$locale.access_editor.roles_table.delete"
        width="180"
      >
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.is_deletable_by_default ? 'success' : 'danger'"
          >{{scope.row.is_deletable_by_default ? $locale.access_editor.yes : $locale.access_editor.no}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="is_menu_visible_by_default"
        :label="$locale.access_editor.roles_table.is_menu_visible_default"
        width="180"
      >
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.is_menu_visible_by_default ? 'success' : 'danger'"
          >{{scope.row.is_menu_visible_by_default ? $locale.access_editor.yes : $locale.access_editor.no}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column fixed="right" min-width="100px" :label="$locale.access_editor.roles_table.action">
        <template slot-scope="scope">
          <el-button
            @click.stop="editRow(scope.$index, scope.row)"
            icon="el-icon-edit"
            circle
            size="mini"
          ></el-button>
          <el-button
            @click.stop="deleteRow(scope.$index, scope.row)"
            type="danger"
            icon="el-icon-delete"
            circle
            size="mini"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="currentChange"
      :page-size="100"
      layout="total, prev, pager, next"
      :total="1000">
    </el-pagination>
    <!-- ----- -->
    <el-dialog
      width="30%"
      :title="$locale.access_editor.roles_table.add_role"
      :visible.sync="isDialog"
    >
      <el-form :model="form" size="small" label-position="left" label-width="35%">
        <el-form-item :label="$locale.access_editor.roles_table.name_role">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
          <el-checkbox
            :label="$locale.access_editor.roles_table.read"
            v-model="form.is_readable_by_default"
            name="type"
            size="medium"
          ></el-checkbox>
          <el-checkbox
            :label="$locale.access_editor.roles_table.add"
            v-model="form.is_addable_by_default"
            name="type"
            size="medium"
          ></el-checkbox>
          <el-checkbox
            :label="$locale.access_editor.roles_table.edit"
            v-model="form.is_editable_by_default"
            name="type"
            size="medium"
            style="margin-left: 0px"
          ></el-checkbox>
          <el-checkbox
            :label="$locale.access_editor.roles_table.delete"
            v-model="form.is_deletable_by_default"
            name="type"
            size="medium"
          ></el-checkbox>
          <el-checkbox
            :label="$locale.access_editor.roles_table.is_menu_visible_default"
            v-model="form.is_menu_visible_by_default"
            name="type"
            size="medium"
            style="margin-top: 15px; margin-bottom: 15px"
          ></el-checkbox>
          <el-form-item :label="$locale.access_editor.roles_table.root_menu_id">
            <el-select class="roles_widthSelect" clearable v-model="form.root_menu_id" size="mini">
              <el-option
                v-for="item in listMenus"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$locale.access_editor.roles_table.default_dashboard">
            <el-select
            class="roles_widthSelect"
            value-key="id"
            collapse-tags
            multiple
            filterable
            v-model="form.dashboards"
            size="mini">
              <el-option
                v-for="item in dashboards"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">{{$locale.main.button.cancel}}</el-button>
        <el-button
          type="primary"
          :disabled="form.name===''"
          @click="save"
        >{{$locale.main.button.save}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Role from '../Models/Role'
import Menu from '../Models/Menu'
import { eventBus } from '@/eventBus'
export default {
  name: 'Roles',
  data () {
    return {
      chooseRole: [],
      listMenus: [],
      value: '',
      isDialog: false,
      dialogFormVisible: false,
      editedIndex: -1,
      form: {
        name: '',
        is_readable_by_default: true,
        is_addable_by_default: true,
        is_editable_by_default: true,
        is_deletable_by_default: true,
        is_menu_visible_by_default: false,
        root_menu_id: null,
        dashboards: []
      },
      defaulForm: {
        name: '',
        is_readable_by_default: true,
        is_addable_by_default: true,
        is_editable_by_default: true,
        is_deletable_by_default: true,
        is_menu_visible_by_default: false,
        root_menu_id: null,
        dashboards: []
      },
      listRoles: [],
      dashboards: [],
      loading: false,
      selected: null
    }
  },
  watch: {
    selected: function (val) {
      if (val.id) eventBus.$emit('roleId', val)
    }
  },
  methods: {
    handleCheckAllChange (val) {
      this.listRoles.forEach(el => {
        if (el.isEnabled !== undefined && el.isEnabled) {
          if (el.id !== val.id) el.isEnabled = false
        }
      })
      this.selected = val
    },
    currentChange (val) {
      console.log(val)
    },
    close () {
      this.isDialog = false
      this.dialogFormVisible = false
      this.form = Object.assign({}, this.defaulForm)
      this.editedIndex = -1
    },
    async save () {
      // редактировать
      if (this.editedIndex > -1) {
        let role = new Role({ id: this.form.id }, this.form)
        role.root_menu_id = this.form.root_menu_id || null
        let response = await role.save()
        this.listRoles.splice(this.editedIndex, 1, response)
        this.close()
      } else {
        // новая роль
        let role = new Role(this.form)
        role.root_menu_id = this.form.root_menu_id || null
        let response = await role.save()
        if (response.id !== undefined) {
          this.listRoles.push(response)
          this.close()
        }
      }
    },
    editRow (index, row) {
      this.isDialog = true
      this.editedIndex = this.listRoles.indexOf(row)
      this.form = Object.assign({}, row)
    },
    deleteRow (index, row) {
      this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention,
        {
          confirmButtonText: this.$locale.main.button.delete,
          cancelButtonText: this.$locale.main.button.cancel,
          type: 'warning'
        }
      ).then(async () => {
        let role = new Role({ id: row.id })
        await role.delete()
        const ind = this.listRoles.indexOf(row)
        this.listRoles.splice(ind, 1)
      })
    }
  },
  async mounted () {
    try {
      this.loading = true
      let roles = await Role.get()
      let menu = await new Menu().params({ root: true }).$get()
      let dashboards = await this.$http.get(`${this.$config.api}/interfaceeditor/dashboards?fields=id,name`)
      this.dashboards = dashboards.data
      this.listMenus = menu
      this.listRoles = roles
      this.loading = false
    } catch (error) {
      this.loading = false
    }
  }
}
</script>

<style>
.roles_width {
  width: 100%;
  display: flex;
}
.roles_width2 .el-form-item__content{
  width: 85%;
  display: flex;
}
.roles_width .el-form-item__content {
  width: 80%;
  display: flex;
}
.roles_margin {
  margin-left: auto;
}
.roles_margin2 {
  margin-left: 15px;
  /* margin-right: 15px; */
}
.roles_group__btn {
  display: flex;
  justify-content: flex-end;
}
.roles_widthSelect {
  width: 162px;
}
/* .roles_height {
  height: calc(100vh-100px);
  overflow-y: auto
} */
</style>
