import DataModel from './DataModel'
export default class Record extends DataModel {
  resource () {
    return 'records'
  }

  request (config) {
    return this.$http.request(config)
  }
}
