export interface ApprovalState {
  isLoading: boolean
  location: string
  deletedApprovalGuid: string
  error: object
}

export const initialState = (): ApprovalState => {
  return {
    isLoading: false,
    location: null,
    deletedApprovalGuid: null,
    error: null
  }
}
