import { MutationTree } from 'vuex'
import { ConnectionState, initialState } from './state'
import { MutationTypes, ConnectionCreatedEvent, ConnectionUpdatedEvent, ConnectionDeletedEvent } from './mutation-types'

export const mutations: MutationTree<ConnectionState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false
    state.error = error
  },
  [MutationTypes.CONNECTION_LOADING_EVENT]: (state) => {
    state.isLoading = true
  },
  [MutationTypes.CONNECTION_LOADED_EVENT]: (state) => {
    state.isLoading = false
  },
  [MutationTypes.CONNECTION_CREATED_EVENT]: (state, action: ConnectionCreatedEvent) => {
    state.location = action.location
    state.isLoading = false
  },
  [MutationTypes.CONNECTION_UPDATED_EVENT]: (state, action: ConnectionUpdatedEvent) => {
    state.isLoading = false
  },
  [MutationTypes.CONNECTION_DELETED_EVENT]: (state, action: ConnectionDeletedEvent) => {
    state.isLoading = false
  }
}
