<template>
  <div class="query-builder">
    <query-builder-group
      v-if="rules.length > 0"
      v-model="model"
      :index="0"
      :labels="labels"
      :maxDepth="maxDepth"
      :depth="depth"
      :rules="rules"
      :components="components"
      :size-control="sizeControl"
      :filter-types="filterTypes"
    ></query-builder-group>
  </div>
</template>

<script>
import Vue from 'vue'
import QueryBuilderGroup from './QueryBuilderGroup.vue'
import deepClone from './utilities.js'

export default Vue.extend({
  name: 'QueryBuilder',

  components: {
    QueryBuilderGroup
  },

  props: {
    fields: {
      type: Array,
      default () {
        return []
      }
    },

    customRules: {
      type: Array,
      default () {
        return []
      }
    },

    filterTypes: {
      type: Array,
      default () {
        return [
          {
            value: 'constant',
            label: this.$t('main.query_builder.filter_types.constant')
          },
          {
            value: 'current_user',
            label: this.$t('main.query_builder.filter_types.current_user')
          },
          {
            value: 'current_datetime',
            label: this.$t('main.query_builder.filter_types.current_datetime')
          },
          {
            value: 'users_table',
            label: this.$t('main.query_builder.filter_types.users_table')
          },
          {
            value: 'parameter',
            label: this.$t('main.query_builder.filter_types.parameter')
          },
          {
            value: 'current_table_field',
            label: this.$t('main.query_builder.filter_types.current_table_field')
          },
          {
            value: 'external_table_field',
            label: this.$t('main.query_builder.filter_types.external_table_field')
          },
          {
            value: 'sql',
            label: this.$t('main.query_builder.filter_types.sql')
          },
          {
            value: 'component',
            label: this.$t('main.query_builder.filter_types.component')
          }
        ]
      }
    },

    components: {
      type: Array,
      default: () => []
    },

    labels: {
      type: Object,
      default () {
        return {
          matchType: this.$t('main.query_builder.labels.match_type'),
          matchTypes: [
            {
              id: 'and',
              label: this.$t('main.query_builder.labels.and')
            },
            {
              id: 'or',
              label: this.$t('main.query_builder.labels.or')
            }
          ],
          groupLabel: this.$t('main.query_builder.labels.group'),
          addRule: this.$t('main.query_builder.labels.add_rule'),
          removeRule: '<i class="el-icon-delete"></i>',
          addGroup: '<i class="el-icon-folder-add"></i>',
          removeGroup: '<i class="el-icon-delete"></i>',
          editGroup: '<i class="el-icon-edit"></i>',
          rulePlaceholder: this.$t('main.query_builder.labels.rule_placeholder'),
          filterTypePlaceholder: 'Filter type'
        }
      }
    },

    maxDepth: {
      type: Number,
      default: 5,
      validator: function (value) {
        return value >= 1
      }
    },

    value: {
      type: Object,
      default () {
        return {
          logical_operator: 'and',
          children: []
        }
      }
    },

    sizeControl: {
      type: String,
      default: 'mini'
    },

    operationTypes: {
      type: Array,
      required: true
    },

    fieldTypeToOperationType: {
      type: Object,
      required: true
    }
  },

  watch: {
    model: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.value)) {
          this.$emit('input', deepClone(value))
        }
      },
      deep: true
    },

    value: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.model)) {
          this.model = deepClone(value)
        }
      },
      deep: true
    },

    script: function (value) {
      this.$emit('changeScript', value)
    },

    query: function (value) {
      this.$emit('changeQuery', value)
    }
  },

  computed: {
    rules () {
      if (this.customRules.length > 0) {
        return this.customRules
      }

      return this.buildRules(this.fields)
    },

    script () {
      return this.buildScript([{ query: this.value, type: 'condition_group' }])
    },

    query () {
      return this.buildQuery([{ query: this.value, type: 'condition_group' }])
    }
  },

  data () {
    return {
      depth: 1,

      model: this.value || {
        logical_operator: 'and',
        children: []
      },

      mappedOperators: {
        eq: '==',
        neq: '!=',
        gt: '>',
        gte: '>=',
        lt: '<',
        lte: '<=',
        like: 'LIKE',
        not_like: 'NOT LIKE',
        in: '==', // (Есть в массиве) == true
        not_in: '!=', // (Есть в массиве) != true
        is_null: '==', // attr == null
        is_not_null: '!=' // attr != null
      },

      mappedLogicOperators: {
        and: '&&',
        or: '||'
      }
    }
  },

  methods: {
    buildRules (fields) {
      const rules = []

      for (const field of fields) {
        if (field.fieldTypeId !== 'field_group') {
          rules.push({
            id: field.id, // unique int
            alias: field.alias, // unique string
            objectId: field.objectId, // int | null
            fieldTypeId: field.fieldTypeId, // string
            label: `${field.name} (${field.alias})`, // string
            operators: this.buildOperators(field.fieldTypeId) // object[]
          })
        } else {
          rules.push(...this.buildRules(field.children))
        }
      }

      return rules
    },

    buildOperators (fieldTypeId) {
      const result = []

      const fieldTypeOperators = this.fieldTypeToOperationType[fieldTypeId] || ['eq', 'neq', 'is_null', 'is_not_null']

      this.operationTypes.forEach(operatorType => {
        if (fieldTypeOperators.includes(operatorType.id)) {
          result.push({
            id: operatorType.id,
            label: this.$t('bi_editor.operation_types.' + operatorType.name)
          })
        }
      })

      return result
    },

    getRules () {
      return this.rules
    },

    getScript () {
      return this.script
    },

    getQuery () {
      return this.query
    },

    buildScript (expressions, logicalOperator = null) {
      if (!expressions) {
        expressions = []
      }

      let script = ''

      for (const [i, expression] of expressions.entries()) {
        let field = `context.${expression.query.field}`

        const rule = this.rules.find(r => r.id === expression.query.field)
        if (rule && rule.fieldTypeId === 'xref_multi_field') {
          field = `${field}id`
        }

        if (expression.type === 'condition_group') {
          script += `(${this.buildScript(expression.query.children, expression.query.logical_operator)})`
        } else {
          if (['in', 'not_in'].includes(expression.query.operator)) {
            let value = '['
            if (Array.isArray(expression.query.value)) {
              value += expression.query.value.join(' ,')
            } else {
              value += expression.query.value
            }
            value += ']'
            if (typeof rule !== 'undefined' && rule.fieldTypeId === 'xref_multi_field') {
              script += `includes(${value}, ${field}) ${this.mappedOperators[expression.query.operator]} true`
            } else {
              script += `includes(${field}, ${value}) ${this.mappedOperators[expression.query.operator]} true`
            }
          } else if (['is_null', 'is_not_null'].includes(expression.query.operator)) {
            script += `field ${this.mappedOperators[expression.query.operator]} null`
          } else {
            let value = null

            if (expression.query.filter_type === 'constant') {
              if (typeof expression.query.value === 'string') {
                value = `'${expression.query.value}'`
              } else {
                value = expression.query.value
              }
            } else if (expression.query.filter_type === 'current_user') {
              value = `{current_user}`
            } else if (expression.query.filter_type === 'current_datetime') {
              value = `{current_datetime}`
            } else if (expression.query.filter_type === 'external_table_field') {
              value = `attr_${expression.query.value}_`
            } else if (expression.query.filter_type === 'current_table_field') {
              value = `attr_${expression.query.value}_`
            }

            script += `${field} ${this.mappedOperators[expression.query.operator]} ${value}`
          }
        }

        if (i < expressions.length - 1 && logicalOperator !== null) {
          script += ` ${this.mappedLogicOperators[logicalOperator]} `
        }
      }

      return script
    },

    buildQuery (conditions = [], level = 0) {
      let query = level === 0 ? {} : []

      for (let i = 0; i < conditions.length; i++) {
        const condition = conditions[i]

        if (condition.type === 'condition_group') {
          if (Array.isArray(query)) {
            let group = {}
            this.$set(group, condition.query.logical_operator, this.buildQuery(condition.query.children, level + 1))

            query.push(group)
          } else {
            this.$set(query, condition.query.logical_operator, this.buildQuery(condition.query.children, level + 1))
          }
        } else {
          const contains = { like: 'contains_string', not_like: 'not_contains_string' }
          const operator = condition.query.operator

          let parent = {}
          let child = {}

          if (['is_null', 'is_not_null'].includes(operator)) {
            this.$set(parent, operator, condition.query.field)
          } else if (['like', 'not_like'].includes(operator)) {
            this.$set(child, condition.query.field, condition.query.value)
            this.$set(parent, contains[operator], child)
          } else {
            this.$set(child, condition.query.field, condition.query.value)
            this.$set(parent, operator, child)
          }

          query.push(parent)
        }
      }

      return query
    }
  }
})
</script>

<style lang="scss">
  .query-builder {
    & > .vqb-group {
      margin-bottom: 20px;
    }

    & > .el-textarea .el-textarea__inner {
      font-size: 14px;
      font-weight: 400;
      color: #409eff;
    }

    & .vqb-group {
      padding: 5px 20px;
      position: relative;
      background-color: #f5f5f5;
      border-left: 2px solid #909090;

      & label {
        color: #ff5722;
      }

      & .rule-actions {
        margin-bottom: 20px;
      }

      &:before {
        content: " ";
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid #d2d45e;
        position: absolute;
        left: 0;
        top: 13px;
      }
    }

    & .vqb-rule {
      margin-top: 15px;
      margin-bottom: 15px;
      border-color: #ddd;
      padding: 15px;
      position: relative;
      background-color: #ececec;
      border-left: 2px solid #ddd;

      & label {
        margin-right: 10px;
        color: #409eff;
      }

      &:before {
        content: " ";
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid #ddd;
        position: absolute;
        left: 0;
        top: 22px;
      }
    }

    & .vqb-rule,
    & .vqb-group {
      padding-right: 0;
    }

    & .vqb-group.depth-1 {
      border-left: 2px solid #fcbe53;

      &:before {
        border-left-color: #fcbe53;
      }
    }

    & .vqb-group.depth-1 .vqb-rule,
    & .vqb-group.depth-2 {
      border-left: 2px solid #d2d45e;

      &:before {
        border-left-color: #d2d45e;
      }
    }

    & .vqb-group.depth-2 .vqb-rule,
    & .vqb-group.depth-3 {
      border-left: 2px solid #8bc34a;

      &:before {
        border-left-color: #8bc34a;
      }
    }

    & .vqb-group.depth-3 .vqb-rule,
    & .vqb-group.depth-4 {
      border-left: 2px solid #20bae9;

      &:before {
        border-left-color: #20bae9;
      }
    }

    & .vqb-group.depth-4 .vqb-rule,
    & .vqb-group.depth-5 {
      border-left: 2px solid #0071d4;

      &:before {
        border-left-color: #0071d4;
      }
    }

    & .vqb-group.depth-5 .vqb-rule {
      border-left: 2px solid #ff5722;

      &:before {
        border-left-color: #ff5722;
      }
    }

    & .close {
      opacity: 1;
      color: rgb(150,150,150);
    }

    & div:not(.input-group) .el-input-number {
      width: 100%;
    }

    & .vqb-rule .el-form-item {
      margin-bottom: 0;
    }

    & .vqb-child {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
</style>
