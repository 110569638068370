import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'

export namespace SyncAPI {
  export class GetExportStructure implements APIRequest<{}> {
    response: {};
    path: string;
    method = HTTPMethod.GET;
    constructor (id: number) {
      this.path = '/api/sync/export/' + id
    }
  }
}
