import { ActionTree } from 'vuex'
import { FeatureState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, FeatureUpdatedEvent, FeatureLoadingEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { FeatureAPI } from '@/services/MapEditor/infrastructure/api/FeatureAPI'

export const actions: ActionTree<FeatureState, any> = {
    [ActionTypes.UPDATE_FEATURE]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new FeatureAPI.UpdateFeature(data.payload));            
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    }
}
