import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import TaskQueryRepositoryInterface from '../../../domain/repository/TaskQueryRepositoryInterface'
import TasksCountQuery from '../../query/TasksCountQuery'

export default class TasksCountHandler implements QueryHandlerInterface {
    private repo: TaskQueryRepositoryInterface;

    constructor (repo: TaskQueryRepositoryInterface) {
      this.repo = repo
    }

    execute (query: TasksCountQuery): Promise<any> {
      return this.repo.getCount(query.getPayload())
    }
}
