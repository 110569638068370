import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { ModelFileDTO, TransformFileDTO } from '@/services/DigitalTwinEditor/domain/model/File/ModelFile'

export namespace ModelFileAPI {

    export class AttachFileToModel implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.headers.location;
        hasFiles = true;
        constructor(public params: ModelFileDTO) {
            this.path = `/digitaltwineditor/files?id=${params.model_id}`
        }
    }

    export class DeleteModelFile implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.DELETE;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string, public params: string[][]) {
            this.path = `/digitaltwineditor/files/${guid}`;
        }
    }

    export class GetFileByGuid implements APIRequest<ModelFileDTO> {
        response: ModelFileDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/digitaltwineditor/files/${guid}`;
        }
    }

    export class TransformFile implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: TransformFileDTO) {
            this.path = `/digitaltwineditor/files/${params.guid}/transform`;
        }
    }
}