import ModelCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/ModelCommandRepositoryInterface'
import { ModelDTO } from '@/services/DigitalTwinEditor/domain/model/Model/Model'
import store from '@/store'
import { UpdateModel } from '@/services/DigitalTwinEditor/infrastructure/store/modules/Model/action-types'

export default class ModelCommandRepository implements ModelCommandRepositoryInterface
{
    update(item: ModelDTO): Promise<void>
    {
        return store.dispatch(new UpdateModel(item));
    }
}
