import { FluxStandardAction } from '@/core/domain/type/types'
import { TaskDTO } from '@/services/EtlEditor/domain/model/Task'

export enum ActionTypes {
  GET_TASK_BY_GUID = 'get_task_by_id',
  CREATE_TASK = 'create_task',
  UPDATE_TASK = 'update_task',
  DELETE_TASK = 'delete_task',
  GET_TASK_OBJECTS = 'get_task_objects',
  GET_TASK_OBJECT_BY_TASK_ID = 'get_task_object_by_task_id'
}

export class GetTaskByGuid implements FluxStandardAction {
  type = 'Task/' + ActionTypes.GET_TASK_BY_GUID
  constructor (public guid: string) {}
}

export class CreateTask implements FluxStandardAction {
  type = 'Task/' + ActionTypes.CREATE_TASK
  constructor (public payload: TaskDTO) {}
}

export class UpdateTask implements FluxStandardAction {
  type = 'Task/' + ActionTypes.UPDATE_TASK
  constructor (public payload: TaskDTO) {}
}

export class DeleteTask implements FluxStandardAction {
  type = 'Task/' + ActionTypes.DELETE_TASK
  constructor (public guid: string) {}
}

export class GetTaskObjects implements FluxStandardAction {
  type = 'Task/' + ActionTypes.GET_TASK_OBJECTS
  constructor (public payload: object) {}
}

export class GetTaskObjectByTaskId implements FluxStandardAction {
  type = 'Task/' + ActionTypes.GET_TASK_OBJECT_BY_TASK_ID
  constructor (public taskId: number) {}
}
