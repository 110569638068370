import { ActionTree } from 'vuex'
import { LogicTreeElementState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, LogicTreeElementLoadingEvent, LogicTreeElementLoadedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { LogicTreeElementAPI } from '@/services/LogicEditor/infrastructure/api/LogicTreeElementAPI'

export const actions: ActionTree<LogicTreeElementState, any> = {
  [ActionTypes.GET_LOGIC_TREE_ELEMENTS]: async ({ commit, dispatch }, data) => {
    commit(new LogicTreeElementLoadingEvent())

    return APIClient.shared.request(new LogicTreeElementAPI.GetLogicTreeElements(data.payload))
      .then((response) => {
        commit(new LogicTreeElementLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.GET_LOGIC_TREE_ELEMENTS_TREE]: async ({ commit, dispatch }, data) => {
    commit(new LogicTreeElementLoadingEvent())

    return APIClient.shared.request(new LogicTreeElementAPI.GetLogicTreeElementsTree(data.payload))
      .then((response) => {
        commit(new LogicTreeElementLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.SEARCH_LOGIC_TREE_ELEMENTS]: async ({ commit, dispatch }, data) => {
    commit(new LogicTreeElementLoadingEvent())

    return APIClient.shared.request(new LogicTreeElementAPI.SearchLogicTreeElements(data.payload))
      .then((response) => {
        commit(new LogicTreeElementLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.GET_LOGIC_TREE_ELEMENT_BY_GUID]: async ({ commit, dispatch }, data) => {
    commit(new LogicTreeElementLoadingEvent())

    return APIClient.shared.request(new LogicTreeElementAPI.GetLogicTreeElementByGuid(data.guid))
      .then((response) => {
        commit(new LogicTreeElementLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.GET_LOGIC_TREE_ELEMENT_BY_ELEMENT_GUID]: async ({ commit, dispatch }, data) => {
    commit(new LogicTreeElementLoadingEvent())

    return APIClient.shared.request(new LogicTreeElementAPI.GetLogicTreeElementByElementGuid(data.elementGuid))
      .then((response) => {
        commit(new LogicTreeElementLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  }
}
