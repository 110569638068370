import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    PROJECT_TREE_ELEMENT_LOADING_EVENT = 'project_tree_element_loading_event',
    PROJECT_TREE_ELEMENT_CREATED_EVENT = 'project_tree_element_created_event',
    PROJECT_TREE_ELEMENT_DELETED_EVENT = 'project_tree_element_deleted_event',
    PROJECT_TREE_ELEMENT_UPDATED_EVENT = 'project_tree_element_updated_event'      
}

export class ProjectTreeElementLoadingEvent implements FluxStandardAction {
    type = MutationTypes.PROJECT_TREE_ELEMENT_LOADING_EVENT
}

export class ProjectTreeElementCreatedEvent implements FluxStandardAction {
    type = MutationTypes.PROJECT_TREE_ELEMENT_CREATED_EVENT
    constructor(public location: string){}
}

export class ProjectTreeElementDeletedEvent implements FluxStandardAction {
    type = MutationTypes.PROJECT_TREE_ELEMENT_DELETED_EVENT
    constructor(public guid: string){}
}

export class ProjectTreeElementUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.PROJECT_TREE_ELEMENT_UPDATED_EVENT
}