import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    LOGIC_TREE_ELEMENT_LOADING_EVENT = 'logic_tree_element_loading_event',
    LOGIC_TREE_ELEMENT_LOADED_EVENT = 'logic_tree_element_loaded_event'
}

export class LogicTreeElementLoadingEvent implements FluxStandardAction {
  type = MutationTypes.LOGIC_TREE_ELEMENT_LOADING_EVENT
}

export class LogicTreeElementLoadedEvent implements FluxStandardAction {
  type = MutationTypes.LOGIC_TREE_ELEMENT_LOADED_EVENT
}
