<template>
  <div class="el-tree-select" :class="selectClass">
    <el-select
      ref="select"
      v-model="localValue"
      v-popover:popover
      :style="styles"
      class="el-tree-select-input"
      popper-class="select-option"
      :disabled="disabled"
      :clearable="clearable"
      :multiple="multiple"
      :placeholder="placeholder"
      :collapse-tags="collapseTags"
      :filterable="false"
      :popper-append-to-body="false"
      @clear="_clear"
      @remove-tag="_removeTag"
      @focus="_show()">
      <el-option
        v-for="item in optionList"
        :value="item.id"
        :label="item.label"
        :key="item.id">
      </el-option>
    </el-select>
    <el-popover
      ref="popover"
      :placement="placement"
      :popper-class="popperClass"
      v-model="visible"
      :width="popoverWidth"
      trigger="click">
      <el-scrollbar tag="div" wrap-class="el-tree-select-dropdown__wrap" view-class="el-tree-select-dropdown__tree" class="is-empty">
        <el-tree
          ref="tree"
          v-bind="treeProps"
          @check-change="_checkNode"
        ></el-tree>
      </el-scrollbar>
    </el-popover>
  </div>
</template>

<script>
const defaultProps = {
  children: 'children',
  isLeaf: 'isLeaf',
  label: 'label',
  disabled: 'disabled'
}

export default {
  name: 'TreeSelect',

  props: {
    value: [Number, Array, String],

    filter: {
      type: Array,
      default () {
        return []
      }
    },

    nodeKey: {
      type: String,
      default: 'id'
    },

    multiple: {
      type: Boolean,
      default: false
    },

    data: {
      type: Array,
      default () {
        return []
      }
    },

    lazy: {
      type: Boolean,
      default: false
    },

    load: {
      type: Function,
      default: function (node, resolve) {
      }
    },

    props: {
      type: Object,
      default () {
        return defaultProps
      }
    },

    renderContent: {
      type: Function,
      default: function (h, { node, data, store }) {
        return h('span', {
          class: {
            'custom-tree-node': true
          }
        }, [
          h('span', node.label)
        ])
      }
    },

    renderOptionLabel: {
      type: Function,
      default: function (item) {
        return item[this.mergedProps.label]
      }
    },

    options: {
      type: Array,
      default () {
        return []
      }
    },

    size: String,

    disabled: {
      type: Boolean,
      default: false
    },

    collapseTags: {
      type: Boolean,
      default: true
    },

    clearable: {
      type: Boolean,
      default: false
    },

    placeholder: {
      type: String,
      default: null
    },

    popoverStyles: {
      type: Object,
      // {}
      default () {
        return {
          width: '100%'
        }
      }
    },

    styles: {
      type: Object,
      // {}
      default () {
        return {
          width: '100%'
        }
      }
    },

    selectClass: {
      type: String,
      default () {
        return ''
      }
    },

    popoverClass: {
      type: String,
      default () {
        return ''
      }
    },

    placement: {
      type: String,
      //  bottom
      default () {
        return 'bottom-start'
      }
    }
  },

  computed: {
    treeProps () {
      const treeProps = {
        props: this.props,
        nodeKey: this.nodeKey,
        size: this.size,
        renderContent: this.renderContent,
        defaultCheckedKeys: this.defaultCheckedKeys,
        defaultExpandedKeys: [],
        accordion: false,
        showCheckbox: true,
        disabled: true,
        checkOnClickNode: true,
        checkStrictly: true,
        expandOnClickNode: true
      }

      if (this.lazy) {
        treeProps['lazy'] = true
        treeProps['load'] = this.load
      } else {
        treeProps['data'] = this.data || []
      }

      return treeProps
    },

    popperClass () {
      let _c = 'el-tree-select-popper ' + this.popoverClass
      return this.disabled ? _c + ' disabled ' : _c
    },

    optionList () {
      return this.lazy ? this.options : this._buildOptions(this.data)
    },

    mergedProps () {
      return Object.assign({}, defaultProps, this.props)
    },

    defaultCheckedKeys () {
      let checkedKeys = []
      if (this.value !== null) {
        checkedKeys = this.multiple ? this.value : [this.value]
      }
      return checkedKeys
    }
  },

  data () {
    return {
      localValue: this.value,
      popoverWidth: 400,
      visible: false
    }
  },

  watch: {
    value () {
      this.localValue = this.value
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.popoverWidth = this.$refs.select ? this.$refs.select.$el.getBoundingClientRect().width - 25 : 400
    })
  },

  methods: {
    _show () {
      this.popoverWidth = this.$refs.select ? this.$refs.select.$el.getBoundingClientRect().width - 25 : 400
      // this.visible = true
    },

    _buildOptions (items) {
      let options = []

      items.forEach((item) => {
        if (item[this.mergedProps.children].length > 0) {
          options.push(...this._buildOptions(item[this.mergedProps.children]))
        } else {
          options.push({
            id: item[this.nodeKey],
            label: this.renderOptionLabel(item)
          })
        }
      })

      return options
    },

    _checkNode (node, checked) {
      const oldValue = this.localValue

      if (checked) {
        if (this.multiple) {
          if (!Array.isArray(this.localValue)) {
            this.localValue = []
          }
          this.localValue.push(node[this.nodeKey])
          this.$refs.tree.setCheckedKeys(this.localValue)
          this.$refs.select.focus()
        } else {
          this.localValue = node[this.nodeKey]
          this.$refs.tree.setCheckedKeys([this.localValue])
        }
      } else {
        if (this.multiple) {
          let index = this.localValue.indexOf(node[this.nodeKey])
          if (index !== -1) {
            this.localValue.splice(index, 1)
          }
        } else {
          if (this.localValue === node[this.nodeKey]) {
            this.localValue = null
          }
        }
      }

      if (oldValue !== this.localValue) {
        this.$emit('change', this.localValue)
      }

      this.$emit('input', this.localValue)

      this.$nextTick(() => {
        if (!this.multiple && checked) {
          this.visible = false
        }
      })
    },

    _clear () {
      this.localValue = null
      this.$refs.tree.setCheckedKeys([])
      this.$emit('clear')
    },

    _removeTag (value) {
      if (this.multiple) {
        let index = this.localValue.indexOf(value)
        if (index !== -1) {
          this.localValue.splice(index, 1)
        }

        this.$refs.tree.setCheckedKeys(this.localValue)
        this.$emit('remove-tag', value)
      }
    }
  }
}
</script>

<style lang="scss">
  .el-tree-select {
    & .select-option {
      display: none !important;
    }

    [aria-disabled='true'] > .el-tree-node__content {
      color: inherit !important;
      background: transparent !important;
      cursor: no-drop !important;
    }
  }

  .el-tree-select-popper {
    max-height: 400px;
    overflow: auto;

    .el-tree-select-dropdown__wrap {
      max-height: 274px;
    }

    .el-tree-node > .el-tree-node__children {
      overflow: visible;
    }

    .el-tree-select-dropdown__tree {
      list-style: none;
      padding: 6px 0;
      margin: 0;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }

    .el-tree-node__attribute {
      font-weight: 500;
      color: #9a9b9f;
    }

    .el-tree-node__children {
      border-left: 2px solid #4FA9F3;
      margin-left: 5px;
    }

    &.disabled {
      display: none !important;
    }

    & .el-button--small {
      width: 25px !important;
      min-width: 25px !important;
    }

    &[x-placement^='bottom'] {
      margin-top: 5px;
    }
  }
</style>
