import { Nullable } from '@/core/domain/type/types'

export default class ProjectTreeElementGroupCreateCommand
{
    private name: string;

    private parentId: Nullable<number>;

    constructor(name: string,  parentId: Nullable<number>)
    {
        this.name = name;
        this.parentId = parentId;
    }

    getClassName(): string
    {
        return 'ProjectTreeElementGroupCreateCommand';
    }

    getName(): string
    {
        return this.name;
    }

    getParentId(): Nullable<number>
    {
        return this.parentId;
    }
}