import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
  ERROR = 'error',
  LOGS_LOADING_EVENT = 'logs_loading_event',
  LOGS_LOADED_EVENT = 'logs_loaded_event'
}

export class LogLoadingEvent implements FluxStandardAction {
  type = MutationTypes.LOGS_LOADING_EVENT
}

export class LogLoadedEvent implements FluxStandardAction {
  type = MutationTypes.LOGS_LOADED_EVENT
}
