import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import OpenWorldCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/OpenWorldCommandRepositoryInterface'
import SetTerrainCommand from '@/services/DigitalTwinEditor/application/command/SetTerrainCommand'
import { OpenWorldDTO } from '@/services/DigitalTwinEditor/domain/model/OpenWorld/OpenWorld'

export default class SetTerrainHandler implements CommandHandlerInterface
{
    private repo: OpenWorldCommandRepositoryInterface;

    constructor(repo: OpenWorldCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: SetTerrainCommand): Promise<void>
    {
        let item: OpenWorldDTO = {
            guid: command.getGuid()
        };
        return this.repo.setTerrain(item);
    }
}