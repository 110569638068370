import { FluxStandardAction } from '@/core/domain/type/types'
import { LoaderDTO } from '@/services/EtlEditor/domain/model/Loader'

export enum ActionTypes {
  GET_LOADER = 'get_loader',
  GET_LOADER_BY_GUID = 'get_loader_by_guid',
  GET_LOADER_TYPES = 'get_loader_types',
  GET_LOADER_TYPE_PROPERTIES = 'get_loader_type_properties',
  CREATE_LOADER = 'create_loader',
  UPDATE_LOADER = 'update_loader',
  DELETE_LOADER = 'delete_loader'
}

export class GetLoaders implements FluxStandardAction {
  type = 'Loader/' + ActionTypes.GET_LOADER
  constructor (public payload: object) {}
}

export class GetLoaderByGuid implements FluxStandardAction {
  type = 'Loader/' + ActionTypes.GET_LOADER_BY_GUID
  constructor (public guid: string) {}
}

export class GetLoaderTypes implements FluxStandardAction {
  type = 'Loader/' + ActionTypes.GET_LOADER_TYPES
  constructor () {}
}

export class GetLoaderTypeProperties implements FluxStandardAction {
  type = 'Loader/' + ActionTypes.GET_LOADER_TYPE_PROPERTIES
  constructor (public loaderTypeId: string) {}
}

export class CreateLoader implements FluxStandardAction {
  type = 'Loader/' + ActionTypes.CREATE_LOADER
  constructor (public payload: LoaderDTO) {}
}

export class UpdateLoader implements FluxStandardAction {
  type = 'Loader/' + ActionTypes.UPDATE_LOADER
  constructor (public payload: LoaderDTO) {}
}

export class DeleteLoader implements FluxStandardAction {
  type = 'Loader/' + ActionTypes.DELETE_LOADER
  constructor (public guid: string) {}
}
