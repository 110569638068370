export interface IResource {
  name: string
  code: string
}

export default class ResourceDTO {
  name: string
  code: string

  constructor (data: IResource) {
    this.name = data.name
    this.code = data.code
  }
}
