<template>
  <div :style="css">
    <span>
      <el-link
        style="white-space: pre;"
        icon="el-icon-document"
        @click="download(value)"
        v-for="(value, index) in localValue"
        type="primary" :key="index">
        {{ value.name }}
      </el-link>
    </span>
  </div>
</template>

<script>
export default {
  props: ['value', 'css'],
  name: 'file_field',
  computed: {
    localValue () {
      let parsed = JSON.parse(this.value || '[]')
      if (parsed instanceof Array && parsed.length > 0) {
        return parsed.map(file => {
          return {
            name: this.getFileName(file),
            url: `${this.$config.api}/files/${this.getFilePath(file)}`
          }
        })
      }

      return []
    }
  },
  methods: {
    download (file) {
      let link = document.createElement('a')
      let nameFile = ''
      if (file?.name) {
      // /\.(?=.*\.)/g, '' - убрать все точки кроме последней.
      // /,/g, '' - убрать запятые
        nameFile = file.name.replace(/\.(?=.*\.)/g, '').replace(/,/g, '')
      }
      link.setAttribute('href', `${file.url}?download=${nameFile}`)
      link.setAttribute('download', `${file.name}`)
      link.setAttribute('target', '_blank')
      console.log(link)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
</script>

<style scoped>

</style>
