import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import OpenWorldCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/OpenWorldCommandRepositoryInterface'
import SetLandscapeCommand from '@/services/DigitalTwinEditor/application/command/SetLandscapeCommand'
import { OpenWorldDTO } from '@/services/DigitalTwinEditor/domain/model/OpenWorld/OpenWorld'

export default class SetLandscapeHandler implements CommandHandlerInterface
{
    private repo: OpenWorldCommandRepositoryInterface;

    constructor(repo: OpenWorldCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: SetLandscapeCommand): Promise<void>
    {
        let item: OpenWorldDTO = {
            guid: command.getGuid(),
            min_zoom: command.getMinZoom(),
            max_zoom: command.getMaxZoom(),
            num_threads: command.getNumThreads(),
            force_tiling: command.getForceTiling(),
            apply_new_styles: command.getApplyNewStyles()
        };
        return this.repo.setLandscape(item);
    }
}