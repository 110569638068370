<template>
  <div>
    <el-row style="margin: 10px 0;">
      <el-col :span="22" style="padding: 5px;">
        {{label}}
      </el-col>
      <el-col :span="2">
        <el-popover
          placement="right"
          title="Выберите действие"
          width="250"
          trigger="click">
          <div @click="createStyle" class="button-context-menu">Создать новый стиль</div>
          <!--<div @click="editStyle" class="button-context-menu">Редактировать выбранный стиль</div>-->
          <el-button slot="reference" class="add-button" icon="el-icon-more" circle size="mini"></el-button>
        </el-popover>

      </el-col>
    </el-row>
    <el-row>
      <el-col :span="22">
        <el-select
          v-model="localValue"
          clearable
        >
          <el-option
            v-for="option in styles"
            :key="option.guid"
            :label="option.name"
            :value="option.id"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { APIClient } from '@/core/infrastructure/api/APIClient'
  import { StyleAPI } from '@/services/MapEditor/infrastructure/api/StyleAPI'

  const TYPES = {
    'point': {
      label: 'Точка'
    },
    'linestring': {
      label: 'Линия'
    },
    'polygon': {
      label: 'Полигон'
    },
    'label': {
      label: 'Подсказка'
    }
  }

  export default {
    name: 'StyleSelect',
    props: {
      value: {
        type: Number,
        default: null
      },
      type: {
        type: String,
        default: 'point'
      }
    },
    computed: {
      label: function () {
        const key = Object.keys(this.types).find(x => x === this.type)
        if (!key) {
          return 'Не определено'
        }
        const type = this.types[key]
        return type ? type.label : 'Не определено'
      }
    },
    data: function () {
      return {
        localValue: this.value,
        styles: [],
        types: TYPES
      }
    },
    watch: {
      localValue: {
        handler: function (newValue) {
          this.$emit('change', newValue)
        }
      }
    },
    mounted () {
      this.loadOptions()
    },
    methods: {
      createStyle () {
        this.$emit('create-style', this.type)
      },
      editStyle () {
        this.$emit('edit-style', this.styles.find(x=>x.id), this.type)
      },
      loadOptions () {
        const me = this
        APIClient.shared.request(
          new StyleAPI.GetStyles({ style_type_id: this.type })
        ).then(r => me.styles = r)
      }
    }
  }
</script>

<style scoped>
.add-button {
  padding: 3px;
}
.button-context-menu {
  width: calc(100% - 20px);
  padding: 5px 10px;
  cursor: pointer;
}
.button-context-menu:hover {
  background-color: #F5F7FA;
}
</style>
