export default {
  namespaced: true,
  state: {
    notify: ''
  },
  mutations: {
    readMsg (state, payload) {
      state.notify = payload
    }
  },
  getters: {
    getNotify (state) {
      return state.notify
    }
  }
}
