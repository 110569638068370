import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { SettingDTO } from '@/services/Databus/domain/model/Setting/Setting'

export enum ActionTypes {
    ADD_SETTING = 'add_setting',
    UPDATE_SETTING = 'update_setting',
    DELETE_SETTING = 'delete_setting',
    GET_SETTINGS = 'get_settings',
    COUNT_SETTINGS = 'count_settings',
}

export class AddSetting implements FluxStandardAction {
    type = 'Setting/' + ActionTypes.ADD_SETTING
    constructor(public payload: SettingDTO){}
}

export class UpdateSetting implements FluxStandardAction {
    type = 'Setting/' + ActionTypes.UPDATE_SETTING
    constructor(public payload: SettingDTO){}
}

export class DeleteSetting implements FluxStandardAction {
    type = 'Setting/' + ActionTypes.DELETE_SETTING
    constructor(public guid: string){}
}

export class GetSettings implements FluxStandardAction {
    type = 'Setting/' + ActionTypes.GET_SETTINGS
    constructor(public payload: object){}
}

export class CountSettings implements FluxStandardAction {
    type = 'Setting/' + ActionTypes.COUNT_SETTINGS
    constructor(public payload: object){}
}
