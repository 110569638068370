import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'
import NameTooLong from '@/core/domain/exception/NameTooLong'
import NameMustNotBeEmpty from '@/core/domain/exception/NameMustNotBeEmpty'

export class AffineTransformationDTO
{
    id?: Nullable<number>;
    guid?: string;
    name?: string;
    description?: string;
    x0?: Nullable<number> = 0;
    y0?: Nullable<number> = 0;
    z0?: Nullable<number>;
    a?: Nullable<number> = 0;
    b?: Nullable<number> = 0;
    c?: Nullable<number> = 0;
    d?: Nullable<number> = 0;
    e?: Nullable<number> = 0;
    f?: Nullable<number> = 0;
    g?: Nullable<number>;
    h?: Nullable<number>;
    i?: Nullable<number>;    
    project_id?: number;
}

export default class AffineTransformation extends AbstractBaseEntity
{
    private id?: Nullable<number>;
    private guid?: Nullable<string>;
    private name: string;
    private description: string;
    private x0: number;
    private y0: number;
    private z0: Nullable<number>;
    private a: number;
    private b: number;
    private c: number;
    private d: number;
    private e: number;
    private f: number;
    private g?: Nullable<number>;
    private h?: Nullable<number>;
    private i?: Nullable<number>;

    constructor(
        id: Nullable<number>, 
        guid: Nullable<string>, 
        name: string, 
        description: string,
        x0: number,
        y0: number,
        z0: Nullable<number>,
        a: number,
        b: number,
        c: number,
        d: number,
        e: number,
        f: number,
        g: Nullable<number>,
        h: Nullable<number>,
        i: Nullable<number>
    ) {
        super();
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.description = description;
        this.x0 = x0;
        this.y0 = y0;
        this.z0 = z0;
        this.a = a;
        this.b = b;
        this.c = c;
        this.d = d;
        this.e = e;
        this.f = f;
        this.g = g;
        this.h = h;
        this.i = i;
        this.assertInvariants();
    }

    static create(dto: AffineTransformationDTO): AffineTransformation
    {
        return new AffineTransformation(
            dto.id,
            dto.guid,
            dto.name,
            dto.description,
            dto.x0,
            dto.y0,
            dto.z0,
            dto.a,
            dto.b,
            dto.c,
            dto.d,
            dto.e,
            dto.f,
            dto.g,
            dto.h,
            dto.i
        );
    }

    setId(id: number): void
    {
        this.id = id;
    }

    getId(): number
    {
        return this.id;
    }

    setGuid(guid: string): void
    {
        this.guid = guid;
    }

    getGuid(): string
    {
        return this.guid;
    }

    setName(name: string): void
    {
        if (name.length > 255) {
            throw new NameTooLong();
        }
        this.name = name;
    }

    getName(): string
    {
        return this.name;
    }

    assertInvariants(): void
    {
        if (this.name.length == 0) {
            throw new NameMustNotBeEmpty();
        }

        if (this.name.length > 255) {
            throw new NameTooLong();
        }
    }

    setDescription(description: string): void
    {
        this.description = description;
    }

    getDescription(): string
    {
        return this.description;
    }

    setX0(x0: number): void
    {
        this.x0 = x0;
    }

    getX0(): number
    {
        return this.x0;
    }

    setY0(y0: number): void
    {
        this.y0 = y0;
    }

    getY0(): number
    {
        return this.y0;
    }

    setZ0(z0: Nullable<number>): void
    {
        this.z0 = z0;
    }

    getZ0(): Nullable<number>
    {
        return this.z0;
    }

    setA(a: number): void
    {
        this.a = a;
    }

    getA(): number
    {
        return this.a;
    }

    setB(b: number): void
    {
        this.b = b;
    }

    getB(): number
    {
        return this.b;
    }

    setC(c: number): void
    {
        this.c = c;
    }

    getC(): number
    {
        return this.c;
    }

    setD(d: number): void
    {
        this.d = d;
    }

    getD(): number
    {
        return this.d;
    }

    setE(e: number): void
    {
        this.e = e;
    }

    getE(): number
    {
        return this.e;
    }

    setF(f: number): void
    {
        this.f = f;
    }

    getF(): number
    {
        return this.f;
    }

    setG(g: Nullable<number>): void
    {
        this.g = g;
    }

    getG(): Nullable<number>
    {
        return this.g;
    }

    setH(h: Nullable<number>): void
    {
        this.h = h;
    }

    getH(): Nullable<number>
    {
        return this.h;
    }

    setI(i: Nullable<number>): void
    {
        this.i = i;
    }

    getI(): Nullable<number>
    {
        return this.i;
    }
}
