import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    BIND_SERVICE_LOADING_EVENT = 'bind_service_loading_event',
    BIND_SERVICE_LOADED_EVENT = 'bind_service_loaded_event',
    BIND_SERVICE_CREATED_EVENT = 'bind_service_created_event',
    BIND_SERVICE_DELETED_EVENT = 'bind_service_deleted_event'
}

export class BindServiceLoadingEvent implements FluxStandardAction {
    type = MutationTypes.BIND_SERVICE_LOADING_EVENT
}

export class BindServiceLoadedEvent implements FluxStandardAction {
    type = MutationTypes.BIND_SERVICE_LOADED_EVENT
}

export class BindServiceCreatedEvent implements FluxStandardAction {
    type = MutationTypes.BIND_SERVICE_CREATED_EVENT
    constructor(public location: string){}
}

export class BindServiceDeletedEvent implements FluxStandardAction {
    type = MutationTypes.BIND_SERVICE_DELETED_EVENT
    constructor(public guid: string){}
}