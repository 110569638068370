<template>
  <div v-if="value.properties">
    <editor-button-action
      :value='value.properties'
      :is-button="true"
      @change="$set(value, 'properties', $event)">
    </editor-button-action>
  </div>
</template>

<script>

import EditorButtonAction from '@/components/InterfaceEditor/components/editor/editor-button-action'
export default {
  name: 'CustomButtonEditor',
  components: { EditorButtonAction },
  props: {
    value: {
      type: Object
    }
  }
}
</script>

<style scoped>

</style>
