import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ProjectTreeElementCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/ProjectTreeElementCommandRepositoryInterface'
import ProjectTreeElementProjectCreateCommand from '@/services/DigitalTwinEditor/application/command/ProjectTreeElementProjectCreateCommand'
import { ProjectDTO } from '@/services/DigitalTwinEditor/domain/model/Project/Project'

export default class ProjectTreeElementProjectCreateHandler implements CommandHandlerInterface
{
    private repo: ProjectTreeElementCommandRepositoryInterface;

    constructor(repo: ProjectTreeElementCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: ProjectTreeElementProjectCreateCommand): Promise<void>
    {
        const item: ProjectDTO = {
            name: command.getName(),
            alias: command.getAlias(),
            description: command.getDescription(),
            parent_id: command.getParentId()
        };
        return this.repo.insertProject(item);
    }
}