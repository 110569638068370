<template>
    <div>
        <ol id="main-info">
            <li v-for="(item, i) of data" :key="i">
                <div v-if="item.type=='file_field'">
                    <p><span style="font-weight: bold">{{item.alias}}</span>:</p>
                    <file v-bind:value="item.value"></file>
                </div>
                <div v-else>
                    <p><span style="font-weight: bold">{{item.alias}}</span>: {{item.value}}</p>
                </div>
            </li>
        </ol>
    </div>

</template>

<script>

import file from "./file_field.vue";
export default {
    name: 'Info',
    props: ['data'],
    components: {
        file
    }
}
</script>

<style>
#main-info{
    padding: 20px 30px;
    border-radius: 2px;
    border: 1px solid #eee;
    margin: 0;
}
 ol{
   padding-left: 20px;
 }
</style>
