import { ActionTree } from 'vuex'
import { ConnectionState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, ConnectionLoadingEvent, ConnectionLoadedEvent, ConnectionCreatedEvent, ConnectionUpdatedEvent, ConnectionDeletedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { ConnectionAPI } from '@/services/AccessEditor/infrastructure/api/ConnectionAPI'

export const actions: ActionTree<ConnectionState, any> = {
  [ActionTypes.ADD_CONNECTION]: async ({ commit, dispatch }, data) => {
    try {
      commit(new ConnectionLoadingEvent())
      let location = await APIClient.shared.request(new ConnectionAPI.AddConnection(data.payload))
      commit(new ConnectionCreatedEvent(location))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.UPDATE_CONNECTION]: async ({ commit, dispatch }, data) => {
    try {
      commit(new ConnectionLoadingEvent())
      let location = await APIClient.shared.request(new ConnectionAPI.UpdateConnection(data.payload))
      commit(new ConnectionUpdatedEvent())
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.DELETE_CONNECTION]: async ({ commit, dispatch }, data) => {
    try {
      await APIClient.shared.request(new ConnectionAPI.DeleteConnection(data.guid))
      commit(new ConnectionDeletedEvent(data.guid))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_CONNECTIONS]: async ({ commit, dispatch }, data) => {
    try {
      commit(new ConnectionLoadingEvent())
      let res = await APIClient.shared.request(new ConnectionAPI.GetConnections(data.payload))
      commit(new ConnectionLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_COUNT_CONNECTIONS]: async ({ commit, dispatch }, data) => {
    try {
      commit(new ConnectionLoadingEvent())
      let res = await APIClient.shared.request(new ConnectionAPI.GetCountConnections(data.payload))
      commit(new ConnectionLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_CONNECTION_PROPERTIES]: async ({ commit, dispatch }, data) => {
    try {
      commit(new ConnectionLoadingEvent())
      let res = await APIClient.shared.request(new ConnectionAPI.GetConnectionPropertirs(data.payload))
      commit(new ConnectionLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  }
}
