import { ActionTree } from 'vuex'
import { ModelFileState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, ModelFileCreatedEvent, ModelFileLoadingEvent, ModelFileDeletedEvent, ModelFileUpdatedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { ModelAPI } from '@/services/DigitalTwinEditor/infrastructure/api/ModelAPI'
import { ModelFileAPI } from '@/services/DigitalTwinEditor/infrastructure/api/ModelFileAPI'

export const actions: ActionTree<ModelFileState, any> = {
    [ActionTypes.ATTACH_FILE_TO_MODEL]: async ({ commit, dispatch }, data) => {
        try {
            commit(new ModelFileLoadingEvent());
            let location = await APIClient.shared.request(new ModelFileAPI.AttachFileToModel(data.payload));
            commit(new ModelFileCreatedEvent(location));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.DELETE_MODEL_FILE]: async ({ commit, dispatch }, data) => {
        try {
            await APIClient.shared.request(new ModelFileAPI.DeleteModelFile(data.guid, data.payload));
            commit(new ModelFileDeletedEvent(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }         
    },
    [ActionTypes.GET_FILE_BY_GUID]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new ModelFileAPI.GetFileByGuid(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    },
    [ActionTypes.TRANSFORM_FILE]: async ({ commit, dispatch }, data) => {
        try {
            commit(new ModelFileLoadingEvent());
            await APIClient.shared.request(new ModelFileAPI.TransformFile(data.payload));
            commit(new ModelFileUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.GET_MODEL_FILES]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new ModelAPI.GetModelFiles(data.modelGuid, data.payload));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    }
}
