
import Vue from 'vue'
import Report from '@/components/ReportEditor/Models/Reports'
import EditorFilter from './editor-filters.vue'

export default Vue.extend({
  name: 'editor-report',

  components: {
    EditorFilter
  },

  inject: {
    getComponents: {
      default: () => {
        return []
      }
    }
  },

  props: {
    value: Object
  },

  watch: {
    value: {
      handler: function () {
        this.model = this.value.reports || null
      },
      deep: true
    },

    model: {
      handler: function () {
        this.$set(this.value, 'reports', this.model)
      },
      deep: true
    },

    tabName: {
      handler: function (value) {
        this.$set(this.value.reports, 'tabName', value)
      }
    }
  },

  computed: {
    components () {
      return this.getComponents()
        .filter(c => ['AnalyticTable/index', 'Registry'].includes(c.initialType))
        .map(c => ({
          label: `${c.properties.name || c.properties.editorAlias || '[Без имени]'} (${c.name})`,
          value: c.guid
        }))
    }
  },

  data () {
    return {
      // Текущий выбранный отчёт
      currentReport: null,

      // Редактируемая модель конфигурации действия кнопки "Открыть отчёт"
      model: this.value.reports || null,
      tabName: this.value?.reports?.tabName || this.value?.reports?.name,
      // Список отчётов стимула
      reports: [],

      // Имя источника для добавления
      sourceInput: '',

      // Редактирование фильтров для каждого источника
      dialogVisible: false,
      sourceActive: null
    }
  },

  async mounted () {
    this.reports = await new Report().params({ fields: 'id,guid,name', report_type: 'report', order: 'r.name:asc' }).$get()

    if (this.model?.id) {
      this.currentReport = this.reports.find(r => r.id === this.model.id)

      if (!this.currentReport) {
        this.model = null

        return
      }

      if (!this.model.sources) {
        this.model.sources = []
      }

      this.sourceActive = this.model.sources[0] || null
    }
  },

  methods: {
    addSource () {
      if (!this.sourceInput.length) {
        return
      }

      this.model.sources.push({
        name: this.sourceInput,
        filters: []
      })

      this.sourceInput = ''

      this.sourceActive = this.model.sources[this.model.sources.length - 1]

      this.$refs.sourceInput.focus()
    },

    removeSource (index) {
      this.model.sources.splice(index, 1)
    },

    async selectReport (value) {
      this.model = {
        id: this.currentReport.id,
        name: this.currentReport.name,
        guid: this.currentReport.guid,
        sources: []
      }
    }
  }
})
