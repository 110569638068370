<template>
  <div style="height: calc( 100% - 15px); margin-top: 15px">
    <div class="registry_title" v-if="!!title && !showCard">{{ title }}</div>
    <cards
      v-show="openedCards.length > 0"
      :opened-cards="openedCards"
      :show-card="showCard"
      @cancelChanges="cancelChanges"
      @back="openedCards = openedCards.slice(0, $event + 1)"
      @back-registry="showCard=false; openedCards = []"
      style="height: calc(100%);"
    ></cards>
    <div v-show="!showCard" :style="{height: (!!title) ? 'calc(100% - 42px)' : '100%' }">
      <el-alert
        v-if="error"
        :title="errorText"
        type="error"
        :closable="false">
      </el-alert>
      <registry-table
        v-else
        :id="id"
        :page-limit="pageLimit"
        :outer-xref="outerXref"
        :state-id="stateId"
        :showButton="showButton"
        :styleTableHeader="styleTableHeader"
        ref="registry_table" :headers="headers"
        @open-card="openCard"
        @edit-record="editRecord"
      ></registry-table>
    </div>
  </div>
</template>

<script>
import Cards from './Cards'

import Card from './Models/Card'
import RegistryTable from './RegistryTable.vue'
import EventFactory from '@/core/infrastructure/service/EventFactory'

export default {
  name: 'Registry',
  components: {
    RegistryTable,
    Cards
  },
  props: {
    id: {
      type: Number
    },
    title: {
      type: String,
      default: null
    },
    outerXref: {
      type: Object,
      default () {
        return {}
      }
    },
    pageLimit: {
      type: Number
    },
    stateId: {
      type: Number
    },
    styleTableHeader: {
      type: String,
      default: () => {
        return 'font-style: normal; font-weight: normal; font-size: 13px; line-height: 20px; word-break: break-word; color: #807265'
      }
    },
    showButton: {
      type: Object,
      default: () => {
        return {
          update: false,
          add: false,
          add_existing: false,
          delete: false,
          export: false,
          import: false,
          views: false,
          group: false
        }
      }
    },
    showVerticalLine: {
      type: Boolean
    }
  },
  provide () {
    return {
      openRegistryCard: this.openRegistryCard,
      openDashboardCard: this.openDashboardCard,
      cancelChanges: this.cancelChanges
    }
  },
  data () {
    return {
      activeCardId: null,
      registry: null,
      breadcrumbs: [],
      errorText: this.$locale.registry.errors.not_found,
      headers: [],
      loading: true,
      error: false,
      name: null,
      cards: [],
      openedCards: [],
      showCard: false,
      positionScrolInTable: 0,
      // pageLimit: 100,
      availableColumnTypes: [
        'string_field',
        'boolean_field',
        'string_field',
        'boolean_field',
        'date_field',
        'datetime_field',
        'float_field',
        'integer_field',
        'text_field',
        'xref_field',
        'xref_multi_field',
        'file_field'
      ]
    }
  },
  computed: {
    activeBreadcrumb () {
      let breadcrumb = this.breadcrumbs.slice(-1)[0]
      if (breadcrumb) {
        return breadcrumb
      }

      return {
        name: null,
        click: () => {
        }
      }
    }
  },
  watch: {
    showCard: {
      handler (value) {
        // если закрыли карточку - очисить выделенные записи
        if (!value && this.$refs.registry_table?.$refs.table) {
          this.$refs.registry_table.$refs.table.clearSelection()
          setTimeout(() => {
            let container = this.$refs.registry_table.$refs.table?.$el.querySelector('.el-table__body-wrapper')
            if (container) {
              container.scrollTo({ top: this.positionScrolInTable, left: 0, behavior: 'instant' })
              this.positionScrolInTable = 0
            }
          }, 300)
        }
      }
    }
  },
  async mounted () {
    this.loading = true
    if (!this.id) {
      this.error = true
      return false
    }
    // this.loadCards()
    this.loading = false
  },
  methods: {
    renderColumns (columns) {
      if (!columns) {
        return false
      }
      this.headers = []
      let me = this
      columns.sort((a, b) => b.row_order - a.row_order).forEach((column) => {
        me.headers.push(me.buildColumn(column))
      })
    },
    buildColumn (column) {
      let me = this

      let data = {
        text: column.name,
        value: `attr_${column.id}_`,
        type: (me.availableColumnTypes.includes(column.entity_type_id) ? me.toCamelCase(column.entity_type_id) : me.toCamelCase('string_field')),
        properties: column.properties,
        width: me.getPropertyValue(column.properties, 'width', 100),
        fixed: JSON.parse(me.getPropertyValue(column.properties, 'is_fixed', false)),
        extended: JSON.parse(me.getPropertyValue(column.properties, 'is_extended', false)),
        column_id: column.id,
        children: []
      }

      if (column.children !== null) {
        column.children.sort((a, b) => b.row_order - a.row_order).forEach((child) => {
          data.children.push(me.buildColumn(child))
        })
      }

      return data
    },
    getPropertyValue (properties, propertyName, defaultValue) {
      let property = properties.find(
        function (element) {
          return element.id === propertyName
        }
      )

      return property ? property.value : defaultValue
    },
    renderFilter (fields) {
      this.filterFields = fields
    },
    async loadCards () {
      if (!this.id) {
        return false
      }
      this.cards = await Card.params({ entity_id: this.id, fields: 'id,name,is_default' }).$get()
    },
    async getCardId (recordId = null) {
      let url = `${this.$config.api}/registryservice/registry/${this.id}/card`
      if (recordId) {
        url = `${this.$config.api}/registryservice/registry/${this.id}/records/${recordId}/card`
      }
      let data = await this.$http.get(url)
      return data.data[0]
    },
    UcFirst (s) {
      return s.replace(/(^[a-z])/ig, ($1) => {
        return $1.toUpperCase()
      })
    },
    toCamelCase (s) {
      return s.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
          .replace('-', '')
          .replace('_', '')
      })
    },
    applyFilter (filters) {
      this.activeFilters = filters
      this.$nextTick(() => {
        this.$refs.registry_table.loadData()
      })
    },
    async editRecord (data) {
      if (!data.id) {
        return false
      }
      this.$set(data, 'loading', true)
      let container = this.$refs.registry_table?.$refs.table.$el.querySelector('.el-table__body-wrapper')?.scrollTop
      if (container) {
        this.positionScrolInTable = container
      }
      await this.openCard(data.id)
      this.$set(data, 'loading', false)
    },
    async openCard (recordId = null) {
      let card = await this.getCardId(recordId)
      if (card) {
        this.openRegistryCard({
          registryId: this.id,
          cardId: card.id,
          cardName: card.name,
          recordId: recordId,
          initialData: {},
          registry: this.$refs.registry_table,
          preventUserCard: true
        })
      }
    },
    openRegistryCard ({ registryId, cardId, cardName, recordId = null, initialData = {}, registry = null, preventUserCard = false }) {
      if (!cardId || !registryId) {
        this.$notify.error({
          title: this.$locale.main.message.error,
          message: this.$locale.main.message.not_saved
        })

        return false
      }
      this.showCard = true

      EventFactory.send(
        this,
        {
          eventType: 'open_record',
          entityId: cardId,
          entityType: 'card',
          entityName: cardName,
          recordId: recordId,
          objectId: registryId
        }
      )

      this.openedCards.push({
        id: cardId,
        registryId: registryId,
        recordId: recordId,
        name: cardName,
        initialData: initialData,
        registry: registry,
        readonly: registry ? (registry.readonly || false) : false,
        preventUserCard: preventUserCard
      })
    },
    openDashboardCard (dashboardId, name, recordId = null, initialData = {}) {
      if (!dashboardId) {
        this.$notify.error({
          title: this.$locale.main.message.error,
          message: this.$locale.main.message.not_saved
        })

        return false
      }
      this.showCard = true
      this.openedCards.push({
        id: dashboardId,
        recordId: recordId,
        name: name,
        initialData: initialData,
        isDashboard: true
      })
    },
    closeCard () {
      this.breadcrumbs[0].click()
    },
    confirmClosingCard (confirmCloseCardText) {
      let response = this.$confirm(confirmCloseCardText, 'Закрытие карточки', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Отмена',
        type: 'warning'
      }).then(() => {
        return true
      }).catch(() => {
        return false
      })
      return response
    },
    /**
     * @param {object} params
     * @param {number} params.depth
     * @param {boolean} params.confirmCloseCard - В общих настройках карточки галка "Уведомлять при закрытии карточки"
     * @param {string} params.confirmCloseCardText - Текста сообщения от пользователя
     */
    async cancelChanges (params = { depth: null, confirmCloseCard: false, confirmCloseCardText: '' }) {
      let { depth, confirmCloseCard, confirmCloseCardText } = params
      if (confirmCloseCard) {
        // закрытие карточки с подтверждением юзера
        let responseUser = await this.confirmClosingCard(confirmCloseCardText)
        if (!responseUser) {
          return
        }
      }
      if (depth) {
        this.openedCards = this.openedCards.slice(0, this.openedCards.length - depth)
        if (this.openedCards.length === 0) {
          this.showCard = false
        }
      } else {
        if (this.openedCards.length === 1) {
          this.showCard = false
          this.openedCards = []
        } else {
          this.openedCards = this.openedCards.slice(0, this.openedCards.length - 1)
        }
      }
    }
  }
}
</script>

<style scoped>
  .filter {
    width: 600px;
  }
  .registry_title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin-left: 24px;
    margin-bottom: 14px;
    height: 28px;
    color: #2C2D35;
  }
</style>
