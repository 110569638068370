import { ActionTree } from 'vuex'
import { SettingState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, SettingLoadingEvent, SettingLoadedEvent, SettingCreatedEvent, SettingUpdatedEvent, SettingDeletedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { SettingAPI } from '@/services/Databus/infrastructure/api/SettingAPI'

export const actions: ActionTree<SettingState, any> = {
    [ActionTypes.ADD_SETTING]: async ({ commit, dispatch }, data) => {
        try {
            commit(new SettingLoadingEvent());
            let location = await APIClient.shared.request(new SettingAPI.AddSetting(data.payload));
            commit(new SettingCreatedEvent(location));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.UPDATE_SETTING]: async ({ commit, dispatch }, data) => {
        try {
            commit(new SettingLoadingEvent());
            let location = await APIClient.shared.request(new SettingAPI.UpdateSetting(data.payload));
            commit(new SettingUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.DELETE_SETTING]: async ({ commit, dispatch }, data) => {
        try {
            await APIClient.shared.request(new SettingAPI.DeleteSetting(data.guid));
            commit(new SettingDeletedEvent(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }         
    },
    [ActionTypes.GET_SETTINGS]: async ({ commit, dispatch }, data) => {
        try {
            commit(new SettingLoadingEvent());
            let res = await APIClient.shared.request(new SettingAPI.GetSettings(data.payload));
            commit(new SettingLoadedEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.COUNT_SETTINGS]: async ({ commit, dispatch }, data) => {
        try {
            commit(new SettingLoadingEvent());
            let res = await APIClient.shared.request(new SettingAPI.CountSettings(data.payload));
            commit(new SettingLoadedEvent());
            return res;
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    }
}
