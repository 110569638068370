<template>
    <el-form label-width="120px" label-position="top" size="mini">
        <el-form-item :label="$locale.interface_editor.component_editor.table.source_type">
            <editor-select :value="value.type" @change="$set(value ,'type', $event)" :options="{multiple: false, options: dataTypes}"></editor-select>
        </el-form-item>
        <template v-if="value.type === 'extended_object'">
            <el-form-item >
                <editor-select :value="value.entityId" @change="$set(value ,'entityId', $event)" :options="{multiple: false, options: listExtendObject}"></editor-select>
            </el-form-item>
            <el-form-item :label="$locale.interface_editor.component_editor.editor_chart.value_field">
                <el-select
                        v-model="valueField"
                        value-key="name"
                        @change="$set(value ,'valueField', $event)"
                >
                    <el-option
                            v-for="(item, index) in filedExtendObject"
                            :key="index"
                            :label="item.name"
                            :value="item">
                    </el-option>
                </el-select>
            </el-form-item>
        </template>
      <template v-if="value.type === 'variable'">
        <el-form-item :label="$locale.interface_editor.component_editor.editor_chart.value_field">
          <el-input v-model.trim="value.valueField.name"></el-input>
        </el-form-item>
      </template>
    </el-form>
</template>
<script>
import EditorSelect from './editor-select'

export default {
  name: 'editor-source',
  components: {
    EditorSelect
  },
  props: {
    value: {
      type: Object,
      default () {
        return {
          type: null,
          entityId: null,
          valueField: {}
        }
      }
    }
  },
  data () {
    return {
      dataTypes: [
        { id: 'extended_object', name: 'Расширенная таблица' },
        { id: 'variable', name: 'Переменная' },
        { id: null, name: 'Без источника' }
      ],
      listExtendObject: [],
      filedExtendObject: [],
      valueField: {}
    }
  },
  watch: {
    value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    },
    'value.entityId': {
      async handler (value) {
        if (value) {
          this.valueField = {}
          let response = await this.$http.get(`${this.$config.api}/bieditor/extended_object_fields?extended_object_id=${value}`)
          this.filedExtendObject = response.data.map((el) => ({ id: el.id, name: el.name }))
        }
      }
    }
  },
  async mounted () {
    let response = await this.$http.get(`${this.$config.api}/bieditor/extended_objects`)
    this.listExtendObject = response.data
    if (this.value.entityId) {
      let response = await this.$http.get(`${this.$config.api}/bieditor/extended_object_fields?extended_object_id=${this.value.entityId}`)
      this.filedExtendObject = response.data.map((el) => ({ id: el.id, name: el.name }))
      this.valueField = this.value.valueField
    }
  }
}
</script>

<style scoped>

</style>
