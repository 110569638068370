
import { VueContext } from 'vue-context'

export default {
  name: 'ModelViewer',
  components: {
    VueContext
  },
  props: ['guid', 'year', 'month', 'extension'],
  data () {
    return {
    }
  },
  mounted() {
  }
}
