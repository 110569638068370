import QueryInterface from '@/core/application/query/QueryInterface'

export default class UserApiKeyQuery implements QueryInterface {
    private userId: number;
    private roleId: number;
    private systemIps: Array<string>;

    constructor (
      userId: number,
      roleId: number,
      systemIps: Array<string>
    ) {
      this.userId = userId
      this.roleId = roleId
      this.systemIps = systemIps
    }

    getClassName (): string {
      return 'UserApiKeyQuery'
    }

    getUserId (): number {
      return this.userId
    }

    getRoleId (): number {
      return this.roleId
    }

    getSystemIps (): Array<string> {
      return this.systemIps
    }
}
