import { MutationTree } from 'vuex'
import { PointStyleImageState, initialState } from './state'
import { MutationTypes, PointStyleImageCreatedEvent, PointStyleImageUpdatedEvent, PointStyleImageDeletedEvent } from './mutation-types'

export const mutations: MutationTree<PointStyleImageState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false;
    state.error = error;    
  },
  [MutationTypes.POINT_STYLE_IMAGE_LOADING_EVENT]: (state) => {
    state.isLoading = true;
  },
  [MutationTypes.POINT_STYLE_IMAGE_LOADED_EVENT]: (state) => {
    state.isLoading = false;
  },
  [MutationTypes.POINT_STYLE_IMAGE_CREATED_EVENT]: (state, action: PointStyleImageCreatedEvent) => {
    state.location = action.location;
    state.isLoading = false;
  },
  [MutationTypes.POINT_STYLE_IMAGE_UPDATED_EVENT]: (state, action: PointStyleImageUpdatedEvent) => {
    state.isLoading = false;
  },
  [MutationTypes.POINT_STYLE_IMAGE_DELETED_EVENT]: (state, action: PointStyleImageDeletedEvent) => {
    state.isLoading = false;
  }
}