import { MutationTree } from 'vuex'
import { ListenerState, initialState } from './state'
import { MutationTypes, ListenerCreatedEvent, ListenerUpdatedEvent, ListenerDeletedEvent } from './mutation-types'

export const mutations: MutationTree<ListenerState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false
    state.error = error
  },
  [MutationTypes.LISTENER_LOADING_EVENT]: (state) => {
    state.isLoading = true
  },
  [MutationTypes.LISTENER_LOADED_EVENT]: (state) => {
    state.isLoading = false
  },
  [MutationTypes.LISTENER_CREATED_EVENT]: (state, action: ListenerCreatedEvent) => {
    state.isLoading = false
    state.location = action.location
  },
  [MutationTypes.LISTENER_UPDATED_EVENT]: (state, action: ListenerUpdatedEvent) => {
    state.isLoading = false
  },
  [MutationTypes.LISTENER_DELETED_EVENT]: (state, action: ListenerDeletedEvent) => {
    state.isLoading = false
    state.deletedListenerGuid = action.guid
  }
}
