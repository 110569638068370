import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    TELEGRAM_CHAT_LOADING_EVENT = 'telegram_chat_loading_event',
    TELEGRAM_CHAT_LOADED_EVENT = 'telegram_chat_loaded_event'
}

export class TelegramChatLoadingEvent implements FluxStandardAction {
  type = MutationTypes.TELEGRAM_CHAT_LOADING_EVENT
}

export class TelegramChatLoadedEvent implements FluxStandardAction {
  type = MutationTypes.TELEGRAM_CHAT_LOADED_EVENT
}
