import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    DAG_LOADING_EVENT = 'dag_loading_event',
    DAG_LOADED_EVENT = 'dag_loaded_event',
    DAG_CREATED_EVENT = 'dag_created_event'
}

export class DagLoadingEvent implements FluxStandardAction {
    type = MutationTypes.DAG_LOADING_EVENT
}

export class DagLoadedEvent implements FluxStandardAction {
    type = MutationTypes.DAG_LOADED_EVENT
}

export class DagCreatedEvent implements FluxStandardAction {
    type = MutationTypes.DAG_CREATED_EVENT
    constructor(public location: string){}
}