import { SourceDTO } from '@/services/MapEditor/domain/model/Source/Source'

export interface SourceState {
  isLoading: boolean;
  location: string;
  error: object;
}

export const initialState = (): SourceState => {
  return {
    isLoading: false,
    location: null,
    error: null
  }
}