import { MutationTree } from 'vuex'
import { StyleState, initialState } from './state'
import { MutationTypes, StyleCreatedEvent, StyleUpdatedEvent, StyleDeletedEvent } from './mutation-types'

export const mutations: MutationTree<StyleState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false;
    state.error = error;    
  },
  [MutationTypes.STYLE_LOADING_EVENT]: (state) => {
    state.isLoading = true;
  },
  [MutationTypes.STYLE_LOADED_EVENT]: (state) => {
    state.isLoading = false;
  },
  [MutationTypes.STYLE_CREATED_EVENT]: (state, action: StyleCreatedEvent) => {
    state.location = action.location;
    state.isLoading = false;
  },
  [MutationTypes.STYLE_UPDATED_EVENT]: (state, action: StyleUpdatedEvent) => {
    state.isLoading = false;
  },
  [MutationTypes.STYLE_DELETED_EVENT]: (state, action: StyleDeletedEvent) => {
    state.isLoading = false;
  }
}