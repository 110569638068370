import ModelInstanceCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/ModelInstanceCommandRepositoryInterface'
import { ModelInstanceDTO } from '@/services/DigitalTwinEditor/domain/model/Project/ModelInstance'
import store from '@/store'
import { AddModelInstance, UpdateModelInstance, DeleteModelInstance } from '@/services/DigitalTwinEditor/infrastructure/store/modules/ModelInstance/action-types'

export default class ModelInstanceCommandRepository implements ModelInstanceCommandRepositoryInterface
{
    insert(item: ModelInstanceDTO): Promise<void>
    {
        return store.dispatch(new AddModelInstance(item));
    }

    update(item: ModelInstanceDTO): Promise<void>
    {
        return store.dispatch(new UpdateModelInstance(item));
    }

    delete(guid: string): Promise<void>
    {
        return store.dispatch(new DeleteModelInstance(guid));
    }
}
