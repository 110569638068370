<template>
  <el-input
    v-model="localModel"
    type="number"
    step="any"
    v-on:input="$emit('input', localModel || null)"
  >
  </el-input>
</template>

<script>
export default {
  name: 'input_float_field',
  props: ['value', 'name', 'fixedNumber'],
  data () {
    return {
      localModel: (this.value && (this.fixedNumber || 2)) ? Number(this.value).toFixed(+(this.fixedNumber || 2)) : this.value
    }
  }
}
</script>
