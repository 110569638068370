export interface FeatureState {
  isLoading: boolean;
  location: string;
  error: object;
}

export const initialState = (): FeatureState => {
  return {
    isLoading: false,
    location: null,
    error: null
  }
}