<template>
  <el-row style="margin-bottom: 5px">
    <el-row style="margin-bottom: 5px">
      <span>{{$locale.interface_editor.component_editor.xref_alter_source.alter_source}}</span>
      <el-switch style="float: right" v-model="value.enabled">{{$locale.interface_editor.component_editor.xref_alter_source.turn_on}}</el-switch>
    </el-row>
    <el-row v-if="value.enabled">
      <el-row>
      {{$locale.interface_editor.component_editor.xref_alter_source.type}} <editor-select :value="value.type" @change="$set(value ,'type', $event)" :options="{multiple: false, options: [
        {id: 'extended_object', name: 'Расширенный реестр'}
      ]}"/>
      </el-row>
    <el-row>
      Id: <el-input style="width: 20%" v-model="value.entityId"></el-input>
    </el-row>
      <el-row>
        {{$locale.interface_editor.component_editor.xref_alter_source.name_field}}<el-input v-model="value.nameField"/>
      </el-row>
    <el-row>
      <editor-filters style="margin-top: 10px" :value="value.filters" @change="$set(value ,'filters', $event)"/>
    </el-row>
    </el-row>
  </el-row>
</template>

<script>
import EditorSelect from './editor-select'
import EditorFilters from './editor-filters'
export default {
  name: 'editor-xref-alternative-source',
  components: { EditorSelect, EditorFilters },
  props: {
    value: {
      type: Object,
      default () {
        return {
          enabled: false,
          type: null,
          entityId: null,
          nameField: null,
          filters: []
        }
      }
    }
  },
  watch: {
    value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
