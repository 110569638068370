import { ActionTree } from 'vuex'
import { SnapshotState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, SnapshotLoadingEvent, SnapshotLoadedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { SnapshotAPI } from '@/services/SyncService/infrastructure/api/SnapshotAPI'

export const actions: ActionTree<SnapshotState, any> = {
  [ActionTypes.GET_SNAPSHOTS]: async ({ commit, dispatch }, data) => {
    try {
      commit(new SnapshotLoadingEvent())
      let res = await APIClient.shared.request(new SnapshotAPI.GetSnapshots(data.payload))
      commit(new SnapshotLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.COUNT_SNAPSHOTS]: async ({ commit, dispatch }, data) => {
    try {
      commit(new SnapshotLoadingEvent())
      let res = await APIClient.shared.request(new SnapshotAPI.CountSnapshots(data.payload))
      commit(new SnapshotLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.FILTER_SNAPSHOTS]: async ({ commit, dispatch }, data) => {
    try {
      commit(new SnapshotLoadingEvent())
      let res = await APIClient.shared.request(new SnapshotAPI.FilterSnapshots(data.payload))
      commit(new SnapshotLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_SCHEMA_FILTER]: async ({ commit, dispatch }, data) => {
    try {
      commit(new SnapshotLoadingEvent())
      let res = await APIClient.shared.request(new SnapshotAPI.GetSnapshotsSchemaFilterValues())
      commit(new SnapshotLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_TABLES_BY_SCHEMAS]: async ({ commit, dispatch }, data) => {
    try {
      commit(new SnapshotLoadingEvent())
      let res = await APIClient.shared.request(new SnapshotAPI.GetSnapshotsTablesBySchema(data.payload))
      commit(new SnapshotLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  }
}
