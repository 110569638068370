<template>
  <div>
    <el-button @click="addField">
      {{$locale.interface_editor.component_editor.filters.add_filter}}
    </el-button>
    <div v-if="conditions.length">
      <el-row :gutter="20">
        <el-col :span="7">
          Текст ошибки
        </el-col>
        <el-col :span="7">
          Поле из основного источника
        </el-col>
        <el-col :span="7">
          Поле из источника группировки
        </el-col>
      </el-row>
    </div>
    <div v-for="(condition, index) in conditions" :key="index">
        <el-row :gutter="20">
          <el-col :span="7">
            <el-form-item>
              <el-input v-model="condition.textError"/>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item>
              <select-field-condition :condition="conditions[index]" :value="value" property="sourceField" :index="index" :data-source="value.sourceData" />
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item>
              <select-field-condition :condition="conditions[index]" :value="value" property="listField" :index="index" :data-source="value.listData" />
            </el-form-item>
          </el-col>
          <el-col :span="1">
            <el-button @click="removeField(index)"
                       size="mini"
                       type="danger"
                       :title="$locale.main.button.delete">
              <i class="el-icon-delete"/>
            </el-button>
          </el-col>
        </el-row>
    </div>
  </div>
</template>

<script>

import SelectFieldCondition from "./SelectFieldCondition"
import SelectField from "./SelectField"
export default {
  name: "condition-fields",
  props: {
    value: {
      type: Object
    }
  },
  components: {
    SelectFieldCondition,
    SelectField
  },
  data () {
    return {
      dataSource: {},
      conditions: []
    }
  },
  beforeMount() {
    if(!this.value.listData.conditionMoving) this.value.listData.conditionMoving = []
    this.conditions = this.value.listData.conditionMoving
  },
  watch: {
    'conditions': {
      handler(conditions) {
        this.$set(this.value.listData, 'conditionMoving', conditions)
      }
    }
  },
  methods: {
    addField () {
      this.conditions.push({
        textError: null,
        sourceField: null,
        listField: null
      })
    },
    removeField (index) {
      this.conditions.splice(index, 1)
    }
  }
}

</script>

<style scoped>

</style>
