export default {
  inject: {
    _getModel: {
      from: 'getModel',
      default: () => () => {
        return {}
      }
    },
    _getRawData: {
      from: 'getRawData',
      default: () => () => {
        return {}
      }
    }
  },
  props: {
    replicationGuid: {
      type: String,
      frozen: true
    },
    replicationIndex: {
      type: Number,
      frozen: true
    }
  },
  methods: {
    getRawData () {
      return this._getRawData(this)
    },
    getModel () {
      return this._getModel(this)
    }
  }
}
