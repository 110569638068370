import Model from './Model'
import TransformerTypeProperty from './TransformerTypeProperty'

export default class TransformerType extends Model {
  resource () {
    return 'transformer_types'
  }

  properties () {
    return this.hasMany(TransformerTypeProperty)
  }
}
