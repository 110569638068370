import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import FileQueryRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/FileQueryRepositoryInterface'
import store from '@/store'
import { GetFileByGuid,  GetModelFiles } from '@/services/DigitalTwinEditor/infrastructure/store/modules/ModelFile/action-types'

export default class FileQueryRepository extends AbstractQueryRepository implements FileQueryRepositoryInterface
{
    getByGuid(guid: string): Promise<any>
    {
        return store.dispatch(new GetFileByGuid(guid));
    }

    getByJoinTable(modelGuid: string, payload: string[][]): Promise<any>
    {
        return store.dispatch(new GetModelFiles(modelGuid, payload));
    }
}