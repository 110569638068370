import { ActionTree } from 'vuex'
import { FormulaState, initialState } from '@/services/LogicEditor/infrastructure/store/modules/Formula/state'
import { ActionTypes } from './action-types'
import {
  MutationTypes,
  FormulaUpdatedEvent,
  FormulaCreatedEvent,
  FormulaLoadingEvent,
  FormulaDeletedEvent,
  FormulaLoadedEvent
} from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { FormulaAPI } from '@/services/LogicEditor/infrastructure/api/FormulaAPI'

export const actions: ActionTree<FormulaState, any> = {
  [ActionTypes.GET_FORMULA_BY_GUID]: async ({ commit, dispatch }, data) => {
    commit(new FormulaLoadingEvent())

    return APIClient.shared.request(new FormulaAPI.GetFormulaByGuid(data.guid))
      .then((response) => {
        commit(new FormulaLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.CREATE_FORMULA]: async ({ commit, dispatch }, data) => {
    commit(new FormulaLoadingEvent())

    return APIClient.shared.request(new FormulaAPI.CreateFormula(data.payload))
      .then((location) => {
        commit(new FormulaCreatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.UPDATE_FORMULA]: async ({ commit, dispatch }, data) => {
    commit(new FormulaLoadingEvent())

    return APIClient.shared.request(new FormulaAPI.UpdateFormula(data.payload))
      .then((location) => {
        commit(new FormulaUpdatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.DELETE_FORMULA]: async ({ commit, dispatch }, data) => {
    commit(new FormulaLoadingEvent())

    return APIClient.shared.request(new FormulaAPI.DeleteFormula(data.guid))
      .then(() => {
        commit(new FormulaDeletedEvent(data.guid))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  }
}
