import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { LoaderDTO, LoaderTypeDTO, LoaderTypePropertyDTO } from '@/services/EtlEditor/domain/model/Loader'

export namespace LoaderAPI {
  export class GetLoader implements APIRequest<LoaderDTO> {
    response: LoaderDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (payload: string[][]) {
      this.path = `/v2/etleditor/loaders?${new URLSearchParams(payload).toString()}`
    }
  }

  export class GetLoaderByGuid implements APIRequest<LoaderDTO> {
    response: LoaderDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/etleditor/loaders/${guid}`
    }
  }

  export class GetLoaderTypes implements APIRequest<LoaderTypeDTO> {
    response: LoaderTypeDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor () {
      this.path = `/v2/etleditor/loader_types`
    }
  }

  export class GetLoaderTypeProperties implements APIRequest<LoaderTypePropertyDTO> {
    response: LoaderTypePropertyDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (loaderTypeId: string) {
      this.path = `/v2/etleditor/loader_types/${loaderTypeId}/properties`
    }
  }

  export class CreateLoader implements APIRequest<string> {
    response: string;
    path = '/v2/etleditor/loaders';
    method = HTTPMethod.POST;
    parse = (data: AxiosResponse) => data.headers.location
    constructor (public params: LoaderDTO) {}
  }

  export class UpdateLoader implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.PUT;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: LoaderDTO) {
      this.path = `/v2/etleditor/loaders/${params.guid}`
    }
  }

  export class DeleteLoader implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.DELETE;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/etleditor/loaders/${guid}`
    }
  }
}
