import { render, staticRenderFns } from "./editor-xref-select.vue?vue&type=template&id=4d66d7e5&scoped=true&"
import script from "./editor-xref-select.vue?vue&type=script&lang=js&"
export * from "./editor-xref-select.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d66d7e5",
  null
  
)

export default component.exports