import { FluxStandardAction } from '@/core/domain/type/types'
import { TransformerDTO } from '@/services/EtlEditor/domain/model/Transformer'

export enum ActionTypes {
  GET_TRANSFORMER = 'get_transformer',
  GET_TRANSFORMER_BY_GUID = 'get_transformer_by_guid',
  GET_TRANSFORMER_TYPES = 'get_transformer_types',
  GET_TRANSFORMER_TYPE_PROPERTIES = 'get_transformer_type_properties',
  CREATE_TRANSFORMER = 'create_transformer',
  UPDATE_TRANSFORMER = 'update_transformer',
  DELETE_TRANSFORMER = 'delete_transformer'
}

export class GetTransformers implements FluxStandardAction {
  type = 'Transformer/' + ActionTypes.GET_TRANSFORMER
  constructor (public payload: object) {}
}

export class GetTransformerByGuid implements FluxStandardAction {
  type = 'Transformer/' + ActionTypes.GET_TRANSFORMER_BY_GUID
  constructor (public guid: string) {}
}

export class GetTransformerTypes implements FluxStandardAction {
  type = 'Transformer/' + ActionTypes.GET_TRANSFORMER_TYPES
  constructor () {}
}

export class GetTransformerTypeProperties implements FluxStandardAction {
  type = 'Transformer/' + ActionTypes.GET_TRANSFORMER_TYPE_PROPERTIES
  constructor (public transformerTypeId: string) {}
}

export class CreateTransformer implements FluxStandardAction {
  type = 'Transformer/' + ActionTypes.CREATE_TRANSFORMER
  constructor (public payload: TransformerDTO) {}
}

export class UpdateTransformer implements FluxStandardAction {
  type = 'Transformer/' + ActionTypes.UPDATE_TRANSFORMER
  constructor (public payload: TransformerDTO) {}
}

export class DeleteTransformer implements FluxStandardAction {
  type = 'Transformer/' + ActionTypes.DELETE_TRANSFORMER
  constructor (public guid: string) {}
}
