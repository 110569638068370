import { Nullable } from '@/core/domain/type/types'

export interface IAPITaskRegistryEntity {
  id: string;
  name: string;
  entity_id: Nullable<number>;
}

export default class TaskRegistryEntity {
  public id: string;
  public name: string;
  public entity_id: Nullable<number>;

  constructor (taskRegistryEntity: IAPITaskRegistryEntity) {
    this.id = taskRegistryEntity.id
    this.name = taskRegistryEntity.name
    this.entity_id = taskRegistryEntity.entity_id
  }

  static load (data: IAPITaskRegistryEntity): TaskRegistryEntity {
    return new TaskRegistryEntity(data)
  }

  static loadMany (data: Array<IAPITaskRegistryEntity>): Array<TaskRegistryEntity> {
    let taskRegistryEntities: Array<TaskRegistryEntity> = []

    data.forEach((item) => {
      taskRegistryEntities.push(this.load(item))
    })

    return taskRegistryEntities
  }
}
