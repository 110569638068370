<template>
  <el-input
    v-model="localModel"
    v-on:input="$emit('input', localModel)"
    type="textarea"
    :rows="rows"
  >
  </el-input>
</template>

<script>
export default {
  name: 'input_text_field',
  props: ['value', 'rows'],
  data () {
    return {
      localModel: this.value
    }
  }
}
</script>
