import { AxiosResponse } from 'axios'
import { Nullable } from '@/core/domain/type/types'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { ModelTreeElementDTO } from '@/services/DigitalTwinEditor/domain/model/Model/ModelTreeElement'

export namespace ModelTreeElementAPI {

    export class AddModelTreeModelElement implements APIRequest<string> {
        response: string;
        path = '/digitaltwineditor/model_tree_elements/models';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.headers.location
        constructor(public params: ModelTreeElementDTO) {}
    }

    export class AddModelTreeGroupElement implements APIRequest<string> {
        response: string;
        path = '/digitaltwineditor/model_tree_elements/groups';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.headers.location
        constructor(public params: ModelTreeElementDTO) {}
    }

    export class DeleteModelTreeElement implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.DELETE;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/digitaltwineditor/model_tree_elements/${guid}`;
        }
    }

    export class GetModelTreeElementByGuid implements APIRequest<ModelTreeElementDTO> {
        response: ModelTreeElementDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/digitaltwineditor/model_tree_elements/${guid}`;
        }
    }

    export class GetModelTreeElements implements APIRequest<ModelTreeElementDTO> {
        response: ModelTreeElementDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(parentId: Nullable<number>) {
            if (parentId !== null) {
                this.path = `/digitaltwineditor/model_tree_elements/?parent_id=${parentId}`;
            } else {
                this.path = `/digitaltwineditor/model_tree_elements/`;
            }
        }
    }
}