import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    TEXTURE_LOADING_EVENT = 'texture_loading_event',
    TEXTURE_LOADED_EVENT = 'texture_loaded_event'
}

export class TextureLoadingEvent implements FluxStandardAction {
    type = MutationTypes.TEXTURE_LOADING_EVENT
}

export class TextureLoadedEvent implements FluxStandardAction {
    type = MutationTypes.TEXTURE_LOADED_EVENT
}
