<template>
  <div class="window" :style="{zIndex: zIndex}" v-if="isActive">
    <div class="overlay" @click="closeWindow(false)">
      <div
        class="content"
        :style="{width: width, height: height}"
        @click="closeWindow(true)">
        <button
          type="button"
          class="close-button el-message-box__headerbtn"
          aria-label="Close"
          v-if="showCloseIcon"
          @click="closeWindow(false)">
          <i class="el-message-box__close el-icon-close"></i>
        </button>
        <div class="header" v-if="title">{{ title }}</div>
        <div
          :style="{
            width: '100%',
            height: (title && title.length > 0) ? 'calc(100% - 28px - 29px)' : '100%'
          }"
        ><slot></slot></div>
      </div>
    </div>
  </div>
</template>

<script>
import { PopupManager } from 'element-ui/src/utils/popup'
export default {
  name: 'ModalWindow',
  props: {
    active: {
      type: Boolean,
      default: false
    },
    title: String,
    width: {
      type: String,
      default: '80%'
    },
    height: {
      type: String,
      default: '80%'
    },
    showCloseIcon: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isClickedOnContent: false,
      zIndex: 0,
      isActive: this.active
    }
  },
  mounted () {
    this.zIndex = PopupManager.nextZIndex()
  },
  watch: {
    active: {
      handler (value) {
        this.isActive = value
      }
    }
  },
  methods: {
    closeWindow (isClickedOnContent) {
      if (isClickedOnContent) {
        this.isClickedOnContent = true
      } else {
        if (this.isClickedOnContent) {
          this.isClickedOnContent = false
        } else {
          this.isActive = false
          this.$emit('update:active', false)
        }
      }
    }
  }
}
</script>

<style scoped>
.window {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: inline-block;
}

.window .overlay {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  display: flex;
  align-items: center
}

.window .content {
  overflow: hidden;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  position: relative;
}

.window .content .close-button {
  z-index: 999;
}

.header {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-left: 24px;
  margin-bottom: 14px;
  margin-top: 15px;
  height: 28px;
  color: #2C2D35;
}
</style>
