import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { ProjectDTO } from '@/services/MapEditor/domain/model/Project/Project'

export enum ActionTypes {
    ADD_PROJECT = 'add_project',
    UPDATE_PROJECT = 'update_project',
    DELETE_PROJECT = 'delete_project',
    GET_PROJECTS = 'get_projects',
    COUNT_PROJECTS = 'count_projects',
    GET_PROJECT_BY_GUID = 'get_project_by_guid',
    GET_PROJECT_SOURCES = 'get_project_sources',
    GET_PROJECT_LAYERS = 'get_project_layers',
    GET_PROJECT_STYLES = 'get_project_styles',
    GET_PROJECT_COORDINATE_SYSTEMS = 'get_project_coordinate_systems'
}

export class AddProject implements FluxStandardAction {
    type = 'Project/' + ActionTypes.ADD_PROJECT
    constructor(public payload: ProjectDTO){}
}

export class UpdateProject implements FluxStandardAction {
    type = 'Project/' + ActionTypes.UPDATE_PROJECT
    constructor(public payload: ProjectDTO){}
}

export class DeleteProject implements FluxStandardAction {
    type = 'Project/' + ActionTypes.DELETE_PROJECT
    constructor(public guid: string){}
}

export class GetProjects implements FluxStandardAction {
    type = 'Project/' + ActionTypes.GET_PROJECTS
    constructor(public payload: string[][]){}
}

export class CountProjects implements FluxStandardAction {
    type = 'Project/' + ActionTypes.COUNT_PROJECTS
    constructor(public payload: string[][]){}
}

export class GetProjectByGuid implements FluxStandardAction {
    type = 'Project/' + ActionTypes.GET_PROJECT_BY_GUID
    constructor(public guid: string){}
}

export class GetProjectSources implements FluxStandardAction {
    type = 'Project/' + ActionTypes.GET_PROJECT_SOURCES
    constructor(public projectGuid: string, public payload: string[][]){}
}

export class GetProjectLayers implements FluxStandardAction {
    type = 'Project/' + ActionTypes.GET_PROJECT_LAYERS
    constructor(public projectGuid: string){}
}

export class GetProjectStyles implements FluxStandardAction {
    type = 'Project/' + ActionTypes.GET_PROJECT_STYLES
    constructor(public projectGuid: string, public payload: string[][]){}
}

export class GetProjectCoordinateSystems implements FluxStandardAction {
    type = 'Project/' + ActionTypes.GET_PROJECT_COORDINATE_SYSTEMS
    constructor(public projectGuid: string, public payload: string[][]){}
}