import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ModelTreeElementCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/ModelTreeElementCommandRepositoryInterface'
import ModelTreeElementGroupCreateCommand from '@/services/DigitalTwinEditor/application/command/ModelTreeElementGroupCreateCommand'
import { ModelTreeElementDTO } from '@/services/DigitalTwinEditor/domain/model/Model/ModelTreeElement'

export default class ModelTreeElementGroupCreateHandler implements CommandHandlerInterface
{
    private repo: ModelTreeElementCommandRepositoryInterface;

    constructor(repo: ModelTreeElementCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: ModelTreeElementGroupCreateCommand): Promise<void>
    {
        const item: ModelTreeElementDTO = {
            name: command.getName(),
            alias: command.getAlias(),
            parent_id: command.getParentId()
        };
        return this.repo.insertGroup(item);
    }
}