import { ActionTree } from 'vuex'
import { GroupState } from '@/services/NotificationEditor/infrastructure/store/modules/NotificationGroup/state'
import { ActionTypes } from './action-types'
import {
  MutationTypes,
  GroupUpdatedEvent,
  GroupCreatedEvent,
  GroupLoadingEvent,
  GroupDeletedEvent,
  GroupLoadedEvent
} from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { GroupAPI } from '@/services/NotificationEditor/infrastructure/api/GroupAPI'

export const actions: ActionTree<GroupState, any> = {
  [ActionTypes.GET_GROUP]: async ({ commit, dispatch }, data) => {
    commit(new GroupLoadingEvent())

    return APIClient.shared.request(new GroupAPI.GetGroup(data.payload))
      .then((response) => {
        commit(new GroupLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.GET_GROUP_BY_GUID]: async ({ commit, dispatch }, data) => {
    commit(new GroupLoadingEvent())

    return APIClient.shared.request(new GroupAPI.GetGroupById(data.id))
      .then((response) => {
        commit(new GroupLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.CREATE_GROUP]: async ({ commit, dispatch }, data) => {
    commit(new GroupLoadingEvent())

    return APIClient.shared.request(new GroupAPI.CreateGroup(data.payload))
      .then((location: string) => {
        commit(new GroupCreatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.UPDATE_GROUP]: async ({ commit, dispatch }, data) => {
    commit(new GroupLoadingEvent())

    return APIClient.shared.request(new GroupAPI.UpdateGroup(data.payload))
      .then((location: string) => {
        commit(new GroupUpdatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.DELETE_GROUP]: async ({ commit, dispatch }, data) => {
    commit(new GroupLoadingEvent())

    return APIClient.shared.request(new GroupAPI.DeleteGroup(data.id))
      .then((location) => {
        commit(new GroupDeletedEvent(data.id))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  }
}
