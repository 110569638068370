import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import PointStyleImageCommandRepositoryInterface from '@/core/domain/repository/modules/PointStyleImageCommandRepositoryInterface'
import PointStyleImageUpdateCommand from '@/core/application/command/modules/PointStyleImage/PointStyleImageUpdateCommand'
import { PointStyleImageDTO } from '@/core/domain/model/modules/PointStyleImage'

export default class PointStyleImageUpdateHandler implements CommandHandlerInterface
{
    private repo: PointStyleImageCommandRepositoryInterface;

    constructor(repo: PointStyleImageCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: PointStyleImageUpdateCommand): Promise<void>
    {
        let item: PointStyleImageDTO = {
            guid: command.getGuid(),
            name: command.getName(),
            image: command.getImage()
        };
        return this.repo.insert(item);
    }
}