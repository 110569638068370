import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    RASTER_LOADING_EVENT = 'raster_loading_event',
    RASTER_LOADED_EVENT = 'raster_loaded_event',
    RASTER_CREATED_EVENT = 'raster_created_event',
    RASTER_UPDATED_EVENT = 'raster_updated_event',
    RASTER_DELETED_EVENT = 'raster_deleted_event' 
}

export class RasterLoadingEvent implements FluxStandardAction {
    type = MutationTypes.RASTER_LOADING_EVENT
}

export class RasterLoadedEvent implements FluxStandardAction {
    type = MutationTypes.RASTER_LOADED_EVENT
}

export class RasterCreatedEvent implements FluxStandardAction {
    type = MutationTypes.RASTER_CREATED_EVENT
    constructor(public location: string){}
}

export class RasterUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.RASTER_UPDATED_EVENT
}

export class RasterDeletedEvent implements FluxStandardAction {
    type = MutationTypes.RASTER_DELETED_EVENT
    constructor(public guid: string){}
}