import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    MODEL_TREE_ELEMENT_LOADING_EVENT = 'model_tree_element_loading_event',
    MODEL_TREE_ELEMENT_CREATED_EVENT = 'model_tree_element_created_event',
    MODEL_TREE_ELEMENT_DELETED_EVENT = 'model_tree_element_deleted_event',
    MODEL_TREE_ELEMENT_UPDATED_EVENT = 'model_tree_element_updated_event'      
}

export class ModelTreeElementLoadingEvent implements FluxStandardAction {
    type = MutationTypes.MODEL_TREE_ELEMENT_LOADING_EVENT
}

export class ModelTreeElementCreatedEvent implements FluxStandardAction {
    type = MutationTypes.MODEL_TREE_ELEMENT_CREATED_EVENT
    constructor(public location: string){}
}

export class ModelTreeElementDeletedEvent implements FluxStandardAction {
    type = MutationTypes.MODEL_TREE_ELEMENT_DELETED_EVENT
    constructor(public guid: string){}
}

export class ModelTreeElementUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.MODEL_TREE_ELEMENT_UPDATED_EVENT
}