import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'
import NameTooLong from '@/core/domain/exception/NameTooLong'
import NameMustNotBeEmpty from '@/core/domain/exception/NameMustNotBeEmpty'
import TreeElementType from '../TreeElementType'

export class ModelGroupDTO
{
    id?: Nullable<number>;
    guid?: Nullable<string>;
    name?: string;
    alias?: string;
    parent_id?: Nullable<number>;

    constructor({ id, guid, name, alias, parent_id } : { id?: Nullable<number>, guid?: Nullable<string>, name?: string, alias?: string, parent_id?: Nullable<number>}) {
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.alias = alias;
        this.parent_id = parent_id;
    }
}

export default class ModelGroup extends AbstractBaseEntity
{
    private id?: Nullable<number>;
    private guid?: Nullable<string>;
    private name: string;
    private alias: Nullable<string>;
    private parentId: Nullable<number>;

    constructor(
        id: Nullable<number>,
        guid: Nullable<string>,
        name: string,
        alias: Nullable<string>,
        parentId: Nullable<number>
    ) {
        super();
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.alias = alias;
        this.parentId = parentId;
        this.assertInvariants();
    }

    static create(dto: ModelGroupDTO): ModelGroup
    {
        return new ModelGroup(
            dto.id,
            dto.guid,
            dto.name,
            dto.alias,
            dto.parent_id
        );
    }

    setId(id: number): void
    {
        this.id = id;
    }

    getId(): number
    {
        return this.id;
    }

    setGuid(guid: string): void
    {
        this.guid = guid;
    }

    getGuid(): string
    {
        return this.guid;
    }

    setName(name: string): void
    {
        if (name.length > 255) {
            throw new NameTooLong();
        }
        this.name = name;
    }

    getName(): string
    {
        return this.name;
    }

    assertInvariants(): void
    {
        if (this.name.length == 0) {
            throw new NameMustNotBeEmpty();
        }

        if (this.name.length > 255) {
            throw new NameTooLong();
        }
    }

    setAlias(alias: Nullable<string>): void
    {
        this.alias = alias;
    }

    getAlias(): Nullable<string>
    {
        return this.alias;
    }

    setParentId(parentId: Nullable<number>): void
    {
        this.parentId = parentId;
    }

    getParentId(): Nullable<number>
    {
        return this.parentId;
    }

    getType(): TreeElementType
    {
        return TreeElementType.MODEL_GROUP;
    }
}