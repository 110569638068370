<template>
  <el-select
    style="width: 100%"
    @visible-change="onExpand"
    v-bind:value="localValue"
    clearable
    :name="name"
    @change="localValue = $event;$emit('input', $event)"
    @clear="localValue = null;$emit('input', localValue)"
    filterable
  >
    <el-option
      v-for="(item, index) in options"
      :key="index"
      :label="item.name"
      :value="item.id">
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: 'input_xref_field',
  props: ['value', 'attrName', 'name'],
  data () {
    return {
      localValue: null,
      options: [],
      loaded: false
    }
  },
  async mounted () {
    if (!this.value) {
      return false
    }
    let parsed = JSON.parse(this.value)
    if (parsed instanceof Array && parsed.length > 0) {
      this.options = this.options.concat(parsed)
      this.localValue = parsed[0].id
    } else if (typeof parsed === 'number') {
      await this.onExpand()
      this.localValue = parsed
    }
  },
  methods: {
    async onExpand () {
      if (!this.loaded) {
        let attributeId = parseInt(/attr_([0-9]+)_/i.exec(this.attrName) ? /attr_([0-9]+)_/i.exec(this.attrName)[1] : 0)

        if (attributeId) {
          let response = await this.$http.get(`${this.$config.api}/registryservice/xref/${attributeId}/data`)
          this.options = response.data
          this.loaded = true
        }
      }
    }
  }
}
</script>
