import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { SchemaTreeDTO } from '@/services/ApiService/domain/model/Schema/SchemaTreeDTO'

export enum ActionTypes {
  GET_SCHEMAS = 'get_schemas',
  CREATE_SCHEMA = 'create_schema',
  DELETE_SCHEMA = 'delete_schema',
  UPDATE_SCHEMA = 'update_schema'
}

export class GetSchemas implements FluxStandardAction {
  type = 'Schema/' + ActionTypes.GET_SCHEMAS
  constructor (public payload: object) {}
}

export class CreateSchema implements FluxStandardAction {
  type = 'Schema/' + ActionTypes.CREATE_SCHEMA
  constructor (public payload: object) {}
}

export class DeleteSchema implements FluxStandardAction {
  type = 'Schema/' + ActionTypes.DELETE_SCHEMA
  constructor (public guid: string) {}
}

export class UpdateSchema implements FluxStandardAction {
  type = 'Schema/' + ActionTypes.UPDATE_SCHEMA
  constructor (public payload: SchemaTreeDTO) {}
}
