
import { VueContext } from 'vue-context'
import Treeselect from '@bingosoftnn/vue-treeselect'
import AttachFileToModelCommand from '@/services/DigitalTwinEditor/application/command/AttachFileToModelCommand'
import FileDeleteCommand from '@/services/DigitalTwinEditor/application/command/FileDeleteCommand'
import FileByGuidQuery from '@/services/DigitalTwinEditor/application/query/FileByGuidQuery'
import ModelFilesQuery from '@/services/DigitalTwinEditor/application/query/ModelFilesQuery'
import FileTransformCommand from '@/services/DigitalTwinEditor/application/command/FileTransformCommand'
import ModelInstanceCreateCommand from '@/services/DigitalTwinEditor/application/command/ModelInstanceCreateCommand'
import ModelInstanceUpdateCommand from '@/services/DigitalTwinEditor/application/command/ModelInstanceUpdateCommand'
import ModelInstanceDeleteCommand from '@/services/DigitalTwinEditor/application/command/ModelInstanceDeleteCommand'
import ModelInstanceByGuidQuery from '@/services/DigitalTwinEditor/application/query/ModelInstanceByGuidQuery'

export default {
  name: 'ModelForm',
  components: {
    VueContext,
    Treeselect
  },
  props: ['value'],
  watch: {
    modelFileCreated: function (location) {
      if (typeof location !== "undefined") {
        this.getQueryBus().execute(
          new FileByGuidQuery(
            location.replace('/files/', '')
          )
        ).then((elementDto) => {
          this.dto.files.push(elementDto);
        });
      }
    },
    modelInstanceCreated: function (location) {
      if (typeof location !== "undefined") {
        this.getQueryBus().execute(
          new ModelInstanceByGuidQuery(
            location.replace('/model_instances/', '')
          )
        ).then((elementDto) => {
          this.dto.instances.push(elementDto);
          this.closeInstance();
        });
      }
    }
  },
  computed: {
    dto: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    isLoading () {
      return this.$store.getters['ModelFile/isLoading'];     
    },
    modelFileCreated () {
      return this.$store.getters['ModelFile/getLocation'];
    },
    modelInstanceCreated () {
      return this.$store.getters['ModelInstance/getLocation'];
    }
  },
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  data () {
    return {
      element_type: "model",
      showFileForm: false,
      formRules: {
        name: {
          required: true,
          message: this.$locale.digitaltwin_editor.errors.name_required,
          trigger: 'input'
        },
        file: {
          required: true,
          message: this.$locale.main.errors.required,
          trigger: 'input'
        }
      },
      coordinate_systems: [{
        id: 3857,
        label: 'EPSG 3857'
      },{
        id: 4326,
        label: 'EPSG 4326'
      }],
      fileDto: {},
      showInstanceForm: false,
      instanceDto: {
        position: [0, 0, 0],
        rotation: [0, 0, 0]
      },
      instanceDtoInTable: null,
      optimizeFormVisible: false,
      optimizationDto: {
        optimization_type: "geometry_compression"
      },
      optimizationTypes: [{
        id: 'geometry_compression',
        label: this.$locale.digitaltwin_editor.model_panel.geometry_compression
      }/*,{
        id: 'texture_resize',
        label: this.$locale.digitaltwin_editor.model_panel.texture_resize
      },{
        id: 'texture_compression',
        label: this.$locale.digitaltwin_editor.model_panel.compress_texture
      }*/],
      dracoMethods: [{
        id: 'edgebreaker',
        label: 'Edgebreaker'
      },{
        id: 'sequential',
        label: 'Sequential'
      }],
      geometryCompressionTypes: [{
        id: 'draco',
        label: 'Draco'
      },{
        id: 'meshopt',
        label: 'Mesh optimization'
      }],
      geometryCompressionLevels: [{
        id: 'medium',
        label: this.$locale.digitaltwin_editor.model_panel.medium
      },{
        id: 'high',
        label: this.$locale.digitaltwin_editor.model_panel.high
      }]
    }
  },
  methods: {
    addFile () {
      this.showFileForm = true;
      this.fileDto.model_id = this.dto.id;
    },
    removeFile (scope) {
      this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
        confirmButtonText: this.$locale.main.button.delete,
        cancelButtonText: this.$locale.main.button.cancel,
        type: 'warning'
      }).then(async () => {
        let record = scope.row;
        let payload = {
          'id': scope.row.id
        };
        this.getCommandBus().execute(new FileDeleteCommand(record.guid, payload)).then((response) => {
          for (let i = 0; i < this.dto.files.length; i += 1) {
            if (this.dto.files[i].id == record.id) {
              this.dto.files.splice(i, 1);
              break;
            }
          }
        });
      });
    },
    changeFile (file) {
      if (this.$refs.upload.uploadFiles.length > 1) {
        this.$refs.upload.uploadFiles.splice(0, 1)
      }
      this.fileDto.file = this.$refs.upload.uploadFiles[0].raw;
    },
    clearFile () {
      if (typeof this.$refs.upload !== 'undefined') {
        this.$refs.upload.uploadFiles = []
      }
      this.fileDto.file = null;
    },
    saveFile () {
      let form = this.$refs.fileForm;
      form.validate((valid, invalidFields) => {
        if (valid) {
          let command = new AttachFileToModelCommand(
            this.fileDto.model_id,
            "",          
            this.fileDto.file
          )
          this.getCommandBus().execute(command).then((response) => {
            this.showFileForm = false;
            this.fileDto = {
              model_id: this.dto.id 
            };
            this.loadModelFiles();
          });
        }
      });
    },
    viewModel (scope) {
      /*if (parseInt(scope.row.size) < 120000000) {*/
        let month = scope.row.month;
        if (month.toString().length == 1) {
          month = `0${month}`;
        }
        let dto = {
          src: `${this.$config.api}/files/digitaltwineditor/files/${scope.row.year}/${month}/${scope.row.guid}.${scope.row.extension}`,
          extension: scope.row.extension,
          size: parseInt(scope.row.size),
          guid: scope.row.guid,
          year: scope.row.year,
          month: month,
          name: scope.row.name,
          element_type: "model_viewer"
        };
        this.getEventBus().$emit('openEditForm', dto);
      /*} else {
        this.$message({
          message: this.$locale.digitaltwin_editor.errors.model_is_too_big,
          type: 'warning'
        });
      }*/
    },
    removeInstance (scope) {
      this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
        confirmButtonText: this.$locale.main.button.delete,
        cancelButtonText: this.$locale.main.button.cancel,
        type: 'warning'
      }).then(async () => {
        let record = scope.row;
        this.getCommandBus().execute(new ModelInstanceDeleteCommand(record.guid)).then((response) => {
          for (let i = 0; i < this.dto.instances.length; i += 1) {
            if (this.dto.instances[i].guid == record.guid) {
              this.dto.instances.splice(i, 1);
              break;
            }
          }
        });
      });
    },
    addInstance () {
      this.instanceDto.element_id = this.dto.tree_element_id;
      this.instanceDto.model_id = this.dto.id;
      this.showInstanceForm = true;
    },
    saveInstance () {
      if (typeof this.instanceDto.guid == "undefined" || this.instanceDto.guid == null) {
        this.getCommandBus().execute(new ModelInstanceCreateCommand(
          this.instanceDto.element_id,
          this.instanceDto.model_id,
          this.instanceDto.position,
          this.instanceDto.rotation,
          this.instanceDto.uses_heightmap_z,
          this.instanceDto.has_3d_tiles
        )).then((elementDto) => {
          this.closeInstance();
        });
      } else {
        this.getCommandBus().execute(new ModelInstanceUpdateCommand(
          this.instanceDto.guid,
          this.instanceDto.position,
          this.instanceDto.rotation,
        )).then((elementDto) => {
          this.instanceDtoInTable.position = this.instanceDto.position;
          this.instanceDtoInTable.rotation = this.instanceDto.rotation;
          this.closeInstance();
        });
      }
    },
    closeInstance () {
      this.showInstanceForm = false;
      this.instanceDto.guid = null;
      this.instanceDto.position = [0, 0, 0];
      this.instanceDto.rotation = [0, 0, 0];
    },
    editInstance(scope) {
      this.instanceDtoInTable = scope.row;
      this.instanceDto.guid = scope.row.guid;
      this.instanceDto.position = scope.row.position.slice();
      this.instanceDto.rotation = scope.row.rotation.slice();
      this.showInstanceForm = true;
    },
    transformFile(command) {
      let tokens = command.split(":");
      let format = tokens[0];
      let guid = tokens[1];
      let id = tokens[2];
      this.getCommandBus().execute(new FileTransformCommand(
        this.dto.id,
        guid,
        id,
        format
      )).then((elementDto) => {  
        this.$message({
          type: 'info',
          message: this.$locale.digitaltwin_editor.messages.wait_until_operation_finishes
        });
        this.loadModelFiles();   
      });
    },
    async loadModelFiles() {
      this.dto.files = await this.getQueryBus().execute(
        new ModelFilesQuery(this.dto.guid, null)
      );
    },
    optimizeGlb() {
      this.getCommandBus().execute(new FileTransformCommand(
        this.dto.id,
        this.optimizationDto.guid,
        this.optimizationDto.id,
        'glb',
        this.optimizationDto
      )).then((elementDto) => {  
        this.$message({
          type: 'info',
          message: this.$locale.digitaltwin_editor.messages.wait_until_operation_finishes
        });
        this.optimizeFormVisible = false;
        this.loadModelFiles();   
      });
    }
  }
}

