export default class StyleUpdateCommand
{
    private guid: string;
    private styleId: string;
    private value: string;

    constructor(guid: string, styleId: string, value: string)
    {
        this.guid = guid;
        this.styleId = styleId;
        this.value = value;
    }

    getGuid(): string
    {
        return this.guid;
    }

    getStyleId(): string
    {
        return this.styleId;
    }

    getValue(): string
    {
        return this.value;
    }

    getClassName(): string
    {
        return 'StyleUpdateCommand';
    }
}