<template>
  <el-form-item :label="label">
    <el-input v-bind:value="value" v-on:input="$emit('input', $event || null)"></el-input>
  </el-form-item>
</template>

<script>
export default {
  name: 'varchar',

  props: ['value', 'label']
}
</script>

<style scoped>

</style>
