import { LayerGroupDTO } from '@/services/MapEditor/domain/model/Layer/LayerGroup'

export interface LayerGroupState {
  isLoading: boolean;
  location: string;
  error: object;
}

export const initialState = (): LayerGroupState => {
  return {
    isLoading: false,
    location: null,
    error: null
  }
}