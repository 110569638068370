<template>
  <div  v-loading="loading">
    <el-row class="navigation" style="display: flex;">
      <el-col class="menu_icon" v-if="showMenu" :class="isCollapse ? 'system_menuShadow1' : 'system_menuShadow'" :span="isCollapse ? 1 : 4" :style="{ width: isCollapse ? 64 +'px' : width + 'px' }">
        <div class="system_avatar" :class="isCollapse ? `${project}_mini` : `${project}`"
             :style="{
          marginLeft: isCollapse ? 12 + '%' : 0 + '%',
          marginRight: isCollapse ? 12 + '%' : 0 + '%',
          backgroundImage: !isCollapse ? mainLogoUrl : miniLogoUrl,
          backgroundSize: !isCollapse ? (mainLogoSize || 'contain') : (miniLogoSize || 'contain')
        }">
        </div>
        <el-input
          v-show="!isCollapse"
          size="small"
          suffix-icon="el-icon-search"
          class="system_mainInput"
          clearable
          :placeholder="$t(`main.label_input.menu`)"
          v-model="searchMenu"
        ></el-input>
        <el-scrollbar class="" :style="{height: isCollapse  ? 'calc(100vh - 140px)' : 'calc(100vh - 187px)'}" >
          <div v-if="!searchMenu">
            <el-menu
              class="el-main-menu"
              :collapse="isCollapse"
              v-for="(item, indexArr) in menu"
              :key="item.id"
              :index="indexArr.toString()"
            >
              <template v-for="(child, ind) of item.children">
                <!-- title -->
                <el-menu-item-group
                  v-if="child.menu_type_id === 'title' && child.is_visible"
                  :key="child.id"
                >
                  <template slot="title">{{child.name }}</template>
                </el-menu-item-group>
                <!-- separator -->
                <el-divider
                  :key="child.id"
                  v-if="child.menu_type_id === 'separator' && child.is_visible"
                ></el-divider>

                <el-menu-item
                  v-if="child.children.length === 0 && child.menu_type_id !== 'separator' && child.menu_type_id !== 'title' && child.is_visible"
                  :index="ind.toString()+ '-' + child.id.toString()"
                  :key="child.id"
                  @click="clickMenu(child.name, child.properties, child)"
                >
                  <i v-if="child.icon_type === 'css' || child.icon_type === null" class="system__menuIcon" :class="child.icon_value"></i>
                  <el-image v-else :src="$config.api + child.icon_value" style="width: 30px; height: 30px;"></el-image>
                  <span slot="title">{{child.name }}</span>
                </el-menu-item>
                <el-submenu
                  v-if="child.children.length > 0 && child.is_visible"
                  :key="child.id"
                  :index="ind.toString() + '-' + child.id.toString()"
                >
                  <template slot="title">
                    <i v-if="child.icon_type === 'css' || child.icon_type === null" class="system__menuIcon" :class="child.icon_value"></i>
                    <el-image v-else :src="$config.api + child.icon_value" style="width: 30px; height: 30px;"></el-image>
                    <span slot="title">{{child.name }}</span>
                  </template>
                  <template v-for="(a, ind2) in child.children">
                    <!-- title -->
                    <el-menu-item-group
                      v-if="a.menu_type_id === 'title' && a.is_visible"
                      :key="a.id"
                    >
                      <template slot="title">{{a.name }}</template>
                    </el-menu-item-group>
                    <!-- separator -->
                    <el-divider
                      :key="a.id"
                      v-if="a.menu_type_id === 'separator' && a.is_visible"
                    ></el-divider>

                    <el-menu-item
                      v-if="a.children.length === 0 && a.menu_type_id !== 'separator' && a.menu_type_id !== 'title' && a.is_visible"
                      :index="ind.toString() + '-' + ind2.toString() + '-' + a.id.toString()"
                      :key="a.id"
                      @click="clickMenu(a.name, a.properties, a)"
                    >
                      <i v-if="a.icon_type === 'css' || a.icon_type === null" class="system__menuIcon" :class="a.icon_value"></i>
                      <el-image v-else :src="$config.api + a.icon_value" style="width: 30px; height: 30px;"></el-image>
                      <span slot="title">{{a.name }}</span>
                    </el-menu-item>
                    <extra-menu-item
                      v-if="a.children.length > 0 && a.is_visible"
                      :item="a.children"
                      :key="a.id"
                      :id="a.id"
                      :ind2="ind2"
                      :name="a.name"
                      :clickMenu="clickMenu"
                    ></extra-menu-item>
                  </template>
                </el-submenu>
              </template>
            </el-menu>
          </div>
          <!-- меню для поиска -->
          <el-menu
            v-else
            class="el-main-menu"
            :collapse="isCollapse"
            v-for="(item, ind) in found"
            :key="item.id"
            :index="ind.toString()"
          >
            <el-menu-item-group
              v-if="item.menu_type_id === 'title' && item.is_visible"
              :key="item.id"
            >
              <template slot="title">{{item.name }}</template>
            </el-menu-item-group>
            <el-divider :key="item.id" v-if="item.menu_type_id === 'separator' && item.is_visible"></el-divider>

            <el-menu-item
              v-if="item.children.length === 0 && item.menu_type_id !== 'separator' && item.menu_type_id !== 'title' && item.is_visible"
              :index="ind.toString()+ '-' + item.id.toString()"
              :key="item.id"
              @click="clickMenu(item.name, item.properties, item)"
            >
              <i v-if="item.icon_type === 'css' || item.icon_type === null" class="system__menuIcon" :class="item.icon_value"></i>
              <el-image v-else :src="$config.api + item.icon_value" style="width: 30px; height: 30px;"></el-image>
              <span slot="title">{{item.name }}</span>
            </el-menu-item>
            <el-submenu
              v-if="item.children.length > 0 && item.is_visible"
              :key="item.id"
              :index="ind.toString() + '-' + item.id.toString()"
            >
              <template slot="title">
                <i v-if="item.icon_type === 'css' || item.icon_type === null" class="system__menuIcon" :class="item.icon_value"></i>
                <el-image v-else :src="$config.api + item.icon_value" style="width: 30px; height: 30px;"></el-image>
                <span slot="title">{{item.name }}</span>
              </template>
              <template v-for="(a, ind2) in item.children">
                <el-menu-item-group
                  v-if="a.menu_type_id === 'title' && item.is_visible"
                  :key="a.id"
                >
                  <template slot="title">{{a.name }}</template>
                </el-menu-item-group>
                <el-divider :key="a.id" v-if="a.menu_type_id === 'separator' && item.is_visible"></el-divider>
                <el-menu-item
                  v-if="a.children.length === 0 && a.menu_type_id !== 'separator' && a.menu_type_id !== 'title' && item.is_visible"
                  :index="ind.toString() + '-' + ind2.toString() + '-' + a.id.toString()"
                  :key="a.id"
                  @click="clickMenu(a.name, a.properties, a)"
                >
                  <i v-if="a.icon_type === 'css' || a.icon_type === null" class="system__menuIcon" :class="a.icon_value"></i>
                  <el-image v-else :src="$config.api + a.icon_value" style="width: 30px; height: 30px;"></el-image>
                  <span slot="title">{{a.name }}</span>
                </el-menu-item>
                <extra-menu-item
                  v-if="a.children.length > 0 && a.is_visible"
                  :item="a.children"
                  :key="a.id"
                  :id="a.id"
                  :ind2="ind2"
                  :name="a.name"
                  :clickMenu="clickMenu"
                ></extra-menu-item>
              </template>
            </el-submenu>
          </el-menu>
        </el-scrollbar>
        <el-button
          class="collapse-button"
          :style="isCollapse ? 'width: 100%' : 'width: 100%'"
          @click="isCollapse = !isCollapse"
          :icon="isCollapse ? 'el-icon-d-arrow-right' : 'el-icon-d-arrow-left'"
        >{{isCollapse ? '' : $t(`main.button.collapse`)}}</el-button>
      </el-col>
      <el-col
        :span="isCollapse ? 23 : 21"
        :style="{flexGrow: 1, width: 'calc(100% - ' + width + 'px)'}"
      >

        <div class="icon-bell_custom box_userInfo" :class="isHiddenMainTabs ? 'system_hidden' : ''">
          <notification :addMainTab="addTab" />
          <!-- name user -->
          <el-popover
            placement="top-start"
            width="220"
            popper-class='system_top_profile'
            v-model="visiblePopover"
            trigger="click">
            <div class="system_bar">
              <label v-if="isAdmin" class="check option">
                <input class="check__input" :class="{checked: isConstructorMode}" type="checkbox" @change="toggleConstructorMode">
                <span class="check__box"></span>
                {{ $t(`main.system.constructor`) }}
              </label>
              <div v-if="isProfileAvailable" @click="openAccessEditor" class="system top_profile">{{ $t(`main.system.profile_user`) }}</div>
              <div v-if="isAdmin" class="system top_profile" @click="dialogVisible = true">{{ $t(`main.system.about_system`) }}</div>
              <div v-if="isAdmin" class="system top_profile" @click="openDashboardAdmin">{{ $t(`main.system.setting_system`) }}</div>
              <el-divider></el-divider>
              <div class="system top_profile footer"  @click="signOut">{{$t('main.button.sign_out')}}</div>
            </div>

            <div slot="reference" class="box_fullName">
              <span class="fullNameUser">{{fullNameUser}}</span>
              <el-avatar v-if="this.avatarUser != null" fit="cover" size="medium" :src="this.$config.api + `/files/${this.getFilePath(this.avatarUser)}`"></el-avatar>
              <el-avatar v-if="this.avatarUser == null" size="medium" icon="el-icon-user-solid"></el-avatar>
            </div>
          </el-popover>
        </div>
        <el-tabs
          :class="isHiddenMainTabs ? 'system_hiddenTab' : ''"
          class="system__tabs"
          style="height: 100vh;display: flex;flex-direction: column;"
          v-model="activeTab"
          type="card"
          closable
          @tab-remove="closeTab"
        >
          <el-tab-pane
            style="height: 100%;"
            v-for="tab in tabs"
            :key="tab.guid"
            :label="tab.title"
            :name="tab.guid"
            :lazy="true"
          >
            <span slot="label" style="user-select: none;" @contextmenu.prevent="showTabContextMenu($event, tab)">
              <i v-if="tab.icon" :class="tab.icon"></i>
              {{tab.title}}
            </span>
            <el-main :class="isHiddenMainTabs ? 'system_mainBox100' :'system_mainBox'" class="custom_scrollbar" >
              <component :ref="`${tab.guid}_component`" :is="tab.component" v-bind="tab.payload"></component>
            </el-main>
            <div v-if="(tab.modal || {}).show" style="position: absolute;
                z-index: 9998;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5);
                display: inline-block;
                transition: opacity 0.3s ease;"
            >
              <div style="width: 100%;height:100%;vertical-align: middle;display: flex;align-items: center" @click="closeModal(false)">
                <div style="width: 80%;
                        height: 80%;
                        overflow: hidden;
                        margin: 0px auto;
                        background-color: #fff;
                        border-radius: 2px;
                        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
                        transition: all 0.3s ease;" @click="closeModal(true)"
                        name="modal_window">
                    <component :is="tab.modal.component" v-bind="tab.modal.payload" v-on="tab.modal.listeners"></component>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
    <about-system :dialogVisible.sync="dialogVisible" :closeDialog="closeDialog"/>
    <vue-context ref="tab_menu" class="el-dropdown-menu  el-dropdown-menu--small tab-menu" v-slot="{ data }">
      <div class="el-dropdown-menu__item" @click="duplicateTab(data)">
        <i class="el-icon-document-copy"></i>{{ $t(`main.button.duplicate`) }}
      </div>
      <div class="el-dropdown-menu__item" @click="updateTab(data)">
        <i class="el-icon-refresh"></i>{{ $t(`main.button.update`) }}
      </div>
    </vue-context>
  </div>
</template>

<script>
import { VueContext } from 'vue-context'
import 'vue-context/src/sass/vue-context.scss'
import Registry from '@/components/Registry'
import WelcomePage from '@/components/WelcomePage'
import CardEditor from '@/components/CardEditor'
import RegistryCard from '@/components/RegistryCard'
import ObjectEditor from '@/components/ObjectEditor'
import MenuEditor from '@/components/MenuEditor'
import AccessEditor from '@/components/AccessEditor'
import AccessEditor_v2 from '@/services/AccessEditor'
import LogicEditor_v2 from '@/services/LogicEditor'
import CssEditor from '@/services/CssEditor'
import EtlEditor_v2 from '@/services/EtlEditor'
import IFrame from '@/components/IFrame'
import ReportEditor from '@/components/ReportEditor'
import MenuItem from '../System/MenuItem'
import AboutSystem from './AboutSystem'
import EtlEditor from '@/components/EtlEditor'
import BIEditor from '@/components/BIEditor'
import DashboardEditor from '@/components/DashboardEditor'
import MapEditor from '@/services/MapEditor'
import DigitalTwinEditor from '@/services/DigitalTwinEditor'
import RasterLibrary from '@/services/RasterLibrary'
import Databus from '@/services/Databus'
import ReleaseEditor from '@/services/ReleaseEditor'
import Dashboard from '@/components/Dashboard'
import DocumentEditor from '@/components/DocumentEditor'
import DocumentViewer from '@/components/DocumentViewer'
import XmlEditor from '@/components/XmlEditor'
import JsonEditor from '@/components/JsonEditor'
import XmlViewer from '@/components/XmlViewer'
import NotificationEditor from '@/services/NotificationEditor'
import DashboardEditorViewer from '@/components/DashboardEditor/viewer'
import StimulsoftReport from '@/components/StimulsoftReport'
import StimulsoftViewer from '@/components/StimulsoftViewer'
import RegistryAddExisting from '@/components/Registry/RegistryAddExisting'
import NotificationCenter from '@/components/System/Notification/NotificationCenter'
import History from '@/services/History'
import AddressService from '@/services/AddressService'
import TaskEditor from '@/services/TaskEditor'
import SyncService from '@/services/SyncService'
import ActivityService from '@/services/ActivityService'
import LogService from '@/services/LogService'
import store from '@/store/index'
import VueNativeSock from 'vue-native-websocket'
import Vue from 'vue'
import Reports from '../MenuEditor/Models/report/Reports'
import DashboardAdmin from '@/services/DashboardAdmin/index.vue'
import InterfaceEditor from '@/services/InterfaceEditor/index.vue'
import ChangeLog from '@/core/infrastructure/components/ChangeLog.vue'
import BPMNEditorApprovals from '@/services/BPMNEditorApprovals'
import ProcessEditor from '@/services/ProcessEditor'
import GarAddressService from '@/services/GarAddressService'
import Notification from '@/components/System/Notification/Notification.vue'
import BPMNEditor from '@/services/BPMNEditor'
import ApiService from '@/services/ApiService'
import PdfSign from '@/components/InterfaceEditor/components/basic/PDFSign/pdf-sign'

import CardsWrapper from '@/components/Registry/CardsWrapper.vue'

// import Users from '@/components/AccessEditor/Models/User'
import PluginFactory from '../../core/infrastructure/service/PluginFactory'
import EventFactory from '../../core/infrastructure/service/EventFactory'
import RoutingService from '@/services/InterfaceViewer/RoutingService'

// API
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { UserAPI } from '@/services/AccessEditor/infrastructure/api/UserAPI'
import { RoleAPI } from '@/services/AccessEditor/infrastructure/api/RoleAPI'
const DEFAULT_TABS = [
  {
    title: '',
    icon: 'el-icon-s-home',
    component: 'WelcomePage',
    payload: {},
    guid: 'welcome-page'
  }
]

export default {
  name: 'System',
  components: {
    Registry,
    WelcomePage,
    CardEditor,
    RegistryCard,
    ObjectEditor,
    MenuEditor,
    AccessEditor,
    LogicEditor_v2,
    CssEditor,
    IFrame,
    ReportEditor,
    'extra-menu-item': MenuItem,
    AboutSystem,
    EtlEditor,
    EtlEditor_v2,
    BIEditor,
    DashboardEditor,
    MapEditor,
    DigitalTwinEditor,
    RasterLibrary,
    AccessEditor_v2,
    Dashboard,
    StimulsoftReport,
    StimulsoftViewer,
    Notification,
    NotificationCenter,
    NotificationEditor,
    DashboardEditorViewer,
    DocumentEditor,
    DocumentViewer,
    Databus,
    ReleaseEditor,
    XmlEditor,
    JsonEditor,
    XmlViewer,
    VueContext,
    History,
    RegistryAddExisting,
    TaskEditor,
    SyncService,
    AddressService,
    ActivityService,
    LogService,
    DashboardAdmin,
    InterfaceEditor,
    ChangeLog,
    BPMNEditorApprovals,
    ProcessEditor,
    CardsWrapper,
    GarAddressService,
    BPMNEditor,
    ApiService,
    'pdf-sign': PdfSign
  },
  props: {
    openDashboardId: String
  },
  watch: {
    searchMenu: {
      handler: function (val, oldval) {
        if (val) {
          this.found = []
          this.deepSearch(this.menu[0].children, val.trim().toLowerCase())
        } else {
          this.found = []
        }
      }
    },

    isAuthenticated: {
      handler: function (val) {
        if (!val) this.$store.dispatch('WSDisconnect')
      },
      immediate: true
    },

    isCollapse: {
      handler: function (value) {
        localStorage.setItem('isCollapse', String(value))
      }
    }
  },

  data () {
    return {
      width: '',
      mainLogo: {},
      menu: [],
      activeTab: '',
      tabs: [],
      searchMenu: '',
      fullNameUser: 'ФИО пользователя',
      avatarUser: null,
      avatarNotify: null,
      found: [],
      dialogVisible: false,
      loading: true,
      isClickedToModalContent: false,
      userAdmin: false,
      visiblePopover: false,
      profileAvailable: false,
      isCollapse: null,
      isHiddenMainTabs: false
    }
  },

  computed: {
    isAuthenticated () {
      return this.$store.getters['Authorization/isAuthenticated']
    },
    isConstructorMode () {
      return this.$store.getters['ConstructorMode/getConstructorMode']
    },
    mainLogoUrl () {
      if (this.mainLogo && this.mainLogo.logoUrl) {
        return `url(${this.$config.api}/files/${this.getFilePath(this.mainLogo.logoUrl)})`
      } else {
        return null
      }
    },
    mainLogoSize () {
      if (this.mainLogo && this.mainLogo.sizeLogo) {
        return this.mainLogo.sizeLogo
      } else {
        return false
      }
    },
    miniLogoUrl () {
      if (this.mainLogo && this.mainLogo.logoUrlMini) {
        return `url(${this.$config.api}/files/${this.getFilePath(this.mainLogo.logoUrlMini)})`
      } else {
        return null
      }
    },
    miniLogoSize () {
      if (this.mainLogo && this.mainLogo.sizeLogoMini) {
        return this.mainLogo.sizeLogoMini
      } else {
        return false
      }
    },
    showMenu () {
      if (this.menu[0] && this.menu[0].children) {
        return this.menu[0].children.length > 0
      } else {
        return false
      }
    },
    project () {
      return this.$config.project
    },
    isAdmin () {
      return this.userAdmin
    },
    isProfileAvailable () {
      return this.profileAvailable
    }
  },

  provide () {
    return {
      addMainTab: this.addTab,
      openTabModalWindow: this.openTabModalWindow,
      updateTab: this.updateTab,
      tabs: this.tabs,
      activeTab: this.getActiveTab,
      closeTab: this.closeTab
    }
  },

  mounted () {
    if (localStorage.getItem('isCollapse') === null) {
      localStorage.setItem('isCollapse', 'true')
    }

    this.isCollapse = localStorage.getItem('isCollapse') === 'true'
  },

  methods: {
    closeModal (isClickedToModalContent = false) {
      if (isClickedToModalContent) {
        this.isClickedToModalContent = true
      } else {
        if (this.isClickedToModalContent) {
          this.isClickedToModalContent = false
        } else {
          let tab = this.tabs.find((item) => item.guid === this.activeTab)
          if (!tab) {
            return false
          }
          tab.modal.component = null
          tab.modal.payload = {}
          tab.modal.show = false
        }
      }
    },
    openTabModalWindow (component, payload = {}, listeners = {}) {
      let tab = this.tabs.find((item) => item.guid === this.activeTab)
      if (!tab) {
        return false
      }
      this.$set(payload, 'modal', tab.modal)

      tab.modal.component = component
      tab.modal.payload = payload
      tab.modal.listeners = listeners
      tab.modal.show = true
      tab.modal.close = this.closeModal
    },
    duplicateTab (tab) {
      this.addTab({
        name: tab.title,
        componentType: tab.component,
        payload: tab.payload,
        beforeClose: tab.beforeClose,
        force: true
      })
    },
    updateTab (tab) {
      this.activeTab = tab.guid = this.generateGuid()
    },
    showTabContextMenu (event, tab) {
      this.$refs['tab_menu'].open(event, tab)
    },
    async signOut () {
      await EventFactory.send(
        this,
        {
          eventType: 'logout'
        }
      )
      await EventFactory.closeConnection('user logout')
      await this.$http.post(`${this.$config.api}/accesseditor/logout`, {}, {
        hideNotification: true
      }).finally(() => {
        this.$store.dispatch('Authorization/logout').then(() => {
          this.$router.push('/login')
        })
      })
    },
    // фильтр меню
    deepSearch (arr, str) {
      let flatten = this.toFlatMenuData(this.menu[0].children)
      flatten.map(item => {
        if (item.name.toLowerCase().search(str) !== -1) {
          let isVisible = this.findIsVisibleForNestedMenu(item.id, flatten)
          if (isVisible) {
            this.found.push(item)
          }
        }
      })
    },
    async clickMenu (name, properties, child) {
      let payload = { 'id': null, 'value': null }
      const component = JSON.parse(JSON.stringify(properties[0]))

      if (component && component.value) {
        payload.id = component.value

        if (child.menu_type_id === 'object') {
          component.value = 'Registry'
          payload.title = JSON.parse(JSON.stringify(properties[1].value))
        }
        if (child.menu_type_id === 'url') {
          payload.url = component.value
          component.value = 'IFrame'
        }
        if (child.menu_type_id === 'report') {
          delete payload.id
          delete payload.value
          let report = await Reports.get()
          report.forEach(function (report) {
            if (report.id === component.value) payload.filename = `${report.guid}.mrt`
          })
          component.value = 'StimulsoftViewer'
        }
        if (child.menu_type_id === 'dashboard') {
          component.value = 'Dashboard'
        }

        if (child.menu_type_id === 'plugin') {
          let plugin = await PluginFactory.build(this.$config.project, component.value, this)
          plugin.execute()
          return false
        }

        if (this?.$eventSocket?.readyState === 1) {
          await EventFactory.send(
            this,
            {
              eventType: 'open_menu',
              entityId: payload.id,
              entityType: component.value,
              entityName: name
            }
          )
        }

        this.addTab({ name: name, componentType: component.value, payload })
      } else {
        return false
      }
    },
    async addTab ({
      name,
      componentType,
      payload,
      isActivate = true,
      force = false,
      beforeClose = null
    }) {
      /* let loader = () => {}
      try {
        await this.loadComponent(componentType)
        loader = () => this.loadComponent(componentType)
      } catch (e) {
        try {
          await this.loadComponent(componentType, 'services')
          loader = () => this.loadComponent(componentType, 'services')
        } catch (e) {
          console.warn(`component ${componentType} not found`)
          return false
        }
      }
      let component = loader */
      let guid = this.generateGuid()
      let tab = {
        title: name,
        guid: guid,
        component: componentType,
        payload: payload,
        beforeClose: beforeClose,
        modal: {
          show: false,
          component: null,
          payload: {}
        }
      }
      let issetTab = this.tabs.find((item) => (
        item.component === componentType &&
        JSON.stringify(Object.entries(item.payload || {}).sort((a, b) => b[1] - a[1])) === JSON.stringify(Object.entries(payload || {}).sort((a, b) => b[1] - a[1]))
      ))
      if (issetTab && isActivate && !force) {
        this.activeTab = issetTab.guid
      } else {
        this.tabs.push(tab)
        if (isActivate) {
          this.activeTab = guid
        }
      }
      await EventFactory.send(
        this,
        {
          eventType: 'open_tab',
          entityId: payload?.id ?? null,
          entityType: componentType,
          entityName: name
        }
      )
    },
    loadComponent (type, path = 'components') {
      if (!type) {
        return null
      }
      // eslint-disable-next-line no-undef
      return import(`@/${path}/${type}`)
    },
    async closeTab (guid) {
      let currentTab = this.tabs.find(tab => tab.guid === guid)
      if (typeof currentTab.beforeClose === 'function' && this.$refs[`${guid}_component`]) {
        await currentTab.beforeClose(this.$refs[`${guid}_component`][0])
      }
      this.tabs = this.tabs.filter((tab) => {
        return tab.guid !== guid
      })
      if (this.activeTab === guid && this.tabs.length > 0) {
        this.activeTab = this.tabs[this.tabs.length - 1].guid
      }
      await EventFactory.send(
        this,
        {
          eventType: 'close_tab',
          entityId: currentTab?.payload?.id ?? null,
          entityType: currentTab?.component,
          entityName: currentTab?.title
        }
      )
    },
    closeDialog () {
      this.dialogVisible = false
    },
    openDashboardAdmin () {
      this.addTab({ name: this.$t('main.system.setting_system'), componentType: 'DashboardAdmin' })
      this.visiblePopover = false
    },
    openAccessEditor () {
      this.addTab({ name: this.$t('main.system.profile_user'), componentType: 'AccessEditor_v2', payload: { isProfile: true } })
      this.visiblePopover = false
    },
    getActiveTab () {
      return this.activeTab
    },
    toggleConstructorMode () {
      this.$store.commit('ConstructorMode/toggleConstructorMode', this.isConstructorMode)
    },
    waitForSocketConnection (socket, callback, i = 0) {
      let me = this
      if (i === 10) return
      setTimeout(function () {
        if (socket.readyState === 1) {
          if (typeof callback === 'function') callback()
        } else {
          me.waitForSocketConnection(socket, callback, ++i)
        }
      }, 3000)
    },
    findIsVisibleForNestedMenu (id, data) {
      let isVisible = false
      let parent = data.find(item => item.id === id)

      if (parent.parent_id !== 1) {
        isVisible = this.findIsVisibleForNestedMenu(parent.parent_id, data)
      } else {
        isVisible = parent.is_visible
      }

      return isVisible
    },
    toFlatMenuData (data) {
      let result = []

      data.map(item => {
        if (item.children.length === 0) {
          result.push(item)
        } else {
          result.push(item)
          result = result.concat(this.toFlatMenuData(item.children))
        }
      })
      return result
    },
    loadRoutes () {
      let defaultDashboardId = null

      if (this.openDashboardId && /^\d+$/.test(this.openDashboardId)) {
        RoutingService.location = window.location.pathname.replace(
          `dashboard/${this.openDashboardId}`, ''
        )
        defaultDashboardId = this.openDashboardId
      }
      const redirectUrl = localStorage.getItem('redirect_url')
      if (redirectUrl) {
        let routes = redirectUrl.split('/').filter(_ => !!_)
        const type = routes.shift()
        const entityId = routes.shift()
        if (type === 'dashboard' && entityId && /^\d+$/.test(entityId)) {
          defaultDashboardId = entityId
          window.history.replaceState({
            redirectUrl: redirectUrl
          }, document.title, window.location.origin + redirectUrl)
          RoutingService.location = routes.join('/')
          localStorage.removeItem('redirect_url')
        }
      }

      if (defaultDashboardId) {
        this.addTab({
          icon: 'el-icon-s-home',
          componentType: Dashboard,
          payload: { id: parseInt(defaultDashboardId), value: 'Dashboard' },
          isActivate: true
        })
      }
    },
    stringToBoolean (value) {
      if (typeof value !== 'string') {
        return value
      }
      switch (value?.toLowerCase()?.trim()) {
        case 'true':
        case 'yes':
        case '1':
          return true

        case 'false':
        case 'no':
        case '0':
          return false

        default:
          return value
      }
    }
  },
  async created () {
    // console.log(`Conneсt user ID ${this.$store.getters['Authorization/userId']}`)
    let protocol = (window.location.protocol === 'https:') ? 'wss' : 'ws'
    Vue.use(VueNativeSock, `${protocol}://${config.api.substring(7)}/${config.notification_sender}/socket`, {
      store: store,
      reconnection: true, // (Boolean) whether to reconnect automatically (false)
      reconnectionAttempts: 4, // (Number) number of reconnection attempts before giving up (Infinity),
      reconnectionDelay: 3000, // (Number) how long to initially wait before attempting a new (1000)
      connectManually: true,
      protocol: localStorage.getItem('user-token')
    })
    // статус пользователя
    const userId = JSON.parse(atob(localStorage.getItem('user-token').split('.')[1])).user.id
    // let userData = await Users.find(userId)
    // this.userAdmin = userData.isAdmin
    let userData = null
    try {
      userData = await APIClient.shared.request(new UserAPI.GetUserById(userId))
      this.userAdmin = userData[0].is_admin
    } catch (error) {
      console.log({ error })
    }
    // Убрать "шестренку" при входе в систему
    this.$store.commit('ConstructorMode/toggleConstructorMode', true)
    // меню
    this.$http
      .get(`${this.$config.api}/menueditor/menu/usermenu`)
      .then(response => {
        this.menu = response.data
        let properties = []
        let responseProperties = response.data[0].properties

        responseProperties.forEach(function (responseProperties) {
          properties[responseProperties.id] = responseProperties.value
        })

        if (localStorage.getItem('isCollapse') === null) {
          localStorage.setItem('is_collapsed', String(properties.is_collapsed))
        }

        this.isCollapse = this.stringToBoolean(properties.is_collapsed)

        this.width = properties.width

        if (properties && properties.logo_size) this.$set(this.mainLogo, 'sizeLogo', properties.logo_size)
        if (properties && properties.logo_id) this.$set(this.mainLogo, 'logo_id', properties.logo_id)
        if (properties && properties.mini_logo_size) this.$set(this.mainLogo, 'sizeLogoMini', properties.mini_logo_size)
        if (properties && properties.mini_logo_id) this.$set(this.mainLogo, 'logoIdMini', properties.mini_logo_id)
      }).then(() => {
        if (this.mainLogo && this.mainLogo.logo_id) {
          this.$http.get(`${this.$config.api}/registryservice/files/${this.mainLogo.logo_id}`)
            .then(response => {
              this.$set(this.mainLogo, 'logoUrl', response.data)
            })
            .catch(error => console.log(error))
        }
        if (this.mainLogo && this.mainLogo.logoIdMini) {
          this.$http.get(`${this.$config.api}/registryservice/files/${this.mainLogo.logoIdMini}`)
            .then(response => {
              this.$set(this.mainLogo, 'logoUrlMini', response.data)
            })
            .catch(error => console.log(error))
        }
      })
      .catch(error => console.log(error))
      .finally(() => { this.loading = false })
    // список дашбордов по умолчанию
    const roleId = JSON.parse(atob(localStorage.getItem('user-token').split('.')[1])).user.role_id
    // меню профиль сотрудника
    try {
      let role = await APIClient.shared.request(new RoleAPI.GetRoleById(roleId))
      this.profileAvailable = role.is_profile_available
      this.isHiddenMainTabs = role.is_hide_top_menu
      let { dashboards } = role
      if (dashboards.length) {
        let results = await Promise.all(dashboards.map(async (id) => {
          let object = {}
          let res = await this.$http.get(`${config.api}/interfaceeditor/dashboards/${id}`)
          object.guid = res.data.guid
          object.title = res.data.name
          object.component = 'Dashboard'
          object.payload = { id: id, value: 'Dashboard' }
          return object
        }))

        results.forEach((item, index) => {
          this.addTab({ name: item.title, componentType: item.component, payload: item.payload, isActivate: index === 0 })
        })
      } else {
        this.tabs = DEFAULT_TABS
        this.activeTab = this.tabs[0].guid
      }
    } catch (error) {
      console.log({ error })
    }

    const avatar = localStorage.getItem('avatar')
    if (avatar !== 'null' && avatar !== null) {
      this.$http
        .get(`${this.$config.api}/registryservice/files/${avatar}`)
        .then(response => {
          this.avatarUser = response.data
        })
        .catch(error => console.log(error))
    }
    if (localStorage.getItem('changePassword') === 'true' && this.$store.getters['Authorization/changePassword']) {
      this.$notify({
        title: this.$locale.main.message.attention,
        message: this.$locale.access_editor.users_item.need_change_pass,
        type: 'warning',
        duration: 0
      })
    }
    this.fullNameUser = localStorage.getItem('nameUser')
    if (localStorage.getItem('user-token')) {
      this.$store.dispatch('WSConnect')
    }
    this.loadRoutes()
    EventFactory.connect()
  }
}
</script>

<style src="./System.css">
</style>
<style src="./StyleNotify.css">
</style>
