export interface ExtractorState {
  isLoading: boolean
  location: string
  deletedExtractorGuid: string
  error: object
}

export const initialState = (): ExtractorState => {
  return {
    isLoading: false,
    location: null,
    deletedExtractorGuid: null,
    error: null
  }
}
