import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'

export namespace RecordAPI {

  export class DeleteRecord implements APIRequest<string> {
    response: string
    path: string
    method = HTTPMethod.DELETE
    parse = (data: AxiosResponse) => data.data
    hideNotification = true
    constructor (registryId: number, recordId: number) {
      this.path = `registryservice/registry/${registryId}/records/${recordId}`
    }
  }
}
