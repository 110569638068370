import ProjectCommandRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/ProjectCommandRepositoryInterface'
import { ProjectDTO, ProjectBuildDTO } from '@/services/DigitalTwinEditor/domain/model/Project/Project'
import { TemplateDTO } from '@/services/DigitalTwinEditor/domain/model/Project/Template'
import store from '@/store'
import { UpdateProject, BuildProject, DownloadLastBuild, DownloadPrj, AttachTemplate, DetachTemplate } from '@/services/DigitalTwinEditor/infrastructure/store/modules/Project/action-types'

export default class ProjectCommandRepository implements ProjectCommandRepositoryInterface
{
    update(item: ProjectDTO): Promise<void>
    {
        return store.dispatch(new UpdateProject(item));
    }

    buildProject(item: ProjectDTO): Promise<void>
    {
        return store.dispatch(new BuildProject(item));
    }

    downloadLastBuild(item: ProjectBuildDTO): Promise<void>
    {
        return store.dispatch(new DownloadLastBuild(item));
    }

    downloadPrj(item: ProjectDTO): Promise<void>
    {
        return store.dispatch(new DownloadPrj(item));
    }

    attachTemplateToProject(item: TemplateDTO): Promise<void>
    {
        return store.dispatch(new AttachTemplate(item));
    }

    detachTemplateFromProject(item: TemplateDTO): Promise<void>
    {
        return store.dispatch(new DetachTemplate(item));
    }
}
