<template>
  <div class="xml_viewer">
    <codemirror class="code_box" :value="content"  :options="options"></codemirror>
  </div>
</template>

<script>
export default {
  name: 'XmlViewer',

  props: {
    content: {
      type: String
    }
  },

  data () {
    return {
      options: {
        tabSize: 4,
        mode: 'application/xml',
        theme: 'base16-light',
        lineNumbers: true,
        line: true,
        collapseIdentical: true,
        highlightDifferences: false,
        readOnly: true
      }
    }
  }
}
</script>

<style type="text/css">
.xml_viewer {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.xml_viewer .code_box {
  height: calc(100vh - 42px);
}
.xml_viewer .code_box .CodeMirror {
  width: 100%;
  height: 100%;
}
.xml_viewer .code_box .cm-s-base16-light.CodeMirror {
  background: #ffffff;
  color: #202020;
}
.xml_viewer .code_box .CodeMirror-gutters {
  z-index: 2;
}
.xml_viewer .code_box .cm-s-base16-light .CodeMirror-gutters {
  border-right: 1px solid #e4e7ed;
}
</style>
