export default {
  props: {
    conditionStyles: {
      type: Array,
      default () { return [] },
      editor: 'StyleConditionsEditor'
    }
  },
  computed: {
    computedStyle () {
      let styles = []
      this.conditionStyles.forEach((item) => {
        if (item.condition && item.style) {
          let condition = JSON.parse(JSON.stringify(item.condition))
          let attributes = condition.match(/\{{(.*?)\}}/g) || []
          attributes.forEach((attribute) => {
            attribute = attribute.replace('{{', '').replace('}}', '')
            condition = condition.replace(`{{${attribute}}}`, this.data[attribute])
          })
          try {
            if (eval(condition)) {
              styles.push(item.style)
            }
          } catch (e) {
            console.info(`invalid condition: ${item.condition}, result: ${condition}`)
          }
        }
      })
      return styles.join(';')
    }
  }
}
