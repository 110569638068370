export default class ProjectBuildCommand
{
    private guid: string;
    private templateId: number;

    constructor(guid: string, templateId: number)
    {
        this.guid = guid;
        this.templateId = templateId;
    }

    getClassName(): string
    {
        return 'DigitalTwinProjectBuildCommand';
    }

    getGuid(): string
    {
        return this.guid;
    }

    getTemplate(): number
    {
        return this.templateId;
    }
}