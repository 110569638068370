import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import PointStyleImageQueryRepositoryInterface from '@/core/domain/repository/modules/PointStyleImageQueryRepositoryInterface'
import store from '@/store'
import { GetPointStyleImages, GetPointStyleImageByGuid } from '@/core/infrastructure/store/modules/PointStyleImage/action-types'

export default class PointStyleImageQueryRepository extends AbstractQueryRepository implements PointStyleImageQueryRepositoryInterface
{
    get(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new GetPointStyleImages(payload));
    }

    getByGuid(guid: string): Promise<any>
    {
        return store.dispatch(new GetPointStyleImageByGuid(guid));
    }
}