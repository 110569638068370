import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    TOPIC_LOADING_EVENT = 'topic_loading_event',
    TOPIC_LOADED_EVENT = 'topic_loaded_event',
    TOPIC_CREATED_EVENT = 'topic_created_event',
    TOPIC_UPDATED_EVENT = 'topic_updated_event',
    TOPIC_DELETED_EVENT = 'topic_deleted_event' 
}

export class TopicLoadingEvent implements FluxStandardAction {
    type = MutationTypes.TOPIC_LOADING_EVENT
}

export class TopicLoadedEvent implements FluxStandardAction {
    type = MutationTypes.TOPIC_LOADED_EVENT
}

export class TopicCreatedEvent implements FluxStandardAction {
    type = MutationTypes.TOPIC_CREATED_EVENT
    constructor(public location: string){}
}

export class TopicUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.TOPIC_UPDATED_EVENT
}

export class TopicDeletedEvent implements FluxStandardAction {
    type = MutationTypes.TOPIC_DELETED_EVENT
    constructor(public guid: string){}
}