import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    COMMAND_LOADING_EVENT = 'command_loading_event',
    COMMAND_LOADED_EVENT = 'command_loaded_event',
    COMMAND_CREATED_EVENT = 'command_created_event',
    COMMAND_UPDATED_EVENT = 'command_updated_event',
    COMMAND_DELETED_EVENT = 'command_deleted_event'
}

export class CommandLoadingEvent implements FluxStandardAction {
  type = MutationTypes.COMMAND_LOADING_EVENT
}

export class CommandLoadedEvent implements FluxStandardAction {
  type = MutationTypes.COMMAND_LOADED_EVENT
}

export class CommandCreatedEvent implements FluxStandardAction {
  type = MutationTypes.COMMAND_CREATED_EVENT
  constructor (public location: string) {}
}

export class CommandUpdatedEvent implements FluxStandardAction {
  type = MutationTypes.COMMAND_UPDATED_EVENT
  constructor (public location: string) {}
}

export class CommandDeletedEvent implements FluxStandardAction {
  type = MutationTypes.COMMAND_DELETED_EVENT
  constructor (public guid: string) {}
}
