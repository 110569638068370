import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { ProjectTreeElementDTO } from '@/services/MapEditor/domain/model/Project/ProjectTreeElement'

export enum ActionTypes {
    ADD_PROJECT_TREE_PROJECT_ELEMENT = 'add_project_tree_project_element',
    ADD_PROJECT_TREE_GROUP_ELEMENT = 'add_project_tree_group_element',
    PUT_PROJECT_TREE_PROJECT_ELEMENT = 'put_project_tree_project_element',
    DELETE_PROJECT_TREE_ELEMENT = 'delete_project_tree_element',
    GET_PROJECT_TREE_ELEMENT_BY_GUID = 'get_project_tree_element_by_guid',
    GET_PROJECT_TREE_ELEMENTS = 'get_project_tree_elements',
    UPDATE_PARENT = 'update_parent'
}

export class AddProjectTreeProjectElement implements FluxStandardAction {
    type = 'ProjectTreeElement/' + ActionTypes.ADD_PROJECT_TREE_PROJECT_ELEMENT
    constructor(public payload: ProjectTreeElementDTO){}
}

export class AddProjectTreeGroupElement implements FluxStandardAction {
    type = 'ProjectTreeElement/' + ActionTypes.ADD_PROJECT_TREE_GROUP_ELEMENT
    constructor(public payload: ProjectTreeElementDTO){}
}

export class PutProjectTreeProjectElement implements FluxStandardAction {
    type = 'ProjectTreeElement/' + ActionTypes.PUT_PROJECT_TREE_PROJECT_ELEMENT
    constructor(public payload: ProjectTreeElementDTO){}
}

export class DeleteProjectTreeElement implements FluxStandardAction {
    type = 'ProjectTreeElement/' + ActionTypes.DELETE_PROJECT_TREE_ELEMENT
    constructor(public guid: string){}
}

export class GetProjectTreeElementByGuid implements FluxStandardAction {
    type = 'ProjectTreeElement/' + ActionTypes.GET_PROJECT_TREE_ELEMENT_BY_GUID
    constructor(public guid: string){}
}

export class GetProjectTreeElements implements FluxStandardAction {
    type = 'ProjectTreeElement/' + ActionTypes.GET_PROJECT_TREE_ELEMENTS
    constructor(public parentId: Nullable<number>){}
}

export class UpdateParent implements FluxStandardAction {
    type = 'ProjectTreeElement/' + ActionTypes.UPDATE_PARENT
    constructor(public payload: ProjectTreeElementDTO){}
}