import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { ProjectDTO } from '@/services/DigitalTwinEditor/domain/model/Project/Project'
import { TemplateDTO } from '@/services/DigitalTwinEditor/domain/model/Project/Template'

export enum ActionTypes {
    GET_PROJECT_BY_GUID = 'get_project_by_guid',
    UPDATE_PROJECT = 'update_project',
    BUILD_PROJECT = 'build_project',
    DOWNLOAD_LAST_BUILD = 'download_last_build',
    DOWNLOAD_PRJ = 'download_prj',
    ATTACH_TEMPLATE = 'attach_template',
    DETACH_TEMPLATE = 'detach_template',
    GET_PROJECT_BUILDS = 'get_project_builds',
    COUNT_PROJECT_BUILDS = 'count_project_builds'
}

export class UpdateProject implements FluxStandardAction {
    type = 'DigitalTwinProject/' + ActionTypes.UPDATE_PROJECT
    constructor(public payload: ProjectDTO){}
}

export class GetProjectByGuid implements FluxStandardAction {
    type = 'DigitalTwinProject/' + ActionTypes.GET_PROJECT_BY_GUID
    constructor(public guid: string){}
}

export class BuildProject implements FluxStandardAction {
    type = 'DigitalTwinProject/' + ActionTypes.BUILD_PROJECT
    constructor(public payload: ProjectDTO){}
}

export class DownloadLastBuild implements FluxStandardAction {
    type = 'DigitalTwinProject/' + ActionTypes.DOWNLOAD_LAST_BUILD
    constructor(public payload: ProjectDTO){}
}

export class DownloadPrj implements FluxStandardAction {
    type = 'DigitalTwinProject/' + ActionTypes.DOWNLOAD_PRJ
    constructor(public payload: ProjectDTO){}
}

export class AttachTemplate implements FluxStandardAction {
    type = 'DigitalTwinProject/' + ActionTypes.ATTACH_TEMPLATE
    constructor(public payload: TemplateDTO){}
}

export class DetachTemplate implements FluxStandardAction {
    type = 'DigitalTwinProject/' + ActionTypes.DETACH_TEMPLATE
    constructor(public payload: TemplateDTO){}
}

export class GetProjectBuilds implements FluxStandardAction {
    type = 'DigitalTwinProject/' + ActionTypes.GET_PROJECT_BUILDS
    constructor (public payload: object) {}
}

export class CountProjectBuilds implements FluxStandardAction {
    type = 'DigitalTwinProject/' + ActionTypes.COUNT_PROJECT_BUILDS
    constructor (public payload: object) {}
}
