import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { SchemaTreeDTO } from '@/services/ApiService/domain/model/Schema/SchemaTreeDTO'

export namespace SchemaAPI {
  export class GetSchemas implements APIRequest<SchemaTreeDTO> {
    response: SchemaTreeDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (payload: string[][]) {
      this.path = '/api/entities_tree?' + (new URLSearchParams(payload).toString())
    }
  }
  export class CreateSchema implements APIRequest<SchemaTreeDTO> {
    response: SchemaTreeDTO;
    path = '/api/entities';
    method = HTTPMethod.POST;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: object) {}
  }
  export class DeleteSchema implements APIRequest<SchemaTreeDTO> {
    response: SchemaTreeDTO;
    path: string;
    method = HTTPMethod.DELETE;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = '/api/entities/' + guid
    }
  }
  export class UpdateSchema implements APIRequest<SchemaTreeDTO> {
    response: SchemaTreeDTO;
    path: string;
    method = HTTPMethod.PUT;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: SchemaTreeDTO) {
      this.path = `/api/entities/${params.guid}`
    }
  }
}
