import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class ListenerDTO {
  id?: Nullable<number>
  guid?: Nullable<string>
  name: string
  description: Nullable<string>
  event_id: number
  listener_cards: Array<number>
  listener_commands: Array<number>
  alias: Nullable<string>
  logic_id?: number
  parent_tree_element_id?: Nullable<number>
  run_after_transaction: Boolean = false

  constructor ({ id, guid, name, description, event_id, listener_cards, listener_commands, alias, logic_id, parent_tree_element_id, run_after_transaction }) {
    this.id = id
    this.guid = guid
    this.name = name
    this.description = description
    this.event_id = event_id
    this.listener_cards = listener_cards
    this.listener_commands = listener_commands
    this.alias = alias
    this.logic_id = logic_id
    this.parent_tree_element_id = parent_tree_element_id
    this.run_after_transaction = run_after_transaction
  }

  static create (): ListenerDTO {
    return new ListenerDTO({
      id: null,
      guid: null,
      name: null,
      description: null,
      event_id: null,
      listener_cards: [],
      listener_commands: [],
      alias: null,
      logic_id: null,
      parent_tree_element_id: null,
      run_after_transaction: false
    })
  }
}

export default class Listener extends AbstractBaseEntity {
  private id?: Nullable<number>
  private guid?: Nullable<string>
  private name: string
  private description: Nullable<string>
  private eventId: number
  private listenerCards: Array<number>
  private listenerCommands: Array<number>
  private logicId: number
  private alias: Nullable<string>
  private parentTreeElementId?: Nullable<number>
  private runAfterTransaction: Boolean = false

  constructor (
    name: string,
    description: Nullable<string>,
    eventId: number,
    listenerCards: Array<number>,
    listenerCommands: Array<number>,
    logicId: number,
    alias: Nullable<string>,
    parentTreeElementId?: Nullable<number>,
    id?: Nullable<number>,
    guid?: Nullable<string>,
    runAfterTransaction?: Boolean
  ) {
    super()
    this.id = id
    this.guid = guid
    this.name = name
    this.description = description
    this.eventId = eventId
    this.listenerCards = listenerCards
    this.listenerCommands = listenerCommands
    this.logicId = logicId
    this.alias = alias
    this.parentTreeElementId = parentTreeElementId
    this.runAfterTransaction = runAfterTransaction
  }

  static create (dto: ListenerDTO): Listener {
    return new Listener(
      dto.name,
      dto.description,
      dto.event_id,
      dto.listener_cards,
      dto.listener_commands,
      dto.logic_id,
      dto.alias,
      dto.parent_tree_element_id,
      dto.id,
      dto.guid,
      dto.run_after_transaction
    )
  }

  setId (id: number): void {
    this.id = id
  }

  getId (): number {
    return this.id
  }

  setGuid (guid: string): void {
    this.guid = guid
  }

  getGuid (): string {
    return this.guid
  }

  getName (): string {
    return this.name
  }

  setName (name: string): void {
    this.name = name
  }

  getDescription (): string {
    return this.description
  }

  setDescription (description: string): void {
    this.description = description
  }

  getEventId (): number {
    return this.eventId
  }

  setEventId (eventId: number): void {
    this.eventId = eventId
  }

  getListenerCards (): Array<number> {
    return this.listenerCards
  }

  setListenerCards (listenerCards: Array<number>): void {
    this.listenerCards = listenerCards
  }

  getListenerCommands (): Array<number> {
    return this.listenerCommands
  }

  setListenerCommands (listenerCommands: Array<number>): void {
    this.listenerCommands = listenerCommands
  }

  getLogicId (): number {
    return this.logicId
  }

  setLogicId (logicId: number): void {
    this.logicId = logicId
  }

  getAlias (): Nullable<string> {
    return this.alias
  }

  setAlias (alias: Nullable<string>): void {
    this.alias = alias
  }

  getParentTreeElementId (): Nullable<number> {
    return this.parentTreeElementId
  }

  setParentTreeElementId (parentTreeElementId?: Nullable<number>): void {
    this.parentTreeElementId = parentTreeElementId
  }

  setRunAfterTransaction (value: Boolean): void {
    this.runAfterTransaction = value
  }

  getRunAfterTransaction (): Boolean {
    return this.runAfterTransaction
  }
}
