import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import TopicQueryRepositoryInterface from '../../../domain/repository/TopicQueryRepositoryInterface'
import TopicsCountQuery from '../../query/TopicsCountQuery'

export default class TopicsCountHandler implements QueryHandlerInterface
{
    private repo: TopicQueryRepositoryInterface;

    constructor(repo: TopicQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: TopicsCountQuery): Promise<any>
    {
        return this.repo.getCount(query.getPayload());
    }
}