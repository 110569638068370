import Vue from 'vue'
export default Vue.extend({
  name: 'fileField',
  template: `
        <div
          style="display: flex; flex-direction: column; align-items: start;"
          :style="this.params.colDef.cssStyle">
          <el-link
            icon="el-icon-document"
            style="line-height: 25px;"
            type="primary"
            v-for="(value, index) in localValue"
            @click="download(value)"
            :key="index">
            {{ value.name }}
          </el-link>
        </div>
      `,
  methods: {
    download (file) {
      let link = document.createElement('a')
      let nameFile = ''
      if (file?.name) {
      // /\.(?=.*\.)/g, '' - убрать все точки кроме последней.
      // /,/g, '' - убрать запятые
        nameFile = file.name.replace(/\.(?=.*\.)/g, '').replace(/,/g, '')
      }
      link.setAttribute('href', `${file.url}?download=${nameFile}`)
      link.setAttribute('download', `${file.name}`)
      link.setAttribute('target', '_blank')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  },
  computed: {
    localValue () {
      let parsed = JSON.parse(this.params.value || '[]')
      if (parsed instanceof Array && parsed.length > 0) {
        return parsed.map(file => {
          return {
            name: this.getFileName(file),
            url: `${this.$config.api}/files/${this.getFilePath(file)}`
          }
        })
      }

      return []
    }
  }
})
