export default class AttachFileToModelCommand
{
    private modelId: number;
    private name: string;
    private file: File;

    constructor(modelId: number, name: string, file: File)
    {
        this.modelId = modelId;
        this.name = name;
        this.file = file;
    }

    getClassName(): string
    {
        return 'AttachFileToModelCommand';
    }

    getModelId(): number
    {
        return this.modelId;
    }

    getName(): string
    {
        return this.name;
    }

    getFile(): File
    {
        return this.file;
    }
}