import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'

export namespace XrefAPI {

  export class GetData implements APIRequest<[]> {
    response: [];
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (xrefId: number, params?: string[][]) {
      if (params) {
        this.path = `/registryservice/xref/${xrefId}/data?${new URLSearchParams(params).toString()}`
      } else {
        this.path = `/registryservice/xref/${xrefId}/data`
      }
    }
  }
}
