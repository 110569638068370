import { ActionTree } from 'vuex'
import { GroupState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, GroupLoadingEvent, GroupCreatedEvent, GroupUpdatedEvent, GroupDeletedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { GroupAPI } from '@/services/EtlEditor/infrastructure/api/GroupAPI'

export const actions: ActionTree<GroupState, any> = {
  [ActionTypes.GET_GROUP_BY_GUID]: async ({ commit, dispatch }, data) => {
    try {
      return await APIClient.shared.request(new GroupAPI.GetGroupByGuid(data.guid))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.CREATE_GROUP]: async ({ commit, dispatch }, data) => {
    try {
      commit(new GroupLoadingEvent())
      let location = await APIClient.shared.request(new GroupAPI.CreateGroup(data.payload))
      commit(new GroupCreatedEvent(location))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.UPDATE_GROUP]: async ({ commit, dispatch }, data) => {
    try {
      commit(new GroupLoadingEvent())
      let location = await APIClient.shared.request(new GroupAPI.UpdateGroup(data.payload))
      commit(new GroupUpdatedEvent(location))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.DELETE_GROUP]: async ({ commit, dispatch }, data) => {
    try {
      await APIClient.shared.request(new GroupAPI.DeleteGroup(data.guid))
      commit(new GroupDeletedEvent(data.guid))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  }
}
