import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { ProcedureDTO } from '@/services/LogicEditor/domain/model/Procedure'

export namespace ProcedureAPI {
  export class GetProcedures implements APIRequest<ProcedureDTO[]> {
    response: ProcedureDTO[];
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data.map(item => new ProcedureDTO(item))
    constructor (payload: string[][] | object) {
      this.path = `/v2/logiceditor/procedures?${new URLSearchParams(payload as string[][]).toString()}`
    }
  }

  export class GetProcedureByGuid implements APIRequest<ProcedureDTO> {
    response: ProcedureDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => new ProcedureDTO(data.data)
    constructor (guid: string) {
      this.path = `/v2/logiceditor/procedures/${guid}`
    }
  }

  export class CreateProcedure implements APIRequest<string> {
    response: string;
    path = '/v2/logiceditor/procedures';
    method = HTTPMethod.POST;
    hideNotification = false;
    parse = (data: AxiosResponse): string => data.headers.location
    constructor (public params: ProcedureDTO) {}
  }

  export class UpdateProcedure implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.PUT;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: ProcedureDTO) {
      this.path = `/v2/logiceditor/procedures/${params.guid}`
    }
  }

  export class DeleteProcedure implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.DELETE;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/logiceditor/procedures/${guid}`
    }
  }
}
