import { ActionTree } from 'vuex'
import { LayerTreeElementState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, LayerTreeElementCreatedEvent, LayerTreeElementLoadingEvent, LayerTreeElementDeletedEvent, LayerTreeElementUpdatedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { LayerTreeElementAPI } from '@/services/MapEditor/infrastructure/api/LayerTreeElementAPI'

export const actions: ActionTree<LayerTreeElementState, any> = {
    [ActionTypes.ADD_LAYER_TREE_LAYER_ELEMENT]: async ({ commit, dispatch }, data) => {
        try {
            commit(new LayerTreeElementLoadingEvent());
            let location = await APIClient.shared.request(new LayerTreeElementAPI.AddLayerTreeLayerElement(data.payload));
            commit(new LayerTreeElementCreatedEvent(location));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.PUT_LAYER_TREE_LAYER_ELEMENT]: async ({ commit, dispatch }, data) => {
        try {
            commit(new LayerTreeElementLoadingEvent());
            let location = await APIClient.shared.request(new LayerTreeElementAPI.PutLayerTreeLayerElement(data.payload));
            commit(new LayerTreeElementCreatedEvent(location));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.ADD_LAYER_TREE_GROUP_ELEMENT]: async ({ commit, dispatch }, data) => {
        try {
            commit(new LayerTreeElementLoadingEvent());
            let location = await APIClient.shared.request(new LayerTreeElementAPI.AddLayerTreeGroupElement(data.payload));
            commit(new LayerTreeElementCreatedEvent(location));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.DELETE_LAYER_TREE_ELEMENT]: async ({ commit, dispatch }, data) => {
        try {
            await APIClient.shared.request(new LayerTreeElementAPI.DeleteLayerTreeElement(data.guid, data.payload));
            commit(new LayerTreeElementDeletedEvent(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }         
    },
    [ActionTypes.GET_LAYER_TREE_ELEMENT_BY_GUID]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new LayerTreeElementAPI.GetLayerTreeElementByGuid(data.guid));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        } 
    },
    [ActionTypes.GET_LAYER_TREE_ELEMENTS]: async ({ commit, dispatch }, data) => {
        try {
            return await APIClient.shared.request(new LayerTreeElementAPI.GetLayerTreeElements(data.parentId, data.projectId));
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },
    [ActionTypes.UPDATE_PARENT]: async ({ commit, dispatch }, data) => {
        try {
            commit(new LayerTreeElementLoadingEvent());
            await APIClient.shared.request(new LayerTreeElementAPI.UpdateParent(data.payload));
            commit(new LayerTreeElementUpdatedEvent());
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    }
}
