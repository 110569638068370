import { FluxStandardAction, Nullable } from '@/core/domain/type/types'

export enum ActionTypes {
    GET_ENTITIES = 'get_entities',
    GET_ENTITY_BY_ID = 'get_entity_by_id',
}

export class GetEntities implements FluxStandardAction {
    type = 'Entity/' + ActionTypes.GET_ENTITIES
    constructor(public payload: string[][]){}
}

export class GetEntityById implements FluxStandardAction {
    type = 'Entity/' + ActionTypes.GET_ENTITY_BY_ID
    constructor(public id: number){}
}