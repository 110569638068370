import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import ProjectQueryRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/ProjectQueryRepositoryInterface'
import store from '@/store'
import { GetProjectByGuid } from '@/services/DigitalTwinEditor/infrastructure/store/modules/Project/action-types'

export default class ProjectQueryRepository extends AbstractQueryRepository implements ProjectQueryRepositoryInterface
{
    getByGuid(guid: string): Promise<any>
    {
        return store.dispatch(new GetProjectByGuid(guid));
    }
}