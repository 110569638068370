import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { ConnectionDTO } from '@/services/AccessEditor/domain/model/Connection/Connection'

export enum ActionTypes {
    ADD_CONNECTION = 'add_connection',
    UPDATE_CONNECTION = 'update_connection',
    DELETE_CONNECTION = 'delete_connection',
    GET_CONNECTIONS = 'get_connections',
    GET_COUNT_CONNECTIONS = 'get_count_connections',
    GET_CONNECTION_PROPERTIES = 'get_connection_properties'
}

export class AddConnection implements FluxStandardAction {
    type = 'Connection/' + ActionTypes.ADD_CONNECTION
    constructor (public payload: ConnectionDTO) {}
}

export class UpdateConnection implements FluxStandardAction {
    type = 'Connection/' + ActionTypes.UPDATE_CONNECTION
    constructor (public payload: ConnectionDTO) {}
}

export class DeleteConnection implements FluxStandardAction {
    type = 'Connection/' + ActionTypes.DELETE_CONNECTION
    constructor (public guid: string) {}
}

export class GetConnections implements FluxStandardAction {
    type = 'Connection/' + ActionTypes.GET_CONNECTIONS
    constructor (public payload: object) {}
}

export class GetCountConnections implements FluxStandardAction {
    type = 'Connection/' + ActionTypes.GET_COUNT_CONNECTIONS
    constructor (public payload: object) {}
}

export class GetConnectionProperties implements FluxStandardAction {
    type = 'Connection/' + ActionTypes.GET_CONNECTION_PROPERTIES
    constructor (public payload: object) {}
}
