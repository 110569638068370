<template>
  <div>
    <el-form-item label="Выбрать расширенную таблицу">
      <el-select
        style="width: 100%"
        size="medium"
        value-key="name"
        v-model="content.extendedObjectId"
        filterable
        clearable
        :placeholder="$locale.report_editor.object_types.extended_object"
        @clear="clearExtendedObjectId"
      >
        <el-option
          v-for="item in listExtendObject"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        <span style="float: left">{{ item.name }}</span>
        <span style="float: right; color: #8492a6; font-size: 13px">ID: {{ item.id }}</span>
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="Выбрать поле">
      <el-select
         value-key="name"
         v-model="content.selectExtendedField"
        :disabled="_isDisabled"
        @change="selectExtendedField"
      >
        <el-option
          v-for="item in fieldExtendObject"
          :key="item.id"
          :label="item.name"
          :value="item"
        >
        </el-option>
      </el-select>
    </el-form-item>
  </div>
</template>

<script>
// Описание. Компонент для отображения спика расширенных таблиц и выбор поля выбранной таблицы.

// API
import ExtendedObject from '@/components/BIEditor/Models/ExtendedObject'
import ExtendedObjectField from '@/components/BIEditor/Models/ExtendedObjectField'

export default {
  name: 'extended-object',
  props: {
    model: {
      type: Object
    }
  },
  data () {
    return {
      content: this.model,
      listExtendObject: [],
      fieldExtendObject: []
    }
  },
  computed: {
    _isDisabled () {
      return !this.content?.extendedObjectId
    }
  },
  watch: {
    'content.extendedObjectId': {
      async handler (value) {
        if (value) {
          let response = await this.getExtendedObjectsFields()
          this.fieldExtendObject = response.map((el) => ({ id: el.id, name: el.name }))
          this.content.selectExtendedField = {}
          this.content.value = ''
          this.$emit('change', this.content)
        }
      }
    }
  },
  methods: {
    clearExtendedObjectId () {
      this.content.selectExtendedField = {}
      this.content.value = ''
      this.$emit('change', this.content)
    },
    async getExtendedObjects () {
      let response = await new ExtendedObject().$get()
      return response
    },
    async getExtendedObjectsFields () {
      let response = await new ExtendedObjectField().params({ extended_object_id: this.model.extendedObjectId }).$get()
      return response
    },
    selectExtendedField (e) {
      this.content.selectExtendedField = e
      // content.value нужен для сохранения старых атрибутов
      this.content.value = e.name
      this.$emit('change', this.content)
    }
  },
  async mounted () {
    this.listExtendObject = await this.getExtendedObjects()
    if (this.model.extendedObjectId) {
      let response = await this.getExtendedObjectsFields()
      this.fieldExtendObject = response.map((el) => ({ id: el.id, name: el.name }))
    }
  }
}
</script>
