<template>
  <tree-select
    :title="content.selectField"
    ref="treeselect"
    v-model="content.selectField"
    class="custom_scrollbar"
    :placeholder="$locale.main.placeholder.select"
    :options="dataRegistry"
    :clearable="true"
    :load-options="loadOptions"
    :normalizer="registryFieldsSettings.normalizer"
    :clear-on-select="true"
    :clear-value-text="registryFieldsSettings.clearValueText"
    :no-children-text="registryFieldsSettings.noChildrenText"
    :loading-text="$locale.main.message.loading"
    :no-options-text="registryFieldsSettings.noOptionsText"
    :no-results-text="registryFieldsSettings.noResultsText"
    :match-keys="registryFieldsSettings.matchKeys"
    :value-consists-of="registryFieldsSettings.valueConsistsOf"
    :disable-branch-nodes="true"
    :async="true"
    :disable-immediate-search="true"
    :cache-options="false"
    :append-to-body="true"
    :searchable="true"
    :defaultExpandLevel='registryFieldsSettings.isDefaultExpanded'
    @select="selectField($event)"
    @open="onMenuOpen"
    @search-change="onSearch"
    @input="input"
  >

    <template slot="before-list">
      <div v-show="showSearchTip" class="vue-treeselect__tip vue-treeselect__seacrh-promt-tip">
        <div class="vue-treeselect__icon-container">
          <span class="vue-treeselect__icon-warning" />
        </div>
        <span class="vue-treeselect__tip-text vue-treeselect__seacrh-promt-tip-text">
          {{ registryFieldsSettings.pressEnterToSearchText }}
        </span>
      </div>
    </template>

    <!-- <div slot="value-label" slot-scope="{ node }">{{ node.label }} ({{ node.id }})</div> -->
    <div slot="value-label" slot-scope="{ node }">{{ node.id }}</div>
    <label slot="option-label" slot-scope="{ node, labelClassName }" :class="labelClassName">
      <span>{{ node.label }}</span>
      <span style="font-size: 12px; margin-left: 5px;">({{ node.id }})</span>
    </label>
  </tree-select>
</template>

<script>
// Описание. Компонент для отображения реестрового дерева и их полей. С поиском.
import Treeselect, { LOAD_CHILDREN_OPTIONS, LOAD_ROOT_OPTIONS, ASYNC_SEARCH } from '@bingosoftnn/vue-treeselect'
import '@bingosoftnn/vue-treeselect/dist/vue-treeselect.css'

// API
import Entity from '@/components/ObjectEditor/Models/Entity'
export default {
  name: 'Object-tree-with-search',
  props: {
    model: {
      type: Object
    }
  },
  components: {
    'tree-select': Treeselect
  },
  data () {
    return {
      content: this.model,
      search: null,
      searchLoaded: false,
      searchLoading: false,
      dataRegistry: null,
      registryFieldsSettings: {
        matchKeys: ['name', 'id', 'label'],
        valueConsistsOf: 'LEAF_PRIORITY',
        clearValueText: 'Очистить',
        noChildrenText: 'Нет данных',
        noResultsText: 'Не найдено',
        pressEnterToSearchText: 'Для поиска нажмите Enter',
        isDefaultExpanded: 0,
        normalizer (node) {
          return {
            id: `attr_${node.id}_`,
            label: node.name,
            children: node.children,
            isLeaf: node.leaf
          }
        }
      }
    }
  },
  computed: {
    showSearchTip () {
      return this.search && !this.searchLoading && !this.searchLoaded
    }
  },
  methods: {
    onSearch (value) {
      this.registryFieldsSettings.isDefaultExpanded = 0
      this.search = value
      this.searchLoaded = false
    },
    async loadOptions ({ action, parentNode, callback }) {
      // console.log({ action, parentNode, callback })
      if (action === LOAD_CHILDREN_OPTIONS) {
        let children = await new Entity({ id: parentNode.id }).children().$get()
        // console.log({ children })
        parentNode.children = this.deepSearch(children)
        callback()
      }
      if (action === LOAD_ROOT_OPTIONS) {
        this.registryFieldsSettings.isDefaultExpanded = 0
        let response = await new Entity().params({ root: true }).$get()
        // console.log(response)
        this.dataRegistry = this.deepSearch(response)
        callback()
      }
      if (action === ASYNC_SEARCH) {
        this.searchLoaded = false
        this.searchLoading = true
        let response = await this.$http.get(`${this.$config.api}/objecteditor/entities/search?search_param=${encodeURIComponent(this.search)}&is_load_tree=true&is_formula=false`)
          .catch(() => {
            this.loading = false
          })
        this.dataRegistry = this.deepSearch(response.data.data)
        if (response.data?.data?.length === 1) {
          // Предположим что введен id поля и выберем сразу найденное значение, раскрыв дерево на один уровень
          // выбрать группу полей и реестр нельзя
          if (Number.isInteger(+this.search) && response.data.data[0].entity_type_id !== 'registry_group') {
            this.registryFieldsSettings.isDefaultExpanded = 1
            this.content.selectField = `attr_${this.search}_`
            this.content.value = `attr_${this.search}_`
          }
        } else {
          this.registryFieldsSettings.isDefaultExpanded = 0
        }

        callback(null, this.dataRegistry)
        this.searchLoading = false
        this.searchLoaded = true
      }
    },
    deepSearch (arr) {
      for (let el of arr) {
        if (el.leaf) delete el.children
        if (el.entity_type_id === 'registry_group') el.children = null
        if (el.has_children) el.children = null
        if (el.children instanceof Array) this.deepSearch(el.children)
      }

      return arr
    },
    async onMenuOpen () {
      this.registryFieldsSettings.isDefaultExpanded = 0
      let response = await new Entity().params({ root: true }).$get()
      this.dataRegistry = this.deepSearch(response)
    },
    selectField (e) {
      this.registryFieldsSettings.isDefaultExpanded = 0
      this.content.value = `attr_${e.id}_`
      this.content.selectField = `${e.name} (attr_${e.id}_)`
      this.search = ''
      // console.log('Посылаю emit change:', this.content)
      this.$emit('change', this.content)
    },
    input () {
      this.registryFieldsSettings.isDefaultExpanded = 0
      if (!this.$refs.treeselect.getValue()) {
        this.content.value = ``
        this.search = ''
        this.content.selectField = ''
        this.$emit('change', this.content)
      }
    }
  }
}

</script>
