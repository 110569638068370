import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    RELEASE_LOADING_EVENT = 'release_loading_event',
    RELEASE_LOADED_EVENT = 'release_loaded_event',
    RELEASE_CREATED_EVENT = 'release_created_event',
    RELEASE_UPDATED_EVENT = 'release_updated_event',
    RELEASE_DELETED_EVENT = 'release_deleted_event'
}

export class ReleaseLoadingEvent implements FluxStandardAction {
    type = MutationTypes.RELEASE_LOADING_EVENT
}

export class ReleaseLoadedEvent implements FluxStandardAction {
    type = MutationTypes.RELEASE_LOADED_EVENT
}

export class ReleaseCreatedEvent implements FluxStandardAction {
    type = MutationTypes.RELEASE_CREATED_EVENT
    constructor(public location: string){}
}

export class ReleaseUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.RELEASE_UPDATED_EVENT
}

export class ReleaseDeletedEvent implements FluxStandardAction {
    type = MutationTypes.RELEASE_DELETED_EVENT
    constructor(public guid: string){}
}