import { ActionTree } from 'vuex'
import { NotificationState } from '@/services/NotificationEditor/infrastructure/store/modules/Notification/state'
import { ActionTypes } from './action-types'
import {
  MutationTypes,
  NotificationUpdatedEvent,
  NotificationCreatedEvent,
  NotificationLoadingEvent,
  NotificationDeletedEvent,
  NotificationLoadedEvent
} from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { NotificationAPI } from '@/services/NotificationEditor/infrastructure/api/NotificationAPI'

export const actions: ActionTree<NotificationState, any> = {
  [ActionTypes.GET_NOTIFICATION]: async ({ commit, dispatch }, data) => {
    commit(new NotificationLoadingEvent())

    return APIClient.shared.request(new NotificationAPI.GetNotification(data.payload))
      .then((response) => {
        commit(new NotificationLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.GET_NOTIFICATION_BY_GUID]: async ({ commit, dispatch }, data) => {
    commit(new NotificationLoadingEvent())

    return APIClient.shared.request(new NotificationAPI.GetNotificationById(data.id))
      .then((response) => {
        commit(new NotificationLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.CREATE_NOTIFICATION]: async ({ commit, dispatch }, data) => {
    commit(new NotificationLoadingEvent())

    return APIClient.shared.request(new NotificationAPI.CreateNotification(data.payload))
      .then((location: string) => {
        commit(new NotificationCreatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.UPDATE_NOTIFICATION]: async ({ commit, dispatch }, data) => {
    commit(new NotificationLoadingEvent())

    return APIClient.shared.request(new NotificationAPI.UpdateNotification(data.payload))
      .then((location: string) => {
        commit(new NotificationUpdatedEvent(location))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.DELETE_NOTIFICATION]: async ({ commit, dispatch }, data) => {
    commit(new NotificationLoadingEvent())

    return APIClient.shared.request(new NotificationAPI.DeleteNotification(data.id))
      .then((location) => {
        commit(new NotificationDeletedEvent(data.id))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  }
}
