<template>
  <div :style="css">
    <el-button icon="el-icon-search" @click="openOuterRegistry"></el-button>
  </div>
</template>

<script>
export default {
  name: 'xref_outer_field',
  props: ['value', 'record-id', 'properties', 'css'],
  inject: ['addMainTab'],
  methods: {
    openOuterRegistry () {
      let xref = this.properties.find((item) => item.id === 'xref')
      if (!xref || !xref.value) {
        return false
      }
      let me = this
      this.$http.get(`${this.$config.api}/objecteditor/entities/${xref.value}`)
        .then((response) => {
          let xrefObjectId = response.data.data.object_id
          if (xrefObjectId) {
            me.addMainTab({ name: 'test',
              componentType: 'Registry',
              payload: {
                id: xrefObjectId,
                outerXref: {
                  id: xref.value,
                  value: me.recordId
                }
              } })
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
