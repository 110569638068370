<template>
  <div :class="{ 'table_mapping': true, 'full_screen': isFullScreen }">
    <el-table
      :data="mapping.data"
      :height="isFullScreen ? this.window.height - 73 : 400"
      border>
      <!-- Объект источник -->
      <el-table-column
        :label="$locale.etl_editor.form.table.source_object"
        header-align="center">
        <!-- Поле -->
        <el-table-column
          prop="source_field_id"
          :label="$locale.etl_editor.form.table.field"
          width="150"
          header-align="center">
          <template slot-scope="scope">
            <el-select v-if="rowEditor === scope.row.id" v-model="scope.row.source_field_id" :placeholder="$locale.etl_editor.form.table.field" size="mini">
              <el-option v-for="item in selectSourceField" :key="item.id" :label="`${item.name} (attr_${item.id}_)`" :value="item.id"></el-option>
            </el-select>

            <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': empty(scope.row.source_field_id) }">
              {{ getObjectName(selectSourceField, scope.row.source_field_id) }}
            </p>
          </template>
        </el-table-column>

        <!-- Столбец -->
        <el-table-column
          prop="source_column"
          :label="$locale.etl_editor.form.table.column"
          width="100"
          header-align="center">
          <template slot-scope="scope">
            <el-input v-if="rowEditor === scope.row.id" size="mini" v-model="scope.row.source_column" :placeholder="$locale.etl_editor.form.table.column"></el-input>

            <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': empty(scope.row.source_column) }">
              {{ scope.row.source_column || '(нет данных)' }}
            </p>
          </template>
        </el-table-column>

        <!-- Псевдоним -->
        <el-table-column
          prop="source_alias"
          :label="$locale.etl_editor.form.table.alias"
          width="100"
          header-align="center">
          <template slot-scope="scope">
            <el-input v-if="rowEditor === scope.row.id" size="mini" v-model="scope.row.source_alias" :placeholder="$locale.etl_editor.form.table.alias"></el-input>

            <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': empty(scope.row.source_alias) }">
              {{ scope.row.source_alias || '(нет данных)' }}
            </p>
          </template>
        </el-table-column>
      </el-table-column>

      <!-- Целевой объект -->
      <el-table-column
        :label="$locale.etl_editor.form.table.target_object"
        header-align="center">
        <!-- Поле -->
        <el-table-column
          prop="target_field_id"
          :label="$locale.etl_editor.form.table.field"
          width="150"
          header-align="center">
          <template slot-scope="scope">
            <el-select v-if="rowEditor === scope.row.id" v-model="scope.row.target_field_id" :placeholder="$locale.etl_editor.form.table.field" size="mini" @change="selectTargetFieldId($event, scope.$index)">
              <el-option v-for="item in selectTargetField" :key="item.id" :label="`${item.name} (attr_${item.id}_)`" :value="item.id"></el-option>
            </el-select>

            <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': scope.row.target_field_id === null }">
              {{ getObjectName(selectTargetField, scope.row.target_field_id) }}
            </p>
          </template>
        </el-table-column>

        <!-- Столбец -->
        <el-table-column
          prop="target_column"
          :label="$locale.etl_editor.form.table.column"
          width="100"
          header-align="center">
          <template slot-scope="scope">
            <el-input v-if="rowEditor === scope.row.id" size="mini" v-model="scope.row.target_column" :placeholder="$locale.etl_editor.form.table.column"></el-input>

            <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': empty(scope.row.target_column) }">
              {{ scope.row.target_column || '(нет данных)' }}
            </p>
          </template>
        </el-table-column>

        <!-- Псевдоним -->
        <el-table-column
          prop="target_alias"
          :label="$locale.etl_editor.form.table.alias"
          width="100"
          header-align="center">
          <template slot-scope="scope">
            <el-input v-if="rowEditor === scope.row.id" size="mini" v-model="scope.row.target_alias" :placeholder="$locale.etl_editor.form.table.alias"></el-input>

            <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': empty(scope.row.target_alias) }">
              {{ scope.row.target_alias || '(нет данных)' }}
            </p>
          </template>
        </el-table-column>
      </el-table-column>

      <!-- Ключевое -->
      <el-table-column
        prop="is_key"
        :label="$locale.etl_editor.form.table.is_key"
        width="100"
        header-align="center">
        <template slot-scope="scope">
          <el-checkbox :disabled="rowEditor !== scope.row.id" v-model="scope.row.is_key" label="Да"></el-checkbox>
        </template>
      </el-table-column>

      <!-- Обязательное -->
      <el-table-column
        prop="is_required"
        :label="$locale.etl_editor.form.table.is_required"
        width="115"
        header-align="center">
        <template slot-scope="scope">
          <el-checkbox :disabled="rowEditor !== scope.row.id" v-model="scope.row.is_required" label="Да"></el-checkbox>
        </template>
      </el-table-column>

      <!-- Загружать/Выгружать значения справочника -->
      <el-table-column
        prop="is_load_xref_table_values"
        :label="$locale.etl_editor.form.table.is_load_xref_table_values"
        width="175"
        header-align="center">
        <template slot-scope="scope">
          <el-checkbox :disabled="rowEditor !== scope.row.id" v-model="scope.row.is_load_xref_table_values" label="Да"></el-checkbox>
        </template>
      </el-table-column>

      <!-- Полсе поиска значения ссылки -->
      <el-table-column
        prop="xref_field_id"
        :label="$locale.etl_editor.form.table.xref_field_id"
        width="140"
        header-align="center">
        <template slot-scope="scope">
          <registry-select-tree
            v-if="rowEditor === scope.row.id && xrefObjectId !== null"
            v-model="scope.row.xref_field_id"
            value-as="number"
            type="field"
            size="mini"
            :placeholder="$locale.etl_editor.form.table.xref_field_id"
            :parent-id="xrefObjectId"
          ></registry-select-tree>

          <p v-else-if="rowEditor !== scope.row.id" :class="{ 'no-data': empty(scope.row.xref_field_id) }">
            {{ scope.row.xref_field_id || '(нет данных)' }}
          </p>
        </template>
      </el-table-column>

      <!-- Условие поиска значения ссылки -->
      <el-table-column
        prop="xref_condition"
        :label="$locale.etl_editor.form.table.xref_condition"
        width="150"
        header-align="center">
        <template slot-scope="scope">
          <el-input
            v-if="rowEditor === scope.row.id"
            size="mini"
            v-model="scope.row.xref_condition"
            :placeholder="$locale.etl_editor.form.table.xref_condition"
          ></el-input>

          <p v-else :class="{ 'no-data': empty(scope.row.xref_condition) }">
            {{ scope.row.xref_condition || '(нет данных)' }}
          </p>
        </template>
      </el-table-column>

      <!-- Загрузкчик внешней ссылки -->
      <el-table-column
        prop="loader_id"
        :label="$locale.etl_editor.form.table.loader_id"
        width="160"
        header-align="center">
        <template slot-scope="scope">
          <el-select v-if="rowEditor === scope.row.id" v-model="scope.row.loader_id" :placeholder="$locale.etl_editor.form.table.loader_id" size="mini">
            <el-option v-for="item in loaders" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>

          <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': scope.row.loader_id === null }">
            {{ getObjectName(loaders, scope.row.loader_id) }}
          </p>
        </template>
      </el-table-column>

      <!-- Значение -->
      <el-table-column
        prop="value"
        :label="$locale.etl_editor.form.table.value"
        width="120"
        header-align="center">
        <template slot-scope="scope">
          <el-input v-if="rowEditor === scope.row.id" size="mini" v-model="scope.row.value" :placeholder="$locale.etl_editor.form.table.value"></el-input>

          <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': empty(scope.row.value) }">
            {{ scope.row.value || '(нет данных)' }}
          </p>
        </template>
      </el-table-column>

      <!-- Порядок -->
      <el-table-column
        prop="row_order"
        :label="$locale.etl_editor.form.table.row_order"
        width="120"
        header-align="center">
        <template slot-scope="scope">
          <el-input-number
            v-if="rowEditor === scope.row.id"
            size="mini"
            v-model="scope.row.row_order"
            :placeholder="$locale.etl_editor.form.table.row_order"
            controls-position="right"
            :min="0"
            :max="99999999">
          </el-input-number>

          <p v-if="rowEditor !== scope.row.id">
            {{ scope.row.row_order || '(нет данных)' }}
          </p>
        </template>
      </el-table-column>

      <!-- Условие -->
      <el-table-column
        prop="condition"
        :label="$locale.etl_editor.form.table.condition"
        width="120"
        header-align="center">
        <template slot-scope="scope">
          <el-input v-if="rowEditor === scope.row.id" size="mini" v-model="scope.row.condition" :placeholder="$locale.etl_editor.form.table.condition"></el-input>

          <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': empty(scope.row.condition) }">
            {{ scope.row.condition || '(нет данных)' }}
          </p>
        </template>
      </el-table-column>

      <el-table-column
        fixed="right"
        width="125"
        header-align="center">
        <template slot="header">
          <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.etl_editor.tooltip.add" placement="top">
            <el-button icon="el-icon-circle-plus-outline" circle type="success" size="mini" @click.stop="addMapping()"></el-button>
          </el-tooltip>
          <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.etl_editor.tooltip.full_screen" placement="top">
            <el-button
              icon="el-icon-full-screen"
              :type="isFullScreen ? 'info' : 'default'"
              size="mini"
              circle
              @click.stop="fullScreen()">
            </el-button>
          </el-tooltip>
        </template>

        <template slot-scope="scope">
          <el-tooltip
            :open-delay="300"
            class="item"
            effect="dark"
            :content="rowEditor === scope.row.id ? $locale.etl_editor.tooltip.save : $locale.etl_editor.tooltip.edit"
            placement="top">
            <el-button
              @click.native.prevent="editMapping(scope.$index)"
              :type="rowEditor === scope.row.id ? 'primary' : 'default'"
              size="mini"
              circle>
              <span :class="rowEditor === scope.row.id ? 'el-icon-success' : 'el-icon-edit'"></span>
            </el-button>
          </el-tooltip>

          <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.etl_editor.tooltip.delete" placement="top">
            <el-button
              @click.native.prevent="deleteMapping(scope.$index)"
              type="danger"
              size="mini"
              circle>
              <span class="el-icon-delete-solid"></span>
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @current-change="tablePageChange"
      :page-size="pagination.limit"
      layout="total, prev, pager, next"
      style="background-color: #fff;"
      :total="mapping.count">
    </el-pagination>
  </div>
</template>

<script>
import RegistrySelectTree from '@/components/Common/RegistrySelectTree'

import Mapping, { MappingDTO } from '@/services/EtlEditor/domain/model/Mapping'
import MappingByGuidQuery from '@/services/EtlEditor/application/query/MappingByGuidQuery'
import MappingsQuery from '@/services/EtlEditor/application/query/MappingsQuery'
import TaskObjectByTaskIdQuery from '@/services/EtlEditor/application/query/TaskObjectByTaskIdQuery'
import MappingCreateCommand from '@/services/EtlEditor/application/command/MappingCreateCommand'
import MappingDeleteCommand from '@/services/EtlEditor/application/command/MappingDeleteCommand'
import MappingUpdateCommand from '@/services/EtlEditor/application/command/MappingUpdateCommand'

export default {
  name: 'TableMapping',

  components: {
    RegistrySelectTree
  },

  props: {
    taskId: Number,
    loaders: {
      type: Array,
      default () {
        return []
      }
    },
    transformers: {
      type: Array,
      default () {
        return []
      }
    }
  },

  computed: {
    createdMapping () {
      return this.$store.getters['Mapping/getMappingLocation']
    }
  },

  watch: {
    createdMapping (location) {
      if (location) {
        this.getQueryBus().execute(
          new MappingByGuidQuery(location.replace('/mappings/', ''))
        ).then(response => {
          if (response.guid) {
            this.mapping.count++
            this.mapping.data.push(response)
          }
        })
      }
    }
  },

  inject: ['getQueryBus', 'getCommandBus'],

  data () {
    return {
      selectSourceField: [],
      selectTargetField: [],

      rowEditor: 0,

      mapping: {
        count: 0,
        data: []
      },
      pagination: {
        current: 0,
        limit: 30
      },

      xrefObjectId: null,

      isFullScreen: false,

      window: {
        width: 0,
        height: 0
      }
    }
  },

  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },

  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },

  async mounted () {
    await this.load()
  },

  methods: {
    selectTargetFieldId (value, index) {
      this.xrefObjectId = this.mapping.data[index].xref_field_id = null
      if (value !== null) {
        this.$http
          .get(`${this.$config.api}/registryservice/xref/${value}`)
          .then((response) => {
            if (response.data !== null) {
              this.xrefObjectId = response.data.xref_object_id
              this.mapping.data[index].xref_field_id = response.data.xref_attr_id
            }
          })
      }
    },

    empty (value) {
      return value === null || value === ''
    },

    async load () {
      this.getObjectList(
        await this.getQueryBus().execute(
          new TaskObjectByTaskIdQuery(this.taskId)
        )
      )

      this.rowEditor = 0

      let count = await this.getQueryBus().execute(
        new MappingsQuery({ '*[func]': 'count', task_id: this.taskId })
      )

      this.mapping.count = count.length ? count[0].count : 0

      this.mapping.data = await this.getQueryBus().execute(
        new MappingsQuery({ task_id: this.taskId, offset: this.pagination.current, limit: this.pagination.limit, order: 'row_order:asc,id:asc' })
      )
    },

    async editMapping (index) {
      if (this.rowEditor === this.mapping.data[index].id) {
        let model = Mapping.create(this.mapping.data[index])
        this.getCommandBus().execute(
          new MappingUpdateCommand(
            model.getGuid(),
            model.getSourceFieldId(),
            model.getSourceColumn(),
            model.getSourceAlias(),
            model.getTargetFieldId(),
            model.getTargetColumn(),
            model.getTargetAlias(),
            model.getIsKey(),
            model.getIsRequired(),
            model.getIsLoadXrefTableValues(),
            model.getXrefFieldId(),
            model.getXrefCondition(),
            model.getLoaderId(),
            model.getTransformerId(),
            model.getRowOrder(),
            model.getValue(),
            model.getCondition(),
            model.getElementTypeId(),
            model.getDescription()
          )
        ).then(() => {
          if (this.mapping.data[index].target_field_id !== null) {
            this.$http
              .get(`${this.$config.api}/registryservice/xref/${this.mapping.data[index].target_field_id}`)
              .then((response) => {
                if (response.data !== null) {
                  this.xrefObjectId = response.data.xref_object_id
                }
              })
          }

          this.rowEditor = 0
        })
      } else {
        this.rowEditor = this.mapping.data[index].id

        if (this.mapping.data[index].target_field_id !== null) {
          this.selectTargetFieldId(this.mapping.data[index].target_field_id, index)
        }
      }
    },

    addMapping () {
      let model = Mapping.create(
        MappingDTO.create(this.taskId)
      )
      this.getCommandBus().execute(
        new MappingCreateCommand(
          model.getTaskId(),
          model.getParentId(),
          model.getSourceFieldId(),
          model.getSourceColumn(),
          model.getSourceAlias(),
          model.getTargetFieldId(),
          model.getTargetColumn(),
          model.getTargetAlias(),
          model.getIsKey(),
          model.getIsRequired(),
          model.getIsLoadXrefTableValues(),
          model.getXrefFieldId(),
          model.getXrefCondition(),
          model.getLoaderId(),
          model.getTransformerId(),
          model.getRowOrder(),
          model.getValue(),
          model.getCondition(),
          model.getElementTypeId(),
          model.getDescription()
        )
      )
    },

    async deleteMapping (index) {
      this.$confirm(this.$locale.main.message.delete, this.$locale.main.message_title.warning, {
        confirmButtonText: this.$locale.main.button.ok,
        cancelButtonText: this.$locale.main.button.cancel,
        type: 'warning'
      }).then(async () => {
        if (this.mapping.data[index].id) {
          this.getCommandBus().execute(new MappingDeleteCommand(this.mapping.data[index].guid))
          this.mapping.count--
          this.mapping.data.splice(index, 1)
        } else {
          this.mapping.data.splice(index, 1)
        }
      })
    },

    handleResize () {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },

    fullScreen () {
      this.isFullScreen = !this.isFullScreen
    },

    getObjectList (data) {
      this.selectSourceField = []
      if (data.extractor_type_id === 'object_table' && data.extractor_object_id !== null) {
        this.$http
          .get(`${this.$config.api}/objecteditor/entities?object_id=${data.extractor_object_id}&is_field=true`)
          .then((response) => {
            this.selectSourceField.push(...response.data.data)
          })
      }

      this.selectTargetField = []
      if (data.loader_type_id === 'object_table' && data.loader_object_id !== null) {
        this.$http
          .get(`${this.$config.api}/objecteditor/entities?object_id=${data.loader_object_id}&is_field=true`)
          .then((response) => {
            this.selectTargetField.push(...response.data.data)
          })
      }
    },

    async tablePageChange (val) {
      val--
      this.pagination.current = (val * this.pagination.limit)

      let count = await this.getQueryBus().execute(
        new MappingsQuery({ '*[func]': 'count', task_id: this.taskId })
      )

      this.mapping.count = count.length ? count[0].count : 0

      this.mapping.data = await this.getQueryBus().execute(
        new MappingsQuery({ task_id: this.taskId, offset: this.pagination.current, limit: this.pagination.limit, order: 'row_order:asc,id:asc' })
      )
    },

    getObjectName (arrayObjects, searchValue) {
      let object = arrayObjects.find(obj => obj.id === searchValue)

      if (typeof object !== 'undefined') {
        return `${object.name} (attr_${object.id}_)`
      }

      return '(нет данных)'
    }
  }
}
</script>

<style scoped></style>
