import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    LOGIC_LOADING_EVENT = 'logic_loading_event',
    LOGIC_LOADED_EVENT = 'logic_loaded_event',
    LOGIC_CREATED_EVENT = 'logic_created_event',
    LOGIC_UPDATED_EVENT = 'logic_updated_event',
    LOGIC_DELETED_EVENT = 'logic_deleted_event'
}

export class LogicLoadingEvent implements FluxStandardAction {
  type = MutationTypes.LOGIC_LOADING_EVENT
}

export class LogicLoadedEvent implements FluxStandardAction {
  type = MutationTypes.LOGIC_LOADED_EVENT
}

export class LogicCreatedEvent implements FluxStandardAction {
  type = MutationTypes.LOGIC_CREATED_EVENT
  constructor (public location: string) {}
}

export class LogicUpdatedEvent implements FluxStandardAction {
  type = MutationTypes.LOGIC_UPDATED_EVENT
  constructor (public location: string) {}
}

export class LogicDeletedEvent implements FluxStandardAction {
  type = MutationTypes.LOGIC_DELETED_EVENT
  constructor (public guid: string) {}
}
