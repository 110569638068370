import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    GEOJSON_LOADING_EVENT = 'geojson_loading_event',
    GEOJSON_LOADED_EVENT = 'geojson_loaded_event'
}

export class GeoJsonLoadingEvent implements FluxStandardAction {
    type = MutationTypes.GEOJSON_LOADING_EVENT
}

export class GeoJsonLoadedEvent implements FluxStandardAction {
    type = MutationTypes.GEOJSON_LOADED_EVENT
}