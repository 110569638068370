import { LayerTreeElementDTO } from '@/services/MapEditor/domain/model/Layer/LayerTreeElement'

export interface LayerTreeElementState {
  isLoading: boolean;
  location: string;
  deletedElementGuid: string;
  error: object
}

export const initialState = (): LayerTreeElementState => {
  return {
    isLoading: false,
    location: null,
    deletedElementGuid: null,
    error: null
  }
}