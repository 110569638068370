import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import TemplateQueryRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/TemplateQueryRepositoryInterface'
import store from '@/store'
import { GetTemplates, CountTemplates } from '@/services/DigitalTwinEditor/infrastructure/store/modules/Template/action-types'

export default class TemplateQueryRepository extends AbstractQueryRepository implements TemplateQueryRepositoryInterface {
  get (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetTemplates(payload))
  }

  getCount (payload: string[][] | object): Promise<any> {
    return store.dispatch(new CountTemplates(payload))
  }
}
