import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { ModelGroupDTO } from '@/services/DigitalTwinEditor/domain/model/Model/ModelGroup'

export enum ActionTypes {
    UPDATE_MODEL_GROUP = 'update_model_group',
    GET_MODEL_GROUP_BY_GUID = 'get_model_group_by_guid'
}

export class UpdateModelGroup implements FluxStandardAction {
    type = 'ModelGroup/' + ActionTypes.UPDATE_MODEL_GROUP
    constructor(public payload: ModelGroupDTO){}
}

export class GetModelGroupByGuid implements FluxStandardAction {
    type = 'ModelGroup/' + ActionTypes.GET_MODEL_GROUP_BY_GUID
    constructor(public guid: string){}
}
