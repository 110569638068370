import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    ROW_FORMULA_LOADING_EVENT = 'row_formula_loading_event',
    ROW_FORMULA_LOADED_EVENT = 'row_formula_loaded_event',
    ROW_FORMULA_CREATED_EVENT = 'row_formula_created_event',
    ROW_FORMULA_UPDATED_EVENT = 'row_formula_updated_event',
    ROW_FORMULA_DELETED_EVENT = 'row_formula_deleted_event'
}

export class RowFormulaLoadingEvent implements FluxStandardAction {
  type = MutationTypes.ROW_FORMULA_LOADING_EVENT
}

export class RowFormulaLoadedEvent implements FluxStandardAction {
  type = MutationTypes.ROW_FORMULA_LOADED_EVENT
}

export class RowFormulaCreatedEvent implements FluxStandardAction {
  type = MutationTypes.ROW_FORMULA_CREATED_EVENT
  constructor (public location: string) {}
}

export class RowFormulaUpdatedEvent implements FluxStandardAction {
  type = MutationTypes.ROW_FORMULA_UPDATED_EVENT
  constructor (public location: string) {}
}

export class RowFormulaDeletedEvent implements FluxStandardAction {
  type = MutationTypes.ROW_FORMULA_DELETED_EVENT
  constructor (public guid: string) {}
}
