import { FluxStandardAction } from '@/core/domain/type/types'
import { LayerDTO } from '@/services/MapEditor/domain/model/Layer/Layer'

export enum MutationTypes {
    ERROR = 'error',
    LAYER_LOADING_EVENT = 'layer_loading_event',
    LAYER_LOADED_EVENT = 'layer_loaded_event',
    LAYER_CREATED_EVENT = 'layer_created_event',
    LAYER_UPDATED_EVENT = 'layer_updated_event' ,
    LAYER_DELETED_EVENT = 'layer_deleted_event' 
}

export class LayerLoadingEvent implements FluxStandardAction {
    type = MutationTypes.LAYER_LOADING_EVENT
}

export class LayerLoadedEvent implements FluxStandardAction {
    type = MutationTypes.LAYER_LOADED_EVENT
}

export class LayerCreatedEvent implements FluxStandardAction {
    type = MutationTypes.LAYER_CREATED_EVENT
    constructor(public location: string){}
}

export class LayerUpdatedEvent implements FluxStandardAction {
    type = MutationTypes.LAYER_UPDATED_EVENT
}

export class LayerDeletedEvent implements FluxStandardAction {
    type = MutationTypes.LAYER_DELETED_EVENT
    constructor(public guid: string){}
}