import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'
import NameTooLong from '@/core/domain/exception/NameTooLong'
import NameMustNotBeEmpty from '@/core/domain/exception/NameMustNotBeEmpty'
import TreeElementType from '../TreeElementType'

export class ModelDTO
{
    id?: Nullable<number>;
    guid?: Nullable<string>;
    name?: string;
    description?: string;
    alias?: string;
    coordinate_system_id?: Nullable<number>;
    position?: Array<number>;
    rotation?: Array<number>;
    files?: Array<object>;
    parent_id?: Nullable<number>;

    constructor({ id, guid, name, description, alias, coordinate_system_id, position, rotation, files, parent_id } : { id?: Nullable<number>, guid?: Nullable<string>, name?: string, description?: string, alias?: string, coordinate_system_id?: Nullable<number>, position?: Array<number>, rotation?: Array<number>, files?: Array<object>, parent_id?: Nullable<number>}) {
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.description = description;
        this.alias = alias;
        this.coordinate_system_id = coordinate_system_id;
        this.position = position ?? [0, 0, 0];
        this.rotation = rotation ?? [0, 0, 0];
        this.files = files ?? [];
        this.parent_id = parent_id;
    }
}

export default class Model extends AbstractBaseEntity
{
    private id?: Nullable<number>;
    private guid?: Nullable<string>;
    private name: string;
    private description: Nullable<string>;
    private alias: Nullable<string>;
    private coordinateSystem: Nullable<number>;
    private position?: Array<number>;
    private rotation?: Array<number>;
    private files?: Array<object>;
    private parentId: Nullable<number>;

    constructor(
        id: Nullable<number>,
        guid: Nullable<string>,
        name: string,
        description: Nullable<string>,
        alias: Nullable<string>,
        coordinateSystem: Nullable<number>,
        position: Array<number>,
        rotation: Array<number>,
        files: Array<object>,
        parentId: Nullable<number>
    ) {
        super();
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.description = description;
        this.alias = alias;
        this.coordinateSystem = coordinateSystem;
        this.position = position ?? [0, 0, 0];
        this.rotation = rotation ?? [0, 0, 0];
        this.files = files ?? [];
        this.parentId = parentId;
        this.assertInvariants();
    }

    static create(dto: ModelDTO): Model
    {
        return new Model(
            dto.id,
            dto.guid,
            dto.name,
            dto.description,
            dto.alias,
            dto.coordinate_system_id,
            dto.position,
            dto.rotation,
            dto.files,
            dto.parent_id
        );
    }

    setId(id: number): void
    {
        this.id = id;
    }

    getId(): number
    {
        return this.id;
    }

    setGuid(guid: string): void
    {
        this.guid = guid;
    }

    getGuid(): string
    {
        return this.guid;
    }

    setName(name: string): void
    {
        if (name.length > 255) {
            throw new NameTooLong();
        }
        this.name = name;
    }

    getName(): string
    {
        return this.name;
    }

    assertInvariants(): void
    {
        if (this.name.length == 0) {
            throw new NameMustNotBeEmpty();
        }

        if (this.name.length > 255) {
            throw new NameTooLong();
        }
    }

    setDescription(description: Nullable<string>): void
    {
        this.description = description;
    }

    getDescription(): Nullable<string>
    {
        return this.description;
    }

    setAlias(alias: Nullable<string>): void
    {
        this.alias = alias;
    }

    getAlias(): Nullable<string>
    {
        return this.alias;
    }

    setCoordinateSystem(coordinateSystem: Nullable<number>): void
    {
        this.coordinateSystem = coordinateSystem;
    }

    getCoordinateSystem(): Nullable<number>
    {
        return this.coordinateSystem;
    }

    setPosition(position: Array<number>): void
    {
        this.position = position;
    }

    getPosition(): Array<number>
    {
        return this.position;
    }

    setRotation(rotation: Array<number>): void
    {
        this.rotation = rotation;
    }

    getRotation(): Array<number>
    {
        return this.rotation;
    }

    setParentId(parentId: Nullable<number>): void
    {
        this.parentId = parentId;
    }

    getParentId(): Nullable<number>
    {
        return this.parentId;
    }

    getType(): TreeElementType
    {
        return TreeElementType.MODEL;
    }
}