import TopicCommandRepositoryInterface from '@/services/Databus/domain/repository/TopicCommandRepositoryInterface'
import { TopicDTO } from '@/services/Databus/domain/model/Topic/Topic'
import store from '@/store'
import { AddTopic, UpdateTopic, DeleteTopic } from '@/services/Databus/infrastructure/store/modules/Topic/action-types'

export default class TopicCommandRepository implements TopicCommandRepositoryInterface
{
    insert(item: TopicDTO): Promise<void>
    {
        return store.dispatch(new AddTopic(item));
    }

    update(item: TopicDTO): Promise<void>
    {
        return store.dispatch(new UpdateTopic(item));
    }

    delete(guid: string): Promise<void>
    {
        return store.dispatch(new DeleteTopic(guid));
    }
}
