import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import LayerQueryRepositoryInterface from '@/services/DigitalTwinEditor/domain/repository/LayerQueryRepositoryInterface'
import store from '@/store'
import { GetLayerByGuid } from '@/services/DigitalTwinEditor/infrastructure/store/modules/Layer/action-types'

export default class LayerQueryRepository extends AbstractQueryRepository implements LayerQueryRepositoryInterface
{
    getByGuid(guid: string): Promise<any>
    {
        return store.dispatch(new GetLayerByGuid(guid));
    }
}